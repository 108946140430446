import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Box, IconButton, Paper, TextField, Typography } from '@mui/material';
import { CreateNewFolder, Delete, Edit } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { FocusNestableItem, FocusRenderItemArgs } from '../../../types/nestable';
import { FocusArea } from '../../../types/document';
import DangerIconButton from '../../../styled/DangerIconButton';
import EditFocusAreaTableDialog from '../dialogs/shvk/EditFocusAreaTableDialog';
import useStore, { rootModule } from '../../../store/storeContext';

const PREFIX = 'FocusNestableComponent';

const classes = {
    paper: `${PREFIX}-paper`,
    depth1: `${PREFIX}-depth1`,
    depth2: `${PREFIX}-depth2`,
    depth3: `${PREFIX}-depth3`,
};

const { theming } = rootModule;

const StyledPaper = styled(Paper)(({ theme }) => ({
    [`&.${classes.paper}`]: {
        position: 'relative',
        padding: theme.spacing(0.5),
        cursor: 'pointer',
    },

    [`&.${classes.depth1}`]: {
        backgroundColor: theming.shvkBlueGray60,
    },

    [`&.${classes.depth2}`]: {
        paddingTop: theme.spacing(0.25),
        paddingBottom: theme.spacing(0.25),
        backgroundColor: theming.shvkBlueGray40,
    },

    [`&.${classes.depth3}`]: {
        paddingTop: theme.spacing(0.25),
        paddingBottom: theme.spacing(0.25),
        backgroundColor: theming.shvkBlueGray20,
    },
}));

interface Props {
    args: FocusRenderItemArgs;
    handleTextChange: (value: string, id: number) => void;
    addItem: (parentId: number | null) => void;
    removeItem: (item: FocusNestableItem) => void;
    save: (closeOnSave: boolean) => Promise<void>;
}

interface DialogState {
    open: boolean;
    focusArea: FocusArea;
}

interface State {
    editing: boolean;
}

function FocusNestableComponent(props: Props) {
    const { document, dialog } = useStore();

    const [state, setState] = useState<State>({
        editing: false,
    });

    const [goalTableDialog, setGoalTableDialog] = useState<DialogState>({
        open: false,
        focusArea: {} as FocusArea,
    });

    const handleEdit = (): void => {
        setState((state) => ({ ...state, editing: !state.editing }));
    };

    const handleOpen = (): void => {
        props.save(false).then(() => {
            const focusArea = document.currentDocument.chapters
                .find((chapter) => chapter.isFocusArea)!
                .focusAreas.find((focusArea) => focusArea.goals.find((goal) => goal.id === props.args.item.id));
            focusArea
                ? setGoalTableDialog({ open: true, focusArea })
                : setGoalTableDialog({ open: true, focusArea: {} as FocusArea });
        });
    };

    async function remove(): Promise<void> {
        const confirmation = await dialog.getConfirmation();
        if (!confirmation) return;

        props.removeItem(props.args.item);
    }

    const { args, handleTextChange, addItem } = props;
    const { depth, children } = props.args.item;

    return (
        <StyledPaper
            variant="outlined"
            className={`${classes.paper} ${
                depth === 1 ? classes.depth1 : depth === 2 ? classes.depth2 : classes.depth3
            }`}
        >
            <Box display="flex" alignItems="center">
                {args.collapseIcon}
                {state.editing ? (
                    <TextField
                        autoFocus
                        fullWidth
                        variant="outlined"
                        size="small"
                        onChange={(event): void => handleTextChange(event.target.value, args.item.id)}
                        value={args.item.text?.replace(/<[^>]+>/g, '') || ''}
                        onBlur={handleEdit}
                        onKeyDown={(event) => {
                            if (event.key === 'Enter') handleEdit();
                        }}
                    />
                ) : (
                    <Typography
                        className="fr-view"
                        onClick={handleEdit}
                        dangerouslySetInnerHTML={{ __html: args.item.text?.trim().replace(/<[^>]+>/g, '') || ' - ' }}
                    />
                )}
                <Box flexGrow={1} />
                {depth === 2 && (
                    <IconButton size="small" color="primary" onClick={handleOpen}>
                        <Edit />
                    </IconButton>
                )}
                {depth !== 3 && (
                    <IconButton size="small" color="primary" onClick={() => addItem(args.item.id)}>
                        <CreateNewFolder />
                    </IconButton>
                )}
                <DangerIconButton size="small" onClick={remove} disabled={children.length > 0}>
                    <Delete />
                </DangerIconButton>
            </Box>
            <EditFocusAreaTableDialog
                isOpen={goalTableDialog.open}
                close={() => setGoalTableDialog((state) => ({ ...state, open: false }))}
                focusArea={goalTableDialog.focusArea}
            />
        </StyledPaper>
    );
}

export default observer(FocusNestableComponent);
