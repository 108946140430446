import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { AppBar, Button, Container, Grid, Slide, Typography, useScrollTrigger } from '@mui/material';
import FeedbackDialog from './FeedbackDialog';
import SeeFeedbackDialog from './SeeFeedbackDialog';
import GeneralDialog from '../GeneralDialog';
import useStore from '../../store/storeContext';

interface State {
    generalDialogOpen: boolean;
    feedbackDialogOpen: boolean;
    seeFeedbackDialogOpen: boolean;
    generalDialogContent: string;
}

type FooterDialog = 'generalDialogOpen' | 'feedbackDialogOpen' | 'seeFeedbackDialogOpen';

function Footer() {
    const { user, info, feedback, localization, theming } = useStore();

    const scrollTrigger = useScrollTrigger();

    const [state, setState] = useState<State>({
        generalDialogOpen: false,
        feedbackDialogOpen: false,
        seeFeedbackDialogOpen: false,
        generalDialogContent: '',
    });

    useEffect(() => {
        setState((state) => ({ ...state, generalDialogContent: '' }));
    }, []);

    function handleDialog(dialog: FooterDialog, content?: string) {
        setState((state) => ({ ...state, [dialog]: !state[dialog] }));
        content && setState((state) => ({ ...state, generalDialogContent: content }));
    }

    const footerStyle = {
        backgroundColor: theming.palette.primary.main,
        padding: theming.spacing(1),
        flexShrink: 0,
        bottom: 0,
        top: 'auto',
    };
    const textStyle = { color: theming.palette.common.white };

    const { translate } = localization;

    const copyRightString = translate('FOOTER_COPYRIGHT').replace('{{year}}', new Date().getFullYear().toString());

    return (
        <Slide in={!scrollTrigger} direction="up">
            <AppBar position="fixed" style={footerStyle}>
                <Container>
                    <Grid container spacing={2} justifyContent="space-around">
                        <Grid item xs={6} sm={2} alignSelf="center" textAlign="center">
                            <Typography variant="caption" style={textStyle}>
                                &copy; {copyRightString}
                            </Typography>
                        </Grid>
                        {info.cookiesInfo?.[localization.locale] ? (
                            <Grid item xs={6} sm={2} textAlign="center" alignSelf="center">
                                <Button
                                    onClick={(): void =>
                                        handleDialog('generalDialogOpen', info.cookiesInfo?.[localization.locale])
                                    }
                                >
                                    <Typography variant="caption" textAlign="center" style={textStyle}>
                                        {translate('COOKIES_AND_PRIVACY')}
                                    </Typography>
                                </Button>
                            </Grid>
                        ) : null}
                        {info.accessibilityStatement?.[localization.locale] ? (
                            <Grid item xs={6} sm={2} textAlign="center">
                                <Button
                                    onClick={(): void =>
                                        handleDialog(
                                            'generalDialogOpen',
                                            info.accessibilityStatement?.[localization.locale],
                                        )
                                    }
                                >
                                    <Typography variant="caption" alignSelf="center" style={textStyle}>
                                        {translate('ACCESSIBILITY_STATEMENT')}
                                    </Typography>
                                </Button>
                            </Grid>
                        ) : null}
                        {user.isAuthorized('license_management', 'update_license') ? (
                            <Grid item xs={6} sm={2} textAlign="center">
                                {/* SeeFeedbacks is meant only for FCG admins.
                                    isAuthorized('license_management', 'update_license')
                                    is used to check if a user role is high enough (= is FCG admin).
                                    At the moment, there is no direct way to check a user role.
                                    As a temporal fix, this method is used.
                                    The Better way would be to check if a user role is Admin or UberAdmin directly.*/}
                                <Button onClick={() => handleDialog('seeFeedbackDialogOpen')}>
                                    <Typography variant="caption" style={textStyle}>
                                        {translate('SEE_FEEDBACKS')}
                                    </Typography>
                                </Button>
                            </Grid>
                        ) : null}
                        <Grid item xs={6} sm={2} textAlign="center">
                            <Button onClick={() => handleDialog('feedbackDialogOpen')}>
                                <Typography variant="caption" style={textStyle}>
                                    {translate('FEEDBACK_TITLE')}
                                </Typography>
                            </Button>
                        </Grid>
                    </Grid>
                </Container>
                <SeeFeedbackDialog
                    isOpen={state.seeFeedbackDialogOpen}
                    close={() => handleDialog('seeFeedbackDialogOpen')}
                />
                <FeedbackDialog
                    open={state.feedbackDialogOpen}
                    onClose={() => handleDialog('feedbackDialogOpen')}
                    feedbackTypes={feedback.feedbackTypes}
                />
                <GeneralDialog
                    open={state.generalDialogOpen}
                    onClose={() => handleDialog('generalDialogOpen')}
                    content={state.generalDialogContent}
                />
            </AppBar>
        </Slide>
    );
}

export default observer(Footer);
