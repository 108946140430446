import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import useStore from '../../../store/storeContext';

interface Props {
    isOpen: boolean;
    close(): void;
}
function LanguageChangedDialog(props: Props) {
    const { localization, document } = useStore();

    useEffect(() => {
        props.isOpen && void document.reloadCurrentDocument();
    }, [props.isOpen]);

    const { translate } = localization;

    return (
        <Dialog
            open={props.isOpen}
            onClose={props.close}
            aria-labelledby="language-changed-dialog"
            maxWidth={'xs'}
            fullWidth
        >
            <DialogTitle>{translate('LANGUAGE')}</DialogTitle>
            <DialogContent dividers>
                <Box m={2}>
                    <Typography>{translate('INFORM_TOOL_LANGUAGE_CHANGE_MESSAGE')}</Typography>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={props.close}>
                    {translate('CLOSE')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default observer(LanguageChangedDialog);
