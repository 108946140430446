import React from 'react';
import { observer } from 'mobx-react-lite';
import {
    Box,
    Button,
    Card,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';
import { Delete } from '@mui/icons-material';
import DangerIconButton from '../../../styled/DangerIconButton';
import DangerButton from '../../../styled/DangerButton';
import moment from 'moment';
import useStore from '../../../store/storeContext';

interface Props {
    isOpen: boolean;
    close(): void;
}

function PreviousSharesDialog(props: Props) {
    const { document, dialog, localization, snackbar } = useStore();

    async function remove(id: string): Promise<void> {
        try {
            const confirmation = await dialog.getConfirmation();
            if (!confirmation) return;

            await document.removeDocumentShare(id);
            snackbar.showSuccess();
        } catch (e) {
            snackbar.showError(e.data?.code);
        }
    }

    async function removeAll(): Promise<void> {
        try {
            const confirmation = await dialog.getConfirmation();
            if (!confirmation) return;

            await document.removeAllDocumentShares();
            snackbar.showSuccess();
        } catch (e) {
            snackbar.showError(e.data?.code);
        }
    }

    const { translate } = localization;

    return (
        <Dialog
            open={props.isOpen}
            onClose={props.close}
            aria-labelledby="previous-shares-dialog-title"
            maxWidth={'md'}
            fullWidth
        >
            <DialogTitle id="previous-shares-dialog-title">{translate('DOCUMENT_SHARE_TITLE')}</DialogTitle>
            <DialogContent dividers>
                <TableContainer component={Card} variant="outlined">
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>{translate('EMAIL')}</TableCell>
                                <TableCell>{translate('VALID_UNTIL')}</TableCell>
                                <TableCell>{translate('DOCUMENT_SHARED_DATA')}</TableCell>
                                <TableCell />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {document.previousDocumentShares.map((share) => (
                                <TableRow key={share.id}>
                                    <TableCell>{share.emailAddress}</TableCell>
                                    <TableCell>{moment(share.linkValid).format('DD.MM.YYYY')}</TableCell>
                                    <TableCell>{translate(share.sharedData)}</TableCell>
                                    <TableCell>
                                        <DangerIconButton onClick={(): Promise<void> => remove(share.id)}>
                                            <Delete />
                                        </DangerIconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={props.close}>
                    {translate('CLOSE')}
                </Button>
                <DangerButton
                    variant="contained"
                    onClick={removeAll}
                    disabled={document.previousDocumentShares.length === 0}
                    startIcon={<Delete />}
                >
                    {translate('REMOVE_ALL')}
                </DangerButton>
                <Box flexGrow={1} />
            </DialogActions>
        </Dialog>
    );
}

export default observer(PreviousSharesDialog);
