import React from 'react';
import { styled } from '@mui/material/styles';
import Button, { ButtonProps } from '@mui/material/Button';

/**Material-UI button that implements SHVK theming */
const ShvkButton = styled((props: ButtonProps) => <Button {...props} variant="contained" />)(({ theme }) => ({
    color: theme.palette.common.white,
    background: theme.palette.info.main,
    '&:hover': {
        background: theme.palette.info.light,
    },
    '&:focus': {
        background: theme.palette.info.light,
    },
}));

export default ShvkButton;
