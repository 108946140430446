import React, { useState, useEffect, ChangeEvent } from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Paper, TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Delete } from '@mui/icons-material';
import { ChapterNestableItem, ChapterRenderItemArgs } from '../../types/nestable';
import DangerIconButton from '../../styled/DangerIconButton';
import useStore, { rootModule } from '../../store/storeContext';

const PREFIX = 'ChapterNestableComponent';

const classes = {
    paper: `${PREFIX}-paper`,
    depth1: `${PREFIX}-depth1`,
    depth2: `${PREFIX}-depth2`,
    depth3: `${PREFIX}-depth3`,
    depth4: `${PREFIX}-depth4`,
    chapterNumber: `${PREFIX}-chapterNumber`,
};

const { theming } = rootModule;

const StyledPaper = styled(Paper)(({ theme }) => ({
    [`&.${classes.paper}`]: {
        position: 'relative',
        padding: theme.spacing(0.5),
        cursor: 'pointer',
    },

    [`&.${classes.depth1}`]: {
        backgroundColor: theming.shvkBlueGray60,
    },

    [`&.${classes.depth2}`]: {
        paddingTop: theme.spacing(0.25),
        paddingBottom: theme.spacing(0.25),
        backgroundColor: theming.shvkBlueGray40,
    },

    [`&.${classes.depth3}`]: {
        paddingTop: theme.spacing(0.25),
        paddingBottom: theme.spacing(0.25),
        backgroundColor: theming.shvkBlueGray20,
    },

    [`&.${classes.depth4}`]: {
        paddingTop: theme.spacing(0.25),
        paddingBottom: theme.spacing(0.25),
        backgroundColor: theming.shvkBlueGray10,
    },

    [`& .${classes.chapterNumber}`]: {
        marginLeft: theme.spacing(0.5),
        marginRight: theme.spacing(1),
    },
}));

interface Props {
    args: ChapterRenderItemArgs;
    handleTextChange(value: string, id: number): void;
    removeItem(item: ChapterNestableItem): void;
    save(): void;
    chapterNumber(chapter: ChapterNestableItem): string;
}

interface State {
    editing: boolean;
    text: string;
}

function ChapterNestableComponent(props: Props) {
    const { dialog, localization } = useStore();

    const [state, setState] = useState<State>({
        editing: false,
        text: '',
    });

    useEffect(() => {
        if (state.editing) {
            props.handleTextChange(state.text, props.args.item.id);
        }
    }, [state.text]);

    const toggleEdit = (): void => {
        setState((state) => ({
            ...state,
            editing: !state.editing,
            text: props.args.item.text,
        }));
    };

    const onChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
        setState((state) => ({
            ...state,
            text: event.target.value,
        }));
    };

    async function remove(): Promise<void> {
        const { translate } = localization;
        let confirmation = false;
        if (props.args.item.isIndicatorArea || props.args.item.isFocusArea) {
            const confirmDialogTitle = translate('CHAPTER_REMOVE');
            const confirmDialogText = props.args.item.isIndicatorArea
                ? translate('CONFIRM_INDICATOR_AREA_REMOVAL_SUBTITLE')
                : props.args.item.isFocusArea
                ? translate('CONFIRM_FOCUS_AREA_REMOVAL_SUBTITLE')
                : translate('CHAPTER_REMOVE_SUBTITLE');
            confirmation = await dialog.getConfirmation(confirmDialogTitle, confirmDialogText, true);
        } else {
            confirmation = await dialog.getConfirmation();
        }
        if (!confirmation) return;

        props.removeItem(props.args.item);
    }

    const { depth, isRemovable, children, text } = props.args.item;

    return (
        <StyledPaper
            variant="outlined"
            className={`${classes.paper} ${
                depth === 1
                    ? classes.depth1
                    : depth === 2
                    ? classes.depth2
                    : depth === 3
                    ? classes.depth3
                    : classes.depth4
            }`}
        >
            <Box display="flex" alignItems="center">
                {props.args.collapseIcon}
                <Typography className={classes.chapterNumber}>{props.chapterNumber(props.args.item)}</Typography>
                {state.editing ? (
                    <TextField
                        autoFocus
                        fullWidth
                        variant="outlined"
                        size="small"
                        onChange={onChange}
                        value={state.text}
                        onBlur={toggleEdit}
                        onKeyDown={(event) => {
                            if (event.key === 'Enter') toggleEdit();
                        }}
                    />
                ) : (
                    <Typography onClick={toggleEdit}>{text || ' - '}</Typography>
                )}
                <Box flexGrow={1} />
                {isRemovable && (
                    <DangerIconButton size="small" onClick={remove} disabled={children.length > 0}>
                        <Delete />
                    </DangerIconButton>
                )}
            </Box>
        </StyledPaper>
    );
}

export default observer(ChapterNestableComponent);
