import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Button, ButtonGroup, Grid, Hidden, IconButton, Paper, Tooltip, Typography } from '@mui/material';
import { ArrowDropDown, Delete, Edit, Info, List, LockOpen } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { TranslationKeys } from '../../types/translation';
import { ButtonEvent, TextFieldEvent } from '../../types/events';
import DocumentOptionsPopover from './DocumentOptionsPopover';
import FroalaCoverPage from './editor/FroalaCoverPage';
import InfoIcon from '@mui/icons-material/Info';
import ShvkTextField from '../../styled/ShvkTextField';
import ShvkButton from '../../styled/ShvkButton';
import DocumentChapters from './DocumentChapters';
import useStore from '../../store/storeContext';

const PREFIX = 'EditorView';

const classes = {
    paper: `${PREFIX}-paper`,
    documentChapters: `${PREFIX}-documentChapters`,
};

const StyledPaper = styled(Paper)(({ theme }) => ({
    [`&.${classes.paper}`]: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(3),
    },

    [`& .${classes.documentChapters}`]: {
        marginTop: theme.spacing(8),
    },
}));

interface Props {
    toggleSideNav(): void;
}

type StatusInterval = ReturnType<typeof setInterval> | undefined;

interface State {
    editingCoverPage: boolean;
    documentOptionsMenu: HTMLElement | null;
    isIntervalSet: boolean;
}

function EditorView(props: Props) {
    const { document, dialog, localization, theming, snackbar, loadingIndicator } = useStore();

    const [state, setState] = useState<State>({
        editingCoverPage: false,
        documentOptionsMenu: null,
        isIntervalSet: false,
    });

    useEffect(() => {
        let statusInterval: StatusInterval = undefined;
        if (!state.isIntervalSet) {
            statusInterval = setInterval(() => {
                void document.chaptersStatus();
            }, 30000);
            setState((state) => ({ ...state, isIntervalSet: true }));
        }
        return () => {
            clearInterval(statusInterval);
            setState((state) => ({ ...state, isIntervalSet: false }));
        };
    }, []);

    async function deleteDocument(): Promise<void> {
        const { translate } = localization;
        const { currentDocument } = document;

        try {
            const title = translate('DOCUMENT_REMOVE');
            const text =
                translate('DOCUMENT_TO_BE_REMOVED') +
                ': ' +
                currentDocument.name +
                '\n\n' +
                translate('DOCUMENT_REMOVE_SUBTITLE') +
                '\n\n' +
                translate('DOCUMENT_REMOVE_SUBTITLE_2');

            const confirmation = await dialog.getConfirmation(title, text, true);
            if (!confirmation) return;

            loadingIndicator.show();

            await document.removeCurrentDocument();
            snackbar.showSuccess();
            await document.closeCurrentDocument();
        } catch (e) {
            snackbar.showError(e.data?.code);
        } finally {
            loadingIndicator.hide();
        }
    }

    async function deleteCoverPage(): Promise<void> {
        try {
            const confirmation = await dialog.getConfirmation();
            if (!confirmation) return;

            document.changeActiveDocumentCoverPageText(null);
            await document.saveCurrentDocument();
        } catch (error) {
            snackbar.showError(error.data?.code);
        }
    }

    const handleDocumentMenuOpen = (event: ButtonEvent): void => {
        setState((state) => ({ ...state, documentOptionsMenu: event.currentTarget }));
    };

    const handleDocumentMenuClose = (): void => {
        setState((state) => ({ ...state, documentOptionsMenu: null }));
    };

    const handleChange = (event: TextFieldEvent): void => {
        document.changeActiveDocumentName(event.target.value);
    };

    const { currentDocument, isEvaDocument, isAnotherReport } = document;
    const { translate } = localization;

    return (
        <StyledPaper className={classes.paper}>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Hidden mdDown>
                        <IconButton onClick={props.toggleSideNav} size="large">
                            <List color="primary" fontSize="large" />
                        </IconButton>
                    </Hidden>
                </Grid>
                <Grid item xs={6}>
                    <Tooltip title={translate('dokumentti_ylaosa_4')}>
                        <Info color="primary" />
                    </Tooltip>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="subtitle2">{currentDocument.organization.name}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Box display="flex">
                        <Box flexGrow={1} mr={2}>
                            <ShvkTextField
                                fullWidth
                                variant="outlined"
                                size="small"
                                placeholder={translate('DOCUMENT_TITLE_PLACEHOLDER')}
                                onChange={(event) => {
                                    handleChange(event);
                                }}
                                onBlur={() => {
                                    void document.saveCurrentDocument();
                                }}
                                value={currentDocument.name}
                                disabled={document.isCurrentDocumentApproved}
                            />
                        </Box>
                        <ShvkButton
                            endIcon={<ArrowDropDown />}
                            onClick={handleDocumentMenuOpen}
                            aria-controls="document-options-popover"
                            aria-haspopup="true"
                        >
                            {translate('DOCUMENT_OPTIONS')}
                        </ShvkButton>
                        <DocumentOptionsPopover
                            anchorEl={state.documentOptionsMenu}
                            handleClose={handleDocumentMenuClose}
                            deleteDocument={deleteDocument}
                            isEditor={true}
                        />
                    </Box>
                </Grid>
                {!isAnotherReport && (
                    <Grid item xs={12}>
                        <Typography>
                            {translate('DOCUMENT_STATUS')}:{' '}
                            {translate(currentDocument.status.code as keyof TranslationKeys)}
                        </Typography>
                        {!isEvaDocument(currentDocument) && (
                            <Typography>
                                {translate('OFFICIAL_DOCUMENT')}: {translate(currentDocument.isOfficial ? 'YES' : 'NO')}
                                <Tooltip
                                    placement="right-start"
                                    title={translate(
                                        currentDocument.organization.isRegional
                                            ? 'OFFICIAL_DOCUMENT_TIP_FOR_REGION'
                                            : 'OFFICIAL_DOCUMENT_TIP_FOR_COUNTY',
                                    )}
                                >
                                    <InfoIcon
                                        color="primary"
                                        style={{
                                            fontSize: 'larger',
                                            display: 'inline-block',
                                            verticalAlign: 'middle',
                                            paddingBottom: '2px',
                                            marginLeft: theming.spacing(0.6),
                                        }}
                                    />
                                </Tooltip>
                            </Typography>
                        )}
                    </Grid>
                )}
                {currentDocument.coverPageText && (
                    <Grid item xs={12}>
                        <Box display="flex" mb={2}>
                            <Box flexGrow={1} />
                            {!document.isCurrentDocumentApproved && (
                                <>
                                    {state.editingCoverPage && (
                                        <Tooltip placement="left-end" title={translate('COVERPAGE_FROALA_TOOLTIP')}>
                                            <InfoIcon
                                                color="primary"
                                                style={{
                                                    display: 'inline-block',
                                                    verticalAlign: 'middle',
                                                    marginTop: theming.spacing(0.6),
                                                    marginRight: theming.spacing(1),
                                                }}
                                            />
                                        </Tooltip>
                                    )}
                                    <ButtonGroup color="primary" size="small" style={{ maxHeight: '2rem' }}>
                                        <Button
                                            onClick={() =>
                                                setState((state) => ({
                                                    ...state,
                                                    editingCoverPage: !state.editingCoverPage,
                                                }))
                                            }
                                        >
                                            {!state.editingCoverPage ? (
                                                <Tooltip title={translate('EDIT_COVERPAGE_TEXT_TOOLTIP')}>
                                                    <Edit />
                                                </Tooltip>
                                            ) : (
                                                <LockOpen color={'error'} />
                                            )}
                                        </Button>
                                        <Button onClick={deleteCoverPage}>
                                            <Delete />
                                        </Button>
                                    </ButtonGroup>
                                </>
                            )}
                        </Box>
                        <Box display="block">
                            {state.editingCoverPage ? (
                                <FroalaCoverPage />
                            ) : (
                                <Typography
                                    className="fr-view"
                                    component="div"
                                    dangerouslySetInnerHTML={{ __html: currentDocument.coverPageText }}
                                />
                            )}
                        </Box>
                    </Grid>
                )}
                <Grid item xs={12} className={classes.documentChapters}>
                    <DocumentChapters />
                </Grid>
                <Grid item xs={12}>
                    <Box display="flex">
                        <Box flexGrow={1} />
                        <ShvkButton
                            endIcon={<ArrowDropDown />}
                            onClick={handleDocumentMenuOpen}
                            aria-controls="document-options-popover"
                            aria-haspopup="true"
                        >
                            {translate('DOCUMENT_OPTIONS')}
                        </ShvkButton>
                    </Box>
                </Grid>
            </Grid>
        </StyledPaper>
    );
}

export default observer(EditorView);
