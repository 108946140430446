import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Alert, Backdrop, CircularProgress, Snackbar, ThemeProvider, StyledEngineProvider } from '@mui/material';
import PublishedOrganizationDocumentsDialog from './components/welcome/PublishedOrganizationDocumentsDialog';
import LanguageChangedDialog from './components/document/dialogs/LanguageChangedDialog';
import AuthenticatedRoute from './routes/AuthenticatedRoute';
import VersionInfoDialog from './components/document/dialogs/VersionInfoDialog';
import ConfirmDialog from './components/ConfirmDialog';
import LoginDialog from './components/header/LoginDialog';
import AllRoutes from './routes/routes';
import UserBar from './components/header/UserBar';
import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import './overrides.css';
import useStore from './store/storeContext';

function App() {
    const { document, user, theming, snackbar, loadingIndicator, dialog, session } = useStore();

    const navigate = useNavigate();

    useEffect(() => {
        async function checkSession() {
            await session.getInitialAppData();
            if (session.parameters.authToken) {
                try {
                    await user.fetchUserDetails();
                } catch (e) {
                    if (e.status === 401) {
                        session.clearSessionData();
                        navigate('/');
                    }
                }
            }
        }

        void checkSession();

        window.addEventListener('beforeunload', async (event) => {
            try {
                if (document.isChapterLocked) {
                    event.preventDefault();
                    await document.releaseChapterLocks();
                }
            } catch (e) {
                return;
            }
        });
    }, []);

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theming.theme}>
                <AuthenticatedRoute />
                <Header />
                {user.isUserLoggedIn && <UserBar />}
                <div style={{ flex: '1 0 auto', backgroundColor: theming.shvkPetrol10 }}>
                    <AllRoutes />
                </div>
                <Footer />
                <Snackbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    open={snackbar.isOpen}
                    autoHideDuration={6000}
                    onClose={snackbar.close}
                >
                    <Alert severity={snackbar.severity} variant="filled">
                        {snackbar.message}
                    </Alert>
                </Snackbar>
                <Backdrop
                    style={{ color: 'white', zIndex: theming.theme.zIndex.tooltip + 1 }}
                    open={loadingIndicator.isLoading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <ConfirmDialog open={dialog.isDeleteDialogOpen} />
                <LoginDialog open={dialog.isLoginDialogOpen} />
                <PublishedOrganizationDocumentsDialog
                    open={dialog.isPublishedOrganizationDocumentsDialogOpen}
                    onClose={dialog.closePublishedOrganizationDocumentsDialog}
                />
                <VersionInfoDialog isOpen={dialog.isVersionInfoDialogOpen} close={dialog.closeVersionInfoDialog} />
                <LanguageChangedDialog
                    isOpen={dialog.isLanguageChangedDialogOpen}
                    close={dialog.closeLanguageChangedDialog}
                />
            </ThemeProvider>
        </StyledEngineProvider>
    );
}

export default observer(App);
