import React, { useEffect, useState } from 'react';
import { observer, useLocalObservable } from 'mobx-react-lite';
import {
    Autocomplete,
    Box,
    CircularProgress,
    Container,
    Divider,
    FormLabel,
    Grid,
    Paper,
    Pagination,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import { Check } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { AdminOrganization } from '../types/organization';
import { UserManagementUser } from '../types/user';
import ShvkTextField from '../styled/ShvkTextField';
import ShvkButton from '../styled/ShvkButton';
import EmailGroupListDialog from '../components/emailGroup/EmailGroupListDialog';
import InviteUserDialog from '../components/user/InviteUserDialog';
import AddUserDialog from '../components/user/AddUserDialog';
import useStore from '../store/storeContext';

const PREFIX = 'UserManagement';

const classes = {
    table: `${PREFIX}-table`,
    cell: `${PREFIX}-cell`,
    row: `${PREFIX}-row`,
};

const Root = styled('div')(({ theme }) => ({
    [`& .${classes.table}`]: {
        tableLayout: 'fixed',
        marginTop: theme.spacing(4),
    },

    [`& .${classes.cell}`]: {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    },

    [`& .${classes.row}`]: {
        cursor: 'pointer',
    },
}));

type OrganizationFilterInput = {
    value: AdminOrganization | null;
    inputValue: string;
};
type UserFilterInput = {
    value: UserManagementUser | null;
    inputValue: string;
};

interface State {
    fetchingOrganizations: boolean;
    fetchingUsers: boolean;
    selectedUser: UserManagementUser | null;
    userDialogOpen: boolean;
    emailGroupDialogOpen: boolean;
    inviteUserDialogOpen: boolean;
    usernameFilter: UserFilterInput;
}

interface StoreState {
    page: number;
    pageCount: number;
    pageContent: UserManagementUser[];
    organizationFilter: OrganizationFilterInput;
    filteredUsers: UserManagementUser[];
    handlePageChange: <T>(e: React.ChangeEvent<T>, page: number) => void;
    handleOrganizationFilterValueChange: (value: AdminOrganization | null) => void;
    handleOrganizationFilterInputChange: (input: string) => void;
}

const itemsPerPage: Readonly<number> = 10;

function UserManagement() {
    const { user, localization, snackbar, organization } = useStore();

    const [state, setState] = useState<State>({
        fetchingOrganizations: false,
        fetchingUsers: false,
        selectedUser: null,
        userDialogOpen: false,
        emailGroupDialogOpen: false,
        inviteUserDialogOpen: false,
        usernameFilter: {
            value: null,
            inputValue: '',
        },
    });

    const localStore = useLocalObservable<StoreState>(() => ({
        page: 1,
        organizationFilter: {
            value: null,
            inputValue: '',
        },

        get pageCount(): number {
            return Math.ceil(this.filteredUsers.length / itemsPerPage);
        },

        get pageContent(): UserManagementUser[] {
            const pageFirstIndex = (this.page - 1) * itemsPerPage;
            return this.filteredUsers.slice(pageFirstIndex, pageFirstIndex + itemsPerPage);
        },

        get filteredUsers(): UserManagementUser[] {
            const filterOrg = this.organizationFilter.value;
            const values = user.userManagementUsers.slice().sort((a, b) => {
                if (a.enabled > b.enabled) {
                    return -1;
                } else if (a.enabled < b.enabled) {
                    return 1;
                } else {
                    if (a.lastName.toLowerCase() > b.lastName.toLowerCase()) {
                        return 1;
                    } else if (a.lastName.toLowerCase() < b.lastName.toLowerCase()) {
                        return -1;
                    } else {
                        if (a.firstName.toLowerCase() > b.firstName.toLowerCase()) {
                            return 1;
                        } else if (a.firstName.toLowerCase() < b.firstName.toLowerCase()) {
                            return -1;
                        } else {
                            return 0;
                        }
                    }
                }
            });
            if (!filterOrg) return values;
            return values.filter((value) => value.organizations.some((org) => org.id === filterOrg.id));
        },

        handlePageChange(_event: React.ChangeEvent<unknown>, value: number): void {
            this.page = value;
        },

        handleOrganizationFilterValueChange(value: AdminOrganization | null): void {
            this.organizationFilter.value = value;
            this.page = 1;
        },

        handleOrganizationFilterInputChange(input: string): void {
            this.organizationFilter.inputValue = input;
        },
    }));

    useEffect((): void => {
        if (!user.userManagementUsers.length) {
            void getData();
        }
    }, []);

    async function getData(): Promise<void> {
        setState((state) => ({ ...state, fetchingOrganizations: true, fetchingUsers: true }));
        try {
            await user.fetchAllUserManagementOrganizationsAndUsers();
        } catch {
            snackbar.showFetchFailedMessage();
        } finally {
            setState((state) => ({ ...state, fetchingUsers: false, fetchingOrganizations: false }));
        }
    }

    const openUserDialog = (user: UserManagementUser | null): void => {
        setState((state) => ({ ...state, userDialogOpen: true, selectedUser: user }));
    };

    const closeUserDialog = (): void => {
        setState((state) => ({ ...state, userDialogOpen: false, selectedUser: null }));
    };

    const toggleEmailGroupDialog = (): void => {
        setState((state) => ({ ...state, emailGroupDialogOpen: !state.emailGroupDialogOpen }));
    };

    const toggleInviteUserDialog = (): void => {
        setState((state) => ({ ...state, inviteUserDialogOpen: !state.inviteUserDialogOpen }));
    };

    const handleUserFilterChange = (value: UserManagementUser | null, input?: string): void => {
        setState((state) => ({ ...state, usernameFilter: { value: value || null, inputValue: input || '' } }));
    };

    const { translate } = localization;

    return (
        <Root>
            <Container>
                <Box my={3}>
                    <Paper>
                        <Box px={2} py={1} textAlign="center">
                            <Typography variant="h6">{translate('USER_MANAGEMENT')}</Typography>
                        </Box>
                        <Divider />
                        <Box m={2}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <FormLabel component="legend">{translate('FILTERING')}</FormLabel>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Autocomplete
                                        value={localStore.organizationFilter.value}
                                        onChange={(_event, newValue: AdminOrganization | null): void => {
                                            localStore.handleOrganizationFilterValueChange(newValue);
                                        }}
                                        inputValue={localStore.organizationFilter.inputValue}
                                        onInputChange={(_event, newInputValue): void => {
                                            localStore.handleOrganizationFilterInputChange(newInputValue);
                                        }}
                                        options={organization.adminOrganizations}
                                        getOptionLabel={(option): string => option.name}
                                        renderOption={(props: object, option): JSX.Element => (
                                            <li {...props} key={option.id}>
                                                {option.name}
                                            </li>
                                        )}
                                        fullWidth
                                        size="small"
                                        loading={state.fetchingOrganizations}
                                        renderInput={(params): JSX.Element => (
                                            <ShvkTextField
                                                {...params}
                                                label={translate('ORGANIZATION')}
                                                variant="outlined"
                                                placeholder={translate('SEARCH_WITH_NAME')}
                                                InputProps={{
                                                    ...params.InputProps,
                                                    endAdornment: (
                                                        <React.Fragment>
                                                            {state.fetchingOrganizations && (
                                                                <CircularProgress size={20} />
                                                            )}
                                                            {params.InputProps.endAdornment}
                                                        </React.Fragment>
                                                    ),
                                                }}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Autocomplete
                                        value={state.usernameFilter.value}
                                        onChange={(_event, newValue: UserManagementUser | null): void => {
                                            handleUserFilterChange(newValue);
                                            if (newValue) openUserDialog(newValue);
                                        }}
                                        inputValue={state.usernameFilter.inputValue}
                                        onInputChange={(_event, newInputValue): void => {
                                            handleUserFilterChange(null, newInputValue);
                                        }}
                                        options={user.userManagementUsers}
                                        getOptionLabel={(option): string => option.userName}
                                        fullWidth
                                        size="small"
                                        loading={state.fetchingUsers}
                                        renderInput={(params): JSX.Element => (
                                            <ShvkTextField
                                                {...params}
                                                label={translate('USERNAME')}
                                                variant="outlined"
                                                placeholder={translate('USER_SEARCH_TIP')}
                                                InputProps={{
                                                    ...params.InputProps,
                                                    endAdornment: (
                                                        <React.Fragment>
                                                            {state.fetchingUsers && <CircularProgress size={20} />}
                                                            {params.InputProps.endAdornment}
                                                        </React.Fragment>
                                                    ),
                                                }}
                                            />
                                        )}
                                    />
                                </Grid>
                            </Grid>
                            {state.fetchingUsers || state.fetchingOrganizations ? (
                                <Box textAlign="center" m={2}>
                                    <CircularProgress />
                                </Box>
                            ) : (
                                <Table aria-label={translate('DOCUMENTS_TITLE')} className={classes.table} size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>{translate('FIRSTNAME')}</TableCell>
                                            <TableCell>{translate('LASTNAME')}</TableCell>
                                            <TableCell>{translate('USERNAME')}</TableCell>
                                            <TableCell>{translate('HOME_ORGANIZATION')}</TableCell>
                                            <TableCell align="center" style={{ width: 100 }}>
                                                {translate('ACTIVATED')}
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {localStore.pageContent.map((user) => (
                                            <TableRow
                                                key={user.id}
                                                className={classes.row}
                                                hover
                                                onClick={(): void => openUserDialog(user)}
                                            >
                                                <TableCell className={classes.cell}>{user.firstName}</TableCell>
                                                <TableCell className={classes.cell}>{user.lastName}</TableCell>
                                                <TableCell className={classes.cell}>{user.userName}</TableCell>
                                                <TableCell className={classes.cell}>{user.homeOrganization}</TableCell>
                                                <TableCell padding="none" align="center" className={classes.cell}>
                                                    {user.enabled && <Check />}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            )}
                        </Box>
                        <Box px={2} pb={2} display="flex" flexWrap="wrap" justifyContent="flex-end">
                            <Box mr={2} flexGrow={1} flexShrink={0} mb={2}>
                                {!state.fetchingUsers || !state.fetchingOrganizations ? (
                                    <Pagination
                                        count={localStore.pageCount}
                                        page={localStore.page}
                                        onChange={localStore.handlePageChange}
                                        color="primary"
                                    />
                                ) : null}
                            </Box>
                            <Box mr={2} mb={2} flexShrink={0}>
                                <ShvkButton onClick={toggleEmailGroupDialog} disabled={state.fetchingUsers}>
                                    {translate('USER_MANAGEMENT_CREATE_EMAIL_GROUP')}
                                </ShvkButton>
                            </Box>
                            <Box mr={2} mb={2} flexShrink={0}>
                                <ShvkButton disabled={state.fetchingOrganizations} onClick={toggleInviteUserDialog}>
                                    {translate('CALL_USER_TO_ORGANIZATION_BUTTON')}
                                </ShvkButton>
                            </Box>
                            <Box mb={2} flexShrink={0}>
                                <ShvkButton onClick={(): void => openUserDialog(null)}>
                                    {translate('CREATE_USERNAME')}
                                </ShvkButton>
                            </Box>
                        </Box>
                    </Paper>
                </Box>
            </Container>
            <EmailGroupListDialog open={state.emailGroupDialogOpen} onClose={toggleEmailGroupDialog} />
            <InviteUserDialog open={state.inviteUserDialogOpen} onClose={toggleInviteUserDialog} />
            <AddUserDialog open={state.userDialogOpen} onClose={closeUserDialog} user={state.selectedUser} />
        </Root>
    );
}

export default observer(UserManagement);
