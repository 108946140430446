import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Table,
    TableBody,
    Pagination,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { SeeFeedback } from '../../types/feedback';
import SeeFeedbackDialogRow from './SeeFeedbackDialogRow';
import useStore from '../../store/storeContext';

const PREFIX = 'SeeFeedbackDialog';

const classes = {
    paper: `${PREFIX}-paper`,
    subTitle: `${PREFIX}-subTitle`,
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
    [`& .${classes.paper}`]: {
        minHeight: '450px',
    },

    [`& .${classes.subTitle}`]: {
        marginBottom: theme.spacing(2),
    },
}));

interface Props {
    isOpen: boolean;
    close(): void;
}

interface State {
    page: number;
}

const itemsPerPage: Readonly<number> = 5;

function SeeFeedbackDialog(props: Props) {
    const { feedback, localization, loadingIndicator, snackbar } = useStore();

    const [state, setState] = useState<State>({
        page: 1,
    });

    useEffect(() => {
        props.isOpen && getData();
    }, [props.isOpen]);

    async function getData(): Promise<void> {
        try {
            loadingIndicator.show();
            await feedback.fetchFeedbacks();
        } catch (e) {
            snackbar.showFetchFailedMessage(e.data?.code);
        } finally {
            loadingIndicator.hide();
        }
    }

    function handlePageChange(_event: React.ChangeEvent<unknown>, value: number): void {
        setState((state) => ({ ...state, page: value }));
    }

    function pageContent(): SeeFeedback[] {
        const pageFirstIndex = (state.page - 1) * itemsPerPage;
        return feedback.feedbacks.slice(pageFirstIndex, pageFirstIndex + itemsPerPage);
    }

    function pageCount(): number {
        return Math.ceil(feedback.feedbacks.length / itemsPerPage);
    }

    const { translate } = localization;
    const { isOpen, close } = props;

    return (
        <StyledDialog
            open={isOpen}
            onClose={close}
            aria-labelledby="see-feedback-dialog"
            maxWidth={'md'}
            fullWidth
            classes={{
                paper: classes.paper,
            }}
        >
            <DialogTitle id="see-feedback-dialog-dialog-title">{translate('SEE_FEEDBACKS')}</DialogTitle>
            <DialogContent dividers>
                <Table>
                    <TableBody>
                        {pageContent().map((feedback) => (
                            <SeeFeedbackDialogRow key={feedback.id} feedback={feedback} />
                        ))}
                    </TableBody>
                </Table>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={close}>
                    {translate('CANCEL')}
                </Button>
                <Box flexGrow={1} />
                <Pagination count={pageCount()} page={state.page} onChange={handlePageChange} color="primary" />
            </DialogActions>
        </StyledDialog>
    );
}

export default observer(SeeFeedbackDialog);
