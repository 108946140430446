import React from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import moment from 'moment';
import useStore from '../../store/storeContext';

interface Props {
    isOpen: boolean;
    close(): void;
    endDate: string | number | Date | null | undefined;
}

function LicenseExpiredDialog(props: Props) {
    const context = useStore();
    const { localization } = context;

    const { contact } = context.contact;
    const { translate } = localization;

    return (
        <Dialog
            open={props.isOpen}
            onClose={props.close}
            aria-labelledby="license-expired-dialog"
            maxWidth={'sm'}
            fullWidth
        >
            <DialogTitle id="license-expired-dialog-title">{translate('LICENSE')}</DialogTitle>
            <DialogContent dividers>
                <Box my={2}>
                    <Typography>
                        {translate('ORGANIZATION_LICENSE_EXPIRED_MESSAGE') + ' '}
                        <b>{moment(props.endDate).format('DD.MM.YYYY')}</b>.
                    </Typography>
                </Box>
                <Box mt={3}>
                    <Typography>{translate('LICENSE_DETAILS_INFO')}:</Typography>
                </Box>
                <Box my={2}>
                    <Typography variant="subtitle2">
                        {translate('NAME') + ': ' + contact?.name + ', ' + contact?.address}
                    </Typography>
                    <Typography variant="subtitle2">{translate('EMAIL') + ': ' + contact?.email}</Typography>
                    <Typography variant="subtitle2">{translate('PHONE') + ': ' + contact?.phone}</Typography>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.close} variant="contained">
                    {translate('CLOSE')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default observer(LicenseExpiredDialog);
