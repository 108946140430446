import React from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Grid, Typography } from '@mui/material';
import { Indicator, IndicatorData } from '../../../types/indicator';
import useStore from '../../../store/storeContext';

interface Props {
    indicator: Indicator;
    origoOrganization?: string;
}

type Circle = {
    x: number;
    y: number;
    r: number;
};

type Line = {
    x1: number;
    y1: number;
    x2: number;
    y2: number;
};

type SvgData = {
    lines: Line[];
    circles: Circle[];
    texts: { value: string; year: string }[];
    color: string;
};

type Options = {
    showLastYearsCount: number;
    xPositions: Array<number>;
    yPositions: { min: number; max: number };
    circleRadius: number;
};

const options: Readonly<Options> = {
    showLastYearsCount: 3,
    xPositions: [25, 70, 115], // max three points - fixed x-coordinates for points
    yPositions: { min: 50, max: 10 },
    circleRadius: 3,
};

function IndicatorDirection(props: Props) {
    const context = useStore();

    const svgData = getSvgData();

    function getSvgData(): SvgData {
        const svgData: SvgData = {
            circles: [],
            color: '',
            lines: [],
            texts: [],
        };
        const data = filterData();
        if (data.length === 0) return svgData;

        const min = Math.min(...data.map((dataItem) => dataItem.value));
        const max = Math.max(...data.map((dataItem) => dataItem.value));
        const svgDrawHeight = options.yPositions.min - options.yPositions.max;

        svgData.color = getColor(data);

        svgData.texts = data.map((dataItem) => {
            return {
                value: dataItem.value.toString(),
                year: dataItem.year.toString(),
            };
        });

        // draw points
        for (let i = 0; i < data.length; i++) {
            const item = data[i];
            const xPos = options.xPositions[i];
            let yPos;
            if (item.value === min) {
                yPos = options.yPositions.min;
            } else if (item.value === max) {
                yPos = options.yPositions.max;
            } else {
                // calculate
                const unitHeight = svgDrawHeight / (max - min);
                const tmp = unitHeight * (max - item.value);

                yPos = options.yPositions.max + tmp;
            }
            const circlePosition = { x: xPos, y: yPos, r: options.circleRadius };
            svgData.circles.push(circlePosition);
        }
        // draw lines between circles
        for (let j = 0; j < svgData.circles.length; j++) {
            if (j + 1 < svgData.circles.length) {
                // if there are more circles
                const linePosition = {
                    x1: svgData.circles[j].x,
                    y1: svgData.circles[j].y,
                    x2: svgData.circles[j + 1].x,
                    y2: svgData.circles[j + 1].y,
                };
                svgData.lines.push(linePosition);
            }
        }

        return svgData;
    }

    function filterData(): IndicatorData[] {
        const {
            comparisonSettings,
            removeDuplicatesFromIndicatorData,
            filterIndicatorDataBetweenCompareYears,
            filterCurrentOrganizationData,
        } = context.indicator;

        if (!comparisonSettings) return [];
        const { startYear, endYear } = comparisonSettings;
        if (!startYear || !endYear) return [];

        const organizationData = filterCurrentOrganizationData(props.indicator.data, props.origoOrganization);

        const uniques = removeDuplicatesFromIndicatorData(organizationData);
        const dataBetweenCompareYears = filterIndicatorDataBetweenCompareYears(uniques);

        let sorted = dataBetweenCompareYears.sort((a, b) => a.year - b.year);
        if (dataBetweenCompareYears.length > options.showLastYearsCount) {
            sorted = sorted.slice(sorted.length - 3, sorted.length);
        }

        return sorted;
    }

    function getColor(data: IndicatorData[]): string {
        const { palette, shvkYellow, shvkMediumGrey } = context.theming;
        const { indicator } = props;
        const { calculateChange, changeLimits } = context.indicator;

        const colorSchema = {
            blue: palette.warning.main,
            red: palette.error.main,
            yellow: shvkYellow,
            gray: shvkMediumGrey,
        };

        if (data.length < 2) {
            return colorSchema.gray;
        }

        const changeValue = calculateChange(indicator.shortTermChangeData).value;

        if (changeValue !== null && Math.abs(changeValue) >= changeLimits.smallChangeMin) {
            if ((changeValue > 0 && indicator.direction > 1) || (changeValue < 0 && indicator.direction < 1)) {
                return colorSchema.blue;
            } else if ((changeValue > 0 && indicator.direction < 1) || (changeValue < 0 && indicator.direction > 1)) {
                return colorSchema.red;
            }
        }

        return colorSchema.gray;
    }

    function getContainerStyle(): React.CSSProperties {
        const color = context.theming.shvkMediumGrey;

        return {
            border: '1px solid ' + color,
            width: '140px',
            height: '60px',
        };
    }

    const containerStyle = getContainerStyle();

    return (
        <Box display="flex" flexDirection="column" alignItems="center">
            <svg style={containerStyle}>
                {svgData.lines.map((line, i) => (
                    <line key={i} x1={line.x1} x2={line.x2} y1={line.y1} y2={line.y2} stroke={svgData.color} />
                ))}
                {svgData.circles.map((circle, i) => (
                    <circle key={i} cx={circle.x} cy={circle.y} r={circle.r} fill={svgData.color} />
                ))}
            </svg>
            <Grid container>
                {svgData.texts.map((dataItem) => (
                    <Grid item xs={4} key={dataItem.year}>
                        <Box display="flex" alignItems="center" flexDirection="column">
                            <Typography variant="caption">
                                <b>{dataItem.value}</b>
                            </Typography>

                            <Typography variant="caption">{dataItem.year}</Typography>
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
}

export default observer(IndicatorDirection);
