import React from 'react';
import { observer } from 'mobx-react-lite';
import { Indicator, IndicatorData } from '../../../types/indicator';
import { ComparisonSettings } from '../../../types/indicator';
import useStore from '../../../store/storeContext';

interface Props {
    indicator: Indicator;
    size?: 'sm' | 'md' | 'lg';
    origoOrganization?: string;
    genderData?: IndicatorData[] | undefined;
}

type DataItem = {
    organizationName: string;
    organizationShortName?: string;
    value: number;
    inIndicatorTops?: boolean;
};

type LegendText = {
    organization: string;
    value: string;
    x: number;
    y: number;
    fontWeight: string;
    inIndicatorTops?: boolean;
};

type Bar = {
    x1: number;
    x2: number;
    y1: number;
    y2: number;
    color: string;
    originalValue: number;
};

type SvgData = {
    bars: Bar[];
    legendTexts: LegendText[];
};

type SvgOptions = {
    svgWidth: number;
    legendWidth: number;
    graphWidth: number;
    legendXPos: number;
    zeroDividerPosition: number;
    legendDividerPosition: number;
    margins: { left: number; right: number; top: number; bottom: number };
    fontSize: number;
};

function IndicatorComparison(props: Props) {
    const context = useStore();
    const { document, theming } = context;

    const options = getOptions();
    const svgData = getSvgData();
    const svgHeight = getSvgHeight();

    function getOptions(): SvgOptions {
        let svgWidth = 450;
        let legendWidth = 190;

        if (props.size === 'md') {
            svgWidth = 350;
        } else if (props.size === 'sm') {
            svgWidth = 295;
            legendWidth = 160;
        }

        const graphWidth = svgWidth - legendWidth;
        const legendXPos = graphWidth;
        const zeroDividerPosition = graphWidth / 2;
        const legendDividerPosition = legendXPos;
        const margins = { left: 5, right: 5, top: 12, bottom: 5 };
        const fontSize = 12;

        return {
            svgWidth,
            legendWidth,
            graphWidth,
            legendXPos,
            zeroDividerPosition,
            legendDividerPosition,
            margins,
            fontSize,
        };
    }

    function getSvgData(): SvgData {
        const { genderData } = props;
        const { currentDocument } = document;
        const indicatorModule = context.indicator;

        const uniques = props.indicator?.data
            ? indicatorModule.removeDuplicatesFromIndicatorData(props.indicator.data)
            : [];
        const dataBetweenCompareYears = indicatorModule.filterIndicatorDataBetweenCompareYears(
            genderData ? genderData : uniques,
        );
        const organizationData = indicatorModule.groupIndicatorDataByOrganization(dataBetweenCompareYears);

        const dataItems: DataItem[] = [];

        organizationData.forEach((dataItem) => {
            const latestFirstArray = dataItem.data.sort((a, b) => b.year - a.year);

            // Select last valid year.
            for (const item of latestFirstArray) {
                if (item.year && item.value || item.year && item.value === 0) {
                    // Keep only the first valid item
                    dataItems.push({
                        organizationName: dataItem.data[0].organization.shortName || dataItem.organizationName,
                        value: item.value,
                        inIndicatorTops: item.inIndicatorTops,
                    });
                    break;
                }
            }
        });

        dataItems.sort((a, b) => a.value - b.value);

        const svgData: SvgData = {
            bars: [],
            legendTexts: [],
        };

        const organizationName =
            props.origoOrganization || currentDocument.organization?.shortName || currentDocument.organization.name;
        const origoItem = dataItems.find((dataItem) => dataItem.organizationName === organizationName) || null;
        if (!origoItem) return svgData;

        svgData.legendTexts = createLegendTexts(dataItems, origoItem);
        svgData.bars = createBars(dataItems, origoItem);
        return svgData;
    }

    function getSvgHeight(): number {
        const { fontSize, margins } = options;
        const { legendTexts } = svgData;

        const barHeight = fontSize || 12;
        const itemCount = legendTexts.length || 1;
        const itemHeight = barHeight + margins.bottom;
        const calculatedHeight = itemHeight * itemCount + margins.top;

        return calculatedHeight < 60 ? 60 : calculatedHeight;
    }

    function createLegendTexts(data: DataItem[], origoItem: DataItem): LegendText[] {
        const { margins, fontSize, legendXPos } = options;

        let yPos = margins.top + 2;
        const xPos = margins.right + legendXPos;

        return data.map((dataItem, i) => {
            if (i > 0) {
                yPos += fontSize + margins.bottom;
            }
            return {
                organization: dataItem.organizationName,
                value: dataItem.value.toString(),
                x: xPos,
                y: yPos,
                fontWeight: dataItem.organizationName === origoItem.organizationName ? 'bold' : 'normal',
                inIndicatorTops: dataItem.inIndicatorTops,
            };
        });
    }

    function createBars(data: DataItem[], origoItem: DataItem): Bar[] {
        const { margins, fontSize } = options;

        const maxDiff = getDataBoundary(data, origoItem);
        let yPos = margins.top;

        const bars: Bar[] = [];

        data.forEach((dataItem, i) => {
            if (i > 0) {
                yPos += fontSize + margins.bottom;
            }
            const bar = createRelativeGraphicData(yPos, dataItem, origoItem, maxDiff);
            bars.push(bar);
        });

        return bars;
    }

    function createRelativeGraphicData(yPos: number, dataItem: DataItem, origoItem: DataItem, maxDiff: number): Bar {
        const { graphWidth } = options;

        const barWidth = graphWidth / 2;
        let change = 0.0;

        const multiplier = getDifferenceMultiplier(origoItem.value, dataItem.value);
        if (
            dataItem.organizationName !== origoItem.organizationName &&
            dataItem.value !== origoItem.value &&
            multiplier < 10
        ) {
            const difference = dataItem.value - origoItem.value;
            change = (difference / maxDiff) * 100.0;
        }
        // relative size of the graphBar
        let graphBarWidth = barWidth * (change / 100.0);

        // if barWidth exceeds maximumWidth for the drawable graphicsBar, draw it as full!
        if (graphBarWidth > barWidth) {
            graphBarWidth = barWidth;
        }

        const xPos = graphWidth / 2;
        const x2Pos = xPos + graphBarWidth;
        const backgroundColor = getBarColor(dataItem.value, origoItem.value);

        return {
            x1: xPos,
            y1: yPos,
            x2: x2Pos,
            y2: yPos,
            color: backgroundColor,
            originalValue: dataItem.value,
        };
    }

    function getDataBoundary(data: DataItem[], origoItem: DataItem): number {
        let maxDifference = 0;
        data.forEach((dataItem) => {
            if (dataItem) {
                const tmp = Math.abs(dataItem.value - origoItem.value);
                const multiplier = getDifferenceMultiplier(origoItem.value, dataItem.value);

                if (tmp > maxDifference && multiplier < 10) {
                    maxDifference = tmp;
                }
            }
        });

        return maxDifference;
    }

    function getDifferenceMultiplier(origoValue: number, compareValue: number): number {
        const difference = Math.abs(compareValue - origoValue);
        let multiplier: number;
        if (origoValue > compareValue) {
            multiplier = difference / compareValue;
        } else {
            multiplier = difference / origoValue;
        }
        return multiplier;
    }

    function getBarColor(value: number, origoValue: number): string {
        // We can assume that comparison settings are never null here.
        const comparisonSettings = context.indicator.comparisonSettings as ComparisonSettings;
        const { palette, shvkYellow } = theming;
        const { indicator } = props;

        // TODO we may need gray schema.
        const colorSchema = {
            blue: palette.warning.main,
            red: palette.error.main,
            yellow: shvkYellow,
            gray: theming.shvkMediumGrey,
        };

        let color = colorSchema.gray;

        const change = Math.abs(origoValue - value);
        const lowLevelChange = origoValue !== 0 ? origoValue / 10 : 0;

        if (change < lowLevelChange && indicator.direction !== 1) {
            color = colorSchema.yellow;
        } else if (comparisonSettings.compareDirection === context.indicator.COMPARE_OTHERS_TO_ORGANIZATION) {
            if (value > origoValue) {
                if (indicator.direction > 1) {
                    color = colorSchema.blue;
                } else if (indicator.direction < 1) {
                    color = colorSchema.red;
                }
            } else if (value < origoValue) {
                if (indicator.direction > 1) {
                    color = colorSchema.red;
                } else if (indicator.direction < 1) {
                    color = colorSchema.blue;
                }
            }
        } else {
            if (value > origoValue) {
                if (indicator.direction > 1) {
                    color = colorSchema.red;
                } else if (indicator.direction < 1) {
                    color = colorSchema.blue;
                }
            } else if (value < origoValue) {
                if (indicator.direction > 1) {
                    color = colorSchema.blue;
                } else if (indicator.direction < 1) {
                    color = colorSchema.red;
                }
            }
        }
        return color;
    }

    function getContainerStyle(): React.CSSProperties {
        const { svgWidth } = options;
        return {
            border: '1px solid ' + theming.shvkMediumGrey,
            width: svgWidth,
            height: svgHeight,
        };
    }

    const { zeroDividerPosition, legendDividerPosition, fontSize } = options;

    return (
        <svg style={getContainerStyle()}>
            <line
                id="combGraphicZeroDivider"
                x1={zeroDividerPosition}
                y1="0"
                x2={zeroDividerPosition}
                y2={svgHeight}
                stroke={theming.shvkMediumGrey}
                strokeWidth="1"
            />
            <line
                id="combGraphicLegendDivider"
                x1={legendDividerPosition}
                y1="0"
                x2={legendDividerPosition}
                y2={svgHeight}
                stroke={theming.shvkMediumGrey}
                strokeWidth="1"
            />
            {svgData.legendTexts.map((legendText, i) => (
                <text key={i} x={legendText.x} y={legendText.y} fontSize={fontSize} fontWeight={legendText.fontWeight}>
                    {legendText.value} {legendText.organization} {legendText.inIndicatorTops && '★'}
                </text>
            ))}
            {svgData.bars.map((bar, i) => (
                <line
                    key={i}
                    x1={bar.x1}
                    x2={bar.x2}
                    y1={bar.y1}
                    y2={bar.y2}
                    stroke={bar.color}
                    strokeWidth={fontSize}
                />
            ))}
        </svg>
    );
}

export default observer(IndicatorComparison);
