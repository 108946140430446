import React from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Divider, Paper, Typography } from '@mui/material';
import useStore from '../../store/storeContext';

function Contact() {
    const { localization, contact } = useStore();

    const { translate } = localization;

    return (
        <Paper sx={{ mb: 3 }}>
            <Box px={2} py={1}>
                <Typography variant="h6" component="h4">
                    {translate('CONTACT_TITLE')}
                </Typography>
            </Box>
            <Divider />
            <Box p={2}>
                <Typography variant="body2" component="span">
                    {contact.contact?.name}
                    <br />
                    {contact.contact?.address}
                    <br />
                    {contact.contact?.email}
                    <br />
                    {contact.contact?.phone}
                </Typography>
            </Box>
        </Paper>
    );
}

export default observer(Contact);
