import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import {
    Box,
    CircularProgress,
    Container,
    Divider,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import LicenseSchema from '../types/license';
import LicenseItemDialog from '../components/admin/LicenseItemDialog';
import ShvkButton from '../styled/ShvkButton';
import useStore from '../store/storeContext';

interface State {
    dialogOpen: boolean;
    fetching: boolean;
    selectedItem: LicenseSchema | null;
}

function License() {
    const { admin, snackbar, localization, theming } = useStore();

    const [state, setState] = useState<State>({
        dialogOpen: false,
        fetching: false,
        selectedItem: null,
    });

    useEffect(() => {
        void getData();
    }, []);

    async function getData(): Promise<void> {
        setState((state) => ({ ...state, fetching: true }));
        try {
            await admin.fetchLicenseSchemas();
        } catch {
            snackbar.showFetchFailedMessage();
        } finally {
            setState((state) => ({ ...state, fetching: false }));
        }
    }

    const openDialog = (item: LicenseSchema | null): void => {
        setState((state) => ({ ...state, dialogOpen: true, selectedItem: item }));
    };

    const closeDialog = (): void => {
        setState((state) => ({ ...state, dialogOpen: false, selectedItem: null }));
    };

    const { translate } = localization;

    return (
        <>
            <Container>
                <Box my={3}>
                    <Paper>
                        <Box px={2} py={1} textAlign="center">
                            <Typography variant="h6">{translate('LICENSE_MANAGEMENT')}</Typography>
                        </Box>
                        <Divider />
                        <Box m={2}>
                            {state.fetching ? (
                                <Box textAlign="center">
                                    <CircularProgress />
                                </Box>
                            ) : (
                                <>
                                    <Typography style={{ marginBottom: theming.spacing(4) }}>
                                        {translate('LICENSE_TABLE_DESCRIPTION')}
                                    </Typography>
                                    <Table aria-label={translate('LICENSE_MANAGEMENT')} size="small">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>{translate('DESCRIPTION')}</TableCell>
                                                <TableCell>{translate('YEARLY_REPORT')}</TableCell>
                                                <TableCell>{translate('LARGE_REPORT')}</TableCell>
                                                <TableCell>{translate('EVA')}</TableCell>
                                                <TableCell>{translate('EVA_MINI')}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {admin.licenseSchemas.map((row) => (
                                                <TableRow
                                                    key={row.id}
                                                    hover
                                                    onClick={(): void => openDialog(row)}
                                                    style={{ cursor: 'pointer' }}
                                                >
                                                    <TableCell>{row.description}</TableCell>
                                                    <TableCell>{row.yearlyReport}</TableCell>
                                                    <TableCell>{row.largeReport}</TableCell>
                                                    <TableCell>{row.eva}</TableCell>
                                                    <TableCell>{row.evaMini}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </>
                            )}
                        </Box>
                        <Box px={2} pb={2} display="flex">
                            <Box flexGrow={1} />
                            <ShvkButton onClick={(): void => openDialog(null)}>{translate('ADD')}</ShvkButton>
                        </Box>
                    </Paper>
                </Box>
            </Container>
            <LicenseItemDialog open={state.dialogOpen} onClose={closeDialog} licenseItem={state.selectedItem} />
        </>
    );
}

export default observer(License);
