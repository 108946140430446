import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { TextFieldEvent } from '../../../../types/events';
import ShvkTextField from '../../../../styled/ShvkTextField';
import ShvkButton from '../../../../styled/ShvkButton';
import useStore from '../../../../store/storeContext';

interface Props {
    isOpen: boolean;
    close(): void;
}

interface State {
    title: string;
}

function AddPerspectiveDialog(props: Props) {
    const { document, localization, loadingIndicator, snackbar } = useStore();

    const [state, setState] = useState<State>({
        title: '',
    });

    useEffect(() => {
        props.isOpen && setState((state) => ({ ...state, title: '' }));
    }, [props.isOpen]);

    async function save(): Promise<void> {
        try {
            loadingIndicator.show();
            await document.addEvaPerspective(state.title);
            snackbar.showSuccess();
        } catch (error) {
            snackbar.showError(error.data?.code);
        } finally {
            props.close();
            loadingIndicator.hide();
        }
    }

    const handleChange = (event: TextFieldEvent): void => {
        setState((state) => ({ ...state, title: event.target.value }));
    };

    const { translate } = localization;

    return (
        <Dialog
            open={props.isOpen}
            onClose={props.close}
            aria-labelledby="add-perspective-dialog-title"
            maxWidth={'sm'}
            fullWidth
        >
            <DialogTitle id="add-perspective-dialog-title">{translate('EVA_ADD_PERSPECTIVE')}</DialogTitle>
            <DialogContent dividers>
                <ShvkTextField
                    required
                    label={translate('EVA_PERSPECTIVE')}
                    multiline
                    rows={3}
                    sx={{ mb: 2 }}
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={state.title}
                    onChange={handleChange}
                />
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={props.close}>
                    {translate('CANCEL')}
                </Button>
                <Box flexGrow={1} />
                <ShvkButton onClick={save} disabled={!state.title}>
                    {translate('SAVE')}
                </ShvkButton>
            </DialogActions>
        </Dialog>
    );
}

export default observer(AddPerspectiveDialog);
