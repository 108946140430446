import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import Nestable, { NestableItem, RenderCollapseIconArgs, RenderItemArgs } from 'react-nestable';
import { Grid, IconButton, MenuItem, Typography } from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { EvaNestableItem, EvaRenderItemArgs } from '../../types/nestable';
import { TextFieldEvent } from '../../types/events';
import { DecisionOption } from '../../types/evaTemplate';
import { getDepth, getPathById, handlerStyle } from '../../utils/NestableUtils';
import EvaNestableComponent from './EvaNestableComponent';
import EditEvaNestableDialog from './EditEvaNestableDialog';
import ShvkButton from '../../styled/ShvkButton';
import ShvkTextField from '../../styled/ShvkTextField';
import useStore from '../../store/storeContext';

interface Props {
    decisionOptions?: DecisionOption[];
    setList(list: EvaNestableItem[]): void;
    list: EvaNestableItem[];
    addToRemovedEvaItems(item: EvaNestableItem): void;
}

interface State {
    dialogOpen: boolean;
    dialogItem: EvaNestableItem | null;
    dialogItemParentId: number | null;
    selectedDefaultTemplateId: number | null;
}

let nextNewId = -1;

function EvaStructure(props: Props) {
    const { evaTemplate, localization } = useStore();

    const [state, setState] = useState<State>({
        dialogOpen: false,
        dialogItem: null,
        dialogItemParentId: null,
        selectedDefaultTemplateId: null,
    });

    useEffect(() => {
        init();
        return () => {
            nextNewId = -1;
            setState((state) => ({
                ...state,
                dialogOpen: false,
                dialogItem: null,
                dialogItemParentId: null,
                selectedDefaultTemplateId: null,
            }));
        };
    }, []);

    function init(): void {
        // Always select the first default template. This is also needed when editing organization eva templates,
        // because the user can delete every decision option, and then the new one is created from the default eva template.
        const defaultTemplateId = evaTemplate.defaultEvaTemplateList[0]?.id;
        setState((state) => ({ ...state, selectedDefaultTemplateId: defaultTemplateId }));

        // If editing: create a nestable list from selected organization eva template.
        if (props.decisionOptions) {
            createListFromOrganizationEvaTemplate();
        }

        // If creating: create nestable list from default eva template.
        else {
            createListFromDefaultEvaTemplate(defaultTemplateId);
        }
    }

    function createListFromDefaultEvaTemplate(defaultTemplateId: number | null): void {
        const defaultPerspectiveTemplate = evaTemplate.defaultEvaTemplateList.find(
            (template) => template.id === defaultTemplateId,
        );
        if (!defaultPerspectiveTemplate) return;

        const decisionOption = defaultPerspectiveTemplate.decisionOption;
        const perspectives = defaultPerspectiveTemplate.perspectives;

        const optionId = nextNewId--;
        // Create a new list from default template, and give unique IDs to every element.
        const newList = [
            {
                id: optionId,
                text: decisionOption.title,
                description: decisionOption.description,
                parentId: null,
                depth: 1,
                children: perspectives.map((perspective) => {
                    const perspectiveId = nextNewId--;
                    return {
                        id: perspectiveId,
                        text: perspective.title,
                        description: perspective.description,
                        parentId: optionId,
                        depth: 2,
                        children: perspective.impactOnTargets.map((impact) => {
                            return {
                                id: nextNewId--,
                                text: impact.title,
                                description: impact.description,
                                parentId: perspectiveId,
                                depth: 3,
                                children: [],
                            };
                        }),
                    };
                }),
            },
        ];
        props.setList(newList);
    }

    function createListFromOrganizationEvaTemplate(): void {
        const newList =
            props.decisionOptions?.map((decisionOption) => {
                return {
                    id: decisionOption.id,
                    text: decisionOption.title,
                    description: decisionOption.description,
                    parentId: null,
                    depth: 1,
                    children:
                        decisionOption.perspectives?.map((perspective) => {
                            return {
                                id: perspective.id,
                                text: perspective.title,
                                description: perspective.description,
                                parentId: decisionOption.id,
                                depth: 2,
                                children: perspective.impactOnTargets.map((impact) => {
                                    return {
                                        id: impact.id,
                                        text: impact.title,
                                        description: impact.description,
                                        parentId: perspective.id,
                                        depth: 3,
                                        children: [],
                                    };
                                }),
                            };
                        }) || [],
                };
            }) || [];
        props.setList(newList);
    }

    function openDialog(dialogItem: EvaNestableItem | null, dialogItemParentId: number | null): void {
        setState((state) => ({ ...state, dialogOpen: true, dialogItem, dialogItemParentId }));
    }

    const handleCloseDialog = (): void => {
        setState((state) => ({ ...state, dialogOpen: false }));
    };

    const handleTextChange = (text: string, description: string, id: number): void => {
        const pathToItem = getPathById(id, props.list);
        const decisionOptionIndex = pathToItem[0];
        const perspectiveIndex = pathToItem[1];
        const impactIndex = pathToItem[2];

        if (pathToItem.length === 1) {
            // Decision option changed. Update values
            const newList = props.list;
            newList[decisionOptionIndex].description = description;
            newList[decisionOptionIndex].text = text;
            props.setList(newList);
        } else {
            // Perspective or impact on target changed. Copy the changes to other decision options.
            const newList = props.list.map((decisionOption) => {
                if (pathToItem.length === 2) {
                    // Perspective changed
                    decisionOption.children[perspectiveIndex].text = text;
                    decisionOption.children[perspectiveIndex].description = description;
                } else if (pathToItem.length === 3) {
                    // Impact on target changed
                    decisionOption.children[perspectiveIndex].children[impactIndex].text = text;
                    decisionOption.children[perspectiveIndex].children[impactIndex].description = description;
                }
                return decisionOption;
            });
            props.setList(newList);
        }
    };

    function handleListChange(items: NestableItem[], item: NestableItem): void {
        const evaItems = items as EvaNestableItem[];
        const evaItem = item as EvaNestableItem;

        if (evaItem.depth > 1) {
            // Moved element was a perspective or an impactOnTarget. Copy the change to other decision options.
            const oldPathToItem = getPathById(evaItem.id, props.list);
            const newPathToItem = getPathById(evaItem.id, evaItems);

            const newList = [...props.list];
            const updatedList = newList.map((decisionOption) => {
                if (evaItem.depth === 2) {
                    // Perspective changed
                    // Delete perspective from old index and save it to a variable
                    const movedPerspective = decisionOption.children.splice(oldPathToItem[1], 1)[0];
                    // Add the perspective to the new index
                    decisionOption.children.splice(newPathToItem[1], 0, movedPerspective);
                } else {
                    // Impact on target changed
                    const perspective = decisionOption.children[oldPathToItem[1]];
                    // Delete impact from old index and save it to a variable
                    const movedImpact = perspective.children.splice(oldPathToItem[2], 1)[0];
                    // Add the impact to the new index
                    perspective.children.splice(newPathToItem[2], 0, movedImpact);
                    decisionOption.children[oldPathToItem[1]] = perspective;
                }
                return decisionOption;
            });
            props.setList(updatedList);
            return;
        }
        // Moved element was a decision option. No need to modify children.
        props.setList(evaItems);
    }

    function addItem(text: string, description: string): void {
        const parentId = state.dialogItemParentId;
        const depth = parentId === null ? 1 : getDepth(parentId, props.list) + 1;

        if (parentId !== null) {
            // Adding a child to a decision option or perspective
            const currentNestableItemList = [...props.list];
            const newNestableItemList = currentNestableItemList.map((decisionOption) => {
                const newItem: EvaNestableItem = {
                    id: nextNewId--,
                    text,
                    children: [],
                    description: description,
                    parentId: decisionOption.id,
                    depth,
                };

                if (depth === 2) {
                    // Adding a new perspective
                    decisionOption.children.push(newItem);
                } else if (depth === 3) {
                    // Adding a new impact on target
                    const perspectiveIndex = getPathById(parentId, props.list)[1];
                    const perspective = decisionOption.children[perspectiveIndex];
                    newItem.parentId = perspective.id;
                    perspective.children.push(newItem);
                    decisionOption.children[perspectiveIndex] = perspective;
                }
                return decisionOption;
            });

            props.setList(newNestableItemList);
        } else {
            // Adding a new decision option
            if (props.list.length === 0) {
                // There are no decision options. Create from the default template.
                createListFromDefaultEvaTemplate(state.selectedDefaultTemplateId);
            } else {
                // Create a new decision option. Copy values from the first decision option.
                const newItem: EvaNestableItem = {
                    id: nextNewId--,
                    text,
                    children: [],
                    description: description,
                    parentId: null,
                    depth,
                };

                // Clone perspectives from the first decision option.
                const childrenClone: EvaNestableItem[] = JSON.parse(JSON.stringify(props.list[0].children));

                // Add cloned perspectives to the new decision option, and give them (and their children) unique IDs.
                newItem.children = childrenClone.map((perspective) => {
                    const perspectiveId = nextNewId--;
                    perspective.id = perspectiveId;
                    perspective.parentId = newItem.id;
                    perspective.children = perspective.children.map((target) => {
                        target.parentId = perspectiveId;
                        target.id = nextNewId--;
                        return target;
                    });
                    return perspective;
                });
                const newNestableItemList = [...props.list];
                newNestableItemList.push(newItem);
                props.setList(newNestableItemList);
            }
        }
    }

    function removeItem(item: EvaNestableItem): void {
        const pathToItem = getPathById(item.id, props.list);
        const decisionOptionIndex = pathToItem[0];
        const perspectiveIndex = pathToItem[1];
        const impactIndex = pathToItem[2];

        if (pathToItem.length > 1) {
            // Perspective or impact removed
            const currentNestableItemList = [...props.list];
            const newNestableItemList = currentNestableItemList.map((decisionOption) => {
                if (pathToItem.length === 2) {
                    // Perspective removed
                    decisionOption.children.splice(perspectiveIndex, 1);
                } else {
                    // Impact removed
                    decisionOption.children[perspectiveIndex].children.splice(impactIndex, 1);
                }
                return decisionOption;
            });
            props.setList(newNestableItemList);
        } else if (pathToItem.length === 1) {
            // Decision option removed
            const newNestableItemList = [...props.list];
            newNestableItemList.splice(decisionOptionIndex, 1);
            props.setList(newNestableItemList);
        }

        if (item.id > 0) props.addToRemovedEvaItems(item);
    }

    function changeDefaultTemplate(event: TextFieldEvent): void {
        const newTemplateId = Number(event.target.value);
        setState((state) => ({ ...state, selectedDefaultTemplateId: newTemplateId }));
        createListFromDefaultEvaTemplate(newTemplateId);
    }

    function addDecisionOption(): void {
        if (props.list.length === 0) {
            // If we have an empty list, the first decision option will be created from the default template.
            // No need to open dialog.
            state.dialogItemParentId = null;
            addItem('', '');
        } else {
            // Get title and description from the dialog.
            openDialog(null, null);
        }
    }

    // Nestable drag confirmation
    function confirmChange(dragItem: NestableItem, destinationParent: NestableItem): boolean {
        const evaDragItem = dragItem as EvaNestableItem;
        // Only allow moves inside the same parent.
        if (!destinationParent) return evaDragItem.parentId === null;
        return evaDragItem.parentId === destinationParent.id;
    }

    // Icon used in nestable list elements
    function renderCollapseIcon(args: RenderCollapseIconArgs): JSX.Element {
        return (
            <IconButton size="small" color="primary">
                {args.isCollapsed ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
            </IconButton>
        );
    }

    // Nestable list element
    function renderItem(args: RenderItemArgs): React.ReactNode {
        return (
            <EvaNestableComponent args={args as EvaRenderItemArgs} removeItem={removeItem} openDialog={openDialog} />
        );
    }

    const { translate } = localization;
    return (
        <div>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography>{translate('ORGANIZATION_EVA_TEMPLATE_TIP')}</Typography>
                </Grid>
                {!props.decisionOptions && (
                    <Grid item xs={12}>
                        <ShvkTextField
                            required
                            select
                            label={translate('SELECT_PERSPECTIVE_TEMPLATE')}
                            value={state.selectedDefaultTemplateId || ''}
                            variant="outlined"
                            size="small"
                            fullWidth
                            onChange={changeDefaultTemplate}
                        >
                            {evaTemplate.defaultEvaTemplateList.map((template) => (
                                <MenuItem key={template.id} value={template.id}>
                                    {template.text}
                                </MenuItem>
                            ))}
                        </ShvkTextField>
                    </Grid>
                )}
                <Grid item xs={12}>
                    <ShvkButton onClick={addDecisionOption}>{translate('EVA_ADD_DECISION_OPTION')}</ShvkButton>
                </Grid>
                <Grid item xs={12}>
                    <Nestable
                        items={props.list}
                        renderItem={renderItem}
                        maxDepth={3}
                        onChange={handleListChange}
                        confirmChange={confirmChange}
                        renderCollapseIcon={renderCollapseIcon}
                        handler={<span style={handlerStyle} />}
                        collapsed={true}
                    />
                </Grid>
            </Grid>
            <EditEvaNestableDialog
                open={state.dialogOpen}
                item={state.dialogItem}
                onClose={handleCloseDialog}
                edit={handleTextChange}
                add={addItem}
            />
        </div>
    );
}

export default observer(EvaStructure);
