import React, { ReactNode, useEffect } from 'react';
import { observer, useLocalObservable } from 'mobx-react-lite';
import {
    Box,
    Button,
    Card,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    Grid,
    Paper,
    Radio,
    RadioGroup,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { CartesianGrid, Label, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { AddIndicatorDialogIndicator, GraphDataItem, IndicatorData, IndicatorGender } from '../../../types/indicator';
import useStore from '../../../store/storeContext';

const PREFIX = 'IndicatorGraphPreviewDialog';

const classes = {
    paper: `${PREFIX}-paper`,
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialog-paper': {
        [theme.breakpoints.up('lg')]: {
            maxWidth: 1280,
        },
    },
    [`& .${classes.paper}`]: {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
}));

interface Props {
    isOpen: boolean;
    close(): void;
    indicator: AddIndicatorDialogIndicator;
}

interface StoreState {
    gender: IndicatorGender;
    data: IndicatorData[];
    graphData: GraphDataItem[];
    organizationsForGraph: string[];
    yAxisScale: number;
    setData: (data: IndicatorData[]) => void;
    setGender: (gender: IndicatorGender) => void;
}

function IndicatorGraphPreviewDialog(props: Props) {
    const { document, indicator, localization, loadingIndicator, theming, snackbar } = useStore();

    const localStore = useLocalObservable<StoreState>(() => ({
        gender: 'total',
        data: [],

        get graphData(): GraphDataItem[] {
            const {
                removeDuplicatesFromIndicatorData,
                groupIndicatorDataByOrganization,
                filterIndicatorDataBetweenCompareYears,
            } = indicator;
            const { currentDocument } = document;

            const currentOrganizationName = currentDocument.organization.name;
            const uniques = removeDuplicatesFromIndicatorData(this.data.filter((item) => item.gender === this.gender));
            const dataBetweenCompareYears = filterIndicatorDataBetweenCompareYears(uniques);
            const sortedByYear = dataBetweenCompareYears.sort((a, b) => a.year - b.year);
            const organizationData = groupIndicatorDataByOrganization(sortedByYear);

            const graphData = organizationData.map((item, i) => {
                const isCurrentOrganization = item.organizationName === currentOrganizationName;
                return {
                    color: isCurrentOrganization
                        ? theming.graphOrganizationHomeColor
                        : theming.graphOrganizationColors[i],
                    name: item.organizationName,
                    shortName: item.organizationShortName,
                    orderNumber: getOrganizationOrderNumber(item.organizationName),
                    strokeWidth: isCurrentOrganization ? 3 : 1.5,
                    values: item.data,
                };
            });

            return graphData;
        },

        get organizationsForGraph(): string[] {
            return this.graphData.map((data) => data.shortName || data.name);
        },

        get yAxisScale(): number {
            return !document.isCurrentDocumentEva ? document.currentDocument.documentIndicatorSetting.graphScale : 0;
        },

        setData(data: IndicatorData[]) {
            this.data = data;
        },
        setGender(gender: IndicatorGender) {
            this.gender = gender;
        },
    }));

    useEffect(() => {
        void init();
    }, []);

    async function init() {
        try {
            loadingIndicator.show();
            const data = await indicator.fetchIndicatorDataForPreview(props.indicator);
            localStore.setData(data);
        } catch (e) {
            snackbar.showFetchFailedMessage(e.data?.code);
        } finally {
            loadingIndicator.hide();
        }
    }

    const handleGenderChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        localStore.setGender(event.target.value as IndicatorGender);
    };

    function getOrganizationOrderNumber(organizationName: string): number {
        const { comparisonSettings } = indicator;
        if (!comparisonSettings) return 0;
        const organization = comparisonSettings.organizations.find((org) => org.name === organizationName);
        return organization?.orderNumber || 0;
    }

    function tableYears(): number[] {
        if (document.isEvaDocument(document.currentDocument)) {
            return localStore.data.map((data) => data.year);
        }
        return indicator
            .dataForLargeGraph(localStore.graphData)
            .flatMap((item) => item.year)
            .sort((a: number, b: number) => a - b);
    }

    function labelContent(props: any): ReactNode {
        let letterCounter = 0;
        let lineCounter = 0;

        return (
            <>
                {localStore.organizationsForGraph.map((org, i) => {
                    if (letterCounter + org.length > 85) {
                        letterCounter = 0;
                        lineCounter += 1;
                    }
                    if (org.length > 20) {
                        letterCounter += 23;
                    } else {
                        letterCounter += org.length + 3;
                    }
                    return (
                        <g key={org}>
                            <circle
                                cx={props.viewBox.x + 760 - letterCounter * 8}
                                cy={props.viewBox.y - 11 - lineCounter * 18}
                                r={6}
                                fill={theming.graphOrganizationColors[i]}
                            />
                            <text
                                fontFamily="Work sans"
                                fontSize="14"
                                color="black"
                                x={props.viewBox.x + 760 + 8 - letterCounter * 8}
                                y={props.viewBox.y - 6 - lineCounter * 18}
                            >
                                {org.length >= 20 ? org.slice(0, 20) + '...' : org}
                            </text>
                        </g>
                    );
                })}
            </>
        );
    }

    const { translate } = localization;
    const { dynamicMarginTopForGraphLabel, dataForLargeGraph } = indicator;

    return (
        <StyledDialog
            open={props.isOpen}
            onClose={props.close}
            aria-labelledby="indicator-graph-preview-dialog-title"
            maxWidth={'lg'}
            fullWidth
        >
            <DialogTitle id="indicator-graph-preview-dialog-title">
                {translate('INDICATOR_PREVIEW')} - {props.indicator.name}
            </DialogTitle>
            <DialogContent dividers>
                <Paper variant="outlined" className={classes.paper}>
                    <Grid container>
                        <Grid item xs={12} md={8}>
                            <ResponsiveContainer width="99%" height="99%" minHeight="450px">
                                <LineChart
                                    width={500}
                                    height={300}
                                    data={dataForLargeGraph(localStore.graphData)}
                                    margin={{
                                        top: dynamicMarginTopForGraphLabel(localStore.organizationsForGraph),
                                        right: 27,
                                        left: 0,
                                        bottom: 5,
                                    }}
                                >
                                    <CartesianGrid />
                                    <XAxis
                                        fontFamily="Work sans"
                                        fontSize="14"
                                        color="black"
                                        stroke="black"
                                        dataKey="year"
                                        type="number"
                                        ticks={tableYears()}
                                        domain={[tableYears()[0], tableYears()[tableYears.length - 1]]}
                                    />
                                    <YAxis
                                        fontFamily="Work sans"
                                        fontSize="14"
                                        color="black"
                                        stroke="black"
                                        type="number"
                                        domain={[localStore.yAxisScale ? 'dataMin' : 0, 'dataMax']}
                                    >
                                        <Label
                                            content={(props) => labelContent(props)}
                                            offset={0}
                                            position="insideBottom"
                                        />
                                    </YAxis>
                                    <Tooltip />
                                    {localStore.organizationsForGraph.map((organization, index) => (
                                        <Line
                                            dot={false}
                                            connectNulls
                                            key={organization}
                                            type="monotoneX"
                                            dataKey={organization}
                                            strokeWidth={
                                                (document.currentDocument.organization?.shortName ||
                                                    document.currentDocument.organization.name) === organization
                                                    ? 4
                                                    : 2
                                            }
                                            stroke={theming.graphOrganizationColors[index]}
                                        />
                                    ))}
                                </LineChart>
                            </ResponsiveContainer>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Box display="flex" flexDirection="column" sx={{ height: '100%' }}>
                                <Paper variant="outlined" className={classes.paper}>
                                    <FormControl component="fieldset" sx={{ mb: 2 }}>
                                        <RadioGroup row value={localStore.gender} onChange={handleGenderChange}>
                                            <FormControlLabel
                                                value="total"
                                                control={<Radio />}
                                                label={translate('INDICATOR_TOTAL_RADIO_BUTTON')}
                                                disabled={!localStore.data.some((item) => item.gender === 'total')}
                                            />
                                            <FormControlLabel
                                                value="female"
                                                control={<Radio />}
                                                label={translate('INDICATOR_FEMALE_RADIO_BUTTON')}
                                                disabled={!localStore.data.some((item) => item.gender === 'female')}
                                            />
                                            <FormControlLabel
                                                value="male"
                                                control={<Radio />}
                                                label={translate('INDICATOR_MALE_RADIO_BUTTON')}
                                                disabled={!localStore.data.some((item) => item.gender === 'male')}
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                </Paper>
                            </Box>
                        </Grid>
                    </Grid>
                </Paper>
                <Paper variant="outlined">
                    <TableContainer component={Card} variant="outlined">
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>{translate('ORGANIZATION')}</TableCell>
                                    {tableYears().map((year) => (
                                        <TableCell key={year}>{year}</TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {localStore.graphData.map((dataItem) => (
                                    <TableRow key={dataItem.name}>
                                        <TableCell>{dataItem.shortName || dataItem.name}</TableCell>
                                        {tableYears().map((year) => (
                                            <TableCell key={year}>
                                                {dataItem.values.find((value) => value.year === year)?.value}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={props.close}>
                    {translate('CLOSE')}
                </Button>
                <Box flexGrow={1} />
            </DialogActions>
        </StyledDialog>
    );
}

export default observer(IndicatorGraphPreviewDialog);
