import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import {
    Box,
    Button,
    Card,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    List,
    ListItemButton,
    ListItemText,
    Typography,
} from '@mui/material';
import EmailGroup from '../../types/emailGroup';
import ShvkButton from '../../styled/ShvkButton';
import AddEmailGroupDialog from './AddEmailGroupDialog';
import useStore from '../../store/storeContext';

interface Props {
    open: boolean;
    onClose(): void;
}

interface State {
    fetchingEmailGroups: boolean;
    editDialogOpen: boolean;
}

function EmailGroupListDialog(props: Props) {
    const { user, localization, snackbar } = useStore();

    const [state, setState] = useState<State>({
        fetchingEmailGroups: false,
        editDialogOpen: false,
    });

    const [selectedEmailGroup, setSelectedEmailGroup] = useState<EmailGroup | null>(null);

    useEffect(() => {
        props.open && init();
    }, [props.open]);

    async function init(): Promise<void> {
        setState((state) => ({ ...state, fetchingEmailGroups: true }));
        try {
            await user.fetchEmailGroupsByCreator();
        } catch {
            snackbar.showFetchFailedMessage();
        } finally {
            setState((state) => ({ ...state, fetchingEmailGroups: false }));
        }
    }

    function openEditDialog(group: EmailGroup | null): void {
        setSelectedEmailGroup(group);
        setState((state) => ({ ...state, editDialogOpen: true }));
    }

    function closeEditDialog(): void {
        setSelectedEmailGroup(null);
        setState((state) => ({ ...state, editDialogOpen: false }));
    }

    const { translate } = localization;

    return (
        <>
            <Dialog
                open={props.open}
                onClose={props.onClose}
                aria-labelledby="email-group-list-dialog-title"
                maxWidth={'sm'}
                fullWidth
            >
                <DialogTitle id="email-group-list-dialog-title">{translate('EMAIL_GROUPS')}</DialogTitle>
                <DialogContent dividers>
                    <Box my={2}>
                        <Box mb={1}>
                            <Typography>{translate('SELECT_EMAIL_GROUP_DESCRIPTION')}</Typography>
                        </Box>
                        <Card style={{ height: 300, overflow: 'auto' }} variant="outlined">
                            {state.fetchingEmailGroups ? (
                                <CircularProgress />
                            ) : (
                                <List dense component="div" role="list">
                                    {user.emailGroups.map((group) => (
                                        <ListItemButton
                                            key={group.id}
                                            role="listitem"
                                            onClick={(): void => openEditDialog(group)}
                                        >
                                            <ListItemText primary={group.name} />
                                        </ListItemButton>
                                    ))}
                                </List>
                            )}
                        </Card>
                        <Box display="flex" justifyContent="flex-end" mt={2}>
                            <ShvkButton onClick={(): void => openEditDialog(null)}>
                                {translate('CREATE_EMAIL_GROUP')}
                            </ShvkButton>
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.onClose} variant="contained">
                        {translate('CLOSE')}
                    </Button>
                    <Box flexGrow={1} />
                </DialogActions>
            </Dialog>
            <AddEmailGroupDialog
                open={state.editDialogOpen}
                onClose={closeEditDialog}
                emailGroup={selectedEmailGroup}
            />
        </>
    );
}

export default observer(EmailGroupListDialog);
