import React from 'react';
import { observer } from 'mobx-react';
import {
    Box,
    Button,
    Card,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';
import { Add, Delete, Timeline } from '@mui/icons-material';
import { Indicator, RemoveIndicatorFromImpactOnTargetParams } from '../../../types/indicator';
import { EditorImpactOnTarget } from '../../../types/evaTemplate';
import { DocumentDialogProps } from '../../../types/dialog';
import DangerIconButton from '../../../styled/DangerIconButton';
import useStore from '../../../store/storeContext';

interface Props {
    indicators: Indicator[];
    impactOnTarget?: EditorImpactOnTarget | null;
}

function IndicatorTable(props: Props) {
    const context = useStore();
    const { dialog, localization, snackbar } = context;

    async function remove(indicator: Indicator): Promise<void> {
        const { translate } = localization;

        const confirmText = translate('CONFIRM_INDICATOR_REMOVAL_SUBTITLE') + ': ' + indicator.name + '?';
        const confirmation = await dialog.getConfirmation(undefined, confirmText);
        if (!confirmation) return;
        const params: RemoveIndicatorFromImpactOnTargetParams = {
            impactId: props.impactOnTarget?.id,
            indicatorId: indicator.id,
            source: indicator.source,
        };
        try {
            await context.indicator.removeIndicatorFromImpactOnTarget(params);
            snackbar.showSuccess();
        } catch (e) {
            snackbar.showError(e.data?.code);
        }
    }

    function addIndicatorDialogProps(): DocumentDialogProps {
        return {
            impactOnTarget: props.impactOnTarget,
        };
    }

    function addIndicatorGraphDialogProps(indicator: Indicator): DocumentDialogProps {
        return {
            indicator: indicator,
        };
    }

    const { translate } = localization;

    return (
        <div>
            <TableContainer component={Card} variant="outlined">
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>{translate('INDICATOR')}</TableCell>
                            <TableCell>{translate('ACTIONS')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.indicators.map((indicator) => (
                            <TableRow key={indicator.id}>
                                <TableCell>{indicator.name}</TableCell>
                                <TableCell>
                                    <IconButton
                                        size="small"
                                        color="primary"
                                        onClick={(): void =>
                                            dialog.openDocumentDialog(
                                                'indicatorGraphDialog',
                                                addIndicatorGraphDialogProps(indicator),
                                            )
                                        }
                                    >
                                        <Timeline />
                                    </IconButton>
                                    <DangerIconButton size="small" onClick={() => remove(indicator)}>
                                        <Delete />
                                    </DangerIconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Box my={2} display="flex">
                <Box flexGrow={1} />
                <Button
                    color="primary"
                    variant="outlined"
                    startIcon={<Add />}
                    onClick={(): void => dialog.openDocumentDialog('addIndicatorDialog', addIndicatorDialogProps())}
                >
                    {translate('ADD_INDICATOR')}
                </Button>
            </Box>
        </div>
    );
}

export default observer(IndicatorTable);
