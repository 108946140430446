import React from 'react';
import { observer } from 'mobx-react-lite';
import { Link, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Chapter } from '../../types/document';
import useStore from '../../store/storeContext';

const PREFIX = 'SideNavigation';

const classes = {
    nav: `${PREFIX}-nav`,
    list: `${PREFIX}-list`,
    row: `${PREFIX}-row`,
};

const Root = styled('nav')(({ theme }) => ({
    [`&.${classes.nav}`]: {
        position: 'sticky',
        top: '100px',
        overflowY: 'auto',
        overflowX: 'hidden',
        height: 'calc(100vh - 200px)',
        marginTop: theme.spacing(3),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
            // Smaller side bar for medium screens
            width: '200px',
        },
        // Side menu and the document are inside the same flexbox. This way the document is responsive to the side menu width.
        // Flexbox is inside a container.
        // Side menu is moved to the left side of the screen (overflows the container).
        // Calculate:
        // Window with - size of the container = container margins.
        // Container margin / 2 = one side margin.
        // Margin * -1 = negative margin
        // ===> moves nav to the right edge of the screen.
        [theme.breakpoints.up('lg')]: {
            marginLeft: `calc(((100vw - ${theme.breakpoints.values.lg}px) / 2) * -1)`,
            width: '400px',
        },
    },

    [`& .${classes.list}`]: {
        padding: 0,
        listStyle: 'none',
    },

    [`& .${classes.row}`]: {
        marginBottom: theme.spacing(0.5),
    },
}));

function SideNavigation() {
    const { document, localization, theming } = useStore();

    function paddingLeft(chapter: Chapter): string {
        if (chapter.chapterLevel < 2) return theming.spacing(0);
        return theming.spacing(3);
    }

    const { translate } = localization;
    const { currentDocument } = document;

    return (
        <Root className={classes.nav}>
            <Typography paragraph>{translate('DOCUMENT_STRUCTURE')}:</Typography>
            <Typography component="ul" className={classes.list} gutterBottom>
                {currentDocument.chapters.map((chapter) => (
                    <li key={chapter.id} className={classes.row}>
                        <Link href={'#chapter-' + chapter.orderNumber} style={{ paddingLeft: paddingLeft(chapter) }}>
                            {chapter.chapterNumberString
                                ? `${chapter.chapterNumberString}. ${chapter.title}`
                                : chapter.title}
                        </Link>
                    </li>
                ))}
            </Typography>
        </Root>
    );
}

export default observer(SideNavigation);
