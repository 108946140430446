import React from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, List } from '@mui/material';
import NewsArticle from '../../types/news';
import NewsRow from '../news/NewsRow';
import useStore from '../../store/storeContext';

interface Props {
    open: boolean;
    onClose(): void;
    newsArticles: NewsArticle[];
    toggleArticleDialog: (newsArticle: NewsArticle) => void;
}

function NewsListDialog(props: Props) {
    const { localization } = useStore();

    const { translate } = localization;
    const { open, onClose, newsArticles, toggleArticleDialog } = props;

    return (
        <Dialog open={open} onClose={onClose} aria-labelledby="news-list-dialog-title" maxWidth={'sm'}>
            <DialogTitle id="news-list-dialog-title">{translate('NEWS_TITLE')}</DialogTitle>
            <DialogContent dividers>
                <List>
                    {newsArticles.map((newsArticle: NewsArticle, i) => (
                        <React.Fragment key={newsArticle.id}>
                            <NewsRow toggleArticleDialog={toggleArticleDialog} newsArticle={newsArticle} />
                            {i === newsArticles.length - 1 ? null : <Divider component="li" />}
                        </React.Fragment>
                    ))}
                </List>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} variant="contained">
                    {translate('CLOSE')}
                </Button>
                <Box flexGrow={1} />
            </DialogActions>
        </Dialog>
    );
}

export default observer(NewsListDialog);
