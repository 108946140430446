import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Button, Divider, List, Paper, Typography } from '@mui/material';
import NewsArticle from '../../types/news';
import GeneralDialog from '../GeneralDialog';
import NewsListDialog from './NewsListDialog';
import NewsRow from '../news/NewsRow';
import moment from 'moment';
import useStore from '../../store/storeContext';

interface State {
    articleDialogOpen: boolean;
    listDialogOpen: boolean;
}

function News() {
    const { news, localization } = useStore();

    const [state, setState] = useState<State>({
        articleDialogOpen: false,
        listDialogOpen: false,
    });

    const [selectedArticle, setSelectedArticle] = useState<NewsArticle | null>(null);

    const handleNewsListDialog = (): void => {
        setState((state) => ({ ...state, listDialogOpen: !state.listDialogOpen }));
    };

    const handleArticleDialog = (newsArticle: NewsArticle | null): void => {
        setState((state) => ({ ...state, articleDialogOpen: !state.articleDialogOpen }));
        newsArticle && setSelectedArticle(newsArticle);
    };

    const { translate } = localization;

    return (
        <>
            <Paper sx={{ mb: 3 }}>
                <Box px={2} py={1}>
                    <Typography variant="h6" component="h4">
                        {translate('NEWS_TITLE')}
                    </Typography>
                </Box>
                <Divider />
                <List>
                    {news.newsArticles.slice(0, 3).map((newsArticle: NewsArticle) => (
                        <React.Fragment key={newsArticle.id}>
                            <NewsRow toggleArticleDialog={handleArticleDialog} newsArticle={newsArticle} />
                            <Divider variant="middle" light={true} component="li" />
                        </React.Fragment>
                    ))}
                </List>
                <Box p={2}>
                    <Typography>
                        <Button onClick={handleNewsListDialog}>{translate('NEWS_MORE_LINK')}</Button>
                    </Typography>
                </Box>
            </Paper>
            <NewsListDialog
                open={state.listDialogOpen}
                onClose={handleNewsListDialog}
                newsArticles={news.newsArticles}
                toggleArticleDialog={handleArticleDialog}
            />
            <GeneralDialog
                content={selectedArticle?.content}
                title={selectedArticle?.title}
                subtitle={moment(selectedArticle?.dateCreated).format('DD.MM.YYYY hh:mm')}
                open={state.articleDialogOpen}
                onClose={(): void => handleArticleDialog(null)}
            />
        </>
    );
}

export default observer(News);
