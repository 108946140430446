import React, { useState, useEffect, ChangeEvent } from 'react';
import { observer } from 'mobx-react-lite';
import {
    Autocomplete,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Popper,
    Typography,
} from '@mui/material';
import { RegisterForm } from '../../types/user';
import { AdminOrganization } from '../../types/organization';
import { TranslationKeys } from '../../types/translation';
import { TextFieldEvent } from '../../types/events';
import ShvkNotificationBox from '../ShvkNotificationBox';
import ShvkTextField from '../../styled/ShvkTextField';
import useStore from '../../store/storeContext';

interface Props {
    isOpen: boolean;
    close(): void;
}

interface State {
    showErrors: boolean;
    firstName: string;
    lastName: string;
    userName: string;
    profession: string;
    unitOrBranch: string;
    password1: string;
    password2: string;
    organizationInput: string;
    organization: AdminOrganization | null;
    fieldFocus: Field | undefined;
}

type Field =
    | 'firstName'
    | 'lastName'
    | 'userName'
    | 'profession'
    | 'unitOrBranch'
    | 'password1'
    | 'password2'
    | 'organization'
    | 'organizationInput';

function RegisterAsAdminUserDialog(props: Props) {
    const context = useStore();
    const { user, dialog, localization, loadingIndicator, snackbar } = context;

    const [state, setState] = useState<State>({
        showErrors: false,
        firstName: '',
        lastName: '',
        userName: '',
        profession: '',
        unitOrBranch: '',
        password1: '',
        password2: '',
        organizationInput: '',
        organization: null,
        fieldFocus: undefined,
    });

    useEffect(() => {
        props.isOpen && init();
    }, [props.isOpen]);

    async function init(): Promise<void> {
        setState((state) => ({
            ...state,
            firstName: '',
            lastName: '',
            userName: '',
            profession: '',
            unitOrBranch: '',
            password1: '',
            password2: '',
            organizationInput: '',
            organization: null,
            fieldFocus: undefined,
        }));
        if (allOrganizations.length === 0) {
            await context.organization.fetchAllOrganizations();
        }
    }

    async function register(): Promise<void> {
        const { showError, showSuccess } = snackbar;
        const fields = ['firstName', 'lastName', 'userName', 'password1', 'password2', 'organization'];
        if (isFormValid() && state.organization) {
            const form: RegisterForm = {
                firstName: state.firstName,
                lastName: state.lastName,
                userName: state.userName.toLocaleLowerCase(),
                profession: state.profession,
                unitOrBranch: state.unitOrBranch,
                password: state.password1,
                organizationId: state.organization.id,
            };
            try {
                loadingIndicator.show();
                const response = await user.register(form);
                showSuccess(response.code as keyof TranslationKeys);
                props.close();
                dialog.closeLoginDialog();
            } catch (error) {
                showError(error.data.code);
            } finally {
                loadingIndicator.hide();
            }
        } else {
            state.showErrors = true;
            for (const field of fields) {
                const isError = calculateError(field as Field);
                if (isError) {
                    document.getElementById(field)?.focus();
                    return;
                }
            }
            showError('ERROR_TITLE');
        }
    }

    function isFormValid(): boolean {
        return (
            Boolean(state.firstName) &&
            Boolean(state.lastName) &&
            isUserNameValid &&
            isPasswordValid &&
            Boolean(state.organization)
        );
    }

    function calculateError(currentField: Field): boolean {
        const { validateUserName, validatePassword, checkPasswordMatch } = user;
        const isNameFieldFocus = state.fieldFocus === 'firstName' || state.fieldFocus === 'lastName';
        const isPasswordFieldFocus = state.fieldFocus === 'password2' || state.fieldFocus === 'password1';

        switch (currentField) {
            case 'firstName':
                return (
                    (!isNameFieldFocus && !state.firstName && Boolean(state.lastName)) ||
                    (state.showErrors && !state.firstName)
                );
            case 'lastName':
                return (
                    (!isNameFieldFocus && !state.lastName && Boolean(state.firstName)) ||
                    (state.showErrors && !state.lastName)
                );
            case 'userName':
                return (
                    (state.fieldFocus !== 'userName' && Boolean(state.userName) && !validateUserName(state.userName)) ||
                    (state.showErrors && !validateUserName(state.userName))
                );
            case 'password1':
                return (
                    (state.showErrors && !validatePassword(state.password1)) ||
                    (state.fieldFocus !== 'password1' && Boolean(state.password1) && !validatePassword(state.password1))
                );
            case 'password2':
                return (
                    (state.showErrors && !validatePassword(state.password2)) ||
                    (!isPasswordFieldFocus && !checkPasswordMatch(state.password1, state.password2)) ||
                    (state.fieldFocus === 'password2' &&
                        Boolean(state.password2) &&
                        !validatePassword(state.password1)) ||
                    (Boolean(state.password2) &&
                        checkPasswordMatch(state.password1, state.password2) &&
                        !validatePassword(state.password1))
                );
            case 'organization':
                return state.showErrors && !state.organization;
            default:
                return false;
        }
    }

    const handleChange = (event: TextFieldEvent, field: Field): void => {
        setState((state) => ({ ...state, [field]: event.target.value }));
    };

    const handleAutocompleteChange = (_event: ChangeEvent<unknown>, newValue: AdminOrganization | null): void => {
        setState((state) => ({ ...state, organization: newValue }));
    };

    const handleFocusChange = (field: Field | undefined): void => {
        setState((state) => ({ ...state, fieldFocus: field }));
        if (!state.fieldFocus && state.organizationInput) {
            setState((state) => ({ ...state, organizationInput: '' }));
        }
    };

    const allOrganizations: AdminOrganization[] = context.organization.adminOrganizations
        .filter((org) => org.category === 'KUNTA')
        .slice()
        .sort((a, b) => a['name'].localeCompare(b['name']));

    const isPasswordValid: boolean =
        user.checkPasswordMatch(state.password1, state.password2) && user.validatePassword(state.password1);

    const isUserNameValid: boolean = user.validateUserName(state.userName);

    const { translate } = localization;

    return (
        <>
            <Dialog
                open={props.isOpen}
                onClose={props.close}
                aria-labelledby="register-as-admin-user-dialog"
                maxWidth={'md'}
                fullWidth
            >
                <DialogTitle id="register-as-admin-user-dialog-title">{translate('REGISTER_DLG_TITLE')}</DialogTitle>
                <DialogContent>
                    <Box mb={1}>
                        <Typography>{translate('REGISTER_DLG_INFO')}</Typography>
                    </Box>
                    <Box display="flex" flexDirection="column">
                        <Box display="flex" flexDirection="row">
                            <Box my={1} mr={1} flexGrow={1}>
                                <Typography>{translate('FIRSTNAME').toUpperCase()}</Typography>
                                <ShvkTextField
                                    id="firstName"
                                    autoFocus
                                    value={state.firstName}
                                    onChange={(event): void => handleChange(event, 'firstName')}
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    error={calculateError('firstName')}
                                    onFocus={(): void => handleFocusChange('firstName')}
                                    onBlur={(): void => handleFocusChange(undefined)}
                                    placeholder={translate('FIRSTNAME') + '*'}
                                />
                            </Box>
                            <Box my={1} ml={1} flexGrow={1}>
                                <Typography>{translate('LASTNAME').toUpperCase()}</Typography>
                                <ShvkTextField
                                    id="lastName"
                                    value={state.lastName}
                                    onChange={(event): void => handleChange(event, 'lastName')}
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    error={calculateError('lastName')}
                                    onFocus={(): void => handleFocusChange('lastName')}
                                    onBlur={(): void => handleFocusChange(undefined)}
                                    placeholder={translate('LASTNAME') + '*'}
                                />
                            </Box>
                        </Box>
                        <ShvkNotificationBox
                            notificationBoxData={{
                                nameData: { firstName: state.firstName, lastName: state.lastName },
                                fieldFocus: state.fieldFocus,
                            }}
                            currentField={state.fieldFocus === 'firstName' ? 'firstName' : 'lastName'}
                        />
                        <Box display="flex" flexDirection="row">
                            <Box my={1} flexGrow={1}>
                                <Typography>{translate('USERNAME').toUpperCase()}</Typography>
                                <ShvkTextField
                                    id="userName"
                                    value={state.userName}
                                    onChange={(event): void => handleChange(event, 'userName')}
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    error={calculateError('userName')}
                                    onFocus={(): void => handleFocusChange('userName')}
                                    onBlur={(): void => handleFocusChange(undefined)}
                                    placeholder={translate('EMAIL_PLACEHOLDER') + '*'}
                                />
                            </Box>
                        </Box>
                        <ShvkNotificationBox
                            notificationBoxData={{ fieldData: state.userName, fieldFocus: state.fieldFocus }}
                            currentField={'userName'}
                        />
                        <Box display="flex" flexDirection="row">
                            <Box my={1} mr={1} flexGrow={1}>
                                <Typography>{translate('PROFESSION').toUpperCase()}</Typography>
                                <ShvkTextField
                                    id="profession"
                                    value={state.profession}
                                    onChange={(event): void => handleChange(event, 'profession')}
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    placeholder={translate('PROFESSION')}
                                />
                            </Box>
                            <Box my={1} ml={1} flexGrow={1}>
                                <Typography>{translate('UNITORBRANCH').toUpperCase()}</Typography>
                                <ShvkTextField
                                    id="unitOrBranch"
                                    value={state.unitOrBranch}
                                    onChange={(event): void => handleChange(event, 'unitOrBranch')}
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    placeholder={translate('UNITORBRANCH')}
                                />
                            </Box>
                        </Box>
                        <Box display="flex" flexDirection="row">
                            <Box my={1} mr={1} width="50%">
                                <Typography>{translate('PASSWORD').toUpperCase()}</Typography>
                                <ShvkTextField
                                    id="password1"
                                    type="password"
                                    autoComplete="new-password"
                                    value={state.password1}
                                    onChange={(event): void => handleChange(event, 'password1')}
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    error={calculateError('password1')}
                                    onFocus={(): void => handleFocusChange('password1')}
                                    onBlur={(): void => handleFocusChange(undefined)}
                                    placeholder={translate('PASSWORD') + '*'}
                                />
                            </Box>
                            <Box my={1} ml={1} width="50%">
                                <Typography>{translate('RE_ENTER_PASSWORD').toUpperCase()}</Typography>
                                <ShvkTextField
                                    id="password2"
                                    type="password"
                                    autoComplete="new-password"
                                    value={state.password2}
                                    onChange={(event): void => handleChange(event, 'password2')}
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    error={calculateError('password2')}
                                    onFocus={(): void => handleFocusChange('password2')}
                                    onBlur={(): void => handleFocusChange(undefined)}
                                    placeholder={translate('RE_ENTER_PASSWORD') + '*'}
                                />
                            </Box>
                        </Box>
                        <ShvkNotificationBox
                            notificationBoxData={{
                                passwordData: { password1: state.password1, password2: state.password2 },
                                fieldFocus: state.fieldFocus,
                            }}
                            currentField={state.fieldFocus === 'password1' ? 'password1' : 'password2'}
                        />
                    </Box>
                    <Box display="flex" flexDirection="row">
                        <Box my={1} flexGrow={1}>
                            <Typography>{translate('ORGANIZATION').toUpperCase()}</Typography>

                            <Autocomplete
                                id="organization"
                                popupIcon={false}
                                open={
                                    !state.organization &&
                                    Boolean(state.organizationInput) &&
                                    state.fieldFocus === 'organization'
                                }
                                options={allOrganizations}
                                getOptionLabel={(option): string => option.name.toString()}
                                renderOption={(props: object, option): JSX.Element => (
                                    <li {...props} key={option.id}>
                                        {option.name}
                                    </li>
                                )}
                                onChange={handleAutocompleteChange}
                                onInputChange={(_event): void =>
                                    handleChange(_event as TextFieldEvent, 'organizationInput')
                                }
                                onFocus={(): void => handleFocusChange('organization')}
                                onBlur={(): void => handleFocusChange(undefined)}
                                PopperComponent={(props) => (
                                    <Popper {...props} placement="top">
                                        {props.children}
                                    </Popper>
                                )}
                                renderInput={(params): JSX.Element => (
                                    <ShvkTextField
                                        {...params}
                                        id="organization"
                                        value={state.organization}
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        error={calculateError('organization')}
                                        placeholder={translate('ORGANIZATION_PLACEHOLDER') + '*'}
                                    />
                                )}
                            />
                        </Box>
                    </Box>
                    <ShvkNotificationBox
                        notificationBoxData={{
                            fieldData: state.organization?.name,
                            fieldFocus: state.fieldFocus,
                        }}
                        currentField={'organization'}
                    />
                    <Box mt={2}>
                        <Typography>{translate('REGISTER_DLG_INSTRUCTION')}</Typography>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.close} variant="contained">
                        {translate('CANCEL')}
                    </Button>
                    <div style={{ flexGrow: 1 }} />
                    <Button onClick={register} color="secondary" variant="contained">
                        {translate('REGISTER')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default observer(RegisterAsAdminUserDialog);
