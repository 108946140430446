import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import {
    Box,
    Button,
    Checkbox,
    Collapse,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    MenuItem,
    Radio,
    RadioGroup,
    Typography,
} from '@mui/material';
import { Info, InsertDriveFile, KeyboardArrowLeft, KeyboardArrowRight, Publish } from '@mui/icons-material';
import { ApprovalSharedDataOptions, DocumentApprovalParams } from '../../../types/document';
import { TextFieldEvent } from '../../../types/events';
import { TranslationKeys } from '../../../types/translation';
import ShvkTextField from '../../../styled/ShvkTextField';
import ShvkButton from '../../../styled/ShvkButton';
import moment from 'moment';
import useStore from '../../../store/storeContext';

interface Props {
    isOpen: boolean;

    close(): void;
}

type FormCheckboxField = 'addDateString';
type FormTextField = 'date' | 'acceptanceClause' | 'sharedData';
type DialogPages = 'evaApproval' | 'approval';
type EvaMiniOptions = '1' | '2' | '3' | null;

interface State {
    addDateString: boolean;
    date: string;
    acceptanceClause: string;
    sharedData: ApprovalSharedDataOptions;
    activePage: DialogPages;
    evaMiniOption: EvaMiniOptions;
    convertEvaMiniToLarge: boolean;
}

function ApprovalDialog(props: Props) {
    const context = useStore();

    const [state, setState] = useState<State>({
        date: '',
        acceptanceClause: '',
        sharedData: 'DOCUMENT_APPROVAL_SHARE_TEXT_ONLY',
        activePage: 'approval',
        evaMiniOption: null,
        addDateString: false,
        convertEvaMiniToLarge: false,
    });

    useEffect(() => {
        init();
    }, [props.isOpen]);

    useEffect(() => {
        updateDateInAcceptanceClause();
    }, [state.date]);

    useEffect(() => {
        toggleDateInAcceptanceClause();
    }, [state.addDateString]);

    function init(): void {
        const { translate } = context.localization;
        const { currentDocument, isCurrentDocumentEvaMini } = context.document;

        const initDate = moment().startOf('day').format('YYYY-MM-DD');

        setState((state) => ({
            ...state,
            activePage: isCurrentDocumentEvaMini ? 'evaApproval' : 'approval',
            date: initDate,
            addDateString: true,
            sharedData: 'DOCUMENT_APPROVAL_SHARE_TEXT_ONLY',
        }));

        // Set default acceptance clause. Eva mini will be set later.
        switch (currentDocument.type.code) {
            case 'LARGE_REPORT':
            case 'REGIONAL_LARGE_REPORT':
                setState((state) => ({
                    ...state,
                    acceptanceClause:
                        translate('APPROVE_STATEMENT_LARGE_REPORT') + ': ' + moment(initDate).format('DD.MM.YYYY'),
                }));
                break;
            case 'YEARLY_REPORT':
            case 'REGIONAL_YEARLY_REPORT':
            case 'ANOTHER_REPORT':
                setState((state) => ({
                    ...state,
                    acceptanceClause:
                        translate('APPROVE_STATEMENT_YEARLY_REPORT') + ': ' + moment(initDate).format('DD.MM.YYYY'),
                }));
                break;
            case 'EVA':
                setState((state) => ({
                    ...state,
                    acceptanceClause: translate('APPROVE_STATEMENT_EVA') + ': ' + moment(initDate).format('DD.MM.YYYY'),
                }));
                break;
        }
    }

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, field: FormCheckboxField): void => {
        setState((state) => ({ ...state, [field]: event.target.checked }));
    };

    const handleTextFieldChange = (event: TextFieldEvent, field: FormTextField): void => {
        if (field === 'sharedData') {
            setState((state) => ({ ...state, [field]: event.target.value as ApprovalSharedDataOptions }));
            return;
        }
        setState((state) => ({ ...state, [field]: event.target.value }));
    };

    const handleEvaMiniOptionChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setState((state) => ({ ...state, evaMiniOption: event.target.value as EvaMiniOptions }));
    };

    const nextPage = (): void => {
        const { translate } = context.localization;

        switch (state.evaMiniOption) {
            case '1':
                setState((state) => ({
                    ...state,
                    acceptanceClause:
                        translate('APPROVE_STATEMENT_EVA_MINI_OPTIONA') +
                        ': ' +
                        moment(state.date).format('DD.MM.YYYY'),
                    convertEvaMiniToLarge: true,
                }));
                break;
            case '2':
                setState((state) => ({
                    ...state,
                    acceptanceClause:
                        translate('APPROVE_STATEMENT_EVA_MINI_OPTIONB') +
                        ': ' +
                        moment(state.date).format('DD.MM.YYYY'),
                    convertEvaMiniToLarge: false,
                }));
                break;
            case '3':
                setState((state) => ({
                    ...state,
                    acceptanceClause:
                        translate('APPROVE_STATEMENT_EVA_MINI_OPTIONC') +
                        ': ' +
                        moment(state.date).format('DD.MM.YYYY'),
                    convertEvaMiniToLarge: false,
                }));
                break;
        }
        setState((state) => ({ ...state, activePage: 'approval' }));
    };

    const previousPage = (): void => {
        setState((state) => ({ ...state, activePage: 'evaApproval' }));
    };

    const toggleDateInAcceptanceClause = (): void => {
        if (state.addDateString) {
            const dateAdded = state.acceptanceClause + ': ' + moment(state.date).format('DD.MM.YYYY');
            setState((state) => ({ ...state, acceptanceClause: dateAdded }));
        } else {
            const dateRemoved = state.acceptanceClause.substring(0, state.acceptanceClause.indexOf(':'));
            setState((state) => ({ ...state, acceptanceClause: dateRemoved }));
        }
    };

    const updateDateInAcceptanceClause = (): void => {
        let newText = state.acceptanceClause;
        newText = newText.substring(0, newText.indexOf(':'));
        newText += ': ' + moment(state.date).format('DD.MM.YYYY');
        setState((state) => ({ ...state, acceptanceClause: newText }));
    };

    async function save(): Promise<void> {
        props.close();
        try {
            context.loadingIndicator.show();

            const params: DocumentApprovalParams = {
                acceptanceClause: state.acceptanceClause,
                acceptedBy: null,
                acceptedDate: new Date(state.date),
                documentId: context.document.currentDocument.id,
                sharedData: state.sharedData,
            };

            if (context.document.isCurrentDocumentEvaMini && state.convertEvaMiniToLarge) {
                await context.document.convertEvaMiniToLarge(context.document.currentDocument.id);
                await context.document.approveDocument(params);
            } else {
                await context.document.approveDocument(params);
                await context.document.reloadCurrentDocument();
            }
        } catch (e) {
            context.snackbar.showError(e.data?.code);
        } finally {
            context.snackbar.showSuccess();
            context.loadingIndicator.hide();
        }
    }

    function sharedDataOptions(): string[] {
        const { isCurrentDocumentEva } = context.document;
        const options = ['DOCUMENT_APPROVAL_SHARE_TEXT_ONLY'];
        if (!isCurrentDocumentEva) {
            options.push(
                'DOCUMENT_APPROVAL_SHARE_INDICATORS',
                'DOCUMENT_APPROVAL_SHARE_SUMMARY',
                'DOCUMENT_APPROVAL_SHARE_INDICATORDESCRIPTION',
            );
        }
        return options;
    }

    function approvalPage(): JSX.Element {
        const { translate } = context.localization;
        const { currentDocument, isEvaDocument } = context.document;

        return (
            <>
                <Typography sx={{ mb: 4 }}>{translate('DOCUMENT_APPROVE_SUBTITLE')}</Typography>
                <Grid container>
                    <Grid item sm={2} xs={12}>
                        <InsertDriveFile style={{ fontSize: 80 }} color="primary" />
                    </Grid>
                    <Grid item sm={10} xs={12}>
                        <Grid container spacing={2} direction="column">
                            <Grid item>
                                <FormLabel>{translate('DOCUMENT')}</FormLabel>
                                <Typography>{currentDocument.name}</Typography>
                            </Grid>
                            <Grid item>
                                <FormLabel>{translate('DATE')}</FormLabel>
                                <ShvkTextField
                                    type="date"
                                    variant="outlined"
                                    size="small"
                                    value={state.date}
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={(event): void => handleTextFieldChange(event, 'date')}
                                />
                            </Grid>
                            {!isEvaDocument(currentDocument) && (
                                <Grid item>
                                    <FormLabel>{translate('DEFINE_APPROVED_DOCUMENT_DATA')}</FormLabel>
                                    <ShvkTextField
                                        select
                                        value={state.sharedData}
                                        onChange={(event): void => handleTextFieldChange(event, 'sharedData')}
                                        variant="outlined"
                                        fullWidth
                                        size="small"
                                    >
                                        {sharedDataOptions().map((option) => (
                                            <MenuItem value={option} key={option}>
                                                {translate(option as keyof TranslationKeys)}
                                            </MenuItem>
                                        ))}
                                    </ShvkTextField>
                                </Grid>
                            )}
                            <Grid item>
                                <ShvkTextField
                                    rows={4}
                                    multiline
                                    value={state.acceptanceClause}
                                    onChange={(event): void => handleTextFieldChange(event, 'acceptanceClause')}
                                    label={translate('ACCEPTANCE_CLAUSE')}
                                    variant="outlined"
                                    fullWidth
                                    size="small"
                                />
                            </Grid>
                            <Grid item>
                                <FormControlLabel
                                    label={translate('ADD_DATE_TO_ACCEPTANCE_CLAUSE')}
                                    control={
                                        <Checkbox
                                            checked={state.addDateString}
                                            color="primary"
                                            onChange={(event): void => handleCheckboxChange(event, 'addDateString')}
                                        />
                                    }
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </>
        );
    }

    function evaApprovalPage(): JSX.Element {
        const { translate } = context.localization;

        return (
            <FormControl component="fieldset">
                <FormLabel component="legend" id="next-phase-label">
                    {translate('SELECT_DOCUMENT_NEXT_PHASE')}
                </FormLabel>
                <RadioGroup
                    aria-labelledby="next-phase-label"
                    value={state.evaMiniOption}
                    onChange={handleEvaMiniOptionChange}
                >
                    <FormControlLabel value="1" control={<Radio />} label={translate('EVA_APPROVE_OPTION_1_TITLE')} />
                    <Typography sx={{ pl: 4, mb: 2 }} variant="body2">
                        {translate('EVA_APPROVE_OPTION_1_TIP')}
                    </Typography>
                    <Collapse in={state.evaMiniOption === '1'}>
                        <Box pt={1} pl={4} mb={2} display="flex">
                            <Info sx={{ mr: 1 }} color="primary" />
                            <Typography variant="body2">{translate('eva_dokumentin_hyvaksyminen_11')}</Typography>
                        </Box>
                    </Collapse>
                    <FormControlLabel value="2" control={<Radio />} label={translate('EVA_APPROVE_OPTION_2_TITLE')} />
                    <Typography sx={{ pl: 4, mb: 2 }} variant="body2">
                        {translate('EVA_APPROVE_OPTION_2_TIP')}
                    </Typography>
                    <FormControlLabel value="3" control={<Radio />} label={translate('EVA_APPROVE_OPTION_3_TITLE')} />
                    <Typography sx={{ pl: 4, mb: 2 }} variant="body2">
                        {translate('EVA_APPROVE_OPTION_3_TIP')}
                    </Typography>
                </RadioGroup>
            </FormControl>
        );
    }

    const { translate } = context.localization;
    const { isOpen, close } = props;
    const { isCurrentDocumentEvaMini } = context.document;

    return (
        <Dialog open={isOpen} onClose={close} aria-labelledby="approve-document-dialog-title" maxWidth="md">
            <DialogTitle id="approve-document-dialog-title">
                {state.activePage === 'approval' ? translate('DOCUMENT_APPROVE') : translate('FURTHER_ACTIONS')}
            </DialogTitle>
            <DialogContent dividers>
                <>
                    {state.activePage === 'approval' && approvalPage()}
                    {state.activePage === 'evaApproval' && evaApprovalPage()}
                </>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={close}>
                    {translate('CANCEL')}
                </Button>
                <Box flexGrow={1} />
                {isCurrentDocumentEvaMini && (
                    <Button
                        disabled={state.activePage === 'evaApproval'}
                        onClick={previousPage}
                        startIcon={<KeyboardArrowLeft />}
                    >
                        {translate('PREVIOUS')}
                    </Button>
                )}
                {state.activePage === 'approval' ? (
                    <ShvkButton onClick={save} endIcon={<Publish />}>
                        {translate('APPROVE_AND_PUBLISH')}
                    </ShvkButton>
                ) : (
                    <ShvkButton onClick={nextPage} disabled={!state.evaMiniOption} endIcon={<KeyboardArrowRight />}>
                        {translate('NEXT')}
                    </ShvkButton>
                )}
            </DialogActions>
        </Dialog>
    );
}

export default observer(ApprovalDialog);
