import React, { useState, useEffect } from 'react';
import { observer, useLocalObservable } from 'mobx-react-lite';
import { runInAction } from 'mobx';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    MenuItem,
    Tab,
    Tabs,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { EvaTemplateRequest, OrganizationEvaTemplate } from '../../types/evaTemplate';
import { TextFieldEvent } from '../../types/events';
import { EvaNestableItem } from '../../types/nestable';
import ShvkTextField from '../../styled/ShvkTextField';
import DangerButton from '../../styled/DangerButton';
import ShvkButton from '../../styled/ShvkButton';
import EvaStructure from './EvaStructure';
import ShvkTabPanel from '../ShvkTabPanel';
import useStore from '../../store/storeContext';

const PREFIX = 'EvaTemplateDialog';

const classes = {
    paper: `${PREFIX}-paper`,
    dialogTitle: `${PREFIX}-dialogTitle`,
};

const StyledDialog = styled(Dialog)(() => ({
    [`& .${classes.paper}`]: {
        height: '90%',
    },

    [`& .${classes.dialogTitle}`]: {
        paddingBottom: 0,
    },
}));

interface Props {
    open: boolean;
    onClose(): void;
    evaTemplate: OrganizationEvaTemplate | null;
}

interface State {
    activeTab: TabName;
    name: string;
    description: string;
    organizationId: string;
}

interface StoreState {
    evaStructure: EvaNestableItem[];
    removedEvaItems: EvaNestableItem[];
}

type TabName = 'DESCRIPTION' | 'STRUCTURE';
type EvaTemplateDialogField = 'name' | 'description' | 'organizationId';

function EvaTemplateDialog(props: Props) {
    const context = useStore();
    const { organization, dialog, localization, snackbar } = context;

    const [state, setState] = useState<State>({
        activeTab: 'DESCRIPTION',
        name: '',
        description: '',
        organizationId: '',
    });

    const localStore = useLocalObservable<StoreState>(() => ({
        evaStructure: [],
        removedEvaItems: [],
    }));

    useEffect(() => {
        props.open && init();
        return () => {
            setState((state) => ({
                ...state,
                activeTab: 'DESCRIPTION',
                name: '',
                description: '',
                organizationId: '',
            }));
        };
    }, [props.open]);

    function init(): void {
        const { evaTemplate } = props;
        setState((state) => ({
            ...state,
            activeTab: 'DESCRIPTION',
            name: evaTemplate?.title || '',
            description: evaTemplate?.description || '',
            organizationId: evaTemplate?.organization.id.toString() || '',
        }));
        runInAction(() => {
            localStore.evaStructure = [];
            localStore.removedEvaItems = [];
        });
    }

    async function save(): Promise<void> {
        try {
            const requestTemplate: EvaTemplateRequest = {
                description: state.description,
                evaModel: { decisionOptions: [], removedItems: [] },
                organization: { id: Number(state.organizationId) },
                templateId: props.evaTemplate?.id || -1,
                title: state.name,
            };

            requestTemplate.evaModel.decisionOptions = localStore.evaStructure.map((decisionOption) => {
                return {
                    description: decisionOption.description,
                    text: decisionOption.text,
                    perspectives: decisionOption.children.map((perspective) => {
                        return {
                            text: perspective.text,
                            impactOnTargets: perspective.children.map((impact) => {
                                return {
                                    description: impact.description,
                                    text: impact.text,
                                };
                            }),
                        };
                    }),
                };
            });

            requestTemplate.evaModel.removedItems = localStore.removedEvaItems.map((item) => {
                let type: string;
                if (item.depth === 1) type = 'option';
                else if (item.depth === 2) type = 'perspective';
                else type = 'target';

                return {
                    id: item.id,
                    type,
                };
            });

            await context.evaTemplate.saveEvaTemplate(requestTemplate);
            snackbar.showSuccess();
        } catch (error) {
            snackbar.showError(error.data?.code);
        } finally {
            props.onClose();
        }
    }

    async function removeTemplate(): Promise<void> {
        try {
            const confirmation = await dialog.getConfirmation(
                undefined,
                translate('GENERAL_CONFIRMATION_MESSAGE'),
                true,
            );
            if (props.evaTemplate && confirmation) {
                await context.evaTemplate.removeEvaTemplate(props.evaTemplate.id);
                snackbar.showSuccess();
                props.onClose();
            }
        } catch (error) {
            snackbar.showError(error.data?.code);
        }
    }

    function setEvaStructure(list: EvaNestableItem[]): void {
        runInAction(() => (localStore.evaStructure = list));
    }

    function addToRemovedEvaItems(item: EvaNestableItem): void {
        runInAction(() => localStore.removedEvaItems.push(item));
    }

    function structureTab(): JSX.Element {
        return (
            <EvaStructure
                decisionOptions={props.evaTemplate?.decisionOptions}
                list={localStore.evaStructure}
                setList={setEvaStructure}
                addToRemovedEvaItems={addToRemovedEvaItems}
            />
        );
    }

    const handleTabChange = (_event: React.ChangeEvent<unknown>, newIndex: TabName): void => {
        setState((state) => ({ ...state, activeTab: newIndex }));
    };

    const handleChange = (event: TextFieldEvent, field: EvaTemplateDialogField): void => {
        setState((state) => ({ ...state, [field]: event.target.value }));
    };

    function descriptionTab(): JSX.Element {
        const { translate } = localization;
        const { evaTemplate } = props;
        return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <ShvkTextField
                        required
                        label={translate('ORGANIZATION_EVA_TEMPLATE_NAME')}
                        value={state.name}
                        variant="outlined"
                        size="small"
                        fullWidth
                        onChange={(event): void => handleChange(event, 'name')}
                    />
                </Grid>
                <Grid item xs={12}>
                    <ShvkTextField
                        multiline
                        rows={6}
                        label={translate('ORGANIZATION_EVA_TEMPLATE_DESCRIPTION')}
                        value={state.description}
                        variant="outlined"
                        size="small"
                        fullWidth
                        onChange={(event): void => handleChange(event, 'description')}
                    />
                </Grid>
                <Grid item xs={12}>
                    <ShvkTextField
                        required
                        select
                        label={translate('ORGANIZATION')}
                        value={state.organizationId}
                        variant="outlined"
                        size="small"
                        fullWidth
                        onChange={(event): void => handleChange(event, 'organizationId')}
                        disabled={Boolean(evaTemplate)}
                        SelectProps={{ MenuProps: { sx: { maxHeight: 300 } } }}
                    >
                        {organization.adminOrganizations
                            .slice()
                            .sort((a, b) => (a.name > b.name ? 1 : -1))
                            .map((organization) => (
                                <MenuItem key={organization.id} value={organization.id}>
                                    {organization.name}
                                </MenuItem>
                            ))}
                    </ShvkTextField>
                </Grid>
            </Grid>
        );
    }

    function getIsSaveButtonDisabled(): boolean {
        return !state.organizationId || !state.name || localStore.evaStructure.length === 0;
    }

    const isSaveButtonDisabled = getIsSaveButtonDisabled();
    const translate = localization.translate;

    return (
        <StyledDialog
            open={props.open}
            onClose={props.onClose}
            aria-labelledby="eva-template-dialog-title"
            maxWidth={'md'}
            fullWidth
            classes={{
                paper: classes.paper,
            }}
        >
            <DialogTitle classes={{ root: classes.dialogTitle }}>
                <span id="eva-template-dialog-title">{translate('ORGANIZATION_EVA_TEMPLATE')}</span>
                <Tabs
                    value={state.activeTab}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={handleTabChange}
                    variant="fullWidth"
                    aria-labelledby="eva-template-dialog-title"
                >
                    <Tab label={translate('ORGANIZATION_EVA_TEMPLATE_DESCRIPTION')} value="DESCRIPTION" />
                    <Tab label={translate('EVA_OPTIONS_PERSPECTIVES_TARGETS')} value="STRUCTURE" />
                </Tabs>
            </DialogTitle>
            <DialogContent dividers>
                <ShvkTabPanel value={'DESCRIPTION'} activePanel={state.activeTab}>
                    {descriptionTab()}
                </ShvkTabPanel>
                <ShvkTabPanel value={'STRUCTURE'} activePanel={state.activeTab}>
                    {structureTab()}
                </ShvkTabPanel>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose} variant="contained">
                    {translate('CLOSE')}
                </Button>
                {props.evaTemplate && (
                    <DangerButton onClick={removeTemplate} variant="contained">
                        {translate('REMOVE')}
                    </DangerButton>
                )}
                <Box flexGrow={1} />
                <ShvkButton onClick={save} disabled={isSaveButtonDisabled}>
                    {translate('SAVE')}
                </ShvkButton>
            </DialogActions>
        </StyledDialog>
    );
}

export default observer(EvaTemplateDialog);
