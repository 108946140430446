import React from 'react';
import { observer, useLocalObservable } from 'mobx-react-lite';
import { Grid } from '@mui/material';
import { Chapter } from '../../types/document';
import EditorChapter from './EditorChapter';
import useStore from '../../store/storeContext';

interface StoreState {
    showSubAreas: boolean;
    chapters: Chapter[];
    toggleShowSubAreas: () => void;
}

function DocumentChapters() {
    const { document } = useStore();

    const localStore = useLocalObservable<StoreState>(() => ({
        showSubAreas: false,

        get chapters(): Chapter[] {
            let chapters = document.currentDocument.chapters;
            if (!this.showSubAreas) {
                chapters = chapters.filter((chapter) => !chapter.isCompareToCountiesSubArea);
            }
            return chapters;
        },

        toggleShowSubAreas(): void {
            this.showSubAreas = !this.showSubAreas;
        },
    }));

    return (
        <Grid container spacing={6}>
            {localStore.chapters.map((chapter) => (
                <Grid item xs={12} key={chapter.id} id={'chapter-' + chapter.orderNumber}>
                    <EditorChapter
                        chapter={chapter}
                        showSubAreas={localStore.showSubAreas}
                        toggleShowSubAreas={localStore.toggleShowSubAreas}
                    />
                </Grid>
            ))}
        </Grid>
    );
}

export default observer(DocumentChapters);
