import React, { useState, useEffect, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Button, Fade, MobileStepper, Paper } from '@mui/material';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import useStore from '../../store/storeContext';

const IMAGE_HEIGHT: Readonly<number> = 250;
const TIMEOUT: Readonly<number> = 15000;

function Carousel() {
    const { image, localization } = useStore();

    const timer = useRef<ReturnType<typeof setTimeout> | undefined>(undefined);
    const activeStep = useRef<number>(0);

    const [showImage, setShowImage] = useState<boolean>(true);

    useEffect(() => {
        init();
        return () => {
            clearTimeout(timer.current);
        };
    }, []);

    function init() {
        setShowImage(true);
        activeStep.current = 0;
        timer.current = setTimeout(() => handleNext(), TIMEOUT);
    }

    function onExited(): void {
        // Start the fade in animation
        setShowImage(true);
    }

    function redirectURLOnClick(url?: string): void {
        if (url) {
            window.open(url);
        }
    }

    const handleNext = (): void => {
        resetTimer();
        const stepCount = image.activeCarouselImages.length;

        // Start the fade-out animation
        setShowImage(false);

        activeStep.current = activeStep.current >= stepCount - 1 ? 0 : activeStep.current + 1;
    };

    const handleBack = (): void => {
        resetTimer();
        setShowImage(false);

        activeStep.current = activeStep.current <= 0 ? stepCount - 1 : activeStep.current - 1;
    };

    const resetTimer = (): void => {
        clearTimeout(timer.current);
        timer.current = setTimeout(() => handleNext(), TIMEOUT);
    };

    const { translate } = localization;
    const stepCount = image.activeCarouselImages.length;

    if (stepCount === 0) {
        return null;
    }

    return (
        <Paper sx={{ mb: 3 }}>
            <Box sx={{ height: IMAGE_HEIGHT, p: 2 }}>
                <Box sx={{ position: 'relative' }}>
                    {image.activeCarouselImages.map((image, index) => (
                        <Fade key={image.id} onExited={onExited} in={activeStep.current === index && showImage}>
                            <img
                                style={{
                                    cursor: image.redirectURL ? 'pointer' : 'default',
                                    position: 'absolute',
                                    width: '100%',
                                    height: IMAGE_HEIGHT,
                                }}
                                alt=""
                                src={`data:image/png;base64,${image.carouselImage}`}
                                onClick={(): void => redirectURLOnClick(image.redirectURL)}
                            />
                        </Fade>
                    ))}
                </Box>
            </Box>
            <MobileStepper
                sx={{ borderBottomLeftRadius: '4px', borderBottomRightRadius: '4px' }}
                variant="dots"
                steps={stepCount}
                position="static"
                activeStep={activeStep.current}
                nextButton={
                    <Button size="small" onClick={handleNext}>
                        {translate('NEXT')}
                        {<KeyboardArrowRight />}
                    </Button>
                }
                backButton={
                    <Button size="small" onClick={handleBack}>
                        {<KeyboardArrowLeft />}
                        {translate('PREVIOUS')}
                    </Button>
                }
            />
        </Paper>
    );
}

export default observer(Carousel);
