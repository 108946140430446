import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormLabel,
    Grid,
    Radio,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import { TextFieldEvent } from '../../types/events';
import ShvkButton from '../../styled/ShvkButton';
import ShvkTextField from '../../styled/ShvkTextField';
import moment from 'moment';
import useStore from '../../store/storeContext';

interface Props {
    open: boolean;
    onClose(): void;
    addLicense(licenseSchemaId: number, startDate: string, endDate: string): void;
}

type SelectLicenseFormField = 'startDate' | 'endDate';

interface State {
    startDate: string;
    endDate: string;
    selectedLicenseSchemaId: number | null;
}

function SelectLicenseDialog(props: Props) {
    const { admin, localization } = useStore();

    const [state, setState] = useState<State>({
        startDate: '',
        endDate: '',
        selectedLicenseSchemaId: null,
    });

    useEffect(() => {
        init();
    }, [props.open]);

    const init = (): void => {
        setState({
            ...state,
            selectedLicenseSchemaId: null,
            startDate: moment().format('YYYY-MM-DD'),
            endDate: moment().add(1, 'years').format('YYYY-MM-DD'),
        });
    };

    const handleDateChange = (event: TextFieldEvent, field: SelectLicenseFormField): void => {
        setState((state) => ({
            ...state,
            [field]: event.target.value,
        }));
    };

    const handleRowClick = (_event: React.MouseEvent<unknown>, id: number): void => {
        setState((state) => ({
            ...state,
            selectedLicenseSchemaId: id,
        }));
    };

    const isSaveButtonDisabled = !state.selectedLicenseSchemaId || !state.startDate || !state.endDate;

    const { translate } = localization;

    return (
        <Dialog open={props.open} onClose={props.onClose} aria-labelledby="select-license-dialog-title" maxWidth={'md'}>
            <DialogTitle id="select-license-dialog-title">{translate('LICENSE')}</DialogTitle>
            <DialogContent dividers>
                <Box mb={2}>
                    <Typography id="license-table-description">{translate('LICENSE_TABLE_DESCRIPTION')}</Typography>
                </Box>
                <Table
                    aria-label={translate('LICENSE_SELECT_TIP')}
                    aria-describedby="license-table-description"
                    size="small"
                >
                    <TableHead>
                        <TableRow>
                            <TableCell padding="checkbox" />
                            <TableCell>{translate('DESCRIPTION')}</TableCell>
                            <TableCell>{translate('YEARLY_REPORT')}</TableCell>
                            <TableCell>{translate('LARGE_REPORT')}</TableCell>
                            <TableCell>{translate('EVA')}</TableCell>
                            <TableCell>{translate('EVA_MINI')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {admin.licenseSchemas.map((row) => (
                            <TableRow
                                key={row.id}
                                hover
                                style={{ cursor: 'pointer' }}
                                role="checkbox"
                                aria-checked={state.selectedLicenseSchemaId === row.id}
                                selected={state.selectedLicenseSchemaId === row.id}
                                onClick={(event): void => handleRowClick(event, row.id)}
                            >
                                <TableCell padding="checkbox">
                                    <Radio checked={state.selectedLicenseSchemaId === row.id} />
                                </TableCell>
                                <TableCell>{row.description}</TableCell>
                                <TableCell>{row.yearlyReport}</TableCell>
                                <TableCell>{row.largeReport}</TableCell>
                                <TableCell>{row.eva}</TableCell>
                                <TableCell>{row.evaMini}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <Box mt={4}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <FormLabel component="legend">{translate('LICENSE_EXPIRES')}</FormLabel>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <ShvkTextField
                                fullWidth
                                label={translate('START_DATE')}
                                type="date"
                                variant="outlined"
                                size="small"
                                value={state.startDate}
                                onChange={(event): void => handleDateChange(event, 'startDate')}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <ShvkTextField
                                fullWidth
                                label={translate('END_DATE')}
                                type="date"
                                variant="outlined"
                                size="small"
                                value={state.endDate}
                                onChange={(event): void => handleDateChange(event, 'endDate')}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose} variant="contained">
                    {translate('CLOSE')}
                </Button>
                <Box flexGrow={1} />
                <ShvkButton
                    onClick={(): void =>
                        props.addLicense(state.selectedLicenseSchemaId!, state.startDate, state.endDate)
                    }
                    disabled={isSaveButtonDisabled}
                >
                    {translate('ADD')}
                </ShvkButton>
            </DialogActions>
        </Dialog>
    );
}

export default observer(SelectLicenseDialog);
