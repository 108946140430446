import React from 'react';
import { observer } from 'mobx-react-lite';
import { runInAction } from 'mobx';
import { IconButton, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Save } from '@mui/icons-material';
import { TextFieldEvent } from '../../../../types/events';
import { EditorPerspective } from '../../../../types/evaTemplate';
import ShvkTextField from '../../../../styled/ShvkTextField';
import useStore, { rootModule } from '../../../../store/storeContext';

const PREFIX = 'EvaPerspectiveTable';

const classes = {
    table: `${PREFIX}-table`,
    tableCell: `${PREFIX}-tableCell`,
};

const { theming } = rootModule;

const StyledTable = styled(Table)(({ theme }) => ({
    [`&.${classes.table}`]: {
        marginBottom: theme.spacing(2),
    },

    [`& .${classes.tableCell}`]: {
        border: '1px solid' + theming.shvkLightGrey,
    },
}));

interface Props {
    showCommentsTextField?: boolean;
    perspective: EditorPerspective;
    editing: boolean;
    children: JSX.Element | JSX.Element[];
}

function EvaPerspectiveTable(props: Props) {
    const context = useStore();

    function getIsEditable(): boolean {
        return !context.document.isCurrentDocumentApproved && !context.document.isPreview && props.editing;
    }

    const isEditable = getIsEditable();

    const handleChange = (event: TextFieldEvent): void => {
        runInAction(() => (props.perspective.comments = event.target.value));
    };

    const handleBlur = (): void => {
        void context.document.saveEvaPerspective(props.perspective.id, props.perspective.comments!);
    };

    async function save(): Promise<void> {
        try {
            context.loadingIndicator.show();
            await context.document.saveEvaPerspective(props.perspective.id, props.perspective.comments!);
            context.snackbar.showSuccess();
        } catch (error) {
            context.snackbar.showError(error.data?.code);
        } finally {
            context.loadingIndicator.hide();
        }
    }

    const { children, showCommentsTextField } = props;
    const { translate } = context.localization;

    return (
        <StyledTable className={classes.table}>
            <TableHead>
                <TableRow>
                    <TableCell className={classes.tableCell} style={{ width: !isEditable ? '' : 340 }}>
                        {translate('EVA_IMPACT_ON_TARGET')}
                    </TableCell>
                    <TableCell className={classes.tableCell} id="eva-table-header-short-term">
                        {translate('EVA_SHORT_TERM_EFFECT')}
                    </TableCell>
                    <TableCell className={classes.tableCell} id="eva-table-header-long-term">
                        {translate('EVA_LONG_TERM_EFFECT')}
                    </TableCell>
                    <TableCell className={classes.tableCell}>{translate('EVA_INDICATORS')}</TableCell>
                    {!isEditable ? null : <TableCell className={classes.tableCell} padding="checkbox" />}
                </TableRow>
            </TableHead>
            <TableBody>
                {children}
                {isEditable && showCommentsTextField ? (
                    <TableRow>
                        <TableCell className={classes.tableCell} colSpan={4}>
                            <ShvkTextField
                                label={translate('EVA_DOCUMENT_PERSPECTIVE_COMMENTS__TIP')}
                                multiline
                                rows={5}
                                value={props.perspective.comments || ''}
                                variant="outlined"
                                size="small"
                                fullWidth
                                onChange={(event) => handleChange(event)}
                                onBlur={handleBlur}
                            />
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                            <IconButton color="primary" onClick={save} size="large">
                                <Save />
                            </IconButton>
                        </TableCell>
                    </TableRow>
                ) : null}
            </TableBody>
        </StyledTable>
    );
}

export default observer(EvaPerspectiveTable);
