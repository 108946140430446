import React, { useEffect } from 'react';
import { observer, useLocalObservable } from 'mobx-react-lite';
import { runInAction } from 'mobx';
import Nestable, { NestableItem, RenderCollapseIconArgs, RenderItemArgs } from 'react-nestable';
import { addDepths, addItem, addParentIds, editText, getDepth, removeItem } from '../../../../utils/NestableUtils';
import {
    Indicator,
    IndicatorPackage,
    IndicatorPackageTheme,
    RemovedItemsParams,
    SaveIndicatorModelItem,
    SaveIndicatorModelParams,
} from '../../../../types/indicator';
import { Info, KeyboardArrowDown, KeyboardArrowUp, Save } from '@mui/icons-material';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Chapter } from '../../../../types/document';
import { IndicatorNestableItem, IndicatorRenderItemArgs } from '../../../../types/nestable';
import ShvkButton from '../../../../styled/ShvkButton';
import IndicatorNestableComponent from '../../shvk/IndicatorNestableComponent';
import useStore from '../../../../store/storeContext';

const PREFIX = 'IndicatorModelDialog';

const classes = {
    paper: `${PREFIX}-paper`,
};

const StyledDialog = styled(Dialog)(() => ({
    [`& .${classes.paper}`]: {
        height: '90%',
    },
}));

interface Props {
    isOpen: boolean;
    close(): void;
}

interface StoreState {
    items: IndicatorNestableItem[];
    removedItems: RemovedItemsParams[];
    getItems: IndicatorNestableItem[];
    indicatorPackages: IndicatorPackage[];
    indicatorModel: SaveIndicatorModelParams;
}

let nextNewId = -1;

function IndicatorModelDialog(props: Props) {
    const { document, indicator, dialog, localization, loadingIndicator, snackbar, theming } = useStore();

    const localStore = useLocalObservable<StoreState>(() => ({
        items: [],
        removedItems: [],

        get getItems(): IndicatorNestableItem[] {
            return this.items;
        },

        get indicatorPackages(): IndicatorPackage[] {
            return document.currentDocument.chapters.find((chapter: Chapter) => chapter.isIndicatorArea)!
                .indicatorPackages;
        },

        get indicatorModel(): SaveIndicatorModelParams {
            const removedItems = this.removedItems;
            const packages = this.getItems.map((indicatorNestableItem: IndicatorNestableItem) => {
                return {
                    id: indicatorNestableItem.id,
                    themes: getAllChildren(indicatorNestableItem),
                    added: indicatorNestableItem.id < 0,
                    name: indicatorNestableItem.text,
                };
            });
            return { packages, removedItems };
        },
    }));

    useEffect(() => {
        props.isOpen && init();
        return () => {
            nextNewId = -1;
        };
    }, [props.isOpen]);

    async function init(): Promise<void> {
        runInAction(() => (localStore.removedItems = []));
        await getData();
        setItems(createNewItems());
    }

    async function getData(): Promise<void> {
        try {
            loadingIndicator.show();
            if (localStore.indicatorPackages) {
                const packagesWithoutThemes: Array<number> = [];
                localStore.indicatorPackages.forEach((indicatorPackage: IndicatorPackage) => {
                    if (indicatorPackage.themes.length === 0) {
                        packagesWithoutThemes.push(indicatorPackage.id);
                    }
                });
                if (packagesWithoutThemes.length > 0) {
                    await Promise.all(
                        packagesWithoutThemes.map((indicatorPackageId: number) =>
                            indicator.fetchPackageThemes(indicatorPackageId),
                        ),
                    );
                }
            }
        } catch (error) {
            snackbar.showError(error.data?.code);
        } finally {
            loadingIndicator.hide();
        }
    }

    async function handleReset(): Promise<void> {
        const { translate } = localization;

        const confirmTitle = translate('GENERAL_CONFIRMATION_TITLE');
        const confirmText = translate('CONFIRM_INDICATOR_MODEL_RESET');
        const confirmation = await dialog.getConfirmation(confirmTitle, confirmText, true);
        if (!confirmation) return;

        try {
            loadingIndicator.show();
            await document.resetIndicatorModel();
            await init();
            snackbar.showSuccess();
        } catch (error) {
            snackbar.showError(error.data?.code);
        } finally {
            loadingIndicator.hide();
        }
    }

    async function save(): Promise<void> {
        try {
            loadingIndicator.show();
            await document.updateDocumentIndicatorAreaChapter(localStore.indicatorModel);
            await init();
            snackbar.showSuccess();
        } catch (error) {
            snackbar.showError(error.data?.code);
        } finally {
            loadingIndicator.hide();
        }
    }

    function setItems(newItems: IndicatorNestableItem[]): void {
        runInAction(() => (localStore.items = newItems));
    }

    function createNewItems(): IndicatorNestableItem[] {
        if (localStore.indicatorPackages) {
            return localStore.indicatorPackages.map((indicatorPackage: IndicatorPackage) => {
                return createLevel1Item(indicatorPackage);
            });
        } else return [] as IndicatorNestableItem[];
    }

    function getAllChildren(indicatorNestableItem: IndicatorNestableItem): SaveIndicatorModelItem[] {
        const children: SaveIndicatorModelItem[] = [];
        indicatorNestableItem.children.forEach((themeLevel0: IndicatorNestableItem) => {
            children.push({
                id: themeLevel0.id,
                added: themeLevel0.id < 0,
                name: themeLevel0.text,
                level: 0,
                pkg: themeLevel0.parentId,
            });
            themeLevel0.children.forEach((themeLevel1: IndicatorNestableItem) => {
                children.push({
                    id: themeLevel1.id,
                    added: themeLevel1.id < 0,
                    name: themeLevel1.text,
                    level: 1,
                    pkg: themeLevel0.parentId,
                    sotkanetIndicators: addIndicators(themeLevel1, 'CHP_SOTKANET'),
                    organizationIndicators: addIndicators(themeLevel1, 'CHP_CUSTOM'),
                    otherIndicators: addIndicators(themeLevel1, 'CHP_OTHER'),
                });
            });
        });
        return children;
    }

    function addIndicators(theme: IndicatorNestableItem, source: string): SaveIndicatorModelItem[] {
        const indicators = theme.children.filter((child: IndicatorNestableItem) => child.indicatorSource === source);
        return indicators.map((indicator: IndicatorNestableItem) => {
            return {
                id: indicator.id,
                added: indicator.id < 0,
                name: indicator.text,
                orderNumber: theme.children.findIndex((ind: IndicatorNestableItem) => ind.id === indicator.id) + 1,
            };
        });
    }

    function createLevel1Item(indicatorPackage: IndicatorPackage): IndicatorNestableItem {
        return {
            id: indicatorPackage.id,
            children: addChildrenToLevel1Item(indicatorPackage),
            parentId: null,
            depth: 1,
            text: indicatorPackage.name,
            isIndicator: false,
            isRemovable: true,
        };
    }

    function addChildrenToLevel1Item(indicatorPackage: IndicatorPackage): IndicatorNestableItem[] {
        return indicatorPackage.themes
            .filter((indicatorPackageTheme: IndicatorPackageTheme) => indicatorPackageTheme.level === 0)
            .map((indicatorPackageTheme: IndicatorPackageTheme) => {
                return createLevel2Item(indicatorPackage, indicatorPackageTheme);
            });
    }

    function createLevel2Item(
        indicatorPackage: IndicatorPackage,
        indicatorPackageTheme: IndicatorPackageTheme,
    ): IndicatorNestableItem {
        return {
            id: indicatorPackageTheme.id,
            children: addChildrenToLevel2Item(indicatorPackage, indicatorPackageTheme.id),
            parentId: indicatorPackageTheme.pkgId,
            depth: 2,
            text: indicatorPackageTheme.name,
            isIndicator: false,
            isRemovable: true,
        };
    }

    function addChildrenToLevel2Item(indicatorPackage: IndicatorPackage, parentId: number): IndicatorNestableItem[] {
        const children: IndicatorNestableItem[] = [];
        let filter = true;

        indicatorPackage.themes.forEach((indicatorPackageTheme: IndicatorPackageTheme) => {
            if (indicatorPackageTheme.level === 1 && !filter)
                children.push(createLevel3Item(indicatorPackageTheme, parentId));
            else if (indicatorPackageTheme.level === 0 && indicatorPackageTheme.id === parentId) filter = false;
            else if (indicatorPackageTheme.level === 0 && indicatorPackageTheme.id !== parentId) filter = true;
        });
        return children;
    }

    function createLevel3Item(indicatorPackageTheme: IndicatorPackageTheme, parentId: number): IndicatorNestableItem {
        return {
            id: indicatorPackageTheme.id,
            children: addChildrenToLevel3Item(indicatorPackageTheme, indicatorPackageTheme.id),
            parentId: parentId,
            depth: 3,
            text: indicatorPackageTheme.name,
            isIndicator: false,
            isRemovable: true,
        };
    }

    function addChildrenToLevel3Item(
        indicatorPackageTheme: IndicatorPackageTheme,
        parentId: number,
    ): IndicatorNestableItem[] {
        const allIndicators = indicator.sortByOrderNumber([
            ...indicatorPackageTheme.sotkanetIndicators,
            ...indicatorPackageTheme.organizationIndicators,
            ...indicatorPackageTheme.otherIndicators,
        ]);
        return allIndicators.map((indicator: Indicator) => {
            return createIndicatorItem(indicator, parentId);
        });
    }

    function createIndicatorItem(indicator: Indicator, parentId: number): IndicatorNestableItem {
        return {
            id: indicator.id,
            children: [],
            parentId: parentId,
            depth: 4,
            text: indicator.name,
            isIndicator: true,
            indicatorSource: indicator.source.code,
            indicatorType: indicator.type.code,
            orderNumber: indicator.orderNumber,
            isRemovable: indicator.isRemovable,
        };
    }

    function removeIndicatorNestableItem(item: IndicatorNestableItem): void {
        const newItems = localStore.getItems.filter(removeItem(item));
        setItems(newItems as IndicatorNestableItem[]);

        const id = item.id;
        const itemType =
            !item.isIndicator && item.depth === 1
                ? 'package'
                : !item.isIndicator && (item.depth === 2 || item.depth === 3)
                ? 'theme'
                : '';
        const code = document.findIndicatorById(item.id)?.source.code;
        const source = { code: code };
        runInAction(() => localStore.removedItems.push({ id, itemType, source }));
    }

    function addIndicatorNestableItem(parentId: number | null = null, isIndicator = false, isRemovable = true): void {
        const { translate } = localization;
        const depth = parentId === null ? 1 : getDepth(parentId, localStore.getItems) + 1;
        const newItem: IndicatorNestableItem = {
            id: nextNewId--,
            text: depth === 1 ? translate('NEW_MAIN_DIRECTORY') : translate('NEW_SUB_DIRECTORY'),
            children: [],
            parentId,
            depth,
            isIndicator,
            isRemovable,
        };
        const currentItems: IndicatorNestableItem[] = [...localStore.getItems];
        if (parentId !== null) {
            // A new subdirectory
            const newItems = currentItems.map(addItem(newItem, parentId));
            setItems(newItems as IndicatorNestableItem[]);
        } else {
            // A new main directory
            const newItems = [...currentItems, newItem];
            handleListChange(newItems);
        }
    }

    function confirmChange(dragItem: NestableItem, destinationParent: NestableItem): boolean {
        const indicatorDragItem = dragItem as IndicatorNestableItem;
        const indicatorDestinationParent = destinationParent as IndicatorNestableItem;
        //Package item can't change depth
        if (indicatorDragItem.depth === 1 && indicatorDestinationParent) return false;
        //Theme must be child to level1 or level 2 item
        else if (
            (indicatorDragItem.depth === 2 || indicatorDragItem.depth === 3) &&
            !(indicatorDestinationParent?.depth === 1 || indicatorDestinationParent?.depth === 2)
        )
            return false;
        //Indicator must be child to level 3 Theme
        else if (indicatorDragItem.isIndicator && indicatorDestinationParent?.depth !== 3) return false;
        //Item with children can't change depth
        else if (
            indicatorDragItem.children.length > 0 &&
            indicatorDestinationParent?.depth !== (indicatorDragItem.depth - 1 || undefined)
        )
            return false;
        else return true;
    }

    function renderItem(args: RenderItemArgs): React.ReactNode {
        return (
            <IndicatorNestableComponent
                args={args as IndicatorRenderItemArgs}
                handleTextChange={handleTextChange}
                addItem={addIndicatorNestableItem}
                removeItem={removeIndicatorNestableItem}
                handleAddIndicator={handleAddIndicator}
                save={save}
            />
        );
    }

    function renderCollapseIcon(args: RenderCollapseIconArgs): JSX.Element {
        return (
            <IconButton size="small" color="primary">
                {args.isCollapsed ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
            </IconButton>
        );
    }

    const handleTextChange = (value: string, id: number): void => {
        const newItems = localStore.getItems.map(editText(id, value));
        setItems(newItems as IndicatorNestableItem[]);
    };

    const handleListChange = (items: NestableItem[]): void => {
        const indicatorItems = items as IndicatorNestableItem[];
        let newItems = indicatorItems.map(addParentIds());
        newItems = newItems.map(addDepths());
        setItems(newItems as IndicatorNestableItem[]);
    };

    const handleAddIndicator = (indicator: Indicator, parentId: number): void => {
        const newIndicator = createIndicatorItem(indicator, parentId);
        const indicators = [...localStore.getItems];
        indicators.forEach((item) => {
            item.children.forEach((child) => {
                child.children.forEach((theme) => {
                    if (theme.id === parentId) {
                        theme.children.push(newIndicator);
                    }
                });
            });
        });
        handleListChange(indicators);
    };

    const { translate } = localization;

    return (
        <StyledDialog
            id="chapter-model-dialog"
            open={props.isOpen}
            onClose={props.close}
            maxWidth="md"
            fullWidth
            classes={{ paper: classes.paper }}
        >
            <DialogTitle id="chapter-model-dialog-title">
                {translate('DOCUMENT_INDICATOR_MODEL_EDIT_TITLE')}
                <Box pt={1} display="flex">
                    <Info style={{ marginRight: theming.spacing(1) }} color="primary" />
                    <Typography variant="body2">{translate('DOCUMENT_INDICATOR_MODEL_EDIT_TIP')}</Typography>
                </Box>
            </DialogTitle>
            <DialogContent>
                <Nestable
                    items={localStore.getItems}
                    renderItem={renderItem}
                    onChange={handleListChange}
                    confirmChange={confirmChange}
                    maxDepth={4}
                    renderCollapseIcon={renderCollapseIcon}
                    collapsed={true}
                />
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={handleReset}>
                    {translate('INDICATOR_MODEL_SET_AS_DEFAULT')}
                </Button>
                <ShvkButton
                    onClick={(): void => {
                        addIndicatorNestableItem(null);
                    }}
                >
                    {translate('ADD_INDICATOR_PACKAGE')}
                </ShvkButton>
                <Box flexGrow={1} />
                <Button variant="contained" onClick={props.close}>
                    {translate('CLOSE')}
                </Button>
                <ShvkButton startIcon={<Save />} onClick={save}>
                    {translate('SAVE')}
                </ShvkButton>
            </DialogActions>
        </StyledDialog>
    );
}

export default observer(IndicatorModelDialog);
