import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { IndicatorPackage } from '../../../types/indicator';
import FroalaEditorComponent from 'react-froala-wysiwyg';
import useStore from '../../../store/storeContext';

interface Props {
    indicatorPackage: IndicatorPackage;
}

interface State {
    notes: string;
}

function FroalaIndicatorNote(props: Props) {
    const { document } = useStore();

    const [state, setState] = useState<State>({
        notes: '',
    });

    useEffect(() => {
        setState((state) => ({ ...state, notes: props.indicatorPackage.notes }));
    }, []);

    function onModelChange(notes: string): void {
        setState((state) => ({ ...state, notes: notes }));
        void document.savePackage({ ...props.indicatorPackage, notes });
    }

    const { chapterId } = props.indicatorPackage;

    return (
        <FroalaEditorComponent
            model={state.notes}
            onModelChange={onModelChange}
            config={{
                ...document.froalaConfig,
                imageUploadParams: { chapterId: chapterId },
                fileUploadParams: { chapterId: chapterId },
            }}
        />
    );
}

export default observer(FroalaIndicatorNote);
