import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormLabel, Grid } from '@mui/material';
import { EditorPerspective, ImpactOnTargetValue } from '../../../../types/evaTemplate';
import { TextFieldEvent } from '../../../../types/events';
import { Chapter } from '../../../../types/document';
import ShvkTextField from '../../../../styled/ShvkTextField';
import ShvkButton from '../../../../styled/ShvkButton';
import EvaSlider from '../../eva/EvaSlider';
import useStore from '../../../../store/storeContext';

interface Props {
    perspective?: EditorPerspective | null;
    isOpen: boolean;
    close(): void;
}

interface State {
    title: string;
    description: string;
    shortTermEffect: ImpactOnTargetValue;
    longTermEffect: ImpactOnTargetValue;
}

type FormField = 'title' | 'description' | 'shortTermEffect' | 'longTermEffect';

function AddImpactOnTargetDialog(props: Props) {
    const { document, localization, loadingIndicator, snackbar } = useStore();

    const [state, setState] = useState<State>({
        title: '',
        description: '',
        shortTermEffect: 0,
        longTermEffect: 0,
    });

    useEffect(() => {
        props.isOpen && init();
    }, [props.isOpen]);

    function init(): void {
        setState((state) => ({
            ...state,
            title: '',
            description: '',
            shortTermEffect: 0,
            longTermEffect: 0,
        }));
    }

    const handleChange = (event: TextFieldEvent, field: FormField): void => {
        if (field === 'shortTermEffect' || field === 'longTermEffect') {
            setState({ ...state, [field]: Number(event.target.value) as ImpactOnTargetValue });
        } else {
            setState({ ...state, [field]: event.target.value });
        }
    };

    async function save(): Promise<void> {
        const params = {
            chapterId: document.currentDocument.chapters.find((chapter: Chapter) => chapter.isEvaDecisionOptionArea)!
                .id,
            target: state.title,
            decisionOptionId: props.perspective!.optionId,
            perspectiveTitle: props.perspective!.title,
            description: state.description,
            longTermEffect: state.longTermEffect,
            shortTermEffect: state.shortTermEffect,
        };
        try {
            loadingIndicator.show();
            await document.addEvaImpactOnTarget(params);
            snackbar.showSuccess();
        } catch (error) {
            snackbar.showError(error.data?.code);
        } finally {
            props.close();
            loadingIndicator.hide();
        }
    }

    const { translate } = localization;
    const { isOpen, close } = props;

    return (
        <Dialog
            open={isOpen}
            onClose={close}
            aria-labelledby="add-impact-on-target-dialog-title"
            maxWidth={'sm'}
            fullWidth
        >
            <DialogTitle id="add-impact-on-target-dialog-title">{translate('EVA_ADD_IMPACT_ON_TARGET')}</DialogTitle>
            <DialogContent dividers>
                <Box p={0.5}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <ShvkTextField
                                required
                                label={translate('EVA_IMPACT_ON_TARGET')}
                                multiline
                                rows={3}
                                variant="outlined"
                                size="small"
                                fullWidth
                                value={state.title}
                                onChange={(event): void => handleChange(event, 'title')}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Box mb={1}>
                                <FormLabel component="legend">{translate('EVA_SHORT_TERM_EFFECT')}</FormLabel>
                            </Box>
                            <EvaSlider
                                value={state.shortTermEffect}
                                onChange={(event): void => handleChange(event, 'shortTermEffect')}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Box mb={1}>
                                <FormLabel component="legend">{translate('EVA_LONG_TERM_EFFECT')}</FormLabel>
                            </Box>
                            <EvaSlider
                                value={state.longTermEffect}
                                onChange={(event): void => handleChange(event, 'longTermEffect')}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <ShvkTextField
                                label={translate('DESCRIPTION')}
                                multiline
                                rows={3}
                                variant="outlined"
                                size="small"
                                fullWidth
                                value={state.description}
                                onChange={(event): void => handleChange(event, 'description')}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={close}>
                    {translate('CANCEL')}
                </Button>
                <Box flexGrow={1} />
                <ShvkButton onClick={save} disabled={!state.title}>
                    {translate('SAVE')}
                </ShvkButton>
            </DialogActions>
        </Dialog>
    );
}

export default observer(AddImpactOnTargetDialog);
