import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, InputAdornment } from '@mui/material';
import ShvkTextField from '../../../styled/ShvkTextField';
import ShvkButton from '../../../styled/ShvkButton';
import DangerButton from '../../../styled/DangerButton';
import { Delete, Save } from '@mui/icons-material';
import { Chapter } from '../../../types/document';
import { TextFieldEvent } from '../../../types/events';
import useStore from '../../../store/storeContext';

interface Props {
    chapter?: Chapter | null;
    isOpen: boolean;
    close(): void;
}

interface State {
    title: string;
    text: string;
}

type FormField = 'title' | 'text';

//THIS COMPONENT IS NOT USED ANYWHERE!
function EditChapterDialog(props: Props) {
    const { dialog, localization, theming } = useStore();

    const [state, setState] = useState<State>({
        title: '',
        text: '',
    });

    useEffect(() => {
        props.isOpen && init();
    }, [props.isOpen]);

    function init(): void {
        setState((state) => ({
            ...state,
            title: props.chapter?.title || '',
            text: props.chapter?.text || '',
        }));
    }

    const handleChange = (event: TextFieldEvent, field: FormField): void => {
        setState((state) => ({
            ...state,
            [field]: event.target.value,
        }));
    };

    const save = (): void => {
        // TODO save
        props.close();
    };

    async function remove(): Promise<void> {
        const dialogModule = dialog;
        const confirmation = await dialogModule.getConfirmation();
        if (!confirmation) return;
        // TODO remove
        props.close();
    }

    const { translate } = localization;

    return (
        <Dialog
            open={props.isOpen}
            onClose={props.close}
            aria-labelledby="edit-chapter-dialog-title"
            maxWidth={'sm'}
            fullWidth
        >
            <DialogTitle id="edit-chapter-dialog-title">{translate('EDIT_CHAPTER')}</DialogTitle>
            <DialogContent dividers>
                <ShvkTextField
                    label={translate('TITLE')}
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={state.title}
                    onChange={(event): void => handleChange(event, 'title')}
                    style={{ marginBottom: theming.theme.spacing(2) }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">{props.chapter?.chapterNumberString || ''}</InputAdornment>
                        ),
                    }}
                />
                <ShvkTextField
                    label="Editor"
                    multiline
                    rows={5}
                    variant="outlined"
                    size="small"
                    fullWidth
                    style={{ marginBottom: theming.theme.spacing(2) }}
                    value={state.text}
                    onChange={(event): void => handleChange(event, 'text')}
                />
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={props.close}>
                    {translate('CANCEL')}
                </Button>
                <DangerButton variant="contained" onClick={remove} startIcon={<Delete />}>
                    {translate('REMOVE')}
                </DangerButton>
                <Box flexGrow={1} />
                <ShvkButton onClick={save} startIcon={<Save />}>
                    {translate('SAVE')}
                </ShvkButton>
            </DialogActions>
        </Dialog>
    );
}

export default observer(EditChapterDialog);
