import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Button, Divider, Paper, Typography } from '@mui/material';
import GeneralDialog from '../GeneralDialog';
import ShvkButton from '../../styled/ShvkButton';
import useStore from '../../store/storeContext';

interface State {
    dialogOpen: boolean;
    dialogContent: string;
}

function Training() {
    const { user, info, localization, loadingIndicator, snackbar, theming } = useStore();

    const [state, setState] = useState<State>({
        dialogOpen: false,
        dialogContent: '',
    });

    async function handleClick(dialogContent: string): Promise<void> {
        let content = '';
        if (!info.materialText && dialogContent === 'LOAD_TOOL_MATERIAL') {
            try {
                loadingIndicator.show();
                await info.fetchMaterialText();
            } catch (error) {
                snackbar.showFetchFailedMessage(error.data?.code);
            } finally {
                loadingIndicator.hide();
            }
        }
        if (dialogContent === 'READ_MORE' && info.shvkInfo) {
            content = info.shvkInfo[localization.locale];
            setState((state) => ({ ...state, dialogContent: content }));
        } else if (dialogContent === 'LOAD_TOOL_MATERIAL' && info.materialText) {
            content = info.materialText[localization.locale];
            setState((state) => ({ ...state, dialogContent: content }));
        }
        handleDialogOpen();
    }

    const handleDialogOpen = (): void => {
        setState((state) => ({ ...state, dialogOpen: !state.dialogOpen }));
        if (state.dialogContent) {
            setState((state) => ({ ...state, dialogContent: '' }));
        }
    };

    const { translate } = localization;
    return (
        <>
            <Paper>
                <Box px={2} py={1}>
                    <Typography variant="h6" component="h4">
                        {translate('WELCOME')}
                    </Typography>
                </Box>
                <Divider />
                <Box p={2}>
                    <Typography>
                        <Button onClick={() => handleClick('READ_MORE')}>{translate('READ_MORE')}</Button>
                        {user.isUserLoggedIn ? (
                            <Button style={{ textAlign: 'left' }} onClick={() => handleClick('LOAD_TOOL_MATERIAL')}>
                                {translate('LOAD_TOOL_MATERIAL')}
                            </Button>
                        ) : null}
                    </Typography>
                    <ShvkButton
                        style={{ marginTop: theming.spacing(4), textAlign: 'center' }}
                        href="https://koulutus.hyvinvointikertomus.fi/#/welcome" //TODO remove this hard coding
                        fullWidth
                    >
                        {translate('TOOL_TEASER_TITLE')}
                    </ShvkButton>
                </Box>
            </Paper>
            <GeneralDialog content={state.dialogContent} open={state.dialogOpen} onClose={handleDialogOpen} />
        </>
    );
}

export default observer(Training);
