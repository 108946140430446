import React from 'react';
import { observer } from 'mobx-react-lite';
import { runInAction } from 'mobx';
import { BACKEND_URL } from '../../../config';
import FroalaEditorComponent from 'react-froala-wysiwyg';
import useStore from '../../../store/storeContext';

function FroalaCoverPage() {
    const { document } = useStore();

    const { currentDocument } = document;

    function onModelChange(text: string): void {
        runInAction(() => (currentDocument.coverPageText = text !== '' ? text : null));
        void document.saveCurrentDocument();
    }

    return (
        <FroalaEditorComponent
            model={currentDocument.coverPageText}
            onModelChange={onModelChange}
            config={{
                ...document.froalaConfig,
                heightMax: 700,
                imageUploadParams: { id: currentDocument.id },
                imageUploadURL: BACKEND_URL + 'document/coverPageImage',
                toolbarButtons: [...document.froalaConfig.toolbarButtons.filter((btn) => btn !== 'insertFile')],
            }}
        />
    );
}

export default observer(FroalaCoverPage);
