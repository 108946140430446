import React, { useEffect } from 'react';
import { observer, useLocalObservable } from 'mobx-react-lite';
import {
    Box,
    CircularProgress,
    Container,
    Divider,
    FormLabel,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    Pagination,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { TextFieldEvent } from '../types/events';
import AuditLogItem, { AuditLogRequestParams } from '../types/auditLog';
import moment from 'moment';
import ShvkButton from '../styled/ShvkButton';
import AuditLogItemDialog from '../components/admin/AuditLogItemDialog';
import ShvkTextField from '../styled/ShvkTextField';
import useStore from '../store/storeContext';

const PREFIX = 'AuditLog';

const classes = {
    table: `${PREFIX}-table`,
    cell: `${PREFIX}-cell`,
    row: `${PREFIX}-row`,
    input: `${PREFIX}-input`,
};

const Root = styled('div')(({ theme }) => ({
    [`& .${classes.table}`]: {
        tableLayout: 'fixed',
    },

    [`& .${classes.cell}`]: {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    },

    [`& .${classes.row}`]: {
        cursor: 'pointer',
    },

    [`& .${classes.input}`]: {
        marginBottom: theme.spacing(2),
    },
}));

interface State {
    startDate: string;
    endDate: string;
    fetching: boolean;
    dialogOpen: boolean;
    selectedItem: AuditLogItem | null;
}

interface StoreState {
    page: number;
    filter: string;
    pageContent: AuditLogItem[];
    pageCount: number;
    handlePageChange: <T>(e: React.ChangeEvent<T>, page: number) => void;
    handleFilterChange: (e: TextFieldEvent) => void;
}

type AuditLogDateField = 'startDate' | 'endDate';

const itemsPerPage: Readonly<number> = 10;

function AuditLog() {
    const { admin, snackbar, localization } = useStore();

    const [state, setState] = React.useState<State>({
        startDate: '',
        endDate: '',
        fetching: false,
        dialogOpen: false,
        selectedItem: null,
    });

    const localStore = useLocalObservable<StoreState>(() => ({
        page: 1,
        filter: '',

        get pageContent(): AuditLogItem[] {
            const pageFirstIndex = (this.page - 1) * itemsPerPage;
            const filteredAuditLog = admin.filteredAuditLog(this.filter);

            return filteredAuditLog.slice(pageFirstIndex, pageFirstIndex + itemsPerPage);
        },

        get pageCount(): number {
            return Math.ceil(admin.filteredAuditLog(this.filter).length / itemsPerPage);
        },

        handlePageChange(_event: React.ChangeEvent<unknown>, value: number): void {
            this.page = value;
        },

        handleFilterChange(event: TextFieldEvent): void {
            this.filter = event.target.value;
            if (this.page > 1) this.page = 1;
        },
    }));

    useEffect(() => {
        setDefaultDate();
        getData();
    }, []);

    async function getData(): Promise<void> {
        setState((state) => ({ ...state, fetching: true }));
        try {
            const requestParams: AuditLogRequestParams = {
                beginDate: state.startDate,
                endDate: state.endDate,
                max: 1000,
                offset: 0,
                orderBy: 'asc',
                sortBy: 'dateCreated',
            };
            await admin.fetchAuditLog(requestParams);
        } catch {
            snackbar.showFetchFailedMessage();
        } finally {
            setState((state) => ({ ...state, fetching: false }));
        }
    }

    const setDefaultDate = (): void => {
        const today = moment().startOf('day').format('YYYY-MM-DD');
        setState((state) => ({ ...state, startDate: today, endDate: today }));
    };

    const getDataOnClick = (): void => {
        if (moment(state.startDate).isAfter(state.endDate)) {
            snackbar.showError('START_DATE_LATER');
        } else {
            getData();
        }
    };

    const handleDateChange = (event: TextFieldEvent, field: AuditLogDateField): void => {
        setState((state) => ({ ...state, [field]: event.target.value }));
    };

    const openDialog = (item: AuditLogItem): void => {
        setState((state) => ({ ...state, dialogOpen: true, selectedItem: item }));
    };

    const closeDialog = (): void => {
        setState((state) => ({ ...state, dialogOpen: false, selectedItem: null }));
    };

    const { translate } = localization;

    return (
        <Root>
            <Container>
                <Box my={3}>
                    <Paper>
                        <Box px={2} py={1} textAlign="center">
                            <Typography variant="h6">{translate('AUDIT_LOG')}</Typography>
                        </Box>
                        <Divider />
                        <Box m={2}>
                            <Grid container spacing={2} justifyContent="flex-start" alignItems="center">
                                <Grid item xs={12}>
                                    <FormLabel component="legend">{translate('FILTERING')}</FormLabel>
                                </Grid>
                                <Grid item>
                                    <ShvkTextField
                                        id="audit-log-start-date"
                                        label={translate('START_DATE')}
                                        type="date"
                                        variant="outlined"
                                        size="small"
                                        value={state.startDate}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onChange={(event): void => handleDateChange(event, 'startDate')}
                                    />
                                </Grid>
                                <Grid item>
                                    <ShvkTextField
                                        id="audit-log-end-date"
                                        label={translate('END_DATE')}
                                        type="date"
                                        variant="outlined"
                                        size="small"
                                        value={state.endDate}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onChange={(event): void => handleDateChange(event, 'endDate')}
                                    />
                                </Grid>
                                <Grid item>
                                    <ShvkButton onClick={getDataOnClick} disabled={state.fetching}>
                                        {translate('SEARCH')}
                                    </ShvkButton>
                                </Grid>
                                {state.fetching && (
                                    <Grid item>
                                        <CircularProgress />
                                    </Grid>
                                )}
                                <Grid item xs={12}>
                                    <ShvkTextField
                                        className={classes.input}
                                        fullWidth
                                        variant="outlined"
                                        label={translate('AUDIT_LOG_SEARCH_PLACEHOLDER')}
                                        size="small"
                                        onChange={localStore.handleFilterChange}
                                    />
                                </Grid>
                            </Grid>
                            <Table aria-label={translate('TRANSLATIONS')} className={classes.table} size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>{translate('DATE_CREATED')}</TableCell>
                                        <TableCell>{translate('AUDIT_LOG_ACTION')}</TableCell>
                                        <TableCell>{translate('AUDIT_LOG_OBJECT')}</TableCell>
                                        <TableCell>{translate('USERNAME')}</TableCell>
                                        <TableCell>{translate('AUDIT_LOG_STATUS')}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {localStore.pageContent.map((row) => (
                                        <TableRow
                                            key={row.id}
                                            hover
                                            className={classes.row}
                                            onClick={(): void => openDialog(row)}
                                        >
                                            <TableCell className={classes.cell}>
                                                {moment(row.dateCreated).format('DD.MM.YYYY HH:mm:ss')}
                                            </TableCell>
                                            <TableCell className={classes.cell}>{row.action}</TableCell>
                                            <TableCell className={classes.cell}>{row.object}</TableCell>
                                            <TableCell className={classes.cell}>{row.username}</TableCell>
                                            <TableCell className={classes.cell}>{row.status}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                        <Box px={2} pb={2} display="flex">
                            <Pagination
                                count={localStore.pageCount}
                                page={localStore.page}
                                onChange={localStore.handlePageChange}
                                color="primary"
                            />
                            <Box flexGrow={1} />
                        </Box>
                    </Paper>
                </Box>
            </Container>
            <AuditLogItemDialog open={state.dialogOpen} auditLogItem={state.selectedItem} onClose={closeDialog} />
        </Root>
    );
}

export default observer(AuditLog);
