import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { ButtonEvent } from '../types/events';
import { styled } from '@mui/material/styles';
import { ArrowDropDown, List } from '@mui/icons-material';
import { Box, Container, Grid, Hidden, IconButton, Paper, Typography } from '@mui/material';
import DocumentChapters from '../components/document/DocumentChapters';
import ShvkButton from '../styled/ShvkButton';
import DocumentOptionsPopover from '../components/document/DocumentOptionsPopover';
import DownloadPdfOrWordDialog from '../components/document/dialogs/DownloadPdfOrWordDialog';
import SideNavigation from '../components/document/SideNavigation';
import useStore from '../store/storeContext';

const PREFIX = 'Preview';

const classes = {
    paper: `${PREFIX}-paper`,
    documentChapters: `${PREFIX}-documentChapters`,
};

const StyledContainer = styled(Container)(({ theme }) => ({
    [`& .${classes.paper}`]: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(3),
    },

    [`& .${classes.documentChapters}`]: {
        marginTop: theme.spacing(8),
    },
}));

interface State {
    documentOptionsMenu: HTMLElement | null;
    sidenavOpen: boolean;
}

function EditorView() {
    const { document, dialog, snackbar, localization } = useStore();

    const navigate = useNavigate();

    const params = useParams();

    const [state, setState] = useState<State>({
        documentOptionsMenu: null,
        sidenavOpen: false,
    });

    const handleDocumentMenuOpen = (event: ButtonEvent): void => {
        setState((state) => ({ ...state, documentOptionsMenu: event.currentTarget }));
    };

    const handleDocumentMenuClose = (): void => {
        setState((state) => ({ ...state, documentOptionsMenu: null }));
    };

    const toggleSideNav = (): void => {
        setState((state) => ({ ...state, sidenavOpen: !state.sidenavOpen }));
    };

    useEffect(() => {
        async function init() {
            if (!document.currentDocument.id && params.documentId) {
                await document.loadPublishedDocumentById(parseInt(params.documentId));
                navigate('/preview/' + params.documentId);
            }
            document.isPreview = true;
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth',
            });
        }
        init().catch(() => snackbar.showError('GENERAL_ERROR'));

        return () => {
            document.isPreview = false;
        };
    }, []);

    const { currentDocument } = document;
    const { translate } = localization;
    const { documentDialogList, closeDocumentDialog } = dialog;

    return (
        <>
            {currentDocument.id ? (
                <StyledContainer>
                    <Box display="flex">
                        {state.sidenavOpen && (
                            <Hidden mdDown>
                                <Box>
                                    <SideNavigation />
                                </Box>
                            </Hidden>
                        )}
                        <Box flexGrow={1}>
                            <Paper className={classes.paper}>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <Hidden mdDown>
                                            <IconButton onClick={toggleSideNav} size="large">
                                                <List color="primary" fontSize="large" />
                                            </IconButton>
                                        </Hidden>
                                    </Grid>
                                    <Grid item xs={6} />
                                    <Grid item xs={12}>
                                        <Typography variant="subtitle2">
                                            {currentDocument?.organization?.name}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box display="flex">
                                            <Box flexGrow={1} mr={2}>
                                                <Typography variant="subtitle2">{currentDocument.name}</Typography>
                                            </Box>
                                            <ShvkButton
                                                endIcon={<ArrowDropDown />}
                                                onClick={handleDocumentMenuOpen}
                                                aria-controls="document-options-popover"
                                                aria-haspopup="true"
                                            >
                                                {translate('DOCUMENT_OPTIONS')}
                                            </ShvkButton>
                                            <DocumentOptionsPopover
                                                anchorEl={state.documentOptionsMenu}
                                                handleClose={handleDocumentMenuClose}
                                                isEditor={false}
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12}>
                                        {currentDocument.coverPageText && (
                                            <Typography
                                                className="fr-view"
                                                component="div"
                                                dangerouslySetInnerHTML={{ __html: currentDocument.coverPageText }}
                                            />
                                        )}
                                        {currentDocument.coverPageImage && (
                                            <img
                                                alt=""
                                                style={{ maxWidth: '100%' }}
                                                src={`data:image/png;base64,${currentDocument.coverPageImage}`}
                                            />
                                        )}
                                    </Grid>
                                    <Grid item xs={12} className={classes.documentChapters}>
                                        <DocumentChapters />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box display="flex">
                                            <Box flexGrow={1} mr={2} />
                                            <ShvkButton
                                                endIcon={<ArrowDropDown />}
                                                onClick={handleDocumentMenuOpen}
                                                aria-controls="document-options-popover"
                                                aria-haspopup="true"
                                            >
                                                {translate('DOCUMENT_OPTIONS')}
                                            </ShvkButton>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Box>
                    </Box>
                    <DownloadPdfOrWordDialog
                        isOpen={documentDialogList.downloadPdfDialog.open}
                        close={(): void => closeDocumentDialog('downloadPdfDialog')}
                        type="pdf"
                    />
                    <DownloadPdfOrWordDialog
                        isOpen={documentDialogList.downloadWordDialog.open}
                        close={(): void => closeDocumentDialog('downloadWordDialog')}
                        type="word"
                    />
                </StyledContainer>
            ) : null}
        </>
    );
}

export default observer(EditorView);
