import React from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import useStore from '../store/storeContext';

interface Props {
    content?: string;
    title?: string;
    subtitle?: string;
    open: boolean;
    onClose(): void;
    maxWidth?: 'lg' | 'xs' | 'sm' | 'md' | 'xl';
}

function GeneralDialog(props: Props) {
    const { localization } = useStore();

    const { content, title, subtitle, open, onClose, maxWidth } = props;
    const { translate } = localization;

    return (
        <Dialog open={open} onClose={onClose} aria-labelledby="general-dialog-title" maxWidth={maxWidth || 'lg'}>
            {!title ? null : <DialogTitle>{title}</DialogTitle>}
            <DialogContent dividers>
                {!subtitle ? null : (
                    <Box mb={2}>
                        <Typography component="h6" variant="subtitle2">
                            {subtitle}
                        </Typography>
                    </Box>
                )}
                {!content ? null : (
                    <Typography className="fr-view" component="div" dangerouslySetInnerHTML={{ __html: content }} />
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} variant="contained">
                    {translate('CLOSE')}
                </Button>
                <Box flexGrow={1} />
            </DialogActions>
        </Dialog>
    );
}

export default observer(GeneralDialog);
