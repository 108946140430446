import React, { useState, ChangeEvent } from 'react';
import { observer } from 'mobx-react-lite';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    CircularProgress,
    Grid,
    Typography,
} from '@mui/material';
import { CloudUpload, ExpandMore } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { TranslationKeys } from '../../types/translation';
import useStore from '../../store/storeContext';

const PREFIX = 'UploadBackgroundAccordion';

const classes = {
    uploadInput: `${PREFIX}-uploadInput`,
};

const StyledAccordion = styled(Accordion)({
    [`& .${classes.uploadInput}`]: {
        display: 'none',
    },
});

interface Props {
    title: keyof TranslationKeys;
    id: string;
}

interface State {
    isUploading: boolean;
}

function UploadBackgroundAccordion(props: Props) {
    const { image, localization, snackbar } = useStore();

    const [state, setState] = useState<State>({
        isUploading: false,
    });

    async function upload(file: File): Promise<void> {
        setState((state) => ({ ...state, isUploading: true }));
        try {
            await image.uploadBackgroundImage(file);
            snackbar.showSuccess('UPLOAD_SUCCESSFUL');
        } catch (error) {
            snackbar.showError(error.data?.code);
        } finally {
            setState((state) => ({ ...state, isUploading: false }));
        }
    }

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>): void => {
        if (!event?.target?.files) return;
        const file = event.target.files[0];
        if (!file) return;
        void upload(file);
    };

    const { title, id } = props;
    const { translate } = localization;

    return (
        <StyledAccordion>
            <AccordionSummary expandIcon={<ExpandMore />} aria-controls={id + '-content'} id={id + '-header'}>
                <Typography>{translate(title)}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container spacing={2}>
                    <Grid item>
                        <input
                            accept="image/*"
                            className={classes.uploadInput}
                            id="background-file-input"
                            type="file"
                            onChange={handleFileChange}
                        />
                        <label htmlFor="background-file-input">
                            <Button
                                variant="contained"
                                color="secondary"
                                component="span"
                                endIcon={<CloudUpload />}
                                disabled={state.isUploading}
                            >
                                {translate('UPLOAD_FRONTPAGE_WALLPAPER')}
                            </Button>
                        </label>
                    </Grid>
                    <Grid item>{state.isUploading && <CircularProgress />}</Grid>
                </Grid>
            </AccordionDetails>
        </StyledAccordion>
    );
}

export default observer(UploadBackgroundAccordion);
