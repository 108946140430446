import React, { useState, useEffect } from 'react';
import { observer, useLocalObservable } from 'mobx-react-lite';
import {
    Autocomplete,
    Box,
    CircularProgress,
    Container,
    Divider,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
    Pagination,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Info } from '@mui/icons-material';
import ShvkTextField from '../styled/ShvkTextField';
import { OrganizationEvaTemplate } from '../types/evaTemplate';
import EvaTemplateDialog from '../components/evaTemplates/EvaTemplateDialog';
import ShvkButton from '../styled/ShvkButton';
import useStore from '../store/storeContext';

const PREFIX = 'EvaTemplateManagement';

const classes = {
    table: `${PREFIX}-table`,
    cell: `${PREFIX}-cell`,
    row: `${PREFIX}-row`,
};

const Root = styled('div')({
    [`& .${classes.table}`]: {
        tableLayout: 'fixed',
    },
    [`& .${classes.cell}`]: {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    },
    [`& .${classes.row}`]: {
        cursor: 'pointer',
    },
});

interface State {
    fetching: boolean;
    dialogOpen: boolean;
    selectedTemplate: OrganizationEvaTemplate | null;
    inputValue: string;
}

interface StoreState {
    page: number;
    pageCount: number;
    pageContent: OrganizationEvaTemplate[];
    handlePageChange: <T>(_event: React.ChangeEvent<T>, value: number) => void;
}

const itemsPerPage: Readonly<number> = 10;

function EvaTemplateManagement() {
    const { organization, evaTemplate, snackbar, localization, theming } = useStore();

    const [state, setState] = useState<State>({
        fetching: false,
        dialogOpen: false,
        selectedTemplate: null,
        inputValue: '',
    });

    const localStore = useLocalObservable<StoreState>(() => ({
        page: 1,

        get pageCount(): number {
            return Math.ceil(evaTemplate.organizationEvaTemplateList.length / itemsPerPage);
        },

        get pageContent(): OrganizationEvaTemplate[] {
            const pageFirstIndex = (this.page - 1) * itemsPerPage;
            return evaTemplate.organizationEvaTemplateList.slice(pageFirstIndex, pageFirstIndex + itemsPerPage);
        },

        handlePageChange(_event: React.ChangeEvent<unknown>, value: number): void {
            this.page = value;
        },
    }));

    useEffect(() => {
        void init();
    }, []);

    function init() {
        setState((state) => ({ ...state, selectedTemplate: null, inputValue: '' }));
        void getData();
    }

    async function getData(): Promise<void> {
        setState((state) => ({ ...state, fetching: true }));
        try {
            await organization.fetchAdminOrganizations();
            const idList = organization.adminOrganizations.map((organization) => organization.id);
            await evaTemplate.fetchOrganizationEvaTemplates(idList);
            await evaTemplate.fetchDefaultEvaTemplates();
        } catch {
            snackbar.showFetchFailedMessage();
        } finally {
            setState((state) => ({ ...state, fetching: false }));
        }
    }

    const handleOpenDialog = (item: OrganizationEvaTemplate | null): void => {
        setState((state) => ({ ...state, dialogOpen: true, selectedTemplate: item }));
    };

    const handleCloseDialog = (): void => {
        setState((state) => ({ ...state, dialogOpen: false, selectedTemplate: null }));
    };

    const { translate } = localization;
    return (
        <Root>
            <Container>
                <Box my={3}>
                    <Paper>
                        <Box px={2} py={1} textAlign="center">
                            <Typography variant="h6">{translate('ORGANIZATION_EVA_TEMPLATES')}</Typography>
                        </Box>
                        <Divider />
                        <Box m={2}>
                            <Autocomplete
                                id="eva-template-autocomplete"
                                value={state.selectedTemplate}
                                onChange={(_event, newValue) => {
                                    handleOpenDialog(newValue);
                                }}
                                inputValue={state.inputValue}
                                onInputChange={(_event, newInputValue) => {
                                    setState((state) => ({ ...state, inputValue: newInputValue }));
                                }}
                                options={evaTemplate.organizationEvaTemplateList}
                                getOptionLabel={(option) => option.title}
                                fullWidth
                                size="small"
                                renderInput={(params): JSX.Element => (
                                    <ShvkTextField
                                        {...params}
                                        label={translate('SEARCH_WITH_NAME')}
                                        variant="outlined"
                                    />
                                )}
                            />
                            <Box p={1} my={2}>
                                {state.fetching ? (
                                    <Box textAlign="center">
                                        <CircularProgress />
                                    </Box>
                                ) : (
                                    <Table
                                        aria-label={translate('ORGANIZATION_EVA_TEMPLATE_MANAGEMENT')}
                                        className={classes.table}
                                        size="small"
                                    >
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>{translate('NAME')}</TableCell>
                                                <TableCell>{translate('DESCRIPTION')}</TableCell>
                                                <TableCell>{translate('ORGANIZATION')}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {localStore.pageContent.map((template) => (
                                                <TableRow
                                                    key={template.id}
                                                    hover
                                                    className={classes.row}
                                                    onClick={(): void => handleOpenDialog(template)}
                                                >
                                                    <TableCell className={classes.cell}>{template.title}</TableCell>
                                                    <TableCell className={classes.cell}>
                                                        {template.description}
                                                    </TableCell>
                                                    <TableCell className={classes.cell}>
                                                        {template.organization.name}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                )}
                            </Box>
                            <Box px={2} pb={2} display="flex">
                                <Pagination
                                    count={localStore.pageCount}
                                    page={localStore.page}
                                    onChange={localStore.handlePageChange}
                                    color="primary"
                                />
                                <Box flexGrow={1} />
                                {!state.fetching && (
                                    <Box display="inline-flex" alignItems="center">
                                        <Tooltip title={translate('lisaa_eva_arviointipohja_13')}>
                                            <Info color="primary" style={{ marginRight: theming.spacing(1) }} />
                                        </Tooltip>
                                        <ShvkButton onClick={(): void => handleOpenDialog(null)}>
                                            {translate('ADD')}
                                        </ShvkButton>
                                    </Box>
                                )}
                            </Box>
                        </Box>
                    </Paper>
                </Box>
            </Container>
            <EvaTemplateDialog
                open={state.dialogOpen}
                onClose={handleCloseDialog}
                evaTemplate={state.selectedTemplate}
            />
        </Root>
    );
}

export default observer(EvaTemplateManagement);
