import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { EvaNestableItem } from '../../types/nestable';
import { Box, Button, Dialog, DialogActions, DialogContent, Grid } from '@mui/material';
import { TextFieldEvent } from '../../types/events';
import ShvkTextField from '../../styled/ShvkTextField';
import ShvkButton from '../../styled/ShvkButton';
import useStore from '../../store/storeContext';

interface Props {
    open: boolean;
    item: EvaNestableItem | null;
    onClose(): void;
    edit(text: string, description: string, id: number): void;
    add(text: string, description: string): void;
}

interface State {
    text: string;
    description: string;
}

type EditEvaDialogField = 'text' | 'description';

function EditEvaNestableDialog(props: Props) {
    const { localization } = useStore();

    const [state, setState] = useState<State>({
        text: '',
        description: '',
    });

    useEffect(() => {
        props.open && init();
    }, [props.open]);

    function init(): void {
        setState((state) => ({
            ...state,
            text: props.item?.text || '',
            description: props.item?.description || '',
        }));
    }

    function save(): void {
        if (props.item) {
            props.edit(state.text, state.description, props.item.id);
        } else props.add(state.text, state.description);

        props.onClose();
    }

    const handleChange = (event: TextFieldEvent, field: EditEvaDialogField): void => {
        setState((state) => ({ ...state, [field]: event.target.value }));
    };

    const { translate } = localization;

    return (
        <Dialog open={props.open} onClose={props.onClose} maxWidth={'xs'} fullWidth>
            <DialogContent dividers>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <ShvkTextField
                            label={translate('NAME')}
                            variant="outlined"
                            size="small"
                            onChange={(event): void => handleChange(event, 'text')}
                            value={state.text}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <ShvkTextField
                            multiline
                            rows={5}
                            label={translate('DESCRIPTION')}
                            variant="outlined"
                            size="small"
                            onChange={(event): void => handleChange(event, 'description')}
                            value={state.description}
                            fullWidth
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose} variant="contained">
                    {translate('CANCEL')}
                </Button>
                <Box flexGrow={1} />
                <ShvkButton color="primary" onClick={save}>
                    {translate('OK')}
                </ShvkButton>
            </DialogActions>
        </Dialog>
    );
}

export default observer(EditEvaNestableDialog);
