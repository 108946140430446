import React, { useState, useEffect, ChangeEvent } from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Button, Card, CardActions, CardContent, CardHeader, Collapse, IconButton } from '@mui/material';
import { Cancel, Delete, Edit, PhotoCamera, Save } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { TextFieldEvent } from '../../types/events';
import CarouselImage from '../../types/carouselImage';
import ShvkTextField from '../../styled/ShvkTextField';
import DangerButton from '../../styled/DangerButton';
import ShvkButton from '../../styled/ShvkButton';
import useStore from '../../store/storeContext';

const PREFIX = 'CarouselImageCard';

const classes = {
    cardContent: `${PREFIX}-cardContent`,
    uploadInput: `${PREFIX}-uploadInput`,
};

const StyledCard = styled(Card)({
    [`& .${classes.cardContent}`]: {
        paddingTop: 0,
    },
    [`& .${classes.uploadInput}`]: {
        display: 'none',
    },
});

interface Props {
    image: CarouselImage;
    save: (orderNumber: number, url: string, file?: string) => void;
    remove: (orderNumber: number) => void;
}

interface State {
    editing: boolean;
    redirectUrl: string;
    carouselImage: string;
}

function CarouselImageCard(props: Props) {
    const { theming, localization } = useStore();

    const [state, setState] = useState<State>({
        editing: false,
        redirectUrl: '',
        carouselImage: '',
    });

    useEffect(() => {
        setState((state) => ({
            ...state,
            // New images have editing mode enabled by default
            editing: props.image.id < 0,
            redirectUrl: props.image.redirectURL || '',
            carouselImage: props.image.carouselImage || '',
        }));
    }, []);

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>): void => {
        if (!event?.target?.files) return;
        const file = event.target.files[0];
        if (!file) return;
        const reader = new FileReader();
        reader.onload = (): void => {
            const result = reader.result as string;
            const imageContent = result.substring(result.indexOf(',') + 1, result.length);
            setState((state) => ({ ...state, carouselImage: imageContent }));
        };
        reader.readAsDataURL(file);
    };

    const handleSave = (): void => {
        const { image, save } = props;
        if (props.image.id < 0) {
            // Add new
            save(image.orderNumber, state.redirectUrl, state.carouselImage);
        } else {
            // Update old
            save(image.orderNumber, state.redirectUrl);
        }
    };

    const handleURLChange = (event: TextFieldEvent): void => {
        setState((state) => ({ ...state, redirectUrl: event.target.value }));
    };

    const handleEditing = (): void => {
        setState((state) => ({ ...state, editing: !state.editing }));
    };

    const { translate } = localization;
    const { image, remove } = props;

    return (
        <StyledCard variant="outlined">
            <CardHeader
                action={
                    !state.editing ? (
                        <IconButton
                            aria-label={translate('EDIT')}
                            color="primary"
                            onClick={(): void => handleEditing()}
                            size="large"
                        >
                            <Edit fontSize="small" />
                        </IconButton>
                    ) : (
                        <IconButton
                            aria-label={translate('CANCEL')}
                            color="primary"
                            onClick={(): void => handleEditing()}
                            size="large"
                        >
                            <Cancel fontSize="small" />
                        </IconButton>
                    )
                }
                title={image.orderNumber + '.'}
                subheader={image.id < 0 && state.carouselImage ? translate('IMAGE_NOT_SAVED') : null}
            />
            <CardContent className={classes.cardContent}>
                {state.carouselImage ? (
                    <img alt="" width="100%" src={`data:image/png;base64,${state.carouselImage}`} />
                ) : (
                    <div>
                        <input
                            accept="image/*"
                            className={classes.uploadInput}
                            id={`carousel-image-${image.orderNumber}-file-input`}
                            type="file"
                            onChange={handleFileChange}
                        />
                        <label htmlFor={`carousel-image-${image.orderNumber}-file-input`}>
                            <Button variant="outlined" color="secondary" component="span" endIcon={<PhotoCamera />}>
                                {translate('SELECT_IMAGE')}
                            </Button>
                        </label>
                    </div>
                )}
                <Collapse in={state.editing}>
                    <ShvkTextField
                        style={{ marginTop: theming.theme.spacing(1) }}
                        variant="outlined"
                        size="small"
                        label={translate('URL')}
                        fullWidth
                        value={state.redirectUrl}
                        onChange={handleURLChange}
                    />
                </Collapse>
            </CardContent>
            <Collapse in={state.editing}>
                <CardActions>
                    {image.id > 0 && (
                        <DangerButton
                            variant="contained"
                            onClick={(): void => remove(image.orderNumber)}
                            endIcon={<Delete />}
                        >
                            {translate('REMOVE')}
                        </DangerButton>
                    )}
                    <Box flexGrow={1} />
                    <ShvkButton onClick={handleSave} endIcon={<Save />} disabled={!state.carouselImage}>
                        {translate('SAVE')}
                    </ShvkButton>
                </CardActions>
            </Collapse>
        </StyledCard>
    );
}

export default observer(CarouselImageCard);
