import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import {
    Alert,
    Box,
    Button,
    Collapse,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
} from '@mui/material';
import { TextFieldEvent } from '../../types/events';
import ShvkTextField from '../../styled/ShvkTextField';
import ForgotPasswordDialog from './ForgotPasswordDialog';
import RegisterAsAdminUserDialog from './RegisterAsAdminUserDialog';
import LicenseExpiredDialog from './LicenseExpiredDialog';
import useStore from '../../store/storeContext';

interface Props {
    open: boolean;
}

interface State {
    username: string;
    password: string;
    loginInProgress: boolean;
    showErrorAlert: boolean;
    forgotPasswordDialogIsOpen: boolean;
    registerAsAdminUserDialogIsOpen: boolean;
    licenseExpiredDialogIsOpen: boolean;
    licenseEndDate: Date | string | number | undefined | null;
}

type LoginFormField = 'username' | 'password';

function LoginDialog(props: Props) {
    const { dialog, user, session, snackbar, localization } = useStore();

    const navigate = useNavigate();

    const [state, setState] = useState<State>({
        username: '',
        password: '',
        loginInProgress: false,
        showErrorAlert: false,
        forgotPasswordDialogIsOpen: false,
        registerAsAdminUserDialogIsOpen: false,
        licenseExpiredDialogIsOpen: false,
        licenseEndDate: null,
    });

    useEffect(() => {
        props.open && init();
    }, [props.open]);

    const handleChange = (event: TextFieldEvent, field: LoginFormField): void => {
        if (state.showErrorAlert) {
            setState((state) => ({ ...state, showErrorAlert: false }));
        }
        setState((state) => ({ ...state, [field]: event.target.value }));
    };

    const init = (): void => {
        setState((state) => ({ ...state, username: '', password: '', licenseEndDate: null }));
    };

    const isLoginButtonDisabled = (): boolean => {
        return state.loginInProgress || state.username === '' || state.password === '';
    };

    async function login(): Promise<void> {
        setState((state) => ({ ...state, loginInProgress: true }));

        try {
            const username = state.username.toLowerCase();
            const password = state.password;
            await session.login(username, password);
        } catch {
            setState((state) => ({ ...state, showErrorAlert: true, loginInProgress: false }));
            return;
        }

        // Get user data after login.
        try {
            // Set selected organization to null.
            // If a user doesn't have rights to the selected organization, they can't log in.
            await user.removeUserSelectedOrganization();
            await user.fetchUserDetails();

            setState((state) => ({ ...state, loginInProgress: false }));

            // Check if the license is expired
            const license = user.userDetails?.homeOrganization.license;

            if (!license?.isExpired) {
                snackbar.showSuccess('LOGIN_SUCCESS');
                dialog.closeLoginDialog();
                await navigate('/home');
            } else {
                dialog.closeLoginDialog();
                setState((state) => ({ ...state, licenseExpiredDialogIsOpen: true, licenseEndDate: license.endDate }));
                await session.logout();
            }
        } catch (error) {
            // Logout if user data fetch fails. This will also clear token from session storage.
            setState((state) => ({ ...state, loginInProgress: false }));
            snackbar.showFetchFailedMessage(error.data?.code);
            dialog.closeLoginDialog();
            await session.logout();
            navigate('/');
        }
    }

    const { open } = props;
    const { translate } = localization;

    return (
        <>
            <Dialog
                open={open}
                onClose={dialog.closeLoginDialog}
                aria-labelledby="login-dialog-title"
                maxWidth={'sm'}
                fullWidth
            >
                <DialogTitle id="login-dialog-title">{translate('LOGIN_TITLE')}</DialogTitle>
                <DialogContent dividers>
                    <Typography>{translate('USERNAME').toUpperCase()}</Typography>
                    <ShvkTextField
                        id="username"
                        name="username"
                        autoFocus
                        placeholder={translate('EMAIL')}
                        value={state.username}
                        onChange={(event): void => handleChange(event, 'username')}
                        variant="outlined"
                        size="small"
                        fullWidth
                        sx={{ mb: 2 }}
                    />
                    <Typography>{translate('PASSWORD').toUpperCase()}</Typography>
                    <ShvkTextField
                        type="password"
                        id="password"
                        name="password"
                        autoComplete="new-password"
                        placeholder={translate('PASSWORD')}
                        value={state.password}
                        onChange={(event): void => handleChange(event, 'password')}
                        onKeyDown={async (event): Promise<void> => {
                            if (event.key === 'Enter') {
                                await login();
                            }
                        }}
                        variant="outlined"
                        size="small"
                        fullWidth
                        sx={{ mb: 2 }}
                    />
                    <Collapse in={state.showErrorAlert}>
                        <Alert severity="error" variant="filled">
                            {translate('INVALID_USERNAME_OR_PASSWORD')}
                        </Alert>
                    </Collapse>
                    <Box display="flex">
                        <Button onClick={() => setState((state) => ({ ...state, forgotPasswordDialogIsOpen: true }))}>
                            {translate('FORGOT_PASSWORD_LINK')}
                        </Button>
                        <Box flexGrow={1} />
                        <Button
                            onClick={() => setState((state) => ({ ...state, registerAsAdminUserDialogIsOpen: true }))}
                        >
                            {translate('REGISTER_AS_USER_LINK')}
                        </Button>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={dialog.closeLoginDialog} variant="contained">
                        {translate('CANCEL')}
                    </Button>
                    <Box flexGrow={1} />
                    <Button onClick={login} color="secondary" variant="contained" disabled={isLoginButtonDisabled()}>
                        {translate('LOGIN')}
                    </Button>
                </DialogActions>
            </Dialog>
            <ForgotPasswordDialog
                isOpen={state.forgotPasswordDialogIsOpen}
                close={() => setState((state) => ({ ...state, forgotPasswordDialogIsOpen: false }))}
            />
            <RegisterAsAdminUserDialog
                isOpen={state.registerAsAdminUserDialogIsOpen}
                close={() => setState((state) => ({ ...state, registerAsAdminUserDialogIsOpen: false }))}
            />
            <LicenseExpiredDialog
                isOpen={state.licenseExpiredDialogIsOpen}
                close={() => setState((state) => ({ ...state, licenseExpiredDialogIsOpen: false }))}
                endDate={state.licenseEndDate}
            />
        </>
    );
}

export default observer(LoginDialog);
