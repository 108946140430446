import React from 'react';
import { ShvkNestableItem } from '../types/nestable';

/*
    Recursive functions, which can be used to find and edit nested items.
 */

// Usage: list.filter(removeItem(id));
export const removeItem = (removedItem: ShvkNestableItem) => (item: ShvkNestableItem) => {
    if (item.id === removedItem.id) return false;
    item.children = item.children.filter(removeItem(removedItem));
    return true;
};

// Usage: list.map(editText(id, text));
export const editText = (modifiedItemId: number, newText: string) => (item: ShvkNestableItem) => {
    if (item.id === modifiedItemId) {
        item.text = newText;
    } else if (item.children.length > 0) {
        item.children.map(editText(modifiedItemId, newText));
    }
    return item;
};

// Usage: list.map(addParentIds());
export const addParentIds =
    (parentId: number | null = null) =>
    (item: ShvkNestableItem) => {
        item.parentId = parentId;
        if (item.children.length > 0) {
            item.children = item.children.map(addParentIds(item.id));
        }
        return item;
    };

// Usage: list.map(addItem(newItem, parentId));
export const addItem = (newItem: ShvkNestableItem, parentId: number) => (el: ShvkNestableItem) => {
    if (el.id === parentId) {
        el.children.push(newItem);
    } else if (el.children.length > 0) {
        el.children.map(addItem(newItem, parentId));
    }
    return el;
};

// Usage: list.map(addDepths());
export const addDepths =
    (parentDepth = 0) =>
    (item: ShvkNestableItem) => {
        const depth = parentDepth + 1;
        item.depth = depth;
        if (item.children.length > 0) {
            item.children = item.children.map(addDepths(depth));
        }
        return item;
    };

export const getPathById = (id: number, items: ShvkNestableItem[]): number[] => {
    let path: number[] = [];

    items.forEach((item, i) => {
        if (item.id === id) {
            path.push(i);
        } else if (item.children) {
            const childrenPath = getPathById(id, item.children);

            if (childrenPath.length) {
                path = path.concat(i).concat(childrenPath);
            }
        }
    });

    return path;
};

export const getDepth = (id: number, list: ShvkNestableItem[]): number => {
    const path = getPathById(id, list);
    return path.length;
};

// Handler is transparent and fills the parent element
export const handlerStyle: React.CSSProperties = {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    background: 'rgba(0,0,0,0)',
    cursor: 'move',
};
