import React, { useState, useEffect, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Pagination, Button, Grid } from '@mui/material';
import { ArrowLeft, ArrowRight } from '@mui/icons-material';
import TransferListItem from '../../types/transferList';
import { TextFieldEvent } from '../../types/events';
import ShvkTextField from '../../styled/ShvkTextField';
import ShvkButton from '../../styled/ShvkButton';
import TransferListItems from './TransferListItems';
import useStore from '../../store/storeContext';

interface Props {
    items: TransferListItem[];
    setSelected: (items: TransferListItem[]) => void;
}

interface State {
    email: string;
    checked: TransferListItem[];
    left: TransferListItem[];
    right: TransferListItem[];
}

interface Pagination {
    currentPage: number;
    pageCount: number;
    pageSize: number;
    pageContent: TransferListItem[];
}

type PaginationSide = 'left' | 'right';

function not(a: TransferListItem[], b: TransferListItem[]): TransferListItem[] {
    return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a: TransferListItem[], b: TransferListItem[]): TransferListItem[] {
    return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a: TransferListItem[], b: TransferListItem[]): TransferListItem[] {
    return [...a, ...not(b, a)];
}

const itemsPerPage: Readonly<number> = 50;

function TransferList(props: Props) {
    const context = useStore();

    const [state, setState] = useState<State>({
        email: '',
        checked: [],
        left: [],
        right: [],
    });

    const [leftPagination, setLeftPagination] = useState<Pagination>({
        currentPage: 1,
        pageCount: 0,
        pageSize: itemsPerPage,
        pageContent: [],
    });

    const [rightPagination, setRightPagination] = useState<Pagination>({
        currentPage: 1,
        pageCount: 0,
        pageSize: itemsPerPage,
        pageContent: [],
    });

    useEffect(() => {
        init();
    }, []);

    function init(): void {
        const left = props.items.filter((item) => !item.defaultSelected);
        const right = props.items.filter((item) => item.defaultSelected);

        setState((state) => ({
            ...state,
            email: '',
            checked: [],
            left,
            right,
        }));

        setLeftPagination((state) => ({
            ...state,
            currentPage: 1,
            pageCount: Math.ceil(left.length / itemsPerPage),
            pageSize: itemsPerPage,
            pageContent: left.slice(0, itemsPerPage),
        }));

        setRightPagination((state) => ({
            ...state,
            currentPage: 1,
            pageCount: Math.ceil(right.length / itemsPerPage),
            pageSize: itemsPerPage,
            pageContent: right.slice(0, itemsPerPage),
        }));
    }

    // Checkbox toggling wrapped in useCallback to avoid re-rendering 'expensive' TransferListItems.
    // Typing on text field would trigger re-render, but this way function definitions get cached.
    const handleToggle = useCallback(
        (value: TransferListItem): void => {
            const currentIndex = state.checked.indexOf(value);
            const newChecked = [...state.checked];
            if (currentIndex === -1) {
                newChecked.push(value);
            } else {
                newChecked.splice(currentIndex, 1);
            }
            setState((state) => ({ ...state, checked: newChecked }));
        },
        [state.checked],
    );

    const handleToggleAll = useCallback(
        (items: TransferListItem[], numberOfChecked: number): void => {
            if (numberOfChecked === items.length) {
                setState((state) => ({ ...state, checked: not(state.checked, items) }));
            } else {
                setState((state) => ({ ...state, checked: union(state.checked, items) }));
            }
        },
        [state.checked],
    );

    const handleCheckedRight = (): void => {
        const leftChecked = intersection(state.checked, state.left);
        const right = [...leftChecked, ...state.right];
        const left = not(state.left, leftChecked);
        const checked = not(state.checked, leftChecked);
        setState((state) => ({ ...state, right, left, checked }));
        handleLeftPageContentChange(left);
        handleRightPageContentChange(right);
        props.setSelected(right);
    };

    const handleCheckedLeft = (): void => {
        const rightChecked = intersection(state.checked, state.right);
        const left = [...rightChecked, ...state.left];
        const right = not(state.right, rightChecked);
        const checked = not(state.checked, rightChecked);
        setState((state) => ({ ...state, left, right, checked }));
        handleLeftPageContentChange(left);
        handleRightPageContentChange(right);
        props.setSelected(right);
    };

    const handlePageChange = (page: number, side: PaginationSide): void => {
        const newPageCount = Math.ceil(state[side].length / itemsPerPage);
        const newContent = [...state[side]].slice((page - 1) * itemsPerPage, page * itemsPerPage);

        if (side === 'left') {
            setLeftPagination((prevState) => ({
                ...prevState,
                currentPage: page,
                pageContent: newContent,
                pageCount: newPageCount,
            }));
        } else {
            setRightPagination((prevState) => ({
                ...prevState,
                currentPage: page,
                pageContent: newContent,
                pageCount: newPageCount,
            }));
        }
    };

    const handleLeftPageContentChange = (items: TransferListItem[]): void => {
        const newPageCount = Math.ceil(items.length / itemsPerPage);
        const isLastPage = newPageCount < leftPagination.currentPage;
        const newContent = [...items].slice(
            (leftPagination.currentPage - (isLastPage ? 2 : 1)) * itemsPerPage,
            leftPagination.currentPage * itemsPerPage,
        );
        setLeftPagination((state) => ({
            ...state,
            pageContent: newContent,
            pageCount: newPageCount,
            currentPage: isLastPage ? state.currentPage - 1 : state.currentPage,
        }));
    };

    const handleRightPageContentChange = (items: TransferListItem[]): void => {
        const newPageCount = Math.ceil(items.length / itemsPerPage);
        const isLastPage = newPageCount < rightPagination.currentPage;
        const newContent = [...items].slice(
            (rightPagination.currentPage - (isLastPage ? 2 : 1)) * itemsPerPage,
            rightPagination.currentPage * itemsPerPage,
        );

        setRightPagination((state) => ({
            ...state,
            pageContent: newContent,
            pageCount: newPageCount,
            currentPage: isLastPage ? state.currentPage - 1 : state.currentPage,
        }));
    };

    const handleChangeEmail = (event: TextFieldEvent): void => {
        setState((state) => ({ ...state, email: event.target.value }));
    };

    const handleAddEmail = (): void => {
        const newItem: TransferListItem = {
            title: state.email,
            defaultSelected: false,
        };
        const right = [newItem, ...state.right];
        setState((state) => ({ ...state, right, email: '' }));
        handleRightPageContentChange(right);
        props.setSelected(right);
    };

    const leftChecked: TransferListItem[] = intersection(state.checked, state.left);
    const rightChecked: TransferListItem[] = intersection(state.checked, state.right);
    const isValidEmail = context.user.validateUserName(state.email);

    const { translate } = context.localization;

    return (
        <Grid container>
            <Grid item xs={5} alignSelf={'flex-start'}>
                <>
                    <TransferListItems
                        sideItems={state.left}
                        pageItems={leftPagination.pageContent}
                        checked={state.checked}
                        handleToggleAll={handleToggleAll}
                        handleToggle={handleToggle}
                    />
                    <Pagination
                        count={leftPagination.pageCount}
                        page={leftPagination.currentPage}
                        siblingCount={2}
                        size="small"
                        variant="text"
                        color="primary"
                        onChange={(_event, page) => handlePageChange(page, 'left')}
                        sx={{ mt: 1 }}
                    />
                </>
            </Grid>
            <Grid item xs={2} mb={2} alignSelf="center">
                <Box mb={0.5} display={'flex'} flexDirection={'column'} alignItems="center">
                    <Button
                        variant="outlined"
                        size="small"
                        onClick={handleCheckedRight}
                        sx={{ mb: 0.5 }}
                        disabled={leftChecked.length === 0}
                    >
                        <ArrowRight />
                    </Button>
                    <Button
                        variant="outlined"
                        size="small"
                        onClick={handleCheckedLeft}
                        disabled={rightChecked.length === 0}
                    >
                        <ArrowLeft />
                    </Button>
                </Box>
            </Grid>
            <Grid item xs={5} alignSelf="flex-start">
                <>
                    <TransferListItems
                        sideItems={state.right}
                        pageItems={rightPagination.pageContent}
                        checked={state.checked}
                        handleToggleAll={handleToggleAll}
                        handleToggle={handleToggle}
                    />
                    {state.right.length ? (
                        <Pagination
                            count={rightPagination.pageCount}
                            page={rightPagination.currentPage}
                            siblingCount={2}
                            size="small"
                            variant="text"
                            color="primary"
                            onChange={(_event, page) => handlePageChange(page, 'right')}
                            sx={{ mt: 1 }}
                        />
                    ) : null}
                </>
            </Grid>
            <Grid item xs={12} display={'flex'} justifyContent={'space-between'}>
                <ShvkTextField
                    type="email"
                    label={translate('EMAIL')}
                    value={state.email}
                    variant="outlined"
                    size="small"
                    sx={{ my: 2, width: '82%' }}
                    onChange={(event): void => handleChangeEmail(event)}
                    error={state.email !== '' && !isValidEmail}
                />
                <ShvkButton
                    variant="outlined"
                    size="small"
                    sx={{ height: 40, alignSelf: 'center' }}
                    onClick={handleAddEmail}
                    disabled={!isValidEmail}
                >
                    {translate('ADD_TO_SELECTED')}
                </ShvkButton>
            </Grid>
        </Grid>
    );
}

export default observer(TransferList);
