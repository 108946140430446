import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import {
    Autocomplete,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
} from '@mui/material';
import { AdminOrganization } from '../../types/organization';
import { TextFieldEvent } from '../../types/events';
import ShvkTextField from '../../styled/ShvkTextField';
import ShvkButton from '../../styled/ShvkButton';
import useStore from '../../store/storeContext';

interface Props {
    open: boolean;
    onClose(): void;
}

interface State {
    userName: string;
}

type OrganizationInput = {
    inputValue: string;
    value: AdminOrganization | null;
};

function InviteUserDialog(props: Props) {
    const { organization, user, localization, snackbar } = useStore();

    const [state, setState] = useState<State>({
        userName: '',
    });

    const [organizationInput, setOrganizationInput] = useState<OrganizationInput>({
        inputValue: '',
        value: null,
    });

    useEffect(() => {
        props.open && init();
    }, [props.open]);

    function init() {
        setState((state) => ({ ...state, userName: '' }));
        setOrganizationInput({ inputValue: '', value: null });
    }

    const handleNameChange = (event: TextFieldEvent): void => {
        setState((state) => ({ ...state, userName: event.target.value }));
    };

    const handleOrganizationChange = (newOrganization: AdminOrganization | null): void => {
        setOrganizationInput((state) => ({ ...state, value: newOrganization }));
    };

    const handleOrganizationInputChange = (newInputValue: string): void => {
        setOrganizationInput((state) => ({ ...state, inputValue: newInputValue }));
    };

    async function invite(): Promise<void> {
        try {
            await organization.callUserToOrganization(state.userName, organizationInput.value!.id);
            snackbar.showSuccess();
            props.onClose();
        } catch (error) {
            snackbar.showError(error.data?.code);
        }
    }

    const isUserNameValid = user.validateUserName(state.userName);
    const isSaveButtonDisabled = state.userName === '' || organizationInput.value === null || !isUserNameValid;
    const { translate } = localization;

    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            aria-labelledby="call-user-to-organization-dialog-title"
            maxWidth={'sm'}
            fullWidth
        >
            <DialogTitle id="call-user-to-organization-dialog-title">
                {translate('CALL_USER_TO_ORGANIZATION_TITLE')}
            </DialogTitle>
            <DialogContent dividers>
                <Typography sx={{ mb: 2 }}>{translate('CALL_USER_TO_ORGANIZATION_TIP')}</Typography>
                <ShvkTextField
                    label={translate('CALL_USER_TO_ORGANIZATION_USER_NAME_TIP')}
                    value={state.userName}
                    error={Boolean(state.userName) && !isUserNameValid}
                    variant="outlined"
                    size="small"
                    fullWidth
                    sx={{ mb: 2 }}
                    onChange={handleNameChange}
                />
                <Autocomplete
                    value={organizationInput.value}
                    onChange={(_event, newValue: AdminOrganization | null): void => {
                        handleOrganizationChange(newValue);
                    }}
                    inputValue={organizationInput.inputValue}
                    onInputChange={(_event, newInputValue): void => {
                        handleOrganizationInputChange(newInputValue);
                    }}
                    options={organization.adminOrganizations}
                    getOptionLabel={(option): string => option.name}
                    renderOption={(props: object, option): JSX.Element => (
                        <li {...props} key={option.id}>
                            {option.name}
                        </li>
                    )}
                    fullWidth
                    size="small"
                    renderInput={(params): JSX.Element => (
                        <ShvkTextField
                            {...params}
                            label={translate('ORGANIZATION')}
                            variant="outlined"
                            placeholder={translate('SEARCH_WITH_NAME')}
                            InputProps={{
                                ...params.InputProps,
                            }}
                        />
                    )}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose} variant="contained">
                    {translate('CLOSE')}
                </Button>
                <Box flexGrow={1} />
                <ShvkButton onClick={invite} disabled={isSaveButtonDisabled}>
                    {translate('SEND_INVITATION')}
                </ShvkButton>
            </DialogActions>
        </Dialog>
    );
}

export default observer(InviteUserDialog);
