import React from 'react';
import { observer } from 'mobx-react-lite';
import { runInAction } from 'mobx';
import { Box, Grid, IconButton, TableCell, TableRow, Typography } from '@mui/material';
import { Delete, PieChart, Save } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { EditorImpactOnTarget, ImpactOnTargetValue } from '../../../../types/evaTemplate';
import { TextFieldEvent } from '../../../../types/events';
import DangerIconButton from '../../../../styled/DangerIconButton';
import ShvkTextField from '../../../../styled/ShvkTextField';
import EvaSlider from '../EvaSlider';
import useStore, { rootModule } from '../../../../store/storeContext';

const PREFIX = 'EvaImpactOnTargetRow';

const classes = {
    tableCell: `${PREFIX}-tableCell`,
    buttonTableCell: `${PREFIX}-buttonTableCell`,
    indicatorCount: `${PREFIX}-indicatorCount`,
};

const { theming } = rootModule;

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    [`& .${classes.tableCell}`]: {
        border: '1px solid' + theming.shvkLightGrey,
    },

    [`& .${classes.buttonTableCell}`]: {
        border: '1px solid' + theming.shvkLightGrey,
        padding: theme.spacing(1),
    },

    [`& .${classes.indicatorCount}`]: {
        marginLeft: theme.spacing(1),
    },
}));

interface Props {
    impact: EditorImpactOnTarget;
    isDialog?: boolean;
    editing: boolean;
}

type Field = 'shortTermEffect' | 'longTermEffect';

// Save impact on target when the user stops dragging the slider. No need to do backend calls on every onChange event.
let saveImpactOnTargetTimeout: ReturnType<typeof setTimeout> | undefined = undefined;

function EvaImpactOnTargetRow(props: Props) {
    const { document, dialog, localization, snackbar, loadingIndicator } = useStore();

    async function deleteImpactOnTarget(): Promise<void> {
        const { impact } = props;
        const { translate } = localization;
        const { getConfirmation } = dialog;
        let confirmDialogText;
        confirmDialogText = translate('CONFIRM_IMPACT_ON_TARGET_REMOVAL_TEXT');
        confirmDialogText = confirmDialogText.replace('{{impactTarget}}', impact.target);
        const confirmation = await getConfirmation(undefined, confirmDialogText, true);

        if (!confirmation) return;

        try {
            loadingIndicator.show();
            await document.removeEvaImpactOnTarget(impact, document.evaDecisionOptionChapter);
            snackbar.showSuccess();
        } catch (error) {
            snackbar.showError(error.data?.code);
        } finally {
            if (props.isDialog) dialog.closeDocumentDialog('impactOnTargetDialog');
            loadingIndicator.hide();
        }
    }

    async function saveImpactOnTarget(): Promise<void> {
        const { impact } = props;
        try {
            loadingIndicator.show();
            await document.saveEvaImpactOnTarget(impact);
            snackbar.showSuccess();
        } catch (error) {
            snackbar.showError(error.data?.code);
        } finally {
            if (props.isDialog) dialog.closeDocumentDialog('impactOnTargetDialog');
            loadingIndicator.hide();
        }
    }

    function getIsEditable(): boolean {
        return !document.isCurrentDocumentApproved && !document.isPreview && props.editing;
    }
    const isEditable = getIsEditable();

    const handleChange = (event: TextFieldEvent, field: Field): void => {
        const { impact } = props;
        if (isEditable) {
            if (field === 'shortTermEffect') {
                runInAction(() => (props.impact.shortTermEffect = Number(event.target.value) as ImpactOnTargetValue));
            } else {
                runInAction(() => (props.impact.longTermEffect = Number(event.target.value) as ImpactOnTargetValue));
            }
            clearTimeout(saveImpactOnTargetTimeout);
            saveImpactOnTargetTimeout = setTimeout(() => void document.saveEvaImpactOnTarget(impact), 800);
        }
    };

    const handleDescription = (event: TextFieldEvent): void => {
        if (isEditable) {
            runInAction(() => (props.impact.description = event.target.value));
        }
    };

    const handleBlur = (): void => {
        if (isEditable) {
            const { impact } = props;
            void document.saveEvaImpactOnTarget(impact);
        }
    };

    const openEditImpactOnTargetNameDialog = (): void => {
        if (isEditable) {
            const impactOnTarget = props.impact;
            dialog.openDocumentDialog('editImpactOnTargetNameDialog', { impactOnTarget });
        }
    };

    const openIndicatorList = (): void => {
        const impactOnTarget = props.impact;
        dialog.openDocumentDialog('evaIndicatorListDialog', { impactOnTarget });
    };

    const cursorStyle = {
        cursor: isEditable ? 'pointer' : 'auto',
    };

    const { translate } = localization;

    return (
        <>
            <StyledTableRow>
                <TableCell className={classes.tableCell} rowSpan={3}>
                    <div style={cursorStyle} onClick={openEditImpactOnTargetNameDialog}>
                        {props.impact.target}
                    </div>
                </TableCell>
            </StyledTableRow>
            <StyledTableRow>
                <TableCell className={classes.tableCell}>
                    <EvaSlider
                        value={props.impact.shortTermEffect}
                        onChange={(event) => handleChange(event, 'shortTermEffect')}
                        isEditable={isEditable}
                    />
                </TableCell>
                <TableCell className={classes.tableCell}>
                    <EvaSlider
                        value={props.impact.longTermEffect}
                        onChange={(event) => handleChange(event, 'longTermEffect')}
                        isEditable={isEditable}
                    />
                </TableCell>
                <TableCell className={classes.tableCell}>
                    <Box display="flex" justifyContent="center" alignItems="center">
                        {isEditable ? (
                            <IconButton color="primary" onClick={openIndicatorList} size="large">
                                <PieChart />
                            </IconButton>
                        ) : (
                            <PieChart color="primary" />
                        )}
                        <Typography className={classes.indicatorCount}>
                            {[...props.impact.sotkanetIndicators, ...props.impact.organizationIndicators].length}
                        </Typography>
                    </Box>
                </TableCell>
                {isEditable ? (
                    <TableCell className={classes.buttonTableCell}>
                        <Grid container spacing={0}>
                            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                                <IconButton color="primary" onClick={saveImpactOnTarget} size="large">
                                    <Save />
                                </IconButton>
                            </Grid>
                            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                                <DangerIconButton onClick={deleteImpactOnTarget}>
                                    <Delete />
                                </DangerIconButton>
                            </Grid>
                        </Grid>
                    </TableCell>
                ) : null}
            </StyledTableRow>
            <StyledTableRow>
                <TableCell className={classes.tableCell} colSpan={isEditable ? 4 : 3}>
                    <ShvkTextField
                        label={translate('EVA_DECISION_OPTION_DESCRIPTION')}
                        multiline
                        rows={3}
                        variant="outlined"
                        size="small"
                        fullWidth
                        value={props.impact.description}
                        onChange={(event) => handleDescription(event)}
                        onBlur={handleBlur}
                        disabled={!isEditable}
                    />
                </TableCell>
            </StyledTableRow>
        </>
    );
}

export default observer(EvaImpactOnTargetRow);
