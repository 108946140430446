import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material';
import { TextFieldEvent } from '../../types/events';
import ConfigDataItem from '../../types/configData';
import ShvkButton from '../../styled/ShvkButton';
import ShvkTextField from '../../styled/ShvkTextField';
import useStore from '../../store/storeContext';

interface Props {
    open: boolean;
    onClose(): void;
    configDataItem: ConfigDataItem | null;
}

interface State {
    key: string;
    value: string;
    description: string;
}

type ConfigDataFormField = 'key' | 'value' | 'description';

function ConfigDataItemDialog(props: Props) {
    const { admin, localization, snackbar } = useStore();

    const [state, setState] = useState<State>({
        key: '',
        value: '',
        description: '',
    });

    useEffect(() => {
        init();
    }, [props.open]);

    const init = (): void => {
        setState({
            key: props.configDataItem?.key || '',
            value: props.configDataItem?.value || '',
            description: props.configDataItem?.description || '',
        });
    };

    const handleChange = (event: TextFieldEvent, field: ConfigDataFormField): void => {
        setState((state) => ({ ...state, [field]: event.target.value }));
    };

    async function save(): Promise<void> {
        try {
            const item: ConfigDataItem = {
                key: state.key,
                value: state.value,
                description: state.description,
                id: props.configDataItem?.id,
            };

            await admin.saveConfigData(item);
            snackbar.showSuccess();
        } catch (error) {
            snackbar.showError(error.data?.code);
        } finally {
            props.onClose();
        }
    }

    const { translate } = localization;

    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            aria-labelledby="config-data-dialog"
            maxWidth={'sm'}
            fullWidth
        >
            <DialogTitle id="config-data-dialog">{translate('CONTROL_DATA')}</DialogTitle>
            <DialogContent dividers>
                <Grid container spacing={2} direction="column" alignItems="stretch">
                    <Grid item>
                        <ShvkTextField
                            required
                            label={translate('KEY')}
                            fullWidth
                            value={state.key}
                            variant="outlined"
                            size="small"
                            onChange={(event): void => handleChange(event, 'key')}
                        />
                    </Grid>
                    <Grid item>
                        <ShvkTextField
                            required
                            label={translate('VALUE')}
                            fullWidth
                            value={state.value}
                            variant="outlined"
                            size="small"
                            onChange={(event): void => handleChange(event, 'value')}
                        />
                    </Grid>
                    <Grid item>
                        <ShvkTextField
                            label={translate('DESCRIPTION')}
                            fullWidth
                            value={state.description}
                            variant="outlined"
                            size="small"
                            onChange={(event): void => handleChange(event, 'description')}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose} variant="contained">
                    {translate('CLOSE')}
                </Button>
                <Box flexGrow={1} />
                <ShvkButton disabled={Boolean(!state.key) || Boolean(!state.value)} onClick={save}>
                    {translate('SAVE')}
                </ShvkButton>
            </DialogActions>
        </Dialog>
    );
}

export default observer(ConfigDataItemDialog);
