import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Button, Dialog, DialogActions, DialogContent, Typography } from '@mui/material';
import { EvaModelNestableItem } from '../../../../types/nestable';
import { TextFieldEvent } from '../../../../types/events';
import ShvkTextField from '../../../../styled/ShvkTextField';
import ShvkButton from '../../../../styled/ShvkButton';
import useStore from '../../../../store/storeContext';

interface Props {
    isOpen: boolean;
    close(): void;
    id: number;
    item: EvaModelNestableItem;
    handleDescriptionChange(value: string, id: number): void;
    handleTextChange(value: string, id: number, groupNumber: number | null): void;
}

type Field = 'text' | 'description';

interface State {
    text: string;
    description: string;
}

function ChangeItemInfoDialog(props: Props) {
    const { localization, loadingIndicator, snackbar, theming, document } = useStore();

    const [state, setState] = useState<State>({
        text: '',
        description: '',
    });

    useEffect(() => {
        props.isOpen && init();
    }, [props.isOpen]);

    function init(): void {
        setState((state) => ({ ...state, text: props.item.text || '', description: props.item.description || '' }));
    }

    const handleChange = (event: TextFieldEvent, field: Field): void => {
        setState((state) => ({ ...state, [field]: event.target.value }));
    };

    async function save(): Promise<void> {
        try {
            loadingIndicator.show();
            await props.handleTextChange(state.text, props.id, props.item.groupNumber);
            await props.handleDescriptionChange(state.description, props.id);
            await document.reloadCurrentDocument();
            snackbar.showSuccess();
        } catch (error) {
            snackbar.showError(error.data?.code);
        } finally {
            props.close();
            loadingIndicator.hide();
        }
    }

    const { translate } = localization;

    return (
        <Dialog open={props.isOpen} onClose={props.close} maxWidth="sm" fullWidth>
            <DialogContent>
                <Typography>{translate('NAME')}</Typography>
                <ShvkTextField
                    value={state.text}
                    variant="outlined"
                    size="small"
                    fullWidth
                    onChange={(event): void => handleChange(event, 'text')}
                />
                <Typography style={{ paddingTop: theming.spacing(1) }}>
                    {props.item.depth === 2
                        ? translate('EVA_DOCUMENT_PERSPECTIVE_COMMENTS__TIP')
                        : translate('DESCRIPTION')}
                </Typography>
                <ShvkTextField
                    multiline
                    rows={6}
                    value={state.description}
                    variant="outlined"
                    size="small"
                    fullWidth
                    onChange={(event): void => handleChange(event, 'description')}
                />
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={props.close}>
                    {translate('CLOSE')}
                </Button>
                <Box flexGrow={1} />
                <ShvkButton onClick={save}>{translate('SAVE')}</ShvkButton>
            </DialogActions>
        </Dialog>
    );
}

export default observer(ChangeItemInfoDialog);
