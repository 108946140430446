import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Grid, IconButton, TableCell, TableRow, Tooltip, Typography } from '@mui/material';
import { Comment, Edit, Info, InfoOutlined, OpenInNew } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { Indicator } from '../../../types/indicator';
import IndicatorCircle from './IndicatorCircle';
import IndicatorDirection from './IndicatorDirection';
import IndicatorComparison from './IndicatorComparison';
import EditIndicatorUrlDialog from '../dialogs/shvk/EditIndicatorUrlDialog';
import useStore from '../../../store/storeContext';

const PREFIX = 'IndicatorTableRow';

const classes = {
    tableCell: `${PREFIX}-tableCell`,
    indicatorNameDiv: `${PREFIX}-indicatorNameDiv`,
    indicatorTypeDiv: `${PREFIX}-indicatorTypeDiv`,
};

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    [`& .${classes.tableCell}`]: {
        borderBottom: '0',
    },

    [`& .${classes.indicatorNameDiv}`]: {
        cursor: 'pointer',
    },

    [`& .${classes.indicatorTypeDiv}`]: {
        paddingRight: theme.spacing(0.5),
    },
}));

interface Props {
    indicator: Indicator;
    packageId?: number;
    editing?: boolean;
    toggleEdit?(): void;
}

interface State {
    open: boolean;
}

function IndicatorTableRow(props: Props) {
    const context = useStore();

    const [state, setState] = useState<State>({
        open: false,
    });

    const handleIndicatorNameClick = (): void => {
        const { isPreview } = context.document;
        if (isPreview || props.indicator.textOnly) return;
        context.dialog.openDocumentDialog('indicatorGraphDialog', { indicator: props.indicator });
    };

    const handleOpenSotkanetIndicatorInfo = (): void => {
        const { indicator } = props;
        const code = indicator.sotkanetId ? indicator.sotkanetId : indicator.code;
        const infoUrl = indicator.infoUrl;

        if (code) {
            window.open('https://www.sotkanet.fi/sotkanet/fi/metadata/indicators/' + code);
        } else if (infoUrl) {
            const url = infoUrl.match(/^https?:\/\//i) ? infoUrl : 'http://' + infoUrl;
            window.open(url);
        }
    };

    function showInfoLink(): boolean {
        const { indicator } = props;

        const code = indicator.sotkanetId ? indicator.sotkanetId : indicator.code;
        const infoUrl = indicator.infoUrl;

        return Boolean(code) || Boolean(infoUrl);
    }

    function indicatorTooltipText(indicator: Indicator): string {
        const { translate } = context.localization;
        if (indicator.type.code === 'P') {
            return '(' + translate('BASE_INDICATOR').charAt(0) + ') ' + translate('BASE_INDICATOR');
        } else if (indicator.type.code === 'L') {
            return '(' + translate('CUSTOM_INDICATOR').charAt(0) + ') ' + translate('CUSTOM_INDICATOR');
        } else if (indicator.type.code === 'M') {
            return '(' + translate('OTHER_INDICATOR').charAt(0) + ') ' + translate('OTHER_INDICATOR');
        } else if (indicator.type.code === 'T') {
            return '(' + translate('FILL_INDICATOR').charAt(0) + ') ' + translate('FILL_INDICATOR');
        } else {
            return '';
        }
    }

    function showHyteKTag(): boolean {
        return (
            context.session.hyteIdsK.some((code) => code === props.indicator.code) ||
            context.session.hyteIdoK.some((name) => name === props.indicator.name)
        );
    }

    function showHyteATag(): boolean {
        return (
            context.session.hyteIdsA.some((code) => code === props.indicator.code) ||
            context.session.hyteIdoA.some((name) => name === props.indicator.name)
        );
    }

    const handleDescriptionDialog = (): void => {
        const { indicator } = props;
        context.dialog.openDocumentDialog('indicatorDescriptionDialog', { indicator });
    };

    const handleIndicatorWithNoteDialog = (): void => {
        const { indicator, packageId, editing, toggleEdit } = props;
        if (!packageId) return;
        const indicatorPackage = context.indicator.indicatorPackageById(packageId);
        context.dialog.openDocumentDialog('indicatorWithNoteDialog', {
            indicator,
            indicatorPackage,
            editing,
            toggleEdit,
        });
    };

    const handleEditIndicatorUrlDialog = (): void => {
        setState((state) => ({ ...state, open: !state.open }));
    };

    const { indicator, packageId } = props;
    const { isPreview } = context.document;
    const translate = context.localization.translate;

    return (
        <>
            <StyledTableRow>
                <TableCell className={indicator.description && !indicator.textOnly ? classes.tableCell : ''}>
                    <Grid
                        container
                        spacing={1}
                        direction={context.indicator.getIndicatorViewMode === 'WIDE_MODE' ? 'column' : 'row'}
                    >
                        <Grid item>
                            <Tooltip
                                placement="top"
                                leaveDelay={100}
                                title={isPreview ? '' : translate('SHOW_INDICATOR_GRAPH_TOOLTIP')}
                            >
                                <div
                                    onClick={handleIndicatorNameClick}
                                    className={!isPreview && !indicator.textOnly ? classes.indicatorNameDiv : ''}
                                >
                                    {indicator.name}
                                </div>
                            </Tooltip>
                            <Grid container direction="row">
                                <Tooltip
                                    placement="top"
                                    leaveDelay={100}
                                    disableInteractive
                                    title={indicatorTooltipText(indicator)}
                                >
                                    <div className={classes.indicatorTypeDiv}>({indicator.type.code})</div>
                                </Tooltip>
                                {context.indicator.getMinimumDataContentIDsForArea.some(
                                    (id) => id === indicator.indicatorId,
                                ) && (
                                    <Tooltip
                                        placement="top"
                                        leaveDelay={100}
                                        disableInteractive
                                        title={
                                            '(VÄH-' +
                                            translate('MINIMUM_DATA_AREA').charAt(0) +
                                            ') ' +
                                            translate('MINIMUM_DATA_AREA')
                                        }
                                    >
                                        <div className={classes.indicatorTypeDiv}>
                                            {'(VÄH-' + translate('MINIMUM_DATA_AREA').charAt(0) + ')'}
                                        </div>
                                    </Tooltip>
                                )}
                                {context.indicator.getMinimumDataContentIDsForMunicipality.some(
                                    (id) => id === indicator.indicatorId,
                                ) && (
                                    <Tooltip
                                        placement="top"
                                        leaveDelay={100}
                                        disableInteractive
                                        title={
                                            '(VÄH-' +
                                            translate('MINIMUM_DATA_MUNICIPAL').charAt(0) +
                                            ') ' +
                                            translate('MINIMUM_DATA_MUNICIPAL')
                                        }
                                    >
                                        <div className={classes.indicatorTypeDiv}>
                                            {'(VÄH-' + translate('MINIMUM_DATA_MUNICIPAL').charAt(0) + ')'}
                                        </div>
                                    </Tooltip>
                                )}
                                {showHyteKTag() && (
                                    <Tooltip
                                        placement="top"
                                        leaveDelay={100}
                                        disableInteractive
                                        title={'(HYTE-K) ' + translate('HYTE_TOOLTIP')}
                                    >
                                        <div className={classes.indicatorTypeDiv}>{'(HYTE-K)'}</div>
                                    </Tooltip>
                                )}
                                {showHyteATag() && (
                                    <Tooltip
                                        placement="top"
                                        leaveDelay={100}
                                        disableInteractive
                                        title={'(HYTE-A) ' + translate('HYTE_TOOLTIP')}
                                    >
                                        <div className={classes.indicatorTypeDiv}>{'(HYTE-A)'}</div>
                                    </Tooltip>
                                )}
                            </Grid>
                        </Grid>
                        <Grid item>
                            {showInfoLink() && (
                                <IconButton size="small" color="primary" onClick={handleOpenSotkanetIndicatorInfo}>
                                    <Tooltip leaveDelay={100} title={translate('SHOW_SOTKANET_METADATA')}>
                                        <Info />
                                    </Tooltip>
                                </IconButton>
                            )}
                            {packageId && (
                                // Table row in IndicatorWithNoteDialog doesn't need packageId, so these are hidden
                                <>
                                    {!isPreview && (
                                        <>
                                            <IconButton size="small" color="primary" onClick={handleDescriptionDialog}>
                                                <Tooltip
                                                    leaveDelay={100}
                                                    title={translate('EDIT_INDICATOR_DESCRIPTION_TOOLTIP')}
                                                >
                                                    <Comment />
                                                </Tooltip>
                                            </IconButton>
                                            <IconButton
                                                size="small"
                                                color="primary"
                                                onClick={handleIndicatorWithNoteDialog}
                                            >
                                                <Tooltip
                                                    leaveDelay={100}
                                                    title={translate('EDIT_INDICATOR_SUMMARY_TOOLTIP')}
                                                >
                                                    <Edit />
                                                </Tooltip>
                                            </IconButton>
                                        </>
                                    )}
                                    {indicator.textOnly && (
                                        <IconButton size="small" color="primary" onClick={handleEditIndicatorUrlDialog}>
                                            <Tooltip leaveDelay={100} title={translate('EDIT_INDICATOR_URL')}>
                                                <OpenInNew />
                                            </Tooltip>
                                        </IconButton>
                                    )}
                                </>
                            )}
                            {indicator.metadata && (
                                <IconButton size="small" color="primary" style={{ cursor: 'default' }}>
                                    <Tooltip title={<div dangerouslySetInnerHTML={{ __html: indicator.metadata }} />}>
                                        <InfoOutlined />
                                    </Tooltip>
                                </IconButton>
                            )}
                        </Grid>
                    </Grid>
                </TableCell>
                {indicator.textOnly ? (
                    <TableCell colSpan={4} sx={{ py: 0 }}>
                        {indicator.description && (
                            <Typography
                                sx={{ wordBreak: 'break-word' }}
                                dangerouslySetInnerHTML={{ __html: indicator.description }}
                            />
                        )}
                    </TableCell>
                ) : context.indicator.getIndicatorViewMode === 'WIDE_MODE' ? (
                    <>
                        <TableCell className={indicator.description ? classes.tableCell : ''}>
                            <IndicatorCircle indicator={indicator} />
                        </TableCell>
                        <TableCell className={indicator.description ? classes.tableCell : ''}>
                            <IndicatorDirection indicator={indicator} />
                        </TableCell>
                        <TableCell align="center" className={indicator.description ? classes.tableCell : ''}>
                            <IndicatorComparison indicator={indicator} />
                        </TableCell>
                    </>
                ) : null}
            </StyledTableRow>
            {indicator.description && !indicator.textOnly && (
                <TableRow>
                    <TableCell colSpan={5}>
                        {indicator.description && (
                            <Typography
                                sx={{ wordBreak: 'break-word' }}
                                dangerouslySetInnerHTML={{ __html: indicator.description }}
                            />
                        )}
                    </TableCell>
                </TableRow>
            )}
            <EditIndicatorUrlDialog
                isOpen={state.open}
                close={handleEditIndicatorUrlDialog}
                indicator={props.indicator}
            />
        </>
    );
}

export default observer(IndicatorTableRow);
