import Axios from '../../axiosConfig';
import { BACKEND_URL } from '../../config';
import { configure, runInAction, makeAutoObservable } from 'mobx';
import Contact from '../../types/contact';

configure({ enforceActions: 'observed' });

export default class ContactModule {
    private contactInfo: Contact | null = null;

    constructor() {
        makeAutoObservable(this, {}, { autoBind: true });
    }

    public async fetchContact(): Promise<void> {
        const response = await Axios({
            method: 'GET',
            url: BACKEND_URL + 'app/contactInfo',
        });
        if (response?.status === 200) runInAction(() => (this.contactInfo = response.data[0]));
    }

    public get contact(): Contact | null {
        return this.contactInfo;
    }
}
