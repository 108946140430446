import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import {
    Box,
    Button,
    Card,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Radio,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import ShvkButton from '../../../styled/ShvkButton';
import useStore from '../../../store/storeContext';

interface Props {
    isOpen: boolean;
    close(): void;
}

interface State {
    selectedUserId: number | null;
}

function ChangeOwnerDialog(props: Props) {
    const { document, localization, snackbar } = useStore();

    const [state, setState] = useState<State>({
        selectedUserId: null,
    });

    useEffect(() => {
        init();
    }, [props.isOpen]);

    const handleRowClick = (_event: React.MouseEvent<unknown>, id: number): void => {
        setState((state) => ({ ...state, selectedUserId: id }));
    };

    const init = (): void => {
        setState((state) => ({ ...state, selectedUserId: document.currentDocument.owner.id }));
    };

    const isSaveButtonDisabled = (): boolean => {
        if (!state.selectedUserId) return true;
        // One of the users in the list must be selected
        return !document.currentDocument.users.some((user) => user.id === state.selectedUserId);
    };

    async function save(): Promise<void> {
        if (!state.selectedUserId) return;

        try {
            await document.changeDocumentOwner(state.selectedUserId);
            snackbar.showSuccess();
            props.close();
        } catch (e) {
            snackbar.showError(e.data?.code);
        }
    }

    const { translate } = localization;
    const { currentDocument } = document;

    return (
        <Dialog open={props.isOpen} onClose={props.close} aria-labelledby="change-owner-dialog-title" maxWidth="md">
            <DialogTitle id="change-owner-dialog-title">{translate('DOCUMENT_OWNER_CHANGE')}</DialogTitle>
            <DialogContent dividers>
                <Box mb={4}>
                    <Typography>
                        {translate('CURRENT_DOCUMENT_OWNER')}: {currentDocument.owner.firstName}{' '}
                        {currentDocument.owner.lastName}
                    </Typography>
                </Box>
                <TableContainer component={Card} variant="outlined">
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell padding="checkbox" />
                                <TableCell>{translate('FIRSTNAME')}</TableCell>
                                <TableCell>{translate('LASTNAME')}</TableCell>
                                <TableCell>{translate('EMAIL')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {currentDocument.users.map((user) => (
                                <TableRow
                                    key={user.id}
                                    hover
                                    style={{ cursor: 'pointer' }}
                                    role="checkbox"
                                    aria-checked={state.selectedUserId === user.id}
                                    selected={state.selectedUserId === user.id}
                                    onClick={(event): void => handleRowClick(event, user.id)}
                                >
                                    <TableCell padding="checkbox">
                                        <Radio checked={state.selectedUserId === user.id} />
                                    </TableCell>
                                    <TableCell>{user.firstName}</TableCell>
                                    <TableCell>{user.lastName}</TableCell>
                                    <TableCell>{user.userName}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={props.close}>
                    {translate('CANCEL')}
                </Button>
                <Box flexGrow={1} />
                <ShvkButton onClick={save} disabled={isSaveButtonDisabled()}>
                    {translate('SAVE')}
                </ShvkButton>
            </DialogActions>
        </Dialog>
    );
}

export default observer(ChangeOwnerDialog);
