import React from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@mui/material';
import { InsertDriveFile } from '@mui/icons-material';
import ShvkButton from '../../../styled/ShvkButton';
import useStore from '../../../store/storeContext';

interface Props {
    isOpen: boolean;
    close(): void;
}

function DisapprovalDialog(props: Props) {
    const { document, localization, loadingIndicator, snackbar } = useStore();

    async function disapprove(): Promise<void> {
        loadingIndicator.show();

        try {
            await document.disapproveDocument();
            snackbar.showSuccess();
            props.close();
            await document.reloadCurrentDocument();
        } catch (error) {
            snackbar.showError(error.data?.code);
        } finally {
            loadingIndicator.hide();
        }
    }

    const { translate } = localization;
    const { currentDocument } = document;

    return (
        <Dialog open={props.isOpen} onClose={props.close} aria-labelledby="disapproval-dialog-title" maxWidth="sm">
            <DialogTitle id="disapproval-dialog-title">{translate('DOCUMENT_DISAPPROVE')}</DialogTitle>
            <DialogContent dividers>
                <Box mb={4}>
                    <Typography>{translate('DOCUMENT_DISAPPROVE_SUBTITLE')}</Typography>
                </Box>
                <Grid container>
                    <Grid item sm={2} xs={12}>
                        <InsertDriveFile style={{ fontSize: 80 }} color="primary" />
                    </Grid>
                    <Grid item sm={10} xs={12}>
                        <Box pt={3}>
                            <Typography variant="subtitle2">
                                {translate('DOCUMENT')}: {currentDocument.name}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={props.close}>
                    {translate('CANCEL')}
                </Button>
                <Box flexGrow={1} />
                <ShvkButton onClick={disapprove}>{translate('DISAPPROVE')}</ShvkButton>
            </DialogActions>
        </Dialog>
    );
}

export default observer(DisapprovalDialog);
