import React from "react";
import { observer } from "mobx-react";
import { runInAction } from "mobx";
import { Checkbox, TableBody, TableCell, TableRow } from "@mui/material";
import { Delete } from "@mui/icons-material";
import {
    AddIndicatorTopParams,
    EnableIndicatorParams,
    Indicator,
    IndicatorPackageTheme,
    IndicatorValue,
    RemoveIndicatorParams,
    RemoveIndicatorTopParams,
    SaveIndicatorDirectionParams,
    SetPrintableParams
} from "../../../types/indicator";
import DangerIconButton from "../../../styled/DangerIconButton";
import IndicatorSlider from "./IndicatorSlider";
import useStore from "../../../store/storeContext";

interface Props {
    indicatorList: Indicator[];
    indicatorPackageTheme?: IndicatorPackageTheme | null;
    updateIndicatorList: () => void;
}

function EditIndicatorListBody(props: Props) {
    const context = useStore();
    const { snackbar, document, dialog } = context;

    async function setEnabled(indicator: Indicator, enabled: boolean): Promise<void> {
        const { indicatorPackageTheme } = props;
        if (!indicatorPackageTheme) return;

        const enableParams: EnableIndicatorParams = {
            enabled,
            id: indicator.id,
            source: indicator.source,
        };

        try {
            // Change value before request, so it doesn't look like it's lagging.
            runInAction(() => (indicator.enabled = enabled));
            await context.indicator.enableIndicator(enableParams);
            props.updateIndicatorList();
        } catch (e) {
            // If request fails, change value back to what it was
            runInAction(() => (indicator.enabled = !enabled));
            snackbar.showError(e.data?.code);
        }
    }

    async function setPrintable(indicator: Indicator, printable: boolean): Promise<void> {
        const { indicatorPackageTheme } = props;
        if (!indicatorPackageTheme) return;

        const printableParams: SetPrintableParams = {
            printable,
            id: indicator.id,
            source: indicator.source,
        };
        try {
            // Change value before request, so it doesn't look like it's lagging.
            runInAction(() => (indicator.isPrintable = printable));
            await context.indicator.setPrintable(printableParams);
        } catch (e) {
            // If request fails, change value back to what it was
            runInAction(() => (indicator.isPrintable = !printable));
            snackbar.showError(e.data?.code);
        }
    }

    async function handleTopSelection(indicator: Indicator, selected: boolean): Promise<void> {
        try {
            if (selected) {
                const addIndicatorTopParams: AddIndicatorTopParams = {
                    indicatorId: indicator.id,
                    source: indicator.source,
                };
                await context.indicator.addIndicatorTop(addIndicatorTopParams);
            } else {
                const removeIndicatorTopParams: RemoveIndicatorTopParams = {
                    indicatorId: indicator.id,
                    source: indicator.source,
                };
                await context.indicator.removeIndicatorTop(removeIndicatorTopParams);
            }
        } catch (e) {
            snackbar.showError(e.data?.code);
        }
    }

    async function remove(indicator: Indicator): Promise<void> {
        const { translate } = context.localization;

        const confirmText = translate('CONFIRM_INDICATOR_REMOVAL_SUBTITLE') + ': ' + indicator.name + '?';
        const confirmation = await dialog.getConfirmation(undefined, confirmText);
        if (!confirmation) return;

        const params: RemoveIndicatorParams = {
            id: indicator.id,
            source: indicator.source,
        };

        try {
            await context.indicator.removeIndicator(params);
            snackbar.showSuccess();
        } catch (e) {
            snackbar.showError(e.data?.code);
        }
    }

    function indicatorTypeText(indicator: Indicator): string {
        const { translate } = context.localization;

        switch (indicator.type.code) {
            case 'P':
                return translate('BASE_INDICATOR');
            case 'T':
                return translate('FILL_INDICATOR');
            case 'L':
                return translate('CUSTOM_INDICATOR');
            case 'M':
                return translate('OTHER_INDICATOR');
        }
    }

    function checkboxType(indicator: Indicator): 'none' | 'normal' | 'checked' {
        const { hyteIdsKLossi, hyteIdsALossi } = context.session;
        const { isRegional } = document.currentDocument.organization;

        if (indicator.textOnly) return 'none';
        else if (indicator.source.code === 'CHP_CUSTOM') return 'none';
        else if (!isRegional && indicator.sotkanetId && hyteIdsKLossi.includes(indicator.sotkanetId.toString()))
            return 'checked';
        else if (isRegional && indicator.sotkanetId && hyteIdsALossi.includes(indicator.sotkanetId.toString()))
            return 'checked';
        else return 'normal';
    }

    async function setIndicatorValue(indicator: Indicator, direction: IndicatorValue): Promise<void> {
        const { indicatorPackageTheme } = props;
        if (!indicatorPackageTheme) return;

        const directionParams: SaveIndicatorDirectionParams = {
            direction,
            indicatorId: indicator.id,
            source: indicator.source,
        };

        const directionBeforeUpdate = indicator.direction;

        try {
            // Change value before request, so it doesn't look like it's lagging.
            runInAction(() => (indicator.direction = direction));
            await context.indicator.saveIndicatorDirection(directionParams);
        } catch (e) {
            // If request fails, change value back to what it was
            runInAction(() => (indicator.direction = directionBeforeUpdate));
            snackbar.showError(e.data?.code);
        }
    }

    return (
        <TableBody>
            {props.indicatorList.map((indicator) => (
                <TableRow key={indicator.id}>
                    <TableCell padding="checkbox" style={{width: "9%"}}>
                        <Checkbox
                            checked={indicator.enabled}
                            onChange={(event): Promise<void> =>
                                setEnabled(indicator, event.target.checked)
                            }
                        />
                    </TableCell>
                    <TableCell style={{width: "41%"}}>{indicator?.name}</TableCell>
                    <TableCell>{indicatorTypeText(indicator)}</TableCell>
                    <TableCell>
                        <IndicatorSlider
                            value={indicator.direction}
                            onChange={(newValue): Promise<void> =>
                                setIndicatorValue(indicator, newValue)
                            }
                        />
                    </TableCell>
                    <TableCell padding="checkbox" align="right">
                        <Checkbox
                            checked={indicator.isPrintable}
                            onChange={(event): Promise<void> =>
                                setPrintable(indicator, event.target.checked)
                            }
                        />
                    </TableCell>
                    {!document.isAnotherReport && document.hasSummary && (
                        <TableCell padding="checkbox" align="right">
                            {checkboxType(indicator) === 'none' ? null : checkboxType(indicator) ===
                            'checked' ? (
                                <Checkbox checked={true} />
                            ) : (
                                <Checkbox
                                    checked={context.indicator.indicatorTops.some(
                                        (top) =>
                                            (top.sotkanetIndicatorId === indicator.indicatorId ||
                                                top.otherIndicatorId === indicator.indicatorId) &&
                                            props.indicatorPackageTheme?.id === top.themeId,
                                    )}
                                    onChange={(event): Promise<void> =>
                                        handleTopSelection(indicator, event.target.checked)
                                    }
                                />
                            )}
                        </TableCell>
                    )}
                    <TableCell padding="checkbox" align="right">
                        {indicator.isRemovable && (
                            <DangerIconButton onClick={(): Promise<void> => remove(indicator)}>
                                <Delete />
                            </DangerIconButton>
                        )}
                    </TableCell>
                </TableRow>
            ))}
        </TableBody>
    )
}

export default observer(EditIndicatorListBody)

