import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import {
    Box,
    Grid,
    Menu,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Paper,
    Typography,
} from '@mui/material';
import { ArrowDropDown } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { EditorPerspective } from '../../../../types/evaTemplate';
import { ButtonEvent } from '../../../../types/events';
import { Chapter } from '../../../../types/document';
import ComparisonTableImpactOnTargetRows from './ComparisonTableImpactOnTargetRows';
import ShvkButton from '../../../../styled/ShvkButton';
import useStore, { rootModule } from '../../../../store/storeContext';

const PREFIX = 'EvaDecisionOptionComparisonTable';

const classes = {
    tableHeaderCell: `${PREFIX}-tableHeaderCell`,
    perspectiveNameCell: `${PREFIX}-perspectiveNameCell`,
};

const { theming } = rootModule;

const StyledBox = styled(Box)({
    [`& .${classes.tableHeaderCell}`]: {
        border: '1px solid' + theming.shvkLightGrey,
    },
    [`& .${classes.perspectiveNameCell}`]: {
        border: '1px solid' + theming.shvkLightGrey,
        backgroundColor: theming.shvkPetrol10,
    },
});

interface Props {
    chapter: Chapter;
    editing: boolean;
}

interface State {
    perspectiveMenu: HTMLElement | null;
    perspective: EditorPerspective | null;
}

function EvaDecisionOptionComparisonTable(props: Props) {
    const { document, localization } = useStore();

    const [state, setState] = useState<State>({
        perspectiveMenu: null,
        perspective: null,
    });

    const handleMenu = (event: ButtonEvent): void => {
        setState((state) => ({
            ...state,
            perspectiveMenu: state.perspectiveMenu === null ? event.currentTarget : null,
        }));
    };

    const handlePerspectiveSelection = (perspective: EditorPerspective | null): void => {
        setState((state) => ({
            ...state,
            perspectiveMenu: null,
            perspective,
        }));
    };

    function getSelectedPerspectiveIndex(): number {
        const { evaDecisionOptionChapter } = document;
        const evaPerspectives =
            evaDecisionOptionChapter &&
            evaDecisionOptionChapter.evaDecisionOptions.length > 0 &&
            evaDecisionOptionChapter?.evaDecisionOptions[0]?.evaPerspectives;
        if (!evaPerspectives) return -1;
        return evaPerspectives.findIndex((p) => p.id === state.perspective?.id);
    }

    const selectedPerspectiveIndex = getSelectedPerspectiveIndex();
    const { translate } = localization;
    const { evaDecisionOptionChapter } = document;

    return (
        <StyledBox my={2}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <ShvkButton
                        aria-controls="perspective-menu"
                        aria-haspopup="true"
                        onClick={handleMenu}
                        endIcon={<ArrowDropDown />}
                    >
                        {state.perspective ? state.perspective.title : translate('EVA_ALL_PERSPECTIVES')}
                    </ShvkButton>
                    <Menu
                        id="perspective-menu"
                        anchorEl={state.perspectiveMenu}
                        keepMounted
                        open={Boolean(state.perspectiveMenu)}
                        onClose={handleMenu}
                    >
                        {/* Get perspectives from the first decision option,
                            because all decision options have the same perspectives*/}
                        {evaDecisionOptionChapter &&
                            evaDecisionOptionChapter.evaDecisionOptions.length > 0 &&
                            evaDecisionOptionChapter?.evaDecisionOptions[0]?.evaPerspectives.map((perspective) => (
                                <MenuItem
                                    key={perspective.id}
                                    onClick={(): void => handlePerspectiveSelection(perspective)}
                                >
                                    {perspective.title}
                                </MenuItem>
                            ))}
                        <MenuItem key={'EVA_ALL_PERSPECTIVES'} onClick={(): void => handlePerspectiveSelection(null)}>
                            {translate('EVA_ALL_PERSPECTIVES')}
                        </MenuItem>
                    </Menu>
                </Grid>
                <Grid item xs={12}>
                    <Paper style={{ maxHeight: '700px', overflowX: 'auto', overflowY: 'auto' }}>
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell className={classes.tableHeaderCell}>
                                        {translate('EVA_IMPACT_ON_TARGET')}
                                    </TableCell>
                                    {evaDecisionOptionChapter?.evaDecisionOptions.map((decisionOption) => (
                                        <TableCell key={decisionOption.id} className={classes.tableHeaderCell}>
                                            <Typography sx={{ wordBreak: 'break-word' }}>
                                                {decisionOption.text}
                                            </Typography>
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            {evaDecisionOptionChapter && evaDecisionOptionChapter.evaDecisionOptions.length > 0 && (
                                <TableBody>
                                    {selectedPerspectiveIndex !== -1
                                        ? // Only one perspective selected. Render impact on target list.
                                          evaDecisionOptionChapter?.evaDecisionOptions[0]?.evaPerspectives[
                                              selectedPerspectiveIndex
                                          ]?.impactTargets.map((impact, impactIndex) => (
                                              <ComparisonTableImpactOnTargetRows
                                                  key={impact.id}
                                                  impact={impact}
                                                  impactIndex={impactIndex}
                                                  perspectiveIndex={selectedPerspectiveIndex}
                                                  editing={props.editing}
                                              />
                                          ))
                                        : // All perspectives selected.
                                          // Render row which displays perspective name, and list impact on targets
                                          evaDecisionOptionChapter?.evaDecisionOptions[0]?.evaPerspectives.map(
                                              (perspective, perspectiveIndex) => (
                                                  <React.Fragment key={perspective.id}>
                                                      {/* PERSPECTIVE NAME ROW*/}
                                                      <TableRow>
                                                          <TableCell
                                                              colSpan={
                                                                  evaDecisionOptionChapter?.evaDecisionOptions.length +
                                                                  1
                                                              }
                                                              className={classes.perspectiveNameCell}
                                                          >
                                                              {translate('EVA_PERSPECTIVE')}: {perspective.title}
                                                          </TableCell>
                                                      </TableRow>
                                                      {perspective.impactTargets.map((impact, impactIndex) => (
                                                          <ComparisonTableImpactOnTargetRows
                                                              key={impact.id}
                                                              impact={impact}
                                                              impactIndex={impactIndex}
                                                              perspectiveIndex={perspectiveIndex}
                                                              editing={props.editing}
                                                          />
                                                      ))}
                                                  </React.Fragment>
                                              ),
                                          )}
                                </TableBody>
                            )}
                        </Table>
                    </Paper>
                </Grid>
            </Grid>
        </StyledBox>
    );
}

export default observer(EvaDecisionOptionComparisonTable);
