import React, { useState, useEffect, ChangeEvent, DragEvent } from 'react';
import { observer } from 'mobx-react-lite';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Paper,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Tabs,
    Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { TabContext, TabPanel } from '@mui/lab';
import { CloudUpload, Save, GetApp } from '@mui/icons-material';
import {
    AddCustomIndicator,
    AddIndicatorParams,
    CreateIndicatorParams,
    Indicator,
    IndicatorPackageTheme,
    IndicatorSource,
    OrganizationTableValue,
    UpdateOrganizationIndicatorParams,
} from '../../../../types/indicator';
import ShvkTextField from '../../../../styled/ShvkTextField';
import ShvkButton from '../../../../styled/ShvkButton';
import useStore, { rootModule } from '../../../../store/storeContext';

const PREFIX = 'UploadCustomIndicatorDialog';

const classes = {
    tableCell: `${PREFIX}-tableCell`,
    warningText: `${PREFIX}-warningText`,
    warningCell: `${PREFIX}-warningCell`,
};

const { theming } = rootModule;

const StyledDialog = styled(Dialog)(({ theme }) => ({
    [`& .${classes.tableCell}`]: {
        border: '1px solid' + theming.shvkLightGrey,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(0.5),
    },

    [`& .${classes.warningText}`]: {
        padding: theme.spacing(1),
        color: theme.palette.error.main,
    },

    [`& .${classes.warningCell}`]: {
        backgroundColor: theming.shvkRed10,
    },
}));

interface Props {
    isOpen: boolean;
    close(): void;
    indicatorPackageTheme?: IndicatorPackageTheme | null;
}

interface State {
    activeTab: string;
}

function UploadCustomIndicatorDialog(props: Props) {
    const context = useStore();
    const { document, organization, localization, loadingIndicator, snackbar } = context;

    const [uploadedIndicators, setUploadedIndicators] = useState<AddCustomIndicator[]>([]);
    const [state, setState] = useState<State>({
        activeTab: 'upload',
    });

    useEffect(() => {
        if (props.isOpen) {
            setState((state) => ({ ...state, activeTab: 'upload' }));
            setUploadedIndicators([]);
        }
        return () => {
            setState((state) => ({ ...state, activeTab: 'upload' }));
            setUploadedIndicators([]);
        };
    }, [props.isOpen]);

    async function upload(file: File): Promise<void> {
        try {
            loadingIndicator.show();
            const indicator = await document.uploadIndicator(file);
            const indicatorAdded = [...uploadedIndicators, ...indicator];
            setUploadedIndicators(indicatorAdded);
            setState((state) => ({
                ...state,
                activeTab: indicatorAdded.length > 0 ? (indicatorAdded.length - 1).toString() : 'upload',
            }));
            snackbar.showSuccess('UPLOAD_SUCCESSFUL');
        } catch (error) {
            snackbar.showError(error.data.key ? error.data.key : 'ADD_CUSTOM_INDICATOR_ERROR');
        } finally {
            loadingIndicator.hide();
        }
    }

    async function save(): Promise<void> {
        try {
            loadingIndicator.show();

            for (const indicator of uploadedIndicators) {
                const createIndicatorParams: CreateIndicatorParams = {
                    source: { name: '', code: 'CUSTOM' } as IndicatorSource,
                    name: indicator.name,
                    organizationId: organization.currentOrganizationId!,
                    organizations: indicator.organizations,
                };

                const newIndicator = await context.indicator.create(createIndicatorParams);

                if (props.indicatorPackageTheme?.organizationIndicators.some((ind) => ind.name === newIndicator.name)) {
                    await context.indicator.updateOrganizationIndicator(
                        updateOrganizationIndicatorParams(newIndicator, indicator),
                    );
                } else {
                    await context.indicator.addIndicator(newIndicatorParams(newIndicator));
                }
            }
            snackbar.showSuccess();
        } catch (error) {
            snackbar.showError(error.data?.code);
        } finally {
            props.close();
            loadingIndicator.hide();
        }
    }

    async function downloadCustomIndicatorTemplate(): Promise<void> {
        loadingIndicator.show();
        try {
            await document.downloadExcelTemplate();
        } catch (error) {
            snackbar.showError(error.data?.code);
        } finally {
            props.close();
            loadingIndicator.hide();
        }
    }

    function newIndicatorParams(newIndicator: Indicator): AddIndicatorParams {
        const { indicatorPackageTheme } = props;
        return {
            documentId: document.currentDocument.id,
            indicators: [
                {
                    indicatorId: newIndicator.id,
                    source: newIndicator.source,
                },
            ],
            theme: indicatorPackageTheme!,
            themeId: indicatorPackageTheme!.id,
        };
    }

    function updateOrganizationIndicatorParams(
        newIndicator: Indicator,
        indicator: AddCustomIndicator,
    ): UpdateOrganizationIndicatorParams {
        const { currentDocument } = document;
        const { currentOrganizationId } = organization;
        const { comparisonSettings } = context.indicator;

        return {
            documentId: currentDocument.id,
            source: { name: '', code: 'CUSTOM' } as IndicatorSource,
            name: newIndicator.name,
            indicatorId: newIndicator.id!,
            years: indicator.years,
            chapterIndicators: props.indicatorPackageTheme!.organizationIndicators,
            organizationId: currentOrganizationId!,
            organizations: indicator.organizations.filter((indOrg) =>
                comparisonSettings?.organizations.map((compareOrg) => compareOrg.name).includes(indOrg.name),
            ),
        };
    }

    const handleIndicatorNameChange = (
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        indicator: AddCustomIndicator,
    ) => {
        const updatedIndicators = uploadedIndicators.map((uploadedIndicator) => {
            if (uploadedIndicator.name === indicator.name) {
                return { ...uploadedIndicator, name: e.target.value };
            }
            return uploadedIndicator;
        });
        setUploadedIndicators(updatedIndicators);
    };

    const handleDataChange = (
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        indicator: AddCustomIndicator,
        organization: OrganizationTableValue,
        year: number,
    ) => {
        const updatedData = organization.data.map((data) => {
            if (data.year === year) {
                return { ...data, value: parseInt(e.target.value) };
            }
            return data;
        });

        const updatedIndicators = uploadedIndicators.map((uploadedIndicator) => {
            if (uploadedIndicator.name === indicator.name) {
                return {
                    ...uploadedIndicator,
                    organizations: uploadedIndicator.organizations.map((organizationValue) => {
                        if (organizationValue.name === organization.name) {
                            return { ...organizationValue, data: updatedData };
                        }
                        return organizationValue;
                    }),
                };
            }
            return uploadedIndicator;
        });
        setUploadedIndicators(updatedIndicators);
    };

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>): void => {
        if (!event?.target?.files) return;
        const file = event.target.files[0];
        if (!file) return;
        void upload(file);
    };

    const handleFileDrop = (event: DragEvent): void => {
        event.preventDefault();
        if (!event.dataTransfer?.files) return;
        const file = event.dataTransfer?.files[0];
        if (!file) return;
        void upload(file);
    };

    const handleTabChange = (_event: React.ChangeEvent<unknown>, newIndex: string): void => {
        setState((state) => ({ ...state, activeTab: newIndex }));
    };

    const isIndicatorOrganizationInCompareSettings = (indicatorOrganizationName: string): boolean => {
        return (
            comparisonSettings?.organizations.some((org) => {
                return (
                    org.name === indicatorOrganizationName ||
                    org.shortName === indicatorOrganizationName
                );
            }) || false
        );
    }

    const { translate } = localization;
    const { comparisonSettings } = context.indicator;

    return (
        <StyledDialog
            open={props.isOpen}
            onClose={props.close}
            aria-labelledby="upload-custom-indicator-dialog"
            maxWidth="md"
            fullWidth
        >
            <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                <DialogTitle id="upload-custom-indicator-title">
                    {translate('ORGANIZATION_INDICATOR_UPLOAD') + ' - ' + props.indicatorPackageTheme?.name}
                </DialogTitle>
                <Button
                    component="span"
                    startIcon={<GetApp />}
                    style={{
                        alignSelf: 'flex-start',
                        marginTop: theming.spacing(2),
                        marginRight: theming.spacing(3),
                        color: '#FFF',
                        backgroundColor: theming.palette.secondary.main,
                    }}
                    variant="contained"
                    onClick={(): Promise<void> => downloadCustomIndicatorTemplate()}
                >
                    {translate('DOWNLOAD_EXCEL_TEMPLATE')}
                </Button>
            </Box>
            <DialogContent>
                <Tabs
                    value={state.activeTab}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={handleTabChange}
                    variant="fullWidth"
                >
                    <Tab label={translate('ORGANIZATION_INDICATOR_UPLOAD')} value="upload" />
                    {uploadedIndicators.map((indicator, i) => (
                        <Tab key={i} label={indicator.name} value={i.toString()} />
                    ))}
                </Tabs>
                <TabContext value={state.activeTab}>
                    <TabPanel value={'upload'}>
                        <input
                            accept=".xlsx"
                            style={{ display: 'none' }}
                            id="upload-custom-indicator-file-input"
                            type="file"
                            onChange={handleFileChange}
                        />
                        <Paper
                            onDragOver={(event) => event.preventDefault()}
                            onDrop={handleFileDrop}
                            style={{
                                backgroundColor: theming.shvkPetrol10,
                                paddingLeft: theming.spacing(4),
                                paddingRight: theming.spacing(4),
                                paddingTop: theming.spacing(8),
                                paddingBottom: theming.spacing(8),
                            }}
                        >
                            <Typography align="center">{translate('ADD_CUSTOM_INDICATOR_TIP')}</Typography>
                        </Paper>
                        <Typography align="center" style={{ lineHeight: theming.spacing(5) }}>
                            {translate('OPTIONAL_CUSTOM_INDICATOR_ADDING_TIP')}
                            <br />
                            <label htmlFor="upload-custom-indicator-file-input">
                                <Button
                                    component="span"
                                    startIcon={<CloudUpload />}
                                    style={{
                                        color: '#FFF',
                                        backgroundColor: theming.palette.secondary.main,
                                    }}
                                    variant="contained"
                                >
                                    {translate('SELECT_FILE')}
                                </Button>
                            </label>
                        </Typography>
                    </TabPanel>
                    {uploadedIndicators.map((indicator, i) => (
                        <TabPanel key={i} value={i.toString()}>
                            <ShvkTextField
                                variant="outlined"
                                size="small"
                                fullWidth
                                value={indicator.name}
                                sx={{ mb: 2 }}
                                onChange={(e) => handleIndicatorNameChange(e, indicator)}
                            />
                            {indicator.organizations.some(
                                (organization) => !isIndicatorOrganizationInCompareSettings(organization.name),
                            ) && (
                                <Typography variant="body2" className={classes.warningText}>
                                    {translate('ORGANIZATIONS_NOT_IN_COMPARISON_SETTINGS')}
                                </Typography>
                            )}
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.tableCell}>{translate('ORGANIZATION')}</TableCell>
                                        {indicator.years.map((year) => (
                                            <TableCell className={classes.tableCell} key={year}>
                                                {year}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {indicator.organizations.map((organization) => (
                                        <TableRow
                                            key={organization.name + 'row'}
                                            className={`${
                                                !isIndicatorOrganizationInCompareSettings(organization.name)
                                                    ? classes.warningCell
                                                    : null
                                            }`}
                                        >
                                            <TableCell className={classes.tableCell} key={organization.name}>
                                                {organization.name}
                                            </TableCell>
                                            {organization.data.map((data) => (
                                                <TableCell
                                                    className={classes.tableCell}
                                                    key={organization.name + data.year}
                                                >
                                                    <ShvkTextField
                                                        type="number"
                                                        size="small"
                                                        fullWidth
                                                        defaultValue={data.value}
                                                        onChange={(e) =>
                                                            handleDataChange(e, indicator, organization, data.year)
                                                        }
                                                    />
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TabPanel>
                    ))}
                </TabContext>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={props.close}>
                    {translate('CLOSE')}
                </Button>
                <Box flexGrow={1} />
                <ShvkButton startIcon={<Save />} onClick={save} disabled={!uploadedIndicators}>
                    {translate('SAVE')}
                </ShvkButton>
            </DialogActions>
        </StyledDialog>
    );
}

export default observer(UploadCustomIndicatorDialog);
