import React, { useState, useEffect } from 'react';
import { GoogleMap, LoadScript, Marker, MarkerClusterer } from '@react-google-maps/api';
import '../../styled/map.css';
import { observer, useLocalObservable } from 'mobx-react-lite';
import { runInAction } from 'mobx';
import { Autocomplete, Box, Divider, Grid, Menu, MenuItem, Paper, Tooltip, Typography } from '@mui/material';
import { ArrowDropDown, Info } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { PublishedDocument, ShvkDocumentType } from '../../types/document';
import { ButtonEvent, TextFieldEvent } from '../../types/events';
import { OrganizationLocation } from '../../types/organization';
import { TranslationKeys } from '../../types/translation';
import ShvkTextField from '../../styled/ShvkTextField';
import ShvkButton from '../../styled/ShvkButton';
import moment from 'moment';
import useStore from '../../store/storeContext';

const PREFIX = 'PublishedDocuments';

const classes = {
    label: `${PREFIX}-table`,
};

const StyledPaper = styled(Paper)(({ theme }) => ({
    [`& .${classes.label}`]: {
        borderRadius: theme.spacing(0.5),
        paddingTop: theme.spacing(0.75),
        paddingBottom: theme.spacing(0.75),
        paddingRight: theme.spacing(1.5),
        paddingLeft: theme.spacing(1.5),
        background: theme.palette.info.main,
        textTransform: 'uppercase',
    },
}));

type PublishedDocumentsFormField = 'selectedDocType' | 'selectedLargeReportPeriod' | 'selectedYearlyReportYear';

type Center = {
    lat: number;
    lng: number;
};

const containerStyle = {
    width: '100%',
    height: '70vh',
};

function PublishedDocuments() {
    const context = useStore();

    const [organizationMenu, setOrganizationMenu] = useState<HTMLElement | null>(null);

    const [center, setCenter] = useState<Center>({ lat: 65.0103, lng: 25.473505 });

    const [zoom, setZoom] = useState<number>(5);

    const localStore = useLocalObservable(() => ({
        selectedDocType: '',
        selectedLargeReportPeriod: '',
        selectedYearlyReportYear: '',
        selectedOrganization: '',
        evaStart: '',
        evaEnd: '',

        get filteredAllPublishedDocuments(): PublishedDocument[] {
            let documents = context.document.allPublishedDocuments;
            if (this.selectedDocType) {
                if (this.selectedDocType === 'YEARLY_REPORT') {
                    documents = documents.filter(
                        (document: PublishedDocument) =>
                            document.type === this.selectedDocType || document.type === 'REGIONAL_YEARLY_REPORT',
                    );
                } else if (this.selectedDocType === 'LARGE_REPORT') {
                    documents = documents.filter(
                        (document: PublishedDocument) =>
                            document.type === this.selectedDocType || document.type === 'REGIONAL_LARGE_REPORT',
                    );
                } else {
                    documents = documents.filter(
                        (document: PublishedDocument) => document.type === this.selectedDocType,
                    );
                }
            }
            if (this.selectedYearlyReportYear) {
                documents = documents.filter(
                    (document: PublishedDocument) =>
                        document.yearBegin?.toString() === this.selectedYearlyReportYear.toString(),
                );
            }
            if (this.selectedLargeReportPeriod) {
                documents = documents.filter(
                    (document: PublishedDocument) =>
                        document.yearBegin?.toString() + ' - ' + document.yearEnd?.toString() ===
                        this.selectedLargeReportPeriod,
                );
            }
            if (context.document.isEvaDocument(this.selectedDocType)) {
                documents = documents.filter(
                    (document: PublishedDocument) =>
                        document.acceptedDate.toString().slice(0, 10) >= this.evaStart &&
                        document.acceptedDate.toString().slice(0, 10) <= this.evaEnd,
                );
            }
            return documents;
        },

        get organizationLocationsWithPublishedDocuments(): OrganizationLocation[] {
            return context.organization.organizationLocations.filter((organization: OrganizationLocation) =>
                this.filteredAllPublishedDocuments.some(
                    (document: PublishedDocument) =>
                        document.organization.name[context.localization.locale] === organization.name,
                ),
            );
        },

        get filteredOrganizationPublishedDocuments(): PublishedDocument[] {
            return this.filteredAllPublishedDocuments
                .filter(
                    (document: PublishedDocument) =>
                        document.organization.name[context.localization.locale] === this.selectedOrganization,
                )
                .sort((doc1, doc2) => (doc1.acceptedDate < doc2.acceptedDate ? 1 : -1));
        },

        changeDate(event: TextFieldEvent): void {
            if (event.target.id === 'eva-filter-start-date') {
                this.evaStart = event.target.value;
            } else {
                this.evaEnd = event.target.value;
            }
        },

        handleChange(event: TextFieldEvent, field: PublishedDocumentsFormField): void {
            this[field] = event.target.value;
            if (field === 'selectedDocType') {
                this.selectedLargeReportPeriod = '';
                this.selectedYearlyReportYear = '';
            }
        },

        handleSelectOrganization(organizationName: string): void {
            this.selectedOrganization = organizationName;
            handleGetOrganizationClose();
            context.dialog.openPublishedOrganizationDocumentsDialog(
                organizationName,
                this.filteredOrganizationPublishedDocuments,
            );
        },
    }));

    useEffect(() => {
        setCenter({ lat: 65.0103, lng: 25.473505 });
        setZoom(5);
        runInAction(() => {
            localStore.evaStart = moment([2020, 0, 1]).format('YYYY[-]MM[-]DD');
            localStore.evaEnd = moment().format('YYYY[-]MM[-]DD');
        });
    }, []);

    function changeCenter(lat: number, lng: number): void {
        setCenter({ lat, lng });
    }

    function changeZoom(zoom: number): void {
        setZoom(zoom);
    }

    function findSelectedOrganization(organization: OrganizationLocation | null): void {
        if (!organization) {
            changeCenter(65.0103, 25.473505);
            changeZoom(5);
        } else {
            changeCenter(organization.latitude, organization.longitude);
            changeZoom(9);
        }
    }

    const handleGetOrganizationClick = (event: ButtonEvent): void => {
        setOrganizationMenu(event.currentTarget);
    };

    const handleGetOrganizationClose = (): void => {
        setOrganizationMenu(null);
    };

    const documentTypes = context.document.documentTypes.filter(
        (type) => type.code !== 'REGIONAL_YEARLY_REPORT' && type.code !== 'REGIONAL_LARGE_REPORT',
    );

    const { translate } = context.localization;

    return (
        <StyledPaper>
            <Box px={2} py={1} display={'flex'} alignItems={'center'}>
                <Typography variant="h6" component="h4" style={{ display: 'inline-block' }}>
                    {translate('PUBLISHED_DOCUMENTS')}
                </Typography>
                <Tooltip title={translate('etusivu_dokumenttien_haku_2')}>
                    <Info color="primary" sx={{ ml: 1 }} />
                </Tooltip>
            </Box>
            <Divider />
            <Box p={2}>
                <ShvkButton sx={{ mb: 6 }} onClick={handleGetOrganizationClick} endIcon={<ArrowDropDown />} fullWidth>
                    {translate('ORGANIZATION_BASED_SEARCH')}
                </ShvkButton>
                <Menu
                    id="get-documents-by-organization"
                    anchorEl={organizationMenu}
                    keepMounted
                    open={Boolean(organizationMenu)}
                    onClose={handleGetOrganizationClose}
                >
                    {localStore.organizationLocationsWithPublishedDocuments.map(
                        (organization: OrganizationLocation) => (
                            <MenuItem
                                key={organization.name}
                                onClick={() => localStore.handleSelectOrganization(organization.name)}
                            >
                                {organization.name}
                            </MenuItem>
                        ),
                    )}
                </Menu>
                <Autocomplete
                    id="locate-organization"
                    options={localStore.organizationLocationsWithPublishedDocuments}
                    getOptionLabel={(option: OrganizationLocation): string => option.name}
                    sx={{ mb: 2 }}
                    fullWidth
                    size="small"
                    openOnFocus
                    selectOnFocus
                    onChange={(_event, newValue) => findSelectedOrganization(newValue)}
                    renderInput={(params): JSX.Element => (
                        <ShvkTextField {...params} label={translate('LOCATE_COUNCIL_ON_MAP_TIP')} variant="outlined" />
                    )}
                />
                <ShvkTextField
                    select
                    id="filter-docs-by-type"
                    onChange={(event): void => localStore.handleChange(event, 'selectedDocType')}
                    label={translate('FILTER_DOCUMENTS')}
                    value={localStore.selectedDocType}
                    variant="outlined"
                    fullWidth
                    sx={{ mb: 2 }}
                    size="small"
                >
                    <MenuItem value={''}>{translate('ALL')}</MenuItem>
                    {documentTypes.map((docType: ShvkDocumentType) => (
                        <MenuItem key={docType.id} value={docType.code}>
                            {translate(docType.code as keyof TranslationKeys)}
                        </MenuItem>
                    ))}
                </ShvkTextField>
                {localStore.selectedDocType !== 'LARGE_REPORT' ? null : (
                    <ShvkTextField
                        select
                        id="large-report-period"
                        onChange={(event): void => localStore.handleChange(event, 'selectedLargeReportPeriod')}
                        label={translate('SELECT_DOCUMENT_PERIOD')}
                        value={localStore.selectedLargeReportPeriod}
                        variant="outlined"
                        fullWidth
                        sx={{ mb: 2 }}
                        size="small"
                    >
                        {context.document.largeReportTermsFromPublishedDocuments.map((term: string) => (
                            <MenuItem key={term} value={term}>
                                {term}
                            </MenuItem>
                        ))}
                    </ShvkTextField>
                )}
                {localStore.selectedDocType !== 'YEARLY_REPORT' ? null : (
                    <ShvkTextField
                        select
                        id="yearly-report-select-year"
                        onChange={(event): void => localStore.handleChange(event, 'selectedYearlyReportYear')}
                        label={translate('SELECT_PERIOD')}
                        value={localStore.selectedYearlyReportYear.toString()}
                        variant="outlined"
                        fullWidth
                        sx={{ mb: 2 }}
                        size="small"
                    >
                        {context.document.yearlyReportYearsFromPublishedDocuments.map((year: number) => (
                            <MenuItem key={year} value={year}>
                                {year}
                            </MenuItem>
                        ))}
                    </ShvkTextField>
                )}
                {!context.document.isEvaDocument(localStore.selectedDocType) ? null : (
                    <Grid container spacing={2} sx={{ mb: 2 }}>
                        <Grid item xs={12}>
                            <Typography>{translate('ACCEPTED_DATE_RANGE_TIP')}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <ShvkTextField
                                fullWidth
                                id="eva-filter-start-date"
                                label={translate('START_DATE')}
                                type="date"
                                variant="outlined"
                                size="small"
                                onChange={(event: TextFieldEvent) => localStore.changeDate(event)}
                                value={localStore.evaStart}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <ShvkTextField
                                fullWidth
                                id="eva-filter-end-date"
                                label={translate('END_DATE')}
                                type="date"
                                variant="outlined"
                                size="small"
                                onChange={(event: TextFieldEvent) => localStore.changeDate(event)}
                                value={localStore.evaEnd}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                    </Grid>
                )}
                <LoadScript googleMapsApiKey="AIzaSyCb8JWHi1F3KKOrez0Fk3AF1krVpE57jA0">
                    <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={zoom}>
                        <MarkerClusterer>
                            {(clusterer): any =>
                                localStore.organizationLocationsWithPublishedDocuments.map((organization) => (
                                    <Marker
                                        key={organization.name}
                                        position={{ lat: organization.latitude, lng: organization.longitude }}
                                        clusterer={clusterer}
                                        title={organization.name}
                                        onClick={() => {
                                            localStore.handleSelectOrganization(organization.name);
                                        }}
                                        label={{
                                            text: organization.name,
                                            color: '#FFF',
                                            className: `${classes.label}`,
                                        }}
                                    />
                                ))
                            }
                        </MarkerClusterer>
                    </GoogleMap>
                </LoadScript>
            </Box>
        </StyledPaper>
    );
}

export default observer(PublishedDocuments);
