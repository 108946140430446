import React from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { Indicator } from '../../../../types/indicator';
import FroalaIndicatorDescription from '../../editor/FroalaIndicatorDescription';
import useStore from '../../../../store/storeContext';

interface Props {
    indicator?: Indicator | null;
    isOpen: boolean;
    close(): void;
}

function IndicatorDescriptionDialog(props: Props) {
    const { localization } = useStore();

    const { translate } = localization;

    return (
        <Dialog
            open={props.isOpen}
            onClose={props.close}
            aria-labelledby="indicator-description-dialog-title"
            maxWidth={'lg'}
            fullWidth
        >
            <DialogTitle id="indicator-description-dialog-title">
                {translate('DESCRIPTION')}: {props.indicator?.name}
            </DialogTitle>
            <DialogContent dividers>
                {props.indicator && props.isOpen && <FroalaIndicatorDescription indicator={props.indicator} />}
            </DialogContent>
            <DialogActions>
                <Button onClick={props.close} variant="contained">
                    {translate('CLOSE')}
                </Button>
                <Box flexGrow={1} />
            </DialogActions>
        </Dialog>
    );
}

export default observer(IndicatorDescriptionDialog);
