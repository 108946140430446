import React from 'react';
import { observer } from 'mobx-react-lite';
import { Grid, TableCell, TableRow, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Indicator } from '../../../types/indicator';
import { Chapter } from '../../../types/document';
import IndicatorCircle from './IndicatorCircle';
import IndicatorDirection from './IndicatorDirection';
import IndicatorComparison from './IndicatorComparison';
import useStore from '../../../store/storeContext';

const PREFIX = 'IndicatorTopRow';

const classes = {
    tableCell: `${PREFIX}-tableCell`,
    indicatorNameDiv: `${PREFIX}-indicatorNameDiv`,
    indicatorTypeDiv: `${PREFIX}-indicatorTypeDiv`,
};

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    [`& .${classes.tableCell}`]: {
        borderBottom: '0',
    },

    [`& .${classes.indicatorNameDiv}`]: {
        cursor: 'pointer',
    },

    [`& .${classes.indicatorTypeDiv}`]: {
        paddingRight: theme.spacing(0.5),
    },
}));

interface Props {
    indicator: Indicator;
    chapter: Chapter;
}

function IndicatorTopRow(props: Props) {
    const { dialog, localization } = useStore();

    const handleIndicatorNameClick = (): void => {
        dialog.openDocumentDialog('indicatorGraphDialog', {
            indicator: props.indicator,
            isTopRowIndicator: true,
        });
    };

    const { indicator } = props;
    const { translate } = localization;

    return (
        <StyledTableRow style={{ verticalAlign: ' top' }}>
            <TableCell className={classes.tableCell}>
                <Grid container spacing={1}>
                    <Grid item>
                        <Tooltip placement={'top-start'} title={translate('SHOW_INDICATOR_GRAPH_TOOLTIP')}>
                            <div onClick={handleIndicatorNameClick} className={`${classes.indicatorNameDiv}`}>
                                {indicator?.name}
                            </div>
                        </Tooltip>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell className={classes.tableCell}>
                {indicator && <IndicatorCircle indicator={indicator} />}
            </TableCell>
            <TableCell className={classes.tableCell}>
                {indicator && (
                    <IndicatorDirection origoOrganization={indicator.origoOrganization} indicator={indicator} />
                )}
            </TableCell>
            <TableCell align="center" className={classes.tableCell}>
                {indicator && (
                    <IndicatorComparison origoOrganization={indicator.origoOrganization} indicator={indicator} />
                )}
            </TableCell>
        </StyledTableRow>
    );
}

export default observer(IndicatorTopRow);
