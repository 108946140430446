import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Button, Container, Divider, Grid, List, Paper, Typography, Pagination, ButtonGroup } from '@mui/material';
import NewsArticle from '../types/news';
import NewsRow from '../components/news/NewsRow';
import GeneralDialog from '../components/GeneralDialog';
import AddNewsDialog from '../components/news/AddNewsDialog';
import DeleteIcon from '@mui/icons-material/Delete';
import ShvkButton from '../styled/ShvkButton';
import moment from 'moment';
import useStore from '../store/storeContext';

interface State {
    articleDialogOpen: boolean;
    addNewsDialogOpen: boolean;
    deleteMode: boolean;
    selectedArticle: NewsArticle | null;
    totalPages: number;
    totalArticles: number;
    page: number;
}

const articlesPerPage: Readonly<number> = 3;

function NewsManagement() {
    const { news, dialog, snackbar, localization } = useStore();

    const [state, setState] = useState<State>({
        articleDialogOpen: false,
        addNewsDialogOpen: false,
        deleteMode: false,
        selectedArticle: null,
        totalPages: 0,
        totalArticles: -1,
        page: 1,
    });

    const [pageArticles, setPageArticles] = useState<NewsArticle[]>([]);

    useEffect((): void => {
        if (state.totalArticles !== news.newsArticles.length) {
            void getData();
        } else {
            setPageContent();
        }
    }, [news.newsArticles.length, state.page]);

    async function getData(): Promise<void> {
        try {
            await news.fetchAllNews();
            setPageCount();
            setArticleCount();
            setPageContent();
        } catch {
            snackbar.showFetchFailedMessage();
        }
    }

    async function remove(newsArticle: NewsArticle): Promise<void> {
        try {
            const id = newsArticle.id;
            const confirmation = await dialog.getConfirmation();
            if (!confirmation) return;
            await news.removeNews(id);
            snackbar.showSuccess();
        } catch (error) {
            snackbar.showError(error.data?.code);
        }
    }

    const toggleArticleDialog = (newsArticle: NewsArticle | null): void => {
        setState((state) => ({ ...state, articleDialogOpen: !state.articleDialogOpen }));
        if (newsArticle) {
            setState((state) => ({ ...state, selectedArticle: newsArticle }));
        }
    };

    const toggleAddNewsDialog = (): void => {
        setState((state) => ({ ...state, addNewsDialogOpen: !state.addNewsDialogOpen }));
    };

    const toggleDeleteMode = (): void => {
        setState((state) => ({ ...state, deleteMode: !state.deleteMode }));
    };

    const handlePageChange = (_event: React.ChangeEvent<unknown>, value: number): void => {
        setState((state) => ({ ...state, page: value }));
    };

    const setPageContent = (): void => {
        const pageFirstIndex = (state.page - 1) * articlesPerPage;
        const articles = news.newsArticles.slice(pageFirstIndex, pageFirstIndex + articlesPerPage);
        setPageArticles(articles);
    };

    const setPageCount = (): void => {
        const pageCount = Math.ceil(news.newsArticles.length / articlesPerPage);
        setState((state) => ({ ...state, totalPages: pageCount }));
    };

    const setArticleCount = (): void => {
        const totalCount = news.newsArticles.length;
        setState((state) => ({ ...state, totalArticles: totalCount }));
    };

    const translate = localization.translate;

    return (
        <>
            <Container>
                <Box my={3}>
                    <Paper>
                        <Box px={2} py={1} textAlign="center">
                            <Typography variant="h6">{translate('NEWS_MANAGEMENT')}</Typography>
                        </Box>
                        <Divider />
                        <List>
                            {pageArticles.map((newsArticle: NewsArticle) => (
                                <React.Fragment key={newsArticle.id}>
                                    <Box display="flex">
                                        <NewsRow toggleArticleDialog={toggleArticleDialog} newsArticle={newsArticle} />
                                        {state.deleteMode && (
                                            <ButtonGroup color="error" sx={{ mr: 1 }} size="small">
                                                <Button
                                                    sx={{ alignSelf: 'center' }}
                                                    aria-label={translate('REMOVE')}
                                                    onClick={(): Promise<void> => remove(newsArticle)}
                                                >
                                                    <DeleteIcon />
                                                </Button>
                                            </ButtonGroup>
                                        )}
                                    </Box>
                                    <Divider />
                                </React.Fragment>
                            ))}
                        </List>
                        <Box p={2}>
                            <Grid container justifyContent="space-between">
                                <Grid item xs={6} sm={2}>
                                    <Box display="flex" justifyContent="flex-start">
                                        {!state.deleteMode ? (
                                            <Button variant="contained" onClick={toggleDeleteMode}>
                                                {translate('REMOVE_NEWS')}
                                            </Button>
                                        ) : (
                                            <Button variant="contained" onClick={toggleDeleteMode}>
                                                {translate('CANCEL')}
                                            </Button>
                                        )}
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={8}>
                                    <Box display="flex" justifyContent="center">
                                        <Pagination
                                            count={state.totalPages}
                                            page={state.page}
                                            onChange={handlePageChange}
                                            color="primary"
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={6} sm={2}>
                                    <Box display="flex" justifyContent="flex-end">
                                        <ShvkButton onClick={toggleAddNewsDialog}>{translate('ADD_NEWS')}</ShvkButton>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Paper>
                </Box>
            </Container>
            <GeneralDialog
                content={state.selectedArticle?.content}
                title={state.selectedArticle?.title}
                subtitle={moment(state.selectedArticle?.dateCreated).format('DD.MM.YYYY hh:mm')}
                open={state.articleDialogOpen}
                onClose={(): void => toggleArticleDialog(null)}
            />
            <AddNewsDialog open={state.addNewsDialogOpen} toggle={toggleAddNewsDialog} />
        </>
    );
}

export default observer(NewsManagement);
