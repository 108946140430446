import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Container, Hidden, Typography } from '@mui/material';
import { ArrowDropDown } from '@mui/icons-material';
import { ButtonEvent } from '../../types/events';
import SelectDocumentPopover from '../home/SelectDocumentPopover';
import DocumentDialogs from './dialogs/DocumentDialogs';
import SideNavigation from './SideNavigation';
import ShvkButton from '../../styled/ShvkButton';
import EditorView from './EditorView';
import useStore from '../../store/storeContext';

interface State {
    sideNavOpen: boolean;
    selectDocumentMenu: HTMLElement | null;
}

function Document() {
    const { indicator, session, localization, loadingIndicator, snackbar } = useStore();

    const [state, setState] = useState<State>({
        sideNavOpen: false,
        selectDocumentMenu: null,
    });

    useEffect(() => {
        void init();
    }, []);

    async function init() {
        try {
            await indicator.fetchMinimumDataContentIDsForArea();
            await indicator.fetchMinimumDataContentIDsForMunicipality();
            await indicator.fetchIndicatorTops();
            await session.fetchControlData();
        } catch (error) {
            snackbar.showError(error.data?.code);
        } finally {
            loadingIndicator.hide();
        }
    }
    const handleDocumentMenuClick = (event: ButtonEvent): void => {
        setState((state) => ({ ...state, selectDocumentMenu: event.currentTarget }));
    };

    const handleDocumentMenuClose = (): void => {
        setState((state) => ({ ...state, selectDocumentMenu: null }));
    };

    const toggleSideNav = (): void => {
        setState((state) => ({ ...state, sideNavOpen: !state.sideNavOpen }));
    };

    const { translate } = localization;

    return (
        <>
            <Container>
                <Box pt={1}>
                    <Typography align="center">
                        <ShvkButton
                            endIcon={<ArrowDropDown />}
                            aria-controls="select-document-popup"
                            aria-haspopup="true"
                            onClick={handleDocumentMenuClick}
                        >
                            {translate('SELECT_DOCUMENT')}
                        </ShvkButton>
                        <SelectDocumentPopover
                            handleClose={handleDocumentMenuClose}
                            anchorEl={state.selectDocumentMenu}
                        />
                    </Typography>
                </Box>
                <Box display="flex">
                    {state.sideNavOpen && (
                        <Hidden mdDown>
                            <Box>
                                <SideNavigation />
                            </Box>
                        </Hidden>
                    )}
                    <Box flexGrow={1} width="100%">
                        <EditorView toggleSideNav={toggleSideNav} />
                    </Box>
                </Box>
            </Container>
            <DocumentDialogs />
        </>
    );
}

export default observer(Document);
