import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import {
    Box,
    Button,
    ButtonGroup,
    Collapse,
    Divider,
    List,
    ListItem,
    ListItemButton,
    ListItemSecondaryAction,
    ListItemText,
    Typography,
} from '@mui/material';
import { ArrowDropDown, ArrowLeft } from '@mui/icons-material';
import { Indicator, IndicatorPackage, IndicatorPackageTheme } from '../../../../types/indicator';
import PowerPointDialogIndicatorsByType from './PowerPointDialogIndicatorsByType';
import useStore from '../../../../store/storeContext';

interface Props {
    indicatorPackage: IndicatorPackage;
    selectedIndicators: Indicator[];
    selectMany(indicators: Indicator[]): void;
}

interface State {
    expanded: boolean;
}

function PowerPointDialogIndicatorPackage(props: Props) {
    const { indicator, localization, theming } = useStore();

    const [state, setState] = useState<State>({
        expanded: false,
    });

    function togglePackage(): void {
        setState((state) => ({ ...state, expanded: !state.expanded }));
    }

    function subThemes(theme: IndicatorPackageTheme): IndicatorPackageTheme[] {
        const themes = props.indicatorPackage.themes.slice().sort((a, b) => a.orderNumber - b.orderNumber);
        const subThemes: IndicatorPackageTheme[] = [];
        let doAdd = false;
        themes.forEach((t) => {
            if (t === theme) doAdd = true;
            else if (t.level === 0) doAdd = false;
            else if (t.level === 1 && doAdd) subThemes.push(t);
        });
        return subThemes;
    }

    const { indicatorPackage, selectedIndicators, selectMany } = props;
    const { indicatorsByTypeFromTheme } = indicator;
    const { translate } = localization;

    return (
        <List dense>
            <ListItem dense>
                <ListItemButton
                    style={{ paddingTop: 0, paddingBottom: 0, marginRight: theming.spacing(2) }}
                    onClick={() =>
                        selectMany(
                            indicatorPackage.themes.flatMap((theme) => [
                                ...theme.sotkanetIndicators,
                                ...theme.organizationIndicators,
                                ...theme.otherIndicators,
                            ]),
                        )
                    }
                >
                    <ListItemText>
                        <Typography variant="h6">{indicatorPackage.name}</Typography>
                    </ListItemText>
                </ListItemButton>
                <ListItemSecondaryAction>
                    <ButtonGroup color="primary" size="small">
                        <Button onClick={togglePackage}>{state.expanded ? <ArrowDropDown /> : <ArrowLeft />}</Button>
                    </ButtonGroup>
                </ListItemSecondaryAction>
            </ListItem>
            {state.expanded && (
                <Collapse in={state.expanded}>
                    {indicatorPackage.themes.map((theme, index) => {
                        const baseIndicators = indicatorsByTypeFromTheme(theme, 'P');
                        const addedIndicators = indicatorsByTypeFromTheme(theme, 'T');
                        const customIndicators = indicatorsByTypeFromTheme(theme, 'L');
                        const otherIndicators = indicatorsByTypeFromTheme(theme, 'M');

                        return (
                            <Box key={theme.id} my={0.5}>
                                {theme.level === 0 && index !== 0 && (
                                    <Box mb={1}>
                                        <Divider />
                                    </Box>
                                )}
                                {theme.level === 0 &&
                                    subThemes(theme).flatMap((theme) => [
                                        ...theme.sotkanetIndicators,
                                        ...theme.organizationIndicators,
                                        ...theme.otherIndicators,
                                    ]).length > 0 && (
                                        <Box ml={1}>
                                            <ListItemButton
                                                style={{ paddingTop: 0.5, paddingBottom: 0.5 }}
                                                onClick={() =>
                                                    selectMany(
                                                        subThemes(theme).flatMap((theme) => [
                                                            ...theme.sotkanetIndicators,
                                                            ...theme.organizationIndicators,
                                                            ...theme.otherIndicators,
                                                        ]),
                                                    )
                                                }
                                            >
                                                <ListItemText>
                                                    <Typography variant="subtitle2">{theme.name}</Typography>
                                                </ListItemText>
                                            </ListItemButton>
                                        </Box>
                                    )}
                                {theme.level === 1 &&
                                    (theme.sotkanetIndicators.length > 0 ||
                                        theme.organizationIndicators.length > 0 ||
                                        theme.otherIndicators.length > 0) && (
                                        <Box ml={2} my={0.5}>
                                            <ListItemButton
                                                style={{ paddingTop: 0.5, paddingBottom: 0.5 }}
                                                onClick={() =>
                                                    selectMany([
                                                        ...theme.sotkanetIndicators,
                                                        ...theme.organizationIndicators,
                                                        ...theme.otherIndicators,
                                                    ])
                                                }
                                            >
                                                <ListItemText>
                                                    <Typography variant="subtitle2">{theme.name}</Typography>
                                                </ListItemText>
                                            </ListItemButton>
                                        </Box>
                                    )}
                                {baseIndicators.length > 0 && (
                                    <PowerPointDialogIndicatorsByType
                                        title={translate('BASE_INDICATORS')}
                                        indicatorList={baseIndicators}
                                        selectedIndicators={selectedIndicators}
                                        selectMany={selectMany}
                                    />
                                )}
                                {addedIndicators.length > 0 && (
                                    <PowerPointDialogIndicatorsByType
                                        title={translate('FILL_INDICATORS')}
                                        indicatorList={addedIndicators}
                                        selectedIndicators={selectedIndicators}
                                        selectMany={selectMany}
                                    />
                                )}
                                {customIndicators.length > 0 && (
                                    <PowerPointDialogIndicatorsByType
                                        title={translate('CUSTOM_INDICATOR')}
                                        indicatorList={customIndicators}
                                        selectedIndicators={selectedIndicators}
                                        selectMany={selectMany}
                                    />
                                )}
                                {otherIndicators.length > 0 && (
                                    <PowerPointDialogIndicatorsByType
                                        title={translate('OTHER_INDICATOR')}
                                        indicatorList={otherIndicators}
                                        selectedIndicators={selectedIndicators}
                                        selectMany={selectMany}
                                    />
                                )}
                            </Box>
                        );
                    })}
                </Collapse>
            )}
        </List>
    );
}

export default observer(PowerPointDialogIndicatorPackage);
