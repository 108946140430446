import Axios from '../../axiosConfig';
import { BACKEND_URL } from '../../config';
import { configure, runInAction, makeAutoObservable } from 'mobx';
import Feedback, { FeedbackType, SeeFeedback } from '../../types/feedback';

configure({ enforceActions: 'observed' });

export default class FeedbackModule {
    private types: FeedbackType[] = [];
    private allFeedbacks: SeeFeedback[] = [];

    constructor() {
        makeAutoObservable(this, {}, { autoBind: true });
    }

    public get feedbacks(): SeeFeedback[] {
        return this.allFeedbacks;
    }

    public async fetchFeedbackTypes(): Promise<void> {
        const response = await Axios({
            method: 'GET',
            url: BACKEND_URL + 'feedbackType',
        });
        runInAction(() => (this.types = response.data));
    }

    public async sendFeedback(feedback: Feedback): Promise<void> {
        await Axios({
            method: 'POST',
            url: BACKEND_URL + 'feedback/save',
            data: feedback,
        });
    }

    public async fetchFeedbacks(): Promise<void> {
        const response = await Axios({
            method: 'GET',
            url: BACKEND_URL + 'feedback',
        });
        if (response.status === 200) runInAction(() => (this.allFeedbacks = response.data));
    }

    public get feedbackTypes(): FeedbackType[] {
        return this.types;
    }

    public getFeedbackTypeById(id: number): FeedbackType | null {
        return this.feedbackTypes.find((type) => type.id === id) || null;
    }
}
