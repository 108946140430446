import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import {
    Box,
    Button,
    MobileStepper,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
} from '@mui/material';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { Chapter } from '../../../types/document';
import { Indicator } from '../../../types/indicator';
import IndicatorTopRow from './IndicatorTopRow';
import InfoIcon from '@mui/icons-material/Info';
import useStore from '../../../store/storeContext';

interface Props {
    chapter: Chapter;
    indicators: Indicator[];
}

interface State {
    activeStep: number;
    currentIndicator: Indicator;
}

function IndicatorTop(props: Props) {
    const { localization, theming } = useStore();

    const [state, setState] = useState<State>({
        activeStep: 0,
        currentIndicator: props.indicators[0],
    });

    function changeStep(change: number): void {
        const newActiveStep = (state.activeStep + props.indicators.length + change) % props.indicators.length;
        setState((state) => ({
            ...state,
            activeStep: newActiveStep,
            currentIndicator: props.indicators[newActiveStep],
        }));
    }

    const { translate } = localization;

    return (
        <Box>
            {state.currentIndicator?.indicatorTopTitle && (
                <Box mb={2}>
                    <Typography variant="h6">{state.currentIndicator.indicatorTopTitle}</Typography>
                </Box>
            )}
            <Box>
                <Paper style={{ overflowX: 'auto' }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>{translate('INDICATOR')}</TableCell>
                                <TableCell>
                                    <Box display="flex" alignItems="center" justifyContent="center">
                                        <Box flexShrink={0}>{translate('CHANGE')} (%)</Box>
                                        <Tooltip
                                            style={{ marginLeft: theming.spacing(0.5) }}
                                            title={translate('muutos_sarakeotsikko_ind_lista_5')}
                                        >
                                            <InfoIcon color="primary" fontSize={'small'} />
                                        </Tooltip>
                                    </Box>
                                </TableCell>
                                <TableCell>
                                    <Box display="flex" alignItems="center" justifyContent="center">
                                        {translate('DIRECTION')}
                                        <Tooltip
                                            style={{ marginLeft: theming.spacing(0.5) }}
                                            title={translate('suunta_sarakeotsikko_ind_lista_6')}
                                        >
                                            <InfoIcon color="primary" fontSize={'small'} />
                                        </Tooltip>
                                    </Box>
                                </TableCell>
                                <TableCell>
                                    <Box display="flex" alignItems="center" justifyContent="center">
                                        {translate('COMPARISON')}
                                        <Tooltip
                                            style={{ marginLeft: theming.spacing(0.5) }}
                                            title={translate('vertailu_sarakeotsikko_ind_lista_7')}
                                        >
                                            <InfoIcon color="primary" fontSize={'small'} />
                                        </Tooltip>
                                    </Box>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <IndicatorTopRow chapter={props.chapter} indicator={state.currentIndicator} />
                            <TableRow>
                                <TableCell style={{ padding: 0 }} colSpan={4}>
                                    <MobileStepper
                                        variant="dots"
                                        activeStep={state.activeStep}
                                        steps={props.indicators.length}
                                        style={{ borderBottomLeftRadius: '4px', borderBottomRightRadius: '4px' }}
                                        position="static"
                                        backButton={
                                            <Button size="small" onClick={() => changeStep(-1)}>
                                                <KeyboardArrowLeft />
                                                {translate('PREVIOUS')}
                                            </Button>
                                        }
                                        nextButton={
                                            <Button size="small" onClick={() => changeStep(1)}>
                                                {translate('NEXT')}
                                                <KeyboardArrowRight />
                                            </Button>
                                        }
                                    />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Paper>
            </Box>
        </Box>
    );
}

export default observer(IndicatorTop);
