import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, Typography } from '@mui/material';
import { AddPhotoAlternateOutlined, ExpandMore } from '@mui/icons-material';
import { TranslationKeys } from '../../types/translation';
import CarouselImage from '../../types/carouselImage';
import CarouselImageCard from './CarouselImageCard';
import ShvkButton from '../../styled/ShvkButton';
import useStore from '../../store/storeContext';

interface Props {
    title: keyof TranslationKeys;
    id: string;
}

function CarouselImageAccordion(props: Props) {
    const { dialog, image, localization, snackbar } = useStore();

    const [newCarouselImage, setNewCarouselImage] = useState<CarouselImage | null>(null);

    function addCarouselImage(): void {
        const storeImages = image.allCarouselImages;
        const orderNumber = storeImages.length === 0 ? 1 : storeImages[storeImages.length - 1].orderNumber + 1;

        setNewCarouselImage((state) => ({
            ...state,
            active: false,
            carouselImage: '',
            contentType: '',
            id: -1,
            orderNumber,
            redirectUrl: '',
        }));
    }

    function getCarouselImages(): CarouselImage[] {
        if (newCarouselImage) return [...image.allCarouselImages, newCarouselImage];
        else return image.allCarouselImages;
    }

    async function remove(orderNumber: number): Promise<void> {
        try {
            const confirmation = await dialog.getConfirmation();
            if (!confirmation) return;
            await image.deleteCarouselImage(orderNumber);
            snackbar.showSuccess();
        } catch (error) {
            snackbar.showError(error.data?.code);
        }
    }

    async function save(orderNumber: number, url: string, file?: string): Promise<void> {
        try {
            if (file) {
                // Adding a new file
                await image.uploadCarouselImages(orderNumber, file, url);
                setNewCarouselImage(null);
            } else {
                // Updating the url to an old file
                await image.saveCarouselImage(orderNumber, url);
            }
            snackbar.showSuccess();
        } catch (error) {
            snackbar.showError(error.data?.code);
        }
    }

    const { translate } = localization;

    return (
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls={props.id + '-content'}
                id={props.id + '-header'}
            >
                <Typography>{translate(props.title)}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container spacing={2}>
                    {getCarouselImages().map((image) => (
                        <Grid item key={image.id} xs={12} sm={6} lg={4}>
                            <CarouselImageCard image={image} save={save} remove={remove} />
                        </Grid>
                    ))}
                    <Grid item xs={12}>
                        <Box display="flex" justifyContent="flex-end">
                            <ShvkButton
                                onClick={addCarouselImage}
                                disabled={Boolean(newCarouselImage)}
                                endIcon={<AddPhotoAlternateOutlined />}
                            >
                                {translate('ADD')}
                            </ShvkButton>
                        </Box>
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
    );
}

export default observer(CarouselImageAccordion);
