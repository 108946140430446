import React, { useState, useEffect, ChangeEvent } from 'react';
import { observer, useLocalObservable } from 'mobx-react-lite';
import {
    Autocomplete,
    Box,
    Button,
    Card,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';
import { Add, Delete } from '@mui/icons-material';
import User from '../../../types/user';
import ShvkTextField from '../../../styled/ShvkTextField';
import ShvkButton from '../../../styled/ShvkButton';
import DangerIconButton from '../../../styled/DangerIconButton';
import useStore from '../../../store/storeContext';

interface Props {
    isOpen: boolean;
    close(): void;
}

type UserInput = {
    value: User | null;
    inputValue: string;
};

interface StoreState {
    userAutocompleteList: User[];
}

function UsersDialog(props: Props) {
    const { document, user, dialog, localization, snackbar } = useStore();

    const [userInput, setUserInput] = useState<UserInput>({
        value: null,
        inputValue: '',
    });

    const localStore = useLocalObservable<StoreState>(() => ({
        get userAutocompleteList(): User[] {
            const { currentDocument } = document;
            const { currentDocumentOrganizationUsers } = user;

            // Filter out users that are already added
            return currentDocumentOrganizationUsers.filter(
                (user) => !currentDocument.users.some((userInList) => userInList.id === user.id),
            );
        },
    }));

    useEffect(() => {
        props.isOpen && getData();
        return () => setUserInput({ value: null, inputValue: '' });
    }, [props.isOpen]);

    async function getData(): Promise<void> {
        try {
            await user.fetchCurrentDocumentOrganizationUsers();
        } catch (e) {
            snackbar.showFetchFailedMessage(e.data?.code);
        }
    }

    async function remove(id: number): Promise<void> {
        try {
            const confirmation = await dialog.getConfirmation();
            if (!confirmation) return;

            await document.removeWorkgroupUser(id);
            snackbar.showSuccess();
        } catch (e) {
            snackbar.showError(e.data?.code);
        }
    }

    async function addUser(): Promise<void> {
        if (!userInput.value) return;

        const userName = userInput.value.userName;
        setUserInput({ value: null, inputValue: '' });

        try {
            await document.addWorkgroupUser(userName);
            snackbar.showSuccess();
        } catch (e) {
            snackbar.showError(e.data?.code);
        }
    }

    const handleUserInputValueChange = (_event: ChangeEvent<unknown>, newInputValue: string): void => {
        setUserInput((state) => ({ ...state, inputValue: newInputValue }));
    };

    const handleUserValueChange = (_event: ChangeEvent<unknown>, newValue: User | null): void => {
        setUserInput((state) => ({ ...state, value: newValue }));
    };

    const { translate } = localization;

    return (
        <Dialog
            open={props.isOpen}
            onClose={props.close}
            aria-labelledby="users-dialog-title"
            maxWidth={'sm'}
            fullWidth
        >
            <DialogTitle id="users-dialog-title">{translate('DOCUMENT_USERS_TITLE')}</DialogTitle>
            <DialogContent dividers>
                <Box display="flex" mb={4}>
                    <Box flexGrow={1} mr={2}>
                        <Autocomplete
                            value={userInput.value}
                            onChange={handleUserValueChange}
                            inputValue={userInput.inputValue}
                            onInputChange={handleUserInputValueChange}
                            options={localStore.userAutocompleteList}
                            getOptionLabel={(option): string => option.userName}
                            fullWidth
                            size="small"
                            renderInput={(params): JSX.Element => (
                                <ShvkTextField
                                    {...params}
                                    label={translate('USER')}
                                    variant="outlined"
                                    placeholder={translate('EMAIL')}
                                    InputProps={{
                                        ...params.InputProps,
                                    }}
                                />
                            )}
                        />
                    </Box>
                    <ShvkButton startIcon={<Add />} onClick={addUser} disabled={!userInput.value}>
                        {translate('ADD_USER')}
                    </ShvkButton>
                </Box>
                <TableContainer component={Card} variant="outlined">
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>{translate('FIRSTNAME')}</TableCell>
                                <TableCell>{translate('LASTNAME')}</TableCell>
                                <TableCell>{translate('EMAIL')}</TableCell>
                                <TableCell />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {document.currentDocument.users.map((user) => (
                                <TableRow key={user.id}>
                                    <TableCell>{user.firstName}</TableCell>
                                    <TableCell>{user.lastName}</TableCell>
                                    <TableCell>{user.userName}</TableCell>
                                    <TableCell>
                                        <DangerIconButton size="small" onClick={(): Promise<void> => remove(user.id)}>
                                            <Delete />
                                        </DangerIconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={props.close}>
                    {translate('CANCEL')}
                </Button>
                <Box flexGrow={1} />
            </DialogActions>
        </Dialog>
    );
}

export default observer(UsersDialog);
