import React, { useState, useEffect } from 'react';
import { observer, useLocalObservable } from 'mobx-react-lite';
import {
    Box,
    Button,
    CircularProgress,
    Container,
    Divider,
    FormControlLabel,
    FormLabel,
    Grid,
    MenuItem,
    Paper,
    Radio,
    RadioGroup,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    Alert,
    Pagination,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Check } from '@mui/icons-material';
import {
    DocumentSearchDocument,
    DocumentSearchParameters,
    DocumentStatusCode,
    ShvkDocumentType,
} from '../types/document';
import { TranslationKeys } from '../types/translation';
import { TextFieldEvent } from '../types/events';
import ShvkTextField from '../styled/ShvkTextField';
import ShvkButton from '../styled/ShvkButton';
import { useNavigate } from 'react-router-dom';
import useStore from '../store/storeContext';

const PREFIX = 'DocumentSearch';

const classes = {
    table: `${PREFIX}-table`,
    cell: `${PREFIX}-cell`,
    row: `${PREFIX}-row`,
};

const StyledContainer = styled(Container)(({ theme }) => ({
    [`& .${classes.table}`]: {
        tableLayout: 'fixed',
        marginTop: theme.spacing(4),
    },

    [`& .${classes.cell}`]: {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    },

    [`& .${classes.row}`]: {
        cursor: 'pointer',
    },
}));

interface State {
    fetching: boolean;
    noDocumentsFound: boolean;
    selectedDocType: string;
    organizationId: string;
    journalNumber: string;
    statusFilter: string;
    name: string;
}

interface StoreState {
    page: number;
    documents: DocumentSearchDocument[];
    pageCount: number;
    pageContent: DocumentSearchDocument[];
    handlePageChange: (_event: React.ChangeEvent<unknown>, value: number) => void;
    setDocuments: (documents: DocumentSearchDocument[]) => void;
}

type DocumentSearchFormField = 'selectedDocType' | 'journalNumber' | 'statusFilter' | 'name' | 'organizationId';

const itemsPerPage: Readonly<number> = 10;

function DocumentSearch() {
    const { document, user, localization, snackbar, theming } = useStore();

    const navigate = useNavigate();

    const [state, setState] = useState<State>({
        fetching: false,
        noDocumentsFound: false,
        selectedDocType: 'ALL',
        organizationId: 'ALL',
        journalNumber: '',
        statusFilter: '',
        name: '',
    });

    const localStore = useLocalObservable<StoreState>(() => ({
        page: 1,
        documents: [],

        get pageCount(): number {
            return Math.ceil(this.documents.length / itemsPerPage);
        },

        get pageContent(): DocumentSearchDocument[] {
            const pageFirstIndex = (this.page - 1) * itemsPerPage;
            return this.documents.slice(pageFirstIndex, pageFirstIndex + itemsPerPage);
        },

        handlePageChange(_event: React.ChangeEvent<unknown>, value: number): void {
            this.page = value;
        },

        setDocuments(documents: DocumentSearchDocument[]): void {
            this.documents = documents;
            this.page = 1;
        },
    }));

    useEffect(() => {
        void getData();
    }, []);

    async function getData(): Promise<void> {
        try {
            await document.fetchAvailableDocumentTypes();
        } catch {
            snackbar.showFetchFailedMessage();
        }
    }

    async function openDocument(id: number): Promise<void> {
        await document.loadDocumentById(id);
        await navigate('/home');
    }

    async function searchDocuments(): Promise<void> {
        setState((state) => ({ ...state, fetching: true, noDocumentsFound: false }));
        try {
            const params: DocumentSearchParameters = {
                allowedOrganizations: user.userDetails?.organizations || [],
                documentStatus: (state.statusFilter as DocumentStatusCode) || undefined,
                documentType: state.selectedDocType === 'ALL' ? undefined : state.selectedDocType,
                title: state.name,
                journalNumber: state.journalNumber,
                organization:
                    user.userDetails?.organizations.find((org) => org.id === Number(state.organizationId)) || undefined,
            };
            const documents = await document.fetchDocuments(params);
            localStore.setDocuments(documents);
            if (localStore.documents.length === 0) {
                setState((state) => ({ ...state, noDocumentsFound: true }));
            }
        } catch {
            snackbar.showFetchFailedMessage();
        } finally {
            setState((state) => ({ ...state, fetching: false }));
        }
    }

    const handleChange = (event: TextFieldEvent, field: DocumentSearchFormField): void => {
        setState((state) => ({ ...state, [field]: event.target.value }));
    };

    const clearFields = (): void => {
        setState((state) => ({
            ...state,
            selectedDocType: 'ALL',
            journalNumber: '',
            statusFilter: '',
            name: '',
            organizationId: 'ALL',
        }));
    };

    const { translate } = localization;

    return (
        <StyledContainer>
            <Box my={3}>
                <Paper>
                    <Box px={2} py={1} textAlign="center">
                        <Typography variant="h6">{translate('DOCUMENT_SEARCH')}</Typography>
                    </Box>
                    <Divider />
                    <Box m={2}>
                        <Grid container spacing={2} justifyContent="space-between" alignItems="flex-end">
                            <Grid item xs={12} sm={6}>
                                <ShvkTextField
                                    select
                                    onChange={(event): void => handleChange(event, 'selectedDocType')}
                                    label={translate('FILTER_DOCUMENTS')}
                                    value={state.selectedDocType}
                                    variant="outlined"
                                    fullWidth
                                    size="small"
                                >
                                    <MenuItem value={'ALL'}>{translate('ALL')}</MenuItem>
                                    {document.documentTypes.map((docType: ShvkDocumentType) => (
                                        <MenuItem key={docType.id} value={docType.code}>
                                            {translate(docType.code as keyof TranslationKeys)}
                                        </MenuItem>
                                    ))}
                                </ShvkTextField>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <ShvkTextField
                                    onChange={(event): void => handleChange(event, 'journalNumber')}
                                    label={translate('JOURNAL_NUMBER')}
                                    value={state.journalNumber}
                                    variant="outlined"
                                    fullWidth
                                    size="small"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <ShvkTextField
                                    onChange={(event): void => handleChange(event, 'name')}
                                    label={translate('NAME')}
                                    value={state.name}
                                    variant="outlined"
                                    fullWidth
                                    size="small"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <ShvkTextField
                                    select
                                    onChange={(event): void => handleChange(event, 'organizationId')}
                                    label={translate('ORGANIZATION')}
                                    value={state.organizationId}
                                    variant="outlined"
                                    fullWidth
                                    size="small"
                                >
                                    <MenuItem value={'ALL'}>{translate('ALL')}</MenuItem>
                                    {user.userDetails?.organizations.map((organization) => (
                                        <MenuItem key={organization.id} value={organization.id}>
                                            {organization.name}
                                        </MenuItem>
                                    ))}
                                </ShvkTextField>
                            </Grid>
                            <Grid item>
                                <FormLabel component="legend">{translate('FILTERING')}</FormLabel>
                                <RadioGroup
                                    row
                                    aria-label={translate('FILTERING')}
                                    value={state.statusFilter}
                                    onChange={(event): void => handleChange(event, 'statusFilter')}
                                >
                                    <FormControlLabel value="" control={<Radio />} label={translate('ALL')} />
                                    <FormControlLabel
                                        value="STATUS_COMPLETE"
                                        control={<Radio />}
                                        label={translate('STATUS_COMPLETE')}
                                    />
                                    <FormControlLabel
                                        value="STATUS_INCOMPLETE"
                                        control={<Radio />}
                                        label={translate('STATUS_INCOMPLETE')}
                                    />
                                </RadioGroup>
                            </Grid>
                            <Grid item>
                                <Button
                                    variant="contained"
                                    disabled={state.fetching}
                                    style={{ marginRight: theming.spacing(2) }}
                                    onClick={clearFields}
                                >
                                    {translate('EMPTY_FIELDS')}
                                </Button>
                                <ShvkButton disabled={state.fetching} onClick={searchDocuments}>
                                    {translate('SEARCH')}
                                </ShvkButton>
                            </Grid>
                        </Grid>
                        {state.fetching ? (
                            <Box textAlign="center">
                                <CircularProgress />
                            </Box>
                        ) : state.noDocumentsFound ? (
                            <Box mt={4}>
                                <Alert severity="info">{translate('NO_DOCUMENTS_FOUND')}</Alert>
                            </Box>
                        ) : (
                            localStore.documents.length > 0 && (
                                <Table aria-label={translate('DOCUMENTS_TITLE')} className={classes.table} size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell style={{ width: '35%', verticalAlign: 'top' }}>
                                                {translate('NAME')}
                                            </TableCell>
                                            <TableCell style={{ width: '20%', verticalAlign: 'top' }}>
                                                {translate('DOCUMENT_TYPE')}
                                            </TableCell>
                                            <TableCell style={{ width: '15%', verticalAlign: 'top' }}>
                                                {translate('CURRENT_DOCUMENT_OWNER')}
                                            </TableCell>
                                            <TableCell style={{ width: '7%', verticalAlign: 'top' }}>
                                                {translate('JOURNAL_NUMBER')}
                                            </TableCell>
                                            <TableCell style={{ width: '13%', verticalAlign: 'top' }}>
                                                {translate('DOCUMENT_STATUS')}
                                            </TableCell>
                                            <TableCell style={{ width: '10%', verticalAlign: 'top' }}>
                                                {translate('PERMISSION')}
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {localStore.pageContent.map((row) => (
                                            <TableRow
                                                key={row.id}
                                                className={classes.row}
                                                hover
                                                onClick={(): void => void openDocument(row.id)}
                                            >
                                                <TableCell className={classes.cell}>{row.name}</TableCell>
                                                <TableCell className={classes.cell}>
                                                    {translate(row.type.code as keyof TranslationKeys)}
                                                </TableCell>
                                                <TableCell className={classes.cell}>
                                                    {row.owner.firstName + ' ' + row.owner.lastName}
                                                </TableCell>
                                                <TableCell className={classes.cell}>{row.journalNumber}</TableCell>
                                                <TableCell className={classes.cell}>
                                                    {translate(row.status.code)}
                                                </TableCell>
                                                <TableCell align="center" className={classes.cell}>
                                                    {row.isPermitted && <Check />}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            )
                        )}
                    </Box>
                    <Box px={2} pb={2} display="flex">
                        {!state.fetching && localStore.documents.length > 0 && (
                            <Pagination
                                count={localStore.pageCount}
                                page={localStore.page}
                                onChange={localStore.handlePageChange}
                                color="primary"
                            />
                        )}
                    </Box>
                </Paper>
            </Box>
        </StyledContainer>
    );
}

export default observer(DocumentSearch);
