import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { ButtonEvent } from '../../types/events';
import { Language } from '../../types/localization';
import {
    AppBar,
    Box,
    Button,
    CircularProgress,
    Container,
    Menu,
    MenuItem,
    Toolbar,
    Tooltip,
    Typography,
} from '@mui/material';
import { ArrowDropDown } from '@mui/icons-material';
import ShvkButton from '../../styled/ShvkButton';
import InfoIcon from '@mui/icons-material/Info';
import useStore from '../../store/storeContext';

interface State {
    languageMenu: HTMLElement | null;
}

function Header() {
    const context = useStore();
    const { document, dialog, session, user, localization, loadingIndicator, theming, snackbar, organization } =
        context;

    const navigate = useNavigate();

    const [state, setState] = useState<State>({
        languageMenu: null,
    });

    const handleClick = (event: ButtonEvent): void => {
        setState((state) => ({ ...state, languageMenu: event.currentTarget }));
    };

    const handleClose = (): void => {
        setState((state) => ({ ...state, languageMenu: null }));
    };

    async function handleLanguageSelection(language: Language): Promise<void> {
        setState((state) => ({ ...state, languageMenu: null }));
        context.localization.changeLanguage(language);
        if (user.isUserLoggedIn && organization.currentOrganizationId) {
            await user.fetchSelectedOrganizationUserDetails(organization.currentOrganizationId);
        }
    }

    async function logout(): Promise<void> {
        try {
            await document.closeCurrentDocument();
            await user.removeUserSelectedOrganization();
            await session.logout();
            navigate('/');
        } catch (error) {
            snackbar.showError(error.data?.code);
        }
    }

    const translate = localization.translate;
    const { isLoggingIn } = loadingIndicator;

    return (
        <>
            <AppBar position="sticky" style={{ backgroundColor: theming.palette.common.white }}>
                <Container>
                    <Toolbar disableGutters>
                        <Tooltip title={translate('BACK_TO_FRONTPAGE')}>
                            <Box
                                style={{ cursor: 'pointer' }}
                                onClick={async () => {
                                    await document.closeCurrentDocument();
                                    navigate('/');
                                }}
                            >
                                <Typography variant="h6" component="h2" style={{ color: theming.palette.common.black }}>
                                    {translate('APP_TITLE')}
                                </Typography>
                                <Typography
                                    variant="overline"
                                    component="span"
                                    style={{ color: theming.palette.common.black }}
                                >
                                    {translate('APP_TITLE2')}
                                </Typography>
                            </Box>
                        </Tooltip>
                        <div style={{ flexGrow: 1 }} />
                        <Button
                            aria-controls="language-menu"
                            aria-haspopup="true"
                            onClick={handleClick}
                            endIcon={<ArrowDropDown />}
                        >
                            {translate('SELECT_LANGUAGE')}
                        </Button>
                        <Menu
                            id="language-menu"
                            anchorEl={state.languageMenu}
                            keepMounted
                            open={Boolean(state.languageMenu)}
                            onClose={handleClose}
                        >
                            {localization.languages.map((language: Language) => (
                                <MenuItem
                                    key={language.id}
                                    onClick={(): Promise<void> => handleLanguageSelection(language)}
                                >
                                    {language.name}
                                </MenuItem>
                            ))}
                        </Menu>
                        {user.isUserLoggedIn ? (
                            <ShvkButton
                                style={{ marginLeft: theming.spacing(4) }}
                                onClick={() => !isLoggingIn && logout()}
                            >
                                {isLoggingIn ? <CircularProgress color="inherit" size={25} /> : translate('LOGOUT')}
                            </ShvkButton>
                        ) : (
                            <ShvkButton
                                style={{ marginLeft: theming.spacing(4) }}
                                onClick={() => !isLoggingIn && dialog.openLoginDialog()}
                            >
                                {isLoggingIn ? <CircularProgress color="inherit" size={25} /> : translate('LOGIN')}
                            </ShvkButton>
                        )}
                        {!user.isUserLoggedIn && (
                            <Tooltip title={translate('etusivu_kirjaudu_1')}>
                                <InfoIcon
                                    color="primary"
                                    style={{
                                        display: 'inline-block',
                                        verticalAlign: 'sub',
                                        marginLeft: theming.spacing(0.5),
                                    }}
                                />
                            </Tooltip>
                        )}
                    </Toolbar>
                </Container>
                <Box style={{ height: 5, backgroundColor: theming.palette.secondary.main }} />
            </AppBar>
        </>
    );
}

export default observer(Header);
