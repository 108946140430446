import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import {
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Typography,
} from '@mui/material';
import { Check, Delete } from '@mui/icons-material';
import DangerButton from '../styled/DangerButton';
import useStore from '../store/storeContext';

interface Props {
    open: boolean;
}

interface State {
    checked: boolean;
}

function ConfirmDialog(props: Props) {
    const { dialog, localization } = useStore();

    const [state, setState] = useState<State>({
        checked: false,
    });

    useEffect(() => {
        props.open && init();
    }, [props.open]);

    function init(): void {
        setState((state) => ({ ...state, checked: false }));
    }

    const handleClick = (confirmation: boolean): void => {
        dialog.resolve(confirmation);
        dialog.closeConfirmDialog();
    };

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setState((state) => ({ ...state, checked: event.target.checked }));
    };

    const {
        confirmDialogTitle,
        confirmDialogText,
        confirmDialogButtonText,
        requireCheckboxConfirmation,
        isGeneralConfirmDialog,
    } = dialog;

    const { translate } = localization;

    return (
        <Dialog open={props.open} aria-labelledby="delete-confirmation-dialog-title" maxWidth={'sm'}>
            <DialogTitle id="delete-confirmation-dialog-title">{confirmDialogTitle}</DialogTitle>
            <DialogContent dividers>
                <Box mb={1}>
                    <Typography style={{ whiteSpace: 'pre-wrap' }}>{confirmDialogText}</Typography>
                </Box>
                {requireCheckboxConfirmation && (
                    <FormControlLabel
                        control={<Checkbox checked={state.checked} onChange={handleCheckboxChange} />}
                        label={translate('YES')}
                    />
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={(): void => handleClick(false)} variant="contained">
                    {translate('CANCEL')}
                </Button>
                <Box flexGrow={1} />
                {isGeneralConfirmDialog ? (
                    <Button
                        variant="contained"
                        color={'info'}
                        onClick={(): void => handleClick(true)}
                        disabled={requireCheckboxConfirmation && !state.checked}
                        startIcon={<Check />}
                    >
                        {confirmDialogButtonText}
                    </Button>
                ) : (
                    <DangerButton
                        variant="contained"
                        onClick={(): void => handleClick(true)}
                        disabled={requireCheckboxConfirmation && !state.checked}
                        startIcon={<Delete />}
                    >
                        {confirmDialogButtonText}
                    </DangerButton>
                )}
            </DialogActions>
        </Dialog>
    );
}

export default observer(ConfirmDialog);
