import React from 'react';
import { useNavigate } from 'react-router-dom';
import { observer, useLocalObservable } from 'mobx-react-lite';
import {
    ArrowForward,
    Assignment,
    Bookmark,
    Close,
    Delete,
    Description,
    Edit,
    FormatListNumbered,
    Group,
    History,
    Home,
    ListAlt,
    Lock,
    NoteAdd,
    Settings,
    Share,
    ThumbUp,
} from '@mui/icons-material';
import { List, ListItem, ListItemButton, ListItemIcon, ListItemText, Popover } from '@mui/material';
import { styled } from '@mui/material/styles';
import { CompressIcon, ExpandIcon, PdfIcon, PowerPointIcon, WordIcon } from '../SvgIcons';
import useStore from '../../store/storeContext';

const PREFIX = 'DocumentOptionsPopover';

const classes = {
    listItemText: `${PREFIX}-listItemText`,
};

const StyledPopover = styled(Popover)({
    [`& .${classes.listItemText}`]: {
        margin: 0,
    },
});

interface Props {
    handleClose(): void;
    anchorEl: HTMLElement | null;
    deleteDocument?(): Promise<void>;
    isEditor: boolean;
}

interface StoreState {
    itemList: ButtonRow[];
}

type ButtonRow = {
    text: string;
    icon: React.ReactElement;
    action(): void;
    disabled?: boolean;
};

function DocumentOptionsPopover(props: Props) {
    const { document, organization, user, dialog, localization, indicator, snackbar } = useStore();

    const navigate = useNavigate();

    const localStore = useLocalObservable<StoreState>(() => ({
        get itemList(): ButtonRow[] {
            const { openDocumentDialog } = dialog;
            const { handleClose, deleteDocument, isEditor } = props;
            const {
                currentDocument,
                lockFocusAreaChapter,
                lockDecisionOptionChapter,
                lockAllChapters,
                changeDocumentIsOfficial,
                fetchOrganizationDocuments,
            } = document;
            const isApproved = document.isCurrentDocumentApproved;
            const isAnotherReport = document.isAnotherReport;
            const hasUserRights = document.hasUserRightsToCurrentDocument;
            const isEva = document.isCurrentDocumentEva;
            const isEvaMini = document.isCurrentDocumentEvaMini;
            const translate = localization.translate;
            const closeCurrentDocument = document.closeCurrentDocument;
            const hasIndicatorArea = document.currentDocument.chapters.some((chapter) => chapter.isIndicatorArea);
            const isRegional = currentDocument.organization.isRegional;
            const isPartOfRegional = currentDocument.organization.isPartOfRegional;
            const rows: ButtonRow[] = [];
            const isAdmin = user.userDetails?.organizations.find(
                (org) => org.id === organization.currentOrganization?.id,
            )?.isAdmin;
            const hasFocusArea = currentDocument.chapters.some((chapter) => chapter.isFocusArea);

            // Test variables
            // Todo
            const indicatorViewMode = indicator.getIndicatorViewMode;

            if (isEditor) {
                rows.push(
                    {
                        text: translate('CLOSE'),
                        icon: <Close fontSize="small" color="primary" />,
                        action: async function () {
                            handleClose();
                            await closeCurrentDocument();
                        },
                    },
                    {
                        text: translate('DOCUMENT_START_NEW'),
                        icon: <NoteAdd fontSize="small" color="primary" />,
                        action: async function () {
                            handleClose();
                            await closeCurrentDocument();
                            dialog.openNewDocumentDialog('SHVK', true);
                        },
                    },
                    {
                        text: translate('DOCUMENT_START_NEW_EVA'),
                        icon: <NoteAdd fontSize="small" color="primary" />,
                        action: async function () {
                            handleClose();
                            await closeCurrentDocument();
                            dialog.openNewDocumentDialog('EVA', true);
                        },
                    },
                    {
                        text: translate('DOCUMENT_START_NEW_ANOTHER'),
                        icon: <NoteAdd fontSize="small" color="primary" />,
                        action: async function () {
                            handleClose();
                            await closeCurrentDocument();
                            dialog.openNewDocumentDialog('ANOTHER_REPORT', true);
                        },
                    },
                );
            }
            if (isEditor && !isApproved && !isEva && isAdmin && (isRegional || isPartOfRegional))
                rows.push(
                    {
                        text: translate(isRegional ? 'COUNTIES_LARGE' : 'REGIONAL_LARGE'),
                        icon: <Assignment fontSize="small" color="primary" />,
                        action: () => {
                            openDocumentDialog('crossOrganizationDialog', { type: 'LARGE_REPORT' });
                            handleClose();
                        },
                    },
                    {
                        text: translate(isRegional ? 'COUNTIES_YEARLY' : 'REGIONAL_YEARLY'),
                        icon: <Assignment fontSize="small" color="primary" />,
                        action: () => {
                            openDocumentDialog('crossOrganizationDialog', { type: 'YEARLY_REPORT' });
                            handleClose();
                        },
                    },
                );
            if (isEditor && !isApproved && hasUserRights)
                rows.push({
                    text: capitalize(translate('REMOVE')),
                    icon: <Delete fontSize="small" color="primary" />,
                    action: () => {
                        deleteDocument!();
                        handleClose();
                    },
                });
            rows.push(
                {
                    text: translate('DOWNLOAD') + ' Pdf',
                    icon: <PdfIcon fontSize="small" color="primary" />,
                    action: () => {
                        openDocumentDialog('downloadPdfDialog');
                        handleClose();
                    },
                },
                {
                    text: translate('DOWNLOAD') + ' Word',
                    icon: <WordIcon fontSize="small" color="primary" />,
                    action: () => {
                        openDocumentDialog('downloadWordDialog');
                        handleClose();
                    },
                },
            );
            if (isEditor && !isEva)
                rows.push({
                    text: translate('DOWNLOAD_POWERPOINT'),
                    icon: <PowerPointIcon fontSize="small" color="primary" />,
                    action: () => {
                        openDocumentDialog('downloadPowerPointDialog');
                        handleClose();
                    },
                });
            if (isEditor) {
                rows.push(
                    {
                        text: translate('SHARE'),
                        icon: <Share fontSize="small" color="primary" />,
                        action: () => {
                            openDocumentDialog('shareDialog');
                            handleClose();
                        },
                    },
                    {
                        text: translate('EDITING_HISTORY'),
                        icon: <History fontSize="small" color="primary" />,
                        action: () => {
                            openDocumentDialog('historyDialog');
                            handleClose();
                        },
                    },
                );
            }
            if (isEditor && hasUserRights) {
                rows.push(
                    {
                        text: translate('USERS'),
                        icon: <Group fontSize="small" color="primary" />,
                        action: () => {
                            openDocumentDialog('usersDialog');
                            handleClose();
                        },
                    },
                    {
                        text: translate('CHANGE_OWNER'),
                        icon: <Lock fontSize="small" color="primary" />,
                        action: () => {
                            openDocumentDialog('changeOwnerDialog');
                            handleClose();
                        },
                    },
                );
            }
            if (isEditor && !isEva)
                rows.push({
                    text: translateIndicatorViewModeAction(),
                    icon:
                        indicatorViewMode === 'COMPACT_MODE' ? (
                            <ExpandIcon fontSize="small" color="primary" />
                        ) : (
                            <CompressIcon fontSize="small" color="primary" />
                        ),
                    action: () => indicator.changeIndicatorViewType(),
                });
            if (isEditor && !isApproved && !isEva && hasUserRights && hasIndicatorArea)
                rows.push(
                    {
                        text: translate('COMPARISON_SETTINGS'),
                        icon: <Settings fontSize="small" color="primary" />,
                        action: () => {
                            openDocumentDialog('comparisonSettingsDialog');
                            handleClose();
                        },
                    },
                    {
                        text: translate('EDIT_DOCUMENT_INDICATOR_MODEL'),
                        icon: <Edit fontSize="small" color="primary" />,
                        action: () => {
                            openDocumentDialog('indicatorModelDialog');
                            handleClose();
                        },
                    },
                );
            if (isEditor && !isApproved && !isEva && hasUserRights && !isAnotherReport)
                rows.push({
                    text: translate(currentDocument.isOfficial ? 'MAKE_DOCUMENT_UNOFFICIAL' : 'MAKE_DOCUMENT_OFFICIAL'),
                    icon: <Bookmark fontSize="small" color="primary" />,
                    action: async function () {
                        await changeDocumentIsOfficial();
                        await fetchOrganizationDocuments(currentDocument.organization.id);
                        handleClose();
                    },
                });
            if (isEditor && !isApproved && isEva && hasUserRights)
                rows.push({
                    text: translate('EDIT_DOCUMENT_EVA_MODEL'),
                    icon: <Edit fontSize="small" color="primary" />,
                    action: async () => {
                        try {
                            await lockDecisionOptionChapter();
                            openDocumentDialog('editEvaModelDialog');
                        } catch (error) {
                            snackbar.setMessage(error?.data?.message);
                            snackbar.setSeverity('error');
                            snackbar.open();
                        } finally {
                            handleClose();
                        }
                    },
                });
            if (isEditor && !isApproved && hasUserRights)
                rows.push({
                    text: translate('EDIT_DOCUMENT_CHAPTER_MODEL'),
                    icon: <FormatListNumbered fontSize="small" color="primary" />,
                    action: async () => {
                        try {
                            await lockAllChapters();
                            openDocumentDialog('chapterModelDialog');
                        } catch (error) {
                            snackbar.setMessage(error?.data?.message);
                            snackbar.setSeverity('error');
                            snackbar.open();
                        } finally {
                            handleClose();
                        }
                    },
                });
            if (isEditor && !isApproved && !isEva && hasUserRights && hasFocusArea)
                rows.push({
                    text: translate('EDIT_DOCUMENT_FOCUS_AREA_MODEL'),
                    icon: <ListAlt fontSize="small" color="primary" />,
                    action: async () => {
                        try {
                            await lockFocusAreaChapter();
                            openDocumentDialog('focusAreaModelDialog');
                        } catch (error) {
                            snackbar.setMessage(error?.data?.message);
                            snackbar.setSeverity('error');
                            snackbar.open();
                        } finally {
                            handleClose();
                        }
                    },
                });
            if (isEditor && isEva)
                rows.push({
                    text: translate('EVA_DECISION_OPTION_COMPARE'),
                    icon: <ListAlt fontSize="small" color="primary" />,
                    action: () => {
                        openDocumentDialog('evaDecisionOptionCompareDialog');
                        handleClose();
                    },
                });
            if (isEditor && !isApproved && hasUserRights)
                rows.push(
                    {
                        text: translate('DOCUMENT_COVER_PAGE'),
                        icon: <Description fontSize="small" color="primary" />,
                        action: () => {
                            openDocumentDialog('coverPageDialog');
                            handleClose();
                        },
                    },
                    {
                        text: !isEvaMini ? translate('APPROVAL') : translate('FURTHER_ACTIONS'),
                        icon: !isEvaMini ? (
                            <ThumbUp fontSize="small" color="primary" />
                        ) : (
                            <ArrowForward fontSize="small" color="primary" />
                        ),
                        action: () => {
                            openDocumentDialog('approvalDialog');
                            handleClose();
                        },
                    },
                );
            if (isEditor && isApproved && hasUserRights)
                rows.push({
                    text: translate('DISAPPROVAL'),
                    icon: <ThumbUp fontSize="small" color="primary" />,
                    action: () => {
                        openDocumentDialog('disapprovalDialog');
                        handleClose();
                    },
                });
            if (!isEditor) {
                rows.push({
                    text: translate('BACK_TO_FRONTPAGE'),
                    icon: <Home fontSize="small" color="primary" />,
                    action: async function () {
                        navigate('/');
                        await closeCurrentDocument();
                        handleClose();
                    },
                });
            }
            return rows;
        },
    }));

    const capitalize = (string: string): string => {
        return string.charAt(0).toUpperCase() + string.toLowerCase().slice(1);
    };

    const translateIndicatorViewModeAction = (): string => {
        const translate = localization.translate;
        const indicatorViewMode = indicator.getIndicatorViewMode;

        const message = translate('CHANGE_DOCUMENT_INDICATOR_VIEW_MODE');
        const modeMessage =
            indicatorViewMode === 'COMPACT_MODE'
                ? translate('WIDE_INDICATOR_MODE')
                : translate('COMPACT_INDICATOR_MODE');
        return message.replace('#mode', modeMessage);
    };

    return (
        <StyledPopover
            id="document-options-popover"
            open={Boolean(props.anchorEl)}
            onClose={props.handleClose}
            anchorEl={props.anchorEl}
        >
            <List>
                {localStore.itemList.map((row) => (
                    <ListItemButton key={row.text} disabled={row.disabled} dense>
                        <ListItem onClick={row.action} disablePadding>
                            <ListItemIcon>{row.icon}</ListItemIcon>
                            <ListItemText primary={row.text} className={classes.listItemText} />
                        </ListItem>
                    </ListItemButton>
                ))}
            </List>
        </StyledPopover>
    );
}

export default observer(DocumentOptionsPopover);
