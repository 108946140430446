import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import FroalaEditorComponent from 'react-froala-wysiwyg';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { FocusArea } from '../../../../types/document';
import ShvkButton from '../../../../styled/ShvkButton';
import useStore from '../../../../store/storeContext';

interface Props {
    isOpen: boolean;
    close(): void;
    focusArea?: FocusArea | null;
}

interface State {
    title: string;
}

function AddFocusAreaGoalDialog(props: Props) {
    const { document, localization, loadingIndicator, snackbar } = useStore();

    const [state, setState] = useState<State>({
        title: '',
    });

    useEffect(() => {
        props.isOpen && setState((state) => ({ ...state, title: '' }));
    }, [props.isOpen]);

    const handleTitleChange = (title: string): void => {
        setState((state) => ({ ...state, title }));
    };

    async function addGoal(): Promise<void> {
        try {
            loadingIndicator.show();
            await document.addGoal(props.focusArea!.id, state.title);
            snackbar.showSuccess();
        } catch (error) {
            snackbar.showError(error.data?.code);
        } finally {
            props.close();
            loadingIndicator.hide();
        }
    }

    const { translate } = localization;

    return (
        <Dialog open={props.isOpen} onClose={props.close} maxWidth="lg" fullWidth>
            <DialogTitle>{translate('ADD_GOAL')}</DialogTitle>
            <DialogContent>
                <FroalaEditorComponent
                    tag="textarea"
                    model={state.title}
                    onModelChange={handleTitleChange}
                    config={{
                        ...document.froalaFocusAreaConfig,
                        imageUploadParams: { chapterId: 0 },
                        fileUploadParams: { chapterId: 0 },
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={props.close}>
                    {translate('CLOSE')}
                </Button>
                <Box flexGrow={1} />
                <ShvkButton onClick={addGoal}>{translate('SAVE')}</ShvkButton>
            </DialogActions>
        </Dialog>
    );
}

export default observer(AddFocusAreaGoalDialog);
