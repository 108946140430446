import React, { useState, useEffect, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { Chapter } from '../../../types/document';
import FroalaEditorComponent from 'react-froala-wysiwyg';
import useStore from '../../../store/storeContext';

interface Props {
    chapter: Chapter;
    closeTextFieldAfterTimeout: () => Promise<void>;
}

interface State {
    text: string;
}

function FroalaChapter(props: Props) {
    const { document, session } = useStore();

    const chapterLockTimeout = session.chapterLockTimeout;

    const timerRef = useRef({} as NodeJS.Timeout);

    const [state, setState] = useState<State>({
        text: '',
    });

    useEffect(() => {
        timerRef.current = setTimeout(() => {
            props.closeTextFieldAfterTimeout();
        }, chapterLockTimeout);
        setState((state) => ({ ...state, text: props.chapter.text }));
        return () => clearTimeout(timerRef.current);
    }, []);

    function onModelChange(text: string): void {
        setState((state) => ({ ...state, text }));
        if (props.chapter.text !== text) {
            clearTimeout(timerRef.current);
            timerRef.current = setTimeout(() => {
                props.closeTextFieldAfterTimeout();
            }, chapterLockTimeout);
            void document.saveChapter({ ...props.chapter, text });
        }
    }

    return (
        <FroalaEditorComponent
            model={state.text}
            onModelChange={onModelChange}
            config={{
                ...document.froalaConfig,
                imageUploadParams: { chapterId: props.chapter.id },
                fileUploadParams: { chapterId: props.chapter.id },
            }}
        />
    );
}

export default observer(FroalaChapter);
