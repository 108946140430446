import React from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Tooltip } from '@mui/material';
import FroalaCoverPage from '../editor/FroalaCoverPage';
import InfoIcon from '@mui/icons-material/Info';
import useStore from '../../../store/storeContext';

interface Props {
    isOpen: boolean;
    close(): void;
}

function CoverPageDialog(props: Props) {
    const { localization, theming } = useStore();

    const { translate } = localization;

    return (
        <Dialog open={props.isOpen} onClose={props.close} maxWidth="lg" fullWidth>
            <DialogTitle>
                {translate('DOCUMENT_COVER_PAGE')}
                <Tooltip placement="right-start" title={translate('COVERPAGE_FROALA_TOOLTIP')}>
                    <InfoIcon
                        color="primary"
                        style={{
                            display: 'inline-block',
                            verticalAlign: 'middle',
                            paddingBottom: '3px',
                            marginLeft: theming.spacing(1),
                        }}
                    />
                </Tooltip>
            </DialogTitle>
            <DialogContent dividers>
                <FroalaCoverPage />
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={props.close}>
                    {translate('CLOSE')}
                </Button>
                <Box flexGrow={1} />
            </DialogActions>
        </Dialog>
    );
}

export default observer(CoverPageDialog);
