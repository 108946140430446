import React from 'react';
import { observer } from 'mobx-react-lite';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Grid, Paper, Typography } from '@mui/material';
import { Delete, ExpandMore } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { EditorPerspective } from '../../../../types/evaTemplate';
import { Chapter } from '../../../../types/document';
import DangerIconButton from '../../../../styled/DangerIconButton';
import EvaPerspectiveTable from './EvaPerspectiveTable';
import EvaImpactOnTargetRow from './EvaImpactOnTargetRow';
import useStore, { rootModule } from '../../../../store/storeContext';

const PREFIX = 'EvaDecisionOptionAccordions';

const classes = {
    accordionDetails: `${PREFIX}-accordionDetails`,
    paper: `${PREFIX}-paper`,
};

const { theming } = rootModule;

const Root = styled('div')(({ theme }) => ({
    [`& .${classes.accordionDetails}`]: {
        display: 'block',
        position: 'relative',
    },

    [`& .${classes.paper}`]: {
        backgroundColor: theming.shvkPetrol10,
        marginBottom: theme.spacing(2),
    },
}));

interface Props {
    chapter: Chapter;
    editing: boolean;
}

function EvaDecisionOptionAccordions(props: Props) {
    const { document, dialog, localization, snackbar, loadingIndicator } = useStore();

    async function removePerspective(perspective: EditorPerspective): Promise<void> {
        const { translate } = localization;
        const { getConfirmation } = dialog;
        let confirmDialogText;
        if (!perspective.removable) {
            confirmDialogText = translate('CONFIRM_REMOVE_DEFAULT_EVA_PERSPECTIVE');
            confirmDialogText = confirmDialogText.replace('#perspective', perspective.title);
        }
        const confirmation = await getConfirmation(undefined, confirmDialogText, true);
        if (!confirmation) return;

        try {
            loadingIndicator.show();
            await document.removeEvaPerspective(perspective.title, props.chapter);
            snackbar.showSuccess();
        } catch (error) {
            snackbar.showError(error.data?.code);
        } finally {
            loadingIndicator.hide();
        }
    }

    function getIsEditable(): boolean {
        return !document.isCurrentDocumentApproved && !document.isPreview && props.editing;
    }

    const openAddImpactOnTargetDialog = (perspective: EditorPerspective): void => {
        dialog.openDocumentDialog('addImpactOnTargetDialog', { perspective });
    };

    const openAddPerspectiveDialog = (): void => {
        dialog.openDocumentDialog('addPerspectiveDialog');
    };

    const isEditable = getIsEditable();
    const { translate } = localization;

    return (
        <Root>
            {props.chapter.evaDecisionOptions.map((decisionOption) => (
                <Accordion key={decisionOption.id}>
                    <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls={'panel-' + decisionOption.orderNumber + '-content'}
                        id={'panel-' + decisionOption.orderNumber + '-header'}
                    >
                        <Typography>{decisionOption.text}</Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.accordionDetails}>
                        {decisionOption.evaPerspectives.map((perspective, i) => (
                            <Box key={perspective.id} mb={i < decisionOption.evaPerspectives.length - 1 ? 8 : 0}>
                                <Paper className={classes.paper} variant="outlined">
                                    <Box display="flex" alignItems="center" px={2} py={!isEditable ? 1.5 : 0}>
                                        <Box flexGrow={1}>
                                            <Typography>
                                                {translate('EVA_PERSPECTIVE')}: {perspective.title}
                                            </Typography>
                                        </Box>
                                        {isEditable && (
                                            <DangerIconButton
                                                onClick={(): Promise<void> => removePerspective(perspective)}
                                            >
                                                <Delete />
                                            </DangerIconButton>
                                        )}
                                    </Box>
                                </Paper>
                                <EvaPerspectiveTable
                                    perspective={perspective}
                                    showCommentsTextField
                                    editing={props.editing}
                                >
                                    {perspective.impactTargets.map((impact) => (
                                        <EvaImpactOnTargetRow key={impact.id} impact={impact} editing={props.editing} />
                                    ))}
                                </EvaPerspectiveTable>
                                {isEditable && (
                                    <Grid container spacing={2}>
                                        <Grid item>
                                            <Button
                                                variant="outlined"
                                                color="primary"
                                                onClick={(): void => openAddImpactOnTargetDialog(perspective)}
                                            >
                                                {translate('EVA_ADD_IMPACT_ON_TARGET')}
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                variant="outlined"
                                                color="primary"
                                                onClick={openAddPerspectiveDialog}
                                            >
                                                {translate('EVA_ADD_PERSPECTIVE')}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                )}
                            </Box>
                        ))}
                    </AccordionDetails>
                </Accordion>
            ))}
        </Root>
    );
}

export default observer(EvaDecisionOptionAccordions);
