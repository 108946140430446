import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { FocusArea, FocusAreaGoal, FocusAreaGoalAction } from '../../../types/document';
import { Button, Table, TableBody, TableCell, TableHead, TableRow, Typography, Tooltip } from '@mui/material';
import { Add, Delete } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import DangerIconButton from '../../../styled/DangerIconButton';
import EditGoalDialog from '../dialogs/shvk/EditGoalDialog';
import EditActionDialog from '../dialogs/shvk/EditActionDialog';
import EditAdditionalColumnByGoalDialog from '../dialogs/shvk/EditAdditionalColumnByGoalDialog';
import useStore, { rootModule } from '../../../store/storeContext';

const PREFIX = 'FocusAreaTable';

const classes = {
    tableCell: `${PREFIX}-tableCell`,
    table: `${PREFIX}-table`,
    tableWrapper: `${PREFIX}-tableWrapper`,
    pointer: `${PREFIX}-pointer`,
};

const { theming } = rootModule;

const Root = styled('div')(({ theme }) => ({
    [`& .${classes.tableCell}`]: {
        border: '1px solid' + theming.shvkLightGrey,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(0.5),
    },

    [`& .${classes.table}`]: {
        // Texts in cells are in html-format. Remove margins and paddings from p-elements (every row is a <p>)
        '& p': {
            margin: 0,
            padding: 0,
        },
    },

    [`&.${classes.tableWrapper}`]: {
        overflowX: 'auto',
        wordBreak: 'break-word',
    },

    [`& .${classes.pointer}`]: {
        cursor: 'pointer',
    },
}));

interface Props {
    focusArea: FocusArea;
    editing: boolean;
}

interface DialogState {
    isOpen: boolean;
    goal: FocusAreaGoal;
}

interface ActionDialogState extends DialogState {
    action: FocusAreaGoalAction;
    tab: string;
}

function FocusAreaTable(props: Props) {
    const { document, dialog, localization, loadingIndicator, snackbar } = useStore();

    const [goalDialog, setGoalDialog] = useState<DialogState>({
        isOpen: false,
        goal: {} as FocusAreaGoal,
    });
    const [additionalColumnDialog, setAdditionalColumnDialog] = useState<DialogState>({
        isOpen: false,
        goal: {} as FocusAreaGoal,
    });
    const [actionDialog, setActionDialog] = useState<ActionDialogState>({
        isOpen: false,
        goal: {} as FocusAreaGoal,
        action: {} as FocusAreaGoalAction,
        tab: 'title',
    } as ActionDialogState);

    async function removeAction(goal: FocusAreaGoal, action: FocusAreaGoalAction): Promise<void> {
        const { translate } = localization;
        const confirmDialogText = translate('REMOVE_GOAL_ACTION');
        const confirmation = await dialog.getConfirmation(undefined, confirmDialogText, true);
        if (!confirmation) return;

        try {
            loadingIndicator.show();
            await document.removeGoalAction(props.focusArea.id, goal.id, action.id);
            snackbar.showSuccess();
        } catch (error) {
            snackbar.showError(error.data?.code);
        } finally {
            loadingIndicator.hide();
        }
    }

    function getIsEditable(): boolean {
        return !document.isCurrentDocumentApproved && !document.isPreview && props.editing;
    }

    function getGoalColSpan(): number {
        const focusAreaTableTitles = document.currentDocument.focusAreaTableTitles;
        if (focusAreaTableTitles.additionalColumnByActionTitle) return 5;
        return 4;
    }

    const isEditable = getIsEditable();
    const addGoalColSpan = getGoalColSpan();

    const handleEditGoal = (goal: FocusAreaGoal): void => {
        if (isEditable) {
            setGoalDialog((state) => ({ ...state, isOpen: true, goal }));
        }
    };

    const handleEditAction = (goal: FocusAreaGoal, action: FocusAreaGoalAction, tab: string): void => {
        if (isEditable) {
            setActionDialog((state) => ({ ...state, isOpen: true, goal, action, tab }));
        }
    };

    const handleEditAdditionalColumn = (goal: FocusAreaGoal): void => {
        if (isEditable) {
            setAdditionalColumnDialog((state) => ({ ...state, isOpen: true, goal }));
        }
    };

    const { translate } = localization;
    const { focusAreaTableTitles } = document.currentDocument;

    return (
        <Root className={classes.tableWrapper}>
            <Table className={classes.table}>
                <TableHead>
                    <TableRow>
                        <TableCell className={classes.tableCell} style={{ width: '16%' }}>
                            {translate('GOALS')}
                        </TableCell>
                        <TableCell className={classes.tableCell} style={{ width: '16%' }}>
                            {focusAreaTableTitles.actionTitle}
                        </TableCell>
                        <TableCell className={classes.tableCell} style={{ width: '16%' }}>
                            {focusAreaTableTitles.resourceTitle}
                        </TableCell>
                        {focusAreaTableTitles.evaluationGaugeTitle !== null ? (
                            <TableCell className={classes.tableCell} style={{ width: '16%' }}>
                                {focusAreaTableTitles.evaluationGaugeTitle}
                            </TableCell>
                        ) : null}
                        {focusAreaTableTitles.additionalColumnByActionTitle !== null ? (
                            <TableCell className={classes.tableCell} style={{ width: '16%' }}>
                                {focusAreaTableTitles.additionalColumnByActionTitle}
                            </TableCell>
                        ) : null}
                        {isEditable ? <TableCell className={classes.tableCell} style={{ width: '4%' }} /> : null}
                        {focusAreaTableTitles.additionalColumnByGoalTitle !== null ? (
                            <TableCell className={classes.tableCell} style={{ width: '16%' }}>
                                {focusAreaTableTitles.additionalColumnByGoalTitle}
                            </TableCell>
                        ) : null}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.focusArea.goals.map((goal) => (
                        <React.Fragment key={goal.id}>
                            <TableRow>
                                <TableCell
                                    className={`${classes.tableCell}  ${isEditable ? classes.pointer : null}`}
                                    rowSpan={goal.actions.length + (isEditable ? 2 : 1)}
                                    onClick={(): void => handleEditGoal(goal)}
                                >
                                    <Tooltip title={isEditable ? translate('GOAL_TITLE_TOOLTIP') : ''}>
                                        <Typography
                                            className="fr-view"
                                            dangerouslySetInnerHTML={{
                                                __html: goal.title || translate('GOAL_TITLE_EMPTY'),
                                            }}
                                        />
                                    </Tooltip>
                                </TableCell>
                            </TableRow>
                            {goal.actions
                                .slice()
                                .sort((a, b) => a.orderNumber - b.orderNumber)
                                .map((action, i) => (
                                    <TableRow key={action.id}>
                                        <TableCell
                                            className={`${classes.tableCell}  ${isEditable ? classes.pointer : null}`}
                                            onClick={(): void => handleEditAction(goal, action, 'title')}
                                        >
                                            <Typography
                                                className="fr-view"
                                                dangerouslySetInnerHTML={{ __html: action.title }}
                                            />
                                        </TableCell>
                                        <TableCell
                                            className={`${classes.tableCell} ${isEditable ? classes.pointer : null}`}
                                            onClick={(): void => handleEditAction(goal, action, 'resources')}
                                        >
                                            <Typography
                                                className="fr-view"
                                                dangerouslySetInnerHTML={{ __html: action.resources }}
                                            />
                                        </TableCell>
                                        {focusAreaTableTitles.evaluationGaugeTitle !== null ? (
                                            <TableCell
                                                className={`${classes.tableCell} ${
                                                    isEditable ? classes.pointer : null
                                                }`}
                                                onClick={(): void => handleEditAction(goal, action, 'evaluationGauge')}
                                            >
                                                <Typography
                                                    className="fr-view"
                                                    dangerouslySetInnerHTML={{ __html: action.evaluationGauge }}
                                                />
                                            </TableCell>
                                        ) : null}
                                        {focusAreaTableTitles.additionalColumnByActionTitle !== null ? (
                                            <TableCell
                                                className={`${classes.tableCell} ${
                                                    isEditable ? classes.pointer : null
                                                }`}
                                                onClick={(): void =>
                                                    handleEditAction(goal, action, 'additionalColumnByActionTitle')
                                                }
                                            >
                                                <Typography
                                                    className="fr-view"
                                                    dangerouslySetInnerHTML={{ __html: action.additionalColumn }}
                                                />
                                            </TableCell>
                                        ) : null}
                                        {isEditable ? (
                                            <TableCell className={classes.tableCell} align="center">
                                                <DangerIconButton
                                                    size="small"
                                                    onClick={(): Promise<void> => removeAction(goal, action)}
                                                >
                                                    <Delete />
                                                </DangerIconButton>
                                            </TableCell>
                                        ) : null}
                                        {focusAreaTableTitles.additionalColumnByGoalTitle !== null && i === 0 ? (
                                            // Additional goal title is the same for every action.
                                            // Render cell only once.
                                            <TableCell
                                                className={`${classes.tableCell} ${
                                                    isEditable ? classes.pointer : null
                                                }`}
                                                rowSpan={goal.actions.length + (isEditable ? 1 : 0)}
                                                onClick={(): void => handleEditAdditionalColumn(goal)}
                                            >
                                                <Typography
                                                    className="fr-view"
                                                    dangerouslySetInnerHTML={{ __html: goal.additionalColumn }}
                                                />
                                            </TableCell>
                                        ) : null}
                                    </TableRow>
                                ))}
                            {isEditable ? (
                                <TableRow>
                                    <TableCell className={classes.tableCell} colSpan={addGoalColSpan}>
                                        <Button
                                            color="primary"
                                            startIcon={<Add />}
                                            onClick={(): void =>
                                                dialog.openDocumentDialog('addFocusAreaActionDialog', {
                                                    focusArea: props.focusArea,
                                                    goal,
                                                    handleEditAction: handleEditAction,
                                                })
                                            }
                                        >
                                            {translate('ADD_GOAL_ACTION')}
                                        </Button>
                                    </TableCell>
                                    {focusAreaTableTitles.additionalColumnByGoalTitle !== null &&
                                        goal.actions.length === 0 && (
                                            <TableCell
                                                className={`${classes.tableCell}`}
                                                onClick={(): void => handleEditAdditionalColumn(goal)}
                                            />
                                        )}
                                </TableRow>
                            ) : null}
                        </React.Fragment>
                    ))}
                </TableBody>
            </Table>
            {goalDialog.isOpen && (
                <EditGoalDialog
                    isOpen={goalDialog.isOpen}
                    close={() => setGoalDialog((state) => ({ ...state, isOpen: false }))}
                    focusArea={props.focusArea}
                    goal={goalDialog.goal}
                />
            )}
            {actionDialog.isOpen && (
                <EditActionDialog
                    isOpen={actionDialog.isOpen}
                    close={() => setActionDialog((state) => ({ ...state, isOpen: false }))}
                    focusArea={props.focusArea}
                    goal={actionDialog.goal}
                    action={actionDialog.action}
                    tab={actionDialog.tab}
                />
            )}
            {additionalColumnDialog.isOpen && (
                <EditAdditionalColumnByGoalDialog
                    isOpen={additionalColumnDialog.isOpen}
                    close={() => setAdditionalColumnDialog((state) => ({ ...state, isOpen: false }))}
                    focusArea={props.focusArea}
                    goal={additionalColumnDialog.goal}
                />
            )}
        </Root>
    );
}

export default observer(FocusAreaTable);
