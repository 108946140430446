import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useNavigate, useParams } from 'react-router-dom';
import { Container, Grid } from '@mui/material';
import { BACKEND_URL } from '../config';
import Carousel from '../components/welcome/Carousel';
import News from '../components/welcome/News';
import Training from '../components/welcome/Training';
import PublishedDocuments from '../components/welcome/PublishedDocuments';
import LatestApprovedDocuments from '../components/welcome/LatestApprovedDocuments';
import Contact from '../components/welcome/Contact';
import ResetPasswordDialog from '../components/user/ResetPasswordDialog';
import useStore from '../store/storeContext';

function Welcome() {
    const { session, dialog, document, snackbar, theming, news, image, contact, info, organization, localization } =
        useStore();

    const navigate = useNavigate();
    const { activateAndResetToken, resetToken, shareToken } = useParams();

    useEffect(() => {
        void init();
    }, []);

    async function init() {
        if (!session.isControlDataInitialized) {
            await session.fetchInitialControlData();
        }
        if (activateAndResetToken || resetToken) {
            dialog.openResetPasswordDialog();
        } else if (shareToken) {
            await document.loadDocumentByShareId(shareToken);
            navigate('/preview/' + document.currentDocument.id);
            return;
        }
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
        await getData();
    }

    async function getData(): Promise<void> {
        try {
            await news.fetchAllNews();
            await image.fetchCarouselImages();
            await contact.fetchContact();
            await info.fetchShvkInfo();
            await organization.fetchOrganizationLocations();
            await document.fetchPublishedDocuments();
            await document.fetchAvailableDocumentTypes();
        } catch {
            snackbar.showFetchFailedMessage();
        }
    }

    const backgroundStyle = {
        backgroundImage: `url(${BACKEND_URL}app/downloadBackgroundImage)`,
        backgroundAttachment: 'fixed',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
    };

    const { translate } = localization;
    const { isTrainingEnvironment } = session;

    return (
        <div style={backgroundStyle}>
            <Container sx={{ py: theming.spacing(3) }}>
                <Carousel />
                {!isTrainingEnvironment && !shareToken && (
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={3}>
                            <News />
                            <Training />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <PublishedDocuments />
                        </Grid>
                        <Grid item xs={12} sm={12} md={3}>
                            <Contact />
                            <LatestApprovedDocuments />
                        </Grid>
                    </Grid>
                )}
            </Container>
            <ResetPasswordDialog
                open={dialog.isResetPasswordDialogOpen}
                token={activateAndResetToken ? activateAndResetToken! : resetToken!}
                title={
                    activateAndResetToken
                        ? translate('ACCOUNT_ACTIVATED_RESET_PASSWORD')
                        : translate('RESET_PASSWORD_DLG_TITLE')
                }
            />
        </div>
    );
}
export default observer(Welcome);
