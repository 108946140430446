import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Collapse, TableCell, TableRow, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { SeeFeedback } from '../../types/feedback';
import moment from 'moment';
import useStore from '../../store/storeContext';

const PREFIX = 'SeeFeedbackDialogRow';

const classes = {
    root: `${PREFIX}-root`,
    collapseCell: `${PREFIX}-collapseCell`,
};

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    [`& .${classes.root}`]: {
        '& > *': {
            borderBottom: 'unset',
        },
        cursor: 'pointer',
    },

    [`& .${classes.collapseCell}`]: {
        paddingLeft: theme.spacing(4),
        paddingTop: 0,
        paddingBottom: 0,
        borderBottom: 'unset',
    },
}));

interface Props {
    feedback: SeeFeedback;
}

interface State {
    expanded: boolean;
}

function SeeFeedbackDialogRow(props: Props) {
    const { localization, theming } = useStore();
    const [state, setState] = useState<State>({
        expanded: false,
    });

    const toggleCollapse = (): void => {
        setState((state) => ({ ...state, expanded: !state.expanded }));
    };
    const { translate } = localization;
    return (
        <>
            <StyledTableRow className={classes.root} hover onClick={toggleCollapse}>
                <TableCell>
                    <Typography>
                        <Box component={'span'} sx={{ fontWeight: 'bold' }}>
                            {translate('USER') + ': '}
                        </Box>
                        {props.feedback.userName}
                    </Typography>
                    <Typography>
                        <Box component={'span'} sx={{ fontWeight: 'bold' }}>
                            {translate('FEEDBACK_SUBJECT') + ': '}
                        </Box>
                        {props.feedback.type.name}
                    </Typography>
                    <Typography>
                        <Box component={'span'} sx={{ fontWeight: 'bold' }}>
                            {translate('FEEDBACK') + ': '}
                        </Box>
                        {!state.expanded
                            ? props.feedback.message.length > 90
                                ? props.feedback.message.slice(0, 90) + '...'
                                : props.feedback.message
                            : null}
                    </Typography>
                </TableCell>
                <TableCell align="right">{moment(props.feedback.dateCreated).format('DD.MM.YYYY HH:mm:ss')}</TableCell>
            </StyledTableRow>
            <StyledTableRow>
                <TableCell className={classes.collapseCell} style={{ width: '95%' }}>
                    <Collapse in={state.expanded} timeout="auto" unmountOnExit>
                        <Box my={2} px={3} py={2} style={{ backgroundColor: theming.shvkBlue10 }}>
                            <Typography>{props.feedback.message}</Typography>
                        </Box>
                    </Collapse>
                </TableCell>
            </StyledTableRow>
        </>
    );
}

export default observer(SeeFeedbackDialogRow);
