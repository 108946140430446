import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import useStore from '../../../../store/storeContext';

interface Props {
    isOpen: boolean;
    close(): void;
    type?: 'LARGE_REPORT' | 'YEARLY_REPORT';
}

interface Doc {
    documentId?: number;
    documentName?: string;
    organizationName: string;
}

function CrossOrganizationDialog(props: Props) {
    const { document, localization, loadingIndicator, snackbar, theming } = useStore();

    const [docs, setDocs] = useState<Doc[]>([] as Doc[]);

    useEffect(() => {
        props.isOpen && init();
    }, [props.isOpen]);

    async function init() {
        setDocs([]);
        try {
            loadingIndicator.show();
            const docs = await document.fetchOfficialDocuments(props.type);
            setDocs(docs);
        } catch (error) {
            snackbar.showError(error.data?.code);
        } finally {
            loadingIndicator.hide();
        }
    }

    function getDocuments(): Doc[] {
        return docs.slice().sort((a, b) => (a.organizationName < b.organizationName ? -1 : 1));
    }

    const documents = getDocuments();
    const { translate } = localization;
    const { isRegional } = document.currentDocument.organization;

    return (
        <Dialog open={props.isOpen} onClose={props.close} maxWidth={'md'} fullWidth>
            <DialogTitle>
                {translate(
                    props.type === 'LARGE_REPORT'
                        ? isRegional
                            ? 'COUNTIES_LARGE_TITLE'
                            : 'REGIONAL_LARGE_TITLE'
                        : isRegional
                        ? 'COUNTIES_YEARLY_TITLE'
                        : 'REGIONAL_YEARLY_TITLE',
                )}
                <Tooltip
                    title={translate(isRegional ? 'REGIONAL_TITLE_TIP' : 'COUNTIES_TITLE_TIP')}
                    placement="right-start"
                >
                    <InfoIcon
                        color="primary"
                        style={{
                            marginLeft: theming.spacing(1),
                            verticalAlign: 'middle',
                            paddingBottom: '3px',
                        }}
                    />
                </Tooltip>
            </DialogTitle>
            <DialogContent dividers>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ borderBottom: '0px', padding: 2 }}>
                                <Typography>
                                    <b>{translate(isRegional ? 'ORGANIZATION' : 'REGIONAL')}</b>
                                </Typography>
                            </TableCell>
                            <TableCell style={{ borderBottom: '0px', padding: 2 }}>
                                <Typography>
                                    <b>{translate('DOCUMENTS_NAME')}</b>
                                </Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {documents.map((document, i) => (
                            <TableRow key={i} hover style={{ cursor: document.documentName ? 'pointer' : 'default' }}>
                                <TableCell style={{ borderBottom: '0px', padding: 1 }}>
                                    <Typography>{document.organizationName}</Typography>
                                </TableCell>
                                <TableCell style={{ borderBottom: '0px', padding: 1 }}>
                                    {document.documentId && document.documentName ? (
                                        <a
                                            href={`/preview/${document.documentId}`}
                                            rel="noopener noreferrer"
                                            target="_blank"
                                        >
                                            <Typography>{document.documentName}</Typography>
                                        </a>
                                    ) : (
                                        <Typography style={{ color: theming.shvkMediumGrey }}>
                                            <i>{translate('OFFICIAL_DOCUMENT_NOT_FOUND')}</i>
                                        </Typography>
                                    )}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={props.close}>
                    {translate('CLOSE')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default observer(CrossOrganizationDialog);
