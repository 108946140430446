import React from 'react';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import { Box, IconButton, Paper, TextField, Typography } from '@mui/material';
import { Bookmark, CreateNewFolder, Delete, Description, ThumbUp } from '@mui/icons-material';
import { DirectoryNestableItem, DirectoryRenderItemArgs } from '../../types/nestable';
import { styled } from '@mui/material/styles';
import useStore from '../../store/storeContext';

const PREFIX = 'DirectoryNestableComponent';

const classes = {
    paper: `${PREFIX}-paper`,
};

const StyledPaper = styled(Paper)(({ theme }) => ({
    [`&.${classes.paper}`]: {
        position: 'relative',
        padding: theme.spacing(0.5),
    },
}));

interface Props {
    editing: boolean;
    args: DirectoryRenderItemArgs;
    handleTextChange(value: string, id: number): void;
    addItem(parentId: number | null): void;
    removeItem(item: DirectoryNestableItem): void;
}

function DirectoryNestableComponent(props: Props) {
    const { document, dialog, theming, loadingIndicator, snackbar } = useStore();

    const navigate = useNavigate();

    async function handleClick(): Promise<void> {
        if (!props.editing && props.args.item.isDocument) {
            try {
                // Loading indicator gets hidden when the <Document /> component is loaded.
                loadingIndicator.show();
                await document.loadDocumentById(props.args.item.id);
            } catch (error) {
                snackbar.showError(error.data?.code);
                if (error.data.code === 'USER_NOT_IN_DOCUMENT_WORKGROUP') {
                    await document.loadForPreviewById(props.args.item.id);
                    navigate('/preview/' + props.args.item.id);
                    loadingIndicator.hide();
                }
            }
        }
    }

    async function handleRemoveItem(item: DirectoryNestableItem): Promise<void> {
        const confirmation = await dialog.getConfirmation();
        if (!confirmation) return;

        props.removeItem(item);
    }

    function getColor(): string {
        if (props.args.item.isDocument) return theming.shvkBlueGray20;
        else {
            if (props.args.item.depth === 1) return theming.shvkBlueGray60;
            else return theming.shvkBlueGray40;
        }
    }

    const { args, editing, handleTextChange, addItem } = props;

    return (
        <StyledPaper
            variant="outlined"
            className={classes.paper}
            style={{ backgroundColor: getColor(), cursor: args.item.isDocument ? 'pointer' : undefined }}
            onClick={handleClick}
        >
            {editing && args.handler}
            <Box display="flex" alignItems="center">
                {args.collapseIcon}
                {editing && !args.item.isDocument ? (
                    <>
                        <TextField
                            variant="outlined"
                            size="small"
                            onChange={(event): void => handleTextChange(event.target.value, args.item.id)}
                            value={args.item.text}
                        />
                        <Box flexGrow={1} />
                        {!args.item.parentId && (
                            <IconButton size="small" color="primary" onClick={(): void => addItem(args.item.id)}>
                                <CreateNewFolder />
                            </IconButton>
                        )}
                        <IconButton
                            size="small"
                            color="primary"
                            onClick={(): Promise<void> => handleRemoveItem(args.item)}
                            disabled={args.item.children.length > 0}
                        >
                            <Delete />
                        </IconButton>
                    </>
                ) : (
                    <>
                        {args.item.isDocument && args.item.isOfficial && <Bookmark color={'primary'} />}
                        {args.item.isDocument && <Description color={'primary'} />}
                        {args.item.isDocument && args.item.isApproved && <ThumbUp color={'primary'} />}
                        <Typography>{args.item.text}</Typography>
                    </>
                )}
            </Box>
        </StyledPaper>
    );
}

export default observer(DirectoryNestableComponent);
