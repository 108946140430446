import Axios from '../../axiosConfig';
import { BACKEND_URL } from '../../config';
import { configure, runInAction, makeAutoObservable } from 'mobx';
import { Language, Locale, LocalizedName } from '../../types/localization';
import { TranslationKeys, Translation } from '../../types/translation';

configure({ enforceActions: 'observed' });

export default class LocalizationModule {
    private allLanguages: Language[] = [];

    private currentLanguage: Language = { id: 5, locale: 'fi_FI', name: 'Suomi' };

    private translations: Translation = {} as Translation;

    private allLocalizedNames: LocalizedName[] = [];

    constructor() {
        makeAutoObservable(this, {}, { autoBind: true });
    }

    public async fetchLanguages(): Promise<void> {
        const response = await Axios({
            method: 'GET',
            url: BACKEND_URL + 'app/languages',
        });
        runInAction(() => {
            this.allLanguages = response.data;
            this.currentLanguage = response.data.find((language: Language) => language.locale === 'fi_FI');
        });
    }

    public async fetchTranslations(): Promise<void> {
        const language = this.currentLanguage?.locale || 'default';
        const response = await Axios({
            method: 'GET',
            url: BACKEND_URL + `translation/getTranslationsByLanguage?lang=${language}`,
        });
        if (response?.status === 200) this.setTranslation(response.data);
    }

    public async fetchAllTranslations(): Promise<void> {
        const response = await Axios({
            method: 'GET',
            url: BACKEND_URL + `translation/getAllTranslations`,
        });
        if (response?.status === 200) runInAction(() => (this.allLocalizedNames = response.data));
    }

    public async saveTranslation(translation: LocalizedName): Promise<void> {
        const response = await Axios({
            method: 'POST',
            url: BACKEND_URL + 'translation/save',
            data: translation,
        });
        const savedTranslation: LocalizedName = response.data;
        runInAction(() => {
            this.translations[savedTranslation.key] = savedTranslation[this.locale];
            const previousTranslation = this.allLocalizedNames.find((name) => name.key === savedTranslation.key);
            if (previousTranslation) {
                (Object.keys(previousTranslation) as Array<keyof Omit<LocalizedName, 'id'>>).forEach((key) => {
                    previousTranslation[key] = savedTranslation[key];
                });
            } else this.allLocalizedNames.push(savedTranslation);
        });
    }

    public async removeTranslation(key: string): Promise<void> {
        await Axios({
            method: 'GET',
            url: BACKEND_URL + 'translation/remove?key=' + key,
        });
        runInAction(() => {
            delete this.translations[key];
            this.allLocalizedNames = this.allLocalizedNames.filter((name) => name.key !== key);
        });
    }

    private setTranslation(translation: Array<{ [key: string]: string }>): void {
        this.translations = translation.reduce<Translation>(
            (translations: Translation, entry: { [key: string]: string }) => {
                for (const [key, value] of Object.entries(entry)) {
                    translations[key] = value;
                }
                return translations;
            },
            {} as Translation,
        );
    }

    public changeLanguage(language: Language): void {
        if (this.currentLanguage !== language) {
            this.currentLanguage = language;
            this.fetchTranslations();
        }
    }

    public translate = (key: keyof TranslationKeys): string => {
        return this.translations?.[key] || '';
    };

    /**Can be used when we only have a dynamic translation key. */
    public findTranslation(key: string): string {
        const translations = this.translations as Translation;
        return translations?.[key] || '';
    }

    public get languages(): Language[] {
        return this.allLanguages;
    }

    public getLanguageById(id: number): Language | null {
        const language = this.languages.find((lang) => lang.id === id);
        return language || null;
    }

    public get locale(): Locale {
        return this.currentLanguage?.locale || 'fi_FI';
    }

    public get getCurrentLanguage(): Language {
        return this.currentLanguage;
    }

    public get allTranslations(): LocalizedName[] {
        return this.allLocalizedNames.filter((translation) => translation.key);
    }
}
