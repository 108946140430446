import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { TextFieldEvent } from '../../types/events';
import { LocalizedName } from '../../types/localization';
import ShvkTextField from '../../styled/ShvkTextField';
import DangerButton from '../../styled/DangerButton';
import ShvkButton from '../../styled/ShvkButton';
import useStore from '../../store/storeContext';

interface Props {
    open: boolean;
    onClose(): void;
    translation: LocalizedName | null;
}

interface State {
    id: number;
    key: string;
    fi_FI: string;
    sv_SE: string;
    en_GB: string;
}

type AddTranslationFormField = keyof Omit<LocalizedName, 'id'>;

function AddTranslationDialog(props: Props) {
    const { dialog, localization, snackbar, theming } = useStore();

    const [state, setState] = useState<State>({
        id: 0,
        key: '',
        fi_FI: '',
        sv_SE: '',
        en_GB: '',
    });

    const handleChange = (event: TextFieldEvent, field: AddTranslationFormField): void => {
        setState((state) => ({
            ...state,
            [field]: event.target.value,
        }));
    };

    useEffect(() => {
        props.open && init();
    }, [props.open]);

    function init(): void {
        const { translation } = props;
        setState((state) => ({
            ...state,
            id: translation?.id || 0,
            key: translation?.key || '',
            fi_FI: translation?.fi_FI || translation?.key || '',
            sv_SE: translation?.sv_SE || translation?.key || '',
            en_GB: translation?.en_GB || translation?.key || '',
        }));
    }

    async function save(): Promise<void> {
        if (!state.key) {
            props.onClose();
            return;
        }

        // If key already exists, keep the dialog open and show a message.
        if (!props.translation && localization.allTranslations.some((translation) => translation.key === state.key)) {
            snackbar.showError('KEY_ALREADY_EXISTS');
            return;
        }

        try {
            const translation: LocalizedName = {
                id: state.id,
                key: state.key,
                fi_FI: state.fi_FI,
                sv_SE: state.sv_SE,
                en_GB: state.en_GB,
            };
            await localization.saveTranslation(translation);
            snackbar.showSuccess();
        } catch (error) {
            snackbar.showError(error.data?.code);
        } finally {
            props.onClose();
        }
    }

    async function remove(): Promise<void> {
        try {
            const confirmation = await dialog.getConfirmation();
            if (!confirmation) return;
            await localization.removeTranslation(props.translation!.key);
            snackbar.showSuccess();
        } catch (error) {
            snackbar.showError(error.data?.code);
        }
        props.onClose();
    }

    const translate = localization.translate;
    const { onClose, open, translation } = props;

    return (
        <Dialog open={open} onClose={onClose} maxWidth={'sm'}>
            <DialogTitle>{translate('TRANSLATION')}</DialogTitle>
            <DialogContent dividers>
                {!translation && (
                    <ShvkTextField
                        required
                        id="translation-key-textfield"
                        label={'TRANSLATION_KEY'}
                        multiline
                        rows={3}
                        value={state.key}
                        variant="outlined"
                        size="small"
                        fullWidth
                        style={{ marginBottom: theming.spacing(2) }}
                        onChange={(event): void => handleChange(event, 'key')}
                        name="key"
                    />
                )}
                <ShvkTextField
                    id="translation-fi-textfield"
                    label={translate('FI_TRANSLATION')}
                    multiline
                    rows={3}
                    value={state.fi_FI}
                    variant="outlined"
                    size="small"
                    fullWidth
                    style={{ marginBottom: theming.spacing(2) }}
                    onChange={(event): void => handleChange(event, 'fi_FI')}
                    name="fi"
                />
                <ShvkTextField
                    id="translation-se-textfield"
                    label={translate('SE_TRANSLATION')}
                    multiline
                    rows={3}
                    value={state.sv_SE}
                    variant="outlined"
                    size="small"
                    fullWidth
                    style={{ marginBottom: theming.spacing(2) }}
                    onChange={(event): void => handleChange(event, 'sv_SE')}
                    name="se"
                />
                <ShvkTextField
                    id="translation-en-textfield"
                    label={translate('EN_TRANSLATION')}
                    multiline
                    rows={3}
                    value={state.en_GB}
                    variant="outlined"
                    size="small"
                    fullWidth
                    style={{ marginBottom: theming.spacing(2) }}
                    onChange={(event): void => handleChange(event, 'en_GB')}
                    name="en"
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} variant="contained">
                    {translate('CANCEL')}
                </Button>
                {translation && (
                    <DangerButton variant="contained" onClick={remove}>
                        {translate('REMOVE')}
                    </DangerButton>
                )}
                <Box flexGrow={1} />
                <ShvkButton onClick={save}>{translate('SAVE')}</ShvkButton>
            </DialogActions>
        </Dialog>
    );
}

export default observer(AddTranslationDialog);
