import React, { useState, useEffect } from 'react';
import { observer, useLocalObservable } from 'mobx-react-lite';
import { runInAction } from 'mobx';
import { Box, Button, CircularProgress, Divider, Grid, Paper, Typography } from '@mui/material';
import { Edit, Save } from '@mui/icons-material';
import Directory, { SaveDirectoryParams, SaveDirectoryParamsDirectory } from '../../types/directory';
import { DirectoryNestableItem } from '../../types/nestable';
import { OrganizationDocument } from '../../types/document';
import DirectoryStructure from './DirectoryStructure';
import ShvkButton from '../../styled/ShvkButton';
import useStore from '../../store/storeContext';

interface Props {
    fetching: boolean;
    getData(): Promise<void>;
}

interface State {
    editing: boolean;
    removedDirectoryIds: number[];
}

interface StoreState {
    shvkList: DirectoryNestableItem[];
    evaList: DirectoryNestableItem[];
    anotherList: DirectoryNestableItem[];
}

function Directories(props: Props) {
    const { user, organization, document, directory, localization, snackbar } = useStore();

    const [state, setState] = useState<State>({
        editing: false,
        removedDirectoryIds: [],
    });

    const localStore = useLocalObservable<StoreState>(() => ({
        shvkList: [],
        evaList: [],
        anotherList: [],
    }));

    useEffect(() => {
        init();
    }, []);

    useEffect(() => {
        init();
    }, [props.fetching]);

    function init(): void {
        const { shvkDirectories, evaDirectories, anotherDirectories } = directory;
        const { organizationShvkDocuments, organizationEvaDocuments, organizationAnotherDocuments } = document;

        runInAction(() => {
            localStore.shvkList = createList(shvkDirectories, organizationShvkDocuments);
            localStore.evaList = createList(evaDirectories, organizationEvaDocuments);
            localStore.anotherList = createList(anotherDirectories, organizationAnotherDocuments);
        });
    }

    async function save(): Promise<void> {
        try {
            const organizationId = organization.currentOrganizationId;
            if (!organizationId) return;

            const shvkDirectories = createSaveParamDirectories(localStore.shvkList, 'SHVK');
            const evaDirectories = createSaveParamDirectories(localStore.evaList, 'EVA');
            const anotherDirectories = createSaveParamDirectories(localStore.anotherList, 'ANOTHER');

            const shvkRootDocs = localStore.shvkList
                .filter((doc) => doc.isDocument)
                .map((doc) => {
                    return { id: doc.id };
                });
            const evaRootDocs = localStore.evaList
                .filter((doc) => doc.isDocument)
                .map((doc) => {
                    return { id: doc.id };
                });
            const anotherRootDocs = localStore.anotherList
                .filter((doc) => doc.isDocument)
                .map((doc) => {
                    return { id: doc.id };
                });

            const saveParams: SaveDirectoryParams = {
                newDirectoryStructure: {
                    directories: [...shvkDirectories, ...evaDirectories, ...anotherDirectories],
                    rootDocs: [...shvkRootDocs, ...evaRootDocs, ...anotherRootDocs],
                },
                removedDirectories: state.removedDirectoryIds.map((id) => {
                    return { id };
                }),
            };

            await directory.saveDirectories(saveParams, organizationId);
            await props.getData();
            snackbar.showSuccess();
            setState((state) => ({ ...state, removedDirectoryIds: [] }));
            toggleEditing();
        } catch (error) {
            snackbar.showError(error.data?.code);
        }
    }

    function createSaveParamDirectories(
        list: DirectoryNestableItem[],
        type: 'SHVK' | 'EVA' | 'ANOTHER',
    ): SaveDirectoryParamsDirectory[] {
        return list
            .filter((mainDir) => !mainDir.isDocument)
            .map((mainDir) => {
                return {
                    id: mainDir.id,
                    name: mainDir.text,
                    type,
                    docs: mainDir.children
                        .filter((doc) => doc.isDocument)
                        .map((doc) => {
                            return { id: doc.id };
                        }),
                    subDirs: mainDir.children
                        .filter((subDir) => !subDir.isDocument)
                        .map((subDir) => {
                            return {
                                id: subDir.id,
                                name: subDir.text,
                                type,
                                docs: subDir.children.map((doc) => {
                                    return { id: doc.id };
                                }),
                            };
                        }),
                };
            });
    }

    const toggleEditing = (): void => {
        setState((state) => ({ ...state, editing: !state.editing }));
    };

    function setShvkList(newList: DirectoryNestableItem[]): void {
        runInAction(() => {
            localStore.shvkList = newList;
        });
    }

    function setEvaList(newList: DirectoryNestableItem[]): void {
        runInAction(() => {
            localStore.evaList = newList;
        });
    }

    function setAnotherList(newList: DirectoryNestableItem[]): void {
        runInAction(() => {
            localStore.anotherList = newList;
        });
    }

    function addRemovedDirectoryId(id: number): void {
        setState((state) => ({ ...state, removedDirectoryIds: [...state.removedDirectoryIds, id] }));
    }

    const cancelEditing = (): void => {
        // Revert changes. Create the lists from original (store) data.
        init();
        toggleEditing();
    };

    function createList(directories: Directory[], documents: OrganizationDocument[]): DirectoryNestableItem[] {
        const newList = directories.map((mainDir) => {
            // Create a main directory
            const mainItem = createMainDirectoryItem(mainDir);
            mainItem.children = mainDir.subDirs.map((subDir) => {
                // Create a sub directory
                const subItem = createSubDirectoryItem(subDir, mainDir.id);
                // Push documents to the subdirectory
                documents.forEach((doc) => {
                    if (doc.directoryId === subDir.id) {
                        subItem.children.push(createDocumentItem(doc, 3));
                    }
                });
                return subItem;
            });
            // Push documents to the main directory
            documents.forEach((doc) => {
                if (doc.directoryId === mainDir.id) {
                    mainItem.children.push(createDocumentItem(doc, 2));
                }
            });
            return mainItem;
        });

        // Push root level documents to the end of the list
        documents.forEach((doc) => {
            if (!doc.directoryId) {
                newList.push(createDocumentItem(doc, 1));
            }
        });

        return newList;
    }

    function createMainDirectoryItem(directory: Directory): DirectoryNestableItem {
        return {
            id: directory.id,
            text: directory.name,
            isDocument: false,
            parentId: null,
            depth: 1,
            children: [],
        };
    }

    function createSubDirectoryItem(directory: Directory, parentId: number): DirectoryNestableItem {
        return {
            id: directory.id,
            text: directory.name,
            isDocument: false,
            parentId: parentId,
            depth: 2,
            children: [],
        };
    }

    function createDocumentItem(document: OrganizationDocument, depth: number): DirectoryNestableItem {
        return {
            id: document.id,
            text: document.name,
            isDocument: true,
            isApproved: document.statusCode === 'STATUS_COMPLETE',
            isOfficial: document.isOfficial,
            parentId: document.directoryId,
            depth,
            children: [],
        };
    }

    const { translate } = localization;

    return (
        <Paper>
            <Box px={2} py={1} textAlign="center">
                <Typography variant="h6">{translate('DOCUMENTS_AND_DIRECTORIES')}</Typography>
            </Box>
            <Divider />
            <Box p={2}>
                {props.fetching ? (
                    <Box textAlign="center">
                        <CircularProgress />
                    </Box>
                ) : (
                    <Grid container spacing={2} justifyContent="space-between">
                        {user.isAuthorized('directory_management', 'modify_directories') ? (
                            !state.editing ? (
                                <Grid item xs={12}>
                                    <ShvkButton onClick={toggleEditing} endIcon={<Edit />}>
                                        {translate('EDIT_DIRECTORY_STRUCTURE')}
                                    </ShvkButton>
                                </Grid>
                            ) : (
                                <>
                                    <Grid item>
                                        <Button variant="contained" onClick={cancelEditing}>
                                            {translate('CANCEL')}
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <ShvkButton onClick={save} endIcon={<Save />}>
                                            {translate('SAVE')}
                                        </ShvkButton>
                                    </Grid>
                                </>
                            )
                        ) : null}
                        <Grid item xs={12}>
                            <Typography>{translate('SHVK_DOCUMENTS')}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <DirectoryStructure
                                directories={directory.shvkDirectories}
                                documents={document.organizationShvkDocuments}
                                editing={state.editing}
                                setList={setShvkList}
                                list={localStore.shvkList}
                                addRemovedDirectoryId={addRemovedDirectoryId}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography>{translate('EVA_DOCUMENTS')}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <DirectoryStructure
                                directories={directory.evaDirectories}
                                documents={document.organizationEvaDocuments}
                                editing={state.editing}
                                setList={setEvaList}
                                list={localStore.evaList}
                                addRemovedDirectoryId={addRemovedDirectoryId}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography>{translate('ANOTHER_DOCUMENTS')}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <DirectoryStructure
                                directories={directory.anotherDirectories}
                                documents={document.organizationAnotherDocuments}
                                editing={state.editing}
                                setList={setAnotherList}
                                list={localStore.anotherList}
                                addRemovedDirectoryId={addRemovedDirectoryId}
                            />
                        </Grid>
                    </Grid>
                )}
            </Box>
        </Paper>
    );
}

export default observer(Directories);
