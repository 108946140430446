import { configure, makeAutoObservable } from 'mobx';
import {
    DocumentDialogList,
    DocumentDialogName,
    DocumentDialogProps,
    NewDocumentDialogProperties,
} from '../../types/dialog';
import { PublishedDocument } from '../../types/document';
import RootModule from '../rootModule';

configure({ enforceActions: 'observed' });

export default class DialogModule {
    private rootModule: RootModule;
    /**
     *
     * General confirmation dialog
     *
     */
    private showConfirmDialog = false;
    private confirmTitle = '';
    private confirmText = '';
    private confirmButtonText = '';
    private isGeneralDialog = false;
    private requireCheckbox = false;
    private showResetPasswordDialog = false;
    private showLoginDialog = false;
    private showVersionInfoDialog = false;
    private showPublishedOrganizationDocumentsDialog = false;
    private showLanguageChangedDialog = false;
    private selectedOrganization = '';
    private filteredDocuments: PublishedDocument[] = [];
    private newDocumentDialogType: 'SHVK' | 'EVA' | 'ANOTHER_REPORT' = 'SHVK';
    private newDocumentDialogOpen = false;
    private newDocumentFromPopover = false;

    constructor(rootModule: RootModule) {
        makeAutoObservable(this, {}, { autoBind: true });
        this.rootModule = rootModule;
    }

    public get getFilteredDocuments(): PublishedDocument[] {
        return this.filteredDocuments;
    }

    public get getSelectedOrganization(): string {
        return this.selectedOrganization;
    }

    public get isPublishedOrganizationDocumentsDialogOpen(): boolean {
        return this.showPublishedOrganizationDocumentsDialog;
    }

    public get isVersionInfoDialogOpen(): boolean {
        return this.showVersionInfoDialog;
    }

    public get isLanguageChangedDialogOpen(): boolean {
        return this.showLanguageChangedDialog;
    }

    public openVersionInfoDialog(): void {
        this.showVersionInfoDialog = true;
    }

    public closeVersionInfoDialog(): void {
        this.showVersionInfoDialog = false;
    }

    public openLanguageChangedDialog(): void {
        this.showLanguageChangedDialog = true;
    }

    public closeLanguageChangedDialog(): void {
        this.showLanguageChangedDialog = false;
    }

    public openPublishedOrganizationDocumentsDialog(
        organization: string,
        filteredDocuments: PublishedDocument[],
    ): void {
        this.selectedOrganization = organization;
        this.filteredDocuments = filteredDocuments;
        this.showPublishedOrganizationDocumentsDialog = true;
    }

    public closePublishedOrganizationDocumentsDialog(): void {
        this.selectedOrganization = '';
        this.filteredDocuments = [];
        this.showPublishedOrganizationDocumentsDialog = false;
    }

    private setTitle(title: string): void {
        this.confirmTitle = title;
    }

    private setText(text: string): void {
        this.confirmText = text;
    }

    private setConfirmButtonText(text: string): void {
        this.confirmButtonText = text;
    }

    private setIsGeneralDialog(flag: boolean): void {
        this.isGeneralDialog = flag;
    }

    public get isResetPasswordDialogOpen(): boolean {
        return this.showResetPasswordDialog;
    }

    public openResetPasswordDialog(): void {
        this.showResetPasswordDialog = true;
    }

    public closeResetPasswordDialog(): void {
        this.showResetPasswordDialog = false;
    }

    public get isLoginDialogOpen(): boolean {
        return this.showLoginDialog;
    }

    public openLoginDialog(): void {
        this.showLoginDialog = true;
    }

    public closeLoginDialog(): void {
        this.showLoginDialog = false;
    }

    public openConfirmDialog(): void {
        this.showConfirmDialog = true;
    }

    public closeConfirmDialog(): void {
        this.showConfirmDialog = false;
    }

    public get isDeleteDialogOpen(): boolean {
        return this.showConfirmDialog;
    }

    public get confirmDialogText(): string {
        return this.confirmText;
    }

    public get confirmDialogTitle(): string {
        return this.confirmTitle;
    }

    public get confirmDialogButtonText(): string {
        return this.confirmButtonText;
    }

    public get isGeneralConfirmDialog(): boolean {
        return this.isGeneralDialog;
    }

    public get requireCheckboxConfirmation(): boolean {
        return this.requireCheckbox;
    }

    // Delete confirmation uses promise, which returns true or false.
    public resolve!: (value: boolean | PromiseLike<boolean>) => void;

    /** getConfirmation usage:
     *
     * Confirm dialog is deletion dialog by default, provide args for customization.
     * To get general confirmation dialog, provide all args. isGeneral needs to be true.
     *
     */
    public getConfirmation(
        title?: string,
        text?: string,
        requireCheckbox?: boolean,
        isGeneral?: boolean,
        buttonText?: string,
    ): Promise<boolean> {
        if (title) this.setTitle(title);
        else this.setTitle(this.rootModule.localization.translate('GENERAL_CONFIRMATION_TITLE'));

        if (text) this.setText(text);
        else this.setText(this.rootModule.localization.translate('GENERAL_CONFIRMATION_MESSAGE'));

        if (buttonText) this.setConfirmButtonText(buttonText);
        else this.setConfirmButtonText(this.rootModule.localization.translate('REMOVE'));

        if (isGeneral) this.setIsGeneralDialog(true);
        else this.setIsGeneralDialog(false);

        this.requireCheckbox = !!requireCheckbox;

        this.openConfirmDialog();

        return new Promise<boolean>((resolve) => {
            this.resolve = resolve;
        });
    }

    /**
     *
     * Document dialogs
     *
     */
    private documentDialogs: DocumentDialogList = {
        // Dialogs opened from document options popover
        createShvkDialog: { open: false },
        createEvaDialog: { open: false },
        downloadPdfDialog: { open: false },
        downloadWordDialog: { open: false },
        downloadPowerPointDialog: { open: false },
        shareDialog: { open: false },
        previousSharesDialog: { open: false },
        historyDialog: { open: false },
        usersDialog: { open: false },
        changeOwnerDialog: { open: false },
        chapterModelDialog: { open: false },
        coverPageDialog: { open: false },
        approvalDialog: { open: false },
        disapprovalDialog: { open: false },
        // Shvk
        comparisonSettingsDialog: { open: false },
        indicatorModelDialog: { open: false },
        focusAreaModelDialog: { open: false },
        crossOrganizationDialog: { open: false },
        // Eva
        editEvaModelDialog: { open: false },
        evaDecisionOptionCompareDialog: { open: false },

        // Dialogs opened from chapters
        editChapterDialog: { open: false },
        addIndicatorDialog: { open: false },
        // Shvk
        editIndicatorListDialog: { open: false },
        uploadCustomIndicatorDialog: { open: false },
        indicatorGraphDialog: { open: false },
        indicatorWithNoteDialog: { open: false },
        indicatorDescriptionDialog: { open: false },
        addFocusAreaDialog: { open: false },
        addFocusAreaGoalDialog: { open: false },
        addFocusAreaActionDialog: { open: false },
        // Eva
        addDecisionOptionDialog: { open: false },
        addPerspectiveDialog: { open: false },
        addImpactOnTargetDialog: { open: false },
        editImpactOnTargetNameDialog: { open: false },
        impactOnTargetDialog: { open: false },
        evaIndicatorListDialog: { open: false },
    };

    public get documentDialogList(): DocumentDialogList {
        return this.documentDialogs;
    }

    public closeDocumentDialog(dialog: DocumentDialogName): void {
        this.documentDialogs[dialog].open = false;
    }

    public openDocumentDialog(dialog: DocumentDialogName, props?: DocumentDialogProps): void {
        this.documentDialogs[dialog].props = props;
        this.documentDialogs[dialog].open = true;
    }

    public get newDocumentDialogProperties(): NewDocumentDialogProperties {
        return {
            type: this.newDocumentDialogType,
            open: this.newDocumentDialogOpen,
            fromPopover: this.newDocumentFromPopover,
        };
    }

    public openNewDocumentDialog(type: 'SHVK' | 'EVA' | 'ANOTHER_REPORT', fromPopover = false): void {
        this.newDocumentDialogType = type;
        this.newDocumentFromPopover = fromPopover;
        this.newDocumentDialogOpen = true;
    }

    public closeNewDocumentDialog(): void {
        this.newDocumentDialogOpen = false;
    }
}
