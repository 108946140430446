import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import {
    Box,
    Button,
    Card,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Table,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
} from '@mui/material';
import { Info, Print, Star } from '@mui/icons-material';
import { Indicator, IndicatorPackageTheme, } from '../../../../types/indicator';
import { DocumentDialogProps } from '../../../../types/dialog';
import ShvkButton from '../../../../styled/ShvkButton';
import EditIndicatorDialogBody from "../../shvk/EditIndicatorListBody";
import useStore from '../../../../store/storeContext';

interface Props {
    isOpen: boolean;
    indicatorPackageTheme?: IndicatorPackageTheme | null;

    close(): void;
}

interface State {
    enabledIndicators: Indicator[];
    disabledIndicators: Indicator[];
    dialogTitle: string;
    isLoaded: boolean;
}

function EditIndicatorListDialog(props: Props) {
    const context = useStore();
    const { document, dialog, localization, theming } = context;

    const [state, setState] = useState<State>({
        enabledIndicators: [],
        disabledIndicators: [],
        dialogTitle: '',
        isLoaded: false,
    });

    useEffect(() => {
        props.isOpen && init();
        return () => {
            setState((state) => ({
                ...state,
                enabledIndicators: [],
                disabledIndicators: [],
                dialogTitle: '',
                isLoaded: false
            }));
        };
    }, [props.isOpen]);

    useEffect(() => {
        if (state.isLoaded) {
            const allIndicators = getIndicators();
            setState((state) => ({
                ...state,
                enabledIndicators: allIndicators.filter(x => x.enabled),
                disabledIndicators: allIndicators.filter(x => !x.enabled)
            }));
        }
    }, [
        props.indicatorPackageTheme?.sotkanetIndicators.length,
        props.indicatorPackageTheme?.organizationIndicators.length,
        props.indicatorPackageTheme?.otherIndicators.length,
    ]);

    function init() {
        const allIndicators = getIndicators();
        setState((state) => ({
            ...state,
            enabledIndicators: allIndicators.filter(x => x.enabled),
            disabledIndicators: allIndicators.filter(x => !x.enabled),
            dialogTitle: getDialogTitle(),
            isLoaded: true
        }));
    }

    function getIndicators(): Indicator[] {
        if (!props.indicatorPackageTheme) return [];
        const allIndicators = [
            ...props.indicatorPackageTheme.sotkanetIndicators,
            ...props.indicatorPackageTheme.organizationIndicators,
            ...props.indicatorPackageTheme.otherIndicators,
        ];

        return context.indicator.sortIndicatorsByEnabled(allIndicators);
    }

    function updateIndicatorList(): void {
        const allIndicators = getIndicators();
        setState((state) => ({
            ...state,
            enabledIndicators: allIndicators.filter(x => x.enabled),
            disabledIndicators: allIndicators.filter(x => !x.enabled)
        }));
    }

    function getDialogTitle(): string {
        if (!props.indicatorPackageTheme) return '';
        const indicatorPackage = context.indicator.indicatorPackageById(props.indicatorPackageTheme.pkgId);
        return indicatorPackage?.name + ' - ' + props.indicatorPackageTheme.name;
    }

    const addDialogProps = (): DocumentDialogProps => {
        return {
            indicatorPackageTheme: props.indicatorPackageTheme,
        };
    };

    const { translate } = localization;
    const { isAnotherReport, hasSummary } = document;

    return (
        <Dialog
            transitionDuration={{ exit: 0 }}
            open={props.isOpen && state.isLoaded}
            onClose={props.close}
            aria-labelledby="edit-indicator-list-dialog-title"
            maxWidth={'md'}
            fullWidth
        >
            <DialogTitle id="edit-indicator-list-dialog-title">{state.dialogTitle}</DialogTitle>
            <DialogContent dividers>
                <TableContainer component={Card} variant="outlined">
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell style={{
                                    width: "9%",
                                    textTransform: 'capitalize'
                                }}>{translate('SELECTED')}</TableCell>
                                <TableCell style={{
                                    width: "41%",
                                    textTransform: 'capitalize'
                                }}>{translate('INDICATOR')}</TableCell>
                                <TableCell>{translate('INDICATOR_TYPE')}</TableCell>
                                <TableCell>
                                    <Box display="flex" alignItems="center" justifyContent="center">
                                        {translate('VALUATION')}
                                        <Tooltip
                                            title={translate('arvotus_otsikko_ind_muokkaustila_8')}
                                            placement={'top'}
                                        >
                                            <Info
                                                color="primary"
                                                fontSize="small"
                                                style={{ marginLeft: theming.spacing(0.5) }}
                                            />
                                        </Tooltip>
                                    </Box>
                                </TableCell>
                                <TableCell padding="checkbox" align="right">
                                    <Box display="flex" justifyContent="center">
                                        <Tooltip
                                            title={translate('INDICATORLIST_PRINTER_ICON_TOOLTIP')}
                                            placement={'top'}
                                        >
                                            <Print color="primary" />
                                        </Tooltip>
                                    </Box>
                                </TableCell>
                                {!isAnotherReport && hasSummary && (
                                    <TableCell padding="checkbox" align="right">
                                        <Box display="flex" justifyContent="center">
                                            <Tooltip
                                                title={translate('INDICATORLIST_STAR_ICON_TOOLTIP')}
                                                placement={'top'}
                                            >
                                                <Star color="primary" />
                                            </Tooltip>
                                        </Box>
                                    </TableCell>
                                )}
                                <TableCell padding="checkbox" />
                            </TableRow>
                        </TableHead>
                        {state.enabledIndicators.length > 0 && (
                            <EditIndicatorDialogBody
                                indicatorList={state.enabledIndicators}
                                indicatorPackageTheme={props.indicatorPackageTheme}
                                updateIndicatorList={updateIndicatorList}
                            />
                        )}
                    </Table>
                </TableContainer>
                {state.enabledIndicators.length > 0 && state.disabledIndicators.length > 0 && (
                    <Box py={1}>
                        <Divider variant={"middle"} />
                    </Box>
                )}
                {state.disabledIndicators.length > 0 && (

                    <TableContainer component={Card} variant="outlined">
                        <Table size="small">
                            <EditIndicatorDialogBody
                                indicatorList={state.disabledIndicators}
                                indicatorPackageTheme={props.indicatorPackageTheme}
                                updateIndicatorList={updateIndicatorList}
                            />
                        </Table>
                    </TableContainer>
                )}
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={props.close}>
                    {translate('CLOSE')}
                </Button>
                <Box flexGrow={1} />
                <ShvkButton
                    onClick={(): void => dialog.openDocumentDialog('uploadCustomIndicatorDialog', addDialogProps())}
                >
                    {translate('ADD_CUSTOM_INDICATOR')}
                </ShvkButton>
                <ShvkButton onClick={(): void => dialog.openDocumentDialog('addIndicatorDialog', addDialogProps())}>
                    {translate('ADD_INDICATOR')}
                </ShvkButton>
            </DialogActions>
        </Dialog>
    )
}

export default observer(EditIndicatorListDialog);
