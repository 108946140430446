import React from 'react';
import { createRoot } from 'react-dom/client';
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/js/plugins.pkgd.min.js';
import 'froala-editor/js/languages/fi.js';
import 'froala-editor/js/languages/sv.js';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { StoreProvider } from './store/storeContext';

createRoot(document.getElementById('root')!).render(
    <StoreProvider>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </StoreProvider>,
);
