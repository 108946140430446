import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { Box, Button, ButtonGroup, Dialog, DialogActions, DialogContent, Typography } from '@mui/material';
import { Edit, LockOpen } from '@mui/icons-material';
import { Indicator, IndicatorPackage } from '../../../../types/indicator';
import IndicatorTableRow from '../../shvk/IndicatorTableRow';
import FroalaIndicatorNote from '../../editor/FroalaIndicatorNote';
import IndicatorPackageThemeItemTable from '../../shvk/IndicatorPackageThemeItemTable';
import useStore from '../../../../store/storeContext';

interface Props {
    indicator?: Indicator | null;
    indicatorPackage?: IndicatorPackage | null;
    isOpen: boolean;
    close(): void;
    editing?: boolean;
    toggleEdit?(): void;
}

interface State {
    isEditing: boolean;
}

function IndicatorWithNoteDialog(props: Props) {
    const { localization, snackbar, theming } = useStore();

    const [state, setState] = useState<State>({
        isEditing: false,
    });

    useEffect(() => {
        props.isOpen && setState((state) => ({ ...state, isEditing: props.editing || false }));
    }, [props.isOpen]);

    const handleEdit = (): void => {
        if (!props.toggleEdit) return;
        try {
            props.toggleEdit();
            setState((state) => ({ ...state, isEditing: !state.isEditing }));
        } catch (error) {
            snackbar.showError(error.data?.code);
        }
    };

    const handleClose = (): void => {
        if (!props.toggleEdit) return;
        state.isEditing && props.toggleEdit();
        props.close();
    };

    const { translate } = localization;

    return (
        <Dialog open={props.isOpen} onClose={props.close} maxWidth={'lg'} fullWidth>
            <DialogContent dividers>
                {props.indicator && (
                    <IndicatorPackageThemeItemTable>
                        <IndicatorTableRow
                            indicator={props.indicator}
                            editing={props.editing}
                            toggleEdit={props.toggleEdit}
                        />
                    </IndicatorPackageThemeItemTable>
                )}
                <Box display="flex">
                    <Box flexGrow={1}>
                        <Typography variant="h6">
                            {translate('SUMMARY')} - {props.indicatorPackage?.name}
                        </Typography>
                    </Box>
                    <ButtonGroup id="button-group" color="primary" size="small">
                        <Button
                            onClick={handleEdit}
                            style={{
                                color: !state.isEditing ? theming.palette.primary.main : theming.palette.error.main,
                            }}
                        >
                            {!state.isEditing ? <Edit /> : <LockOpen />}
                        </Button>
                    </ButtonGroup>
                </Box>
                <Typography variant="caption">
                    <i>
                        {translate('CHAPTER_LAST_UPDATED')} {props.indicatorPackage?.editor}{' '}
                        {moment(props.indicatorPackage?.lastUpdated).format('DD.MM.YYYY HH:mm')}
                    </i>
                </Typography>
                {state.isEditing && props.indicatorPackage ? (
                    <FroalaIndicatorNote indicatorPackage={props.indicatorPackage} />
                ) : (
                    <Typography
                        className="fr-view"
                        dangerouslySetInnerHTML={{ __html: props.indicatorPackage?.notes || '' }}
                    />
                )}
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={handleClose}>
                    {translate('CLOSE')}
                </Button>
                <Box flexGrow={1} />
            </DialogActions>
        </Dialog>
    );
}

export default observer(IndicatorWithNoteDialog);
