import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Collapse, IconButton, TableCell, TableRow, Tooltip, Typography } from '@mui/material';
import { History } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { DocumentHistoryItem, DocumentHistoryTitle } from '../../../types/document';
import moment from 'moment';
import useStore from '../../../store/storeContext';

const PREFIX = 'HistoryDialogRow';

const classes = {
    root: `${PREFIX}-root`,
    collapseCell: `${PREFIX}-collapseCell`,
};

const StyledTableRow = styled(TableRow)({
    [`& .${classes.root}`]: {
        '& > *': {
            borderBottom: 'unset',
        },
        cursor: 'pointer',
    },
});

interface Props {
    historyTitle: DocumentHistoryTitle;
}

interface State {
    extended: boolean;
    historyContent: DocumentHistoryItem | null;
}

function HistoryDialogRow(props: Props) {
    const { document, dialog, localization, snackbar, loadingIndicator } = useStore();

    const [state, setState] = useState<State>({
        extended: false,
        historyContent: null,
    });

    async function toggleCollapse(): Promise<void> {
        if (state.historyContent === null) {
            try {
                const response = await document.fetchHistoryById(props.historyTitle.id);
                setState((state) => ({ ...state, historyContent: response }));
            } catch (error) {
                snackbar.showFetchFailedMessage(error.data?.code);
            }
        }

        setState((state) => ({ ...state, extended: !state.extended }));
    }

    async function restore(): Promise<void> {
        const { getConfirmation } = dialog;
        const { restoreDeletedChapter } = document;
        const { translate } = localization;
        try {
            if (state.historyContent) {
                const confirmation = await getConfirmation(
                    translate('RESTORE_CHAPTER_TITLE'),
                    translate('RESTORE_CHAPTER_TIP') + `: ${state.historyContent.chapter.title}`,
                    false,
                    true,
                    translate('RESTORE_DOCUMENT'),
                );
                if (confirmation) {
                    loadingIndicator.show();
                    await restoreDeletedChapter(state.historyContent.chapter.id, state.historyContent.id);
                    snackbar.showSuccess('SUCCESS');
                }
            }
        } catch (e) {
            snackbar.showError(e?.data?.code);
        } finally {
            loadingIndicator.hide();
        }
    }

    const chapterIsDeletedOrRestored = () => {
        const { chapters } = document.currentDocument;
        if (!state.historyContent?.deleted) {
            return true;
        } else if (state.historyContent?.deleted && state.historyContent?.chapter) {
            const historyChapterId = state.historyContent.chapter.id;
            return chapters.map((chp) => chp.id).includes(historyChapterId);
        } else return false;
    };
    const { translate } = localization;
    const { historyTitle } = props;
    return (
        <>
            <StyledTableRow className={classes.root} hover onClick={toggleCollapse}>
                <TableCell>{historyTitle.user.userName}</TableCell>
                <TableCell align="right">{moment(historyTitle?.date).format('DD.MM.YYYY HH:mm:ss')}</TableCell>
            </StyledTableRow>
            <StyledTableRow>
                <TableCell sx={{ width: '95%', borderBottom: 'unset', pl: 4, py: 0 }}>
                    <Collapse in={state.extended} timeout="auto" unmountOnExit>
                        <Typography
                            className="fr-view"
                            dangerouslySetInnerHTML={{ __html: state.historyContent?.change.content || '' }}
                            sx={{ mt: 1 }}
                        />
                    </Collapse>
                </TableCell>
                <TableCell sx={{ borderBottom: 'unset', pl: 4, py: 0 }} align="right">
                    <Collapse in={state.extended} timeout="auto" unmountOnExit>
                        <IconButton
                            size="small"
                            color="primary"
                            onClick={restore}
                            disabled={!state.historyContent?.chapter || chapterIsDeletedOrRestored()}
                        >
                            <Tooltip title={translate('RESTORE_DOCUMENT')}>
                                <History />
                            </Tooltip>
                        </IconButton>
                    </Collapse>
                </TableCell>
            </StyledTableRow>
        </>
    );
}

export default observer(HistoryDialogRow);
