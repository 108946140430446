import React from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Button, ListItem, ListItemText, Typography } from '@mui/material';
import { truncateWithEllipsis } from '../../utils/utils';
import NewsArticle from '../../types/news';
import moment from 'moment';

interface Props {
    toggleArticleDialog: (article: NewsArticle) => void;
    newsArticle: NewsArticle;
}

function NewsRow(props: Props) {
    const { toggleArticleDialog, newsArticle } = props;
    const contentString =
        newsArticle.content.length <= 200 ? newsArticle.content : truncateWithEllipsis(newsArticle.content, 200);

    return (
        <ListItem>
            <ListItemText
                disableTypography
                primary={
                    <Button
                        sx={{ p: 0, cursor: 'pointer', justifyContent: 'flex-start' }}
                        component="h5"
                        onClick={(): void => toggleArticleDialog(newsArticle)}
                    >
                        {newsArticle.title}
                    </Button>
                }
                secondary={
                    <Box display="flex" flexDirection="column" component="span">
                        <Typography variant="caption" sx={{ mt: 0.5 }}>
                            {moment(newsArticle.dateCreated).format('DD.MM.YYYY hh:mm')}
                        </Typography>
                        <Typography
                            variant="body2"
                            color="textPrimary"
                            sx={{ mt: 2 }}
                            dangerouslySetInnerHTML={{
                                __html: contentString,
                            }}
                        />
                    </Box>
                }
            />
        </ListItem>
    );
}

export default observer(NewsRow);
