import React, { ChangeEvent, useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { styled } from '@mui/material/styles';
import {
    Autocomplete,
    Box,
    Button,
    Card,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControlLabel,
    FormLabel,
    Grid,
    Radio,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tabs,
    Typography,
    Pagination,
    Tooltip,
} from '@mui/material';
import { Check } from '@mui/icons-material';
import { TabContext, TabPanel } from '@mui/lab';
import { UserManagementUser, UserManagementUserSaveRequestParams } from '../../types/user';
import { OrganizationWithRole, AdminOrganization } from '../../types/organization';
import { OrganizationDocument } from '../../types/document';
import { TextFieldEvent } from '../../types/events';
import ShvkNotificationBox from '../ShvkNotificationBox';
import DangerIconButton from '../../styled/DangerIconButton';
import ShvkTextField from '../../styled/ShvkTextField';
import DangerButton from '../../styled/DangerButton';
import ShvkButton from '../../styled/ShvkButton';
import DeleteIcon from '@mui/icons-material/Delete';
import useStore from '../../store/storeContext';
import InfoIcon from '@mui/icons-material/Info';

const PREFIX = 'AddUserDialog';

const classes = {
    cell: `${PREFIX}-cell`,
    tabPanel: `${PREFIX}-tabPanel`,
    tableContainerCard: `${PREFIX}-tableContainerCard`,
};

const Root = styled('div')({
    [`& .${classes.cell}`]: {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    },

    [`& .${classes.tabPanel}`]: {
        boxSizing: 'border-box',
        height: '100%',
    },

    [`& .${classes.tableContainerCard}`]: {
        overflowY: 'auto',
        height: '100%',
        flexGrow: 1,
    },
});

interface Props {
    open: boolean;
    onClose(): void;
    user: UserManagementUser | null;
}

interface State {
    firstName: string;
    lastName: string;
    userName: string;
    profession: string;
    unitOrBranch: string;
    password1: string;
    password2: string;
    organizationInputValue: string;
    homeOrganizationId: string;
    enabled: boolean;
    page: number;
}

type UserDialogFormField =
    | 'firstName'
    | 'lastName'
    | 'userName'
    | 'profession'
    | 'unitOrBranch'
    | 'password1'
    | 'password2'
    | 'organization'
    | 'homeOrganizationId';

type TabName = 'BASIC_INFO' | 'PASSWORD' | 'ORGANIZATIONS' | 'ACCOUNT_INFO' | 'WORK_GROUPS';

const itemsPerPage: Readonly<number> = 10;

function AddUserDialog(props: Props) {
    const context = useStore();

    const [state, setState] = useState<State>({
        firstName: '',
        lastName: '',
        userName: '',
        profession: '',
        unitOrBranch: '',
        password1: '',
        password2: '',
        organizationInputValue: '',
        homeOrganizationId: '',
        enabled: true,
        page: 1,
    });

    const [selectedOrganizations, setSelectedOrganizations] = useState<OrganizationWithRole[]>([]);
    const [workGroupDocuments, setWorkGroupDocuments] = useState<OrganizationDocument[]>([]);
    const [fieldFocus, setFieldFocus] = useState<UserDialogFormField | undefined>(undefined);
    const [activeTab, setActiveTab] = useState<TabName>('BASIC_INFO');

    useEffect(() => {
        props.open && void init();
        return () => setActiveTab('BASIC_INFO');
    }, [props.open]);

    async function init(): Promise<void> {
        setState((state) => ({
            ...state,
            firstName: props.user?.firstName || '',
            lastName: props.user?.lastName || '',
            userName: props.user?.userName || '',
            profession: props.user?.profession || '',
            unitOrBranch: props.user?.unitOrBranch || '',
            password1: '',
            password2: '',
            homeOrganizationId: props.user?.homeOrganizationId?.toString() || '',
            enabled: props.user ? props.user.enabled : true,
            page: 1,
        }));
        setSelectedOrganizations(props.user?.organizations || []);

        if (props.user?.organizations) {
            setWorkGroupDocuments(await getOrganizationDocuments(props.user.organizations, props.user.id));
        } else {
            setWorkGroupDocuments([]);
        }
    }

    async function getOrganizationDocuments(
        organizations: OrganizationWithRole[],
        userId?: number,
    ): Promise<OrganizationDocument[]> {
        const organizationIds = organizations.map((o) => o.id);
        let organizationDocuments: OrganizationDocument[] = [];
        if (organizationIds.length > 0) {
            organizationDocuments = await context.document.fetchDocumentsForOrganizations(organizationIds, userId);
        }
        return organizationDocuments;
    }

    async function save(): Promise<void> {
        try {
            const userName = state.userName.toLocaleLowerCase();
            const homeOrganizationName =
                selectedOrganizations.find((org) => org.id === Number(state.homeOrganizationId))?.name || '';
            const documentIds = workGroupDocuments.reduce((ids: number[], doc) => {
                if (doc.workgroup) {
                    ids.push(doc.id);
                }
                return ids;
            }, []);

            const params: UserManagementUserSaveRequestParams = {
                accountExpired: false,
                accountLocked: false,
                enabled: state.enabled,
                firstName: state.firstName,
                lastName: state.lastName,
                password: state.password1,
                unitOrBranch: state.unitOrBranch,
                profession: state.profession,
                homeOrganizationId: Number(state.homeOrganizationId),
                homeOrganization: homeOrganizationName,
                organizations: selectedOrganizations,
                userName,
                documents: documentIds,
                id: props.user?.id || -1,
                passwordExpired: false,
            };
            if (props.user) {
                await context.user.updateUser(params);
            } else {
                await context.user.addUser(params);
            }
            context.snackbar.showSuccess();
        } catch (error) {
            context.snackbar.showError(error.data?.code);
        } finally {
            props.onClose();
        }
    }

    async function remove(): Promise<void> {
        try {
            const { translate } = context.localization;

            const title = translate('REMOVE_USER_CONFIRM_TITLE');
            const text = translate('REMOVE_USER_CONFIRM_MESSAGE');

            const confirmation = await context.dialog.getConfirmation(title, text);
            if (!confirmation) return;

            await context.user.deleteUser(state.userName);
            context.snackbar.showSuccess();
        } catch (error) {
            context.snackbar.showError(error.data?.code);
        }
        props.onClose();
    }

    const handleTextChange = (event: TextFieldEvent, field: UserDialogFormField): void => {
        setState((state) => ({
            ...state,
            [field]: event.target.value,
        }));
    };

    const handleFocusChange = (field: UserDialogFormField | undefined): void => {
        setFieldFocus(field);
    };

    const handleTabChange = (_event: React.ChangeEvent<unknown>, newIndex: TabName): void => {
        setActiveTab(newIndex);
    };

    const handlePageChange = (_event: React.ChangeEvent<unknown>, value: number): void => {
        setState((state) => ({
            ...state,
            page: value,
        }));
    };

    const handleOrganizationInputValueChange = (_event: ChangeEvent<unknown>, newInputValue: string): void => {
        setState((state) => ({
            ...state,
            organizationInputValue: newInputValue,
        }));
    };

    async function handleOrganizationValueChange(
        _event: ChangeEvent<unknown>,
        newValue: AdminOrganization | null,
    ): Promise<void> {
        if (newValue) {
            // Organizations in the autocomplete and the selected item list have different types.
            // Change the new item to the correct type and add to the list.
            const orgWithRole: OrganizationWithRole = {
                id: newValue.id,
                name: newValue.name,
                isAdmin: false,
            };
            const newOrganizations = [...selectedOrganizations, orgWithRole];
            setSelectedOrganizations(newOrganizations);
            setState((state) => ({
                ...state,
                organizationInputValue: '',
                homeOrganizationId: state.homeOrganizationId === '' ? newValue.id.toString() : state.homeOrganizationId,
            }));
            const newWorkGroupDocuments = await getOrganizationDocuments([orgWithRole]);
            setWorkGroupDocuments(workGroupDocuments.concat(newWorkGroupDocuments));
        }
    }

    const handleAdminCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, organizationId: number): void => {
        const checked = selectedOrganizations.map((organization) => {
            if (organization.id === organizationId) {
                organization.isAdmin = event.target.checked;
                return organization;
            }
            return organization;
        });
        setSelectedOrganizations(checked);
    };

    const toggleWorkgroupSelection = (document: OrganizationDocument): void => {
        if (hasCurrentUserAdminRightsToDocument(document)) {
            const toggled = workGroupDocuments.map((doc) => {
                if (doc.id === document.id) {
                    doc.workgroup = !doc.workgroup;
                    return doc;
                }
                return doc;
            });
            setWorkGroupDocuments(toggled);
        }
    };

    function removeUserOrganization(organizationId: number): void {
        const organizationRemoved = selectedOrganizations.filter((org) => org.id !== organizationId);
        const workgroupRemoved = workGroupDocuments.filter((doc) => doc.organizationId !== organizationId);
        setSelectedOrganizations(organizationRemoved);
        setWorkGroupDocuments(workgroupRemoved);
    }

    function isSaveButtonDisabled(): boolean {
        const { validatePassword, checkPasswordMatch, validateUserName } = context.user;

        // Username needs to be always valid
        const userNameValid = validateUserName(state.userName);
        // Name needs to be always valid
        const nameValid = Boolean(state.firstName) && Boolean(state.lastName);
        // User needs home organization
        const organizationValid = selectedOrganizations.length > 0 && Boolean(state.homeOrganizationId);

        let passwordValid: boolean;
        if (props.user && (state.password1 || state.password2)) {
            // If we have a user (editing) and a password (changing password) -> password needs to be valid
            passwordValid = validatePassword(state.password1) && checkPasswordMatch(state.password1, state.password2);
        } else if (props.user) {
            // If we have a user (editing) but no password, no need to validate.
            passwordValid = true;
        } else {
            // If we don't have a user (creating a new user) -> password needs to be valid
            passwordValid =
                (Boolean(state.password1) || Boolean(state.password2)) &&
                validatePassword(state.password1) &&
                checkPasswordMatch(state.password1, state.password2);
        }

        return !userNameValid || !passwordValid || !nameValid || !organizationValid;
    }

    function isEnabledCheckboxDisabled(): boolean {
        const homeOrganization = selectedOrganizations.find((org) => org.id === Number(state.homeOrganizationId));
        if (!homeOrganization) return true;
        return !hasCurrentUserAdminRightsInOrganization(homeOrganization);
    }

    function pageContent(): OrganizationDocument[] {
        const pageFirstIndex = (state.page - 1) * itemsPerPage;
        return workGroupDocuments.slice(pageFirstIndex, pageFirstIndex + itemsPerPage);
    }

    function pageCount(): number {
        return Math.ceil(workGroupDocuments.length / itemsPerPage);
    }

    function hasCurrentUserAdminRightsInOrganization(organization: OrganizationWithRole): boolean {
        return context.organization.adminOrganizations.some((org) => org.id === organization.id);
    }

    function hasCurrentUserAdminRightsToDocument(document: OrganizationDocument): boolean {
        const organization = selectedOrganizations.find((org) => org.id === document.organizationId);
        if (!organization) return false;
        return hasCurrentUserAdminRightsInOrganization(organization);
    }

    function isDeleteButtonDisabled(organization: OrganizationWithRole): boolean {
        // Disabled if it is a home organization OR current user has no rights in the organization.
        return (
            Number(state.homeOrganizationId) === organization.id ||
            !hasCurrentUserAdminRightsInOrganization(organization)
        );
    }

    function isRadioButtonDisabled(organization: OrganizationWithRole): boolean {
        // Disabled if current user has no rights in the organization.
        // If current user has no right in home organization, all radio buttons are disabled.
        const homeOrganization = selectedOrganizations.find((org) => org.id === Number(state.homeOrganizationId));
        if (!homeOrganization) return !hasCurrentUserAdminRightsInOrganization(organization);
        return (
            !hasCurrentUserAdminRightsInOrganization(organization) ||
            !hasCurrentUserAdminRightsInOrganization(homeOrganization)
        );
    }

    function calculateError(currentField: UserDialogFormField | undefined): boolean {
        const { validateUserName, validatePassword, checkPasswordMatch } = context.user;
        const isNameFieldFocus = fieldFocus === 'firstName' || fieldFocus === 'lastName';

        switch (currentField) {
            case 'firstName':
                return !isNameFieldFocus && !state.firstName && Boolean(state.lastName);

            case 'lastName':
                return !isNameFieldFocus && !state.lastName && Boolean(state.firstName);

            case 'userName':
                return fieldFocus !== 'userName' && Boolean(state.userName) && !validateUserName(state.userName);

            case 'password1':
                return fieldFocus !== 'password1' && Boolean(state.password1) && !validatePassword(state.password1);

            case 'password2':
                return (
                    (!(fieldFocus === 'password2' || fieldFocus === 'password1') &&
                        !checkPasswordMatch(state.password1, state.password2)) ||
                    (fieldFocus === 'password2' && Boolean(state.password2) && !validatePassword(state.password1)) ||
                    (Boolean(state.password2) &&
                        checkPasswordMatch(state.password1, state.password2) &&
                        !validatePassword(state.password1))
                );
            case 'organization':
                return (
                    fieldFocus !== 'organization' && selectedOrganizations.length === 0 && !state.organizationInputValue
                );
            default:
                return false;
        }
    }

    const organizationAutocompleteList: AdminOrganization[] = context.organization.adminOrganizations.filter((org) => {
        return !selectedOrganizations.some((selectedOrg) => org.id === selectedOrg.id);
    });

    // The Current user is not allowed to remove user
    // if user belongs any other organization in which the current user is NOT a superuser
    const hasCurrentUserAdminRightsInAllUserOrganizations = !selectedOrganizations.some(
        (org) => !hasCurrentUserAdminRightsInOrganization(org),
    );

    function basicInfoTab(): JSX.Element {
        const { translate } = context.localization;
        return (
            <Root>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <ShvkTextField
                            required
                            autoFocus
                            label={translate('FIRSTNAME')}
                            value={state.firstName}
                            variant="outlined"
                            size="small"
                            error={calculateError('firstName')}
                            onFocus={(): void => handleFocusChange('firstName')}
                            onBlur={(): void => handleFocusChange(undefined)}
                            fullWidth
                            onChange={(event): void => handleTextChange(event, 'firstName')}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <ShvkTextField
                            required
                            label={translate('LASTNAME')}
                            value={state.lastName}
                            variant="outlined"
                            size="small"
                            error={calculateError('lastName')}
                            onFocus={(): void => handleFocusChange('lastName')}
                            onBlur={(): void => handleFocusChange(undefined)}
                            fullWidth
                            onChange={(event): void => handleTextChange(event, 'lastName')}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <ShvkTextField
                            required
                            label={translate('USERNAME')}
                            value={state.userName}
                            variant="outlined"
                            size="small"
                            onFocus={(): void => handleFocusChange('userName')}
                            onBlur={(): void => handleFocusChange(undefined)}
                            fullWidth
                            onChange={(event): void => handleTextChange(event, 'userName')}
                            error={calculateError('userName')}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <ShvkTextField
                            label={translate('PROFESSION')}
                            value={state.profession}
                            variant="outlined"
                            size="small"
                            fullWidth
                            onChange={(event): void => handleTextChange(event, 'profession')}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <ShvkTextField
                            label={translate('UNITORBRANCH')}
                            value={state.unitOrBranch}
                            variant="outlined"
                            size="small"
                            fullWidth
                            onChange={(event): void => handleTextChange(event, 'unitOrBranch')}
                        />
                    </Grid>
                </Grid>
                <ShvkNotificationBox
                    notificationBoxData={{
                        nameData: { firstName: state.firstName, lastName: state.lastName },
                        fieldData: state.userName,
                        fieldFocus,
                    }}
                    marginTop={1.5}
                    currentField={
                        fieldFocus === 'userName' ? 'userName' : fieldFocus === 'firstName' ? 'firstName' : 'lastName'
                    }
                />
            </Root>
        );
    }

    function passwordTab(): JSX.Element {
        const { translate } = context.localization;

        return (
            <Root>
                <Grid container spacing={2}>
                    {props.user && (
                        <Grid item xs={12}>
                            <FormLabel component="legend">{translate('RESET_PASSWORD_DLG_TITLE')}</FormLabel>
                        </Grid>
                    )}
                    <Grid item xs={12} sm={6}>
                        <ShvkTextField
                            required
                            label={translate('PASSWORD')}
                            variant="outlined"
                            size="small"
                            type="password"
                            autoFocus
                            fullWidth
                            onChange={(event): void => handleTextChange(event, 'password1')}
                            value={state.password1}
                            autoComplete="new-password"
                            error={calculateError('password1')}
                            onFocus={(): void => handleFocusChange('password1')}
                            onBlur={(): void => handleFocusChange(undefined)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <ShvkTextField
                            required
                            label={translate('RE_ENTER_PASSWORD')}
                            variant="outlined"
                            size="small"
                            type="password"
                            fullWidth
                            onChange={(event): void => handleTextChange(event, 'password2')}
                            value={state.password2}
                            autoComplete="new-password"
                            error={calculateError('password2')}
                            onFocus={(): void => handleFocusChange('password2')}
                            onBlur={(): void => handleFocusChange(undefined)}
                        />
                    </Grid>
                </Grid>
                <ShvkNotificationBox
                    notificationBoxData={{
                        passwordData: { password1: state.password1, password2: state.password2 },
                        fieldFocus,
                    }}
                    marginTop={1.5}
                    currentField={fieldFocus === 'password1' ? 'password1' : 'password2'}
                />
            </Root>
        );
    }

    function organizationTab(): JSX.Element {
        const { translate } = context.localization;
        return (
            <Root>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <FormLabel component="legend">{translate('SELECT_ORGANIZATION')} *</FormLabel>
                    </Grid>
                    <Grid item xs={12}>
                        <Autocomplete
                            // Autocomplete is used to push selected item to list, so the value will always be null.
                            open={Boolean(state.organizationInputValue) && fieldFocus === 'organization'}
                            popupIcon={false}
                            value={null}
                            onChange={handleOrganizationValueChange}
                            inputValue={state.organizationInputValue}
                            onInputChange={handleOrganizationInputValueChange}
                            options={organizationAutocompleteList}
                            getOptionLabel={(option): string => option.name}
                            renderOption={(props: object, option): JSX.Element => (
                                <li {...props} key={option.id}>
                                    {option.name}
                                </li>
                            )}
                            fullWidth
                            size="small"
                            onFocus={(): void => handleFocusChange('organization')}
                            onBlur={(): void => handleFocusChange(undefined)}
                            renderInput={(params): JSX.Element => (
                                <ShvkTextField
                                    {...params}
                                    label={translate('ORGANIZATION')}
                                    variant="outlined"
                                    autoFocus
                                    placeholder={translate('USER_MANAGEMENT_SELECT_ORGANIZATION_TIP')}
                                    error={calculateError('organization')}
                                    InputProps={{
                                        ...params.InputProps,
                                    }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item />
                    {selectedOrganizations.length > 0 && (
                        <>
                            <Grid item xs={12}>
                                <FormLabel component="legend">{translate('USER_ORGANIZATIONS')}</FormLabel>
                            </Grid>
                            <Grid item xs={12}>
                                <Card variant="outlined">
                                    {selectedOrganizations.map((organization) => (
                                        <React.Fragment key={organization.id}>
                                            <Box py={1} px={2}>
                                                <Grid container justifyContent="space-between">
                                                    <Grid item xs={12}>
                                                        <Typography>{organization.name}</Typography>
                                                    </Grid>
                                                    <Grid item>
                                                        <FormControlLabel
                                                            label={translate('ADMIN_RIGHTS')}
                                                            control={
                                                                <Checkbox
                                                                    checked={organization.isAdmin}
                                                                    onChange={(event): void =>
                                                                        handleAdminCheckboxChange(
                                                                            event,
                                                                            organization.id,
                                                                        )
                                                                    }
                                                                    color="primary"
                                                                    disabled={
                                                                        !hasCurrentUserAdminRightsInOrganization(
                                                                            organization,
                                                                        )
                                                                    }
                                                                />
                                                            }
                                                        />
                                                        <FormControlLabel
                                                            label={translate('HOME_ORGANIZATION')}
                                                            control={
                                                                <Radio
                                                                    value={organization.id}
                                                                    color="primary"
                                                                    checked={
                                                                        Number(state.homeOrganizationId) ===
                                                                        organization.id
                                                                    }
                                                                    onChange={(event): void =>
                                                                        handleTextChange(event, 'homeOrganizationId')
                                                                    }
                                                                    disabled={isRadioButtonDisabled(organization)}
                                                                />
                                                            }
                                                        />
                                                    </Grid>
                                                    <Grid item>
                                                        <DangerIconButton
                                                            aria-label={translate('REMOVE')}
                                                            disabled={isDeleteButtonDisabled(organization)}
                                                            onClick={(): void =>
                                                                removeUserOrganization(organization.id)
                                                            }
                                                        >
                                                            <DeleteIcon />
                                                        </DangerIconButton>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                            <Divider />
                                        </React.Fragment>
                                    ))}
                                </Card>
                            </Grid>
                        </>
                    )}
                </Grid>
                <ShvkNotificationBox
                    notificationBoxData={{
                        fieldData: selectedOrganizations[0]?.name ?? undefined,
                        fieldFocus,
                    }}
                    currentField={'organization'}
                />
            </Root>
        );
    }

    function accountTab(): JSX.Element {
        const { translate } = context.localization;
        return (
            <Root>
                <FormControlLabel
                    label={translate('USER_ACCOUNT_ENABLED')}
                    control={
                        <Checkbox
                            checked={state.enabled}
                            onChange={(event): void =>
                                setState((state) => ({ ...state, enabled: event.target.checked }))
                            }
                            color="primary"
                            disabled={isEnabledCheckboxDisabled()}
                        />
                    }
                />
            </Root>
        );
    }

    function workGroupTab(): JSX.Element {
        const { translate } = context.localization;

        return (
            <Root>
                <Box display="flex" flexDirection="column" className={classes.tabPanel}>
                    <Box mb={2}>
                        <Typography id="add-user-dialog-workgroup-tip">
                            {translate('USER_MANAGEMENT_SELECT_WORKGROUPS_TIP')}
                        </Typography>
                    </Box>
                    <TableContainer component={Card} variant="outlined" className={classes.tableContainerCard}>
                        <Table
                            aria-describedby="add-user-dialog-workgroup-tip"
                            stickyHeader
                            aria-label={translate('USER_MANAGEMENT_USER_WORKGROUPS')}
                            sx={{ tableLayout: 'fixed' }}
                            size="small"
                        >
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ width: '40%' }}>{translate('NAME')}</TableCell>
                                    <TableCell>{translate('ORGANIZATION')}</TableCell>
                                    <TableCell>{translate('DOCUMENT_STATUS')}</TableCell>
                                    <TableCell align="center">
                                        {translate('USER_MANAGEMENT_USER_IN_WORKGROUPT_TITLE')}
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {pageContent().map((document) => (
                                    <TableRow
                                        key={document.id}
                                        hover
                                        onClick={(): void => toggleWorkgroupSelection(document)}
                                        style={{
                                            cursor: hasCurrentUserAdminRightsToDocument(document)
                                                ? 'pointer'
                                                : 'not-allowed',
                                        }}
                                    >
                                        <TableCell className={classes.cell}>{document.name}</TableCell>
                                        <TableCell className={classes.cell}>{document.organization}</TableCell>
                                        <TableCell className={classes.cell}>{translate(document.statusCode)}</TableCell>
                                        <TableCell padding="none" align="center" className={classes.cell}>
                                            {document.workgroup && <Check />}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Box mt={2}>
                        <Pagination count={pageCount()} page={state.page} onChange={handlePageChange} color="primary" />
                    </Box>
                </Box>
            </Root>
        );
    }

    const { translate } = context.localization;

    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            transitionDuration={{ exit: 0 }}
            aria-labelledby="add-user-dialog-title"
            maxWidth={'md'}
            fullWidth
            sx={{ height: '90%' }}
        >
            <DialogTitle id="add-user-dialog-title" sx={{ pb: 0 }}>
                {props.user ? (
                    <>
                        {translate('USER_INFORMATION')}:
                        <Typography variant={'h6'} component="span" sx={{ pl: 1 }}>
                            {props.user.firstName + ' ' + props.user.lastName}
                        </Typography>
                    </>
                ) : (
                    <>
                        {translate('ADD_USER')}
                        <Tooltip title={translate('kayttajahallinta_luo_kayttajatunnus_ikkuna_3')}>
                            <InfoIcon
                                color="primary"
                                style={{
                                    fontSize: 'larger',
                                    display: 'inline-block',
                                    verticalAlign: 'middle',
                                    marginLeft: '10px',
                                    marginBottom: '2px',
                                }}
                            />
                        </Tooltip>
                    </>
                )}
                <Tabs
                    value={activeTab}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={handleTabChange}
                    variant="fullWidth"
                    aria-labelledby="organization-item-dialog-title"
                >
                    <Tab label={translate('USER_MANAGEMENT_USER_BASIC_INFORMATION')} value="BASIC_INFO" />
                    <Tab label={translate('USER_MANAGEMENT_USER_PASSWORD')} value="PASSWORD" />
                    <Tab label={translate('USER_MANAGEMENT_USER_ORGANIZATIONS')} value="ORGANIZATIONS" />
                    <Tab label={translate('USER_MANAGEMENT_USER_ACCOUNT_INFORMATION')} value="ACCOUNT_INFO" />
                    <Tab label={translate('USER_MANAGEMENT_USER_WORKGROUPS')} value="WORK_GROUPS" />
                </Tabs>
            </DialogTitle>
            <DialogContent dividers>
                <TabContext value={activeTab}>
                    <TabPanel value={'BASIC_INFO'}>{basicInfoTab()}</TabPanel>
                    <TabPanel value={'PASSWORD'}>{passwordTab()}</TabPanel>
                    <TabPanel value={'ORGANIZATIONS'}>{organizationTab()}</TabPanel>
                    <TabPanel value={'ACCOUNT_INFO'}>{accountTab()}</TabPanel>
                    <TabPanel value={'WORK_GROUPS'}>{workGroupTab()}</TabPanel>
                </TabContext>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose} variant="contained">
                    {translate('CANCEL')}
                </Button>
                {props.user && hasCurrentUserAdminRightsInAllUserOrganizations && (
                    <DangerButton variant="contained" onClick={remove}>
                        {translate('REMOVE')}
                    </DangerButton>
                )}
                <Box flexGrow={1} />
                <ShvkButton disabled={isSaveButtonDisabled()} onClick={save}>
                    {translate('SAVE')}
                </ShvkButton>
            </DialogActions>
        </Dialog>
    );
}

export default observer(AddUserDialog);
