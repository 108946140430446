import Axios from '../../axiosConfig';
import { BACKEND_URL } from '../../config';
import { configure, runInAction, makeAutoObservable } from 'mobx';
import Organization, {
    OrganizationCategory,
    OrganizationLocation,
    OrganizationWithLicense,
    SaveOrganizationRequestParam,
    AdminOrganization,
    ComparisonSettingsFormOrganization,
    UserOrganization,
} from '../../types/organization';
import RootModule from '../rootModule';

configure({ enforceActions: 'observed' });

export default class OrganizationModule {
    private rootModule: RootModule;
    private orgLocations: OrganizationLocation[] = [];
    private categories: OrganizationCategory[] = [];
    private availableCollectionCodes: number[] = [];
    private adminOrganizationList: AdminOrganization[] = [];

    constructor(rootModule: RootModule) {
        makeAutoObservable(this, {}, { autoBind: true });
        this.rootModule = rootModule;
    }

    public setAdminOrganizationList(organizationsList: AdminOrganization[]) {
        this.adminOrganizationList = organizationsList;
    }

    public async fetchOrganizationLocations(): Promise<void> {
        const response = await Axios({
            method: 'GET',
            url: BACKEND_URL + 'organization/list',
        });
        if (response?.status === 200) runInAction(() => (this.orgLocations = response.data));
    }

    public async fetchOrganizationCategories(): Promise<void> {
        const response = await Axios({
            method: 'GET',
            url: BACKEND_URL + 'organization/organizationCategories',
        });
        runInAction(() => (this.categories = response.data));
    }

    public async fetchOrganizationsWithLicenses(
        selectedLicenseScema?: string,
        selectedOrganizationCategory?: string,
    ): Promise<OrganizationWithLicense[]> {
        const response = await Axios({
            method: 'POST',
            url: BACKEND_URL + 'organization/getOrganizationsWithLicenses',
            data: {
                selectedLicenseScema,
                selectedOrganizationCategory,
            },
        });
        return response.data;
    }

    public async fetchCollectionOrganizations(organizationId: number): Promise<Organization[]> {
        const response = await Axios({
            method: 'POST',
            url: BACKEND_URL + 'organization/getCollectionOrganizations',
            data: {
                organizationId,
            },
        });
        return response.data;
    }

    public async fetchAvailableCollectionCodes(): Promise<void> {
        const response = await Axios({
            method: 'POST',
            url: BACKEND_URL + 'organization/availableCollectionCodes',
        });
        runInAction(() => (this.availableCollectionCodes = response.data));
    }

    public async fetchAllOrganizations(): Promise<void> {
        const response = await Axios({
            method: 'POST',
            url: BACKEND_URL + 'organization/list',
        });
        runInAction(() => (this.adminOrganizationList = response.data));
    }

    public async fetchAdminOrganizations(): Promise<void> {
        const response = await Axios({
            method: 'POST',
            url: BACKEND_URL + 'organization/adminOrganizations',
        });
        runInAction(() => (this.adminOrganizationList = response.data));
    }

    public async callUserToOrganization(userName: string, organizationId: number): Promise<void> {
        await Axios({
            method: 'POST',
            url: BACKEND_URL + 'organization/callUserToOrganization',
            data: {
                userName,
                organization: organizationId,
            },
        });
    }

    public async saveOrganization(organization: SaveOrganizationRequestParam): Promise<OrganizationWithLicense> {
        const response = await Axios({
            method: 'POST',
            url: BACKEND_URL + 'organization/saveOrganization',
            data: organization,
        });
        return response.data;
    }

    public async searchOrganizations(searchString: string): Promise<ComparisonSettingsFormOrganization[]> {
        const response = await Axios({
            method: 'POST',
            url: BACKEND_URL + 'organization/search',
            data: { searchCity: searchString },
        });
        return response.data;
    }

    public get organizationLocations(): OrganizationLocation[] {
        return this.orgLocations
            .filter((org) => org.longitude)
            .slice()
            .sort((a, b) => a['name'].localeCompare(b['name']));
    }

    public get organizationCategories(): OrganizationCategory[] {
        return this.categories;
    }

    public get collectionCodes(): number[] {
        return this.availableCollectionCodes;
    }

    public get adminOrganizations(): AdminOrganization[] {
        return this.adminOrganizationList;
    }

    public get sortedAdminOrganizations(): AdminOrganization[] {
        //sorted by name and category
        return this.adminOrganizationList
            .slice()
            .sort((a, b) => (a.name < b.name ? 1 : -1))
            .sort((a, b) => (a.category > b.category ? 1 : -1));
    }

    public getOrganizationCategoryByName(name: string): OrganizationCategory | null {
        return this.organizationCategories.find((category) => category.name === name) || null;
    }

    // When user logs in -> selected organization is set to null -> home organization is the active organization.
    // When user changes organization -> selected organization is the active organization.
    // This getter can be used to get the active organization id.
    public get currentOrganizationId(): number | null {
        const selectedOrganizationId = this.rootModule.user.userDetails?.selectedOrganization.id;
        const homeOrganizationId = this.rootModule.user.userDetails?.homeOrganization.id;
        return selectedOrganizationId || homeOrganizationId || null;
    }

    public get currentOrganization(): UserOrganization | null {
        const selectedOrganization = this.rootModule.user.userDetails?.selectedOrganization;
        const homeOrganization = this.rootModule.user.userDetails?.homeOrganization;
        return selectedOrganization || homeOrganization || null;
    }
}
