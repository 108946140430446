import React from 'react';
import { observer } from 'mobx-react-lite';
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import { TextFieldEvent } from '../../../types/events';
import { ImpactOnTargetValue } from '../../../types/evaTemplate';
import { rootModule } from '../../../store/storeContext';

const PREFIX = 'EvaSlider';

const classes = {
    buttonArea: `${PREFIX}-buttonArea`,
    slider: `${PREFIX}-slider`,
    sliderDefaultCursor: `${PREFIX}-slider-default-cursor`,
    rangeIndicator: `${PREFIX}-rangeIndicator`,
    rangeIndicatorDefaultCursor: `${PREFIX}-rangeIndicator-default-cursor`,
    thumbText: `${PREFIX}-thumbText`,
    evaImpactLine: `${PREFIX}-evaImpactLine`,
    evaMinusLine: `${PREFIX}-evaMinusLine`,
    evaPlusLine: `${PREFIX}-evaPlusLine`,
    sliderNegative: `${PREFIX}-sliderNegative`,
    sliderPositive: `${PREFIX}-sliderPositive`,
    sliderNeutral: `${PREFIX}-sliderNeutral`,
};

const { theming } = rootModule;

const Root = styled('div')(({ theme }) => ({
    [`&.${classes.buttonArea}`]: {
        textAlign: 'center',
        width: '148px',
        height: '34px',
        background: 'transparent',
        border: '2px hidden',
        borderTopLeftRadius: '15px',
        borderTopRightRadius: '15px',
        borderBottomRightRadius: '15px',
        borderBottomLeftRadius: '15px',
        position: 'relative',
    },

    [`& .${classes.slider}`]: {
        appearance: 'none',
        height: '30px',
        margin: 0,
        width: '100%',
        position: 'relative',
        boxSizing: 'content-box',
        paddingTop: 0,
        backgroundColor: 'transparent',
        '&:focus': {
            outline: 'none',
        },
        '&::-webkit-slider-thumb': {
            boxShadow: '0 0 0 #000031',
            height: '28px',
            width: '28px',
            borderRadius: '50px',
            background: '#FFFFFF',
            cursor: 'pointer',
            appearance: 'none',
        },
        '&::-moz-range-thumb': {
            boxShadow: '0 0 0 #000031',
            height: '23px',
            width: '23px',
            borderRadius: '50px',
            background: '#FFFFFF',
            cursor: 'pointer',
            appearance: 'none',
        },
    },

    [`& .${classes.sliderDefaultCursor}`]: {
        '&::-webkit-slider-thumb': {
            cursor: 'default',
        },
        '&::-moz-range-thumb': {
            cursor: 'default',
        },
    },

    [`& .${classes.rangeIndicator}`]: {
        '&::-webkit-slider-runnable-track': {
            // Background with ticks
            background: 'repeating-linear-gradient(to right, #f7f7f7, #f7f7f7 11%, #dddddd 11%, #dddddd 13%)',

            width: '100%',
            height: '30px',
            cursor: 'pointer',
            boxShadow: 'none',
            borderRadius: '50px',
            border: '1px solid #DDDDDD',
        },
        '&::-moz-range-track': {
            // Background with ticks
            background: 'repeating-linear-gradient(to right, #f7f7f7, #f7f7f7 11%, #dddddd 11%, #dddddd 13%)',

            width: '100%',
            height: '30px',
            cursor: 'pointer',
            boxShadow: 'none',
            borderRadius: '50px',
            border: '1px solid #DDDDDD',
        },
        '&:focus': {
            '&::-webkit-slider-runnable-track': {
                // Background with ticks
                background: 'repeating-linear-gradient(to right, #f7f7f7, #f7f7f7 11%, #dddddd 11%, #dddddd 13%)',
            },
            '&::-moz-range-track': {
                // Background with ticks
                background: 'repeating-linear-gradient(to right, #f7f7f7, #f7f7f7 11%, #dddddd 11%, #dddddd 13%)',
            },
        },
    },

    [`& .${classes.rangeIndicatorDefaultCursor}`]: {
        '&::-webkit-slider-runnable-track': {
            cursor: 'default',
        },
        '&::-moz-range-track': {
            cursor: 'default',
        },
    },

    [`& .${classes.thumbText}`]: {
        pointerEvents: 'none',
        position: 'absolute',
        top: '3px',
    },

    [`& .${classes.evaImpactLine}`]: {
        position: 'absolute',
        height: '8px',
        top: '12px',
    },

    [`& .${classes.evaMinusLine}`]: {
        backgroundColor: theme.palette.error.main,
    },

    [`& .${classes.evaPlusLine}`]: {
        backgroundColor: theme.palette.warning.main,
    },

    [`& .${classes.sliderNegative}`]: {
        '&::-webkit-slider-thumb': {
            border: '3px solid ' + theme.palette.error.main,
        },
        '&::-moz-range-thumb': {
            border: '3px solid ' + theme.palette.error.main,
        },
    },

    [`& .${classes.sliderPositive}`]: {
        '&::-webkit-slider-thumb': {
            border: '3px solid ' + theme.palette.warning.main,
        },
        '&::-moz-range-thumb': {
            border: '3px solid ' + theme.palette.warning.main,
        },
    },

    [`& .${classes.sliderNeutral}`]: {
        '&::-webkit-slider-thumb': {
            border: '3px solid ' + theming.shvkDarkGrey,
        },
        '&::-moz-range-thumb': {
            border: '3px solid ' + theming.shvkDarkGrey,
        },
    },
}));

interface Props {
    value: ImpactOnTargetValue;
    onChange(event: TextFieldEvent): void;
    isEditable?: boolean;
}

function EvaSlider(props: Props) {
    function getThumbText(): JSX.Element {
        const { value } = props;

        const textStyle = {
            left: '',
        };
        switch (value) {
            case -3:
                textStyle.left = '5px';
                break;
            case -2:
                textStyle.left = '25px';
                break;
            case -1:
                textStyle.left = '46px';
                break;
            case 0:
                textStyle.left = '69px';
                break;
            case 1:
                textStyle.left = '90px';
                break;
            case 2:
                textStyle.left = '109px';
                break;
            case 3:
                textStyle.left = '128px';
                break;
        }

        return (
            <Typography component="div" className={classes.thumbText} style={textStyle}>
                {value}
            </Typography>
        );
    }

    function getInputClassName(): string {
        const { value } = props;

        let valueClass;
        if (value < 0) valueClass = classes.sliderNegative;
        else if (value > 0) valueClass = classes.sliderPositive;
        else valueClass = classes.sliderNeutral;
        if (props.isEditable === false) {
            return `${classes.slider} ${classes.sliderDefaultCursor} ${classes.rangeIndicator} ${classes.rangeIndicatorDefaultCursor} ${valueClass}`;
        } else {
            return `${classes.slider} ${classes.rangeIndicator} ${valueClass}`;
        }
    }

    function getLine(): JSX.Element {
        const { value } = props;

        let valueClass = '';
        const lineStyle = {
            left: '0',
            width: '0',
        };
        switch (value) {
            case -3:
                valueClass = classes.evaMinusLine;
                lineStyle.left = '27px';
                lineStyle.width = '50px';
                break;
            case -2:
                valueClass = classes.evaMinusLine;
                lineStyle.left = '47px';
                lineStyle.width = '30px';

                break;
            case -1:
                valueClass = classes.evaMinusLine;
                lineStyle.left = '67px';
                lineStyle.width = '10px';

                break;
            case 1:
                valueClass = classes.evaPlusLine;
                lineStyle.left = '75px';
                lineStyle.width = '6px';
                break;
            case 2:
                valueClass = classes.evaPlusLine;
                lineStyle.left = '75px';
                lineStyle.width = '26px';
                break;
            case 3:
                valueClass = classes.evaPlusLine;
                lineStyle.left = '75px';
                lineStyle.width = '46px';
                break;
        }

        return <div className={`${classes.evaImpactLine} ${valueClass}`} style={lineStyle} />;
    }

    const thumbText = getThumbText();
    const inputClassName = getInputClassName();
    const line = getLine();

    return (
        <Root className={classes.buttonArea}>
            <input
                value={props.value}
                onChange={props.onChange}
                type="range"
                min="-3"
                max="3"
                step="1"
                className={inputClassName}
            />
            {thumbText}
            {line}
        </Root>
    );
}

export default observer(EvaSlider);
