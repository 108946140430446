import Axios from '../../axiosConfig';
import { BACKEND_URL } from '../../config';
import { configure, runInAction, makeAutoObservable } from 'mobx';
import NewsArticle from '../../types/news';

configure({ enforceActions: 'observed' });

/**Provides news related variables and methods */
export default class NewsModule {
    private news: NewsArticle[] = [];

    constructor() {
        makeAutoObservable(this, {}, { autoBind: true });
    }

    public async fetchAllNews(): Promise<void> {
        const response = await Axios({
            method: 'GET',
            url: BACKEND_URL + 'news/all',
        });
        if (response?.status === 200) {
            const sorted = response.data.sort((a: NewsArticle, b: NewsArticle) => {
                return new Date(b.dateCreated!).getTime() - new Date(a.dateCreated!).getTime();
            });
            runInAction(() => (this.news = sorted));
        }
    }

    public async removeNews(id: number): Promise<void> {
        await Axios({
            method: 'POST',
            url: BACKEND_URL + 'news/delete',
            data: { id },
        });
        runInAction(() => {
            this.news = this.news.filter((article) => article.id !== id);
        });
    }

    public async saveNews(title: string, content: string): Promise<void> {
        const response = await Axios({
            method: 'POST',
            url: BACKEND_URL + 'news/save',
            data: { title, content },
        });

        const savedArticle = response.data;
        runInAction(() => {
            this.news.unshift(savedArticle);
        });
    }

    public get newsArticles(): NewsArticle[] {
        return this.news;
    }
}
