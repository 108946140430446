import React, { useEffect, useState } from 'react';
import Nestable, { NestableItem, RenderCollapseIconArgs, RenderItemArgs } from 'react-nestable';
import { observer, useLocalObservable } from 'mobx-react-lite';
import { runInAction } from 'mobx';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';
import { Info, KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { ChapterNestableItem, ChapterRenderItemArgs } from '../../../types/nestable';
import { addDepths, addParentIds, editText, removeItem } from '../../../utils/NestableUtils';
import ChapterNestableComponent from '../ChapterNestableComponent';
import ShvkButton from '../../../styled/ShvkButton';
import {
    Chapter,
    RemovedChaptersParams,
    SaveChapterModelChapter,
    SaveChapterModelParams,
} from '../../../types/document';
import useStore from '../../../store/storeContext';

const PREFIX = 'ChapterModelDialog';

const classes = {
    paper: `${PREFIX}-paper`,
};

const StyledDialog = styled(Dialog)(() => ({
    [`& .${classes.paper}`]: {
        height: '90%',
    },
}));

interface Props {
    isOpen: boolean;
    close(): void;
}

interface State {
    nextNewId: number;
}

interface StoreState {
    items: ChapterNestableItem[];
    removedItems: RemovedChaptersParams[];
    isEva: boolean;
    getItems: ChapterNestableItem[];
    chapterModel: SaveChapterModelParams;
    setItems: (newItems: ChapterNestableItem[]) => void;
    removeItem: (item: ChapterNestableItem) => void;
    handleTextChange: (value: string, id: number) => void;
}

function ChapterModelDialog(props: Props) {
    const { document, localization, theming, loadingIndicator, snackbar } = useStore();

    const [state, setState] = useState<State>({
        nextNewId: -1,
    });

    const localStore = useLocalObservable<StoreState>(() => ({
        items: [],
        removedItems: [],

        get isEva(): boolean {
            return document.isEvaDocument(document.currentDocument);
        },

        get getItems(): ChapterNestableItem[] {
            return this.items.slice();
        },

        get chapterModel(): SaveChapterModelParams {
            const removedChapters = this.removedItems;
            const chapters: SaveChapterModelChapter[] = [];

            this.getItems.forEach((chapter) => {
                chapters.push(addSaveChapterParams(chapter));
                chapter.children.forEach((chapter) => {
                    chapters.push(addSaveChapterParams(chapter));
                    chapter.children.forEach((chapter) => {
                        chapters.push(addSaveChapterParams(chapter));
                        chapter.children.forEach((chapter) => {
                            chapters.push(addSaveChapterParams(chapter));
                        });
                    });
                });
            });

            return { chapters, removedChapters };
        },

        setItems(newItems: ChapterNestableItem[]): void {
            this.items = newItems;
        },

        removeItem(item: ChapterNestableItem): void {
            const newItems = this.getItems.filter(removeItem(item));
            this.setItems(newItems as ChapterNestableItem[]);

            const id = item.id;
            const isRemovable = item.isRemovable;
            if (id > 0 && isRemovable) {
                this.removedItems.push({ id, isRemovable });
            }
        },

        handleTextChange(value: string, id: number): void {
            const newItems = this.getItems.map(editText(id, value));
            this.setItems(newItems as ChapterNestableItem[]);
        },
    }));

    useEffect(() => {
        void init();
    }, [props.isOpen]);

    async function save(): Promise<void> {
        try {
            loadingIndicator.show();
            await document.updateDocumentChapters(localStore.chapterModel);
            await init();
            snackbar.showSuccess();
        } catch (error) {
            snackbar.showError(error.data?.code);
        } finally {
            props.close();
            loadingIndicator.hide();
        }
    }

    function init(): void {
        setState((state) => ({ ...state, nextNewId: -1 }));
        localStore.setItems(createNewItems());
        runInAction(() => {
            localStore.removedItems = [];
        });
    }

    function createNewItems(): ChapterNestableItem[] {
        const chapters = document.currentDocument.chapters;
        let filteredChapters: Chapter[];

        if (localStore.isEva) {
            filteredChapters = chapters.filter((chapter: Chapter) => chapter.chapterLevel === 1);
        } else {
            filteredChapters = chapters.filter((chapter: Chapter) => chapter.chapterLevel === 0);
        }

        return filteredChapters.map((chapter: Chapter) => {
            return createItem(chapter, null);
        });
    }

    function createItem(chapter: Chapter, parentId: number | null): ChapterNestableItem {
        return {
            id: chapter.id,
            parentId: parentId,
            depth: localStore.isEva ? chapter.chapterLevel : chapter.chapterLevel + 1,
            text: chapter.title,
            isRemovable: chapter.isRemovable,
            isIndicatorArea: chapter.isIndicatorArea,
            isFocusArea: chapter.isFocusArea,
            isCompareToCountiesSubArea: chapter.isCompareToCountiesSubArea,
            isCompareToCountiesMainArea: chapter.isCompareToCountiesMainArea,
            isIntroduction: chapter.isIntroduction,
            isSummary: chapter.isSummary,
            children: document.findSubChapters(chapter.id, true).map((cha: Chapter) => createItem(cha, chapter.id)),
        };
    }

    function addSaveChapterParams(chapter: ChapterNestableItem): SaveChapterModelChapter {
        return {
            added: chapter.id < 0,
            id: chapter.id,
            chapterLevel: localStore.isEva ? chapter.depth : chapter.depth - 1,
            isIndicatorArea: chapter.isIndicatorArea,
            isFocusArea: chapter.isFocusArea,
            title: chapter.text,
        };
    }

    function chapterNumber(findChapter: ChapterNestableItem): string {
        const { isEva, getItems } = localStore;

        let level1Chapters;

        if (isEva) {
            level1Chapters = getItems;
        } else level1Chapters = getItems.map((chapter: ChapterNestableItem) => chapter.children).flat();
        const level2Chapters = level1Chapters.map((chapter: ChapterNestableItem) => chapter.children).flat();
        let chapterNumber = '';

        if ((isEva && findChapter.depth === 1) || (!isEva && findChapter.depth === 2))
            chapterNumber = (
                level1Chapters.findIndex((chapter: ChapterNestableItem) => chapter.id === findChapter.id) + 1
            ).toString();
        else if ((isEva && findChapter.depth === 2) || (!isEva && findChapter.depth === 3)) {
            const parent = level1Chapters.find((chapter: ChapterNestableItem) => chapter.id === findChapter.parentId);

            if (parent) {
                chapterNumber =
                    chapterNumber +
                    (
                        level1Chapters.findIndex((chapter: ChapterNestableItem) => chapter.id === parent.id) + 1
                    ).toString();
                chapterNumber =
                    chapterNumber +
                    '.' +
                    (
                        parent.children.findIndex((chapter: ChapterNestableItem) => chapter.id === findChapter.id) + 1
                    ).toString();
            }
        } else if ((isEva && findChapter.depth === 3) || (!isEva && findChapter.depth === 4)) {
            const parent = level2Chapters.find((chapter: ChapterNestableItem) => chapter.id === findChapter.parentId);
            const parentsParent = level1Chapters.find(
                (chapter: ChapterNestableItem) => chapter.id === parent?.parentId,
            );

            if (parent && parentsParent) {
                chapterNumber =
                    chapterNumber +
                    (
                        level1Chapters.findIndex((chapter: ChapterNestableItem) => chapter.id === parentsParent?.id) + 1
                    ).toString();
                chapterNumber =
                    chapterNumber +
                    '.' +
                    (
                        parentsParent.children.findIndex((chapter: ChapterNestableItem) => chapter.id === parent.id) + 1
                    ).toString();
                chapterNumber =
                    chapterNumber +
                    '.' +
                    (
                        parent.children.findIndex((chapter: ChapterNestableItem) => chapter.id === findChapter.id) + 1
                    ).toString();
            }
        }
        return chapterNumber;
    }

    function confirmChange(chapterDragItem: NestableItem, chapterDestinationParent: NestableItem): boolean {
        const destinationParent = chapterDestinationParent as ChapterNestableItem;
        const dragItem = chapterDragItem as ChapterNestableItem;

        //Rules for restricting drag moves should be added below.
        const isNotAllowedMove =
            (dragItem.children.length > 0 && dragItem.depth === 1 && destinationParent) ||
            (dragItem.children.length > 0 && dragItem.depth > 1 && !destinationParent) ||
            (dragItem.isSummary && destinationParent) ||
            (dragItem.isIntroduction && destinationParent) ||
            (dragItem.isCompareToCountiesMainArea && destinationParent.depth !== 1) ||
            dragItem.isCompareToCountiesSubArea ||
            destinationParent?.isSummary ||
            destinationParent?.isIntroduction ||
            destinationParent?.isCompareToCountiesSubArea ||
            destinationParent?.isCompareToCountiesMainArea;

        //Currently, only SHVK-documents have restricted moves.
        if (!localStore.isEva && isNotAllowedMove) {
            return false;
        } else return true;
    }

    function handleListChange(items: NestableItem[]): void {
        const chapterItems = items as ChapterNestableItem[];
        let newItems = chapterItems.map(addParentIds());
        newItems = newItems.map(addDepths());
        localStore.setItems(newItems as ChapterNestableItem[]);
    }

    const addItem = (): void => {
        const newItem: ChapterNestableItem = {
            id: state.nextNewId,
            text: '',
            children: [],
            parentId: null,
            isRemovable: true,
            isIndicatorArea: false,
            isFocusArea: false,
            isCompareToCountiesSubArea: false,
            isCompareToCountiesMainArea: false,
            isIntroduction: false,
            isSummary: false,
            depth: 1,
        };

        setState((state) => ({ ...state, nextNewId: state.nextNewId - 1 }));

        const newItems = localStore.getItems;
        newItems.push(newItem);

        localStore.setItems(newItems as ChapterNestableItem[]);
        // handleListChange(newItems);
        // Note: If something gets wonky, add handleListChange to localStore and call it from here also.
    };

    const renderItem = (args: RenderItemArgs): React.ReactNode => {
        return (
            <ChapterNestableComponent
                handleTextChange={localStore.handleTextChange}
                removeItem={localStore.removeItem}
                chapterNumber={chapterNumber}
                args={args as ChapterRenderItemArgs}
                save={save}
            />
        );
    };

    const renderCollapseIcon = (args: RenderCollapseIconArgs): JSX.Element => {
        return (
            <IconButton size="small" color="primary">
                {args.isCollapsed ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
            </IconButton>
        );
    };

    const { translate } = localization;

    return (
        <StyledDialog
            id="chapter-model-dialog"
            open={props.isOpen}
            onClose={props.close}
            classes={{ paper: classes.paper }}
            maxWidth="md"
            fullWidth
        >
            <DialogTitle id="chapter-model-dialog-title">
                {translate('DOCUMENT_CHAPTER_MODEL_EDIT_TITLE')}
                <Box pt={1} display="flex">
                    <Info style={{ marginRight: theming.spacing(1) }} color="primary" />
                    <Typography variant="body2">{translate('DOCUMENT_CHAPTER_MODEL_EDIT_TIP')}</Typography>
                </Box>
            </DialogTitle>
            <DialogContent>
                <Nestable
                    items={localStore.getItems}
                    maxDepth={localStore.isEva ? 3 : 4}
                    renderItem={renderItem}
                    onChange={handleListChange}
                    confirmChange={confirmChange}
                    renderCollapseIcon={renderCollapseIcon}
                    collapsed={false}
                />
            </DialogContent>
            <DialogActions>
                <ShvkButton onClick={addItem}>{translate('ADD_CHAPTER')}</ShvkButton>
                <Box flexGrow={1} />
                <Button variant="contained" onClick={props.close}>
                    {translate('CLOSE')}
                </Button>
                <ShvkButton onClick={save}>{translate('SAVE')}</ShvkButton>
            </DialogActions>
        </StyledDialog>
    );
}

export default observer(ChapterModelDialog);
