import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { TextFieldEvent } from '../../types/events';
import ShvkTextField from '../../styled/ShvkTextField';
import useStore from '../../store/storeContext';

interface Props {
    isOpen: boolean;
    close(): void;
}

function ForgotPasswordDialog(props: Props) {
    const context = useStore();
    const { user, localization, snackbar } = context;

    const [state, setState] = useState({
        email: '',
    });

    useEffect(() => {
        props.isOpen && init();
    }, [props.isOpen]);

    function init(): void {
        setState((state) => ({ ...state, email: '' }));
    }

    const handleChange = (event: TextFieldEvent): void => {
        setState((state) => ({ ...state, email: event.target.value }));
    };

    async function send(): Promise<void> {
        try {
            await user.sendResetLink(state.email);
            snackbar.showSuccess();
            props.close();
        } catch (error) {
            snackbar.showError(error.data.code);
        }
    }

    const isSendButtonDisabled = !user.validateUserName(state.email);
    const { translate } = localization;
    const { contact } = context.contact;

    return (
        <>
            <Dialog
                open={props.isOpen}
                onClose={props.close}
                aria-labelledby="forgot-password-dialog"
                maxWidth={'sm'}
                fullWidth
            >
                <DialogTitle id="forgot-password-dialog-title">{translate('FORGOT_DLG_TITLE')}</DialogTitle>
                <DialogContent dividers>
                    <Box>
                        <Typography>{translate('FORGOT_DLG_INSTRUCTION')}</Typography>
                    </Box>
                    <Box my={3}>
                        <Typography>{translate('EMAIL').toUpperCase()}</Typography>
                        <ShvkTextField
                            id="email"
                            name="email"
                            autoFocus
                            value={state.email}
                            onChange={(event): void => handleChange(event)}
                            variant="outlined"
                            size="small"
                            fullWidth
                            error={Boolean(state.email) && isSendButtonDisabled}
                            placeholder={translate('EMAIL')}
                        />
                    </Box>
                    <Box my={1}>
                        <Typography>{translate('FORGOT_DLG_SEND_INSTRUCTION')}</Typography>
                    </Box>
                    <Box mt={3}>
                        <Typography variant="subtitle2">{translate('FORGOT_DLG_MISSING_USERNAME')}</Typography>
                    </Box>
                    <Box mt={1}>
                        <Typography variant="subtitle2">
                            {translate('NAME') + ': ' + contact?.name + ', ' + contact?.address}
                        </Typography>
                        <Typography variant="subtitle2">{translate('EMAIL') + ': ' + contact?.email}</Typography>
                        <Typography variant="subtitle2">{translate('PHONE') + ': ' + contact?.phone}</Typography>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.close} variant="contained">
                        {translate('CANCEL')}
                    </Button>
                    <Box flexGrow={1} />
                    <Button onClick={send} color="secondary" variant="contained" disabled={isSendButtonDisabled}>
                        {translate('SEND')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default observer(ForgotPasswordDialog);
