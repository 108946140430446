import React from 'react';
import { styled } from '@mui/material/styles';
import Button, { ButtonProps } from '@mui/material/Button';

/**Material-UI button that implements SHVK theming */
const ShvkSecondaryButton = styled((props: ButtonProps) => <Button {...props} variant="contained" />)(({ theme }) => ({
    color: theme.palette.common.white,
    background: theme.palette.primary.main,
    '&:hover': {
        background: theme.palette.primary.light,
    },
    '&:focus': {
        background: theme.palette.primary.light,
    },
}));

export default ShvkSecondaryButton;
