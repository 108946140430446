import React from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Tooltip, Typography } from '@mui/material';
import { ArrowForward } from '@mui/icons-material';
import { Indicator, IndicatorChange } from '../../../types/indicator';
import useStore from '../../../store/storeContext';

interface Props {
    indicator: Indicator;
}

type BorderWidths = {
    smallChange: number;
    mediumChange: number;
    largeChange: number;
};

const changeBorderWidths: Readonly<BorderWidths> = { smallChange: 1.5, mediumChange: 4, largeChange: 6 };

function IndicatorCircle(props: Props) {
    const context = useStore();
    const { localization, theming } = context;

    const change = getChange();
    const circleStyle = getCircleStyle();

    function getChange(): IndicatorChange {
        const { calculateChange } = context.indicator;
        const { indicator } = props;
        return calculateChange(indicator.shortTermChangeData);
    }

    function getCircleStyle(): React.CSSProperties {
        const borderColor = getBorderColor();
        const borderWidth = getBorderWidth();
        return {
            border: borderWidth + 'px solid ' + borderColor,
            width: '60px',
            height: '60px',
            borderRadius: '50%',
        };
    }

    function getBorderWidth(): number {
        const { changeLimits } = context.indicator;
        let lineWidth = 1;

        if (change.value === null) return lineWidth;

        const changeValue = Math.abs(change.value);

        if (changeValue) {
            if (changeValue < changeLimits.smallChangeMin) {
                lineWidth = 0;
            } else if (changeValue >= changeLimits.smallChangeMin && changeValue < changeLimits.mediumChangeMin) {
                lineWidth = changeBorderWidths.smallChange;
            } else if (changeValue >= changeLimits.mediumChangeMin && changeValue < changeLimits.largeChangeMin) {
                lineWidth = changeBorderWidths.mediumChange;
            } else if (changeValue >= changeLimits.mediumChangeMin) {
                lineWidth = changeBorderWidths.largeChange;
            }
        }

        return lineWidth;
    }

    function getBorderColor(): string {
        const { palette, shvkMediumGrey } = theming;
        const { indicator } = props;

        const colorSchema = {
            blue: palette.warning.main,
            red: palette.error.main,
            gray: shvkMediumGrey,
        };

        if (!change.value) return colorSchema.gray;

        if ((change.value > 0 && indicator.direction > 1) || (change.value < 0 && indicator.direction < 1)) {
            return colorSchema.blue;
        } else if ((change.value > 0 && indicator.direction < 1) || (change.value < 0 && indicator.direction > 1)) {
            return colorSchema.red;
        } else {
            return colorSchema.gray;
        }
    }

    function showCircle(): boolean {
        if (change.value !== null) {
            if (Math.abs(change.value) < context.indicator.changeLimits.smallChangeMin) {
                return false;
            }
        }
        return true;
    }

    const { indicator } = props;
    const { comparisonSettings } = context.indicator;
    const { value, startYear, endYear } = change;
    const { translate } = localization;

    return (
        <Box display="flex" alignItems="center" flexDirection="column">
            {showCircle() && (
                <Tooltip
                    title={
                        startYear && endYear
                            ? translate('CHANGE') + ' (%): ' + startYear + ' - ' + endYear
                            : translate('CHANGE_UNKNOWN')
                    }
                >
                    <Box display="flex" alignItems="center" justifyContent="center" style={circleStyle}>
                        <Typography>{value?.toFixed(2) || '?'}</Typography>
                    </Box>
                </Tooltip>
            )}
            {!showCircle() && !indicator.textOnly && (
                <>
                    <Typography>{value?.toFixed(2) || '?'}</Typography>
                    <Tooltip
                        title={
                            startYear && endYear
                                ? translate('ARROW_FORWARD_INDICATOR_TOOLTIP') + ': ' + startYear + ' - ' + endYear
                                : translate('ARROW_FORWARD_INDICATOR_TOOLTIP')
                        }
                    >
                        <ArrowForward fontSize="large" />
                    </Tooltip>
                </>
            )}
            {comparisonSettings?.changeYearsVisibility === 1 && !indicator.textOnly && (
                <Typography variant="caption">
                    {startYear} - {endYear}
                </Typography>
            )}
        </Box>
    );
}

export default observer(IndicatorCircle);
