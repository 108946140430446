import React, { useEffect } from 'react';
import { observer, useLocalObservable } from 'mobx-react-lite';
import DesktopModule from '../types/desktopModule';
import { Box, Container, Grid } from '@mui/material';
import DesktopCard from '../components/desktop/DesktopCard';
import cardModules from '../components/desktop/cardModules';
import useStore from '../store/storeContext';

function Desktop() {
    const { user, session, snackbar } = useStore();

    const state = useLocalObservable(() => ({
        modules: cardModules,
        get desktopModules(): DesktopModule[] {
            return this.modules.reduce<DesktopModule[]>((modules, currentModule) => {
                if (user.isAuthorized(currentModule.name, currentModule.action)) modules.push(currentModule.module);
                return modules;
            }, []);
        },
    }));

    useEffect(() => {
        async function init() {
            if (!session.isControlDataInitialized) await session.fetchInitialControlData();
        }
        init().catch(() => snackbar.showError('GENERAL_ERROR'));
    }, []);

    return (
        <Container>
            <Box my={3}>
                <Grid container spacing={3}>
                    {state.desktopModules.map((module: DesktopModule, i) => (
                        <Grid item xs={12} sm={6} md={4} key={i}>
                            <DesktopCard desktopModule={module} />
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </Container>
    );
}

export default observer(Desktop);
