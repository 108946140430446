import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { TextFieldEvent } from '../../types/events';
import ShvkTextField from '../../styled/ShvkTextField';
import ShvkButton from '../../styled/ShvkButton';
import useStore from '../../store/storeContext';

interface Props {
    open: boolean;
    toggle(): void;
}

interface State {
    title: string;
    article: string;
}

type AddNewsFormField = 'title' | 'article';

function AddNewsDialog(props: Props) {
    const { news, localization, snackbar, theming } = useStore();

    const [state, setState] = useState<State>({
        title: '',
        article: '',
    });

    useEffect(() => {
        props.open && init();
    }, [props.open]);

    function init(): void {
        setState((state) => ({ ...state, title: '', article: '' }));
    }

    const handleChange = (event: TextFieldEvent, field: AddNewsFormField): void => {
        setState((state) => ({ ...state, [field]: event.target.value }));
    };

    async function save(): Promise<void> {
        try {
            await news.saveNews(state.title, state.article);
            snackbar.showSuccess();
        } catch (error) {
            snackbar.showError(error.data?.code);
        } finally {
            props.toggle();
        }
    }

    const isAddButtonDisabled = state.title === '' || state.article === '';
    const { translate } = localization;

    return (
        <>
            <Dialog
                open={props.open}
                onClose={props.toggle}
                aria-labelledby="add-news-dialog-title"
                maxWidth={'sm'}
                fullWidth
            >
                <DialogTitle id="add-news-dialog-title">{translate('ADD_NEWS')}</DialogTitle>
                <DialogContent dividers>
                    <ShvkTextField
                        id="news-title"
                        label={translate('TITLE')}
                        variant="outlined"
                        size="small"
                        fullWidth
                        style={{ marginBottom: theming.theme.spacing(2) }}
                        required
                        value={state.title}
                        onChange={(event): void => handleChange(event, 'title')}
                        name="title"
                    />
                    <ShvkTextField
                        required
                        id="news-article"
                        label={translate('ADD_NEWS_CONTENT')}
                        multiline
                        rows={10}
                        variant="outlined"
                        size="small"
                        fullWidth
                        style={{ marginBottom: theming.theme.spacing(2) }}
                        value={state.article}
                        onChange={(event): void => handleChange(event, 'article')}
                        name="article"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.toggle} variant="contained">
                        {translate('CANCEL')}
                    </Button>
                    <Box flexGrow={1} />
                    <ShvkButton color="primary" variant="contained" disabled={isAddButtonDisabled} onClick={save}>
                        {translate('SEND')}
                    </ShvkButton>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default observer(AddNewsDialog);
