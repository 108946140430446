import React from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Typography } from '@mui/material';
import useStore from '../../../../store/storeContext';

interface Props {
    value: number;
}

function EvaCircle(props: Props) {
    const { theming } = useStore();

    function getCircleStyle(): React.CSSProperties {
        const { value } = props;

        let color;
        if (value === 0) color = theming.shvkMediumGrey;
        else if (value < 0) color = theming.palette.error.main;
        else color = theming.palette.warning.main;

        return {
            border: '3px solid ' + color,
            width: '50px',
            height: '50px',
            borderRadius: '50%',
        };
    }

    const circleStyle = getCircleStyle();

    return (
        <Box display="flex" alignItems="center" justifyContent="center" style={circleStyle}>
            <Typography>{props.value}</Typography>
        </Box>
    );
}

export default observer(EvaCircle);
