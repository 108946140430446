import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import {
    Box,
    CircularProgress,
    Container,
    Divider,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import ConfigDataItem from '../types/configData';
import ConfigDataItemDialog from '../components/admin/ConfigDataItemDialog';
import ShvkButton from '../styled/ShvkButton';
import useStore from '../store/storeContext';

interface State {
    dialogOpen: boolean;
    fetching: boolean;
    selectedItem: ConfigDataItem | null;
}

function ConfigData() {
    const { admin, snackbar, localization, theming } = useStore();

    const [state, setState] = useState<State>({
        dialogOpen: false,
        fetching: false,
        selectedItem: null,
    });

    useEffect(() => {
        if (!admin.configData.length) getData();
    }, []);

    async function getData(): Promise<void> {
        setState((state) => ({ ...state, fetching: true }));
        try {
            await admin.fetchConfigData();
        } catch {
            snackbar.showFetchFailedMessage();
        } finally {
            setState((state) => ({ ...state, fetching: false }));
        }
    }

    const openDialog = (item: ConfigDataItem | null): void => {
        setState((state) => ({ ...state, dialogOpen: true, selectedItem: item }));
    };

    const closeDialog = (): void => {
        setState((state) => ({ ...state, dialogOpen: false }));
    };

    const { translate } = localization;

    return (
        <>
            <Container>
                <Box my={3}>
                    <Paper style={{ overflowX: 'auto' }}>
                        <Box px={2} py={1} textAlign="center">
                            <Typography variant="h6">{translate('CONFIG_DATA')}</Typography>
                        </Box>
                        <Divider />
                        <Box m={2}>
                            {state.fetching && <CircularProgress />}
                            <Table aria-label={translate('CONFIG_DATA')} size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>{translate('KEY')}</TableCell>
                                        <TableCell>{translate('VALUE')}</TableCell>
                                        <TableCell>{translate('DESCRIPTION')}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {admin.configData.map((row) => (
                                        <TableRow
                                            key={row.id}
                                            hover
                                            onClick={(): void => openDialog(row)}
                                            style={{ cursor: 'pointer' }}
                                        >
                                            <TableCell>{row.key}</TableCell>
                                            <TableCell>{row.value}</TableCell>
                                            <TableCell>{row.description}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                        <Box px={2} pb={2} display="flex" justifyContent={'left'}>
                            <ShvkButton
                                style={{ marginLeft: theming.spacing(1.5) }}
                                onClick={(): void => openDialog(null)}
                            >
                                {translate('ADD')}
                            </ShvkButton>
                        </Box>
                    </Paper>
                </Box>
            </Container>
            <ConfigDataItemDialog open={state.dialogOpen} onClose={closeDialog} configDataItem={state.selectedItem} />
        </>
    );
}

export default observer(ConfigData);
