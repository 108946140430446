import React, { useState, useEffect } from 'react';
import { observer, useLocalObservable } from 'mobx-react-lite';
import {
    Autocomplete,
    Box,
    CircularProgress,
    Container,
    Divider,
    FormLabel,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    Pagination,
} from '@mui/material';
import { Check } from '@mui/icons-material';
import { AdminOrganization, OrganizationCategory, OrganizationWithLicense } from '../types/organization';
import OrganizationItemDialog from '../components/admin/OrganizationItemDialog';
import LicenseSchema from '../types/license';
import ShvkButton from '../styled/ShvkButton';
import ShvkTextField from '../styled/ShvkTextField';
import useStore from '../store/storeContext';

type LicenseTypeInput = {
    value: LicenseSchema | null;
    inputValue: string;
};

type OrganizationTypeInput = {
    value: OrganizationCategory | null;
    inputValue: string;
};

type FieldType = 'organizationType' | 'licenseType';

interface State {
    fetching: boolean;
    dialogOpen: boolean;
    selectedOrganization: OrganizationWithLicense | null;
    searchByName: AdminOrganization | null;
    organizationType: OrganizationTypeInput;
    licenseType: LicenseTypeInput;
}

interface StoreState {
    page: number;
    pageCount: number;
    organizations: OrganizationWithLicense[];
    pageContent: OrganizationWithLicense[];
    handlePageChange: <T>(e: React.ChangeEvent<T>, value: number) => void;
    updateTable: (savedOrganization: OrganizationWithLicense) => void;
    updateOrganizations: (response: OrganizationWithLicense[], searchByNameId?: number) => void;
}

const itemsPerPage: Readonly<number> = 5;
function Organizations() {
    const { admin, organization, snackbar, localization } = useStore();

    const [state, setState] = useState<State>({
        fetching: false,
        dialogOpen: false,
        selectedOrganization: null,
        searchByName: null,
        organizationType: {
            value: null,
            inputValue: '',
        },
        licenseType: {
            value: null,
            inputValue: '',
        },
    });

    const localStore = useLocalObservable<StoreState>(() => ({
        page: 1,
        organizations: [],

        get pageCount(): number {
            return Math.ceil(this.organizations.length / itemsPerPage);
        },

        get pageContent(): OrganizationWithLicense[] {
            const pageFirstIndex = (this.page - 1) * itemsPerPage;
            return this.organizations.slice(pageFirstIndex, pageFirstIndex + itemsPerPage);
        },

        handlePageChange(_event: React.ChangeEvent<unknown>, value: number): void {
            this.page = value;
        },

        updateTable(savedOrganization: OrganizationWithLicense): void {
            const oldIndex = this.organizations.findIndex((organization) => organization.id === savedOrganization.id);
            if (oldIndex >= 0) {
                this.organizations[oldIndex] = savedOrganization;
            } else {
                this.organizations.push(savedOrganization);
            }
        },

        updateOrganizations(response: OrganizationWithLicense[], searchByNameId?: number): void {
            if (searchByNameId) {
                this.organizations = response.filter((org) => org.id === searchByNameId);
            } else {
                this.organizations = response;
            }
        },
    }));

    useEffect(() => {
        getData();
    }, []);

    async function getData(): Promise<void> {
        setState((state) => ({ ...state, fetching: true }));
        try {
            await admin.fetchLicenseSchemas();
            await organization.fetchOrganizationCategories();
            await organization.fetchAllOrganizations();
        } catch {
            snackbar.showFetchFailedMessage();
        } finally {
            setState((state) => ({ ...state, fetching: false }));
        }
    }

    async function getOrganizationsWithFilters(): Promise<void> {
        setState((state) => ({ ...state, fetching: true }));
        try {
            const licenseSchema = state.licenseType.value?.description;
            const organizationCategory = state.organizationType.value?.name;

            const response = await organization.fetchOrganizationsWithLicenses(licenseSchema, organizationCategory);

            if (state.searchByName) {
                localStore.updateOrganizations(response, state.searchByName.id);
            } else {
                localStore.updateOrganizations(response);
            }
        } catch {
            snackbar.showFetchFailedMessage();
        } finally {
            setState((state) => ({ ...state, fetching: false }));
        }
    }

    const openDialog = (item: OrganizationWithLicense | null): void => {
        setState((state) => ({ ...state, dialogOpen: true, selectedOrganization: item }));
    };

    const closeDialog = (): void => {
        setState((state) => ({ ...state, dialogOpen: false }));
    };

    const handleSearchInputChange = (value: AdminOrganization | null): void => {
        setState((state) => ({ ...state, searchByName: value }));
    };

    const handleTypeValueChange = (type: FieldType, value: LicenseSchema | OrganizationCategory | null): void => {
        setState((state) => ({ ...state, [type]: { ...state[type], value } }));
    };

    const handleTypeInputChange = (type: FieldType, inputValue: string): void => {
        setState((state) => ({ ...state, [type]: { ...state[type], inputValue } }));
    };

    const { translate } = localization;

    return (
        <>
            <Container>
                <Box my={3}>
                    <Paper>
                        <Box px={2} py={1} textAlign="center">
                            <Typography variant="h6">{translate('USER_ORGANIZATIONS')}</Typography>
                        </Box>
                        <Divider />
                        <Box m={2}>
                            <Box p={1} mb={2}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <FormLabel component="legend">{translate('FILTERING')}</FormLabel>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Autocomplete
                                            value={state.searchByName}
                                            onChange={(_event, newValue: AdminOrganization | null): void => {
                                                handleSearchInputChange(newValue);
                                            }}
                                            id="organization-license-filter"
                                            options={organization.sortedAdminOrganizations}
                                            getOptionLabel={(option: AdminOrganization): string =>
                                                option.category + ': ' + option.name
                                            }
                                            renderOption={(props: object, option): JSX.Element => (
                                                <li {...props} key={option.id}>
                                                    {option.name}
                                                </li>
                                            )}
                                            size="small"
                                            selectOnFocus
                                            renderInput={(params): JSX.Element => (
                                                <ShvkTextField
                                                    {...params}
                                                    label={translate('NAME')}
                                                    variant="outlined"
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Autocomplete
                                            value={state.licenseType.value}
                                            onChange={(_event, newValue: LicenseSchema | null): void => {
                                                handleTypeValueChange('licenseType', newValue);
                                            }}
                                            inputValue={state.licenseType.inputValue}
                                            onInputChange={(_event, newInputValue): void => {
                                                handleTypeInputChange('licenseType', newInputValue);
                                            }}
                                            id="organization-license-filter"
                                            options={admin.licenseSchemas}
                                            getOptionLabel={(option: LicenseSchema): string => option.description}
                                            size="small"
                                            selectOnFocus
                                            renderInput={(params): JSX.Element => (
                                                <ShvkTextField
                                                    {...params}
                                                    label={translate('LICENSE_TYPE')}
                                                    variant="outlined"
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Autocomplete
                                            value={state.organizationType.value}
                                            onChange={(_event, newValue: OrganizationCategory | null): void => {
                                                handleTypeValueChange('organizationType', newValue);
                                            }}
                                            inputValue={state.organizationType.inputValue}
                                            onInputChange={(_event, newInputValue): void => {
                                                handleTypeInputChange('organizationType', newInputValue);
                                            }}
                                            id="organization-category-filter"
                                            options={organization.organizationCategories}
                                            getOptionLabel={(option: OrganizationCategory): string => option.name}
                                            size="small"
                                            openOnFocus
                                            selectOnFocus
                                            renderInput={(params): JSX.Element => (
                                                <ShvkTextField
                                                    {...params}
                                                    label={translate('ORGANIZATION_TYPE')}
                                                    variant="outlined"
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <ShvkButton onClick={getOrganizationsWithFilters}>
                                            {translate('SEARCH')}
                                        </ShvkButton>
                                    </Grid>
                                </Grid>
                            </Box>
                            {state.fetching ? (
                                <Box textAlign="center">
                                    <CircularProgress />
                                </Box>
                            ) : (
                                <Table aria-label={translate('ORGANIZATION_MANAGEMENT')} size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>
                                                {translate('NAME')} {translate('FI_TRANSLATION')}
                                            </TableCell>
                                            <TableCell>
                                                {translate('NAME')} {translate('SE_TRANSLATION')}
                                            </TableCell>
                                            <TableCell>
                                                {translate('NAME')} {translate('EN_TRANSLATION')}
                                            </TableCell>
                                            <TableCell>{translate('LICENSE_TYPE')}</TableCell>
                                            <TableCell>{translate('ACTIVATED')}</TableCell>
                                            <TableCell>{translate('ORGANIZATION_TYPE')}</TableCell>
                                            <TableCell>{translate('ORGANIZATION_CODE')}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {localStore.pageContent.map((row) => (
                                            <TableRow
                                                key={row.id}
                                                hover
                                                onClick={(): void => openDialog(row)}
                                                style={{ cursor: 'pointer' }}
                                            >
                                                <TableCell>{row.name.fi_FI}</TableCell>
                                                <TableCell>{row.name.sv_SE}</TableCell>
                                                <TableCell>{row.name.en_GB}</TableCell>
                                                <TableCell>
                                                    {admin.getLicenseSchemaById(row.licenseSchema)?.description}
                                                </TableCell>
                                                <TableCell align="center">{row.active && <Check />}</TableCell>
                                                <TableCell>{row.category}</TableCell>
                                                <TableCell>{row.code}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            )}
                        </Box>
                        <Box px={2} pb={2} display="flex">
                            <Pagination
                                count={localStore.pageCount}
                                page={localStore.page}
                                onChange={localStore.handlePageChange}
                                color="primary"
                            />
                            <Box flexGrow={1} />
                            <ShvkButton onClick={(): void => openDialog(null)}>{translate('ADD')}</ShvkButton>
                        </Box>
                    </Paper>
                </Box>
            </Container>
            <OrganizationItemDialog
                open={state.dialogOpen}
                onClose={closeDialog}
                organization={state.selectedOrganization}
                updateTable={localStore.updateTable}
            />
        </>
    );
}

export default observer(Organizations);
