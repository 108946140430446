import React, { useState, useEffect } from 'react';
import { observer, useLocalObservable } from 'mobx-react-lite';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { EditorDecisionOption } from '../../../../types/evaTemplate';
import { TextFieldEvent } from '../../../../types/events';
import { Chapter } from '../../../../types/document';
import ShvkButton from '../../../../styled/ShvkButton';
import ShvkTextField from '../../../../styled/ShvkTextField';
import useStore from '../../../../store/storeContext';

interface Props {
    decisionOption?: EditorDecisionOption | null;
    chapter: Chapter | null | undefined;
    isOpen: boolean;
    close(): void;
}

interface State {
    title: string;
    description: string;
}

interface StoreState {
    dialogTitle: string;
}

type FormField = 'title' | 'description';

function AddDecisionOptionDialog(props: Props) {
    const { document, localization, snackbar, theming, loadingIndicator } = useStore();

    const [state, setState] = useState<State>({
        title: '',
        description: '',
    });

    const localStore = useLocalObservable<StoreState>(() => ({
        get dialogTitle(): string {
            const { translate } = localization;
            const { decisionOption } = props;
            const isEvaMini = document.isCurrentDocumentEvaMini;

            if (decisionOption) {
                if (isEvaMini) return translate('EVA_MINI_EDIT_DECISION_OPTION');
                else return translate('EVA_EDIT_DECISION_OPTION');
            } else {
                if (isEvaMini) return translate('EVA_MINI_ADD_DECISION_OPTION');
                else return translate('EVA_ADD_DECISION_OPTION');
            }
        },
    }));

    useEffect(() => {
        props.isOpen && init();
    }, [props.isOpen]);

    function init(): void {
        setState((state) => ({
            ...state,
            title: props.decisionOption?.text || '',
            description: props.decisionOption?.description || '',
        }));
    }

    const handleChange = (event: TextFieldEvent, field: FormField): void => {
        setState((state) => ({
            ...state,
            [field]: event.target.value,
        }));
    };

    async function save(): Promise<void> {
        const params = {
            chapter: props.chapter,
            chapterId: props.chapter?.id,
            description: state.description,
            text: state.title,
            id: props.decisionOption?.id,
        };

        try {
            loadingIndicator.show();
            if (props.decisionOption) await document.saveEvaDecisionOption(params);
            else await document.addEvaDecisionOption(params);
        } catch (error) {
            snackbar.showError(error.data?.code);
        } finally {
            props.close();
            loadingIndicator.hide();
        }
    }

    const { translate } = localization;
    const isEvaMini = document.isCurrentDocumentEvaMini;

    return (
        <Dialog
            open={props.isOpen}
            onClose={props.close}
            aria-labelledby="add-decision-option-dialog-title"
            maxWidth={'sm'}
            fullWidth
        >
            <DialogTitle id="add-decision-option-dialog-title">{localStore.dialogTitle}</DialogTitle>
            <DialogContent dividers>
                <ShvkTextField
                    required
                    label={translate(isEvaMini ? 'EVA_MINI_DECISION_OPTION' : 'EVA_DECISION_OPTION')}
                    multiline
                    rows={5}
                    style={{ marginBottom: theming.theme.spacing(2) }}
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={state.title}
                    onChange={(event): void => handleChange(event, 'title')}
                />
                <ShvkTextField
                    label={translate('EVA_DECISION_OPTION_DESCRIPTION')}
                    multiline
                    rows={5}
                    style={{ marginBottom: theming.theme.spacing(2) }}
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={state.description}
                    onChange={(event): void => handleChange(event, 'description')}
                />
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={props.close}>
                    {translate('CANCEL')}
                </Button>
                <Box flexGrow={1} />
                <ShvkButton onClick={save} disabled={!state.title}>
                    {translate('SAVE')}
                </ShvkButton>
            </DialogActions>
        </Dialog>
    );
}

export default observer(AddDecisionOptionDialog);
