import Axios from '../../axiosConfig';
import { BACKEND_URL } from '../../config';
import { configure, runInAction, makeAutoObservable } from 'mobx';
import { ShvkDocumentType } from '../../types/document';
import { LocalizedName } from '../../types/localization';
import ChapterTemplate from '../../types/chapterTemplate';
import RootModule from '../rootModule';

configure({ enforceActions: 'observed' });

export default class ChapterTemplateModule {
    private rootModule: RootModule;
    private docTypes: ShvkDocumentType[] = [];
    private chapTemplates: ChapterTemplate[] = [];
    private availableTipTypes: LocalizedName[] = [];

    constructor(rootModule: RootModule) {
        makeAutoObservable(this, {}, { autoBind: true });
        this.rootModule = rootModule;
    }

    public async fetchAllChapterTemplates(): Promise<void> {
        const response = await Axios({
            method: 'GET',
            url: BACKEND_URL + 'template/getAll',
        });
        if (response.status === 200) runInAction(() => (this.chapTemplates = response.data));
    }

    public async fetchAvailableDocTypes(): Promise<void> {
        const response = await Axios({
            method: 'POST',
            url: BACKEND_URL + 'template/getAvailableDocTypesAndDescriptions',
            data: { lang: this.rootModule.localization.locale },
        });
        if (response.status === 200) runInAction(() => (this.docTypes = response.data));
    }

    public async fetchAvailableTipTypes(): Promise<void> {
        const response = await Axios({
            method: 'GET',
            url: BACKEND_URL + 'template/getAvailableTipTypesAndDescriptions',
        });
        if (response.status === 200) runInAction(() => (this.availableTipTypes = response.data));
    }

    public async saveChapterTemplate(template: ChapterTemplate): Promise<void> {
        const response = await Axios({
            method: 'POST',
            url: BACKEND_URL + 'template/save',
            data: template,
        });

        const newTemplate = response.data as ChapterTemplate;
        runInAction(() => {
            this.chapTemplates.push(newTemplate);
        });
    }

    public async updateChapterTemplate(template: ChapterTemplate): Promise<void> {
        const response = await Axios({
            method: 'POST',
            url: BACKEND_URL + 'template/update',
            data: template,
        });

        const updatedTemplate = response.data;
        runInAction(() => {
            const index = this.chapTemplates.findIndex((template) => template.id === updatedTemplate.id);
            this.chapTemplates[index] = updatedTemplate;
            this.chapTemplates = this.chapTemplates.map((template) => {
                if (template.title.id === updatedTemplate.title.id) {
                    template.title = updatedTemplate.title;
                    return template;
                }
                return template;
            });
        });
    }

    public async deleteChapterTemplate(id: number): Promise<void> {
        await Axios({
            method: 'GET',
            url: BACKEND_URL + 'template/remove?id=' + id,
        });

        runInAction(() => {
            this.chapTemplates = this.chapTemplates.filter((template) => template.id !== id);
        });
    }

    public get documentTypes(): ShvkDocumentType[] {
        return this.docTypes;
    }

    public get tipTypes(): LocalizedName[] {
        return this.availableTipTypes;
    }

    public get chapterTemplates(): ChapterTemplate[] {
        return this.chapTemplates
            .slice()
            .sort((a, b) => (a.type > b.type ? 1 : a.type < b.type ? -1 : 0 || a.order > b.order ? 1 : -1));
    }
}
