import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Container, Divider, Paper, Typography } from '@mui/material';
import MaterialTextAccordion from '../components/material/MaterialTextAccordion';
import CarouselImageAccordion from '../components/material/CarouselImageAccordion';
import UploadBackgroundAccordion from '../components/material/UploadBackgroundAccordion';
import useStore from '../store/storeContext';

function MaterialManagement() {
    const { info, image, snackbar, localization } = useStore();

    useEffect(() => {
        void getData();
    }, []);

    async function getData(): Promise<void> {
        try {
            await info.fetchShvkInfo();
            await info.fetchMaterialText();
            await image.getAllCarouselImages();
            await info.fetchCookiesInfo();
            await info.fetchAccessibilityStatement();
        } catch {
            snackbar.showFetchFailedMessage();
        }
    }

    const { translate } = localization;

    return (
        <Container>
            <Box my={3}>
                <Paper>
                    <Box px={2} py={1} textAlign="center">
                        <Typography variant="h6">{translate('MATERIAL_MANAGEMENT')}</Typography>
                    </Box>
                    <Divider />
                    <Box m={2} pb={2}>
                        <MaterialTextAccordion
                            title={'TOOL_INFO_TITLE'}
                            id="tool-info-management"
                            localizedName={info.shvkInfo}
                            save={info.saveInfoText}
                        />
                        <MaterialTextAccordion
                            title={'TOOL_MATERIAL_TITLE'}
                            id="tool-material-management"
                            localizedName={info.materialText}
                            save={info.saveMaterialText}
                        />
                        <CarouselImageAccordion title={'FRONTPAGE_CAROUSEL_IMAGES'} id="carousel-image-management" />
                        <UploadBackgroundAccordion title={'FRONTPAGE_WALLPAPER'} id={'background-image-management'} />
                        <MaterialTextAccordion
                            title={'COOKIES_AND_PRIVACY'}
                            id="cookies-and-privacy-management"
                            localizedName={info.cookiesInfo}
                            save={info.saveCookiesText}
                        />
                        <MaterialTextAccordion
                            title={'ACCESSIBILITY_STATEMENT'}
                            id="accessibility-statement-management"
                            localizedName={info.accessibilityStatement}
                            save={info.saveAccessibilityStatement}
                        />
                    </Box>
                </Paper>
            </Box>
        </Container>
    );
}

export default observer(MaterialManagement);
