import React, { ReactNode, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import downloadjs from 'downloadjs';
import { renderToStaticMarkup } from 'react-dom/server';
import { CartesianGrid, Label, Line, LineChart, ReferenceLine, Tooltip, XAxis, YAxis } from 'recharts';
import {
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControlLabel,
    Radio,
    Tooltip as MuiTooltip,
} from '@mui/material';
import { IndicatorSvgParams } from '../../../../types/document';
import { Indicator, IndicatorData } from '../../../../types/indicator';
import InfoIcon from '@mui/icons-material/Info';
import ShvkButton from '../../../../styled/ShvkButton';
import PowerPointDialogIndicatorPackage from './PowerPointDialogIndicatorPackage';
import useStore from '../../../../store/storeContext';

interface Props {
    isOpen: boolean;
    close(): void;
}

interface State {
    indicatorDescription: boolean;
    greyScale: boolean;
    radioSelection: RadioSelection;
}

type GraphDataItem = {
    values: IndicatorData[];
    strokeWidth: number;
    color: string;
    orderNumber: number;
    name: string;
};

type RadioSelection = 'large' | 'small';

function DownloadPowerPointDialog(props: Props) {
    const context = useStore();
    const { document, localization, loadingIndicator, snackbar, theming } = context;

    const [selectedIndicators, setSelectedIndicators] = useState<Indicator[]>([] as Indicator[]);

    const [state, setState] = useState<State>({
        indicatorDescription: false,
        greyScale: false,
        radioSelection: 'large',
    });

    useEffect(() => {
        props.isOpen && void init();

        // cleanup selectedIndicators when dialog unmounts
        return () => {
            setSelectedIndicators([]);
        };
    }, [props.isOpen]);

    async function init(): Promise<void> {
        setState((state) => ({
            ...state,
            indicatorDescription: false,
            greyScale: false,
            radioSelection: 'large',
        }));

        const { indicatorPackageChapter } = document;
        const packageIds: number[] = [];

        indicatorPackageChapter?.indicatorPackages.forEach((indicatorPackage) => {
            // If theme is not in the store yet. Fetch it.
            if (indicatorPackage.themes.length === 0) packageIds.push(indicatorPackage.id);
        });

        if (packageIds.length > 0) {
            loadingIndicator.show();
            try {
                for (const id of packageIds) {
                    await context.indicator.fetchPackageThemes(id);
                }
            } catch (error) {
                snackbar.showFetchFailedMessage(error.data?.code);
            } finally {
                loadingIndicator.hide();
            }
        }
        selectEnabled();
    }

    function selectEnabled(): void {
        const selected = [...selectedIndicators];
        const { indicatorPackageChapter } = document;
        const indicatorPackages = indicatorPackageChapter?.indicatorPackages;
        const themes = indicatorPackages?.flatMap((indpkg) => indpkg.themes);
        if (themes) {
            themes.forEach((theme) => {
                theme.sotkanetIndicators.forEach((indicator) => indicator.enabled && selected.push(indicator));
                theme.organizationIndicators.forEach((indicator) => indicator.enabled && selected.push(indicator));
                theme.otherIndicators.forEach((indicator) => indicator.enabled && selected.push(indicator));
            });
        }
        setSelectedIndicators(selected);
    }

    function selectMany(indicators: Indicator[]): void {
        let selected = [...selectedIndicators];
        if (indicators.every((indicator) => selectedIndicators.includes(indicator))) {
            selected = selectedIndicators.filter((indicator) => !indicators.includes(indicator));
        } else {
            indicators.forEach((indicator) => !selectedIndicators.includes(indicator) && selected.push(indicator));
        }
        setSelectedIndicators(selected);
    }

    async function download(): Promise<void> {
        const selectedIndicatorsWithData = selectedIndicators.filter((indicator) => indicator.data.length !== 0);
        if (selectedIndicatorsWithData.length !== selectedIndicators.length) {
            if (selectedIndicatorsWithData.length === 0) {
                snackbar.showError('INDICATOR_DATA_MISSING');
                return;
            }
        }

        const params = {
            documentId: document.currentDocument.id,
            images: state.radioSelection === 'large' ? await images(selectedIndicatorsWithData) : [],
            indicatorDescription: state.indicatorDescription,
            largeOrSmallGraph: state.radioSelection,
            indicatorIds: state.radioSelection === 'small' ? selectedIndicators.map((indicator) => indicator.id) : [],
            colorSchema: state.greyScale
                ? 'greyScale'
                : theming.getChosenPalette === 'bluePalette'
                ? 'blueThema'
                : 'defaultThema',
        };

        try {
            loadingIndicator.show();
            const powerPoint = await document.downloadPowerPoint(params);
            downloadjs(
                `data:application/vnd.openxmlformats-officedocument.presentationml.presentation;base64,${powerPoint}`,
                `${document.currentDocument.name}.pptx`,
                '.pptx',
            );
            snackbar.showSuccess();
        } catch (error) {
            snackbar.showError(error.data?.code);
        } finally {
            props.close();
            loadingIndicator.hide();
        }
    }

    function tableYears(indicator: Indicator): number[] {
        const { comparisonSettings } = context.indicator;
        if (!comparisonSettings) return [];
        if (!comparisonSettings.startYear || !comparisonSettings.endYear) return [];

        return dataForGraph(indicator)
            .flatMap((item: any) => item.year)
            .sort((a: number, b: number) => a - b);
    }

    async function images(selectedIndicatorsWithData: Indicator[]): Promise<IndicatorSvgParams[]> {
        return await Promise.all(
            selectedIndicatorsWithData.map(async (indicator) => await createIndicatorSvg(indicator)),
        );
    }

    async function createIndicatorSvg(indicator: Indicator): Promise<IndicatorSvgParams> {
        const { translate } = localization;
        const minLimit = indicator?.alarm_min?.toString() === '-1' ? '' : indicator?.alarm_min?.toString();
        const maxLimit = indicator?.alarm_max?.toString() === '-1' ? '' : indicator?.alarm_max?.toString();
        const targetLimit = indicator?.target_limit?.toString() === '-1' ? '' : indicator?.target_limit?.toString();
        const yAxisScale = document.currentDocument.documentIndicatorSetting.graphScale;

        const graph = (
            <LineChart
                width={1010}
                height={500}
                data={dataForGraph(indicator)}
                margin={{
                    top: 5,
                    right: 210,
                    left: 5,
                    bottom: 5,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                    fontFamily="Work sans"
                    fontSize="12"
                    color="black"
                    stroke="black"
                    dataKey="year"
                    ticks={tableYears(indicator)}
                    type="number"
                    domain={[tableYears(indicator)[0], tableYears(indicator)[tableYears.length - 1]]}
                />
                <YAxis
                    fontFamily="Work sans"
                    fontSize="12"
                    color="black"
                    stroke="black"
                    type="number"
                    domain={[
                        (dataMin: number): number =>
                            Math.floor(
                                Math.min(
                                    dataMin,
                                    minLimit ? Number(minLimit) : dataMin,
                                    targetLimit ? Number(targetLimit) : dataMin,
                                    yAxisScale ? dataMin : 0,
                                ),
                            ),
                        (dataMax: number): number =>
                            Math.ceil(
                                Math.max(
                                    dataMax,
                                    maxLimit ? Number(maxLimit) : dataMax,
                                    targetLimit ? Number(targetLimit) : dataMax,
                                ),
                            ),
                    ]}
                >
                    <Label content={(props) => labelContent(props, indicator)} offset={0} position="insideBottom" />
                </YAxis>
                {maxLimit && (
                    <ReferenceLine
                        y={maxLimit}
                        label={translate('INDICATOR_MAX_LIMIT_GRAPH_LEGEND_TEXT')}
                        stroke={state.greyScale ? 'grey' : 'red'}
                        strokeDasharray="3 3"
                    />
                )}
                {targetLimit && (
                    <ReferenceLine
                        y={targetLimit}
                        label={translate('INDICATOR_TARGET_LIMIT_GRAPH_LEGEND_TEXT')}
                        stroke={state.greyScale ? 'grey' : 'green'}
                        strokeDasharray="3 3"
                    />
                )}
                {minLimit && (
                    <ReferenceLine
                        y={minLimit}
                        label={translate('INDICATOR_MIN_LIMIT_GRAPH_LEGEND_TEXT')}
                        stroke={state.greyScale ? 'grey' : 'orange'}
                        strokeDasharray="3 3"
                    />
                )}
                <Tooltip />
                {organizationsForGraph(indicator).map((organization, index) => (
                    <Line
                        key={organization.name}
                        type="monotoneX"
                        dot={false}
                        connectNulls
                        dataKey={organization.name}
                        stroke={
                            state.greyScale
                                ? theming.graphOrganizationColorsGreyScale[index]
                                : theming.graphOrganizationColors[index]
                        }
                        strokeWidth={document.currentDocument.organization.name === organization.name ? 3 : 1.5}
                        strokeDasharray={state.greyScale ? theming.graphOrganizationDasharrays[index] : ''}
                    />
                ))}
            </LineChart>
        );

        const svgString = renderToStaticMarkup(graph);

        return {
            height: 480,
            indicatorId: indicator.id,
            name: indicator.name,
            description: indicator.description!,
            source: indicator.source.code,
            svg: svgString,
            width: 980,
        };
    }

    function labelContent(props: any, indicator: Indicator): ReactNode {
        const organizations = organizationsForGraph(indicator)
            .map((org, i) => {
                return {
                    name: org.name,
                    value: org.value,
                    color: state.greyScale
                        ? theming.graphOrganizationColorsGreyScale[i]
                        : theming.graphOrganizationColors[i],
                    dash: theming.graphOrganizationDasharrays[i],
                    home: document.currentDocument.organization.name === org.name,
                };
            })
            .slice()
            .sort((a, b) => (a.value && b.value ? b.value - a.value : 0));
        return (
            <>
                {organizations.map((org, i) => {
                    return (
                        <g key={org.name}>
                            {state.greyScale ? (
                                <line
                                    x1={props.viewBox.x + 800}
                                    x2={props.viewBox.x + 800 + 10}
                                    y1={props.viewBox.y + 6 + i * 14}
                                    y2={props.viewBox.y + 6 + i * 14}
                                    stroke={org.color}
                                    strokeWidth={org.home ? 5 : 3}
                                    strokeDasharray={org.dash}
                                />
                            ) : (
                                <circle
                                    cx={props.viewBox.x + 800 + 5}
                                    cy={props.viewBox.y + 5 + i * 14}
                                    r={4}
                                    fill={org.color}
                                />
                            )}
                            <text
                                fontFamily="Work sans"
                                fontSize="12"
                                color="black"
                                fontWeight={org.home ? 'bold' : 'normal'}
                                x={props.viewBox.x + 800 + 12}
                                y={props.viewBox.y + 10 + i * 14}
                            >
                                {org.name.length > 22 ? org.name.slice(0, 22) + '...' : org.name}
                            </text>
                            <text
                                fontFamily="Work sans"
                                fontSize="12"
                                fontWeight={org.home ? 'bold' : 'normal'}
                                x={props.viewBox.x + 800 + 160}
                                y={props.viewBox.y + 10 + i * 14}
                            >
                                {org.value}
                            </text>
                        </g>
                    );
                })}
            </>
        );
    }

    function dataForGraph(indicator: Indicator): any {
        const years = new Set(graphData(indicator).flatMap((data) => data.values.flatMap((value) => value.year)));
        const data = [...years].map((year) => ({
            year: year,
        }));
        data.forEach((data: any) => {
            graphData(indicator).forEach((organization) => {
                data[organization.name] = organization.values.find((o) => o.year === data.year)?.value ?? null;
            });
        });
        return data.sort((a, b) => b.year - a.year);
    }

    function graphData(indicator: Indicator): GraphDataItem[] {
        const {
            removeDuplicatesFromIndicatorData,
            groupIndicatorDataByOrganization,
            filterIndicatorDataBetweenCompareYears,
        } = context.indicator;
        const { currentDocument } = document;

        const data: IndicatorData[] = indicator.data;

        const currentOrganizationName = currentDocument.organization.name;
        const uniques = removeDuplicatesFromIndicatorData(data);
        const dataBetweenCompareYears = filterIndicatorDataBetweenCompareYears(uniques);
        const sortedByYear = dataBetweenCompareYears.sort((a, b) => a.year - b.year);
        const organizationData = groupIndicatorDataByOrganization(sortedByYear);

        let colorIndex = 0;
        let graphData: GraphDataItem[] = organizationData.map((dataItem) => {
            const isCurrentOrganization = dataItem.organizationName === currentOrganizationName;
            let color = theming.graphOrganizationHomeColor;

            if (!isCurrentOrganization) {
                color =
                    colorIndex < theming.graphOrganizationColors.length
                        ? theming.graphOrganizationColors[colorIndex++]
                        : theming.graphOrganizationColors[0];
            }

            return {
                color,
                name: dataItem.organizationShortName || dataItem.organizationName,
                orderNumber: getOrganizationOrderNumber(dataItem.organizationName),
                strokeWidth: isCurrentOrganization ? 3 : 1.5,
                values: dataItem.data,
            };
        });

        if (indicator.source.code === 'CHP_SOTKANET') {
            graphData = patchDataWithOrganizationsFromComparisonSettings(graphData);
        }

        return graphData.filter((d) => d.color !== '').sort((a, b) => a.orderNumber - b.orderNumber);
    }

    function patchDataWithOrganizationsFromComparisonSettings(data: GraphDataItem[]): GraphDataItem[] {
        const { comparisonSettings } = context.indicator;
        if (!comparisonSettings) return data;

        // Some organizations may not have any data. Add empty data objects.
        comparisonSettings.organizations.forEach((organization) => {
            const organizationIsMissingFromList = !data.some((item) => item.name === organization.name);
            if (organizationIsMissingFromList) {
                data.push({
                    color: '',
                    name: organization.name,
                    orderNumber: getOrganizationOrderNumber(organization.name),
                    strokeWidth: 0,
                    values: [],
                });
            }
        });

        return data;
    }

    function getOrganizationOrderNumber(organizationName: string): number {
        const { comparisonSettings } = context.indicator;
        if (!comparisonSettings) return 0;
        const organization = comparisonSettings.organizations.find((org) => org.name === organizationName);
        return organization?.orderNumber || 0;
    }

    function organizationsForGraph(indicator: Indicator): { name: string; value: number | null }[] {
        return graphData(indicator).map((data) => {
            return {
                name: data.name,
                value: data.values[data.values.length - 1]?.value ?? null,
            };
        });
    }

    const { translate } = localization;
    const { indicatorPackageChapter } = document;

    return (
        <Dialog
            open={props.isOpen}
            onClose={props.close}
            aria-labelledby="download-pdf-dialog-title"
            maxWidth={'md'}
            fullWidth
        >
            <DialogTitle id="download-pdf-dialog-title">
                {translate('SELECT_INDICATORS_FOR_POWER_POINT_TITLE')}
                <MuiTooltip
                    placement="right-start"
                    title={
                        <span style={{ whiteSpace: 'pre-line' }}>
                            {translate('SELECT_INDICATORS_FOR_POWER_POINT_TIP')}
                        </span>
                    }
                >
                    <InfoIcon
                        color="primary"
                        style={{
                            display: 'inline-block',
                            verticalAlign: 'middle',
                            paddingBottom: '3px',
                            marginLeft: theming.spacing(1),
                        }}
                    />
                </MuiTooltip>
            </DialogTitle>
            <DialogContent dividers>
                <Box>
                    <FormControlLabel
                        label={translate('PRINT_LARGE_INDICATOR_GRAPH')}
                        control={
                            <Radio
                                color="primary"
                                checked={state.radioSelection === 'large'}
                                value={'large'}
                                onChange={(e) =>
                                    setState((state) => ({
                                        ...state,
                                        radioSelection: e.target.value as RadioSelection,
                                    }))
                                }
                            />
                        }
                    />
                </Box>
                <Box>
                    <FormControlLabel
                        label={translate('PRINT_SMALL_INDICATOR_GRAPH')}
                        control={
                            <Radio
                                color="primary"
                                checked={state.radioSelection === 'small'}
                                value={'small'}
                                onChange={(e) =>
                                    setState((state) => ({
                                        ...state,
                                        radioSelection: e.target.value as RadioSelection,
                                    }))
                                }
                            />
                        }
                    />
                </Box>
                <Box>
                    <FormControlLabel
                        label={translate('BLACK_AND_WHITE_PRINT_OPTION')}
                        control={
                            <Checkbox
                                color="primary"
                                onChange={(e) => setState((state) => ({ ...state, greyScale: e.target.checked }))}
                            />
                        }
                    />
                </Box>
                <Box>
                    <FormControlLabel
                        label={translate('PRINT_INDICATOR_DESCRIPTION')}
                        control={
                            <Checkbox
                                color="primary"
                                onChange={(e) =>
                                    setState((state) => ({ ...state, indicatorDescription: e.target.checked }))
                                }
                            />
                        }
                    />
                </Box>
                <Box my={2}>
                    <Divider />
                </Box>
                {indicatorPackageChapter?.indicatorPackages.map((indicatorPackage) => (
                    <PowerPointDialogIndicatorPackage
                        indicatorPackage={indicatorPackage}
                        key={indicatorPackage.id}
                        selectedIndicators={selectedIndicators}
                        selectMany={selectMany}
                    />
                ))}
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={props.close}>
                    {translate('CLOSE')}
                </Button>
                <Box flexGrow={1} />
                <ShvkButton onClick={download}>{translate('PRINT')}</ShvkButton>
            </DialogActions>
        </Dialog>
    );
}

export default observer(DownloadPowerPointDialog);
