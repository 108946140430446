import {
    Announcement,
    Business,
    Description,
    Edit,
    FindInPage,
    Fingerprint,
    Help,
    Info,
    Language,
    Person,
    Settings,
    VerifiedUser,
} from '@mui/icons-material';
import { Module } from '../../types/desktopModule';

const cardModules: Readonly<Module[]> = [
    {
        name: 'news_management',
        action: 'read_news',
        module: {
            icon: Announcement,
            title: 'NEWS_MANAGEMENT',
            subtitle: 'NEWS_MANAGEMENT_DESCRIPTION',
            link: 'newsmanagement',
        },
    },
    {
        name: 'translations_management',
        action: 'read_translation',
        module: {
            icon: Language,
            title: 'TRANSLATION_MANAGEMENT',
            subtitle: 'TRANSLATION_MANAGEMENT_DESCRIPTION',
            link: 'translations',
        },
    },
    {
        name: 'chapter_template',
        action: 'read_chapter_template',
        module: {
            icon: Description,
            title: 'CHAPTER_TEMPLATE_MANAGEMENT',
            subtitle: 'CHAPTER_TEMPLATE_MANAGEMENT_DESCRIPTION',
            link: 'chaptertemplates',
        },
    },
    {
        name: 'user_management',
        action: 'read_user',
        module: {
            icon: Person,
            title: 'USER_MANAGEMENT',
            subtitle: 'USER_MANAGEMENT_DESCRIPTION',
            link: 'usermanagement',
        },
    },
    {
        name: 'documents',
        action: 'search_documents',
        module: {
            icon: FindInPage,
            title: 'DOCUMENT_SEARCH',
            subtitle: 'DOCUMENT_SEARCH_DESCRIPTION',
            link: 'documentsearch',
        },
    },
    {
        name: 'organization_management',
        action: 'read_organization',
        module: {
            icon: Business,
            title: 'ORGANIZATION_MANAGEMENT',
            subtitle: 'ORGANIZATION_MANAGEMENT_DESCRIPTION',
            link: 'organizations',
        },
    },
    {
        name: 'audit_view',
        action: 'read_audit_view',
        module: {
            icon: Fingerprint,
            title: 'AUDIT_LOG',
            subtitle: 'AUDIT_LOG_DESCRIPTION',
            link: 'auditlog',
        },
    },
    {
        name: 'config_view',
        action: 'read_config_view',
        module: {
            icon: Settings,
            title: 'CONFIG_DATA',
            subtitle: 'CONFIG_DATA_DESCRIPTION',
            link: 'configdata',
        },
    },
    {
        name: 'license_management',
        action: 'update_license',
        module: {
            icon: VerifiedUser,
            title: 'LICENSE_MANAGEMENT',
            subtitle: 'LICENSE_MANAGEMENT_DESCRIPTION',
            link: 'license',
        },
    },
    {
        name: 'material_management',
        action: 'modify_info',
        module: {
            icon: Info,
            title: 'MATERIAL_MANAGEMENT',
            subtitle: 'MATERIAL_MANAGEMENT_DESCRIPTION',
            link: 'material',
        },
    },
    {
        name: 'organization_eva_template',
        action: 'modify',
        module: {
            icon: Edit,
            title: 'ORGANIZATION_EVA_TEMPLATE_MANAGEMENT',
            subtitle: 'ORGANIZATION_EVA_TEMPLATE_MANAGEMENT_TEXT',
            link: 'evatemplates',
        },
    },
    {
        name: 'help_text_management',
        action: 'read_help_text',
        module: {
            icon: Help,
            title: 'HELP_TEXT_MANAGEMENT',
            subtitle: 'HELP_TEXT_MANAGEMENT_DESCRIPTION',
            link: 'helptexts',
        },
    },
];

export default cardModules;
