import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, Typography } from '@mui/material';
import { TextFieldEvent } from '../../types/events';
import Feedback, { FeedbackType } from '../../types/feedback';
import ShvkTextField from '../../styled/ShvkTextField';
import ShvkButton from '../../styled/ShvkButton';
import useStore from '../../store/storeContext';

interface Props {
    open: boolean;
    onClose(): void;
    feedbackTypes: FeedbackType[];
}

interface State {
    type: string;
    email: string;
    message: string;
}

type FeedbackFormField = 'type' | 'email' | 'message';

function FeedbackDialog(props: Props) {
    const { feedback, localization, snackbar, theming } = useStore();

    const [state, setState] = useState<State>({
        type: '',
        email: '',
        message: '',
    });

    useEffect(() => {
        props.open && init();
    }, [props.open]);

    function init(): void {
        setState((state) => ({ ...state, type: '', email: '', message: '' }));
    }

    const textStyle = {
        width: '100%',
        marginBottom: theming.spacing(2),
    };

    const handleChange = (event: TextFieldEvent, field: FeedbackFormField): void => {
        setState((state) => ({ ...state, [field]: event.target.value as string }));
    };

    async function send(): Promise<void> {
        try {
            const feedbackParams: Feedback = {
                message: state.message,
                senderEmail: state.email,
                type: feedback.getFeedbackTypeById(Number(state.type))!,
            };
            await feedback.sendFeedback(feedbackParams);
            snackbar.showSuccess('THANK_YOU_FOR_THE_FEEDBACK');
        } catch (error) {
            snackbar.showError(error.data?.code);
        } finally {
            props.onClose();
        }
    }

    const { translate } = localization;

    return (
        <Dialog open={props.open} onClose={props.onClose} aria-labelledby="feedback-dialog-title" maxWidth={'md'}>
            <DialogTitle id="feedback-dialog-title">{translate('FEEDBACK_DLG_TITLE')}</DialogTitle>
            <DialogContent dividers>
                <Typography style={textStyle}>{translate('FEEDBACK_DLG_HEADER')}</Typography>
                <ShvkTextField
                    select
                    id="select-feedback-type"
                    onChange={(event): void => handleChange(event, 'type')}
                    label={translate('FEEDBACK_TYPE')}
                    value={state.type}
                    name="type"
                    required
                    variant="outlined"
                    fullWidth
                    style={{ marginBottom: theming.theme.spacing(2) }}
                    size="small"
                >
                    {props.feedbackTypes.map((feedbackType: FeedbackType) => (
                        <MenuItem key={feedbackType.id} value={feedbackType.id.toString()}>
                            {feedbackType.name}
                        </MenuItem>
                    ))}
                </ShvkTextField>
                <ShvkTextField
                    required
                    id="feedback-textfield"
                    label={translate('FEEDBACK')}
                    multiline
                    rows={10}
                    value={state.message}
                    variant="outlined"
                    size="small"
                    fullWidth
                    style={{ marginBottom: theming.theme.spacing(2) }}
                    onChange={(event): void => handleChange(event, 'message')}
                    name="feedback"
                />
                <ShvkTextField
                    id="feedback-email"
                    label={translate('EMAIL')}
                    value={state.email}
                    variant="outlined"
                    size="small"
                    fullWidth
                    style={{ marginBottom: theming.theme.spacing(2) }}
                    helperText={translate('FEEDBACK_DLG_EMAIL_TITLE')}
                    onChange={(event): void => handleChange(event, 'email')}
                    name="email"
                    type="email"
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose} variant="contained">
                    {translate('CANCEL')}
                </Button>
                <Box flexGrow={1} />
                <ShvkButton onClick={send} disabled={!state.message || !state.type}>
                    {translate('SEND')}
                </ShvkButton>
            </DialogActions>
        </Dialog>
    );
}

export default observer(FeedbackDialog);
