import React, { useEffect } from 'react';
import Nestable, { NestableItem, RenderCollapseIconArgs, RenderItemArgs } from 'react-nestable';
import { observer, useLocalObservable } from 'mobx-react-lite';
import { runInAction } from 'mobx';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Tooltip,
    Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Info, KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { EvaModelNestableItem, EvaModelRenderItemArgs, RemovedEvaModelNestableItem } from '../../../../types/nestable';
import { EditorDecisionOption, EditorImpactOnTarget, EditorPerspective } from '../../../../types/evaTemplate';
import { addDepths, addItem, addParentIds, editText, removeItem } from '../../../../utils/NestableUtils';
import { SaveEvaModelParams } from '../../../../types/indicator';
import { Chapter } from '../../../../types/document';
import EvaModelNestableComponent from '../../eva/EvaModelNestableComponent';
import ShvkButton from '../../../../styled/ShvkButton';
import useStore from '../../../../store/storeContext';

const PREFIX = 'EditEvaModelDialog';

const classes = {
    paper: `${PREFIX}-paper`,
};

const StyledDialog = styled(Dialog)(() => ({
    [`& .${classes.paper}`]: {
        height: '90%',
    },
}));

interface Props {
    isOpen: boolean;
    close(): void;
}

interface StoreState {
    items: EvaModelNestableItem[];
    removedItems: RemovedEvaModelNestableItem[];
    getItems: EvaModelNestableItem[];
    evaModel: SaveEvaModelParams;
    options: EditorDecisionOption[];
    isMaxDecisionOptions: boolean;
    setItems: (newItems: EvaModelNestableItem[]) => void;
}

// As state operations are asynchronous, and we are dealing with nested objects that need new temp ids,
// we need to declare id variables here instead of React state variable.
let nextNewId = -1;
let nextNewGroupNumber = -1;

function EditEvaModelDialog(props: Props) {
    const { document, localization, loadingIndicator, snackbar, theming } = useStore();

    const localStore = useLocalObservable<StoreState>(() => ({
        items: [],
        removedItems: [],

        get getItems(): EvaModelNestableItem[] {
            return this.items;
        },

        get evaModel(): SaveEvaModelParams {
            const removedItems = this.removedItems;
            const decisionOptions = this.getItems.map((decision) => {
                return {
                    id: decision.id,
                    text: decision.text,
                    description: decision.description,
                    perspectives: decision.children.map((perspective) => {
                        return {
                            text: perspective.text,
                            oldTitle: perspective.oldTitle!,
                            description: perspective.description,
                            impactOnTargets: perspective.children.map((impact) => {
                                return {
                                    text: impact.text,
                                    oldTitle: impact.oldTitle!,
                                    description: impact.description,
                                };
                            }),
                        };
                    }),
                };
            });
            return { evaModel: { decisionOptions, removedItems } };
        },

        get options(): EditorDecisionOption[] {
            return document.currentDocument.chapters.find((chapter: Chapter) => chapter.isEvaDecisionOptionArea)!
                .evaDecisionOptions;
        },

        get isMaxDecisionOptions(): boolean {
            return this.items.length >= 4;
        },

        setItems(newItems: EvaModelNestableItem[]): void {
            this.items = newItems;
        },
    }));

    useEffect(() => {
        props.isOpen && init();
        return () => {
            nextNewId = -1;
            nextNewGroupNumber = -1;
        };
    }, [props.isOpen]);

    async function save(): Promise<void> {
        try {
            loadingIndicator.show();
            await document.updateDocumentEvaDecisionAreaChapter(localStore.evaModel);
            snackbar.showSuccess();
        } catch (error) {
            snackbar.showError(error.data?.code);
        } finally {
            props.close();
            loadingIndicator.hide();
        }
    }

    function init(): void {
        const newItems = createNewItems();
        runInAction(() => (localStore.removedItems = []));
        localStore.setItems(newItems);
    }

    function createNewItems(): EvaModelNestableItem[] {
        if (localStore.options) {
            return localStore.options.map((option: EditorDecisionOption) => {
                let nextGroupNumber = 1;
                return {
                    id: option.id,
                    groupNumber: null,
                    depth1Parent: null,
                    depth2Parent: null,
                    parentId: null,
                    depth: 1,
                    text: option.text,
                    description: option.description,
                    children: option.evaPerspectives.map((perspective: EditorPerspective) => {
                        return {
                            id: perspective.id,
                            groupNumber: nextGroupNumber++,
                            depth1Parent: option.id,
                            depth2Parent: null,
                            oldTitle: perspective.title,
                            parentId: option.id,
                            depth: 2,
                            text: perspective.title,
                            description: perspective!.comments,
                            children: perspective.impactTargets.map((impact: EditorImpactOnTarget) => {
                                return {
                                    id: impact.id,
                                    groupNumber: nextGroupNumber++,
                                    depth1Parent: option.id,
                                    depth2Parent: perspective.id,
                                    oldTitle: impact.target,
                                    parentId: perspective.id,
                                    depth: 3,
                                    text: impact.target,
                                    description: impact.description,
                                    children: [],
                                };
                            }),
                        };
                    }),
                };
            }) as EvaModelNestableItem[];
        } else return [] as EvaModelNestableItem[];
    }

    function addDecisionItem(): void {
        const { translate } = localization;
        const newId = nextNewId--;
        const newItem: EvaModelNestableItem = {
            id: newId,
            groupNumber: null,
            depth1Parent: null,
            depth2Parent: null,
            parentId: null,
            depth: 1,
            description: '',
            text: translate('EVA_OPTION'),
            children: localStore.getItems[0]
                ? localStore.getItems[0].children.map((perspective) => {
                      return addPerspectiveItem(newId, perspective);
                  })
                : [],
        };
        const newItems = [...localStore.getItems, newItem];
        localStore.setItems(newItems as EvaModelNestableItem[]);
        handleListChange(newItems);
    }

    function addPerspectiveItem(
        parentId: number | null,
        templatePerspective: EvaModelNestableItem,
    ): EvaModelNestableItem {
        const newId = nextNewId--;
        const newItem = {
            id: newId,
            groupNumber: templatePerspective.groupNumber,
            depth1Parent: parentId,
            depth2Parent: null,
            parentId: parentId,
            depth: 2,
            description: '',
            text: templatePerspective.text,
            children: localStore.getItems[0].children
                .find((perspective) => perspective.id === templatePerspective.id)!
                .children.map((target) => {
                    return addTargetItem(newId, parentId, target);
                }),
        };
        return newItem;
    }

    function addTargetItem(
        parentId: number | null,
        depth1Parent: number | null,
        templateTarget: EvaModelNestableItem,
    ): EvaModelNestableItem {
        const newId = nextNewId--;
        return {
            id: newId,
            groupNumber: templateTarget.groupNumber,
            depth1Parent: depth1Parent,
            depth2Parent: parentId,
            parentId: parentId,
            depth: 3,
            description: '',
            text: templateTarget.text,
            children: [],
        };
    }

    function createNewPerspectiveItem(parentId: number, groupNumber: number, newId: number): void {
        const { translate } = localization;

        const newItem: EvaModelNestableItem = {
            id: newId,
            groupNumber: groupNumber,
            depth1Parent: parentId,
            depth2Parent: null,
            parentId: parentId,
            depth: 2,
            description: '',
            text: translate('EVA_PERSPECTIVE'),
            children: [],
        };
        const newItems = localStore.getItems.map(addItem(newItem, parentId));

        localStore.setItems(newItems as EvaModelNestableItem[]);
    }

    function handleCreateNewTargetItem(groupNumber: number): void {
        const addedGroupItems: EvaModelNestableItem[] = [];
        const newGroupNumber = nextNewGroupNumber--;

        localStore.getItems.forEach((item) => {
            if (item.groupNumber === groupNumber) {
                addedGroupItems.push(item);
            }
            item.children.forEach((it) => {
                if (it.groupNumber === groupNumber) {
                    addedGroupItems.push(it);
                }
                it.children.forEach((i) => {
                    if (i.groupNumber === groupNumber) {
                        addedGroupItems.push(i);
                    }
                });
            });
        });

        addedGroupItems.forEach((perspective) => {
            createNewTargetItem(perspective.id, perspective.depth1Parent!, newGroupNumber, nextNewId);
            nextNewId--;
        });
    }

    function handleCreateNewPerspectiveItem(): void {
        const newGroupNumber = nextNewGroupNumber--;
        localStore.getItems.forEach((decision) => {
            createNewPerspectiveItem(decision.id, newGroupNumber, nextNewId);
            nextNewId--;
        });
    }

    function createNewTargetItem(parentId: number, depth1Parent: number, groupNumber: number, newId: number): void {
        const { translate } = localization;
        const newItem: EvaModelNestableItem = {
            id: newId,
            groupNumber: groupNumber,
            depth1Parent: depth1Parent,
            depth2Parent: parentId,
            parentId: parentId,
            depth: 3,
            description: '',
            text: translate('EVA_IMPACT_ON_TARGET'),
            children: [],
        };
        const newItems = localStore.getItems.map(addItem(newItem, parentId));

        localStore.setItems(newItems as EvaModelNestableItem[]);
    }

    function removeNestableItem(removedItem: EvaModelNestableItem): void {
        const removedGroupItems: EvaModelNestableItem[] = [];

        if (removedItem.groupNumber) {
            localStore.getItems.forEach((item) => {
                if (item.groupNumber === removedItem.groupNumber) {
                    removedGroupItems.push(item);
                }
                item.children.forEach((it) => {
                    if (it.groupNumber === removedItem.groupNumber) {
                        removedGroupItems.push(it);
                    }
                    it.children.forEach((i) => {
                        if (i.groupNumber === removedItem.groupNumber) {
                            removedGroupItems.push(i);
                        }
                    });
                });
            });
        } else {
            removedGroupItems.unshift(removedItem);
            removedItem.children.forEach((child) => {
                removedGroupItems.unshift(child);
                child.children.forEach((child2) => {
                    removedGroupItems.unshift(child2);
                });
            });
        }

        let newItems = localStore.getItems;
        removedGroupItems.forEach((item) => {
            newItems = newItems.filter(removeItem(item));
        });

        localStore.setItems(newItems as EvaModelNestableItem[]);
        handleListChange(newItems);

        removedGroupItems.forEach((item) => {
            localStore.removedItems.push({
                id: item.id,
                newItem: item.id < 0,
                type: item.depth === 1 ? 'decision' : item.depth === 2 ? 'perspective' : 'target',
            });
        });
    }

    function handleListChange(items: NestableItem[], item?: NestableItem): void {
        const evaModelItems = items as EvaModelNestableItem[];

        if (item) {
            const evaModelItem = item as EvaModelNestableItem;
            const groupItems: EvaModelNestableItem[] = [];
            let groupItemOrder: (number | null)[] = [];

            evaModelItems.forEach((item) => {
                if (item.groupNumber === evaModelItem.groupNumber) {
                    groupItems.push(item);
                }
                item.children.forEach((it) => {
                    if (it.groupNumber === evaModelItem.groupNumber) {
                        groupItems.push(it);
                    }
                    it.children.forEach((i) => {
                        if (i.groupNumber === evaModelItem.groupNumber) {
                            groupItems.push(i);
                        }
                    });
                });
            });

            if (evaModelItem.depth === 2) {
                groupItemOrder = evaModelItems
                    .find((it) => it.id === evaModelItem.depth1Parent)!
                    .children.map((item) => {
                        return item.groupNumber;
                    });
            } else if (evaModelItem.depth === 3) {
                groupItemOrder = evaModelItems
                    .find((it) => it.id === evaModelItem.depth1Parent)!
                    .children.find((i) => i.id === evaModelItem.depth2Parent)!
                    .children.map((item) => {
                        return item.groupNumber;
                    });
            }

            groupItems.forEach((item) => {
                if (item.depth === 2) {
                    evaModelItems.forEach((it) => {
                        it.children.sort((a, b) => {
                            return groupItemOrder.indexOf(a.groupNumber) - groupItemOrder.indexOf(b.groupNumber);
                        });
                    });
                } else if (item.depth === 3) {
                    evaModelItems
                        .find((it) => it.id === item.depth1Parent)!
                        .children.forEach((i) => {
                            i.children.sort((a, b) => {
                                return groupItemOrder.indexOf(a.groupNumber) - groupItemOrder.indexOf(b.groupNumber);
                            });
                        });
                }
            });
        }

        let newItems = evaModelItems.map(addParentIds());
        newItems = newItems.map(addDepths());
        localStore.setItems(newItems as EvaModelNestableItem[]);
    }

    function handleTextChange(value: string, id: number, groupNumber: number | null): void {
        const changeTextItems: EvaModelNestableItem[] = [];
        let newItems: EvaModelNestableItem[] = [];

        if (groupNumber) {
            localStore.getItems.forEach((item) => {
                if (item.groupNumber === groupNumber) {
                    changeTextItems.push(item);
                }
                item.children.forEach((it) => {
                    if (it.groupNumber === groupNumber) {
                        changeTextItems.push(it);
                    }
                    it.children.forEach((i) => {
                        if (i.groupNumber === groupNumber) {
                            changeTextItems.push(i);
                        }
                    });
                });
            });
            newItems = localStore.getItems;
            changeTextItems.forEach((item) => {
                newItems = newItems.map(editNestableText(item.id, value));
            });
        } else {
            newItems = localStore.getItems.map(editNestableText(id, value));
        }
        localStore.setItems(newItems);
    }

    const editNestableText = (modifiedItemId: number, newText: string) => (item: EvaModelNestableItem) => {
        if (item.id === modifiedItemId) {
            item.text = newText;
        } else if (item.children.length > 0) {
            item.children.map(editText(modifiedItemId, newText));
        }
        return item;
    };

    const handleDescriptionChange = (value: string, id: number): void => {
        const newItems = localStore.getItems.map(editDescription(id, value));
        localStore.setItems(newItems);
    };

    const editDescription = (modifiedItemId: number, newDescription: string) => (item: EvaModelNestableItem) => {
        if (item.id === modifiedItemId) {
            item.description = newDescription;
        } else if (item.children.length > 0) {
            item.children.map(editDescription(modifiedItemId, newDescription));
        }
        return item;
    };

    const confirmChange = (evaModelDragItem: NestableItem, evaModelDestinationParent: NestableItem): boolean => {
        const dragItem = evaModelDragItem as EvaModelNestableItem;
        const destinationParent = evaModelDestinationParent as EvaModelNestableItem;
        // Item can´t change depth
        if (destinationParent?.depth !== (dragItem.depth - 1 || undefined)) return false;
        // Item can´t change parent
        else if (dragItem.depth !== 1 && destinationParent?.id !== dragItem.parentId) return false;
        else return true;
    };

    const renderCollapseIcon = (args: RenderCollapseIconArgs): JSX.Element => {
        return (
            <IconButton size="small" color="primary">
                {args.isCollapsed ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
            </IconButton>
        );
    };

    const renderItem = (args: RenderItemArgs): React.ReactNode => {
        return (
            <EvaModelNestableComponent
                args={args as EvaModelRenderItemArgs}
                handleDescriptionChange={handleDescriptionChange}
                handleTextChange={handleTextChange}
                handleCreateNewPerspectiveItem={handleCreateNewPerspectiveItem}
                handleCreateNewTargetItem={handleCreateNewTargetItem}
                removeItem={removeNestableItem}
                save={save}
            />
        );
    };

    const { translate } = localization;

    return (
        <StyledDialog
            open={props.isOpen}
            onClose={props.close}
            maxWidth="md"
            fullWidth
            classes={{ paper: classes.paper }}
        >
            <DialogTitle>
                {translate('EVA_OPTIONS_PERSPECTIVES_TARGETS')}
                <Box pt={1} display="flex">
                    <Info style={{ marginRight: theming.spacing(1) }} color="primary" />
                    <Typography variant="body2">{translate('ORGANIZATION_EVA_TEMPLATE_TIP')}</Typography>
                </Box>
            </DialogTitle>
            <DialogContent>
                <Nestable
                    items={localStore.getItems}
                    renderItem={renderItem}
                    onChange={handleListChange}
                    confirmChange={confirmChange}
                    maxDepth={3}
                    renderCollapseIcon={renderCollapseIcon}
                    collapsed={true}
                />
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={props.close}>
                    {translate('CLOSE')}
                </Button>
                <Tooltip
                    placement="top"
                    title={localStore.isMaxDecisionOptions ? translate('EVA_ADD_DECISION_OPTION_MAX_AMOUNT_TIP') : ''}
                >
                    <span style={{ marginLeft: theming.spacing(1) }}>
                        <ShvkButton disabled={localStore.isMaxDecisionOptions} onClick={addDecisionItem}>
                            {translate('EVA_ADD_DECISION_OPTION')}
                        </ShvkButton>
                    </span>
                </Tooltip>
                {!localStore.isMaxDecisionOptions && (
                    <Tooltip
                        placement="top"
                        leaveDelay={100}
                        title={translate('EVA_ADD_DECISION_OPTION_TIP')}
                        style={{ verticalAlign: 'middle', marginLeft: theming.spacing(1) }}
                    >
                        <Info color="primary" />
                    </Tooltip>
                )}
                <Box flexGrow={1} />
                <ShvkButton onClick={save}>{translate('SAVE')}</ShvkButton>
            </DialogActions>
        </StyledDialog>
    );
}

export default observer(EditEvaModelDialog);
