import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import FroalaEditorComponent from 'react-froala-wysiwyg';
import { Box, Button, Dialog, DialogActions, DialogContent, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { FocusArea, FocusAreaGoal } from '../../../../types/document';
import { TextFieldEvent } from '../../../../types/events';
import ShvkTextField from '../../../../styled/ShvkTextField';
import ShvkButton from '../../../../styled/ShvkButton';
import useStore from '../../../../store/storeContext';

const PREFIX = 'EditAdditionalColumnByGoalDialog';

const classes = {
    paper: `${PREFIX}-paper`,
};

const StyledDialog = styled(Dialog)(() => ({
    [`& .${classes.paper}`]: {
        height: 'auto',
    },
}));

interface Props {
    isOpen: boolean;
    close(): void;
    focusArea: FocusArea;
    goal: FocusAreaGoal;
}

interface State {
    title: string;
    additionalColumn: string;
}

function EditAdditionalColumnByGoalDialog(props: Props) {
    const { document, localization, loadingIndicator, snackbar } = useStore();

    const [state, setState] = useState<State>({
        title: '',
        additionalColumn: '',
    });

    useEffect(() => {
        props.isOpen && init();
    }, [props.isOpen]);

    function init() {
        setState((state) => ({
            ...state,
            title: document.currentDocument.focusAreaTableTitles.additionalColumnByGoalTitle || '',
            additionalColumn: props.goal.additionalColumn || '',
        }));
    }

    const handleAdditionalColumnChange = (title: string): void => {
        setState((state) => ({
            ...state,
            additionalColumn: title,
        }));
    };

    const handleTitleChange = (event: TextFieldEvent): void => {
        setState((state) => ({
            ...state,
            title: event.target.value,
        }));
    };

    async function save(): Promise<void> {
        try {
            loadingIndicator.show();
            await document.updateFocusAreaTableTitles({
                ...document.currentDocument.focusAreaTableTitles,
                additionalColumnByGoalTitle: state.title,
            });
            await document.saveGoal(props.focusArea.id, props.goal.id, props.goal.title, state.additionalColumn);
            snackbar.showSuccess();
        } catch (error) {
            snackbar.showError(error.data?.code);
        } finally {
            props.close();
            loadingIndicator.hide();
        }
    }

    const { translate } = localization;

    return (
        <StyledDialog
            open={props.isOpen}
            onClose={props.close}
            classes={{ paper: classes.paper }}
            maxWidth="lg"
            fullWidth
        >
            <DialogContent>
                <ShvkTextField variant="outlined" fullWidth value={state.title} onChange={handleTitleChange} />
                <Typography variant="h6">
                    <br />
                </Typography>
                <FroalaEditorComponent
                    tag="textarea"
                    model={state.additionalColumn}
                    onModelChange={handleAdditionalColumnChange}
                    config={{ ...document.froalaFocusAreaConfig }}
                />
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={props.close}>
                    {translate('CLOSE')}
                </Button>
                <Box flexGrow={1} />
                <ShvkButton onClick={save}>{translate('SAVE')}</ShvkButton>
            </DialogActions>
        </StyledDialog>
    );
}

export default observer(EditAdditionalColumnByGoalDialog);
