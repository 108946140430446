import Axios from '../../axiosConfig';
import { BACKEND_URL } from '../../config';
import { configure, runInAction, makeAutoObservable } from 'mobx';
import { LocalizedName } from '../../types/localization';

configure({ enforceActions: 'observed' });

export default class InfoModule {
    private info: LocalizedName | null = null;
    private material: LocalizedName | null = null;
    private cookies: LocalizedName | null = null;
    private accessibility: LocalizedName | null = null;

    constructor() {
        makeAutoObservable(this, {}, { autoBind: true });
    }

    public async fetchShvkInfo(): Promise<void> {
        const response = await Axios({
            method: 'GET',
            url: BACKEND_URL + 'app/infoText',
        });
        runInAction(() => (this.info = response.data));
    }

    public async fetchMaterialText(): Promise<void> {
        const response = await Axios({
            method: 'GET',
            url: BACKEND_URL + 'app/materialText',
        });
        runInAction(() => (this.material = response.data));
    }

    public async fetchCookiesInfo(): Promise<void> {
        const response = await Axios({
            method: 'GET',
            url: BACKEND_URL + 'app/cookiesText',
        });
        runInAction(() => (this.cookies = response.data));
    }

    public async fetchAccessibilityStatement(): Promise<void> {
        const response = await Axios({
            method: 'GET',
            url: BACKEND_URL + 'app/accessibilityText',
        });
        runInAction(() => (this.accessibility = response.data));
    }

    public async saveInfoText(localizedName: LocalizedName): Promise<void> {
        await Axios({
            method: 'POST',
            url: BACKEND_URL + 'app/saveInfoText',
            data: localizedName,
        });
        runInAction(() => (this.info = localizedName));
    }

    public async saveMaterialText(localizedName: LocalizedName): Promise<void> {
        await Axios({
            method: 'POST',
            url: BACKEND_URL + 'app/saveMaterialText',
            data: localizedName,
        });
        runInAction(() => (this.material = localizedName));
    }

    public async saveCookiesText(localizedName: LocalizedName): Promise<void> {
        await Axios({
            method: 'POST',
            url: BACKEND_URL + 'app/saveCookiesText',
            data: localizedName,
        });
        runInAction(() => (this.cookies = localizedName));
    }

    public async saveAccessibilityStatement(localizedName: LocalizedName): Promise<void> {
        await Axios({
            method: 'POST',
            url: BACKEND_URL + 'app/saveAccessibilityText',
            data: localizedName,
        });
        runInAction(() => (this.accessibility = localizedName));
    }

    public get shvkInfo(): LocalizedName | null {
        return this.info;
    }

    public get materialText(): LocalizedName | null {
        return this.material;
    }

    public get cookiesInfo(): LocalizedName | null {
        return this.cookies;
    }

    public get accessibilityStatement(): LocalizedName | null {
        return this.accessibility;
    }
}
