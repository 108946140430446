import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Box, IconButton, Paper, Typography } from '@mui/material';
import { CreateNewFolder, Delete } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { EvaModelNestableItem, EvaModelRenderItemArgs } from '../../../types/nestable';
import DangerIconButton from '../../../styled/DangerIconButton';
import ChangeItemInfoDialog from '../dialogs/eva/ChangeItemInfoDialog';
import useStore, { rootModule } from '../../../store/storeContext';

const PREFIX = 'EvaModelNestableComponent';

const classes = {
    paper: `${PREFIX}-paper`,
    depth1: `${PREFIX}-depth1`,
    depth2: `${PREFIX}-depth2`,
    depth3: `${PREFIX}-depth3`,
};

const { theming } = rootModule;

const StyledPaper = styled(Paper)(({ theme }) => ({
    [`&.${classes.paper}`]: {
        position: 'relative',
        padding: theme.spacing(0.5),
        cursor: 'pointer',
    },

    [`&.${classes.depth1}`]: {
        backgroundColor: theming.shvkBlueGray60,
    },

    [`&.${classes.depth2}`]: {
        paddingTop: theme.spacing(0.25),
        paddingBottom: theme.spacing(0.25),
        backgroundColor: theming.shvkBlueGray40,
    },

    [`&.${classes.depth3}`]: {
        paddingTop: theme.spacing(0.25),
        paddingBottom: theme.spacing(0.25),
        backgroundColor: theming.shvkBlueGray20,
    },
}));

interface Props {
    args: EvaModelRenderItemArgs;
    handleDescriptionChange(value: string, id: number): void;
    handleTextChange(value: string, id: number, groupNumber: number | null): void;
    handleCreateNewPerspectiveItem(): void;
    handleCreateNewTargetItem(groupNumber: number): void;
    removeItem(item: EvaModelNestableItem): void;
    save(): void;
}

interface State {
    open: boolean;
}

function EvaModelNestableComponent(props: Props) {
    const { dialog } = useStore();

    const [state, setState] = useState<State>({
        open: false,
    });

    async function remove(): Promise<void> {
        const confirmation = await dialog.getConfirmation();
        if (!confirmation) return;

        props.removeItem(props.args.item);
    }

    const handleDialog = (): void => {
        setState((state) => ({ ...state, open: !state.open }));
    };

    const { depth, id, groupNumber } = props.args.item;

    return (
        <StyledPaper
            variant="outlined"
            className={`${classes.paper} ${
                depth === 1 ? classes.depth1 : depth === 2 ? classes.depth2 : classes.depth3
            }`}
        >
            <Box display="flex" alignItems="center">
                {props.args.collapseIcon}
                <Box flexGrow={1}>
                    <Typography onClick={handleDialog}>{props.args.item.text || ' - '}</Typography>
                </Box>
                {depth === 1 && (
                    <IconButton size="small" color="primary" onClick={props.handleCreateNewPerspectiveItem}>
                        <CreateNewFolder />
                    </IconButton>
                )}
                {depth === 2 && (
                    <IconButton
                        size="small"
                        color="primary"
                        onClick={() => props.handleCreateNewTargetItem(groupNumber!)}
                    >
                        <CreateNewFolder />
                    </IconButton>
                )}
                <DangerIconButton size="small" onClick={remove}>
                    <Delete />
                </DangerIconButton>
            </Box>
            <ChangeItemInfoDialog
                isOpen={state.open}
                close={handleDialog}
                id={id}
                item={props.args.item}
                handleDescriptionChange={props.handleDescriptionChange}
                handleTextChange={props.handleTextChange}
            />
        </StyledPaper>
    );
}

export default observer(EvaModelNestableComponent);
