import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    List,
    ListItemButton,
    ListItemText,
} from '@mui/material';
import { Brightness1 as Icon } from '@mui/icons-material/';
import useStore from '../../store/storeContext';

interface Props {
    open: boolean;
    onClose(): void;
}

interface State {
    chosenTheme: string;
}

const themes: Readonly<string[]> = ['defaultPalette', 'bluePalette'];

function ColorDialog(props: Props) {
    const context = useStore();
    const { theming } = context;

    const [state, setState] = useState<State>({
        chosenTheme: '',
    });

    useEffect(() => {
        props.open && init();
    }, [props.open]);

    function init() {
        setState((state) => ({
            ...state,
            chosenTheme: theming.getChosenPalette,
        }));
    }

    const handleClick = (newChosenTheme: string): void => {
        context.theming.changePalette(newChosenTheme);
        setState((state) => ({
            ...state,
            chosenTheme: newChosenTheme,
        }));
        props.onClose();
    };

    const { translate } = context.localization;

    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            aria-labelledby="user-info-dialog-title"
            maxWidth={'sm'}
            fullWidth={true}
        >
            <DialogTitle id="user-info-dialog-title">{translate('USER_SETTINGS_COLOR_SCHEME')}</DialogTitle>
            <DialogContent>
                <Divider />
                <List>
                    {themes.map((theme) => (
                        <ListItemButton
                            style={{
                                backgroundColor: theme === state.chosenTheme ? context.theming.shvkBlue10 : '',
                            }}
                            key={theme}
                            onClick={(): void => handleClick(theme)}
                        >
                            <ListItemText
                                primary={
                                    theme === 'defaultPalette'
                                        ? translate('DEFAULT_COLOR_SCHEMA')
                                        : translate('BLUE_COLOR_SCHEMA')
                                }
                            />
                            <Box width="40%" style={{ float: 'right' }}>
                                <Grid container>
                                    <Icon
                                        style={{
                                            fontSize: 30,
                                            color: context.theming.getPaletteInfo(theme).primary.main,
                                        }}
                                    />
                                    <Icon
                                        style={{
                                            fontSize: 30,
                                            color: context.theming.getPaletteInfo(theme).secondary.main,
                                        }}
                                    />
                                    <Icon
                                        style={{
                                            fontSize: 30,
                                            color: context.theming.getPaletteInfo(theme).success.main,
                                        }}
                                    />
                                    <Icon
                                        style={{
                                            fontSize: 30,
                                            color: context.theming.getPaletteInfo(theme).error.main,
                                        }}
                                    />
                                    <Icon
                                        style={{
                                            fontSize: 30,
                                            color: context.theming.getPaletteInfo(theme).info.main,
                                        }}
                                    />
                                </Grid>
                            </Box>
                        </ListItemButton>
                    ))}
                </List>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose} variant="contained">
                    {translate('CLOSE')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default observer(ColorDialog);
