import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { TextFieldEvent } from '../../types/events';
import ShvkTextField from '../../styled/ShvkTextField';
import ShvkButton from '../../styled/ShvkButton';
import HelpText from '../../types/helpText';
import useStore from '../../store/storeContext';

interface Props {
    open: boolean;
    onClose(): void;
    helpText: HelpText | null;
}

interface State {
    fi_FI: string;
    sv_SE: string;
    en_GB: string;
}

type HelpTextFormField = 'fi_FI' | 'sv_SE' | 'en_GB';

function HelpTextDialog(props: Props) {
    const { admin, localization, snackbar, theming } = useStore();

    const [state, setState] = useState<State>({
        fi_FI: '',
        sv_SE: '',
        en_GB: '',
    });

    useEffect((): void => {
        init();
    }, [props.open]);

    const handleChange = (event: TextFieldEvent, field: HelpTextFormField): void => {
        setState({
            ...state,
            [field]: event.target.value,
        });
    };

    const init = (): void => {
        setState({
            fi_FI: props.helpText?.fi_fi || '',
            sv_SE: props.helpText?.sv_se || '',
            en_GB: props.helpText?.en_gb || '',
        });
    };

    async function save(): Promise<void> {
        try {
            if (props.helpText) {
                const params: HelpText = {
                    description: props.helpText.description,
                    location: props.helpText.location,
                    key: props.helpText.key,
                    fi_fi: state.fi_FI,
                    sv_se: state.sv_SE,
                    en_gb: state.en_GB,
                };
                await admin.saveHelpText(params);
                snackbar.showSuccess();
            }
        } catch (error) {
            snackbar.showError(error.data?.code);
        } finally {
            props.onClose();
        }
    }

    const { translate } = localization;

    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            aria-labelledby="help-text-management-dialog-title"
            maxWidth={'sm'}
            fullWidth
        >
            <DialogTitle id="help-text-management-dialog-title">{translate('HELP_TEXT_MANAGEMENT')}</DialogTitle>
            <DialogContent dividers>
                <ShvkTextField
                    id="helptext-fi-textfield"
                    label={translate('FI_TRANSLATION')}
                    multiline
                    rows={5}
                    value={state.fi_FI}
                    variant="outlined"
                    size="small"
                    fullWidth
                    style={{ marginBottom: theming.theme.spacing(2) }}
                    onChange={(event): void => handleChange(event, 'fi_FI')}
                    name="fi"
                />
                <ShvkTextField
                    id="helptext-se-textfield"
                    label={translate('SE_TRANSLATION')}
                    multiline
                    rows={5}
                    value={state.sv_SE}
                    variant="outlined"
                    size="small"
                    fullWidth
                    style={{ marginBottom: theming.theme.spacing(2) }}
                    onChange={(event): void => handleChange(event, 'sv_SE')}
                    name="se"
                />
                <ShvkTextField
                    id="helptext-en-textfield"
                    label={translate('EN_TRANSLATION')}
                    multiline
                    rows={5}
                    value={state.en_GB}
                    variant="outlined"
                    size="small"
                    fullWidth
                    style={{ marginBottom: theming.theme.spacing(2) }}
                    onChange={(event): void => handleChange(event, 'en_GB')}
                    name="en"
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose} variant="contained">
                    {translate('CLOSE')}
                </Button>
                <Box flexGrow={1} />
                <ShvkButton onClick={save}>{translate('SAVE')}</ShvkButton>
            </DialogActions>
        </Dialog>
    );
}

export default observer(HelpTextDialog);
