import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { observer, useLocalObservable } from 'mobx-react-lite';
import {
    Autocomplete,
    Box,
    Button,
    Card,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    FormLabel,
    Grid,
    IconButton,
    Paper,
    Popper,
    Radio,
    RadioGroup,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
} from '@mui/material';
import { Info, Search, Timeline } from '@mui/icons-material';
import { AddIndicatorDialogIndicator, Indicator, IndicatorPackageTheme } from '../../../types/indicator';
import { EditorImpactOnTarget } from '../../../types/evaTemplate';
import { FocusAreaGoalAction } from '../../../types/document';
import IndicatorAlreadyInDocumentDialog from './IndicatorAlreadyInDocumentDialog';
import IndicatorGraphPreviewDialog from './IndicatorGraphPreviewDialog';
import ShvkTextField from '../../../styled/ShvkTextField';
import ShvkButton from '../../../styled/ShvkButton';
import useStore from '../../../store/storeContext';

interface Props {
    isOpen: boolean;
    impactOnTarget?: EditorImpactOnTarget | null;
    indicatorPackageTheme?: IndicatorPackageTheme | null;
    goalAction?: FocusAreaGoalAction;
    sotkanetOnly?: boolean;

    close(): void;

    saveIndicators(indicators: AddIndicatorDialogIndicator[]): void;
}

type IndicatorSourceOption = 'MINIMUM_MUNICIPAL' | 'MINIMUM_AREA' | 'SECURITY' | 'DEFAULT';
type SourceCode = 'SOTKANET' | 'CUSTOM' | 'OTHER';
type SourceCheckbox = {
    checked: boolean;
    code: SourceCode;
    name: string;
    id?: number;
};

interface State {
    fetchingIndicators: boolean;
    searchInputValue: string;
    securityS: boolean;
    securityL: boolean;
    securityR: boolean;
    isAddAllDialog: boolean;
    indicatorAlreadyInDocumentOpen: boolean;
    indicatorGraphPreviewOpen: boolean;
    indicatorGraphPreviewIndicator: AddIndicatorDialogIndicator | null;
}

interface StoreState {
    indicatorList: AddIndicatorDialogIndicator[];
    sourceType: IndicatorSourceOption;
    sortedIndicatorList: AddIndicatorDialogIndicator[];
    resetLocalStore: () => void;
    setIndicatorList: (indicators: AddIndicatorDialogIndicator[]) => void;
    setSourceType: (sourceType: IndicatorSourceOption) => void;
}

function AddIndicatorDialog(props: Props) {
    const { document, indicator, localization, loadingIndicator, snackbar, session } = useStore();

    const inputRef = useRef<HTMLDivElement>();

    const [state, setState] = useState<State>({
        fetchingIndicators: false,
        searchInputValue: '',
        securityS: false,
        securityL: false,
        securityR: false,
        isAddAllDialog: false,
        indicatorAlreadyInDocumentOpen: false,
        indicatorGraphPreviewOpen: false,
        indicatorGraphPreviewIndicator: null,
    });

    const [searchMadeById, setSearchMadeById] = useState(false);
    const [recentIndicators, setRecentIndicators] = useState<AddIndicatorDialogIndicator[]>([]);
    const [supportedIndicatorSources, setSupportedIndicatorSources] = useState<SourceCheckbox[]>([]);
    const [indicatorAlreadyInDocumentIndicators, setIndicatorAlreadyInDocumentIndicators] = useState<Indicator[]>([]);

    const localStore = useLocalObservable<StoreState>(() => ({
        indicatorList: [],
        sourceType: 'DEFAULT',

        get sortedIndicatorList(): AddIndicatorDialogIndicator[] {
            const list = this.indicatorList;
            if (this.sourceType === 'DEFAULT') {
                return list.slice().sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0));
            } else {
                return list.slice().sort((a, b) => (a.order < b.order ? -1 : a.order > b.order ? 1 : 0));
            }
        },

        resetLocalStore() {
            this.indicatorList = [];
            this.sourceType = 'DEFAULT';
        },

        setIndicatorList(indicators: AddIndicatorDialogIndicator[]) {
            this.indicatorList = indicators;
        },

        setSourceType(sourceType: IndicatorSourceOption) {
            this.sourceType = sourceType;
        },
    }));

    useEffect(() => {
        if (props.isOpen) {
            void init();
        }
    }, [props.isOpen]);

    useEffect(() => {
        // Reset a recently added indicators list when changing to another impact on target or theme
        resetRecentIndicators();
    }, [props.impactOnTarget?.id, props.indicatorPackageTheme?.id]);

    async function init(): Promise<void> {
        await getSupportedIndicatorSources();
        await fetchIndicatorPackageThemes();
        if (recentIndicators.length > 0) {
            recalculateRecentIndicators();
        }
        setState((state) => ({ ...state, searchInputValue: '' }));
        localStore.resetLocalStore();
    }

    async function getSupportedIndicatorSources(): Promise<void> {
        const { translate } = localization;
        const { isCurrentDocumentEva } = document;
        let sources: SourceCheckbox[] = [];

        if (props.sotkanetOnly) {
            sources = [
                {
                    checked: true,
                    code: 'SOTKANET',
                    name: 'Sotkanet',
                },
            ];
        } else {
            sources = [
                {
                    checked: true,
                    code: 'SOTKANET',
                    name: 'Sotkanet',
                },
                {
                    checked: true,
                    code: 'CUSTOM',
                    name: translate('ORGANIZATION'),
                },
            ];

            if (!isCurrentDocumentEva) {
                // Get 'other' indicators for shvk documents. Eva has no 'other' indicators.
                try {
                    await indicator.fetchOtherIndicatorSources();
                    indicator.otherIndicatorSources.forEach((source) => {
                        sources.push({
                            checked: true,
                            code: source.code as SourceCode,
                            name: source.name,
                            id: source.id,
                        });
                    });
                } catch (e) {
                    snackbar.showFetchFailedMessage(e.data?.code);
                }
            }
        }
        setSupportedIndicatorSources(sources);
    }

    async function fetchIndicatorPackageThemes(): Promise<void> {
        // Fetch themes that haven't been fetched yet.
        const { indicatorPackageChapter } = document;
        const packageIds: number[] = [];

        indicatorPackageChapter?.indicatorPackages.forEach((indicatorPackage) => {
            // The theme is not in the store yet. Fetch it.
            if (indicatorPackage.themes.length === 0) packageIds.push(indicatorPackage.id);
        });

        if (packageIds.length > 0) {
            loadingIndicator.show();
            try {
                for (const id of packageIds) {
                    await indicator.fetchPackageThemes(id);
                }
            } catch (error) {
                snackbar.showFetchFailedMessage(error.data?.code);
            } finally {
                loadingIndicator.hide();
            }
        }
    }

    async function searchIndicators(): Promise<void> {
        setState((state) => ({ ...state, fetchingIndicators: true }));

        let searchCodes: string;
        if (localStore.sourceType === 'DEFAULT') {
            // Create search codes from checked checkboxes
            const filtered = supportedIndicatorSources.filter((source) => source.checked);
            searchCodes = filtered
                .map((source) => {
                    if (source.code === 'OTHER') {
                        return source.code + '_' + source.id;
                    } else {
                        return source.code;
                    }
                })
                .join(',');
        } else if (localStore.sourceType === 'SECURITY') {
            const securities = [];
            if (state.securityS) securities.push('SECURITY_S');
            if (state.securityL) securities.push('SECURITY_L');
            if (state.securityR) securities.push('SECURITY_R');
            searchCodes = securities.join(',');
        } else {
            searchCodes = localStore.sourceType;
        }

        try {
            let indicators = await indicator.searchIndicators(searchCodes, state.searchInputValue);
            // Filter out 'other' indicators if the current document is eva
            if (document.isCurrentDocumentEva) {
                indicators = indicators.filter((indicator) => indicator.source.code !== 'OTHER');
            }
            // Filter out indicators that are already selected
            indicators = indicators.filter((indicator) => {
                return !allSelectedIndicators().some((indicatorInUse) => indicatorInUse.name === indicator.name);
            });
            // Filter out the indicators that are already in the recent indicators list
            indicators = indicators.filter((indicator) => {
                return !recentIndicators.some((recentIndicator) => indicator.name === recentIndicator.name);
            });

            // Filter out duplicate indicators
            indicators = indicators.filter((indicator, index, self) => {
                return index === self.findIndex((i) => i.id === indicator.id);
            });
            // Check if search was made with number (Sotkanet ID)
            if (!Number.isNaN(parseInt(state.searchInputValue)) && indicators.length > 0) {
                setSearchMadeById(true);
            }
            localStore.setIndicatorList(indicators);
            // Set focus to the autocomplete text-field. This will open the autocomplete popup.
            inputRef.current?.focus();
        } catch (e) {
            snackbar.showError(e.data?.code);
        } finally {
            setState((state) => ({ ...state, fetchingIndicators: false }));
        }
    }

    function allSelectedIndicators(): Indicator[] {
        if (props.indicatorPackageTheme) {
            return [
                ...props.indicatorPackageTheme.sotkanetIndicators,
                ...props.indicatorPackageTheme.organizationIndicators,
                ...props.indicatorPackageTheme.otherIndicators,
            ];
        } else if (props.impactOnTarget) {
            return [...props.impactOnTarget.sotkanetIndicators, ...props.impactOnTarget.organizationIndicators];
        } else if (props.goalAction) {
            return [
                ...props.goalAction.sotkanetIndicators,
                ...props.goalAction.organizationIndicators,
                ...props.goalAction.otherIndicators,
            ];
        } else {
            return [];
        }
    }

    function selectedIndicatorsAlreadyInDocument(indicatorList: AddIndicatorDialogIndicator[]): Indicator[] {
        const documentIndicators = document.currentDocumentIndicators;
        const foundIndicators: Indicator[] = [];
        indicatorList.forEach((listIndicator) =>
            documentIndicators.forEach((documentIndicator) => {
                if (documentIndicator.indicatorId === listIndicator.id) {
                    foundIndicators.push(documentIndicator);
                }
            }),
        );
        return foundIndicators;
    }

    function addRecentIndicators(newIndicators: AddIndicatorDialogIndicator[]): void {
        // Update the recent indicators list with indicators that do not already exist on the recentIndicators list.
        const filteredNewIndicators = newIndicators.filter((indicator) => {
            return !recentIndicators.some((recentIndicator) => recentIndicator.id === indicator.id);
        });

        setRecentIndicators([...recentIndicators, ...filteredNewIndicators]);

        // Clear autocomplete input
        setState((state) => ({ ...state, searchInputValue: '' }));

        // Filter autocomplete list. Don't show indicators that are added in recent indicators
        const filteredIndicatorList = localStore.indicatorList.filter((indicator) => {
            return !newIndicators.some((newIndicator) => indicator.id === newIndicator.id);
        });

        localStore.setIndicatorList(filteredIndicatorList);
    }

    function openIndicatorGraphPreview(indicator: AddIndicatorDialogIndicator) {
        setState((state) => ({ ...state, indicatorGraphPreviewOpen: true, indicatorGraphPreviewIndicator: indicator }));
    }

    const resetRecentIndicators = (): void => {
        setRecentIndicators([]);
    };

    const recalculateRecentIndicators = (): void => {
        //If selected indicator gets removed, filter it out from the recent indicators list.
        const filtered = recentIndicators.filter((recentIndicator) => {
            return allSelectedIndicators().some((selectedIndicator) => selectedIndicator.name === recentIndicator.name);
        });
        setRecentIndicators(filtered);
    };

    const addIndicator = (): void => {
        const indicator = localStore.indicatorList.find((ind) => ind.name === state.searchInputValue);
        if (!indicator) return;

        const alreadyAddedIndicators = selectedIndicatorsAlreadyInDocument([indicator]);
        if (alreadyAddedIndicators.length) {
            setState((state) => ({
                ...state,
                isAddAllDialog: false,
                indicatorAlreadyInDocumentOpen: true,
            }));
            setIndicatorAlreadyInDocumentIndicators(alreadyAddedIndicators);
        } else {
            props.saveIndicators([indicator]);
            addRecentIndicators([indicator]);
        }
    };

    const addAllIndicators = (): void => {
        if (localStore.indicatorList.length > session.maxAddIndicators) {
            const { translate } = localization;
            snackbar.setMessage(
                translate('MAX_ADD_INDICATORS').replace('#number', session.maxAddIndicators.toString()),
            );
            snackbar.setSeverity('error');
            snackbar.open();
        } else {
            const alreadyAddedIndicators = selectedIndicatorsAlreadyInDocument(localStore.indicatorList);
            if (alreadyAddedIndicators.length) {
                setState((state) => ({
                    ...state,
                    isAddAllDialog: true,
                    indicatorAlreadyInDocumentOpen: true,
                }));
                setIndicatorAlreadyInDocumentIndicators(alreadyAddedIndicators);
            } else {
                props.saveIndicators(localStore.indicatorList);
                addRecentIndicators(localStore.indicatorList);
            }
        }
    };

    const indicatorAlreadyInDocumentAddIndicators = (): void => {
        if (state.isAddAllDialog) {
            props.saveIndicators(localStore.indicatorList);
            addRecentIndicators(localStore.indicatorList);
        } else {
            const indicator = localStore.indicatorList.find((ind) => ind.name === state.searchInputValue);
            if (!indicator) return;
            props.saveIndicators([indicator]);
            addRecentIndicators([indicator]);
        }
    };

    const handleTextFieldChange = (_event: ChangeEvent<unknown>, newInputValue: string): void => {
        if (searchMadeById) {
            setSearchMadeById(false);
        }
        setState((state) => ({ ...state, searchInputValue: newInputValue }));
    };

    const handleSourceChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        localStore.setSourceType(event.target.value as IndicatorSourceOption);
    };

    const handleSupportedSourceChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const updatedSources = supportedIndicatorSources.map((src) => {
            if (src.name === event.target.name) {
                return {
                    ...src,
                    checked: event.target.checked,
                };
            } else {
                return src;
            }
        });
        setSupportedIndicatorSources(updatedSources);
    };

    const { translate } = localization;

    return (
        <Dialog
            open={props.isOpen}
            onClose={props.close}
            aria-labelledby="add-indicator-dialog-title"
            maxWidth="md"
            fullWidth
        >
            <DialogTitle id="add-indicator-dialog-title">{translate('ADD_INDICATORS')}</DialogTitle>
            <DialogContent dividers>
                <Box display="flex" mb={2}>
                    <Box flexGrow={1} mr={4} display="flex">
                        <Autocomplete
                            openOnFocus
                            freeSolo
                            forcePopupIcon
                            inputValue={state.searchInputValue}
                            onInputChange={handleTextFieldChange}
                            options={localStore.sortedIndicatorList}
                            filterOptions={
                                // If search is made by Sotkanet ID, search results will not be filtered
                                !searchMadeById
                                    ? undefined
                                    : (x) => {
                                          return x;
                                      }
                            }
                            getOptionLabel={(option): string => {
                                return typeof option !== 'object' ? option.toString() : option.name;
                            }}
                            renderOption={(props: object, option): JSX.Element => (
                                <li {...props} key={option.id}>
                                    {option.name}
                                </li>
                            )}
                            PopperComponent={(props) => (
                                <Popper {...props} style={{ width: 895 }} placement="bottom-start">
                                    {props.children}
                                </Popper>
                            )}
                            ListboxProps={{ style: { maxHeight: 460 } }}
                            fullWidth
                            size="small"
                            loading={state.fetchingIndicators}
                            renderInput={(params): JSX.Element => (
                                <ShvkTextField
                                    {...params}
                                    inputRef={inputRef}
                                    label={translate('SEARCH_CRITERIA')}
                                    variant="outlined"
                                    placeholder={
                                        localStore.indicatorList.length === 0
                                            ? translate('INDICATOR_SEARCH_PLACEHOLDER_EMPTY_LIST')
                                            : translate('INDICATOR_SEARCH_PLACEHOLDER')
                                    }
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <React.Fragment>
                                                {state.fetchingIndicators && <CircularProgress size={20} />}
                                                {params.InputProps.endAdornment}
                                            </React.Fragment>
                                        ),
                                    }}
                                />
                            )}
                            onKeyDown={async (event): Promise<void> => {
                                if (event.key === 'Enter') {
                                    inputRef.current?.blur();
                                    await searchIndicators();
                                }
                            }}
                        />
                        <Button variant="outlined" onClick={searchIndicators} startIcon={<Search />} sx={{ ml: 1 }}>
                            {translate('SEARCH')}
                        </Button>
                    </Box>
                    <ShvkButton sx={{ mr: 1 }} onClick={addIndicator} disabled={!state.searchInputValue}>
                        {translate('ADD')}
                    </ShvkButton>
                    <ShvkButton onClick={addAllIndicators} disabled={localStore.indicatorList.length === 0}>
                        {translate('ADD_ALL') + ' (' + localStore.indicatorList.length + ')'}
                    </ShvkButton>
                </Box>
                <Box mb={2}>
                    <FormLabel component="legend" id="select-source-label">
                        {translate('SELECT_SOURCE')}
                    </FormLabel>
                    <RadioGroup
                        aria-labelledby="select-source-label"
                        value={localStore.sourceType}
                        onChange={handleSourceChange}
                    >
                        <Box display="inline-flex" alignItems="center">
                            <FormControlLabel
                                value="MINIMUM_MUNICIPAL"
                                control={<Radio />}
                                label={translate('MINIMUM_DATA_MUNICIPAL')}
                            />
                            <Tooltip title={translate('kunnan_minimitietohaun_kuvaus_17')}>
                                <Info color="primary" />
                            </Tooltip>
                        </Box>
                        <Box display="inline-flex" alignItems="center">
                            <FormControlLabel
                                value="MINIMUM_AREA"
                                control={<Radio />}
                                label={translate('MINIMUM_DATA_AREA')}
                            />
                            <Tooltip title={translate('alueen_minimitietohaun_kuvaus_18')}>
                                <Info color="primary" />
                            </Tooltip>
                        </Box>
                        <Box display="inline-flex" alignItems="center">
                            <FormControlLabel
                                value="SECURITY"
                                control={<Radio />}
                                label={translate('SECURITY_SOURCE')}
                            />
                            <Tooltip title={translate('SECURITY_SOURCE_DESCRIPTION')}>
                                <Info color="primary" />
                            </Tooltip>
                        </Box>
                        <Box pl={2}>
                            <Grid container direction="column">
                                <Grid item>
                                    <FormControlLabel
                                        label={translate('SMALL_AREA_MUNICIPAL')}
                                        control={
                                            <Checkbox
                                                disabled={localStore.sourceType !== 'SECURITY'}
                                                checked={state.securityS}
                                                name={'SMALL_AREA_MUNICIPAL'}
                                                color="primary"
                                                onChange={(event) =>
                                                    setState((state) => ({ ...state, securityS: event.target.checked }))
                                                }
                                            />
                                        }
                                    />
                                </Grid>
                                <Grid item>
                                    <FormControlLabel
                                        label={translate('LARGE_AREA_MUNICIPAL')}
                                        control={
                                            <Checkbox
                                                disabled={localStore.sourceType !== 'SECURITY'}
                                                checked={state.securityL}
                                                name={'LARGE_AREA_MUNICIPAL'}
                                                color="primary"
                                                onChange={(event) =>
                                                    setState((state) => ({ ...state, securityL: event.target.checked }))
                                                }
                                            />
                                        }
                                    />
                                </Grid>
                                <Grid item>
                                    <FormControlLabel
                                        label={translate('REGIONAL_SECURITY_INDICATORS')}
                                        control={
                                            <Checkbox
                                                disabled={localStore.sourceType !== 'SECURITY'}
                                                checked={state.securityR}
                                                name={'REGIONAL_SECURITY_INDICATORS'}
                                                color="primary"
                                                onChange={(event) =>
                                                    setState((state) => ({ ...state, securityR: event.target.checked }))
                                                }
                                            />
                                        }
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                        <Box display="inline-flex" alignItems="center">
                            <FormControlLabel value="DEFAULT" control={<Radio />} label={translate('FREE_SELECTION')} />
                            <Tooltip title={translate('vapaan_haun_kuvaus_19')}>
                                <Info color="primary" />
                            </Tooltip>
                        </Box>
                    </RadioGroup>
                    <Box pl={2}>
                        <Grid container direction="column">
                            {supportedIndicatorSources.map((source) => (
                                <Grid item key={source.name}>
                                    <FormControlLabel
                                        label={source.name}
                                        control={
                                            <Checkbox
                                                disabled={localStore.sourceType !== 'DEFAULT'}
                                                checked={source.checked}
                                                name={source.name}
                                                color="primary"
                                                onChange={handleSupportedSourceChange}
                                            />
                                        }
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                </Box>
                {recentIndicators.length > 0 && (
                    <>
                        <FormLabel id="recent-indicators-label">{translate('RECENT_INDICATORS')}</FormLabel>
                        <TableContainer component={Card} variant="outlined" sx={{ mb: 2, mt: 1 }}>
                            <Table aria-labelledby="recent-indicators-label" size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>{translate('INDICATOR')}</TableCell>
                                        <TableCell align="right">{translate('ACTIONS')}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {recentIndicators.map((indicator) => (
                                        <TableRow key={indicator.id}>
                                            <TableCell>{indicator.name}</TableCell>
                                            <TableCell align="right">
                                                <IconButton
                                                    size="small"
                                                    color="primary"
                                                    onClick={() => openIndicatorGraphPreview(indicator)}
                                                >
                                                    <Timeline />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </>
                )}
                <Paper variant="outlined">
                    <Box display="flex" p={1}>
                        <Info color="primary" sx={{ mr: 1 }} />
                        <Typography>{translate('lisaa_indikaattori_10')}</Typography>
                    </Box>
                </Paper>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={props.close}>
                    {translate('CLOSE')}
                </Button>
                <Box flexGrow={1} />
            </DialogActions>
            {state.indicatorAlreadyInDocumentOpen && (
                <IndicatorAlreadyInDocumentDialog
                    isOpen={state.indicatorAlreadyInDocumentOpen}
                    close={() => setState((state) => ({ ...state, indicatorAlreadyInDocumentOpen: false }))}
                    indicators={indicatorAlreadyInDocumentIndicators}
                    addIndicators={indicatorAlreadyInDocumentAddIndicators}
                />
            )}
            {state.indicatorGraphPreviewOpen && state.indicatorGraphPreviewIndicator && (
                <IndicatorGraphPreviewDialog
                    isOpen={state.indicatorGraphPreviewOpen}
                    close={() => setState((state) => ({ ...state, indicatorGraphPreviewOpen: false }))}
                    indicator={state.indicatorGraphPreviewIndicator}
                />
            )}
        </Dialog>
    );
}

export default observer(AddIndicatorDialog);
