import React, { useEffect } from 'react';
import Nestable, { NestableItem, RenderCollapseIconArgs, RenderItemArgs } from 'react-nestable';
import { addDepths, addItem, addParentIds, editText, getDepth, removeItem } from '../../../../utils/NestableUtils';
import { observer, useLocalObservable } from 'mobx-react-lite';
import { runInAction } from 'mobx';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';
import { Info, KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { Chapter, FocusArea, FocusAreaGoal, FocusAreaGoalAction, RemovedFocusParams } from '../../../../types/document';
import { FocusNestableItem, FocusRenderItemArgs } from '../../../../types/nestable';
import { Indicator } from '../../../../types/indicator';
import FocusNestableComponent from '../../shvk/FocusNestableComponent';
import ShvkButton from '../../../../styled/ShvkButton';
import useStore from '../../../../store/storeContext';

const PREFIX = 'FocusAreaModelDialog';

const classes = {
    paper: `${PREFIX}-paper`,
};

const StyledDialog = styled(Dialog)(() => ({
    [`& .${classes.paper}`]: {
        height: '90%',
    },
}));

interface Props {
    isOpen: boolean;
    close(): void;
}

interface StoreState {
    items: FocusNestableItem[];
    removedItems: RemovedFocusParams[];
    getItems: FocusNestableItem[];
    focusAreaModel: any;
    setItems: (newItems: FocusNestableItem[]) => void;
}

let nextNewId = -1;

function FocusAreaModelDialog(props: Props) {
    const { document, localization, loadingIndicator, snackbar, theming } = useStore();

    const localStore = useLocalObservable<StoreState>(() => ({
        items: [],
        removedItems: [],

        get getItems(): FocusNestableItem[] {
            return this.items;
        },

        get focusAreaModel(): any {
            return this.getItems.map((focusArea: FocusNestableItem) => {
                return {
                    added: focusArea.id < 0,
                    id: focusArea.id,
                    title: focusArea.text,
                    orderNumber: this.getItems.findIndex((focus: FocusNestableItem) => focus.id === focusArea.id),
                    goals: focusArea.children.map((goal: FocusNestableItem) => {
                        return {
                            added: goal.id < 0,
                            id: goal.id,
                            title: goal.text,
                            orderNumber: focusArea.children.findIndex((go: FocusNestableItem) => go.id === goal.id),
                            additionalColumn: goal.additionalColumn,
                            actions: goal.children.map((action: FocusNestableItem) => {
                                return {
                                    added: action.id < 0,
                                    id: action.id,
                                    title: action.text,
                                    orderNumber: goal.children.findIndex(
                                        (act: FocusNestableItem) => act.id === action.id,
                                    ),
                                    resources: action.resources,
                                    evaluationGauge: action.evaluationGauge,
                                    additionalColumn: action.additionalColumn,
                                    sotkanetIndicators: action.sotkanetIndicators,
                                    organizationIndicators: action.organizationIndicators,
                                    otherIndicators: action.otherIndicators,
                                };
                            }),
                        };
                    }),
                };
            });
        },
        setItems(newItems: FocusNestableItem[]): void {
            this.items = newItems;
        },
    }));

    useEffect(() => {
        props.isOpen && init();
        return () => {
            nextNewId = -1;
        };
    }, [props.isOpen]);

    function init(): void {
        runInAction(() => {
            localStore.items = createNewItems();
            localStore.removedItems = [];
        });
    }

    async function save(closeOnSave: boolean): Promise<void> {
        try {
            loadingIndicator.show();
            await document.updateDocumentFocusAreaChapter(localStore.focusAreaModel, localStore.removedItems);
            await init();
            if (closeOnSave) {
                snackbar.showSuccess();
                props.close();
            }
        } catch (error) {
            snackbar.showError(error.data?.code);
        } finally {
            loadingIndicator.hide();
        }
    }

    function createNewItems(): FocusNestableItem[] {
        const focusAreas = document.currentDocument.chapters.find(
            (chapter: Chapter) => chapter.isFocusArea,
        )!.focusAreas;

        return focusAreas.map((focusArea: FocusArea) => {
            return createFocusItem(focusArea);
        });
    }

    function createFocusItem(focusArea: FocusArea): FocusNestableItem {
        return {
            id: focusArea.id,
            parentId: null,
            depth: 1,
            text: focusArea.title,
            orderNumber: focusArea.orderNumber,
            children: focusArea.goals.map((goal: FocusAreaGoal) => {
                return createGoalItem(goal, focusArea.id);
            }),
        };
    }

    function createGoalItem(goal: FocusAreaGoal, parentId: number): FocusNestableItem {
        return {
            id: goal.id,
            parentId: parentId,
            depth: 2,
            text: goal.title,
            additionalColumn: goal.additionalColumn,
            orderNumber: goal.orderNumber,
            children: goal.actions
                .slice()
                .sort((a, b) => a.orderNumber - b.orderNumber)
                .map((action: FocusAreaGoalAction) => {
                    return createActionItem(action, goal.id);
                }),
        };
    }

    function createActionItem(action: FocusAreaGoalAction, parentId: number): FocusNestableItem {
        return {
            id: action.id,
            parentId: parentId,
            depth: 3,
            text: action.title,
            orderNumber: action.orderNumber,
            resources: action.resources,
            evaluationGauge: action.evaluationGauge,
            additionalColumn: action.additionalColumn,
            children: [],
            sotkanetIndicators: action.sotkanetIndicators.map((indicator: Indicator) => {
                return { id: indicator.id };
            }),
            organizationIndicators: action.organizationIndicators.map((indicator: Indicator) => {
                return { id: indicator.id };
            }),
            otherIndicators: action.otherIndicators.map((indicator: Indicator) => {
                return { id: indicator.id };
            }),
        };
    }

    function addFocusNestableItem(parentId: number | null): void {
        const { translate } = localization;
        const depth = parentId === null ? 1 : getDepth(parentId, localStore.getItems) + 1;
        const newItem: FocusNestableItem = {
            id: nextNewId--,
            text: depth === 1 ? translate('FOCUSAREA') : depth === 2 ? translate('GOAL') : translate('ACTION'),
            orderNumber: -1,
            children: [],
            parentId,
            depth,
        };
        if (parentId !== null) {
            // A new subdirectory
            const newItems = localStore.getItems.map(addItem(newItem, parentId));
            localStore.setItems(newItems as FocusNestableItem[]);
        } else {
            // A new main directory
            const newItems = [...localStore.getItems];
            newItems.push(newItem);
            localStore.setItems(newItems as FocusNestableItem[]);
            handleListChange(newItems);
        }
    }

    function removeFocusNestableItem(item: FocusNestableItem): void {
        const newItems = localStore.getItems.filter(removeItem(item));
        localStore.setItems(newItems as FocusNestableItem[]);

        const id = item.id;
        const itemType = item.depth === 1 ? 'focusArea' : item.depth === 2 ? 'goal' : 'action';
        runInAction(() => {
            localStore.removedItems.push({ id, itemType });
        });
    }

    function handleTextChange(value: string, id: number): void {
        const newItems = localStore.getItems.map(editText(id, value));
        localStore.setItems(newItems as FocusNestableItem[]);
    }

    function handleListChange(items: NestableItem[]): void {
        const focusItems = items as FocusNestableItem[];
        let newItems = focusItems.map(addParentIds());
        newItems = newItems.map(addDepths());
        localStore.setItems(newItems as FocusNestableItem[]);
    }

    function renderCollapseIcon(args: RenderCollapseIconArgs): JSX.Element {
        return (
            <IconButton size="small" color="primary">
                {args.isCollapsed ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
            </IconButton>
        );
    }

    const renderItem = (args: RenderItemArgs): React.ReactNode => {
        return (
            <FocusNestableComponent
                args={args as FocusRenderItemArgs}
                handleTextChange={handleTextChange}
                addItem={addFocusNestableItem}
                removeItem={removeFocusNestableItem}
                save={save}
            />
        );
    };

    const confirmChange = (FocusDragItem: NestableItem, FocusDestinationParent: NestableItem): boolean => {
        const dragItem = FocusDragItem as FocusNestableItem;
        const destinationParent = FocusDestinationParent as FocusNestableItem;
        //Item can´t change depth
        return destinationParent?.depth === (dragItem.depth - 1 || undefined);
    };

    const { translate } = localization;

    return (
        <StyledDialog
            id="chapter-model-dialog"
            open={props.isOpen}
            onClose={props.close}
            classes={{ paper: classes.paper }}
            maxWidth="md"
            fullWidth
        >
            <DialogTitle id="chapter-model-dialog-title">
                <Box pt={1} display="flex">
                    <Info style={{ marginRight: theming.spacing(1) }} color="primary" />
                    <Typography variant="body2">{translate('DOCUMENT_FOCUS_AREA_MODEL_EDIT_TIP')}</Typography>
                </Box>
            </DialogTitle>
            <DialogContent>
                <Nestable
                    items={localStore.getItems}
                    renderItem={renderItem}
                    onChange={handleListChange}
                    confirmChange={confirmChange}
                    maxDepth={3}
                    renderCollapseIcon={renderCollapseIcon}
                    collapsed={true}
                />
            </DialogContent>
            <DialogActions>
                <ShvkButton onClick={() => addFocusNestableItem(null)}>{translate('ADD_FOCUS_AREA')}</ShvkButton>
                <Box flexGrow={1} />
                <Button variant="contained" onClick={props.close}>
                    {translate('CLOSE')}
                </Button>
                <ShvkButton onClick={() => save(true)}>{translate('SAVE')}</ShvkButton>
            </DialogActions>
        </StyledDialog>
    );
}

export default observer(FocusAreaModelDialog);
