import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { Save } from '@mui/icons-material';
import { Indicator, SaveIndicatorParams } from '../../../../types/indicator';
import { TextFieldEvent } from '../../../../types/events';
import ShvkButton from '../../../../styled/ShvkButton';
import ShvkTextField from '../../../../styled/ShvkTextField';
import { runInAction } from 'mobx';
import useStore from '../../../../store/storeContext';

interface Props {
    indicator: Indicator;
    isOpen: boolean;
    close(): void;
}

interface State {
    url: string;
}

function EditIndicatorUrlDialog(props: Props) {
    const context = useStore();
    const { localization, snackbar, theming } = context;

    const [state, setState] = useState<State>({
        url: '',
    });

    useEffect(() => {
        props.isOpen && init();
    }, [props.isOpen]);

    function init(): void {
        setState((state) => ({ ...state, url: props.indicator.infoUrl || '' }));
    }

    async function saveUrl(): Promise<void> {
        if (!props.indicator) return;

        try {
            const params: SaveIndicatorParams = {
                infoUrl: state.url,
                source: props.indicator.source,
                indicatorId: props.indicator.id,
            };
            await context.indicator.saveIndicator(params);
            runInAction(() => (props.indicator.infoUrl = state.url));
            snackbar.showSuccess();
            props.close();
        } catch (e) {
            snackbar.showError(e.data?.code);
        }
    }

    const handleChange = (event: TextFieldEvent): void => {
        setState((state) => ({ ...state, url: event.target.value }));
    };

    const { translate } = localization;

    return (
        <Dialog
            open={props.isOpen}
            onClose={props.close}
            aria-labelledby="edit-indicator-url-dialog"
            maxWidth={'sm'}
            fullWidth
        >
            <DialogTitle id="edit-indicator-url-dialog-title">
                {translate('EDIT_INDICATOR_URL')}: {props.indicator?.name}
            </DialogTitle>
            <DialogContent dividers>
                <ShvkTextField
                    multiline
                    rows={5}
                    label={translate('INDICATOR_URL')}
                    variant="outlined"
                    type="number"
                    size="small"
                    fullWidth
                    style={{ marginBottom: theming.theme.spacing(2) }}
                    value={state.url}
                    onChange={handleChange}
                />
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={props.close}>
                    {translate('CANCEL')}
                </Button>
                <Box flexGrow={1} />
                <ShvkButton startIcon={<Save />} onClick={saveUrl}>
                    {translate('SAVE')}
                </ShvkButton>
            </DialogActions>
        </Dialog>
    );
}

export default observer(EditIndicatorUrlDialog);
