import Axios from '../../axiosConfig';
import { BACKEND_URL } from '../../config';
import { configure, makeAutoObservable, runInAction } from 'mobx';
import {
    AddImpactOnTargetIndicatorParams,
    AddIndicatorDialogIndicator,
    AddIndicatorParams,
    AddIndicatorTopParams,
    ComparisonSettings,
    ComparisonSettingsRequestParams,
    CreateIndicatorParams,
    EnableIndicatorParams,
    FetchIndicatorDataParams,
    GraphDataItem,
    GraphDataTick,
    Indicator,
    IndicatorChange,
    IndicatorData,
    IndicatorPackage,
    IndicatorPackageTheme,
    IndicatorShortTermChangeData,
    IndicatorSource,
    IndicatorTop,
    IndicatorTypeCode,
    OrganizationIndicatorData,
    RemoveIndicatorFromImpactOnTargetParams,
    RemoveIndicatorParams,
    RemoveIndicatorTopParams,
    SaveIndicatorDirectionParams,
    SaveIndicatorParams,
    SetPrintableParams,
    UpdateOrganizationIndicatorParams,
} from '../../types/indicator';
import Organization from '../../types/organization';
import RootModule from '../rootModule';

configure({ enforceActions: 'observed' });

export default class IndicatorModule {
    private rootModule: RootModule;
    public readonly COMPARE_ORGANIZATION_TO_OTHERS = 0 as const;
    public readonly COMPARE_OTHERS_TO_ORGANIZATION = 1 as const;
    public readonly changeLimits = { smallChangeMin: 5, mediumChangeMin: 10, largeChangeMin: 15 };

    private readonly compareYearLimits = {
        yearlyReportCompareYearLimit: 3,
        largeReportCompareYear: 5,
        largeReportCompareYearLimit: 3,
    };
    private indicatorViewMode = 'WIDE_MODE';
    private minimumDataContentIDsForArea: number[] = [];
    private minimumDataContentIDsForMunicipality: number[] = [];
    private settings: ComparisonSettings | null = null;
    private otherIndicatorSourceList: IndicatorSource[] = [];
    private indicatorTopList: IndicatorTop[] = [];
    private summaryDataForIndicatorTop: Indicator[] | null = null;

    constructor(rootModule: RootModule) {
        makeAutoObservable(this, {}, { autoBind: true });
        this.rootModule = rootModule;
    }

    public get getMinimumDataContentIDsForArea(): number[] {
        return this.minimumDataContentIDsForArea;
    }

    public get getMinimumDataContentIDsForMunicipality(): number[] {
        return this.minimumDataContentIDsForMunicipality;
    }

    public changeIndicatorViewType(): void {
        this.indicatorViewMode === 'COMPACT_MODE'
            ? (this.indicatorViewMode = 'WIDE_MODE')
            : (this.indicatorViewMode = 'COMPACT_MODE');
    }

    public get getIndicatorViewMode(): string {
        return this.indicatorViewMode;
    }

    public get indicatorTops(): IndicatorTop[] {
        return this.indicatorTopList;
    }

    public get summaryData(): Indicator[] | null {
        return this.summaryDataForIndicatorTop;
    }

    public set summaryData(data: Indicator[] | null) {
        this.summaryDataForIndicatorTop = data;
    }

    public async fetchPackageThemes(id: number): Promise<void> {
        const response = await Axios({
            method: 'POST',
            url: BACKEND_URL + 'document/packageThemes',
            data: { id },
        });
        const themes: IndicatorPackageTheme[] = response.data;
        this.rootModule.document.setIndicatorPackageThemes(themes, id);
    }

    public async searchIndicators(source: string, text: string): Promise<AddIndicatorDialogIndicator[]> {
        const organizationId = this.rootModule.organization.currentOrganizationId;

        const response = await Axios({
            method: 'POST',
            url: BACKEND_URL + 'indicator/search',
            data: {
                organization: organizationId,
                source,
                text,
            },
        });
        return response.data;
    }

    public async fetchOtherIndicatorSources(): Promise<void> {
        const response = await Axios({
            method: 'GET',
            url: BACKEND_URL + 'indicator/listOtherIndicatorSources',
        });
        this.otherIndicatorSourceList = response.data;
    }

    public async addIndicator(params: AddIndicatorParams): Promise<Indicator[]> {
        const response = await Axios({
            method: 'POST',
            url: BACKEND_URL + 'indicator/add',
            data: params,
        });
        this.rootModule.document.addCurrentDocumentIndicators(params.theme, response.data as Indicator[]);
        return response.data as Indicator[];
    }

    public async updateOrganizationIndicator(params: UpdateOrganizationIndicatorParams): Promise<void> {
        const response = await Axios({
            method: 'POST',
            url: BACKEND_URL + 'indicator/updateOrganizationIndicator',
            data: params,
        });

        const indicatorPackages = this.rootModule.document.indicatorPackageChapter?.indicatorPackages;
        if (indicatorPackages) {
            const pkg = indicatorPackages.find((indpkg) =>
                indpkg.themes.find((theme) => theme.organizationIndicators.find((ind) => ind.id === response.data.id)),
            );
            if (pkg) {
                const theme = pkg.themes.find((theme) =>
                    theme.organizationIndicators.find((ind) => ind.id === response.data.id),
                );
                if (theme) {
                    runInAction(() => {
                        theme.organizationIndicators = theme.organizationIndicators.map((ind) =>
                            ind.id === response.data.id ? response.data : ind,
                        );
                    });
                }
            }
        }
    }

    public async create(params: CreateIndicatorParams): Promise<Indicator> {
        const response = await Axios({
            method: 'POST',
            url: BACKEND_URL + 'indicator/create',
            data: params,
        });
        if (response.status === 200) {
            return response.data;
        } else return {} as Indicator;
    }

    public async saveIndicator(params: SaveIndicatorParams): Promise<void> {
        await Axios({
            method: 'POST',
            url: BACKEND_URL + 'indicator/saveIndicator',
            data: params,
        });
    }

    public async fetchIndicatorData(params: FetchIndicatorDataParams): Promise<IndicatorData[]> {
        const response = await Axios({
            method: 'POST',
            url: BACKEND_URL + 'indicator/data',
            data: params,
        });
        return response.data;
    }

    public async fetchIndicatorDataForPreview(indicator: AddIndicatorDialogIndicator): Promise<IndicatorData[]> {
        const response = await Axios({
            method: 'POST',
            url: BACKEND_URL + 'indicator/getDataForPreview',
            data: {
                indicatorId: indicator.id,
                source: indicator.source.code,
                documentId: this.rootModule.document.currentDocument.id,
            },
        });
        return response.data;
    }

    public async enableIndicator(params: EnableIndicatorParams): Promise<void> {
        await Axios({
            method: 'POST',
            url: BACKEND_URL + 'indicator/enabledIndicator',
            data: params,
        });
    }

    public async setPrintable(params: SetPrintableParams): Promise<void> {
        await Axios({
            method: 'POST',
            url: BACKEND_URL + 'indicator/setPrintable',
            data: params,
        });
    }

    public async fetchSummary(chapterId: number): Promise<string | null> {
        const response = await Axios({
            method: 'POST',
            url: BACKEND_URL + 'indicator/getSummary',
            data: { chapterId },
        });
        if (response.status === 200) return response.data.text;
        else return null;
    }

    public async fetchDataForIndicatorTop(chapterId: number): Promise<Indicator[]> {
        const response = await Axios({
            method: 'POST',
            url: BACKEND_URL + 'indicator/getDataForIndicatorTop',
            data: { chapterId },
        });
        return response.data as Indicator[];
    }

    public async fetchIndicatorTops(): Promise<void> {
        const response = await Axios({
            method: 'POST',
            url: BACKEND_URL + 'indicator/getIndicatorTops',
            data: { documentId: this.rootModule.document.currentDocument.id },
        });
        runInAction(() => {
            this.indicatorTopList = response.data;
        });
    }

    public async addIndicatorTop(params: AddIndicatorTopParams): Promise<void> {
        const response = await Axios({
            method: 'POST',
            url: BACKEND_URL + 'indicator/indicatorTop',
            data: { ...params, documentId: this.rootModule.document.currentDocument.id },
        });
        runInAction(() => {
            this.indicatorTopList.push(response.data);
            this.fetchSummaryDataForIndicatorTop();
        });
    }

    public async removeIndicatorTop(params: RemoveIndicatorTopParams): Promise<void> {
        const response = await Axios({
            method: 'DELETE',
            url: BACKEND_URL + 'indicator/indicatorTop',
            data: { ...params, documentId: this.rootModule.document.currentDocument.id },
        });
        runInAction(() => {
            this.indicatorTopList = this.indicatorTopList.filter(
                (indicatorTop) => indicatorTop.id !== response.data.id,
            );
            this.fetchSummaryDataForIndicatorTop();
        });
    }

    public async fetchSummaryDataForIndicatorTop(): Promise<void> {
        const summaryChapter = this.rootModule.document.currentDocument.chapters.find((chp) => chp.isSummary);
        if (summaryChapter) {
            const data = await this.fetchDataForIndicatorTop(summaryChapter.id);
            runInAction(() => {
                this.summaryDataForIndicatorTop = data;
            });
        }
    }

    public async saveIndicatorDirection(params: SaveIndicatorDirectionParams): Promise<void> {
        await Axios({
            method: 'POST',
            url: BACKEND_URL + 'indicator/saveIndicatorDirection',
            data: params,
        });
    }

    public async removeIndicator(params: RemoveIndicatorParams): Promise<void> {
        await Axios({
            method: 'POST',
            url: BACKEND_URL + 'indicator/remove',
            data: params,
        });
        this.rootModule.document.removeCurrentDocumentIndicator(params);
    }

    public async removeIndicatorFromImpactOnTarget(params: RemoveIndicatorFromImpactOnTargetParams): Promise<void> {
        await Axios({
            method: 'POST',
            url: BACKEND_URL + 'eva/removeIndicatorFromImpactOnTarget',
            data: params,
        });
        this.rootModule.document.removeCurrentDocumentImpactOnTargetIndicator(params);
    }

    public async addImpactOnTargetIndicator(params: AddImpactOnTargetIndicatorParams): Promise<void> {
        const response = await Axios({
            method: 'POST',
            url: BACKEND_URL + 'eva/addImpactOnTargetIndicator',
            data: params,
        });
        this.rootModule.document.addCurrentDocumentImpactOnTargetIndicators(
            params.impactId,
            response.data as Indicator[],
        );
    }

    public async saveComparisonSettings(settings: ComparisonSettingsRequestParams): Promise<void> {
        const response = await Axios({
            method: 'POST',
            url: BACKEND_URL + 'document/saveComparisonSettings',
            data: {
                comparisonSettings: settings,
                documentId: this.rootModule.document.currentDocument.id,
            },
        });

        const newSettings: ComparisonSettings = {
            changeYearsVisibility: settings.changeYearsVisibility,
            compareDirection: settings.compareDirection,
            documentId: response.data.documentId,
            endYear: response.data.endYear,
            graphScale: settings.graphScale,
            id: response.data.id,
            organizationName: settings.organizationName,
            organizations: response.data.organizations.map((org: Organization) => {
                return {
                    ...org,
                    name: org.name[this.rootModule.localization.locale],
                };
            }),
            startYear: response.data.startYear,
        };

        runInAction(() => {
            this.settings = newSettings;
        });
    }

    public async fetchMinimumDataContentIDsForArea(): Promise<void> {
        const response = await Axios({
            method: 'GET',
            url: BACKEND_URL + 'indicator/listMinimumDataContentIDsForArea',
        });
        runInAction(() => {
            this.minimumDataContentIDsForArea = response.data;
        });
    }

    public async fetchMinimumDataContentIDsForMunicipality(): Promise<void> {
        const response = await Axios({
            method: 'GET',
            url: BACKEND_URL + 'indicator/listMinimumDataContentIDsForMunicipality',
        });
        runInAction(() => {
            this.minimumDataContentIDsForMunicipality = response.data;
        });
    }

    public clearComparisonSettings(): void {
        this.settings = null;
    }

    public setComparisonSettings(comparisonSettings: ComparisonSettings): void {
        if (!comparisonSettings.compareDirection) {
            comparisonSettings.compareDirection = this.COMPARE_ORGANIZATION_TO_OTHERS;
        }
        this.settings = comparisonSettings;
    }

    public get otherIndicatorSources(): IndicatorSource[] {
        return this.otherIndicatorSourceList;
    }

    public get comparisonSettings(): ComparisonSettings | null {
        return this.settings;
    }

    public indicatorPackageById(id: number): IndicatorPackage | null {
        return this.rootModule.document.indicatorPackageChapter?.indicatorPackages.find((pkg) => pkg.id === id) || null;
    }

    public indicatorsByTypeFromTheme = (
        indicatorTheme: IndicatorPackageTheme,
        code: IndicatorTypeCode,
    ): Indicator[] => {
        const indicators = [
            ...indicatorTheme.sotkanetIndicators,
            ...indicatorTheme.otherIndicators,
            ...indicatorTheme.organizationIndicators,
        ];
        return indicators.filter((indicator) => indicator.type.code === code);
    };

    public sortIndicatorsByEnabled(indicators: Indicator[]): Indicator[] {
        // Sort the indicators first by orderNumber, and then by their enabled status. Enabled indicators
        // appear before !enabled indicators.
        const sortedByOrder = this.sortByOrderNumber(indicators);

        return sortedByOrder.sort((a, b) => {
            if (a.enabled && !b.enabled) return -1;
            else if (!a.enabled && b.enabled) return 1;
            else return a.orderNumber - b.orderNumber;
        });
    }

    public sortByOrderNumber(indicators: Indicator[]): Indicator[] {
        return indicators.sort((a, b) => a.orderNumber - b.orderNumber);
    }

    public removeDuplicatesFromIndicatorData(data: IndicatorData[]): IndicatorData[] {
        return data.reduce((acc, cur) => {
            if (!acc.some((item) => item.year === cur.year && item.organization.id === cur.organization.id)) {
                acc.push(cur);
            }
            return acc;
        }, [] as IndicatorData[]);
    }

    public filterIndicatorDataBetweenCompareYears = (data: IndicatorData[]): IndicatorData[] => {
        if (!this.comparisonSettings) return data;
        const { startYear, endYear } = this.comparisonSettings;
        if (!startYear || !endYear) return data;
        return data.filter((item) => item.year >= startYear && item.year <= endYear);
    };

    public groupIndicatorDataByOrganization(data: IndicatorData[]): OrganizationIndicatorData[] {
        const organizationData: OrganizationIndicatorData[] = [];

        data.forEach((dataItem) => {
            const orgName = dataItem.organization.name;
            const shortName = dataItem.organization.shortName;
            const inIndicatorTops = dataItem.inIndicatorTops;
            const organization = organizationData.find((orgItem) => orgItem.organizationName === orgName);
            if (!organization) {
                organizationData.push({
                    organizationName: orgName,
                    data: [dataItem],
                    organizationShortName: shortName,
                    inIndicatorTops: inIndicatorTops,
                });
            } else {
                organization.data.push(dataItem);
            }
        });

        return organizationData;
    }

    public calculateChange = (data: IndicatorShortTermChangeData[] | undefined): IndicatorChange => {
        const numericChange: IndicatorChange = { value: null, startYear: null, endYear: null };

        if (!data) return numericChange;
        if (data.length < 2) return numericChange;

        const reportType = this.rootModule.document.currentDocument.type.code;
        const lastItem = data[data.length - 1];

        if (reportType === 'LARGE_REPORT' || reportType === 'REGIONAL_LARGE_REPORT') {
            const result = this.getLargeReportCompareYear(lastItem.year, data);
            if (result.compareItem) {
                numericChange.value = this.calculateChangePercent(result.compareItem.value, lastItem.value);
                numericChange.startYear = result.compareItem?.year || null;
                numericChange.endYear = lastItem.year;
            }
        } else if (
            reportType === 'YEARLY_REPORT' ||
            reportType === 'REGIONAL_YEARLY_REPORT' ||
            reportType === 'ANOTHER_REPORT'
        ) {
            const compareItem = data[data.length - 2];
            if (lastItem.year - compareItem.year <= this.compareYearLimits.yearlyReportCompareYearLimit) {
                numericChange.value = this.calculateChangePercent(compareItem.value, lastItem.value);
                numericChange.startYear = compareItem?.year || null;
                numericChange.endYear = lastItem.year;
            }
        }

        return numericChange;
    };

    private calculateChangePercent = (oldValue: number, newValue: number): number => {
        if (oldValue === 0) {
            if (newValue > 0) return 100;
            else if (newValue < 0) return -100;
            else return 0;
        }
        return ((newValue - oldValue) / Math.abs(oldValue)) * 100;
    };

    private getLargeReportCompareYear = (
        year: number,
        data: IndicatorShortTermChangeData[],
    ): { compareItem: IndicatorShortTermChangeData | null; fetchData: boolean | null } => {
        const { largeReportCompareYear, largeReportCompareYearLimit } = this.compareYearLimits;

        for (const item of data) {
            if (year - item.year === largeReportCompareYear) {
                return { compareItem: item, fetchData: false };
            } else if (year - item.year >= largeReportCompareYearLimit && year - item.year <= largeReportCompareYear) {
                return { compareItem: item, fetchData: true };
            }
        }

        return { compareItem: null, fetchData: null };
    };

    public filterCurrentOrganizationData = (data: IndicatorData[], origoOrganization?: string): IndicatorData[] => {
        const { currentDocument } = this.rootModule.document;
        const organizationName =
            origoOrganization || currentDocument.organization.shortName || currentDocument.organization.name;
        return data.filter(
            (indicatorData) =>
                indicatorData.organization.shortName === organizationName ||
                indicatorData.organization.name === organizationName,
        );
    };

    public dataForLargeGraph(graphData: GraphDataItem[]): GraphDataTick[] {
        const years = new Set(graphData.flatMap((data) => data.values.flatMap((value) => value.year)));
        const data = [...years].map((year) => ({
            year: year,
        }));
        data.forEach((data) => {
            graphData.forEach((organization) => {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                data[organization.shortName || organization.name] =
                    organization.values.find((o) => o.year === data.year)?.value ?? null;
            });
        });
        return data.sort((a, b) => b.year - a.year);
    }

    public dynamicMarginTopForGraphLabel(organizationsForGraph: string[]): number {
        let margin;
        const organizationCount = organizationsForGraph.length;
        if (organizationCount <= 10) {
            margin = 40;
        } else if (organizationCount > 10 && organizationCount <= 15) {
            margin = 50;
        } else if (organizationCount > 15 && organizationCount <= 20) {
            margin = 60;
        } else if (organizationCount > 20 && organizationCount <= 25) {
            margin = 70;
        } else if (organizationCount > 25 && organizationCount <= 30) {
            margin = 80;
        } else margin = 90;

        return margin;
    }
}
