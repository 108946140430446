import React from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Tooltip } from '@mui/material';
import { EditorDecisionOption, EditorImpactOnTarget, EditorPerspective } from '../../../../types/evaTemplate';
import EvaPerspectiveTable from '../../eva/decisionOptionArea/EvaPerspectiveTable';
import EvaImpactOnTargetRow from '../../eva/decisionOptionArea/EvaImpactOnTargetRow';
import InfoIcon from '@mui/icons-material/Info';
import useStore from '../../../../store/storeContext';

interface Props {
    isOpen: boolean;
    close(): void;
    impactOnTarget?: EditorImpactOnTarget | null;
    perspective?: EditorPerspective | null;
    decisionOption?: EditorDecisionOption | null;
}

function ImpactOnTargetDialog(props: Props) {
    const { localization, theming } = useStore();

    const { translate } = localization;

    return (
        <Dialog
            open={props.isOpen}
            onClose={props.close}
            aria-labelledby="impact-on-target-dialog-title"
            maxWidth={'lg'}
        >
            <DialogTitle id="impact-on-target-dialog-title">
                {`${props.decisionOption?.text} - ${props.perspective?.title}`}
                <Tooltip title={translate('paatosvaihtoehdon_muokkausruutu_16')}>
                    <InfoIcon
                        color="primary"
                        style={{
                            display: 'inline-block',
                            verticalAlign: 'middle',
                            marginBottom: theming.spacing(0.5),
                            marginLeft: theming.spacing(1),
                        }}
                    />
                </Tooltip>
            </DialogTitle>
            <DialogContent dividers>
                <EvaPerspectiveTable perspective={props.perspective!} editing>
                    <EvaImpactOnTargetRow impact={props.impactOnTarget!} isDialog editing />
                </EvaPerspectiveTable>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={props.close}>
                    {translate('CLOSE')}
                </Button>
                <Box flexGrow={1} />
            </DialogActions>
        </Dialog>
    );
}

export default observer(ImpactOnTargetDialog);
