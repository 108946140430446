import { alpha, styled } from '@mui/material/styles';
import { IconButton } from '@mui/material';

const DangerIconButton = styled(IconButton)(({ theme }) => ({
    color: theme.palette.error.main,
    '&:hover': {
        backgroundColor: alpha(theme.palette.error.main, theme.palette.action.hoverOpacity),
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
            backgroundColor: 'transparent',
        },
    },
}));

export default DangerIconButton;
