import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import Nestable, { NestableItem, RenderCollapseIconArgs, RenderItemArgs } from 'react-nestable';
import {
    addDepths,
    addItem,
    addParentIds,
    editText,
    getDepth,
    handlerStyle,
    removeItem,
} from '../../utils/NestableUtils';
import { DirectoryNestableItem, DirectoryRenderItemArgs } from '../../types/nestable';
import { Box, Button, Grid, IconButton } from '@mui/material';
import { CreateNewFolder, KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import Directory from '../../types/directory';
import { OrganizationDocument } from '../../types/document';
import DirectoryNestableComponent from './DirectoryNestableComponent';
import useStore from '../../store/storeContext';

interface Props {
    directories: Directory[];
    documents: OrganizationDocument[];
    editing: boolean;
    setList(newList: DirectoryNestableItem[]): void;
    list: DirectoryNestableItem[];
    addRemovedDirectoryId(id: number): void;
}

let nextNewId = -1;

function DirectoryStructure(props: Props) {
    const { localization } = useStore();

    useEffect(() => {
        return () => {
            nextNewId = -1;
        };
    }, []);

    function addDirectoryItem(parentId: number | null): void {
        const { translate } = localization;
        const depth = parentId === null ? 1 : getDepth(parentId, props.list) + 1;
        const newItem: DirectoryNestableItem = {
            id: nextNewId--,
            text: depth === 1 ? translate('NEW_MAIN_DIRECTORY') : translate('NEW_SUB_DIRECTORY'),
            children: [],
            isDocument: false,
            parentId,
            depth,
        };
        const oldList = [...props.list];
        if (parentId !== null) {
            // New subdirectory
            const newList = oldList.map(addItem(newItem, parentId));
            props.setList(newList as DirectoryNestableItem[]);
        } else {
            // New main directory
            const newList = [newItem, ...oldList];
            props.setList(newList);
            handleListChange(newList);
        }
    }

    function removeDirectoryItem(item: DirectoryNestableItem): void {
        const oldList = [...props.list];
        const newList = oldList.filter(removeItem(item));
        props.setList(newList as DirectoryNestableItem[]);
        if (item.id > 0) props.addRemovedDirectoryId(item.id);
    }

    function confirmChange(dragItem: NestableItem, destinationParent: NestableItem): boolean {
        const directoryDestinationParent = destinationParent as DirectoryNestableItem;
        const directoryDragItem = dragItem as DirectoryNestableItem;

        // Anything can be moved to root level.
        if (!directoryDestinationParent) return true;
        // Nothing can be moved into a document.
        if (directoryDestinationParent.isDocument) return false;
        // Directory can't be moved into subdirectory
        if (!directoryDragItem.isDocument && directoryDestinationParent.depth === 2) return false;
        // Allow everything else
        return true;
    }

    function renderCollapseIcon(args: RenderCollapseIconArgs): JSX.Element {
        return (
            <IconButton size="small" color="primary">
                {args.isCollapsed ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
            </IconButton>
        );
    }

    function renderItem(args: RenderItemArgs): React.ReactNode {
        return (
            <DirectoryNestableComponent
                args={args as DirectoryRenderItemArgs}
                handleTextChange={handleTextChange}
                addItem={addDirectoryItem}
                removeItem={removeDirectoryItem}
                editing={props.editing}
            />
        );
    }

    const handleTextChange = (value: string, id: number): void => {
        const oldList = [...props.list];
        const newList = oldList.map(editText(id, value));
        props.setList(newList as DirectoryNestableItem[]);
    };

    const handleListChange = (items: NestableItem[]): void => {
        const directoryItems = [...items] as DirectoryNestableItem[];
        let newList = directoryItems.map(addParentIds());
        newList = newList.map(addDepths());
        props.setList(newList as DirectoryNestableItem[]);
    };

    const { translate } = localization;

    return (
        <Grid container spacing={2} justifyContent="space-between">
            {props.editing && (
                <Grid item xs={12}>
                    <Box display="flex" justifyContent="flex-end">
                        <Button
                            color="secondary"
                            variant="outlined"
                            onClick={(): void => addDirectoryItem(null)}
                            endIcon={<CreateNewFolder />}
                        >
                            {translate('ADD_MAIN_DIRECTORY')}
                        </Button>
                    </Box>
                </Grid>
            )}
            <Grid item xs={12}>
                <Nestable
                    items={props.list}
                    renderItem={renderItem}
                    maxDepth={3}
                    onChange={handleListChange}
                    confirmChange={confirmChange}
                    renderCollapseIcon={renderCollapseIcon}
                    handler={<span style={handlerStyle} />}
                    collapsed={true}
                />
            </Grid>
        </Grid>
    );
}

export default observer(DirectoryStructure);
