import React from 'react';
import { observer } from 'mobx-react-lite';
import { Box, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material';
import { Indicator } from '../../../../types/indicator';

interface Props {
    title: string;
    indicatorList: Indicator[];
    selectedIndicators: Indicator[];
    selectMany(indicators: Indicator[]): void;
}

function PowerPointDialogIndicatorsByType(props: Props) {
    const { title, indicatorList, selectedIndicators, selectMany } = props;
    return (
        <Box ml={3}>
            <ListItemButton onClick={() => selectMany(indicatorList)} style={{ paddingTop: 0.5, paddingBottom: 0.5 }}>
                <ListItemText>
                    <Typography variant="subtitle2">{title}</Typography>
                </ListItemText>
            </ListItemButton>
            <Box ml={1}>
                {indicatorList.map((indicator) => (
                    <ListItemButton
                        key={indicator.id}
                        style={{ paddingTop: 0.5, paddingBottom: 0.5 }}
                        onClick={() => selectMany([indicator])}
                    >
                        <ListItemText>{indicator.name}</ListItemText>
                        <ListItemIcon>
                            {selectedIndicators.includes(indicator) ? (
                                <CheckBox color="primary" />
                            ) : (
                                <CheckBoxOutlineBlank color="primary" />
                            )}
                        </ListItemIcon>
                    </ListItemButton>
                ))}
            </Box>
        </Box>
    );
}

export default observer(PowerPointDialogIndicatorsByType);
