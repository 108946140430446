import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { EditorImpactOnTarget } from '../../../../types/evaTemplate';
import { TextFieldEvent } from '../../../../types/events';
import ShvkButton from '../../../../styled/ShvkButton';
import ShvkTextField from '../../../../styled/ShvkTextField';
import useStore from '../../../../store/storeContext';

interface Props {
    isOpen: boolean;
    close(): void;
    impactOnTarget?: EditorImpactOnTarget | null;
}

interface State {
    title: string;
}

function EditImpactOnTargetNameDialog(props: Props) {
    const { document, localization, snackbar, loadingIndicator, theming } = useStore();

    const [state, setState] = useState<State>({
        title: '',
    });

    useEffect(() => {
        props.isOpen && init();
    }, [props.isOpen]);

    async function save(): Promise<void> {
        try {
            loadingIndicator.show();
            await document.updateEvaImpactOnTargetTitle(props.impactOnTarget, state.title);
            snackbar.showSuccess();
        } catch (error) {
            snackbar.showError(error.data?.code);
        } finally {
            props.close();
            loadingIndicator.hide();
        }
    }

    function init(): void {
        setState((state) => ({ ...state, title: props.impactOnTarget?.target || '' }));
    }

    const handleChange = (event: TextFieldEvent): void => {
        setState((state) => ({ ...state, title: event.target.value }));
    };

    const { translate } = localization;

    return (
        <Dialog
            open={props.isOpen}
            onClose={props.close}
            aria-labelledby="edit-impact-on-target-name-dialog-title"
            maxWidth={'sm'}
            fullWidth
        >
            <DialogTitle id="edit-impact-on-target-name-dialog-title">
                {translate('EDIT_EVA_IMPACT_ON_TARGET_TITLE')}
            </DialogTitle>
            <DialogContent dividers>
                <ShvkTextField
                    required
                    label={translate('NAME')}
                    style={{ marginBottom: theming.theme.spacing(2) }}
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={state.title}
                    onChange={handleChange}
                />
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={props.close}>
                    {translate('CANCEL')}
                </Button>
                <Box flexGrow={1} />
                <ShvkButton onClick={save} disabled={!state.title}>
                    {translate('SAVE')}
                </ShvkButton>
            </DialogActions>
        </Dialog>
    );
}

export default observer(EditImpactOnTargetNameDialog);
