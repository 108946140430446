import Axios from 'axios';
import { rootModule } from './store/storeContext';

const instance = Axios.create();

//Adding language to all outbound requests:
instance.interceptors.request.use((request) => {
    const language = rootModule.localization.locale;
    const token = rootModule.session.parameters.authToken;
    if (language) {
        request.headers['Language'] = language;
    }
    if (token) {
        request.headers['X-Auth-Token'] = token;
    }
    return request;
});

//Request error handler middleware:
instance.interceptors.response.use(
    (response) => {
        const contentType = response.headers['content-type'];
        if (typeof response.data === 'string' && contentType?.includes('json')) {
            // JSON is broken. Fix vertical tabs and parse back to JSON.
            response.data = JSON.parse(response.data.replace(/\\v/g, '\\u000B'));
        }
        return response;
    },
    async (error) => {
        throw error.response;
    },
);

export default instance;
