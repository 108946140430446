import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Button, ButtonGroup, Tooltip, Typography } from '@mui/material';
import { Delete, Edit, Info } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { Chapter, FocusArea } from '../../../types/document';
import FocusAreaTable from './FocusAreaTable';
import EditFocusAreaDialog from '../dialogs/shvk/EditFocusAreaDialog';
import ShvkSecondaryButton from '../../../styled/ShvkSecondaryButton';
import useStore from '../../../store/storeContext';

const PREFIX = 'FocusAreas';

const classes = {
    pointer: `${PREFIX}-pointer`,
};

const Root = styled('div')(() => ({
    [`& .${classes.pointer}`]: {
        cursor: 'pointer',
    },
}));

interface Props {
    chapter: Chapter;
    editing: boolean;
}

interface State {
    open: boolean;
    focusArea: FocusArea;
}

function FocusAreas(props: Props) {
    const { document, dialog, localization, loadingIndicator, snackbar } = useStore();

    const [state, setState] = useState<State>({
        open: false,
        focusArea: {} as FocusArea,
    });

    function handleChangeTitle(focusArea: FocusArea): void {
        if (!document.isCurrentDocumentApproved && !document.isPreview && props.editing) {
            setState((state) => ({
                ...state,
                open: true,
                focusArea,
            }));
        }
    }

    async function removeFocusArea(focusAreaId: number): Promise<void> {
        const confirmation = await dialog.getConfirmation(undefined, undefined, true);
        if (!confirmation) return;

        try {
            loadingIndicator.show();
            await document.removeFocusArea(focusAreaId);
            snackbar.showSuccess();
        } catch (error) {
            snackbar.showError(error.data?.code);
        } finally {
            loadingIndicator.hide();
        }
    }

    const { isPreview } = document;
    const { translate } = localization;

    // Approved documents are readonly
    const isApproved = document.isCurrentDocumentApproved;

    return (
        <Root>
            {props.chapter.focusAreas.map((focusArea) => (
                <Box key={focusArea.id} my={6}>
                    <Typography
                        className={`${!isApproved && !isPreview && props.editing ? classes.pointer : null}`}
                        variant="h6"
                        onClick={(): void => handleChangeTitle(focusArea)}
                    >
                        {focusArea.mainTitle || ''}
                    </Typography>
                    <Box display="flex" alignItems="flex-start">
                        <Box flexGrow={1}>
                            <Typography
                                className={`${
                                    !isApproved && !isPreview && props.editing ? classes.pointer : null
                                } "fr-view`}
                                variant="subtitle2"
                                dangerouslySetInnerHTML={{
                                    __html: focusArea.title || translate('FOCUS_AREA_TITLE_EMPTY'),
                                }}
                                onClick={(): void => handleChangeTitle(focusArea)}
                            />
                        </Box>
                        {!isApproved && !isPreview && props.editing && (
                            <ButtonGroup color="primary" size="small">
                                <Button onClick={(): void => handleChangeTitle(focusArea)}>
                                    <Edit />
                                </Button>
                                <Button onClick={(): Promise<void> => removeFocusArea(focusArea.id)}>
                                    <Delete />
                                </Button>
                            </ButtonGroup>
                        )}
                    </Box>
                    <FocusAreaTable focusArea={focusArea} editing={props.editing} />
                    {!isApproved && !isPreview && props.editing && (
                        <Box display="flex">
                            <Box flexGrow={1} />
                            <Button
                                variant="outlined"
                                onClick={(): void =>
                                    dialog.openDocumentDialog('addFocusAreaGoalDialog', {
                                        focusArea: focusArea,
                                    })
                                }
                                sx={{ mt: 2 }}
                            >
                                {translate('ADD_GOAL')}
                            </Button>
                        </Box>
                    )}
                </Box>
            ))}
            {!isApproved && !isPreview && props.editing && (
                <Box display="flex" alignItems="center" my={2}>
                    <ShvkSecondaryButton
                        onClick={(): void =>
                            dialog.openDocumentDialog('addFocusAreaDialog', {
                                chapter: props.chapter,
                            })
                        }
                    >
                        {translate('ADD_FOCUS_AREA')}
                    </ShvkSecondaryButton>
                    <Tooltip sx={{ ml: 1 }} title={translate('hv_suunnitelma_lisaa_painopistealue_9')}>
                        <Info color="primary" />
                    </Tooltip>
                </Box>
            )}
            <EditFocusAreaDialog
                isOpen={state.open}
                close={() => setState((state) => ({ ...state, open: false }))}
                focusArea={state.focusArea}
            />
        </Root>
    );
}

export default observer(FocusAreas);
