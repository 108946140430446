import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Alert, AlertColor, AlertTitle, Collapse, Paper } from '@mui/material';
import { NotificationBoxData } from '../types/user';
import useStore from '../store/storeContext';

interface Props {
    notificationBoxData: NotificationBoxData;
    currentField?: string;
    marginTop?: number;
    marginBottom?: number;
}

interface State {
    severity: AlertColor | undefined;
    alertTitle: string;
    alertBody: string;
}

/**
 * Notification box usage:
 * 1. Prop 'notificationBoxData' is required. First, provide the 'fieldFocus' as a string, then provide the 'fieldData'.
 * 2. If multiple fields are connected (e.g. password fields) provide an object containing data for each field.
 * 3. To render only one box at a time, provide the 'currentField'. Only the box for focused field will be shown.
 */
function ShvkNotificationBox(props: Props) {
    const { localization, user } = useStore();

    const [state, setState] = useState<State>({
        severity: undefined,
        alertTitle: '',
        alertBody: '',
    });

    useEffect(() => {
        setNotificationData();
        return () => {
            setState({
                severity: undefined,
                alertTitle: '',
                alertBody: '',
            });
        };
    }, [props.notificationBoxData, props.currentField]);

    function setNotificationData(): void {
        const { fieldFocus, fieldData, passwordData, nameData } = props.notificationBoxData;
        const { translate } = localization;

        setState((state) => ({ ...state, alertTitle: translate('NOTICE') }));

        // Add a new case here if logic for the field is not found in the existing options.
        switch (fieldFocus) {
            case 'firstName':
            case 'lastName': {
                const severity = !nameData?.firstName || !nameData?.lastName ? 'info' : undefined;
                setState((state) => ({
                    ...state,
                    alertBody: severity === 'info' ? translate('NAME_FORMAT_TIP') : '',
                    severity,
                }));
                break;
            }

            case 'userName': {
                const severity = !user.validateUserName(fieldData || '') ? 'info' : undefined;
                setState((state) => ({
                    ...state,
                    alertBody: severity === 'info' ? translate('USERNAME_FORMAT_TIP') : '',
                    severity,
                }));
                break;
            }

            case 'password1':
            case 'password2': {
                const password1 = passwordData?.password1 || '';
                const password2 = passwordData?.password2 || '';
                setState((state) => ({ ...state, severity: 'info' }));

                // This handles the first password field.
                if (!user.validatePassword(password1)) {
                    const alertBody =
                        password2 && !user.checkPasswordMatch(password1, password2)
                            ? translate('PASSWORD_FORMAT_TIP') + '\n\n' + translate('PASSWORD_MISMATCH')
                            : translate('PASSWORD_FORMAT_TIP');
                    setState((state) => ({ ...state, alertBody }));
                } else if (!user.checkPasswordMatch(password1, password2)) {
                    setState((state) => ({ ...state, alertBody: translate('PASSWORD_MISMATCH') }));
                }

                // This handles the second password field.
                if (!user.checkPasswordMatch(password1, password2)) {
                    const alertBody = !user.validatePassword(password1)
                        ? translate('PASSWORD_FORMAT_TIP') + '\n\n' + translate('PASSWORD_MISMATCH')
                        : translate('PASSWORD_MISMATCH');
                    setState((state) => ({ ...state, alertBody }));
                } else if (!user.validatePassword(password1)) {
                    setState((state) => ({ ...state, alertBody: translate('PASSWORD_FORMAT_TIP') }));
                }

                // Hide notification box if passwords are formatted correctly.
                if (user.checkPasswordMatch(password1, password2) && user.validatePassword(password1)) {
                    setState((state) => ({ ...state, severity: undefined }));
                }
                break;
            }

            case 'organization': {
                const severity = !fieldData ? 'info' : undefined;
                setState((state) => ({
                    ...state,
                    alertBody: severity === 'info' ? translate('USER_MANAGEMENT_FIND_ORGANIZATION_TIP') : '',
                    severity,
                }));
                break;
            }

            default:
                setState((state) => ({ ...state, severity: undefined }));
                break;
        }
    }

    const { fieldFocus } = props.notificationBoxData;

    return (
        <Collapse
            //If the severity or fieldFocus prop is set to undefined, collapsed alerts will get hidden.
            in={fieldFocus === props.currentField && Boolean(state.severity)}
        >
            <Paper variant={'elevation'} sx={{ mt: props.marginTop ?? 0, mb: props.marginBottom ?? 0 }}>
                <Alert
                    variant={'standard'}
                    severity={state.severity ? state.severity : 'info'}
                    color={state.severity ? state.severity : 'info'}
                    sx={state.alertBody ? { whiteSpace: 'pre-line' } : { pt: 1.5 }}
                >
                    <AlertTitle>{state.alertTitle}</AlertTitle>
                    {state.alertBody}
                </Alert>
            </Paper>
        </Collapse>
    );
}

export default observer(ShvkNotificationBox);
