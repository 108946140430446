import React, { useEffect, useState } from 'react';
import { observer, useLocalObservable } from 'mobx-react-lite';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Table,
    TableBody,
    Typography,
    Pagination,
    Autocomplete,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { DocumentHistoryTitle } from '../../../types/document';
import HistoryDialogRow from './HistoryDialogRow';
import useStore from '../../../store/storeContext';
import ShvkTextField from '../../../styled/ShvkTextField';

const PREFIX = 'HistoryDialog';

const classes = {
    paper: `${PREFIX}-paper`,
};

const StyledDialog = styled(Dialog)({
    [`& .${classes.paper}`]: {
        minHeight: '450px',
    },
});

interface Props {
    isOpen: boolean;
    close(): void;
}

interface StoreState {
    page: number;
    historyDates: string[];
    historyTitles: DocumentHistoryTitle[];
    pageCount: number;
    setPage: (page: number) => void;
}

const itemsPerPage: Readonly<number> = 5;

function HistoryDialog(props: Props) {
    const { document, localization, snackbar, loadingIndicator } = useStore();

    const [date, setDate] = useState<string>('');

    const localStore = useLocalObservable<StoreState>(() => ({
        page: 1,

        get historyDates(): string[] {
            return document.documentHistoryDates.map((date) => parseDateStringForHistoryDates(date));
        },

        get historyTitles(): DocumentHistoryTitle[] {
            const pageFirstIndex = (this.page - 1) * itemsPerPage;
            return document.documentHistoryTitles.slice(pageFirstIndex, pageFirstIndex + itemsPerPage);
        },

        get pageCount(): number {
            return Math.ceil(document.documentHistoryTitles.length / itemsPerPage);
        },

        setPage(page: number) {
            this.page = page;
        },
    }));

    useEffect(() => {
        props.isOpen && void getData();
        return () => localStore.setPage(1);
    }, [props.isOpen]);

    async function getData(): Promise<void> {
        try {
            loadingIndicator.show();
            await document.fetchHistoryDates();
            if (document.documentHistoryDates.length > 0) {
                setDate(parseDateStringForHistoryDates(document.documentHistoryDates[0]));
                await document.fetchHistoryTitles(document.documentHistoryDates[0]);
            } else {
                setDate('');
            }
        } catch (e) {
            snackbar.showFetchFailedMessage(e.data?.code);
        } finally {
            loadingIndicator.hide();
        }
    }

    function parseDateStringForFetchHistoryTitles(date: string) {
        const dayMonthYearArray = date.split('.');
        const day = dayMonthYearArray[0];
        const month = dayMonthYearArray[1];
        const year = dayMonthYearArray[2];
        return `${year}-${month}-${day}`;
    }

    function parseDateStringForHistoryDates(date: string) {
        const yearMonthDayArray = date.split('-');
        const day = yearMonthDayArray[2];
        const month = yearMonthDayArray[1];
        const year = yearMonthDayArray[0];
        return `${day}.${month}.${year}`;
    }

    function handlePageChange(_event: React.ChangeEvent<unknown>, value: number): void {
        localStore.setPage(value);
    }

    const { translate } = localization;

    return (
        <StyledDialog
            open={props.isOpen}
            onClose={props.close}
            aria-labelledby="document-history-dialog-title"
            maxWidth={'sm'}
            fullWidth
            classes={{
                paper: classes.paper,
            }}
        >
            <DialogTitle id="document-history-dialog-title">{translate('EDITING_HISTORY')}</DialogTitle>
            <DialogContent dividers>
                <Typography sx={{ mb: 2 }}>{translate('EDITING_HISTORY_SUBTITLE')}</Typography>
                <Autocomplete
                    disablePortal
                    id="history-dates"
                    options={localStore.historyDates}
                    value={date}
                    disableClearable
                    onChange={async (_event, newValue) => {
                        setDate(newValue);
                        localStore.setPage(1);
                        const date = parseDateStringForFetchHistoryTitles(newValue);
                        try {
                            await document.fetchHistoryTitles(date);
                        } catch (e) {
                            snackbar.showFetchFailedMessage(e.data?.code);
                        }
                    }}
                    sx={{ width: 300 }}
                    renderInput={(params) => <ShvkTextField {...params} label={translate('EDITING_DATE')} />}
                />
                <Table>
                    <TableBody>
                        {localStore.historyTitles.map((historyTitle) => (
                            <HistoryDialogRow historyTitle={historyTitle} key={historyTitle.id} />
                        ))}
                    </TableBody>
                </Table>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={props.close}>
                    {translate('CANCEL')}
                </Button>
                <Box flexGrow={1} />
                <Pagination
                    count={localStore.pageCount}
                    page={localStore.page}
                    onChange={handlePageChange}
                    color="primary"
                />
            </DialogActions>
        </StyledDialog>
    );
}

export default observer(HistoryDialog);
