import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Box, Button, Divider, List, ListItem, ListItemText, Paper, Typography } from '@mui/material';
import { DescriptionOutlined } from '@mui/icons-material';
import { PublishedDocument } from '../../types/document';
import ApprovedDocumentsDialog from './ApprovedDocumentsDialog';
import moment from 'moment';
import useStore from '../../store/storeContext';

interface State {
    dialogOpen: boolean;
}

function LatestApprovedDocuments() {
    const { document, loadingIndicator, snackbar, localization } = useStore();

    const navigate = useNavigate();

    const [state, setState] = useState<State>({
        dialogOpen: false,
    });

    const handleDialog = (): void => {
        setState((state) => ({ ...state, dialogOpen: !state.dialogOpen }));
    };

    async function handleClick(id: number): Promise<void> {
        try {
            loadingIndicator.show();
            await document.loadPublishedDocumentById(id);
        } catch (error) {
            snackbar.showError(error.data?.code);
        } finally {
            navigate('/preview/' + id);
            loadingIndicator.hide();
        }
    }

    const { translate, locale } = localization;
    const { latestPublishedDocuments } = document;

    return (
        <>
            <Paper>
                <Box px={2} py={1}>
                    <Typography variant="h6">{translate('APPROVED_FUNCTION')}</Typography>
                </Box>
                <Divider />
                <List>
                    {latestPublishedDocuments.slice(0, 5).map((doc: PublishedDocument) => (
                        <React.Fragment key={doc.id}>
                            <ListItem onClick={(): Promise<void> => handleClick(doc.id)}>
                                <ListItemText
                                    primary={
                                        <Typography variant="body2" component="span">
                                            {moment(doc.acceptedDate).format('DD.MM.YYYY hh:mm') +
                                                ' / ' +
                                                doc.organization?.name[locale]}
                                        </Typography>
                                    }
                                    secondary={
                                        <Button style={{ cursor: 'pointer' }} component="span">
                                            <DescriptionOutlined style={{ verticalAlign: 'bottom' }} color="primary" />
                                            {doc.name}
                                        </Button>
                                    }
                                />
                            </ListItem>
                            <Divider variant="middle" light={true} component="li" />
                        </React.Fragment>
                    ))}
                </List>
                <Box p={2}>
                    <Typography>
                        <Button onClick={handleDialog}>{translate('PREVIOUS_DOCUMENTS')}</Button>
                    </Typography>
                </Box>
            </Paper>
            <ApprovedDocumentsDialog
                open={state.dialogOpen}
                onClose={handleDialog}
                documents={latestPublishedDocuments}
            />
        </>
    );
}

export default observer(LatestApprovedDocuments);
