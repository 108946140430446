import React from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { PublishedDocument } from '../../types/document';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography,
} from '@mui/material';
import { DescriptionOutlined } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import useStore from '../../store/storeContext';

const PREFIX = 'PublishedOrganizationDocumentsDialog';

const classes = {
    cell: `${PREFIX}-cell`,
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${classes.cell}`]: {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
}));

interface Props {
    open: boolean;
    onClose(): void;
}

function PublishedOrganizationDocumentsDialog(props: Props) {
    const { document, loadingIndicator, dialog, snackbar, localization } = useStore();

    const navigate = useNavigate();

    async function handleClick(id: number): Promise<void> {
        try {
            loadingIndicator.show();
            dialog.closePublishedOrganizationDocumentsDialog();
            await document.loadPublishedDocumentById(id);
        } catch (error) {
            snackbar.showError(error.data?.code);
        } finally {
            navigate('/preview/' + id);
            loadingIndicator.hide();
        }
    }

    const { open, onClose } = props;
    const { translate } = localization;

    return (
        <Dialog
            open={open}
            aria-labelledby="organization-approved-documents-dialog"
            maxWidth={'sm'}
            fullWidth
            onClose={onClose}
        >
            <DialogTitle id="organization-approved-documents-dialog-title">
                {translate('DOCUMENTS_TITLE') + ' - ' + dialog.getSelectedOrganization}
            </DialogTitle>
            <DialogContent dividers>
                <TableContainer>
                    <Table sx={{ tableLayout: 'fixed' }} aria-label="documents-table">
                        <TableBody>
                            {dialog.getSelectedOrganization
                                ? dialog.getFilteredDocuments.map((document: PublishedDocument) => (
                                      <TableRow key={document.id}>
                                          <StyledTableCell className={classes.cell}>
                                              <Button
                                                  component="span"
                                                  onClick={async (): Promise<void> => handleClick(document.id)}
                                              >
                                                  <DescriptionOutlined
                                                      style={{ verticalAlign: 'bottom' }}
                                                      color="primary"
                                                  />
                                                  <Typography style={{ whiteSpace: 'normal' }}>
                                                      {document.name}
                                                  </Typography>
                                              </Button>
                                          </StyledTableCell>
                                      </TableRow>
                                  ))
                                : null}
                        </TableBody>
                    </Table>
                </TableContainer>
            </DialogContent>
            <DialogActions>
                <Box flexGrow={1} />
                <Button onClick={onClose} variant="contained">
                    {translate('CLOSE')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default observer(PublishedOrganizationDocumentsDialog);
