import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import {
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Grid,
    InputAdornment,
    MenuItem,
    Tab,
    Tabs,
} from '@mui/material';
import { FormatIndentIncrease, FormatListNumbered, InsertDriveFileOutlined, Language } from '@mui/icons-material';
import { TabContext, TabPanel } from '@mui/lab';
import { TranslationKeys } from '../../types/translation';
import { TextFieldEvent } from '../../types/events';
import { Locale, LocalizedName } from '../../types/localization';
import ChapterTemplate, { ChapterTemplateOptions } from '../../types/chapterTemplate';
import ShvkButton from '../../styled/ShvkButton';
import DangerButton from '../../styled/DangerButton';
import ShvkTextField from '../../styled/ShvkTextField';
import useStore from '../../store/storeContext';

interface Props {
    open: boolean;
    onClose(): void;
    chapterTemplate: ChapterTemplate | null;
}

type TabName = 'BASIC_INFO' | 'OPTIONS';

type ChapterTemplateField =
    | 'language'
    | 'docType'
    | 'localizedTitles'
    | 'tipKey'
    | 'localizedTips'
    | 'orderNumber'
    | 'level'
    | 'sid';

interface State {
    activeTab: TabName;
    language: string;
    docType: string;
    orderNumber: string;
    level: string;
    sid: string;
    isFirstRender: boolean;
}

const levels: Readonly<number[]> = [0, 1, 2, 3];

function AddChapterTemplatesDialog(props: Props) {
    const { localization, chapterTemplate, snackbar, dialog } = useStore();

    const [state, setState] = useState<State>({
        activeTab: 'BASIC_INFO',
        language: '',
        docType: '',
        orderNumber: '',
        level: '',
        sid: '',
        isFirstRender: true,
    });

    const [localizedTitles, setLocalizedTitles] = useState<LocalizedName>({} as LocalizedName);
    const [localizedTips, setLocalizedTips] = useState<LocalizedName>({} as LocalizedName);
    const [options, setOptions] = useState<ChapterTemplateOptions>({} as ChapterTemplateOptions);

    const tipIsDefinedForAnyLanguage = (): boolean => {
        // Return true if tip is defined for at least one language.
        return Object.entries(localizedTips).some(([key, value]) => {
            if (key === 'key') return false;
            return value;
        });
    };

    useEffect(() => {
        props.open && init();
        return () => {
            setState((state) => ({ ...state, activeTab: 'BASIC_INFO' }));
        };
    }, [props.open]);

    useEffect(() => {
        // Skip sid generation on first render.
        if (!state.isFirstRender) {
            setState((state) => ({ ...state, sid: generateSID() }));
        } else setState((state) => ({ ...state, isFirstRender: false }));
    }, [state.docType, state.orderNumber]);

    function init(): void {
        const locale = localization.locale;

        setState({
            activeTab: 'BASIC_INFO',
            language: locale,
            docType: props.chapterTemplate?.type || '',
            orderNumber: props.chapterTemplate?.order.toString() || '1',
            level: props.chapterTemplate?.level.toString() || '0',
            sid: props.chapterTemplate?.sid || '',
            isFirstRender: true,
        });

        setLocalizedTitles(props.chapterTemplate?.title || ({} as LocalizedName));
        setLocalizedTips(props.chapterTemplate?.tip || ({} as LocalizedName));
        setOptions(props.chapterTemplate?.options || createDefaultOptions());
    }

    async function save(): Promise<void> {
        try {
            const template: ChapterTemplate = {
                id: -1,
                language: state.language as Locale,
                level: Number(state.level),
                options: options,
                order: Number(state.orderNumber),
                sid: state.sid,
                tip: localizedTips,
                title: localizedTitles,
                type: state.docType,
            };

            await chapterTemplate.saveChapterTemplate(template);
            snackbar.showSuccess();
        } catch (error) {
            snackbar.showError(error.data?.code);
        } finally {
            props.onClose();
        }
    }

    async function update(): Promise<void> {
        try {
            if (!props.chapterTemplate) return;
            const template: ChapterTemplate = {
                id: props.chapterTemplate.id,
                type: state.docType,
                language: state.language as Locale,
                order: Number(state.orderNumber),
                level: Number(state.level),
                sid: state.sid,
                tip: localizedTips,
                title: localizedTitles,
                options: options,
            };

            await chapterTemplate.updateChapterTemplate(template);
            snackbar.showSuccess();
        } catch (error) {
            snackbar.showError(error.data?.code);
        } finally {
            props.onClose();
        }
    }

    async function remove(): Promise<void> {
        try {
            const confirmation = await dialog.getConfirmation();
            if (!confirmation) return;
            props.chapterTemplate && (await chapterTemplate.deleteChapterTemplate(props.chapterTemplate.id));
            snackbar.showSuccess();
        } catch (error) {
            snackbar.showError(error.data?.code);
        }
        props.onClose();
    }

    function handleChange(event: TextFieldEvent, field: ChapterTemplateField): void {
        switch (field) {
            case 'localizedTitles':
                setLocalizedTitles((prevState) => ({ ...prevState, [state.language as Locale]: event.target.value }));
                break;
            case 'localizedTips':
                setLocalizedTips((prevState) => ({ ...prevState, [state.language as Locale]: event.target.value }));
                break;
            case 'tipKey':
                setLocalizedTips((state) => ({ ...state, key: event.target.value }));
                break;
            case 'orderNumber':
                setState((state) => ({ ...state, [field]: event.target.value }));
                if (Number(event.target.value) < 1) {
                    setState((state) => ({ ...state, [field]: '1' }));
                }
                break;
            case 'docType':
            case 'level':
                setState((state) => ({ ...state, [field]: event.target.value }));
                break;
            default:
                setState((state) => ({ ...state, [field]: event.target.value }));
                break;
        }
    }

    const saveOnClick = (): void => {
        const sidExists = chapterTemplate.chapterTemplates.some((template) => template.sid === state.sid);
        if ((sidExists && !props.chapterTemplate) || (sidExists && props.chapterTemplate?.sid !== state.sid)) {
            snackbar.showError('SID_ALREADY_EXISTS');
            return;
        }

        if (props.chapterTemplate) {
            void update();
        } else void save();
    };

    const createDefaultOptions = (): ChapterTemplateOptions => {
        return {
            UI_FOCUS_AREA: false,
            REMOVABLE: false,
            UI_APPROVED_AREA: false,
            UI_INDICATOR_AREA: false,
            EDITABLE: false,
            TITLE_EDITABLE: false,
            VISIBLE: false,
            PRINTABLE: false,
            PUBLISHABLE: false,
            UI_OPTIONS_AREA: false,
            UI_EVA_PERSPECTIVE_AREA: false,
            UI_EVA_DECISION_OPTION_AREA: false,
            SUMMARY: false,
            COMPARE_TO_COUNTIES_MAIN_AREA: false,
            COMPARE_TO_COUNTIES_SUB_AREA: false,
            COMPARE_TO_WELLBEING_COUNTY_AREA: false,
        };
    };
    const generateSID = (): string => {
        let sid = '';
        switch (state.docType) {
            case 'EVA':
                sid = 'EVA_CHP_';
                break;
            case 'EVA_MINI':
                sid = 'EVAM_CHP_';
                break;
            case 'LARGE_REPORT':
            case 'REGIONAL_LARGE_REPORT':
                sid = 'LR_CHP';
                break;
            case 'YEARLY_REPORT':
            case 'REGIONAL_YEARLY_REPORT':
            case 'ANOTHER_REPORT':
                sid = 'YR_CHP';
                break;
        }
        sid += state.orderNumber;
        if (state.docType.includes('REGIONAL')) {
            sid += '_REGION';
        } else if (state.docType.includes('ANOTHER')) {
            sid += '_ANOTHER';
        }
        return sid;
    };

    const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setOptions((options) => ({ ...options, [event.target.name]: event.target.checked }));
    };

    const handleTabChange = (_event: React.ChangeEvent<unknown>, newIndex: TabName): void => {
        setState((state) => ({ ...state, activeTab: newIndex }));
    };

    const isSaveButtonDisabled = (): boolean => {
        return (
            !state.sid ||
            !state.docType ||
            !state.language ||
            !state.orderNumber ||
            !state.level ||
            (tipIsDefinedForAnyLanguage() && !localizedTips.key)
        );
    };

    const { translate } = localization;

    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            maxWidth={'sm'}
            fullWidth
            aria-labelledby="chapter-template-dialog-title"
            sx={{ '& .MuiPaper-root': { minHeight: '640px' } }}
        >
            <DialogTitle id="chapter-template-dialog-title" sx={{ pb: 0 }}>
                {translate('CHAPTER_TEMPLATE')}
                <Tabs
                    value={state.activeTab}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={handleTabChange}
                    aria-labelledby="chapter-template-dialog-title"
                >
                    <Tab label={translate('BASIC_INFO')} value="BASIC_INFO" />
                    <Tab label={translate('OPTIONS')} value="OPTIONS" />
                </Tabs>
            </DialogTitle>
            <DialogContent dividers>
                <TabContext value={state.activeTab}>
                    <TabPanel value={'BASIC_INFO'}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <ShvkTextField
                                    required
                                    select
                                    id="chapter-language"
                                    value={state.language}
                                    onChange={(event): void => handleChange(event, 'language')}
                                    label={translate('LANGUAGE')}
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Language />
                                            </InputAdornment>
                                        ),
                                    }}
                                >
                                    {localization.languages.map((language) => (
                                        <MenuItem key={language.id} value={language.locale}>
                                            {language.name}
                                        </MenuItem>
                                    ))}
                                </ShvkTextField>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <ShvkTextField
                                    required
                                    select
                                    id="chapter-document-type"
                                    value={state.docType}
                                    onChange={(event): void => handleChange(event, 'docType')}
                                    label={translate('DOCUMENT_TYPE')}
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <InsertDriveFileOutlined />
                                            </InputAdornment>
                                        ),
                                    }}
                                >
                                    {chapterTemplate.documentTypes.map((docType) => (
                                        <MenuItem key={docType.id} value={docType.code}>
                                            {translate(docType.code as keyof TranslationKeys)}
                                        </MenuItem>
                                    ))}
                                </ShvkTextField>
                            </Grid>
                            <Grid item xs={12}>
                                <ShvkTextField
                                    id="chapter-title"
                                    label={translate('TITLE_PLACEHOLDER')}
                                    multiline
                                    rows={2}
                                    value={localizedTitles[state.language as Locale] || ''}
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    onChange={(event): void => handleChange(event, 'localizedTitles')}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <ShvkTextField
                                    required={tipIsDefinedForAnyLanguage()}
                                    id="chapter-tip-key"
                                    label={translate('TIP_KEY')}
                                    value={localizedTips.key || ''}
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    onChange={(event): void => handleChange(event, 'tipKey')}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <ShvkTextField
                                    id="chapter-tip-text"
                                    multiline
                                    rows={4}
                                    label={translate('TIP_TEXT')}
                                    value={localizedTips[state.language as Locale] || ''}
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    onChange={(event): void => handleChange(event, 'localizedTips')}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <ShvkTextField
                                    required
                                    id="chapter-order-number"
                                    label={translate('ORDER_NUMBER')}
                                    type="number"
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    onChange={(event): void => handleChange(event, 'orderNumber')}
                                    value={state.orderNumber}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <FormatListNumbered />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <ShvkTextField
                                    required
                                    select
                                    id="chapter-level"
                                    value={state.level}
                                    onChange={(event): void => handleChange(event, 'level')}
                                    label={translate('LEVEL_NUMBER')}
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <FormatIndentIncrease />
                                            </InputAdornment>
                                        ),
                                    }}
                                >
                                    {levels.map((level) => (
                                        <MenuItem key={level} value={level.toString()}>
                                            {level}
                                        </MenuItem>
                                    ))}
                                </ShvkTextField>
                            </Grid>
                            <Grid item xs={12}>
                                <ShvkTextField
                                    required
                                    id="chapter-sid"
                                    label="SID"
                                    value={state.sid}
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    onChange={(event): void => handleChange(event, 'sid')}
                                />
                            </Grid>
                        </Grid>
                    </TabPanel>
                    <TabPanel value={'OPTIONS'}>
                        <Grid container>
                            {Object.entries(options).map(([option, value]) => (
                                <Grid item xs={12} sm={6} key={option}>
                                    <FormControlLabel
                                        label={translate(option as keyof TranslationKeys)}
                                        control={
                                            <Checkbox
                                                checked={value}
                                                onChange={handleOptionChange}
                                                name={option}
                                                color="primary"
                                            />
                                        }
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </TabPanel>
                </TabContext>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose} variant="contained">
                    {translate('CANCEL')}
                </Button>
                {props.chapterTemplate && (
                    <DangerButton variant="contained" onClick={remove}>
                        {translate('REMOVE')}
                    </DangerButton>
                )}
                <Box flexGrow={1} />
                <ShvkButton onClick={saveOnClick} disabled={isSaveButtonDisabled()}>
                    {translate('SAVE')}
                </ShvkButton>
            </DialogActions>
        </Dialog>
    );
}

export default observer(AddChapterTemplatesDialog);
