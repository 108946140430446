export const sortByName = (a: string, b: string): number => {
    if (a < b) return -1;
    if (b < a) return 1;
    return 0;
};

// This function takes an array and finds an object by searchCriteria and moves it to the beginning of the array.
// It can also handle an array of objects, just provide objectPropertyToSearch to match with searchCriteria.
export function moveToIndexZero<T, T2>(initialArray: T[], searchCriteria: T2, objectPropertyToSearch?: keyof T): T[] {
    let foundObject: T | undefined;
    let filteredArray: T[] = [];
    if (objectPropertyToSearch) {
        foundObject = initialArray.find((item: any) => item[objectPropertyToSearch] === searchCriteria);
        filteredArray = initialArray.filter((item: any) => item[objectPropertyToSearch] !== searchCriteria);
    } else {
        foundObject = initialArray.find((item: any) => item === searchCriteria);
        filteredArray = initialArray.filter((item: any) => item !== searchCriteria);
    }
    if (foundObject && filteredArray.length > 0) {
        return [foundObject, ...filteredArray];
    }
    return initialArray;
}

// Shortens the given string to a specified length and appends '...' to the end.
export function truncateWithEllipsis(str: string, maxLength: number): string {
    const words = str.split(' ');

    let letterCount = 0;
    let content = '';

    for (let i = 0; i < words.length; i++) {
        const word = words[i];
        const wordLength = word.length + 1;

        if (letterCount + wordLength <= maxLength) {
            letterCount += wordLength;
            content += word + ' ';
        } else {
            break;
        }
    }

    return content.trim() + '...';
}
