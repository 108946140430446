import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import {
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Grid,
} from '@mui/material';
import { TextFieldEvent } from '../../types/events';
import LicenseSchema from '../../types/license';
import DangerButton from '../../styled/DangerButton';
import ShvkButton from '../../styled/ShvkButton';
import ShvkTextField from '../../styled/ShvkTextField';
import useStore from '../../store/storeContext';

interface Props {
    open: boolean;
    onClose(): void;
    licenseItem: LicenseSchema | null;
}

interface State {
    description: string;
    lrAmount: number;
    yrAmount: number;
    evaAmount: number;
    evaMiniAmount: number;
    languageSupport: boolean;
}

type LicenseDialogFormField = 'description' | 'lrAmount' | 'yrAmount' | 'evaAmount' | 'evaMiniAmount';

function LicenseItemDialog(props: Props) {
    const { dialog, admin, snackbar, localization } = useStore();

    const [state, setState] = useState<State>({
        description: '',
        lrAmount: 0,
        yrAmount: 0,
        evaAmount: 0,
        evaMiniAmount: 0,
        languageSupport: false,
    });

    useEffect((): void => {
        init();
    }, [props.open]);

    const init = (): void => {
        setState({
            description: props.licenseItem?.description || '',
            evaAmount: props.licenseItem?.eva || 0,
            evaMiniAmount: props.licenseItem?.evaMini || 0,
            lrAmount: props.licenseItem?.largeReport || 0,
            yrAmount: props.licenseItem?.yearlyReport || 0,
            languageSupport: props.licenseItem?.languageSupport || false,
        });
    };

    const handleChange = (event: TextFieldEvent, field: LicenseDialogFormField): void => {
        if (field === 'description') {
            setState((state) => ({ ...state, [field]: event.target.value }));
        } else {
            const value = Number(event.target.value);
            setState((state) => ({ ...state, [field]: value >= 0 ? value : 0 }));
        }
    };

    async function save(): Promise<void> {
        try {
            const item: LicenseSchema = {
                id: props.licenseItem?.id || 0,
                description: state.description,
                eva: state.evaAmount,
                evaMini: state.evaMiniAmount,
                largeReport: state.lrAmount,
                yearlyReport: state.yrAmount,
                languageSupport: state.languageSupport,
            };

            await admin.saveLicenseSchema(item);
            snackbar.showSuccess();
        } catch (error) {
            snackbar.showError(error.data?.code);
        } finally {
            props.onClose();
        }
    }

    async function remove(): Promise<void> {
        try {
            const confirmation = await dialog.getConfirmation();
            if (!confirmation) return;
            await admin.removeLicenseSchema(props.licenseItem!.id);
            snackbar.showSuccess();
        } catch (error) {
            snackbar.showError(error.data?.code);
        }
        props.onClose();
    }

    const { translate } = localization;

    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            aria-labelledby="license-dialog-title"
            maxWidth={'sm'}
            fullWidth
        >
            <DialogTitle id="license-dialog-title">{translate('LICENSE_INFORMATION')}</DialogTitle>
            <DialogContent dividers>
                <Grid container spacing={2} direction="column" alignItems="stretch">
                    <Grid item>
                        <ShvkTextField
                            required
                            label={translate('DESCRIPTION')}
                            fullWidth
                            value={state.description}
                            variant="outlined"
                            size="small"
                            onChange={(event): void => handleChange(event, 'description')}
                        />
                    </Grid>
                    <Grid item>
                        <ShvkTextField
                            required
                            type="number"
                            label={translate('LICENSE_EVA_REPORT_AMOUNT')}
                            fullWidth
                            value={state.evaAmount}
                            variant="outlined"
                            size="small"
                            onChange={(event): void => handleChange(event, 'evaAmount')}
                        />
                    </Grid>
                    <Grid item>
                        <ShvkTextField
                            required
                            type="number"
                            label={translate('LICENSE_EVA_MINI_REPORT_AMOUNT')}
                            fullWidth
                            value={state.evaMiniAmount}
                            variant="outlined"
                            size="small"
                            onChange={(event): void => handleChange(event, 'evaMiniAmount')}
                        />
                    </Grid>
                    <Grid item>
                        <ShvkTextField
                            required
                            type="number"
                            label={translate('LICENSE_YEARLY_REPORT_AMOUNT')}
                            fullWidth
                            value={state.yrAmount}
                            variant="outlined"
                            size="small"
                            onChange={(event): void => handleChange(event, 'yrAmount')}
                        />
                    </Grid>
                    <Grid item>
                        <ShvkTextField
                            required
                            type="number"
                            label={translate('LICENSE_LARGE_REPORT_AMOUNT')}
                            fullWidth
                            value={state.lrAmount}
                            variant="outlined"
                            size="small"
                            onChange={(event): void => handleChange(event, 'lrAmount')}
                        />
                    </Grid>
                    <Grid item>
                        <FormControlLabel
                            label={translate('LICENSE_LANGUAGE_SUPPORT_OPTION')}
                            control={
                                <Checkbox
                                    checked={state.languageSupport}
                                    onChange={(event): void =>
                                        setState((state) => ({ ...state, languageSupport: event.target.checked }))
                                    }
                                    color="primary"
                                />
                            }
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose} variant="contained">
                    {translate('CANCEL')}
                </Button>
                {props.licenseItem && (
                    <DangerButton variant="contained" onClick={remove}>
                        {translate('REMOVE')}
                    </DangerButton>
                )}
                <Box flexGrow={1} />
                <ShvkButton onClick={save}>{translate('SAVE')}</ShvkButton>
            </DialogActions>
        </Dialog>
    );
}

export default observer(LicenseItemDialog);
