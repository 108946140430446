import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import FroalaEditorComponent from 'react-froala-wysiwyg';
import { Box, Button, Dialog, DialogActions, DialogContent, Tooltip, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { FocusArea } from '../../../../types/document';
import { TextFieldEvent } from '../../../../types/events';
import ShvkTextField from '../../../../styled/ShvkTextField';
import ShvkButton from '../../../../styled/ShvkButton';
import useStore from '../../../../store/storeContext';

const PREFIX = 'EditFocusAreaDialog';

const classes = {
    paper: `${PREFIX}-paper`,
};

const StyledDialog = styled(Dialog)(() => ({
    [`& .${classes.paper}`]: {
        height: 'auto',
    },
}));

interface Props {
    isOpen: boolean;
    close(): void;
    focusArea: FocusArea;
}

interface State {
    mainTitle: string;
    title: string;
}

function EditFocusAreaDialog(props: Props) {
    const { document, localization, loadingIndicator, snackbar } = useStore();

    const [state, setState] = useState<State>({
        mainTitle: '',
        title: '',
    });

    useEffect(() => {
        props.isOpen && init();
    }, [props.isOpen]);

    function init() {
        setState({
            mainTitle: props.focusArea.mainTitle || '',
            title: props.focusArea.title || '',
        });
    }

    const handleMainTitleChange = (event: TextFieldEvent): void => {
        setState((state) => ({
            ...state,
            mainTitle: event.target.value,
        }));
    };

    const handleTitleChange = (newValue: string): void => {
        setState((state) => ({
            ...state,
            title: newValue,
        }));
    };

    async function save(): Promise<void> {
        try {
            loadingIndicator.show();
            await document.saveFocusArea(props.focusArea.id, state.mainTitle, state.title);
            snackbar.showSuccess();
        } catch (error) {
            snackbar.showError(error.data?.code);
        } finally {
            props.close();
            loadingIndicator.hide();
        }
    }

    const { translate } = localization;

    return (
        <StyledDialog
            id="edit-focus-area-dialog"
            open={props.isOpen}
            onClose={props.close}
            classes={{ paper: classes.paper }}
            maxWidth="lg"
            fullWidth
        >
            <DialogContent>
                <Typography variant="h6">{translate('ADDITIONAL_FOCUS_AREA_TITLE')}</Typography>
                <ShvkTextField
                    size="small"
                    variant="outlined"
                    fullWidth
                    value={state.mainTitle}
                    onChange={(event: TextFieldEvent) => handleMainTitleChange(event)}
                />
                <Typography variant="h6" mt={2}>
                    {translate('ADD_FOCUS_AREA_MAIN_TITLE')}
                </Typography>
                <FroalaEditorComponent
                    tag="textarea"
                    model={state.title}
                    onModelChange={handleTitleChange}
                    config={{ ...document.froalaFocusAreaConfig }}
                />
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={props.close}>
                    {translate('CLOSE')}
                </Button>
                <Box flexGrow={1} />
                <Tooltip title={!state.title.length && translate('FOCUS_AREA_TITLE_EMPTY')} placement="top">
                    <span>
                        <ShvkButton onClick={save} disabled={!state.title.length}>
                            {translate('SAVE')}
                        </ShvkButton>
                    </span>
                </Tooltip>
            </DialogActions>
        </StyledDialog>
    );
}

export default observer(EditFocusAreaDialog);
