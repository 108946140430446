import React, { useState, useEffect, ChangeEvent } from 'react';
import { observer } from 'mobx-react-lite';
import {
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    FormLabel,
    IconButton,
    List,
    ListItemButton,
    ListItemSecondaryAction,
    ListItemText,
    Radio,
    RadioGroup,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Tabs,
    Tooltip,
} from '@mui/material';
import { TabContext, TabPanel } from '@mui/lab';
import { styled } from '@mui/material/styles';
import { Comment, Timeline } from '@mui/icons-material';
import { Chapter } from '../../../types/document';
import { IndicatorPackage } from '../../../types/indicator';
import IndicatorPrintOption from '../../../types/indicatorPrintOption';
import ShvkButton from '../../../styled/ShvkButton';
import useStore from '../../../store/storeContext';

const PREFIX = 'DownloadPdfOrWordDialog';

const classes = {
    paper: `${PREFIX}-paper`,
    dialogTitle: `${PREFIX}-dialogTitle`,
    tableCell: `${PREFIX}-tableCell`,
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
    [`& .${classes.paper}`]: {
        height: '90%',
    },

    [`& .${classes.dialogTitle}`]: {
        paddingBottom: 0,
    },

    [`& .${classes.tableCell}`]: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingTop: theme.spacing(0),
        paddingBottom: theme.spacing(0),
    },
}));

interface Props {
    isOpen: boolean;
    close(): void;
    type: 'pdf' | 'word';
}

interface State {
    activeTab: TabName;
    radioGroupValue: string;
    printChapterList: boolean;
    blackAndWhiteMode: boolean;
    indicatorDescription: boolean;
    chaptersToPrint: number[];
    indicatorsToPrint: IndicatorPrintOption[];
}

type TabName = 'CHAPTERS' | 'INDICATOR_PACKAGES';

function DownloadPdfOrWordDialog(props: Props) {
    const { document, indicator, localization, theming, loadingIndicator, snackbar } = useStore();

    const [state, setState] = useState<State>({
        activeTab: 'CHAPTERS',
        radioGroupValue: 'all',
        printChapterList: true,
        blackAndWhiteMode: false,
        indicatorDescription: false,
        chaptersToPrint: [],
        indicatorsToPrint: [],
    });

    useEffect(() => {
        props.isOpen && init();
        return () => {
            setState((state) => ({ ...state, activeTab: 'CHAPTERS' }));
        };
    }, [props.isOpen]);

    async function startDownload(): Promise<void> {
        const { currentDocument } = document;
        const indicatorsToPrint =
            document.isEvaDocument(currentDocument) ||
            !currentDocument.chapters.find((chapter) => chapter.isIndicatorArea)
                ? []
                : state.radioGroupValue === 'all'
                ? document.currentDocument.chapters
                      .find((chapter) => chapter.isIndicatorArea)!
                      .indicatorPackages.map((indicatorPackage) => {
                          return { id: indicatorPackage.id, printGraph: true, printNote: true };
                      })
                : state.indicatorsToPrint;
        const colorSchema = state.blackAndWhiteMode
            ? 'greyScale'
            : theming.getChosenPalette === 'defaultPalette'
            ? 'default'
            : 'blueThema';
        const indicatorGraphMode = indicator.getIndicatorViewMode;

        try {
            loadingIndicator.show();
            await document.downloadWordOrPdf(
                props.type,
                colorSchema,
                indicatorGraphMode,
                state.printChapterList,
                state.chaptersToPrint,
                state.indicatorDescription,
                indicatorsToPrint,
            );
            snackbar.showSuccess();
        } catch (error) {
            snackbar.showError(error.data?.code);
        } finally {
            props.close();
            loadingIndicator.hide();
        }
    }

    function init(): void {
        const { currentDocument } = document;

        const chaptersToPrint = currentDocument.chapters
            .filter((chapter) => !(chapter.isCompareToCountiesSubArea && chapter.text.length === 0))
            .map((chapter) => chapter.id);

        const indicatorsToPrint =
            document.isEvaDocument(currentDocument) ||
            !currentDocument.chapters.find((chapter) => chapter.isIndicatorArea)
                ? []
                : document.currentDocument.chapters
                      .find((chapter) => chapter.isIndicatorArea)!
                      .indicatorPackages.map((indicatorPackage) => {
                          return { id: indicatorPackage.id, printGraph: false, printNote: false };
                      });

        setState(() => ({
            activeTab: 'CHAPTERS',
            radioGroupValue: 'all',
            printChapterList: true,
            blackAndWhiteMode: false,
            indicatorDescription: false,
            chaptersToPrint,
            indicatorsToPrint,
        }));
    }

    function handleIndicatorToPrintSelection(indicatorPackageId: number, graphOrNote: string, value: boolean): void {
        const valueChanged = state.indicatorsToPrint.map((indicator) => {
            if (indicator.id === indicatorPackageId) {
                if (graphOrNote === 'graph') {
                    return { ...indicator, printGraph: value };
                }
                return { ...indicator, printNote: value };
            }
            return indicator;
        });
        setState((state) => ({ ...state, indicatorsToPrint: valueChanged }));
    }

    function toggleAll(graphOrNote: string): void {
        const valueChanged = state.indicatorsToPrint.map((indicator) => {
            if (graphOrNote === 'graph') {
                if (state.indicatorsToPrint.some((indicator) => !indicator.printGraph)) {
                    return { ...indicator, printGraph: true };
                } else {
                    return { ...indicator, printGraph: false };
                }
            } else {
                if (state.indicatorsToPrint.some((indicator) => !indicator.printNote)) {
                    return { ...indicator, printNote: true };
                } else {
                    return { ...indicator, printNote: false };
                }
            }
        });
        setState((state) => ({ ...state, indicatorsToPrint: valueChanged }));
    }

    const handleRadioGroupChange = (value: string): void => {
        setState((state) => ({
            ...state,
            radioGroupValue: value,
        }));
    };

    const handleTabChange = (_event: React.ChangeEvent<unknown>, newIndex: TabName): void => {
        setState((state) => ({
            ...state,
            activeTab: newIndex,
        }));
    };

    const toggleChapterListPrinting = (): void => {
        setState((state) => ({
            ...state,
            printChapterList: !state.printChapterList,
        }));
    };

    const toggleBlackAndWhiteMode = (): void => {
        setState((state) => ({
            ...state,
            blackAndWhiteMode: !state.blackAndWhiteMode,
        }));
    };

    const toggleIndicatorDescription = (): void => {
        setState((state) => ({
            ...state,
            indicatorDescription: !state.indicatorDescription,
        }));
    };

    const handleChapterChecking = (chapterId: number): void => {
        if (state.chaptersToPrint.includes(chapterId)) {
            const chaptersToPrintFiltered = state.chaptersToPrint.filter((c) => c !== chapterId);
            setState((state) => ({ ...state, chaptersToPrint: chaptersToPrintFiltered }));
        } else {
            setState((state) => ({ ...state, chaptersToPrint: [...state.chaptersToPrint, chapterId] }));
        }
    };

    function chaptersTab(): JSX.Element {
        const { translate } = localization;
        const { currentDocument } = document;

        return (
            <div>
                <FormControlLabel
                    label={translate('CHAPTER_LIST_PRINT_OPTION')}
                    control={
                        <Checkbox
                            color="primary"
                            checked={state.printChapterList}
                            onChange={toggleChapterListPrinting}
                        />
                    }
                />
                <List>
                    {currentDocument.chapters.map((chapter) => (
                        <ListItemButton key={chapter.id} dense onClick={(): void => handleChapterChecking(chapter.id)}>
                            <ListItemText primary={`${chapter.chapterNumberString} ${chapter.title}`} />
                            <ListItemSecondaryAction>
                                <Checkbox
                                    edge="end"
                                    color="primary"
                                    checked={state.chaptersToPrint.includes(chapter.id)}
                                    onChange={(): void => handleChapterChecking(chapter.id)}
                                />
                            </ListItemSecondaryAction>
                        </ListItemButton>
                    ))}
                </List>
            </div>
        );
    }

    function indicatorPackagesTab(): JSX.Element {
        const { translate } = localization;
        const indicatorPackages =
            document.currentDocument.chapters.find((chapter: Chapter) => chapter.isIndicatorArea)?.indicatorPackages ||
            [];

        return (
            <div>
                <Box mt={2}>
                    <FormControlLabel
                        label={translate('BLACK_AND_WHITE_PRINT_OPTION')}
                        control={
                            <Checkbox
                                checked={state.blackAndWhiteMode}
                                onChange={toggleBlackAndWhiteMode}
                                color="primary"
                            />
                        }
                    />
                </Box>
                <Box mb={1}>
                    <FormControlLabel
                        label={translate('PRINT_INDICATOR_DESCRIPTION')}
                        control={
                            <Checkbox
                                color="primary"
                                checked={state.indicatorDescription}
                                onChange={toggleIndicatorDescription}
                            />
                        }
                    />
                </Box>
                <FormLabel id="indicator-radio-group-label" component="legend">
                    {translate('INDICATOR_PACKAGES_OPTIONS_TITLE')}
                </FormLabel>
                <RadioGroup
                    aria-labelledby="indicator-radio-group-label"
                    value={state.radioGroupValue}
                    onChange={(_event: ChangeEvent, value: string) => handleRadioGroupChange(value)}
                >
                    <FormControlLabel value="all" control={<Radio />} label={translate('ALL_INDICATOR_PACKAGES')} />
                    <FormControlLabel
                        value="select"
                        control={<Radio />}
                        label={translate('SELECT_INDICATOR_PACKAGES_OPTION')}
                    />
                </RadioGroup>
                {state.radioGroupValue === 'select' && (
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell className={classes.tableCell}>{translate('NAME')}</TableCell>
                                <TableCell className={classes.tableCell}>
                                    <IconButton onClick={() => toggleAll('graph')} size="large">
                                        <Tooltip title={translate('SELECT_ALL_GRAPHS_TOOLTIP')}>
                                            <Timeline />
                                        </Tooltip>
                                    </IconButton>
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                    <IconButton onClick={() => toggleAll('note')} size="large">
                                        <Tooltip title={translate('SELECT_ALL_INDICATORS_NOTES')}>
                                            <Comment />
                                        </Tooltip>
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {indicatorPackages!.map((indicatorPackage: IndicatorPackage) => (
                                <TableRow key={indicatorPackage.id}>
                                    <TableCell className={classes.tableCell}>{indicatorPackage.name}</TableCell>
                                    <TableCell className={classes.tableCell}>
                                        <Checkbox
                                            color="primary"
                                            checked={
                                                state.indicatorsToPrint.find(
                                                    (indicator) => indicator.id === indicatorPackage.id,
                                                )?.printGraph
                                            }
                                            onChange={(_event: ChangeEvent, value: boolean) =>
                                                handleIndicatorToPrintSelection(indicatorPackage.id, 'graph', value)
                                            }
                                        />
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                        <Checkbox
                                            color="primary"
                                            checked={
                                                state.indicatorsToPrint.find(
                                                    (indicator) => indicator.id === indicatorPackage.id,
                                                )?.printNote
                                            }
                                            onChange={(_event: ChangeEvent, value: boolean) =>
                                                handleIndicatorToPrintSelection(indicatorPackage.id, 'note', value)
                                            }
                                        />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                )}
            </div>
        );
    }

    const { translate } = localization;
    const isEva = document.isCurrentDocumentEva;
    const hasIndicatorArea = document.currentDocument.chapters.some((chapter) => chapter.isIndicatorArea);

    return (
        <StyledDialog
            open={props.isOpen}
            onClose={props.close}
            aria-labelledby="download-pdf-dialog-title"
            maxWidth={'md'}
            fullWidth
            classes={{
                paper: classes.paper,
            }}
        >
            <DialogTitle classes={{ root: classes.dialogTitle }}>
                <span id="download-pdf-dialog-title">{translate('DOCUMENT_PRINT_OPTIONS_TITLE')}</span>
                {!isEva ? (
                    // Show tabs for shvk document. Shvk has indicator packages.
                    <Tabs
                        value={state.activeTab}
                        indicatorColor="primary"
                        textColor="primary"
                        onChange={handleTabChange}
                        variant="fullWidth"
                    >
                        <Tab label={translate('CHAPTERS')} value="CHAPTERS" />
                        <Tab
                            label={translate('INDICATOR_PACKAGES')}
                            value="INDICATOR_PACKAGES"
                            disabled={!hasIndicatorArea}
                        />
                    </Tabs>
                ) : (
                    // Don't show tabs for eva document. Eva doesn't have indicator packages. Show some padding instead.
                    <Box pb={2} />
                )}
            </DialogTitle>
            <DialogContent dividers>
                <TabContext value={state.activeTab}>
                    <TabPanel value={'CHAPTERS'}>{chaptersTab()}</TabPanel>
                    {!isEva && <TabPanel value={'INDICATOR_PACKAGES'}>{indicatorPackagesTab()}</TabPanel>}
                </TabContext>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={props.close}>
                    {translate('CANCEL')}
                </Button>
                <Box flexGrow={1} />
                <ShvkButton onClick={startDownload}>{translate('DOWNLOAD')}</ShvkButton>
            </DialogActions>
        </StyledDialog>
    );
}

export default observer(DownloadPdfOrWordDialog);
