import React, { createContext, useContext, ReactNode } from 'react';
import RootModule from './rootModule';

export const rootModule = new RootModule();

export const StoreContext = createContext<RootModule>({} as RootModule);

export function StoreProvider({ children }: { children: ReactNode }) {
    return <StoreContext.Provider value={rootModule}>{children}</StoreContext.Provider>;
}

// Custom hook to be used inside functional components to access all modules composed by rootModule.
export default function useStore(): RootModule {
    const context = useContext(StoreContext);
    if (context === undefined) {
        throw new Error('useStore must be used within StoreProvider');
    }
    return context;
}
