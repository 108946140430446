import React from 'react';
import { Box } from '@mui/material';

interface TabPanelProps {
    children?: React.ReactNode;
    activePanel: string | number;
    value: string | number;
}

export default function ShvkTabPanel(props: TabPanelProps): JSX.Element {
    const { children, value, activePanel, ...other } = props;

    return (
        <div role="tabpanel" hidden={value !== activePanel} {...other}>
            <Box p={3}>{children}</Box>
        </div>
    );
}
