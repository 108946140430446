import React, { useState, useEffect, createRef } from 'react';
import { observer } from 'mobx-react-lite';
import {
    Autocomplete,
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    MenuItem,
    Paper,
    Radio,
    RadioGroup,
    Tooltip,
} from '@mui/material';
import { Delete, Save } from '@mui/icons-material';
import { ComparisonSettingsFormOrganization } from '../../../../types/organization';
import { ComparisonSettingsRequestParams } from '../../../../types/indicator';
import { TextFieldEvent } from '../../../../types/events';
import { moveToIndexZero } from '../../../../utils/utils';
import DangerIconButton from '../../../../styled/DangerIconButton';
import ShvkTextField from '../../../../styled/ShvkTextField';
import ShvkButton from '../../../../styled/ShvkButton';
import InfoIcon from '@mui/icons-material/Info';
import useStore from '../../../../store/storeContext';

interface Props {
    isOpen: boolean;
    close(): void;
}

interface State {
    startYear: number;
    endYear: number;

    // Autocomplete input text
    organizationInputValue: string;

    // List of organizations that are fetched using autocomplete input.
    searchResponseOrganizations: ComparisonSettingsFormOrganization[];
    filteredAutocompleteOrganizations: ComparisonSettingsFormOrganization[];

    // List of added organization
    organizationList: ComparisonSettingsFormOrganization[];
    direction: RadioFieldValue;
    visibility: RadioFieldValue;
    scale: RadioFieldValue;
    fetchingOrganizations: boolean;
}

type SelectField = 'startYear' | 'endYear';
type RadioField = 'direction' | 'visibility' | 'scale';
type RadioFieldValue = '1' | '0';

const currentYear: Readonly<number> = new Date().getFullYear();
const firstYearInList: Readonly<number> = 2008;

// Organizations don't need to be fetched with every keystroke.
let searchTimeout: ReturnType<typeof setTimeout> | undefined = undefined;

function ComparisonSettingsDialog(props: Props) {
    const { document, organization, indicator, localization, loadingIndicator, snackbar, theming } = useStore();

    const inputRef = createRef<HTMLInputElement>();

    const [state, setState] = useState<State>({
        startYear: firstYearInList,
        endYear: currentYear,
        organizationInputValue: '',
        searchResponseOrganizations: [],
        filteredAutocompleteOrganizations: [],
        organizationList: [],
        direction: '0',
        visibility: '0',
        scale: '0',
        fetchingOrganizations: false,
    });

    useEffect(() => {
        props.isOpen && init();
    }, [props.isOpen]);

    function init(): void {
        const { comparisonSettings } = indicator;
        // Organizations in comparison settings and search response have different types.
        // We can use the type of the search response because it has everything we need.
        // Map organizations from comparison settings to use that type.
        if (comparisonSettings) {
            //Display home organization on top of the list.
            const orderedOrganizations = moveToIndexZero(
                comparisonSettings.organizations,
                comparisonSettings.organizationName,
                'name',
            );
            const organizationList =
                orderedOrganizations.map((organization) => {
                    return {
                        id: organization.id,
                        name: organization.name,
                    };
                }) || [];

            setState((state) => ({ ...state, organizationList }));
        }
        setState((state) => ({
            ...state,
            organizationInputValue: '',
            searchResponseOrganizations: [],
            filteredAutocompleteOrganizations: [],
            direction: (comparisonSettings?.compareDirection?.toString() as RadioFieldValue) || '0',
            scale: (comparisonSettings?.graphScale?.toString() as RadioFieldValue) || '0',
            visibility: (comparisonSettings?.changeYearsVisibility?.toString() as RadioFieldValue) || '0',
            startYear: comparisonSettings?.startYear || firstYearInList,
            endYear: comparisonSettings?.endYear || currentYear,
            fetchingOrganizations: false,
        }));
    }

    async function save(): Promise<void> {
        const { currentDocument } = document;
        const { comparisonSettings } = indicator;
        if (!comparisonSettings || !currentDocument) return;

        const settings: ComparisonSettingsRequestParams = {
            changeYearsVisibility: Number(state.visibility) as 1 | 0,
            compareDirection: Number(state.direction) as 1 | 0,
            documentId: currentDocument.id,
            endYear: state.endYear,
            graphScale: Number(state.scale) as 1 | 0,
            id: comparisonSettings.id,
            organizationName: currentDocument.organization.name,
            organizations: state.organizationList,
            startYear: state.startYear,
        };

        try {
            loadingIndicator.show();
            await indicator.saveComparisonSettings(settings);
            snackbar.showSuccess();
            await document.refreshCurrentDocument();
        } catch (e) {
            snackbar.showError(e.data?.code);
        } finally {
            loadingIndicator.hide();
            props.close();
        }
    }

    async function searchOrganization(newInputValue: string): Promise<void> {
        setState((state) => ({
            ...state,
            fetchingOrganizations: true,
        }));
        try {
            const organizations = await organization.searchOrganizations(newInputValue);
            const filteredOrganizations = organizations.filter(
                (responseOrg) => !state.organizationList.some((listOrg) => listOrg.id === responseOrg.id),
            );
            setState((state) => ({
                ...state,
                searchResponseOrganizations: organizations,
                filteredAutocompleteOrganizations: filteredOrganizations,
                fetchingOrganizations: false,
            }));
            // Set focus to the autocomplete text-field. This will open the autocomplete popup.
            inputRef.current?.focus();
        } catch (e) {
            snackbar.showError(e.data?.code);
            setState((state) => ({ ...state, fetchingOrganizations: false }));
        }
    }

    function getStartYearOptions(): number[] {
        const startYearOptions = [];
        for (let year = firstYearInList; year < state.endYear; year++) {
            startYearOptions.push(year);
        }

        return startYearOptions;
    }

    function getEndYearOptions(): number[] {
        const endYearOptions = [];
        for (let year = currentYear; year > state.startYear; year--) {
            endYearOptions.push(year);
        }
        return endYearOptions.reverse();
    }

    function removeOrganization(id: number): void {
        setState((state) => ({
            ...state,
            organizationList: state.organizationList.filter((org) => org.id !== id),
        }));
    }

    function isHomeOrganization(org: ComparisonSettingsFormOrganization): boolean {
        return document.currentDocument.organization.id === org.id;
    }

    const handleOrganizationInputChange = (newInputValue: string): void => {
        setState((state) => ({
            ...state,
            organizationInputValue: newInputValue,
        }));

        clearTimeout(searchTimeout);

        if (newInputValue !== '') {
            searchTimeout = setTimeout(() => {
                void searchOrganization(newInputValue);
            }, 600);
        } else {
            setState((state) => ({
                ...state,
                searchResponseOrganizations: [],
                filteredAutocompleteOrganizations: [],
            }));
        }
    };

    const handleOrganizationValueChange = (newValue: ComparisonSettingsFormOrganization | null): void => {
        if (newValue) {
            // Push the new value to the list and reset the autocomplete input value.
            setState((state) => ({
                ...state,
                organizationList: [...state.organizationList, newValue as ComparisonSettingsFormOrganization],
            }));
            handleOrganizationInputChange('');
        }
    };

    const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setState((state) => ({
            ...state,
            [event.target.name as RadioField]: event.target.value as RadioFieldValue,
        }));
    };

    const handleTextFieldChange = (event: TextFieldEvent): void => {
        setState((state) => ({
            ...state,
            [event.target.name as SelectField]: Number(event.target.value),
        }));
    };

    const startYearOptions = getStartYearOptions();
    const endYearOptions = getEndYearOptions();

    const { translate } = localization;

    return (
        <Dialog
            open={props.isOpen}
            onClose={props.close}
            aria-labelledby="comparison-settings-dialog-title"
            maxWidth={'sm'}
            fullWidth
        >
            <DialogTitle id="comparison-settings-dialog-title">{translate('COMPARISON_SETTINGS')}</DialogTitle>
            <DialogContent dividers>
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <ShvkTextField
                            select
                            label={translate('START_YEAR')}
                            fullWidth
                            variant="outlined"
                            size="small"
                            value={state.startYear}
                            name="startYear"
                            onChange={handleTextFieldChange}
                        >
                            {startYearOptions.map((year) => (
                                <MenuItem key={year} value={year}>
                                    {year}
                                </MenuItem>
                            ))}
                        </ShvkTextField>
                    </Grid>
                    <Grid item xs={3}>
                        <ShvkTextField
                            select
                            label={translate('END_YEAR')}
                            variant="outlined"
                            fullWidth
                            size="small"
                            value={state.endYear}
                            name="endYear"
                            onChange={handleTextFieldChange}
                        >
                            {endYearOptions.map((year) => (
                                <MenuItem key={year} value={year}>
                                    {year}
                                </MenuItem>
                            ))}
                        </ShvkTextField>
                    </Grid>
                    <Grid item xs={12}>
                        <Autocomplete
                            openOnFocus
                            inputValue={state.organizationInputValue}
                            onInputChange={(_e, value) => handleOrganizationInputChange(value)}
                            onChange={(_e, value) => handleOrganizationValueChange(value)}
                            // We only use onChange to push a new value to a list.
                            // We can set value to null because we do not want to set a value to autocomplete.
                            value={null}
                            options={state.filteredAutocompleteOrganizations}
                            getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
                            renderOption={(props: object, option): JSX.Element => (
                                <li {...props} key={option.id}>
                                    {option.name}
                                </li>
                            )}
                            fullWidth
                            size="small"
                            loading={state.fetchingOrganizations}
                            renderInput={(params): JSX.Element => (
                                <ShvkTextField
                                    inputRef={inputRef}
                                    {...params}
                                    label={translate('ADD_COMPARISON_ORGANIZATION')}
                                    variant="outlined"
                                    placeholder={translate('ORGANIZATION_PLACEHOLDER')}
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <React.Fragment>
                                                {state.fetchingOrganizations && <CircularProgress size={20} />}
                                                {params.InputProps.endAdornment}
                                            </React.Fragment>
                                        ),
                                    }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormLabel component="legend" id="organization-list-header">
                            {translate('CHOSEN_ORGANIZATIONS')}
                        </FormLabel>
                        <Paper variant="outlined">
                            <List dense aria-labelledby="organization-list-header">
                                {state.organizationList.map((org, i) => (
                                    <ListItem key={org.id} divider={i < state.organizationList.length - 1}>
                                        {isHomeOrganization(org) ? (
                                            <ListItemText>
                                                <span style={{ fontWeight: 'bold' }}>
                                                    {org.name} ({translate('HOME_ORGANIZATION')})
                                                </span>
                                            </ListItemText>
                                        ) : (
                                            <ListItemText>{org.name}</ListItemText>
                                        )}
                                        <ListItemSecondaryAction>
                                            <Tooltip
                                                title={
                                                    isHomeOrganization(org)
                                                        ? translate('HOME_ORGANIZATION_DELETION_TIP')
                                                        : null
                                                }
                                            >
                                                <span>
                                                    <DangerIconButton
                                                        size="small"
                                                        onClick={(): void => removeOrganization(org.id)}
                                                        disabled={isHomeOrganization(org)}
                                                    >
                                                        <Delete />
                                                    </DangerIconButton>
                                                </span>
                                            </Tooltip>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                ))}
                            </List>
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl component="fieldset">
                            <FormLabel component="legend">
                                {translate('COMPARE_DIRECTION')}
                                <Tooltip placement="right-start" title={translate('COMPARE_DIRECTION_TIP')}>
                                    <InfoIcon
                                        color="primary"
                                        style={{
                                            fontSize: 'larger',
                                            display: 'inline-block',
                                            verticalAlign: 'middle',
                                            marginLeft: theming.spacing(0.6),
                                        }}
                                    />
                                </Tooltip>
                            </FormLabel>
                            <RadioGroup
                                aria-label={translate('COMPARE_DIRECTION')}
                                name="direction"
                                value={state.direction}
                                onChange={handleRadioChange}
                            >
                                <FormControlLabel
                                    value="0"
                                    control={<Radio />}
                                    label={translate('COMPARE_DIRECTION_OPT_1')}
                                />
                                <FormControlLabel
                                    value="1"
                                    control={<Radio />}
                                    label={translate('COMPARE_DIRECTION_OPT_2')}
                                />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl component="fieldset">
                            <FormLabel component="legend">{translate('COMPARE_SETTINGS_GRAPH_SCALE_TITLE')}</FormLabel>
                            <RadioGroup
                                aria-label={translate('COMPARE_SETTINGS_GRAPH_SCALE_TITLE')}
                                name="scale"
                                value={state.scale}
                                onChange={handleRadioChange}
                            >
                                <FormControlLabel
                                    value="0"
                                    control={<Radio />}
                                    label={translate('COMPARE_SETTINGS_GRAPH_SCALE_OPTION_0_MAX')}
                                />
                                <FormControlLabel
                                    value="1"
                                    control={<Radio />}
                                    label={translate('COMPARE_SETTINGS_GRAPH_SCALE_OPTION_MIN_MAX')}
                                />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl component="fieldset">
                            <FormLabel component="legend">
                                {translate('CHANGE_YEARS_VISIBILITY_SETTING_TITLE')}
                            </FormLabel>
                            <RadioGroup
                                aria-label={translate('CHANGE_YEARS_VISIBILITY_SETTING_TITLE')}
                                name="visibility"
                                value={state.visibility}
                                onChange={handleRadioChange}
                            >
                                <FormControlLabel
                                    value="0"
                                    control={<Radio />}
                                    label={translate('SHOW_CHANGE_YEARS_ON_MOUSE_OVER_OPTION')}
                                />
                                <FormControlLabel
                                    value="1"
                                    control={<Radio />}
                                    label={translate('SHOW_CHANGE_YEARS_ALWAYS_OPTION')}
                                />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={props.close}>
                    {translate('CANCEL')}
                </Button>
                <Box flexGrow={1} />
                <ShvkButton startIcon={<Save />} onClick={save}>
                    {translate('SAVE')}
                </ShvkButton>
            </DialogActions>
        </Dialog>
    );
}

export default observer(ComparisonSettingsDialog);
