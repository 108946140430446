import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Paper, Tooltip, Typography } from '@mui/material';
import { ArrowDropDown, ControlPoint } from '@mui/icons-material';
import { ButtonEvent } from '../../types/events';
import SelectDocumentPopover from './SelectDocumentPopover';
import ShvkSecondaryButton from '../../styled/ShvkSecondaryButton';
import NewDocumentDialog from '../document/newDocumentDialog';
import ShvkButton from '../../styled/ShvkButton';
import useStore from '../../store/storeContext';

interface State {
    selectDocumentMenu: HTMLElement | null;
}

function DocumentArea() {
    const { dialog, localization, theming } = useStore();

    const [state, setState] = useState<State>({
        selectDocumentMenu: null,
    });

    const handleDocumentMenuClick = (event: ButtonEvent): void => {
        setState((state) => ({ ...state, selectDocumentMenu: event.currentTarget }));
    };

    const handleDocumentMenuClose = (): void => {
        setState((state) => ({ ...state, selectDocumentMenu: null }));
    };

    const { translate } = localization;
    const { newDocumentDialogProperties, closeNewDocumentDialog, openNewDocumentDialog } = dialog;

    return (
        <>
            <Paper>
                <Box p={3} textAlign="center">
                    <Box mb={2}>
                        <Typography variant="h4" component="h4">
                            {translate('NO_DOCUMENT')}
                        </Typography>
                        <Typography variant="h6" component="h6">
                            {translate('NO_DOCUMENT_SUBTITLE')}
                        </Typography>
                    </Box>
                    <Box mb={4}>
                        <ShvkButton
                            endIcon={<ArrowDropDown />}
                            aria-controls="select-document-popup"
                            aria-haspopup="true"
                            onClick={handleDocumentMenuClick}
                        >
                            {translate('SELECT_DOCUMENT')}
                        </ShvkButton>
                        <SelectDocumentPopover
                            handleClose={handleDocumentMenuClose}
                            anchorEl={state.selectDocumentMenu}
                        />
                    </Box>
                    <Box mb={2}>
                        <ShvkSecondaryButton startIcon={<ControlPoint />} onClick={() => openNewDocumentDialog('SHVK')}>
                            {translate('DOCUMENT_START_NEW')}
                        </ShvkSecondaryButton>
                    </Box>
                    <Box mb={4}>
                        <ShvkSecondaryButton startIcon={<ControlPoint />} onClick={() => openNewDocumentDialog('EVA')}>
                            {translate('DOCUMENT_START_NEW_EVA')}
                        </ShvkSecondaryButton>
                    </Box>
                    <Box mb={6}>
                        <Tooltip arrow placement="bottom" title={translate('DOCUMENT_START_NEW_ANOTHER_TOOLTIP')}>
                            <span>
                                <ShvkSecondaryButton
                                    startIcon={<ControlPoint />}
                                    onClick={() => openNewDocumentDialog('ANOTHER_REPORT')}
                                    style={{ marginBottom: theming.spacing(1) }}
                                >
                                    {translate('DOCUMENT_START_NEW_ANOTHER')}
                                </ShvkSecondaryButton>
                            </span>
                        </Tooltip>
                    </Box>
                </Box>
            </Paper>
            <NewDocumentDialog
                open={newDocumentDialogProperties.open}
                onClose={closeNewDocumentDialog}
                type={newDocumentDialogProperties.type}
                fromPopover={newDocumentDialogProperties.fromPopover}
            />
        </>
    );
}

export default observer(DocumentArea);
