import React from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';
import { DescriptionOutlined } from '@mui/icons-material';
import { PublishedDocument } from '../../types/document';
import useStore from '../../store/storeContext';

interface Props {
    open: boolean;
    onClose(): void;
    documents: PublishedDocument[];
}

function ApprovedDocumentsDialog(props: Props) {
    const { document, localization, loadingIndicator, snackbar } = useStore();

    const navigate = useNavigate();

    async function handleClick(id: number): Promise<void> {
        try {
            loadingIndicator.show();
            await document.loadPublishedDocumentById(id);
        } catch (error) {
            snackbar.showError(error.data?.code);
        } finally {
            navigate('/preview/' + id);
            loadingIndicator.hide();
        }
    }

    const { open, onClose, documents } = props;
    const { translate, locale } = localization;

    return (
        <Dialog open={open} onClose={onClose} aria-labelledby="approved-documents-dialog-title" maxWidth={'md'}>
            <DialogTitle id="approved-documents-dialog-title">{translate('APPROVED_FUNCTION')}</DialogTitle>
            <DialogContent dividers>
                <TableContainer component={Paper} variant="outlined">
                    <Table aria-label={translate('APPROVED_FUNCTION')}>
                        <TableHead>
                            <TableRow>
                                <TableCell>{translate('USER_ORGANIZATIONS')}</TableCell>
                                <TableCell>{translate('DOCUMENT_SEARCH')}</TableCell>
                                <TableCell>{translate('APPROVED_DATE')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {documents
                                // TODO Define how many docs we want to show
                                .slice(5, 100)
                                .map((doc: PublishedDocument) => (
                                    <TableRow key={doc.id}>
                                        <TableCell>{doc.organization?.name[locale]}</TableCell>
                                        <TableCell>
                                            <Button component="span" onClick={(): Promise<void> => handleClick(doc.id)}>
                                                <DescriptionOutlined
                                                    style={{ verticalAlign: 'bottom' }}
                                                    color="primary"
                                                />
                                                {doc.name}
                                            </Button>
                                        </TableCell>
                                        <TableCell>{moment(doc.acceptedDate).format('DD.MM.YYYY')}</TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} variant="contained">
                    {translate('CLOSE')}
                </Button>
                <Box flexGrow={1} />
            </DialogActions>
        </Dialog>
    );
}

export default observer(ApprovedDocumentsDialog);
