import React from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@mui/material';
import AuditLogItem from '../../types/auditLog';
import moment from 'moment';
import useStore from '../../store/storeContext';

interface Props {
    open: boolean;
    onClose(): void;
    auditLogItem: AuditLogItem | null;
}

function AuditLogItemDialog(props: Props) {
    const { localization } = useStore();

    const { translate } = localization;

    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            aria-labelledby="audit-log-modal-title"
            maxWidth={'sm'}
            fullWidth
        >
            <DialogTitle id="audit-log-modal-title">{translate('AUDIT_LOG')}</DialogTitle>
            <DialogContent dividers>
                <Grid container spacing={3} direction="column" alignItems="stretch">
                    <Grid item>
                        <Typography variant="subtitle2">{translate('DATE_CREATED')}</Typography>
                        <Typography variant="body2">
                            {moment(props.auditLogItem?.dateCreated).format('DD.MM.YYYY HH:mm:ss')}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="subtitle2">{translate('AUDIT_LOG_ACTION')}</Typography>
                        <Typography variant="body2">{props.auditLogItem?.action}</Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="subtitle2">{translate('AUDIT_LOG_OBJECT')}</Typography>
                        <Typography variant="body2">{props.auditLogItem?.object}</Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="subtitle2">{translate('USERNAME')}</Typography>
                        <Typography variant="body2">{props.auditLogItem?.username}</Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="subtitle2">{translate('IPADDRESS')}</Typography>
                        <Typography variant="body2">{props.auditLogItem?.source}</Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="subtitle2">{translate('AUDIT_LOG_STATUS')}</Typography>
                        <Typography variant="body2">{props.auditLogItem?.status}</Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="subtitle2">{translate('USER_AGENT')}</Typography>
                        <Typography variant="body2">{props.auditLogItem?.userAgent}</Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="subtitle2">{translate('AUDIT_LOG_REASON')}</Typography>
                        <Typography variant="body2">{props.auditLogItem?.reason}</Typography>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose} variant="contained">
                    {translate('CLOSE')}
                </Button>
                <Box flexGrow={1} />
            </DialogActions>
        </Dialog>
    );
}

export default observer(AuditLogItemDialog);
