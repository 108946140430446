import React, { useEffect, useState } from 'react';
import { observer, useLocalObservable } from 'mobx-react-lite';
import {
    Autocomplete,
    Box,
    Container,
    createFilterOptions,
    Divider,
    FormControlLabel,
    FormLabel,
    IconButton,
    Paper,
    Radio,
    RadioGroup,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    Pagination,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Edit } from '@mui/icons-material';
import { Locale, LocalizedName } from '../types/localization';
import { TextFieldEvent } from '../types/events';
import AddTranslationDialog from '../components/translations/AddTranslationDialog';
import ShvkTextField from '../styled/ShvkTextField';
import ShvkButton from '../styled/ShvkButton';
import useStore from '../store/storeContext';

const PREFIX = 'Translations';

const classes = {
    table: `${PREFIX}-table`,
    cell: `${PREFIX}-cell`,
};

const Root = styled('div')({
    [`& .${classes.table}`]: {
        tableLayout: 'fixed',
    },

    [`& .${classes.cell}`]: {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    },
});

type FilterType = Locale | '';

interface State {
    selectedTranslation: LocalizedName | null;
    dialogOpen: boolean;
}

interface StoreState {
    page: number;
    selectedLanguageFilter: FilterType;
    limitTranslationsByLanguage: LocalizedName[];
    pageContent: LocalizedName[];
    pageCount: number;
    handlePageChange<T>(e: React.ChangeEvent<T>, value: number): void;
    handleChange(e: TextFieldEvent): void;
}

const itemsPerPage: Readonly<number> = 5;

function Translations() {
    const { localization, snackbar } = useStore();

    const [state, setState] = useState<State>({
        selectedTranslation: null,
        dialogOpen: false,
    });

    const localStore = useLocalObservable<StoreState>(() => ({
        page: 1,
        selectedLanguageFilter: '',

        // Filter method for radio buttons.
        get limitTranslationsByLanguage(): LocalizedName[] {
            let allTranslations = localization.allTranslations;
            if (this.selectedLanguageFilter !== '') {
                allTranslations = allTranslations.filter(
                    (translation) =>
                        !translation[this.selectedLanguageFilter as Locale] ||
                        translation[this.selectedLanguageFilter as Locale] === translation.key,
                );
            }
            return allTranslations;
        },

        get pageContent(): LocalizedName[] {
            const pageFirstIndex = (this.page - 1) * itemsPerPage;
            return this.limitTranslationsByLanguage.slice(pageFirstIndex, pageFirstIndex + itemsPerPage);
        },

        get pageCount(): number {
            return Math.ceil(this.limitTranslationsByLanguage.length / itemsPerPage);
        },

        handlePageChange(_event: React.ChangeEvent<unknown>, value: number): void {
            this.page = value;
        },

        handleChange(event: TextFieldEvent): void {
            this.selectedLanguageFilter = event.target.value as FilterType;
            this.page = 1;
        },
    }));

    useEffect((): void => {
        void getData();
    }, []);

    async function getData(): Promise<void> {
        try {
            await localization.fetchAllTranslations();
        } catch {
            snackbar.showFetchFailedMessage();
        }
    }

    const openDialog = (translation: LocalizedName | null): void => {
        setState((state) => ({ ...state, selectedTranslation: translation, dialogOpen: true }));
    };

    const closeDialog = (): void => {
        setState((state) => ({ ...state, selectedTranslation: null, dialogOpen: false }));
    };

    // Allow searching by current language and key
    const filterOptions = createFilterOptions({
        stringify: (localized: LocalizedName) => {
            return localized.key + localized[localization.locale];
        },
    });

    const { translate } = localization;

    return (
        <Root>
            <Container>
                <Box my={3}>
                    <Paper>
                        <Box px={2} py={1} textAlign="center">
                            <Typography variant="h6">{translate('TRANSLATION_MANAGEMENT')}</Typography>
                        </Box>
                        <Divider />
                        <Box m={2}>
                            <Autocomplete
                                id="translation-search"
                                sx={{ mb: 1 }}
                                size="small"
                                fullWidth
                                value={state.selectedTranslation}
                                onChange={(_event, translation): void => {
                                    translation && openDialog(translation as LocalizedName);
                                }}
                                options={localization.allTranslations}
                                getOptionLabel={(option: LocalizedName): string => option[localization.locale]}
                                renderOption={(props: object, option): JSX.Element => (
                                    <li {...props} key={option.id}>
                                        {option[localization.locale]}
                                    </li>
                                )}
                                filterOptions={filterOptions}
                                renderInput={(params): JSX.Element => (
                                    <ShvkTextField
                                        {...params}
                                        label={translate('SEARCH_FOR_TRANSLATION_TIP')}
                                        variant="outlined"
                                    />
                                )}
                            />
                            <Box p={1} mb={2}>
                                <FormLabel component="legend">{translate('LIMIT_VISIBLE_TRANSLATIONS')}</FormLabel>
                                <RadioGroup
                                    row
                                    aria-label={translate('LIMIT_VISIBLE_TRANSLATIONS')}
                                    value={localStore.selectedLanguageFilter}
                                    onChange={localStore.handleChange}
                                >
                                    <FormControlLabel
                                        value=""
                                        control={<Radio />}
                                        label={translate('SHOW_ALL_TRANSLATIONS')}
                                    />
                                    <FormControlLabel
                                        value="fi_FI"
                                        control={<Radio />}
                                        label={translate('NOT_TRANSLATED_FI')}
                                    />
                                    <FormControlLabel
                                        value="sv_SE"
                                        control={<Radio />}
                                        label={translate('NOT_TRANSLATED_SE')}
                                    />
                                    <FormControlLabel
                                        value="en_GB"
                                        control={<Radio />}
                                        label={translate('NOT_TRANSLATED_EN')}
                                    />
                                </RadioGroup>
                            </Box>
                            <Table aria-label={translate('TRANSLATIONS')} className={classes.table} size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>{translate('FI_TRANSLATION')}</TableCell>
                                        <TableCell>{translate('SE_TRANSLATION')}</TableCell>
                                        <TableCell>{translate('EN_TRANSLATION')}</TableCell>
                                        <TableCell style={{ width: 50 }} />
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {localStore.pageContent.map((row) => (
                                        <TableRow key={row.key}>
                                            <TableCell className={classes.cell}>{row.fi_FI}</TableCell>
                                            <TableCell className={classes.cell}>{row.sv_SE}</TableCell>
                                            <TableCell className={classes.cell}>{row.en_GB}</TableCell>
                                            <TableCell className={classes.cell}>
                                                <IconButton
                                                    aria-label={translate('EDIT')}
                                                    onClick={(): void => openDialog(row)}
                                                    color="inherit"
                                                    size="large"
                                                >
                                                    <Edit fontSize="small" />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                        <Box px={2} pb={2} display="flex">
                            <Pagination
                                count={localStore.pageCount}
                                page={localStore.page}
                                onChange={localStore.handlePageChange}
                                color="primary"
                            />
                            <Box flexGrow={1} />
                            <ShvkButton onClick={(): void => openDialog(null)}>{translate('ADD')}</ShvkButton>
                        </Box>
                    </Paper>
                </Box>
            </Container>
            <AddTranslationDialog
                open={state.dialogOpen}
                onClose={closeDialog}
                translation={state.selectedTranslation}
            />
        </Root>
    );
}

export default observer(Translations);
