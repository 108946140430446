import { responsiveFontSizes, Theme, createTheme } from '@mui/material/styles';
import { configure, makeAutoObservable } from 'mobx';
import { PaletteOptions, Palette } from '@mui/material/styles/createPalette';
import { fiFI, Localization, svSE, enUS } from '@mui/material/locale';
import { Locale } from '../../types/localization';
import RootModule from '../rootModule';

configure({ enforceActions: 'observed' });

type ThemeLanguages = Record<Locale, Localization>;

export default class ThemingModule {
    public readonly shvkPurple = '#923468';
    public readonly shvkPurple80 = '#A15F88';
    public readonly shvkPurple10 = '#F0E4E8';

    public readonly shvkPetrol = '#0E4264';
    public readonly shvkPetrol80 = '#3E6982';
    public readonly shvkPetrol10 = '#DFE6E8';

    public readonly shvkBlue = '#255DD0';
    public readonly shvkBlue80 = '#527ABD';
    public readonly shvkBlue60 = '#7398CF';
    public readonly shvkBlue10 = '#E0E8F6';

    public readonly shvkLightBlue = '#7DC6F0';
    public readonly shvkLightBlue80 = '#85CDF1';

    public readonly shvkRed = '#FF3E60';
    public readonly shvkRed80 = '#F16A83';
    public readonly shvkRed10 = '#FDE5E7';

    public readonly shvkBlueGray60 = '#9EAFBB';
    public readonly shvkBlueGray40 = '#BBC8D0';
    public readonly shvkBlueGray20 = '#DBE1E4';
    public readonly shvkBlueGray10 = '#EAEDEE';

    public readonly shvkGreen = '#5FD100';

    public readonly shvkYellow = '#FFE561';

    public readonly shvkBrown = '#996633';

    public readonly shvkLightGrey = '#e0e0e0';
    public readonly shvkMediumGrey = '#bdbdbd';
    public readonly shvkDarkGrey = '#757575';

    public readonly shvkSavu10 = '#FAF8F7';

    private rootModule: RootModule;

    private languages: ThemeLanguages = {
        fi_FI: fiFI,
        sv_SE: svSE,
        en_GB: enUS,
    };

    private chosenPalette: string = localStorage.getItem('chosenPalette') || 'defaultPalette';

    private defaultPalette: PaletteOptions = {
        primary: {
            main: this.shvkPetrol,
            light: this.shvkPetrol80,
        },
        secondary: {
            main: this.shvkPurple,
            light: this.shvkPurple80,
        },
        success: {
            main: this.shvkBlue,
            light: this.shvkBlue80,
        },
        error: {
            main: this.shvkRed,
            light: this.shvkRed80,
        },
        info: {
            main: this.shvkPurple,
            light: this.shvkPurple80,
        },
        warning: {
            main: this.shvkGreen,
        },
    };

    private bluePalette: PaletteOptions = {
        primary: {
            main: this.shvkPetrol,
            light: this.shvkPetrol80,
        },
        secondary: {
            main: this.shvkLightBlue,
            light: this.shvkLightBlue80,
        },
        success: {
            main: this.shvkBrown,
            light: this.shvkBrown,
        },
        error: {
            main: this.shvkBlue,
            light: this.shvkBlue80,
        },
        info: {
            main: this.shvkBlue80,
            light: this.shvkBlue60,
        },
        warning: {
            main: this.shvkBrown,
        },
    };
    /**Shortcut for theme spacing */
    public spacing = (factor: number): string => `${0.5 * factor}rem`; // spacing(1) = 0.5rem = 8px

    constructor(rootModule: RootModule) {
        makeAutoObservable(this, {}, { autoBind: true });
        this.rootModule = rootModule;
    }

    public get theme(): Theme {
        const muiTheme = createTheme(
            {
                spacing: this.spacing,
                typography: {
                    fontFamily: 'Work Sans, sans-serif',
                },
                palette: this.chosenPalette === 'defaultPalette' ? this.defaultPalette : this.bluePalette,
                components: {
                    MuiButton: {
                        styleOverrides: {
                            containedPrimary: {
                                color: 'black',
                                background: this.shvkLightGrey,
                                '&:hover': {
                                    background: this.shvkMediumGrey,
                                },
                                '&:focus': {
                                    background: this.shvkMediumGrey,
                                },
                            },
                        },
                    },
                },
            },
            this.languages[this.rootModule.localization.locale],
        );
        return responsiveFontSizes(muiTheme);
    }

    /**Shortcut for theme palette */
    public get palette(): Palette {
        return this.theme.palette;
    }

    public get getChosenPalette(): string {
        return this.chosenPalette;
    }

    public getPaletteInfo(paletteName: any): any {
        return paletteName === 'defaultPalette' ? this.defaultPalette : this.bluePalette;
    }

    public changePalette(newChosenPalette: string): void {
        localStorage.setItem('chosenPalette', newChosenPalette);
        this.chosenPalette = newChosenPalette;
    }

    public readonly graphOrganizationHomeColor = '#000000';
    public readonly graphOrganizationColors = [
        '#204c84',
        '#d91818',
        '#1d933b',
        '#fad54a',
        '#78828e',
        '#20d2ef',
        '#ee6428',
        '#81fb4b',
        '#583016',
        '#e2dfd4',
        '#52294f',
        '#c52e5c',
        '#187655',
        '#f1f76f',
        '#414653',
        '#1f90bc',
        '#e9786a',
        '#0dbc3a',
        '#b98b55',
        '#b2afa6',
        '#94ecea',
        '#813530',
        '#134646',
        '#f7f7ad',
        '#806640',
        '#2b213b',
        '#f29a3e',
        '#eec48a',
        '#2f2017',
        '#15212c',
    ];

    public readonly graphOrganizationColorsGreyScale = [
        '#000000',
        '#505050',
        '#888888',
        '#989898',
        '#A0A0A0',
        '#A9A9A9',
        '#B8B8B8',
        '#C0C0C0',
        '#D0D0D0',
        '#DCDCDC',
        '#F0F0F0',
        '#F8F8F8',
        '#181818',
        '#505050',
        '#888888',
        '#989898',
        '#A0A0A0',
        '#A9A9A9',
        '#B8B8B8',
        '#C0C0C0',
        '#D0D0D0',
        '#DCDCDC',
        '#F0F0F0',
        '#F8F8F8',
        '#181818',
        '#505050',
        '#888888',
        '#989898',
        '#A0A0A0',
        '#A9A9A9',
        '#B8B8B8',
        '#C0C0C0',
        '#D0D0D0',
        '#DCDCDC',
        '#F0F0F0',
        '#F8F8F8',
    ];

    public readonly graphOrganizationDasharrays = [
        '',
        '5 5',
        '5 10',
        '10 5',
        '5 1',
        '1 5',
        '2 1',
        '3 2 1',
        '5 5 1 5',
        '2 4',
        '1 1',
        '3 3 1',
        '3 3 1',
        '1 1',
        '2 4',
        '5 5 1 5',
        '3 2 1',
        '2 1',
        '1 5',
        '5 1',
        '10 5',
        '5 10',
        '5 5',
        '',
        '2 1',
        '1 5',
        '5 1',
        '10 5',
        '5 10',
        '5 5',
        '',
        '3 3 1',
        '1 1',
        '2 4',
        '5 5 1 5',
        '3 2 1',
    ];
}
