import React, { useState, useEffect, ChangeEvent } from 'react';
import { observer } from 'mobx-react-lite';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormLabel,
    Grid,
    Radio,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@mui/material';
import { TextFieldEvent } from '../../types/events';
import ShvkTextField from '../../styled/ShvkTextField';
import ShvkButton from '../../styled/ShvkButton';
import useStore from '../../store/storeContext';

interface Props {
    open: boolean;
    onClose(): void;
}

interface State {
    password: string;
    password2: string;
    homeOrganizationId: number;
}

type UserInfoFormField = 'password' | 'password2';

function UserInfoDialog(props: Props) {
    const { user, session, snackbar, localization } = useStore();

    const [state, setState] = useState<State>({
        password: '',
        password2: '',
        homeOrganizationId: -1,
    });

    useEffect(() => {
        init();
    }, [props.open]);

    function init(): void {
        setState((state) => ({
            ...state,
            password: '',
            password2: '',
            homeOrganizationId: user.userDetails?.homeOrganization?.id || -1,
        }));
    }

    async function save(): Promise<void> {
        const password = state.password ? state.password : undefined;
        try {
            await user.changeUserInfo(password, undefined, state.homeOrganizationId);
            snackbar.showSuccess();
        } catch (error) {
            snackbar.showError(error.data?.code);
        } finally {
            props.onClose();
        }
    }

    const handleTextChange = (event: TextFieldEvent, field: UserInfoFormField): void => {
        setState((state) => ({ ...state, [field]: event.target.value }));
    };

    const handleRadioChange = (event: ChangeEvent<HTMLInputElement>): void => {
        setState((state) => ({ ...state, homeOrganizationId: Number(event.target.value) }));
    };

    const isSaveButtonDisabled =
        (state.password && !user.validatePassword(state.password)) ||
        !user.checkPasswordMatch(state.password, state.password2);

    const { translate } = localization;
    const { userDetails } = user;
    const { isTrainingEnvironment } = session;

    return (
        <Dialog open={props.open} onClose={props.onClose} aria-labelledby="user-info-dialog-title" maxWidth={'sm'}>
            <DialogTitle id="user-info-dialog-title">{translate('MY_INFO')}</DialogTitle>
            <DialogContent dividers>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <ShvkTextField
                            id="user-form-first-name"
                            label={translate('FIRSTNAME')}
                            variant="outlined"
                            size="small"
                            disabled
                            fullWidth
                            sx={{ mb: 2 }}
                            defaultValue={userDetails?.firstName || ''}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <ShvkTextField
                            id="user-form-last-name"
                            label={translate('LASTNAME')}
                            variant="outlined"
                            size="small"
                            disabled
                            fullWidth
                            sx={{ mb: 2 }}
                            defaultValue={userDetails?.lastName || ''}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <ShvkTextField
                            id="user-form-email"
                            label={translate('USERNAME') + ' (' + translate('EMAIL') + ')'}
                            variant="outlined"
                            size="small"
                            disabled
                            fullWidth
                            sx={{ mb: 2 }}
                            defaultValue={userDetails?.userName || ''}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <ShvkTextField
                            id="user-form-profession"
                            label={translate('PROFESSION')}
                            variant="outlined"
                            size="small"
                            disabled
                            fullWidth
                            sx={{ mb: 2 }}
                            defaultValue={userDetails?.profession || ''}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <ShvkTextField
                            id="user-form-unit"
                            label={translate('UNITORBRANCH')}
                            variant="outlined"
                            size="small"
                            disabled
                            fullWidth
                            sx={{ mb: 2 }}
                            defaultValue={userDetails?.unitOrBranch || ''}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <ShvkTextField
                            id="user-form-password"
                            label={translate('PASSWORD')}
                            disabled={isTrainingEnvironment}
                            variant="outlined"
                            size="small"
                            type="password"
                            fullWidth
                            sx={{ mb: 2 }}
                            onChange={(event): void => handleTextChange(event, 'password')}
                            value={state.password}
                            autoComplete="new-password"
                            error={Boolean(state.password) && !user.validatePassword(state.password)}
                            helperText={translate('PASSWORD_FORMAT_TIP')}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <ShvkTextField
                            id="user-form-password-again"
                            label={translate('RE_ENTER_PASSWORD')}
                            disabled={isTrainingEnvironment}
                            variant="outlined"
                            size="small"
                            type="password"
                            fullWidth
                            sx={{ mb: 2 }}
                            onChange={(event): void => handleTextChange(event, 'password2')}
                            value={state.password2}
                            autoComplete="new-password"
                            error={!user.checkPasswordMatch(state.password, state.password2)}
                            helperText={
                                !user.checkPasswordMatch(state.password, state.password2)
                                    ? translate('PASSWORD_MISMATCH')
                                    : ''
                            }
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormLabel id="user-info-table-label">{translate('ACCESS_RIGHTS')}</FormLabel>
                        <Table aria-labelledby="user-info-table-label" size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>{translate('ORGANIZATION')}</TableCell>
                                    <TableCell>{translate('MAIN_USER')}</TableCell>
                                    <TableCell padding="checkbox">{translate('HOME_ORGANIZATION')}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {userDetails?.organizations.map((row) => (
                                    <TableRow key={row.id}>
                                        <TableCell>{row.name}</TableCell>
                                        <TableCell>{row.isAdmin ? translate('YES') : translate('NO')}</TableCell>
                                        <TableCell align="center">
                                            <Radio
                                                value={row.id}
                                                checked={state.homeOrganizationId === row.id}
                                                onChange={(event): void => handleRadioChange(event)}
                                            />
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose} variant="contained">
                    {translate('CANCEL')}
                </Button>
                <Box flexGrow={1} />
                <ShvkButton color="primary" onClick={save} disabled={isSaveButtonDisabled}>
                    {translate('SAVE')}
                </ShvkButton>
            </DialogActions>
        </Dialog>
    );
}

export default observer(UserInfoDialog);
