import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import TransferListItem from '../../types/transferList';
import EmailGroup from '../../types/emailGroup';
import { TextFieldEvent } from '../../types/events';
import ShvkTextField from '../../styled/ShvkTextField';
import TransferList from './TransferList';
import DangerButton from '../../styled/DangerButton';
import ShvkButton from '../../styled/ShvkButton';
import useStore from '../../store/storeContext';

interface Props {
    open: boolean;
    onClose(): void;
    emailGroup: EmailGroup | null;
}

interface State {
    name: string;
    description: string;
    addressString: string;
}

type EmailGroupDialogFormField = 'name' | 'description';

function AddEmailGroupDialog(props: Props) {
    const { user, dialog, snackbar, localization, theming } = useStore();

    const [state, setState] = useState<State>({
        name: '',
        description: '',
        addressString: '',
    });

    useEffect(() => {
        props.open && init();
    }, [props.open]);

    function init(): void {
        setState({
            name: props.emailGroup?.name || '',
            description: props.emailGroup?.groupDesc || '',
            addressString: props.emailGroup?.addresses || '',
        });
    }

    const handleChange = (event: TextFieldEvent, field: EmailGroupDialogFormField): void => {
        setState({
            ...state,
            [field]: event.target.value,
        });
    };

    function transferListItems(): TransferListItem[] {
        const emailArray = props.emailGroup?.addresses.split(',') || [];
        const userManagementUsers = user.userManagementUsers;

        const transferlist: TransferListItem[] = userManagementUsers.map((user) => {
            return {
                title: user.userName,
                subtitle: user.firstName + ' ' + user.lastName,
                defaultSelected: false,
            };
        });

        emailArray.forEach((email) => {
            if (transferlist.find((user) => user.title === email)) {
                transferlist.find((user) => user.title === email)!.defaultSelected = true;
            } else {
                transferlist.push({
                    title: email,
                    defaultSelected: true,
                });
            }
        });

        return transferlist.sort((a, b) => (a.title > b.title ? 1 : -1));
    }

    function createAddressString(items: TransferListItem[]): void {
        const addressString = items.map((item) => item.title).join(',');
        setState((state) => ({ ...state, addressString }));
    }

    async function save(): Promise<void> {
        try {
            const emailGroup: EmailGroup = {
                id: props.emailGroup?.id || -1,
                name: state.name,
                addresses: state.addressString,
                groupDesc: state.description,
            };
            await user.saveEmailGroup(emailGroup);
            snackbar.showSuccess();
        } catch (error) {
            snackbar.showError(error.data?.code);
        } finally {
            props.onClose();
        }
    }

    async function remove(): Promise<void> {
        try {
            const confirmation = await dialog.getConfirmation();
            if (!confirmation || !props.emailGroup) return;
            const name = props.emailGroup.name;
            await user.removeEmailGroup(name);
            snackbar.showSuccess();
        } catch (error) {
            snackbar.showError(error.data?.code);
        }
        props.onClose();
    }

    const isSaveButtonDisabled = state.addressString === '' || state.name === '';
    const { translate } = localization;

    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            aria-labelledby="email-group-dialog-title"
            maxWidth={'md'}
            fullWidth
        >
            <DialogTitle id="email-group-dialog-title">{translate('EMAIL_GROUP')}</DialogTitle>
            <DialogContent dividers>
                <ShvkTextField
                    label={translate('USER_MANAGEMENT_EMAIL_GROUP_NAME')}
                    value={state.name}
                    variant="outlined"
                    size="small"
                    fullWidth
                    style={{ marginBottom: theming.theme.spacing(2) }}
                    onChange={(event): void => handleChange(event, 'name')}
                />
                <TransferList items={transferListItems()} setSelected={createAddressString} />
                <ShvkTextField
                    label={translate('USER_MANAGEMENT_EMAIL_GROUP_DESC')}
                    multiline
                    rows={3}
                    value={state.description}
                    variant="outlined"
                    size="small"
                    fullWidth
                    style={{ marginBottom: theming.theme.spacing(2) }}
                    onChange={(event): void => handleChange(event, 'description')}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose} variant="contained">
                    {translate('CLOSE')}
                </Button>
                {props.emailGroup && (
                    <DangerButton variant="contained" onClick={remove}>
                        {translate('REMOVE')}
                    </DangerButton>
                )}
                <Box flexGrow={1} />
                <ShvkButton onClick={save} disabled={isSaveButtonDisabled}>
                    {translate('SAVE')}
                </ShvkButton>
            </DialogActions>
        </Dialog>
    );
}

export default observer(AddEmailGroupDialog);
