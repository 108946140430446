import React from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Divider, List, ListItemButton, ListItemIcon, ListItemText, Popover, Typography } from '@mui/material';
import { ThumbUp } from '@mui/icons-material';
import { OrganizationDocument } from '../../types/document';
import { TranslationKeys } from '../../types/translation';
import { sortByName } from '../../utils/utils';
import useStore from '../../store/storeContext';

interface Props {
    handleClose(): void;
    anchorEl: HTMLElement | null;
}

function SelectDocumentPopover(props: Props) {
    const { document, localization, loadingIndicator, snackbar } = useStore();
    async function handleDocumentSelection(organizationDocument: OrganizationDocument): Promise<void> {
        try {
            loadingIndicator.show();
            await document.loadDocumentById(organizationDocument.id);
        } catch (error) {
            snackbar.showError(error.data?.code);
        } finally {
            props.handleClose();
            loadingIndicator.hide();
        }
    }

    const { translate } = localization;

    return (
        <Popover
            id="select-document-popup"
            open={Boolean(props.anchorEl)}
            onClose={props.handleClose}
            anchorEl={props.anchorEl}
        >
            <Box px={2} py={1} textAlign="center">
                <Typography>{translate('SHVK_DOCUMENTS')}</Typography>
            </Box>
            <List dense>
                {document.organizationShvkDocuments
                    .sort((a, b) => sortByName(a.name.toUpperCase(), b.name.toUpperCase()))
                    .map((document) => (
                        <ListItemButton
                            key={document.id}
                            onClick={(): Promise<void> => handleDocumentSelection(document)}
                        >
                            <ListItemIcon style={{ minWidth: 26 }}>
                                {document.statusCode === 'STATUS_COMPLETE' && (
                                    <ThumbUp fontSize="small" color={'primary'} />
                                )}
                            </ListItemIcon>
                            <ListItemText primary={document.name} />
                        </ListItemButton>
                    ))}
            </List>
            <Divider />
            <Box px={2} py={1} textAlign="center">
                <Typography>{translate('EVA_DOCUMENTS')}</Typography>
            </Box>
            <List dense>
                {document.organizationEvaDocuments
                    .sort((a, b) => sortByName(a.name.toUpperCase(), b.name.toUpperCase()))
                    .map((document) => (
                        <ListItemButton
                            key={document.id}
                            onClick={(): Promise<void> => handleDocumentSelection(document)}
                        >
                            <ListItemIcon style={{ minWidth: 26 }}>
                                {document.statusCode === 'STATUS_COMPLETE' && (
                                    <ThumbUp fontSize="small" color={'primary'} />
                                )}
                            </ListItemIcon>
                            <ListItemText
                                primary={
                                    document.name + '(' + translate(document.type.code as keyof TranslationKeys) + ')'
                                }
                            />
                        </ListItemButton>
                    ))}
            </List>
            <Divider />
            <Box px={2} py={1} textAlign="center">
                <Typography>{translate('ANOTHER_DOCUMENTS')}</Typography>
            </Box>
            <List dense>
                {document.organizationAnotherDocuments
                    .sort((a, b) => sortByName(a.name.toUpperCase(), b.name.toUpperCase()))
                    .map((document) => (
                        <ListItemButton
                            key={document.id}
                            onClick={(): Promise<void> => handleDocumentSelection(document)}
                        >
                            <ListItemIcon style={{ minWidth: 26 }}>
                                {document.statusCode === 'STATUS_COMPLETE' && (
                                    <ThumbUp fontSize="small" color={'primary'} />
                                )}
                            </ListItemIcon>
                            <ListItemText primary={document.name} />
                        </ListItemButton>
                    ))}
            </List>
        </Popover>
    );
}

export default observer(SelectDocumentPopover);
