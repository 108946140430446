import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { VersionParameters } from '../../../types/sessionParameters';
import GeneralDialog from '../../GeneralDialog';
import moment from 'moment';
import useStore from '../../../store/storeContext';

const PREFIX = 'VersionInfoDialog';

const classes = {
    header: `${PREFIX}-header`,
    paragraph: `${PREFIX}-paragraph`,
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
    [`& .${classes.header}`]: {
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(1),
    },

    [`& .${classes.paragraph}`]: {
        marginLeft: theme.spacing(1),
    },
}));

interface Props {
    isOpen: boolean;
    close(): void;
}

interface State {
    dialogOpen: boolean;
    dialogContent: string | undefined;
    backendVersion: VersionParameters | null;
}

function VersionInfoDialog(props: Props) {
    const { session, info, localization, snackbar, loadingIndicator } = useStore();

    const [state, setState] = useState<State>({
        dialogOpen: false,
        dialogContent: '',
        backendVersion: null,
    });

    useEffect(() => {
        props.isOpen && init();
    }, [props.isOpen]);

    async function init(): Promise<void> {
        if (!state.backendVersion) {
            try {
                loadingIndicator.show();
                const backendVersion = await session.fetchBackendVersion();
                setState((state) => ({ ...state, backendVersion }));
            } catch (error) {
                snackbar.showFetchFailedMessage(error.data?.code);
            } finally {
                loadingIndicator.hide();
            }
        }
    }

    async function handleClick(dialogContent: string): Promise<void> {
        try {
            loadingIndicator.show();
            !info.materialText && (await info.fetchMaterialText());
            !info.shvkInfo && (await info.fetchShvkInfo());
        } catch (error) {
            snackbar.showFetchFailedMessage(error.data?.code);
        } finally {
            loadingIndicator.hide();
        }
        if (dialogContent === 'READ_MORE') {
            setState((state) => ({ ...state, dialogContent: info.shvkInfo?.[localization.locale] }));
        } else if (dialogContent === 'LOAD_TOOL_MATERIAL') {
            setState((state) => ({ ...state, dialogContent: info.materialText?.[localization.locale] }));
        }
        handleDialog();
    }

    function handleDialog(): void {
        if (state.dialogOpen) {
            setState((state) => ({ ...state, dialogOpen: false }));
        } else {
            setState((state) => ({ ...state, dialogOpen: true }));
        }
    }

    const { translate } = localization;

    return (
        <StyledDialog
            open={props.isOpen}
            onClose={props.close}
            aria-labelledby="version-info-dialog-title"
            maxWidth={'xs'}
            fullWidth
        >
            <DialogTitle id="version-info-dialog">{translate('APP_VERSION_INFO')}</DialogTitle>
            <DialogContent dividers>
                <Button onClick={() => handleClick('READ_MORE')}>{translate('READ_MORE')}</Button>
                <br />
                <Button onClick={() => handleClick('LOAD_TOOL_MATERIAL')}>{translate('LOAD_TOOL_MATERIAL')}</Button>
                <Typography variant="h5" className={classes.header}>
                    Frontend
                </Typography>
                <Typography className={classes.paragraph}>Version: {process.env.REACT_APP_VERSION}</Typography>
                <Typography variant="h5" className={classes.header}>
                    Backend
                </Typography>
                <Typography className={classes.paragraph}>
                    Version: {state.backendVersion?.version || '-'}
                    <br />
                    Build: {state.backendVersion?.buildNumber || '-'}
                    <br />
                    Date:{' '}
                    {state.backendVersion?.buildDate
                        ? moment(state.backendVersion.buildDate, 'YYYY-MM-DDThh:mm:ss').format('D.M.YYYY h:mm')
                        : '-'}
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={props.close}>
                    {translate('CLOSE')}
                </Button>
            </DialogActions>
            <GeneralDialog content={state.dialogContent} open={state.dialogOpen} onClose={handleDialog} />
        </StyledDialog>
    );
}

export default observer(VersionInfoDialog);
