import React, { useEffect } from 'react';
import { observer, useLocalObservable } from 'mobx-react-lite';
import { runInAction } from 'mobx';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { EditorImpactOnTarget } from '../../../../types/evaTemplate';
import { Indicator } from '../../../../types/indicator';
import IndicatorTable from '../IndicatorTable';
import useStore from '../../../../store/storeContext';

interface Props {
    isOpen: boolean;
    close(): void;
    impactOnTarget?: EditorImpactOnTarget | null;
}

interface StoreState {
    impactOnTarget: EditorImpactOnTarget | null | undefined;
    indicators: Indicator[];
}

function EvaIndicatorListDialog(props: Props) {
    const { localization } = useStore();

    const localStore = useLocalObservable<StoreState>(() => ({
        impactOnTarget: null,
        get indicators(): Indicator[] {
            if (!this.impactOnTarget) return [];
            return [...this.impactOnTarget.sotkanetIndicators, ...this.impactOnTarget.organizationIndicators];
        },
    }));

    useEffect(() => {
        props.isOpen && runInAction(() => (localStore.impactOnTarget = props.impactOnTarget));
    }, [props.isOpen]);

    const { translate } = localization;

    return (
        <Dialog
            open={props.isOpen}
            onClose={props.close}
            aria-labelledby="eva-indicator-list-dialog-title"
            maxWidth={'sm'}
            fullWidth
        >
            <DialogTitle id="eva-indicator-list-dialog-title">
                {translate('EVA_INDICATORS')}
                <Typography>
                    {translate('EVA_IMPACT_ON_TARGET')} - {props.impactOnTarget?.target}
                </Typography>
            </DialogTitle>
            <DialogContent dividers>
                <IndicatorTable indicators={localStore.indicators} impactOnTarget={props.impactOnTarget} />
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={props.close}>
                    {translate('CLOSE')}
                </Button>
                <Box flexGrow={1} />
            </DialogActions>
        </Dialog>
    );
}

export default observer(EvaIndicatorListDialog);
