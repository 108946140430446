import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Popover,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { ArrowDropDown } from '@mui/icons-material';
import { PdfIcon } from '../../../SvgIcons';
import { ButtonEvent } from '../../../../types/events';
import ShvkButton from '../../../../styled/ShvkButton';
import useStore, { rootModule } from '../../../../store/storeContext';

const PREFIX = 'EvaDecisionOptionCompareDialog';

const classes = {
    tableCell: `${PREFIX}-tableCell`,
    perspectiveNameCell: `${PREFIX}-perspectiveNameCell`,
    popoverItemText: `${PREFIX}-popoverItemText`,
};

const { theming } = rootModule;

const StyledDialog = styled(Dialog)({
    [`& .${classes.tableCell}`]: {
        border: '1px solid' + theming.shvkLightGrey,
    },
    [`& .${classes.perspectiveNameCell}`]: {
        border: '1px solid' + theming.shvkLightGrey,
        backgroundColor: theming.shvkPetrol10,
    },
    [`& .${classes.popoverItemText}`]: {
        margin: 0,
    },
});

interface Props {
    isOpen: boolean;
    close(): void;
}

interface State {
    actionsMenu: HTMLElement | null;
}

function EvaDecisionOptionCompareDialog(props: Props) {
    const { document, localization, snackbar, loadingIndicator, theming } = useStore();

    const [state, setState] = useState<State>({
        actionsMenu: null,
    });

    const handleActionsMenuOpen = (event: ButtonEvent): void => {
        setState((state) => ({
            ...state,
            actionsMenu: event.currentTarget,
        }));
    };

    const handleActionsMenuClose = (): void => {
        setState((state) => ({ ...state, actionsMenu: null }));
    };

    async function download(pdfOrExcel: string): Promise<void> {
        try {
            handleActionsMenuClose();
            loadingIndicator.show();
            if (pdfOrExcel === 'pdf') {
                await document.downloadEvaDecisionOptionCompareTablePDF();
            } else {
                await document.downloadEvaDecisionOptionCompareTableExcel();
            }
            snackbar.showSuccess();
        } catch (error) {
            snackbar.showError(error.data.code);
        } finally {
            loadingIndicator.hide();
        }
    }

    const cellStyle = (value: number): React.CSSProperties | undefined => {
        const { palette } = theming;

        if (value > 0) {
            return {
                backgroundColor: palette.warning.main,
                color: 'white',
            };
        } else if (value < 0) {
            return {
                backgroundColor: palette.error.main,
                color: 'white',
            };
        }
    };

    const { translate } = localization;
    const { evaDecisionOptionChapter } = document;

    return (
        <StyledDialog
            open={props.isOpen}
            onClose={props.close}
            aria-labelledby="eva-decision-option-compare-dialog-title"
            maxWidth={'lg'}
        >
            <DialogTitle id="eva-decision-option-compare-dialog-title">
                {translate('EVA_DECISION_OPTION_COMPARE')}
            </DialogTitle>
            <DialogContent dividers>
                <Box mb={2}>
                    <ShvkButton
                        onClick={handleActionsMenuOpen}
                        endIcon={<ArrowDropDown />}
                        aria-controls="compare-dialog-options-popover"
                        aria-haspopup="true"
                    >
                        {translate('DOCUMENT_OPTIONS')}
                    </ShvkButton>
                </Box>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell />
                            {evaDecisionOptionChapter?.evaDecisionOptions.map((decisionOption) => (
                                <TableCell key={decisionOption.id} className={classes.tableCell} colSpan={2}>
                                    {decisionOption.text}
                                </TableCell>
                            ))}
                        </TableRow>
                        <TableRow>
                            <TableCell className={classes.tableCell}>{translate('EVA_IMPACT_ON_TARGET')}</TableCell>
                            {evaDecisionOptionChapter?.evaDecisionOptions.map((decisionOption) => (
                                <React.Fragment key={decisionOption.id}>
                                    <TableCell className={classes.tableCell}>
                                        {translate('EVA_TERM_EFFECT_PART_1_SHORT')}{' '}
                                        {translate('EVA_TERM_EFFECT_PART_3')}
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                        {translate('EVA_TERM_EFFECT_PART_2_LONG')} {translate('EVA_TERM_EFFECT_PART_3')}
                                    </TableCell>
                                </React.Fragment>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {evaDecisionOptionChapter &&
                            evaDecisionOptionChapter.evaDecisionOptions.length > 0 &&
                            evaDecisionOptionChapter?.evaDecisionOptions[0]?.evaPerspectives.map(
                                (perspective, perspectiveIndex) => (
                                    <React.Fragment key={perspective.id}>
                                        {/* PERSPECTIVE NAME ROW*/}
                                        <TableRow>
                                            <TableCell
                                                colSpan={evaDecisionOptionChapter?.evaDecisionOptions.length * 2 + 1}
                                                className={classes.perspectiveNameCell}
                                            >
                                                {translate('EVA_PERSPECTIVE')}: {perspective.title}
                                            </TableCell>
                                        </TableRow>
                                        {perspective.impactTargets.map((impact, impactIndex) => (
                                            <TableRow key={impact.id}>
                                                {/* IMPACT ON TARGET NAME CELL*/}
                                                <TableCell className={classes.tableCell}>{impact.target}</TableCell>
                                                {/*SHORT AND LONG TERM CELLS*/}
                                                {evaDecisionOptionChapter?.evaDecisionOptions.map((decisionOption) => {
                                                    const shortTerm =
                                                        decisionOption.evaPerspectives[perspectiveIndex].impactTargets[
                                                            impactIndex
                                                        ]?.shortTermEffect;
                                                    const longTerm =
                                                        decisionOption.evaPerspectives[perspectiveIndex].impactTargets[
                                                            impactIndex
                                                        ]?.longTermEffect;
                                                    return (
                                                        <React.Fragment key={decisionOption.id}>
                                                            <TableCell
                                                                className={classes.tableCell}
                                                                align="center"
                                                                style={cellStyle(shortTerm)}
                                                            >
                                                                {shortTerm}
                                                            </TableCell>
                                                            <TableCell
                                                                className={classes.tableCell}
                                                                align="center"
                                                                style={cellStyle(longTerm)}
                                                            >
                                                                {longTerm}
                                                            </TableCell>
                                                        </React.Fragment>
                                                    );
                                                })}
                                            </TableRow>
                                        ))}
                                    </React.Fragment>
                                ),
                            )}
                    </TableBody>
                </Table>
                <Box mt={2}>
                    <ShvkButton
                        onClick={handleActionsMenuOpen}
                        endIcon={<ArrowDropDown />}
                        aria-controls="compare-dialog-options-popover"
                        aria-haspopup="true"
                    >
                        {translate('DOCUMENT_OPTIONS')}
                    </ShvkButton>
                </Box>
                <Popover
                    id="compare-dialog-options-popover"
                    open={Boolean(state.actionsMenu)}
                    onClose={handleActionsMenuClose}
                    anchorEl={state.actionsMenu}
                >
                    <List dense>
                        <ListItemButton onClick={() => download('pdf')}>
                            <ListItemIcon>
                                <PdfIcon fontSize="small" color="primary" />
                            </ListItemIcon>
                            <ListItemText
                                primary={translate('DOWNLOAD') + ' Pdf'}
                                className={classes.popoverItemText}
                            />
                        </ListItemButton>
                        <ListItemButton onClick={() => download('excel')}>
                            <ListItemIcon>
                                <PdfIcon fontSize="small" color="primary" />
                            </ListItemIcon>
                            <ListItemText
                                primary={translate('DOWNLOAD') + ' Excel'}
                                className={classes.popoverItemText}
                            />
                        </ListItemButton>
                    </List>
                </Popover>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={props.close}>
                    {translate('CLOSE')}
                </Button>
                <Box flexGrow={1} />
            </DialogActions>
        </StyledDialog>
    );
}

export default observer(EvaDecisionOptionCompareDialog);
