import Axios from '../../axiosConfig';
import { BACKEND_URL } from '../../config';
import { configure, runInAction, makeAutoObservable } from 'mobx';
import CarouselImage from '../../types/carouselImage';

configure({ enforceActions: 'observed' });

export default class ImageModule {
    private activeImages: CarouselImage[] = [];
    private allImages: CarouselImage[] = [];

    constructor() {
        makeAutoObservable(this, {}, { autoBind: true });
    }

    public async fetchCarouselImages(): Promise<void> {
        const response = await Axios({
            method: 'GET',
            url: BACKEND_URL + 'app/getActiveCarouselImages',
        });
        runInAction(() => (this.activeImages = response.data));
    }

    public async getAllCarouselImages(): Promise<void> {
        const response = await Axios({
            method: 'GET',
            url: BACKEND_URL + 'app/uploadCarouselImage',
        });
        runInAction(() => (this.allImages = response.data));
    }

    public async uploadCarouselImages(orderNumber: number, fileData: string, redirectURL: string): Promise<void> {
        const reponse = await Axios({
            method: 'POST',
            url: BACKEND_URL + 'app/uploadCarouselImage',
            data: {
                orderNumber,
                fileData,
                redirectURL,
            },
        });
        const uploadedImage = reponse.data;
        runInAction(() => {
            this.allImages.push(uploadedImage);
        });
    }

    public async saveCarouselImage(orderNumber: number, redirectURL: string): Promise<void> {
        const response = await Axios({
            method: 'POST',
            url: BACKEND_URL + 'app/saveCarouselImages',
            data: {
                orderNumber,
                redirectURL,
            },
        });
        const savedImage: CarouselImage = response.data;
        const allImagesIndex = this.allImages.findIndex((img) => img.id === savedImage.id);
        const activeImagesIndex = this.activeImages.findIndex((img) => img.id === savedImage.id);

        runInAction(() => {
            if (allImagesIndex > -1) this.allImages[allImagesIndex] = savedImage;
            else this.allImages.push(savedImage);

            if (activeImagesIndex > -1) this.activeImages[activeImagesIndex] = savedImage;
            else this.activeImages.push(savedImage);
        });
    }

    public async deleteCarouselImage(orderNumber: number): Promise<void> {
        const response = await Axios({
            method: 'DELETE',
            url: BACKEND_URL + 'app/uploadCarouselImage?orderNumber=' + orderNumber,
        });
        const images: CarouselImage[] = response.data;
        runInAction(() => {
            this.allImages = images;
            this.activeImages = images.filter((img) => img.active);
        });
    }

    public async uploadBackgroundImage(file: File): Promise<void> {
        const form = new FormData();
        form.append('file', file);

        await Axios({
            method: 'POST',
            url: BACKEND_URL + 'app/uploadBackgroundImage',
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            data: form,
        });
    }

    public get activeCarouselImages(): CarouselImage[] {
        return this.activeImages;
    }

    public get allCarouselImages(): CarouselImage[] {
        return this.allImages;
    }
}
