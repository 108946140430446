import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from '@mui/material';
import { Delete, Info } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { FocusArea } from '../../../../types/document';
import DangerIconButton from '../../../../styled/DangerIconButton';
import ShvkButton from '../../../../styled/ShvkButton';
import useStore, { rootModule } from '../../../../store/storeContext';

const PREFIX = 'EditFocusAreaTableDialog';

const classes = {
    paper: `${PREFIX}-paper`,
    tableCell: `${PREFIX}-tableCell`,
    table: `${PREFIX}-table`,
    pointer: `${PREFIX}-pointer`,
};

const { theming } = rootModule;

const StyledDialog = styled(Dialog)(({ theme }) => ({
    [`& .${classes.paper}`]: {
        height: 'auto',
    },

    [`& .${classes.tableCell}`]: {
        border: '1px solid' + theming.shvkLightGrey,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(0.5),
    },

    [`& .${classes.table}`]: {
        // Texts in cells are in html-format. Remove margins and paddings from p-elements (every row is a <p>)
        '& p': {
            margin: 0,
            padding: 0,
        },
        wordBreak: 'break-word',
    },

    [`& .${classes.pointer}`]: {
        cursor: 'pointer',
    },
}));

interface Props {
    isOpen: boolean;
    close(): void;
    focusArea: FocusArea;
}

interface State {
    actionTitle: string;
    resourceTitle: string;
    evaluationGaugeTitle: string | null;
    additionalColumnByGoalTitle: string | null;
    additionalColumnByActionTitle: string | null;
    editingActionTitle: boolean;
    editingResourceTitle: boolean;
    editingEvaluationGaugeTitle: boolean;
    editingAdditionalColumnByGoalTitle: boolean;
    editingAdditionalColumnByActionTitle: boolean;
}

type Field =
    | 'actionTitle'
    | 'resourceTitle'
    | 'evaluationGaugeTitle'
    | 'additionalColumnByGoalTitle'
    | 'additionalColumnByActionTitle';
type EditingField =
    | 'editingActionTitle'
    | 'editingResourceTitle'
    | 'editingEvaluationGaugeTitle'
    | 'editingAdditionalColumnByGoalTitle'
    | 'editingAdditionalColumnByActionTitle';

function EditGoalDialog(props: Props) {
    const { document, localization, loadingIndicator, snackbar, theming } = useStore();

    const [state, setState] = useState<State>({
        actionTitle: '',
        resourceTitle: '',
        evaluationGaugeTitle: null,
        additionalColumnByGoalTitle: null,
        additionalColumnByActionTitle: null,
        editingActionTitle: false,
        editingResourceTitle: false,
        editingEvaluationGaugeTitle: false,
        editingAdditionalColumnByGoalTitle: false,
        editingAdditionalColumnByActionTitle: false,
    });

    useEffect(() => {
        props.isOpen && init();
    }, [props.isOpen]);

    function init(): void {
        const focusAreaTableTitles = document.currentDocument.focusAreaTableTitles;
        setState((state) => ({
            ...state,
            actionTitle: focusAreaTableTitles.actionTitle || '',
            resourceTitle: focusAreaTableTitles.resourceTitle || '',
            evaluationGaugeTitle: focusAreaTableTitles.evaluationGaugeTitle || null,
            additionalColumnByGoalTitle: focusAreaTableTitles.additionalColumnByGoalTitle || null,
            additionalColumnByActionTitle: focusAreaTableTitles.additionalColumnByActionTitle || null,
        }));
    }

    const handleTextChange = (value: string | null, field: Field): void => {
        setState((state) => ({
            ...state,
            [field]: value,
        }));
    };

    const toggleEdit = (field: EditingField): void => {
        setState((state) => ({
            ...state,
            [field]: !state[field],
        }));
    };

    async function save(): Promise<void> {
        const titles = {
            actionTitle: state.actionTitle,
            resourceTitle: state.resourceTitle,
            evaluationGaugeTitle: state.evaluationGaugeTitle,
            additionalColumnByGoalTitle: state.additionalColumnByGoalTitle,
            additionalColumnByActionTitle: state.additionalColumnByActionTitle,
        };
        try {
            loadingIndicator.show();
            await document.updateFocusAreaTableTitles(titles);
            snackbar.showSuccess();
        } catch (error) {
            snackbar.showError(error.data?.code);
        } finally {
            props.close();
            loadingIndicator.hide();
        }
    }

    const { translate } = localization;

    return (
        <StyledDialog
            id="edit-goal-table-dialog"
            open={props.isOpen}
            onClose={props.close}
            classes={{ paper: classes.paper }}
            maxWidth="md"
            fullWidth
        >
            <DialogTitle>
                <Box pt={1} display="flex">
                    <Info style={{ marginRight: theming.spacing(1) }} color="primary" />
                    <Typography variant="body2">{translate('DOCUMENT_FOCUS_AREA_TABLE_EDIT_TIP')}</Typography>
                </Box>
            </DialogTitle>
            <DialogContent>
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.tableCell} style={{ width: '16%' }}>
                                {translate('GOALS')}
                            </TableCell>
                            <TableCell
                                className={`${classes.tableCell} ${classes.pointer}`}
                                style={{ width: '16%' }}
                                onClick={() => toggleEdit('editingActionTitle')}
                            >
                                {state.editingActionTitle ? (
                                    <TextField
                                        autoFocus
                                        fullWidth
                                        size="small"
                                        onChange={(event): void => handleTextChange(event.target.value, 'actionTitle')}
                                        value={state.actionTitle}
                                        onBlur={() => toggleEdit('editingActionTitle')}
                                        onKeyDown={(event) => {
                                            if (event.key === 'Enter') toggleEdit('editingActionTitle');
                                        }}
                                    />
                                ) : (
                                    state.actionTitle
                                )}
                            </TableCell>
                            <TableCell
                                className={`${classes.tableCell} ${classes.pointer}`}
                                style={{ width: '16%' }}
                                onClick={() => toggleEdit('editingResourceTitle')}
                            >
                                {state.editingResourceTitle ? (
                                    <TextField
                                        autoFocus
                                        fullWidth
                                        size="small"
                                        onChange={(event): void =>
                                            handleTextChange(event.target.value, 'resourceTitle')
                                        }
                                        value={state.resourceTitle}
                                        onBlur={() => toggleEdit('editingResourceTitle')}
                                        onKeyDown={(event) => {
                                            if (event.key === 'Enter') toggleEdit('editingResourceTitle');
                                        }}
                                    />
                                ) : (
                                    state.resourceTitle
                                )}
                            </TableCell>
                            {state.evaluationGaugeTitle !== null && (
                                <TableCell
                                    className={`${classes.tableCell} ${classes.pointer}`}
                                    style={{ width: '16%' }}
                                    onClick={() => toggleEdit('editingEvaluationGaugeTitle')}
                                >
                                    {state.editingEvaluationGaugeTitle ? (
                                        <TextField
                                            autoFocus
                                            fullWidth
                                            size="small"
                                            onChange={(event): void =>
                                                handleTextChange(event.target.value, 'evaluationGaugeTitle')
                                            }
                                            value={state.evaluationGaugeTitle}
                                            onBlur={() => toggleEdit('editingEvaluationGaugeTitle')}
                                            onKeyDown={(event) => {
                                                if (event.key === 'Enter') toggleEdit('editingEvaluationGaugeTitle');
                                            }}
                                        />
                                    ) : (
                                        state.evaluationGaugeTitle
                                    )}
                                    {
                                        <DangerIconButton
                                            size="small"
                                            onClick={() => {
                                                toggleEdit('editingEvaluationGaugeTitle');
                                                handleTextChange(null, 'evaluationGaugeTitle');
                                            }}
                                        >
                                            <Delete />
                                        </DangerIconButton>
                                    }
                                </TableCell>
                            )}
                            {state.additionalColumnByActionTitle !== null ? (
                                <TableCell
                                    className={`${classes.tableCell} ${classes.pointer}`}
                                    style={{ width: '16%' }}
                                    onClick={() => toggleEdit('editingAdditionalColumnByActionTitle')}
                                >
                                    {state.editingAdditionalColumnByActionTitle ? (
                                        <TextField
                                            autoFocus
                                            fullWidth
                                            size="small"
                                            onChange={(event): void =>
                                                handleTextChange(event.target.value, 'additionalColumnByActionTitle')
                                            }
                                            value={state.additionalColumnByActionTitle}
                                            onBlur={() => toggleEdit('editingAdditionalColumnByActionTitle')}
                                            onKeyDown={(event) => {
                                                if (event.key === 'Enter')
                                                    toggleEdit('editingAdditionalColumnByActionTitle');
                                            }}
                                        />
                                    ) : (
                                        state.additionalColumnByActionTitle
                                    )}
                                    {
                                        <DangerIconButton
                                            size="small"
                                            onClick={() => {
                                                toggleEdit('editingAdditionalColumnByActionTitle');
                                                handleTextChange(null, 'additionalColumnByActionTitle');
                                            }}
                                        >
                                            <Delete />
                                        </DangerIconButton>
                                    }
                                </TableCell>
                            ) : null}
                            {state.additionalColumnByGoalTitle !== null ? (
                                <TableCell
                                    className={`${classes.tableCell} ${classes.pointer}`}
                                    style={{ width: '16%' }}
                                    onClick={() => toggleEdit('editingAdditionalColumnByGoalTitle')}
                                >
                                    {state.editingAdditionalColumnByGoalTitle ? (
                                        <TextField
                                            autoFocus
                                            fullWidth
                                            size="small"
                                            onChange={(event): void =>
                                                handleTextChange(event.target.value, 'additionalColumnByGoalTitle')
                                            }
                                            value={state.additionalColumnByGoalTitle}
                                            onBlur={() => toggleEdit('editingAdditionalColumnByGoalTitle')}
                                            onKeyDown={(event) => {
                                                if (event.key === 'Enter')
                                                    toggleEdit('editingAdditionalColumnByGoalTitle');
                                            }}
                                        />
                                    ) : (
                                        state.additionalColumnByGoalTitle
                                    )}
                                    {
                                        <DangerIconButton
                                            size="small"
                                            onClick={() => {
                                                toggleEdit('editingAdditionalColumnByGoalTitle');
                                                handleTextChange(null, 'additionalColumnByGoalTitle');
                                            }}
                                        >
                                            <Delete />
                                        </DangerIconButton>
                                    }
                                </TableCell>
                            ) : null}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.focusArea.goals?.map((goal) => (
                            <React.Fragment key={goal.id}>
                                <TableRow>
                                    <TableCell className={`${classes.tableCell}`} rowSpan={goal.actions.length + 1}>
                                        <Typography
                                            className="fr-view"
                                            dangerouslySetInnerHTML={{
                                                __html: goal.title || translate('GOAL_TITLE_EMPTY'),
                                            }}
                                        />
                                    </TableCell>
                                </TableRow>
                                {goal.actions.map((action, i) => (
                                    <TableRow key={action.id}>
                                        <TableCell className={classes.tableCell}>
                                            <Typography
                                                className="fr-view"
                                                dangerouslySetInnerHTML={{ __html: action.title }}
                                            />
                                        </TableCell>
                                        <TableCell className={classes.tableCell}>
                                            <Typography
                                                className="fr-view"
                                                dangerouslySetInnerHTML={{ __html: action.resources }}
                                            />
                                        </TableCell>
                                        {state.evaluationGaugeTitle !== null && (
                                            <TableCell className={classes.tableCell}>
                                                <Typography
                                                    className="fr-view"
                                                    dangerouslySetInnerHTML={{ __html: action.evaluationGauge }}
                                                />
                                            </TableCell>
                                        )}
                                        {state.additionalColumnByActionTitle !== null ? (
                                            <TableCell className={classes.tableCell}>
                                                <Typography
                                                    className="fr-view"
                                                    dangerouslySetInnerHTML={{ __html: action.additionalColumn }}
                                                />
                                            </TableCell>
                                        ) : null}
                                        {state.additionalColumnByGoalTitle !== null && i === 0 ? (
                                            // Additional goal title is the same for every action.
                                            // Render cell only once.
                                            <TableCell className={classes.tableCell} rowSpan={goal.actions.length}>
                                                <Typography
                                                    className="fr-view"
                                                    dangerouslySetInnerHTML={{ __html: goal.additionalColumn }}
                                                />
                                            </TableCell>
                                        ) : null}
                                    </TableRow>
                                ))}
                            </React.Fragment>
                        ))}
                    </TableBody>
                </Table>
            </DialogContent>
            <DialogActions>
                {state.evaluationGaugeTitle === null && (
                    <ShvkButton
                        sx={{ fontSize: '13px' }}
                        onClick={() =>
                            handleTextChange(translate('DEFAULT_TITLE_EVALUATION_GAUGE'), 'evaluationGaugeTitle')
                        }
                    >
                        {translate('ADD_COLUMN_FOR_FOCUS_AREA_TABLE_EVALUATION_GAUGE')}
                    </ShvkButton>
                )}
                {state.additionalColumnByGoalTitle === null && (
                    <ShvkButton
                        sx={{ fontSize: '13px' }}
                        onClick={() =>
                            handleTextChange(
                                translate('DEFAULT_TITLE_ADDITIONAL_COLUMN_BY_GOAL'),
                                'additionalColumnByGoalTitle',
                            )
                        }
                    >
                        {translate('ADD_COLUMN_FOR_FOCUS_AREA_TABLE_BY_GOAL')}
                    </ShvkButton>
                )}
                {state.additionalColumnByActionTitle === null && (
                    <ShvkButton
                        sx={{ fontSize: '13px' }}
                        onClick={() =>
                            handleTextChange(
                                translate('DEFAULT_TITLE_ADDITIONAL_COLUMN_BY_ACTION'),
                                'additionalColumnByActionTitle',
                            )
                        }
                    >
                        {translate('ADD_COLUMN_FOR_FOCUS_AREA_TABLE_BY_ACTION')}
                    </ShvkButton>
                )}
                <Box flexGrow={1} />
                <Button variant="contained" onClick={props.close}>
                    {translate('CLOSE')}
                </Button>
                <ShvkButton onClick={save}>{translate('SAVE')}</ShvkButton>
            </DialogActions>
        </StyledDialog>
    );
}

export default observer(EditGoalDialog);
