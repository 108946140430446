import { styled } from '@mui/material/styles';
import Button, { ButtonProps } from '@mui/material/Button';
import React from 'react';

const DangerButton = styled((props: ButtonProps) => <Button {...props} />)(({ theme }) => ({
    color: theme.palette.common.white,
    background: theme.palette.error.main,
    '&:hover': {
        backgroundColor: theme.palette.error.light,
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
            backgroundColor: theme.palette.error.main,
        },
    },
    '&:focus': {
        backgroundColor: theme.palette.error.light,
    },
}));

export default DangerButton;
