import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import Welcome from '../views/Welcome';
import Home from '../views/Home';
import Desktop from '../views/Desktop';
import NewsManagement from '../views/NewsManagement';
import Translations from '../views/Translations';
import ChapterTemplates from '../views/ChapterTemplates';
import AuditLog from '../views/AuditLog';
import ConfigData from '../views/ConfigData';
import License from '../views/License';
import Organizations from '../views/Organizations';
import HelpTexts from '../views/HelpTexts';
import DocumentSearch from '../views/DocumentSearch';
import UserManagement from '../views/UserManagement';
import MaterialManagement from '../views/MaterialManagement';
import EvaTemplateManagement from '../views/EvaTemplateManagement';
import Preview from '../views/Preview';

function AllRoutes() {
    return (
        <Routes>
            <Route path="/" element={<Welcome />}>
                <Route path="/index.html/forgot/password/:resetToken" element={<Welcome />} />
                <Route path="/index.html/account/activateandreset/:activateAndResetToken" element={<Welcome />} />
                <Route path="/index.html/document/share/:shareToken" element={<Welcome />} />
            </Route>
            <Route path="/home" element={<Home />} />
            <Route path="/desktop" element={<Desktop />} />
            <Route path="/newsmanagement" element={<NewsManagement />} />
            <Route path="/translations" element={<Translations />} />
            <Route path="/chaptertemplates" element={<ChapterTemplates />} />
            <Route path="/auditlog" element={<AuditLog />} />
            <Route path="/configdata" element={<ConfigData />} />
            <Route path="/license" element={<License />} />
            <Route path="/organizations" element={<Organizations />} />
            <Route path="/helptexts" element={<HelpTexts />} />
            <Route path="/documentsearch" element={<DocumentSearch />} />
            <Route path="/preview/:documentId" element={<Preview />} />
            <Route path="/usermanagement" element={<UserManagement />} />
            <Route path="/material" element={<MaterialManagement />} />
            <Route path="/evatemplates" element={<EvaTemplateManagement />} />
        </Routes>
    );
}

export default observer(AllRoutes);
