import React, { useState, useEffect } from 'react';
import { observer, useLocalObservable } from 'mobx-react-lite';
import { runInAction } from 'mobx';
import {
    Autocomplete,
    Box,
    Button,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    FormLabel,
    Grid,
    MenuItem,
    Tab,
    Tabs,
    Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { TabContext, TabPanel } from '@mui/lab';
import { TextFieldEvent } from '../../types/events';
import { LocalizedName } from '../../types/localization';
import Organization, {
    OrganizationCategory,
    OrganizationWithLicense,
    SaveOrganizationRequestParam,
} from '../../types/organization';
import LicenseSchema, { OrganizationLicense } from '../../types/license';
import ShvkButton from '../../styled/ShvkButton';
import DangerButton from '../../styled/DangerButton';
import SelectLicenseDialog from './SelectLicenseDialog';
import ShvkTextField from '../../styled/ShvkTextField';
import moment from 'moment';
import useStore from '../../store/storeContext';

const PREFIX = 'OrganizationItemDialog';

const classes = {
    paper: `${PREFIX}-paper`,
};

const Root = styled('div')({
    [`& .${classes.paper}`]: {
        minHeight: '615px',
    },
});

interface Props {
    open: boolean;
    onClose(): void;
    organization: OrganizationWithLicense | null;
    updateTable(savedOranization: OrganizationWithLicense): void;
}

type TabName = 'BASIC_INFO' | 'CONTACT_INFO' | 'LICENSE';

type OrganizationFormField =
    | 'nameFi'
    | 'nameSe'
    | 'nameEn'
    | 'shortNameFi'
    | 'shortNameSe'
    | 'shortNameEn'
    | 'category'
    | 'uri'
    | 'code'
    | 'latitude'
    | 'longitude';

type CollectionInput = {
    value: Organization[];
    inputValue: string;
};

interface State {
    activeTab: TabName;
    nameFi: string;
    nameSe: string;
    nameEn: string;
    shortNameFi: string;
    shortNameSe: string;
    shortNameEn: string;
    uri: string;
    code: string;
    latitude: string;
    longitude: string;
    active: boolean;
    selectLicenseDialogOpen: boolean;
    fetchingOrganizationsWithLicences: boolean;
    fetchingCollectionOrganizations: boolean;
}

interface StoreState {
    allOrganizations: OrganizationWithLicense[];
    collection: CollectionInput;
    collectionCodes: string[];
    collectionValue: Organization[];
    wellbeingCounties: OrganizationWithLicense[];
    setAllOrganizations: (organizations: OrganizationWithLicense[]) => void;
    initStoreState: () => void;
}

function OrganizationItemDialog(props: Props) {
    const { organization, dialog, admin, snackbar, loadingIndicator, localization } = useStore();

    const [state, setState] = useState<State>({
        activeTab: 'BASIC_INFO',
        nameFi: '',
        nameSe: '',
        nameEn: '',
        shortNameFi: '',
        shortNameSe: '',
        shortNameEn: '',
        uri: '',
        code: '',
        latitude: '',
        longitude: '',
        active: false,
        selectLicenseDialogOpen: false,
        fetchingOrganizationsWithLicences: false,
        fetchingCollectionOrganizations: false,
    });

    const [category, setCategory] = useState<OrganizationCategory>({} as OrganizationCategory);

    const [licenseSchema, setLicenseSchema] = useState<LicenseSchema | null>(null);

    const [organizationLicense, setOrganizationLicense] = useState<OrganizationLicense>({} as OrganizationLicense);

    const localStore = useLocalObservable<StoreState>(() => ({
        // List of all organizations in autocomplete.
        allOrganizations: [],
        // List of selected organizations in autocomplete, and the input value of the autocomplete text-field.
        collection: {
            value: [],
            inputValue: '',
        },

        get collectionCodes(): string[] {
            // Code list in store doesn't have the code of the selected organization,
            // which can be something like "OLD_1234".
            // We need to add the old code to the list,
            // or select component will give out-of-range warning.
            const codes = organization.collectionCodes.map((code) => code.toString());
            if (props.organization) codes.push(props.organization.code);
            return codes;
        },

        get collectionValue(): Organization[] {
            // Mobx will give error without this check.
            if (this.collection.value.length > 0) return this.collection.value;
            return [];
        },

        get wellbeingCounties(): OrganizationWithLicense[] {
            return this.allOrganizations.filter((org) => org.category.toString() === 'KUNTA');
        },

        setAllOrganizations(organizations: OrganizationWithLicense[]) {
            this.allOrganizations = organizations;
        },

        initStoreState(): void {
            this.allOrganizations = [];
            this.collection = {
                value: [],
                inputValue: '',
            };
        },
    }));

    useEffect(() => {
        void init();
    }, [props.open]);

    async function init(): Promise<void> {
        const category = organization.getOrganizationCategoryByName(props.organization?.category || '');

        setState({
            activeTab: 'BASIC_INFO',
            fetchingCollectionOrganizations: false,
            fetchingOrganizationsWithLicences: false,
            selectLicenseDialogOpen: false,
            nameFi: props.organization?.name.fi_FI || '',
            nameSe: props.organization?.name.sv_SE || '',
            nameEn: props.organization?.name.en_GB || '',
            shortNameFi: props.organization?.shortName?.fi_FI || '',
            shortNameSe: props.organization?.shortName?.sv_SE || '',
            shortNameEn: props.organization?.shortName?.en_GB || '',
            active: props.organization?.active || false,
            uri: props.organization?.uri || '',
            code: props.organization?.code || '',
            latitude: props.organization?.latitude.toString() || '',
            longitude: props.organization?.longitude.toString() || '',
        });

        localStore.initStoreState();

        setCategory(category || ({} as OrganizationCategory));

        setLicenseSchema(
            props.organization?.licenseSchema ? admin.getLicenseSchemaById(props.organization.licenseSchema) : null,
        );

        setOrganizationLicense(props.organization?.license || createEmptyLicense());

        if (props.organization?.isCollectionCategory) {
            await getCollectionOrganizations(props.organization?.id);
            await getOrganizationsWithLicenses();
        }
        if (props.organization?.isWellbeingCategory) {
            await getCollectionOrganizations(props.organization?.id);
            await getOrganizationsWithLicenses();
        }
    }

    async function getCollectionOrganizations(organizationId?: number): Promise<void> {
        setState((state) => ({ ...state, fetchingCollectionOrganizations: true }));

        if (!organizationId) {
            runInAction(() => {
                localStore.collection.value = [];
            });
            return;
        }
        try {
            const collection = await organization.fetchCollectionOrganizations(organizationId);
            runInAction(() => {
                localStore.collection.value = collection;
            });
        } catch (error) {
            snackbar.showFetchFailedMessage(error.data?.code);
        } finally {
            setState((state) => ({ ...state, fetchingCollectionOrganizations: false }));
        }
    }

    async function getOrganizationsWithLicenses(): Promise<void> {
        const filterOutFromOptions = ['EUROOPPA', 'ELY-KESKUS', 'EURALUEET'];
        setState((state) => ({ ...state, fetchingOrganizationsWithLicences: true }));
        try {
            const organizations = await organization.fetchOrganizationsWithLicenses();
            const filteredOrganizations = organizations.filter(
                (org) => org.sotkanetId && !filterOutFromOptions.includes(org.category.toString()),
            );
            localStore.setAllOrganizations(filteredOrganizations);
        } catch {
            snackbar.showFetchFailedMessage();
        } finally {
            setState((state) => ({ ...state, fetchingOrganizationsWithLicences: false }));
        }
    }

    async function getCollectionCodes(): Promise<void> {
        try {
            await organization.fetchAvailableCollectionCodes();
        } catch (error) {
            snackbar.showFetchFailedMessage(error.data?.code);
        }
    }

    async function removeLicense(): Promise<void> {
        const confirmation = await dialog.getConfirmation();
        if (!confirmation) return;
        setLicenseSchema(null);
        setOrganizationLicense(createEmptyLicense());
    }

    async function save(): Promise<void> {
        let orgLicense = organizationLicense;

        try {
            loadingIndicator.show();
            if (organizationLicense.beginDate && organizationLicense.endDate) {
                const beginDate = new Date(organizationLicense.beginDate).getTime();
                const endDate = new Date(organizationLicense.endDate).getTime();
                orgLicense = { ...organizationLicense, beginDate, endDate };
            }

            const name: LocalizedName = {} as LocalizedName;
            name.fi_FI = state.nameFi;
            name.sv_SE = state.nameSe;
            name.en_GB = state.nameEn;

            const shortName: LocalizedName = {} as LocalizedName;
            shortName.fi_FI = state.shortNameFi;
            shortName.sv_SE = state.shortNameSe;
            shortName.en_GB = state.shortNameEn;

            const organizationData: SaveOrganizationRequestParam = {
                id: props.organization?.id,
                active: state.active,
                category: category.name,
                code: state.code,
                latitude: Number(state.latitude),
                longitude: Number(state.longitude),
                name,
                shortName,
                uri: state.uri,
                license: licenseSchema?.id ? orgLicense : null,
                schema: licenseSchema?.id || 0,
                collectionOrganizations: localStore.collection.value,
            };

            const savedOrganization = await organization.saveOrganization(organizationData);
            snackbar.showSuccess();

            // Return new/updated organization to the organization view.
            props.updateTable(savedOrganization);
        } catch (error) {
            snackbar.showError(error.data?.code);
        } finally {
            props.onClose();
            loadingIndicator.hide();
        }
    }

    function addLicense(licenseSchemaId: number, startDate: string, endDate: string): void {
        setLicenseSchema(admin.getLicenseSchemaById(licenseSchemaId));

        const licenseTemplate = createEmptyLicense();
        licenseTemplate.beginDate = startDate;
        licenseTemplate.endDate = endDate;
        licenseTemplate.isExpired = admin.isLicenseExpired(endDate);
        licenseTemplate.daysLeft = admin.countDaysLeft(endDate);

        setOrganizationLicense(licenseTemplate);

        toggleSelectLicenseDialog();
    }

    const createEmptyLicense = (): OrganizationLicense => {
        return {
            beginDate: null,
            daysLeft: 0,
            endDate: null,
            isExpired: true,
            isStarted: false,
        };
    };

    const handleChange = (event: TextFieldEvent, field: OrganizationFormField): void => {
        if (field === 'category') {
            void handleCategoryChange(event.target.value);
            return;
        }
        setState((state) => ({ ...state, [field]: event.target.value }));
    };

    const handleCategoryChange = async (categoryName: string): Promise<void> => {
        const newCategory = organization.getOrganizationCategoryByName(categoryName);
        setCategory(newCategory || ({} as OrganizationCategory));

        if (newCategory?.isCollectionCategory) {
            await getCollectionCodes();
            await getOrganizationsWithLicenses();
        }
        if (newCategory?.isWellbeingCategory) {
            await getOrganizationsWithLicenses();
        }
    };

    const toggleSelectLicenseDialog = (): void => {
        setState((state) => ({ ...state, selectLicenseDialogOpen: !state.selectLicenseDialogOpen }));
    };

    const handleTabChange = (_event: React.ChangeEvent<unknown>, newIndex: TabName): void => {
        setState({ ...state, activeTab: newIndex });
    };

    const isSaveButtonDisabled = !(state.nameFi && state.code && category);
    const { translate } = localization;

    return (
        <Root>
            <Dialog
                open={props.open}
                onClose={props.onClose}
                aria-labelledby="organization-item-dialog-title"
                maxWidth={'sm'}
                fullWidth
                classes={classes}
            >
                <DialogTitle id="organization-item-dialog-title">{translate('ORGANIZATION')}</DialogTitle>
                <DialogContent dividers>
                    <TabContext value={state.activeTab}>
                        <Tabs
                            value={state.activeTab}
                            indicatorColor="primary"
                            textColor="primary"
                            onChange={handleTabChange}
                            variant="fullWidth"
                            aria-labelledby="organization-item-dialog-title"
                        >
                            <Tab label={translate('BASIC_INFO')} value="BASIC_INFO" />
                            <Tab label={translate('CONTACT_INFO')} value="CONTACT_INFO" />
                            <Tab label={translate('LICENSE')} value="LICENSE" />
                        </Tabs>
                        <TabPanel value={'BASIC_INFO'}>
                            <Grid container spacing={2} direction="column" alignItems="stretch">
                                <Grid item>
                                    <FormLabel component="legend">{translate('NAME')}</FormLabel>
                                </Grid>
                                <Grid item>
                                    <ShvkTextField
                                        required
                                        id="organization-name-finnish"
                                        label={translate('FI_TRANSLATION')}
                                        value={state.nameFi}
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        onChange={(event) => handleChange(event, 'nameFi')}
                                    />
                                </Grid>
                                <Grid item>
                                    <ShvkTextField
                                        id="organization-name-swedish"
                                        label={translate('SE_TRANSLATION')}
                                        value={state.nameSe}
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        onChange={(event) => handleChange(event, 'nameSe')}
                                    />
                                </Grid>
                                <Grid item>
                                    <ShvkTextField
                                        id="organization-name-english"
                                        label={translate('EN_TRANSLATION')}
                                        value={state.nameEn}
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        onChange={(event) => handleChange(event, 'nameEn')}
                                    />
                                </Grid>
                                <Grid item>
                                    <Box my={2} />
                                </Grid>
                                <Grid item>
                                    <FormLabel component="legend">{translate('SHORT_NAME')}</FormLabel>
                                </Grid>
                                <Grid item>
                                    <ShvkTextField
                                        required
                                        id="organization-name-finnish"
                                        label={translate('FI_TRANSLATION')}
                                        value={state.shortNameFi}
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        onChange={(event) => handleChange(event, 'shortNameFi')}
                                    />
                                </Grid>
                                <Grid item>
                                    <ShvkTextField
                                        id="organization-name-swedish"
                                        label={translate('SE_TRANSLATION')}
                                        value={state.shortNameSe}
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        onChange={(event) => handleChange(event, 'shortNameSe')}
                                    />
                                </Grid>
                                <Grid item>
                                    <ShvkTextField
                                        id="organization-name-english"
                                        label={translate('EN_TRANSLATION')}
                                        value={state.shortNameEn}
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        onChange={(event) => handleChange(event, 'shortNameEn')}
                                    />
                                </Grid>
                                <Grid item>
                                    <Box my={2} />
                                </Grid>
                                <Grid item>
                                    <ShvkTextField
                                        required
                                        select
                                        id="organization-category"
                                        value={category.name || ''}
                                        onChange={(event) => handleChange(event, 'category')}
                                        label={translate('CATEGORY')}
                                        variant="outlined"
                                        fullWidth
                                        size="small"
                                        disabled={
                                            props.organization?.isCollectionCategory ||
                                            props.organization?.isWellbeingCategory
                                        }
                                    >
                                        {organization.organizationCategories.map((category) => (
                                            <MenuItem key={category.id} value={category.name}>
                                                {category.name}
                                            </MenuItem>
                                        ))}
                                    </ShvkTextField>
                                </Grid>
                                {(category?.isCollectionCategory || category?.isWellbeingCategory) && (
                                    <Grid item>
                                        <Box pl={3}>
                                            <Autocomplete
                                                value={localStore.collectionValue}
                                                onChange={(_event, newArray: Organization[]): void => {
                                                    runInAction(() => {
                                                        localStore.collection.value = newArray;
                                                    });
                                                }}
                                                inputValue={localStore.collection.inputValue}
                                                onInputChange={(_event, newInputValue): void => {
                                                    runInAction(() => {
                                                        localStore.collection.inputValue = newInputValue;
                                                    });
                                                }}
                                                multiple
                                                filterSelectedOptions
                                                options={
                                                    category.isCollectionCategory
                                                        ? localStore.allOrganizations
                                                        : localStore.wellbeingCounties
                                                }
                                                getOptionLabel={(option: Organization): string =>
                                                    option.name[localization.locale] + ' (' + option.category + ')'
                                                }
                                                isOptionEqualToValue={(option, value): boolean =>
                                                    option.id === value.id
                                                }
                                                fullWidth
                                                size="small"
                                                loading={
                                                    state.fetchingOrganizationsWithLicences ||
                                                    state.fetchingCollectionOrganizations
                                                }
                                                renderInput={(params): JSX.Element => (
                                                    <ShvkTextField
                                                        {...params}
                                                        label={translate('COLLECTION_ORGANIZATIONS')}
                                                        variant="outlined"
                                                        placeholder={translate('SEARCH_WITH_NAME')}
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            endAdornment: (
                                                                <React.Fragment>
                                                                    {(state.fetchingOrganizationsWithLicences ||
                                                                        state.fetchingCollectionOrganizations) && (
                                                                        <CircularProgress size={20} />
                                                                    )}
                                                                    {params.InputProps.endAdornment}
                                                                </React.Fragment>
                                                            ),
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Box>
                                    </Grid>
                                )}
                                <Grid item>
                                    <FormControlLabel
                                        label={translate('ACTIVATED')}
                                        control={
                                            <Checkbox
                                                checked={state.active}
                                                onChange={(event): void =>
                                                    setState((state) => ({ ...state, active: event.target.checked }))
                                                }
                                                color="primary"
                                            />
                                        }
                                    />
                                </Grid>
                            </Grid>
                        </TabPanel>
                        <TabPanel value={'CONTACT_INFO'}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <ShvkTextField
                                        label={translate('URI')}
                                        value={state.uri}
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        onChange={(event) => handleChange(event, 'uri')}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    {category?.isCollectionCategory ? (
                                        <ShvkTextField
                                            required
                                            select
                                            label={translate('ORGANIZATION_CODE')}
                                            value={state.code}
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                            disabled={Boolean(props.organization)}
                                            onChange={(event) => handleChange(event, 'code')}
                                        >
                                            {localStore.collectionCodes.map((code) => (
                                                <MenuItem key={code} value={code}>
                                                    {code}
                                                </MenuItem>
                                            ))}
                                        </ShvkTextField>
                                    ) : (
                                        <ShvkTextField
                                            required
                                            label={translate('MUNICIPALITY_CODE')}
                                            value={state.code}
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                            onChange={(event) => handleChange(event, 'code')}
                                        />
                                    )}
                                </Grid>
                                {props.organization?.sotkanetId && (
                                    <Grid item xs={12}>
                                        <ShvkTextField
                                            label={translate('SOTKANET_ID')}
                                            value={props.organization.sotkanetId}
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                            disabled
                                        />
                                    </Grid>
                                )}
                                <Grid item xs={12}>
                                    <Box my={2} />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormLabel component="legend">{translate('LOCATION')}</FormLabel>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <ShvkTextField
                                        label={translate('LATITUDE')}
                                        value={state.latitude}
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        onChange={(event) => handleChange(event, 'latitude')}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <ShvkTextField
                                        label={translate('LONGITUDE')}
                                        value={state.longitude}
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        onChange={(event) => handleChange(event, 'longitude')}
                                    />
                                </Grid>
                            </Grid>
                        </TabPanel>
                        <TabPanel value={'LICENSE'}>
                            <Grid justifyContent="space-between" container spacing={2}>
                                <Grid item xs={10}>
                                    <Typography variant="h6">{translate('LICENSE_EXPIRES')}</Typography>
                                    {organizationLicense.beginDate && organizationLicense.endDate ? (
                                        <Typography>
                                            {moment(organizationLicense.beginDate).format('DD.MM.YYYY') +
                                                ' - ' +
                                                moment(organizationLicense.endDate).format('DD.MM.YYYY')}
                                        </Typography>
                                    ) : (
                                        <Typography>{translate('LICENSE_NOT_VALID')}</Typography>
                                    )}
                                    <Typography>
                                        {translate('LICENSE_VALID_DAYS_LEFT')}: {organizationLicense.daysLeft}
                                    </Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    {organizationLicense.isExpired ? (
                                        <ShvkButton onClick={toggleSelectLicenseDialog}>{translate('ADD')}</ShvkButton>
                                    ) : (
                                        <DangerButton variant="contained" onClick={removeLicense}>
                                            {translate('REMOVE')}
                                        </DangerButton>
                                    )}
                                </Grid>
                                {licenseSchema && (
                                    <Grid item xs={12}>
                                        <Typography variant="h6">
                                            {translate('LICENSE_INFORMATION')}
                                            {organizationLicense.isExpired && (
                                                <Typography variant="caption">
                                                    {' (' + translate('LICENSE_NOT_VALID') + ')'}
                                                </Typography>
                                            )}
                                        </Typography>
                                        <Typography>{licenseSchema.description}</Typography>
                                        <Typography>
                                            {translate('LICENSE_YEARLY_REPORT_AMOUNT')}: {licenseSchema.yearlyReport}
                                        </Typography>
                                        <Typography>
                                            {translate('LICENSE_LARGE_REPORT_AMOUNT')}: {licenseSchema.largeReport}
                                        </Typography>
                                        <Typography>
                                            {translate('LICENSE_EVA_REPORT_AMOUNT')}: {licenseSchema.eva}
                                        </Typography>
                                        <Typography>
                                            {translate('LICENSE_EVA_MINI_REPORT_AMOUNT')}: {licenseSchema.evaMini}
                                        </Typography>
                                    </Grid>
                                )}
                            </Grid>
                        </TabPanel>
                    </TabContext>
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.onClose} variant="contained">
                        {translate('CLOSE')}
                    </Button>
                    <Box flexGrow={1} />
                    <ShvkButton onClick={save} disabled={isSaveButtonDisabled}>
                        {translate('SAVE')}
                    </ShvkButton>
                </DialogActions>
            </Dialog>
            <SelectLicenseDialog
                onClose={toggleSelectLicenseDialog}
                open={state.selectLicenseDialogOpen}
                addLicense={addLicense}
            />
        </Root>
    );
}

export default observer(OrganizationItemDialog);
