import React from 'react';
import { observer } from 'mobx-react-lite';
import TransferListItem from '../../types/transferList';
import { Card, CardHeader, Checkbox, Divider, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import useStore from '../../store/storeContext';

interface Props {
    sideItems: TransferListItem[];
    pageItems: TransferListItem[];
    checked: TransferListItem[];
    handleToggleAll: (items: TransferListItem[], numberOfChecked: number) => void;
    handleToggle: (item: TransferListItem) => void;
}

function TransferListItems(props: Props) {
    const { theming, localization } = useStore();

    const { translate } = localization;
    const { sideItems, pageItems, checked, handleToggleAll, handleToggle } = props;

    const sideCheckedCount: number = checked.filter((value) => sideItems.indexOf(value) !== -1).length;
    const totalCheckedCount: number = checked.filter((value) => pageItems.indexOf(value) !== -1).length;

    return (
        <Card variant="outlined">
            <CardHeader
                style={{ padding: `${theming.spacing(1)} ${theming.spacing(2)}` }}
                avatar={
                    <Checkbox
                        onClick={(): void => handleToggleAll(pageItems, totalCheckedCount)}
                        checked={totalCheckedCount === pageItems.length && pageItems.length !== 0}
                        indeterminate={totalCheckedCount !== pageItems.length && totalCheckedCount !== 0}
                        disabled={pageItems.length === 0}
                    />
                }
                title={translate('CHOICES')}
                subheader={`${sideCheckedCount}/${sideItems.length} ${translate('SELECTED')}`}
            />
            <Divider />
            <List dense component="div" role="list" style={{ height: 300, overflow: 'auto' }}>
                {pageItems.map((item: TransferListItem) => {
                    const labelId = `transfer-list-all-item-${item.title}-label`;

                    return (
                        <ListItemButton key={item.title} role="listitem" onClick={(): void => handleToggle(item)}>
                            <ListItemIcon>
                                <Checkbox
                                    checked={checked.some((i) => i.title === item.title)}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{ 'aria-labelledby': labelId }}
                                />
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={item.title} secondary={item.subtitle} />
                        </ListItemButton>
                    );
                })}
            </List>
        </Card>
    );
}

export default observer(TransferListItems);
