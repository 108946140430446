import React from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

const publicRoutes: Readonly<Array<string>> = ['/', '/preview'];

function AuthenticatedRoute() {
    const token = localStorage.getItem('shvkAuthToken');
    const location = useLocation();

    const isAuthenticatedOrPublicRoute = () => {
        const isAuthenticated = Boolean(token);
        const isPublicRoute =
            publicRoutes.includes(location.pathname) ||
            location.pathname.includes('/index.html/account/activateandreset/') ||
            location.pathname.includes('/index.html/forgot/password/') ||
            location.pathname.includes('/index.html/document/share/') ||
            location.pathname.includes('/preview/');
        return isAuthenticated || isPublicRoute;
    };

    return <>{!isAuthenticatedOrPublicRoute() && <Navigate to={'/'} replace={true} />}</>;
}

export default observer(AuthenticatedRoute);
