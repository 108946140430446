import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@mui/material';
import { TextFieldEvent } from '../../types/events';
import ShvkButton from '../../styled/ShvkButton';
import ShvkTextField from '../../styled/ShvkTextField';
import { useNavigate } from 'react-router-dom';
import useStore from '../../store/storeContext';

interface Props {
    open: boolean;
    token: string;
    title: string;
}

interface State {
    password: string;
    password2: string;
    showError: boolean;
}

type PasswordFormField = 'password' | 'password2';

function ResetPasswordDialog(props: Props) {
    const { user, snackbar, localization, dialog, loadingIndicator } = useStore();

    const navigate = useNavigate();

    const [state, setState] = useState<State>({
        password: '',
        password2: '',
        showError: false,
    });

    useEffect(() => {
        init();
    }, [props.open]);

    const init = (): void => {
        state.password = '';
        state.password2 = '';
        state.showError = false;
    };

    const handleChange = (event: TextFieldEvent, field: PasswordFormField): void => {
        setState((state) => ({ ...state, [field]: event.target.value }));
    };

    async function save(): Promise<void> {
        if (user.validatePassword(state.password) && user.checkPasswordMatch(state.password, state.password2)) {
            try {
                loadingIndicator.show();
                await user.resetPasswordWithToken(props.token, state.password, state.password2);
                snackbar.showSuccess();
                close();
                dialog.openLoginDialog();
            } catch (error) {
                snackbar.showError(error.data?.code);
            } finally {
                loadingIndicator.hide();
            }
        } else {
            state.showError = true;
            if (!user.validatePassword(state.password)) {
                snackbar.showError('PASSWORD_FORMAT_TIP');
            } else {
                snackbar.showError('PASSWORD_MISMATCH');
            }
        }
    }

    const close = (): void => {
        navigate('/');
        dialog.closeResetPasswordDialog();
    };

    const { translate } = localization;

    return (
        <Dialog
            open={props.open}
            onClose={close}
            aria-labelledby="reset_password"
            maxWidth={'md'}
            fullWidth
            sx={{ height: 'auto' }}
        >
            <DialogTitle id="reset_password" sx={{ pb: 1 }}>
                {props.title}
            </DialogTitle>
            <DialogContent dividers>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography>{translate('RESET_PASSWORD')}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <ShvkTextField
                            required
                            autoFocus
                            label={translate('PASSWORD')}
                            variant="outlined"
                            size="small"
                            type="password"
                            fullWidth
                            onChange={(event): void => handleChange(event, 'password')}
                            value={state.password}
                            autoComplete="new-password"
                            error={state.showError && !user.validatePassword(state.password)}
                            helperText={translate('PASSWORD_FORMAT_TIP')}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <ShvkTextField
                            required
                            label={translate('RE_ENTER_PASSWORD')}
                            variant="outlined"
                            size="small"
                            type="password"
                            fullWidth
                            onChange={(event): void => handleChange(event, 'password2')}
                            onKeyDown={(event): void => {
                                if (event.key === 'Enter') void save();
                            }}
                            value={state.password2}
                            autoComplete="new-password"
                            error={state.showError && !user.validatePassword(state.password2)}
                            helperText={
                                !user.checkPasswordMatch(state.password, state.password2)
                                    ? translate('PASSWORD_MISMATCH')
                                    : ''
                            }
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={close} variant="contained">
                    {translate('CANCEL')}
                </Button>
                <Box flexGrow={1} />
                <ShvkButton onClick={save}>{translate('SAVE')}</ShvkButton>
            </DialogActions>
        </Dialog>
    );
}

export default observer(ResetPasswordDialog);
