import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormLabel,
    MenuItem,
    Typography,
} from '@mui/material';
import { Share } from '@mui/icons-material';
import { SharedDataOptions, ShareDocumentParams } from '../../../types/document';
import { TextFieldEvent } from '../../../types/events';
import { TranslationKeys } from '../../../types/translation';
import ShvkTextField from '../../../styled/ShvkTextField';
import ShvkButton from '../../../styled/ShvkButton';
import moment from 'moment';
import useStore from '../../../store/storeContext';

interface Props {
    isOpen: boolean;
    close(): void;
}

interface State {
    addresses: string;
    validDate: string;
    sharedData: SharedDataOptions;
    shareVersion: number;
    emailGroupId: number | null;
    fetchingEmailGroups: boolean;
    fetchingPreviousShares: boolean;
}

type FormField = 'addresses' | 'emailGroupId' | 'sharedData' | 'shareVersion' | 'validDate';

function ShareDialog(props: Props) {
    const { document, user, dialog, snackbar, localization, theming, loadingIndicator } = useStore();

    const [state, setState] = useState<State>({
        addresses: '',
        validDate: '',
        sharedData: 'SHARE_TEXT_ONLY',
        shareVersion: 1,
        emailGroupId: null,
        fetchingEmailGroups: false,
        fetchingPreviousShares: false,
    });

    useEffect(() => {
        if (props.isOpen) {
            init();
            void getData();
        }
    }, [props.isOpen]);

    function init(): void {
        setState((state) => ({
            ...state,
            addresses: '',
            validDate: moment().endOf('day').format('YYYY-MM-DD'),
            sharedData: 'SHARE_TEXT_ONLY',
            shareVersion: 1,
            emailGroupId: null,
        }));
    }

    async function getData(): Promise<void> {
        try {
            setState((state) => ({
                ...state,
                fetchingEmailGroups: true,
                fetchingPreviousShares: true,
            }));
            await user.fetchEmailGroupsByCreator();
            await document.fetchPreviousDocumentShares();
        } catch (error) {
            snackbar.showFetchFailedMessage(error.data?.code);
        } finally {
            setState((state) => ({
                ...state,
                fetchingEmailGroups: false,
                fetchingPreviousShares: false,
            }));
        }
    }

    const handleChange = (event: TextFieldEvent, field: FormField): void => {
        switch (field) {
            case 'emailGroupId':
            case 'shareVersion':
                setState((state) => ({
                    ...state,
                    [field]: Number(event.target.value),
                }));
                break;
            case 'sharedData':
                setState((state) => ({
                    ...state,
                    sharedData: event.target.value as SharedDataOptions,
                }));
                break;
            default:
                setState((state) => ({
                    ...state,
                    [field]: event.target.value,
                }));
                break;
        }
    };

    async function share(): Promise<void> {
        const { validateUserName } = user;

        // Current behavior is to take email addresses from both lists (email group and email list from input).
        // If only one should be used at a time, the other input should be disabled and cleared.
        let emailGroupUsers: string[] = [];
        let addressListUsers: string[] = [];

        if (state.emailGroupId) {
            const emailGroup = user.emailGroups.find((group) => group.id === state.emailGroupId);
            if (emailGroup) {
                emailGroupUsers = emailGroup.addresses.split(',');
            }
        }
        if (state.addresses) addressListUsers = state.addresses.split(',');

        const allUsers = [...addressListUsers, ...emailGroupUsers];

        // Check that email addresses are valid
        for (const user of allUsers) {
            if (!validateUserName(user)) {
                snackbar.showError('MAIL_SEND_ERROR');
                return;
            }
        }

        if (allUsers.length === 0) {
            snackbar.showError('MAIL_SEND_ERROR');
            return;
        }

        try {
            loadingIndicator.show();
            const params: ShareDocumentParams = {
                distributorId: user.userDetails!.id,
                documentId: document.currentDocument.id,
                documentVersion: state.shareVersion,
                emails: allUsers.join(','),
                sharedInfo: state.sharedData,
                valid: new Date(state.validDate),
            };

            const response = await document.shareDocument(params);
            snackbar.showSuccess(response?.code as keyof TranslationKeys);
            props.close();
        } catch (error) {
            snackbar.showError(error.data?.code);
        } finally {
            //runInAction(() => (fetchingEmailGroups = false));
            loadingIndicator.hide();
        }
    }

    const { translate } = localization;
    const { currentDocument } = document;
    const isEva = document.isEvaDocument(document.currentDocument);

    return (
        <Dialog
            open={props.isOpen}
            onClose={props.close}
            aria-labelledby="share-dialog-title"
            maxWidth={'sm'}
            fullWidth
        >
            <DialogTitle id="share-dialog-title">{translate('DOCUMENT_SHARE_TITLE')}</DialogTitle>
            <DialogContent dividers>
                <Box mb={4}>
                    <Typography>
                        {translate('DOCUMENT_TO_BE_SHARED')}: {currentDocument.name}
                    </Typography>
                </Box>
                <Box mb={1}>
                    <FormLabel component="legend">{translate('DOCUMENT_SHARE_SUBTITLE')}</FormLabel>
                </Box>
                <ShvkTextField
                    label={translate('EMAIL')}
                    variant="outlined"
                    size="small"
                    fullWidth
                    placeholder={translate('EMAIL_PLACEHOLDER')}
                    value={state.addresses}
                    onChange={(event): void => handleChange(event, 'addresses')}
                    style={{ marginBottom: theming.theme.spacing(3) }}
                />
                <Box mb={1}>
                    <FormLabel component="legend">{translate('DOCUMENT_SHARE_GROUP_SUBTITLE')}</FormLabel>
                </Box>
                <ShvkTextField
                    select
                    value={state.emailGroupId || ''}
                    onChange={(event): void => handleChange(event, 'emailGroupId')}
                    label={translate('EMAIL_GROUP')}
                    variant="outlined"
                    fullWidth
                    style={{ marginBottom: theming.theme.spacing(3) }}
                    size="small"
                    InputProps={{
                        endAdornment: state.fetchingEmailGroups ? <CircularProgress size={20} /> : null,
                    }}
                >
                    {user.emailGroups.map((emailGroup) => (
                        <MenuItem key={emailGroup.id} value={emailGroup.id}>
                            {emailGroup.name}
                        </MenuItem>
                    ))}
                </ShvkTextField>
                <Box mb={1}>
                    <FormLabel component="legend">{translate('DEFINE_SHARED_DOCUMENT_DATA')}</FormLabel>
                </Box>
                <ShvkTextField
                    select
                    value={state.sharedData}
                    onChange={(event): void => handleChange(event, 'sharedData')}
                    label={translate('DOCUMENT_INFORMATION_SHARED_TIP')}
                    variant="outlined"
                    fullWidth
                    style={{ marginBottom: theming.theme.spacing(3) }}
                    size="small"
                >
                    <MenuItem value={'SHARE_TEXT_ONLY'}>{translate('SHARE_TEXT_ONLY')}</MenuItem>
                    {!isEva && <MenuItem value={'SHARE_INDICATORS'}>{translate('SHARE_INDICATORS')}</MenuItem>}
                    {!isEva && <MenuItem value={'SHARE_SUMMARY'}>{translate('SHARE_SUMMARY')}</MenuItem>}
                </ShvkTextField>
                <ShvkTextField
                    select
                    value={state.shareVersion}
                    onChange={(event): void => handleChange(event, 'shareVersion')}
                    label={translate('DOCUMENT_SHARE_VERSION_TITLE')}
                    variant="outlined"
                    fullWidth
                    style={{ marginBottom: theming.theme.spacing(3) }}
                    size="small"
                >
                    <MenuItem value={1}>{translate('SHARE_DOCUMENT_VERSION_OPTION_PDF')}</MenuItem>
                    <MenuItem value={2}>{translate('SHARE_DOCUMENT_VERSION_OPTION_ONLINE')}</MenuItem>
                </ShvkTextField>
                <Box mb={1}>
                    <FormLabel component="legend">{translate('DEFINE_SHARED_DOCUMENT_VALID_TIME')}</FormLabel>
                </Box>
                <ShvkTextField
                    type="date"
                    variant="outlined"
                    size="small"
                    value={state.validDate}
                    fullWidth
                    style={{ marginBottom: theming.theme.spacing(3) }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onChange={(event): void => handleChange(event, 'validDate')}
                />
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={props.close}>
                    {translate('CANCEL')}
                </Button>
                <Box flexGrow={1} />
                <Button
                    variant="outlined"
                    disabled={state.fetchingPreviousShares}
                    onClick={(): void => dialog.openDocumentDialog('previousSharesDialog')}
                >
                    {translate('SHOW_DOCUMENT_SHARES_ACTION')}
                </Button>
                <ShvkButton startIcon={<Share />} onClick={share}>
                    {translate('SHARE')}
                </ShvkButton>
            </DialogActions>
        </Dialog>
    );
}

export default observer(ShareDialog);
