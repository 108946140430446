import React, { useEffect, useState } from 'react';
import { observer, useLocalObservable } from 'mobx-react-lite';
import {
    Autocomplete,
    Box,
    Container,
    createFilterOptions,
    Divider,
    FormControlLabel,
    FormLabel,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Paper,
    Radio,
    RadioGroup,
    Tooltip,
    Typography,
    Pagination,
    ListItemButton,
} from '@mui/material';
import { Info } from '@mui/icons-material';
import { TranslationKeys } from '../types/translation';
import { TextFieldEvent } from '../types/events';
import ChapterTemplate from '../types/chapterTemplate';
import AddChapterTemplatesDialog from '../components/chapterTemplates/AddChapterTemplatesDialog';
import ShvkTextField from '../styled/ShvkTextField';
import ShvkButton from '../styled/ShvkButton';
import useStore from '../store/storeContext';

interface State {
    dialogOpen: boolean;
    selectedChapterTemplate: ChapterTemplate | null;
}

interface StoreState {
    page: number;
    selectedDocTypeFilter: string;
    chaptersByDocumentType: ChapterTemplate[];
    pageContent: ChapterTemplate[];
    pageCount: number;
    handlePageChange: <T>(_e: React.ChangeEvent<T>, value: number) => void;
    handleChange: (e: TextFieldEvent) => void;
}

const itemsPerPage: Readonly<number> = 5;

function ChapterTemplates() {
    const { chapterTemplate, localization, snackbar } = useStore();

    const [state, setState] = useState<State>({
        dialogOpen: false,
        selectedChapterTemplate: null,
    });

    const localStore = useLocalObservable<StoreState>(() => ({
        page: 1,
        selectedDocTypeFilter: '',

        // Filter method for radio buttons.
        get chaptersByDocumentType(): ChapterTemplate[] {
            const chapters = chapterTemplate.chapterTemplates;
            if (this.selectedDocTypeFilter !== '') {
                return chapters
                    .filter((chapter) => chapter.type === this.selectedDocTypeFilter)
                    .sort((a, b) => (a.order > b.order ? 1 : -1));
            } else {
                return chapters;
            }
        },

        get pageContent(): ChapterTemplate[] {
            const pageFirstIndex = (this.page - 1) * itemsPerPage;
            return this.chaptersByDocumentType.slice(pageFirstIndex, pageFirstIndex + itemsPerPage);
        },

        get pageCount(): number {
            return Math.ceil(this.chaptersByDocumentType.length / itemsPerPage);
        },

        handlePageChange(_event: React.ChangeEvent<unknown>, value: number): void {
            this.page = value;
        },

        handleChange(event: TextFieldEvent): void {
            this.selectedDocTypeFilter = event.target.value as string;
            this.page = 1;
        },
    }));

    useEffect(() => {
        void getData();
    }, []);

    async function getData(): Promise<void> {
        try {
            await chapterTemplate.fetchAvailableDocTypes();
            await chapterTemplate.fetchAllChapterTemplates();
        } catch {
            snackbar.showFetchFailedMessage();
        }
    }

    const openDialog = (chapterTemplate: ChapterTemplate | null): void => {
        setState((state) => ({ ...state, selectedChapterTemplate: chapterTemplate, dialogOpen: true }));
    };

    const closeDialog = (): void => {
        setState((state) => ({ ...state, dialogOpen: false }));
    };

    // Allow searching by SID and title (any language)
    const filterOptions = createFilterOptions({
        stringify: (template: ChapterTemplate) => {
            return template.sid + Object.values(template.title).toString();
        },
    });

    const translate = localization.translate;

    return (
        <>
            <Container>
                <Box my={3}>
                    <Paper>
                        <Box px={2} py={1} textAlign="center">
                            <Typography variant="h6">{translate('MODELTEMPLATES')}</Typography>
                        </Box>
                        <Divider />
                        <Box m={2}>
                            <Autocomplete
                                id="chapter-template-search"
                                options={chapterTemplate.chapterTemplates}
                                getOptionLabel={(template: ChapterTemplate): string => template.sid}
                                onChange={(_event, template): void => {
                                    if (template) openDialog(template as ChapterTemplate);
                                }}
                                fullWidth
                                size="small"
                                selectOnFocus
                                clearOnBlur
                                filterOptions={filterOptions}
                                renderInput={(params): JSX.Element => (
                                    <ShvkTextField
                                        {...params}
                                        label={translate('SEARCH_FOR_DOCUMENT_TEMPLATE')}
                                        variant="outlined"
                                    />
                                )}
                            />
                            <Box p={1} my={2}>
                                <FormLabel component="legend">{translate('FILTERING')}</FormLabel>
                                <RadioGroup
                                    row
                                    aria-label={translate('FILTERING')}
                                    value={localStore.selectedDocTypeFilter}
                                    onChange={localStore.handleChange}
                                >
                                    <FormControlLabel value="" control={<Radio />} label={translate('ALL')} />
                                    {chapterTemplate.documentTypes.map((docType) => (
                                        <FormControlLabel
                                            key={docType.id}
                                            value={docType.code}
                                            control={<Radio />}
                                            label={translate(docType.code as keyof TranslationKeys)}
                                        />
                                    ))}
                                </RadioGroup>
                            </Box>
                            <List aria-label={translate('MODELTEMPLATES')}>
                                {localStore.pageContent.map((template) => (
                                    <ListItem key={template.id} onClick={(): void => openDialog(template)}>
                                        <ListItemButton>
                                            <ListItemText
                                                primary={template.title[localization.locale]}
                                                secondary={template.sid}
                                            />
                                            {template.tip[localization.locale] && (
                                                <ListItemIcon>
                                                    <Tooltip arrow title={template.tip[localization.locale]}>
                                                        <Info fontSize="small" color="primary" />
                                                    </Tooltip>
                                                </ListItemIcon>
                                            )}
                                        </ListItemButton>
                                    </ListItem>
                                ))}
                            </List>
                        </Box>
                        <Box px={2} pb={2} display="flex">
                            <Pagination
                                count={localStore.pageCount}
                                page={localStore.page}
                                onChange={localStore.handlePageChange}
                                color="primary"
                            />
                            <Box flexGrow={1} />
                            <ShvkButton onClick={(): void => openDialog(null)}>{translate('ADD')}</ShvkButton>
                        </Box>
                    </Paper>
                </Box>
            </Container>
            <AddChapterTemplatesDialog
                open={state.dialogOpen}
                onClose={closeDialog}
                chapterTemplate={state.selectedChapterTemplate}
            />
        </>
    );
}

export default observer(ChapterTemplates);
