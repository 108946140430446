import Axios from '../../axiosConfig';
import { BACKEND_URL } from '../../config';
import { configure, runInAction, makeAutoObservable } from 'mobx';
import User, {
    RegisterForm,
    UserDetails,
    UserManagementUser,
    UserManagementUserSaveRequestParams,
} from '../../types/user';
import { UserOrganization } from '../../types/organization';
import EmailGroup from '../../types/emailGroup';
import RootModule from '../rootModule';

configure({ enforceActions: 'observed' });

export default class UserModule {
    private rootModule: RootModule;
    private userData: UserDetails | null = null;
    private userManagementUserList: UserManagementUser[] = [];
    private emailGroupsByCreator: EmailGroup[] = [];
    private organizationUsers: User[] = []; // Current organization users. Used in current document.

    constructor(rootModule: RootModule) {
        makeAutoObservable(this, {}, { autoBind: true });
        this.rootModule = rootModule;
    }

    public async fetchUserDetails(): Promise<void> {
        try {
            this.rootModule.loadingIndicator.showLogin();
            const response = await Axios({
                method: 'GET',
                url: BACKEND_URL + 'user/getDetails',
            });

            const details: UserDetails = response.data;
            details.selectedOrganization = details.homeOrganization;
            runInAction(() => (this.userData = details));
        } finally {
            this.rootModule.loadingIndicator.hideLogin();
        }
    }

    public async fetchSelectedOrganizationUserDetails(organizationId: number): Promise<void> {
        const response = await Axios({
            method: 'POST',
            url: BACKEND_URL + 'user/getSelectedOrganizationUserDetails',
            data: { organizationId },
        });
        const details: UserDetails = response.data;
        if (!details.selectedOrganization?.name) {
            details.selectedOrganization = details.homeOrganization;
        }
        runInAction(() => (this.userData = details));
    }

    public async fetchCurrentDocumentOrganizationUsers(): Promise<void> {
        const response = await Axios({
            method: 'POST',
            url: BACKEND_URL + 'organization/getOrganizationUsers',
            data: { organizationId: this.rootModule.document.currentDocument.organization.id },
        });
        runInAction(() => (this.organizationUsers = response.data));
    }

    public get currentDocumentOrganizationUsers(): User[] {
        return this.organizationUsers;
    }

    public async changeUserInfo(
        password?: string,
        selectedOrganizationId?: number,
        homeOrganizationId?: number,
    ): Promise<void> {
        const response = await Axios({
            method: 'POST',
            url: BACKEND_URL + 'user/changeUserInfo',
            data: {
                homeOrganizationId: homeOrganizationId || this.userData?.homeOrganization.id,
                selectedOrganizationId: selectedOrganizationId || '',
                password,
            },
        });
        if (this.userData && response.data) runInAction(() => (this.userData!.homeOrganization = response.data));
    }

    public async removeUserSelectedOrganization(): Promise<void> {
        await Axios({
            method: 'POST',
            url: BACKEND_URL + 'user/removeUserSelectedOrganization',
        });
    }

    public async fetchAllUserManagementOrganizationsAndUsers(): Promise<void> {
        const response = await Axios({
            method: 'GET',
            url: BACKEND_URL + 'organization/allOrganizationsAndUsers',
        });
        const users = response.data[1];
        const organizations = response.data[0];
        const userManagementUser = UserModule.mapIsAdminToUserManagementUsers(users);
        runInAction(() => {
            this.rootModule.organization.setAdminOrganizationList(organizations);
            this.userManagementUserList = userManagementUser;
        });
    }

    public async fetchEmailGroupsByCreator(): Promise<void> {
        const response = await Axios({
            method: 'GET',
            url: BACKEND_URL + 'user/getEmailGroupsByCreator',
        });
        runInAction(() => (this.emailGroupsByCreator = response.data));
    }

    public async saveEmailGroup(emailGroup: EmailGroup): Promise<void> {
        const response = await Axios({
            method: 'POST',
            url: BACKEND_URL + 'user/saveEmailGroup',
            data: {
                id: emailGroup.id,
                emailGroup: emailGroup.name,
                groupDesc: emailGroup.groupDesc,
                mailingList: emailGroup.addresses,
            },
        });
        const savedGroup = response.data.result;
        const index = this.emailGroups.findIndex((group) => group.id === savedGroup.id);
        if (index > -1) runInAction(() => (this.emailGroupsByCreator[index] = savedGroup));
        else runInAction(() => this.emailGroupsByCreator.push(savedGroup));
    }

    public async addUser(userParams: UserManagementUserSaveRequestParams): Promise<void> {
        const response = await Axios({
            method: 'POST',
            url: BACKEND_URL + 'user/addUser',
            data: userParams,
        });
        runInAction(() => {
            const newUser = UserModule.mapIsAdminToUserManagementUsers([response.data])[0];
            this.userManagementUserList.push(newUser);
        });
    }

    public async deleteUser(userName: string): Promise<void> {
        await Axios({
            method: 'POST',
            url: BACKEND_URL + 'user/deleteUser',
            data: { userName },
        });
        runInAction(
            () =>
                (this.userManagementUserList = this.userManagementUserList.filter(
                    (user) => user.userName !== userName,
                )),
        );
    }

    public async updateUser(userParams: UserManagementUserSaveRequestParams): Promise<void> {
        const response = await Axios({
            method: 'POST',
            url: BACKEND_URL + 'user/updateUser',
            data: userParams,
        });
        const updatedUser = response.data;
        const index = this.userManagementUserList.findIndex((user) => user.id === updatedUser.id);
        if (index > -1)
            runInAction(
                () =>
                    (this.userManagementUserList[index] = UserModule.mapIsAdminToUserManagementUsers([updatedUser])[0]),
            );
    }

    public async removeEmailGroup(name: string): Promise<void> {
        await Axios({
            method: 'POST',
            url: BACKEND_URL + 'user/removeEmailGroup',
            data: {
                emailGroup: name,
            },
        });
        runInAction(() => {
            this.emailGroupsByCreator = this.emailGroupsByCreator.filter((group) => group.name !== name);
        });
    }

    public clearUserDetails(): void {
        this.userManagementUserList = [];
        this.rootModule.organization.setAdminOrganizationList([]);
        this.userData = null;
    }

    public async resetPasswordWithToken(token: string, password: string, password2: string): Promise<void> {
        await Axios({
            method: 'POST',
            url: BACKEND_URL + 'passwordReset/withToken',
            data: { token: token, password: password, password2: password2 },
        });
    }

    public async sendResetLink(email: string): Promise<void> {
        await Axios({
            method: 'POST',
            url: BACKEND_URL + 'passwordReset/createLink',
            data: { userName: email },
        });
    }

    public async register(params: RegisterForm): Promise<{ code: string }> {
        const response = await Axios({
            method: 'POST',
            url: BACKEND_URL + 'user/register',
            data: { ...params },
        });
        return response.data;
    }

    public get userDetails(): UserDetails | null {
        return this.userData;
    }

    public get userOrganizations(): UserOrganization[] {
        const organizations = this.userDetails?.organizations || [];
        return organizations
            .slice()
            .sort((a, b) => (a.name > b.name ? 1 : -1))
            .sort((a, b) => (a.auth.length > b.auth.length ? -1 : 1))
            .sort((a) => (a.id === this.userDetails?.homeOrganization.id ? -1 : 1));
    }

    public get userManagementUsers(): UserManagementUser[] {
        return this.userManagementUserList;
    }

    public get isUserLoggedIn(): boolean {
        return Boolean(this.userData);
    }

    public get emailGroups(): EmailGroup[] {
        return this.emailGroupsByCreator;
    }

    public isAuthorized(name: string, action: string): boolean {
        if (!name || !action) return false;
        if (!this.isUserLoggedIn) return false;
        if (!Array.isArray(this.userDetails?.auth)) return false;
        const userRight = this.userDetails?.auth.find((module) => module.name === name);
        if (!userRight) return false;
        return userRight.actions.some((a) => a === action);
    }

    public validateUserName(userName: string): boolean {
        // Validation for basic email addresses, see: https://www.regular-expressions.info/email.html
        /*
            Current regex rules for email validation:

            - local-part can contain letters a-z, numbers 0-9, dots, -, %, + and _.

            - sub-domains can contain letters a-z, numbers 0-9 and -. Subdomain must begin with letter or number, and they
            cannot contain consecutive hyphens -. They also must end with letter or number followed by dot. Limit for
            amount of subdomains is 8.

            - top-level domain is limited to length of 63, and it must have two letters at least. Only letters a-z are allowed.
        * */
        const emailPattern = new RegExp('^[a-z0-9._%+-]+@(?:[a-z0-9]+(?:-[a-z0-9]+)*\\.){1,8}[a-z]{2,63}$');
        return emailPattern.test(userName) && userName.length < 321;
    }

    public validatePassword(password: string): boolean {
        const PASSWORD_MIN_LENGTH = 8;

        const isValidLength = password.length >= PASSWORD_MIN_LENGTH;
        const hasUpperCase = /[A-Z]/.test(password);
        const hasLowerCase = /[a-z]/.test(password);
        const hasNumbers = /\d/.test(password);
        const hasNoSpaces = /\S/.test(password);

        return isValidLength && hasUpperCase && hasLowerCase && hasNumbers && hasNoSpaces;
    }

    public checkPasswordMatch(password1: string, password2: string): boolean {
        return password1 === password2;
    }

    private static mapIsAdminToUserManagementUsers(users: UserManagementUser[]): UserManagementUser[] {
        return users.map((user) => {
            user.organizations = user.organizations.map((organization) => {
                const authority = organization.role?.authority;
                organization.isAdmin =
                    authority === 'ROLE_SUPERUSER' || authority === 'ROLE_ADMIN' || authority === 'ROLE_UBERADMIN';

                return organization;
            });
            return user;
        });
    }
}
