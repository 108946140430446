import React from 'react';
import { observer, useLocalObservable } from 'mobx-react-lite';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    List,
    ListItem,
    ListItemText,
    Tooltip,
    Typography,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { Indicator, IndicatorPackage, IndicatorPackageTheme } from '../../../types/indicator';
import useStore from '../../../store/storeContext';

interface Props {
    isOpen: boolean;
    close(): void;
    indicators: Indicator[];
    addIndicators(): void;
}

interface StoreState {
    indicatorPackages: IndicatorPackage[];
}

function IndicatorAlreadyInDocumentDialog(props: Props) {
    const { document, localization, theming } = useStore();

    const localStore = useLocalObservable<StoreState>(() => ({
        get indicatorPackages(): IndicatorPackage[] {
            const { indicatorPackageChapter } = document;
            let indicatorPackages: IndicatorPackage[] = [];
            if (indicatorPackageChapter) {
                indicatorPackages = indicatorPackageChapter.indicatorPackages.filter(
                    (indicatorPackage) => filterThemes(indicatorPackage.themes, indicatorPackage).length,
                );
            }
            return indicatorPackages;
        },
    }));
    function filterIndicators(documentIndicators: Indicator[]): Indicator[] {
        const { indicators } = props;
        return documentIndicators.filter((documentIndicator) =>
            indicators.some((indicator) => indicator.id === documentIndicator.id),
        );
    }

    function filterThemes(
        themes: IndicatorPackageTheme[],
        indicatorPackage: IndicatorPackage,
    ): IndicatorPackageTheme[] {
        return themes.filter((theme) => {
            if (theme.level === 0) {
                return subThemes(theme, indicatorPackage).some((subTheme) => {
                    return (
                        filterIndicators(subTheme.sotkanetIndicators).length ||
                        filterIndicators(subTheme.otherIndicators).length ||
                        filterIndicators(subTheme.organizationIndicators).length
                    );
                });
            } else {
                return (
                    filterIndicators(theme.sotkanetIndicators).length ||
                    filterIndicators(theme.otherIndicators).length ||
                    filterIndicators(theme.organizationIndicators).length
                );
            }
        });
    }

    function subThemes(mainTheme: IndicatorPackageTheme, indicatorPackage: IndicatorPackage): IndicatorPackageTheme[] {
        const subThemes: IndicatorPackageTheme[] = [];
        const themes = indicatorPackage.themes.slice().sort((a, b) => a.orderNumber - b.orderNumber);
        let addToSubThemes = false;

        themes.forEach((theme) => {
            if (theme.level === 0) {
                if (theme === mainTheme) addToSubThemes = true;
                else addToSubThemes = false;
            } else {
                if (addToSubThemes) subThemes.push(theme);
            }
        });

        return subThemes;
    }

    const { translate } = localization;

    return (
        <Dialog open={props.isOpen} onClose={props.close} maxWidth="md" fullWidth>
            <DialogTitle id="add-indicator-dialog-title">
                {translate('INDICATOR_ALREADY_IN_DOCUMENT_TITLE')}
                <Tooltip placement="right-start" title={translate('INDICATOR_ALREADY_IN_DOCUMENT_TIP')}>
                    <InfoIcon
                        color="primary"
                        style={{
                            display: 'inline-block',
                            verticalAlign: 'middle',
                            paddingBottom: '5px',
                            marginLeft: theming.spacing(1),
                        }}
                    />
                </Tooltip>
            </DialogTitle>
            <DialogContent dividers>
                <List dense>
                    {localStore.indicatorPackages.map((indicatorPackage) => (
                        <Box key={indicatorPackage.id}>
                            <ListItem key={indicatorPackage.id}>
                                <ListItemText>
                                    <Typography variant="h6">{indicatorPackage.name}</Typography>
                                </ListItemText>
                            </ListItem>
                            {filterThemes(indicatorPackage.themes, indicatorPackage).map((theme) => (
                                <Box key={theme.id} ml={theme.level * 2 + 2}>
                                    <ListItem>
                                        <ListItemText>
                                            <Typography variant="subtitle2">{theme.name}</Typography>
                                        </ListItemText>
                                    </ListItem>

                                    {filterIndicators(theme.sotkanetIndicators).map((indicator) => (
                                        <Box key={indicator.id} ml={2}>
                                            <ListItem key={indicator.id}>
                                                <ListItemText>{indicator.name}</ListItemText>
                                            </ListItem>
                                        </Box>
                                    ))}

                                    {filterIndicators(theme.otherIndicators).map((indicator) => (
                                        <Box key={indicator.id} ml={2}>
                                            <ListItem key={indicator.id}>
                                                <ListItemText>{indicator.name}</ListItemText>
                                            </ListItem>
                                        </Box>
                                    ))}

                                    {filterIndicators(theme.organizationIndicators).map((indicator) => (
                                        <Box key={indicator.id} ml={2}>
                                            <ListItem key={indicator.id}>
                                                <ListItemText>{indicator.name}</ListItemText>
                                            </ListItem>
                                        </Box>
                                    ))}
                                </Box>
                            ))}
                        </Box>
                    ))}
                </List>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={props.close}>
                    {translate('CANCEL')}
                </Button>
                <Box flexGrow={1} />
                <Button
                    color="secondary"
                    variant="contained"
                    onClick={() => {
                        props.close();
                        props.addIndicators();
                    }}
                >
                    {translate('ADD')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default observer(IndicatorAlreadyInDocumentDialog);
