import React from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Box, Divider, List, ListItem, ListItemButton, ListItemText, Popover, Typography } from '@mui/material';
import { UserBarMenu } from './UserBar';
import useStore from '../../store/storeContext';

interface Props {
    handleClose: (menu: UserBarMenu) => void;
    anchorEl: HTMLElement | null;
}

function SelectOrganizationPopover(props: Props) {
    const context = useStore();
    const { document, user, localization, snackbar, theming, loadingIndicator } = context;

    const navigate = useNavigate();

    async function changeUserOrganization(organizationId: number): Promise<void> {
        try {
            loadingIndicator.show();
            await user.changeUserInfo(undefined, organizationId, undefined);
            await user.fetchSelectedOrganizationUserDetails(organizationId);
            navigate('/home');
            snackbar.showSuccess();
        } catch (error) {
            snackbar.showFetchFailedMessage(error.data?.code);
        } finally {
            loadingIndicator.hide();
        }
    }

    const handleOrganizationClick = (organizationId: number): void => {
        changeUserOrganization(organizationId)
            .then(() => document.closeCurrentDocument())
            .then(() => props.handleClose('organizationMenu'));
    };

    const { translate } = localization;

    return (
        <Popover
            id="select-organization-popup"
            open={Boolean(props.anchorEl)}
            onClose={(): void => props.handleClose('organizationMenu')}
            anchorEl={props.anchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
        >
            <Box px={2} py={1} textAlign="center">
                <Typography variant="h6" component="h4">
                    {translate('SELECT_ORGANIZATION_TITLE')}
                </Typography>
            </Box>
            <Divider />
            <List>
                {user.userOrganizations.map((userOrganization) => (
                    <ListItemButton key={userOrganization.id}>
                        <ListItem
                            style={{
                                backgroundColor:
                                    context.organization.currentOrganizationId === userOrganization.id
                                        ? theming.shvkBlue10
                                        : '',
                            }}
                            key={userOrganization.id}
                            onClick={(): void => handleOrganizationClick(userOrganization.id)}
                        >
                            <ListItemText primary={userOrganization.name} secondary={userOrganization.category} />
                        </ListItem>
                    </ListItemButton>
                ))}
            </List>
        </Popover>
    );
}

export default observer(SelectOrganizationPopover);
