import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { runInAction } from 'mobx';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Divider,
    IconButton,
    Tab,
    Tabs,
    Typography,
} from '@mui/material';
import { Edit, Save, ExpandMore } from '@mui/icons-material';
import { TabContext, TabPanel } from '@mui/lab';
import { styled } from '@mui/material/styles';
import { TranslationKeys } from '../../types/translation';
import { LocalizedName } from '../../types/localization';
import { BACKEND_URL } from '../../config';
import FroalaEditorComponent from 'react-froala-wysiwyg';
import useStore from '../../store/storeContext';

const PREFIX = 'MaterialTextAccordion';

const classes = {
    details: `${PREFIX}-details`,
    iconButton: `${PREFIX}-iconButton`,
};

const StyledAccordion = styled(Accordion)(({ theme }) => ({
    [`& .${classes.details}`]: {
        display: 'block',
        position: 'relative',
    },

    [`& .${classes.iconButton}`]: {
        position: 'absolute',
        right: theme.spacing(2),
        top: 0,
    },
}));

interface Props {
    title: keyof TranslationKeys;
    id: string;
    localizedName: LocalizedName | null;
    save: (localizedName: LocalizedName) => Promise<void>;
}

interface State {
    editing: boolean;
    fiText: string;
    seText: string;
    activeTab: TabName;
}

type TabName = 'FI' | 'SE';
type MaterialManagementField = 'fiText' | 'seText';

function MaterialTextAccordion(props: Props) {
    const { session, document, localization, snackbar } = useStore();

    const [state, setState] = useState<State>({
        editing: false,
        fiText: '',
        seText: '',
        activeTab: 'FI',
    });

    useEffect(() => {
        init();
        return () => {
            setState((state) => ({ ...state, fiText: '', seText: '', activeTab: 'FI', editing: false }));
        };
    }, []);

    useEffect(() => {
        init();
    }, [props.localizedName]);

    function init(): void {
        setState((state) => ({
            ...state,
            fiText: props.localizedName?.fi_FI || '',
            seText: props.localizedName?.sv_SE || '',
            activeTab: 'FI',
        }));
    }

    const handleTabChange = (_event: React.ChangeEvent<unknown>, newIndex: TabName): void => {
        setState((state) => ({ ...state, activeTab: newIndex }));
    };

    const handleChange = (text: string, field: MaterialManagementField): void => {
        setState((state) => ({ ...state, [field]: text }));
    };

    function editOnClick(): void {
        setState((state) => ({ ...state, editing: true }));
    }

    function saveOnClick(): void {
        setState((state) => ({ ...state, editing: false }));
        void save();
    }

    async function save(): Promise<void> {
        try {
            const { save } = props;

            if (!props.localizedName) props.localizedName = {} as LocalizedName;

            runInAction(() => {
                if (props.localizedName) {
                    props.localizedName.fi_FI = state.fiText;
                    props.localizedName.sv_SE = state.seText;
                }
            });

            await save(props.localizedName);
            snackbar.showSuccess();
        } catch (error) {
            snackbar.showError(error.data?.code);
        }
    }

    const { title, id } = props;
    const { translate } = localization;
    const { froalaConfig } = document;

    return (
        <StyledAccordion>
            <AccordionSummary expandIcon={<ExpandMore />} aria-controls={id + '-content'} id={id + '-header'}>
                <Typography>{translate(title)}</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
                <Tabs value={state.activeTab} indicatorColor="primary" textColor="primary" onChange={handleTabChange}>
                    <Tab label={translate('FI_TRANSLATION')} value="FI" />
                    <Tab label={translate('SE_TRANSLATION')} value="SE" />
                </Tabs>
                {state.editing ? (
                    <IconButton
                        aria-label={translate('SAVE')}
                        color="primary"
                        className={classes.iconButton}
                        onClick={saveOnClick}
                        size="large"
                    >
                        <Save fontSize="small" />
                    </IconButton>
                ) : (
                    <IconButton
                        aria-label={translate('EDIT')}
                        color="primary"
                        className={classes.iconButton}
                        onClick={editOnClick}
                        size="large"
                    >
                        <Edit fontSize="small" />
                    </IconButton>
                )}
                <Divider />
                <TabContext value={state.activeTab}>
                    <TabPanel value={'FI'}>
                        {state.editing ? (
                            <FroalaEditorComponent
                                tag="textarea"
                                model={state.fiText}
                                onModelChange={(text: string) => handleChange(text, 'fiText')}
                                config={{
                                    ...froalaConfig,
                                    imageUploadURL: BACKEND_URL + 'app/material',
                                    imageUploadParams: { lang: 'FI' },
                                    fileInsertButtons: ['fileInsert'],
                                    fileUploadURL: BACKEND_URL + 'app/material',
                                    fileUploadParams: { lang: 'FI' },
                                    fileMaxSize: 400 * 1024 * 1024,
                                    requestHeaders: { 'X-Auth-Token': session.parameters.authToken },
                                }}
                            />
                        ) : (
                            <Typography
                                className="fr-view"
                                component="div"
                                dangerouslySetInnerHTML={{ __html: state.fiText }}
                            />
                        )}
                    </TabPanel>
                    <TabPanel value={'SE'}>
                        {state.editing ? (
                            <FroalaEditorComponent
                                tag="textarea"
                                model={state.seText}
                                onModelChange={(text: string) => handleChange(text, 'seText')}
                                config={{
                                    ...froalaConfig,
                                    imageUploadURL: BACKEND_URL + 'app/material',
                                    imageUploadParams: { lang: 'SV' },
                                    fileUploadURL: BACKEND_URL + 'app/material',
                                    fileUploadParams: { lang: 'SV' },
                                    fileMaxSize: 400 * 1024 * 1024,
                                    requestHeaders: { 'X-Auth-Token': session.parameters.authToken },
                                }}
                            />
                        ) : (
                            <Typography
                                className="fr-view"
                                component="div"
                                dangerouslySetInnerHTML={{ __html: state.seText }}
                            />
                        )}
                    </TabPanel>
                </TabContext>
            </AccordionDetails>
        </StyledAccordion>
    );
}

export default observer(MaterialTextAccordion);
