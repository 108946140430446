import React from 'react';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import { Box, Card, CardContent, Typography } from '@mui/material';
import ShvkButton from '../../styled/ShvkButton';
import DesktopModule from '../../types/desktopModule';
import useStore from '../../store/storeContext';

interface Props {
    desktopModule: DesktopModule;
}

function DesktopCard(props: Props) {
    const { localization, theming } = useStore();

    const navigate = useNavigate();

    const Icon = props.desktopModule.icon;
    const translate = localization.translate;

    return (
        <Card>
            <CardContent>
                <Box textAlign="center">
                    <Icon style={{ fontSize: 50, color: theming.shvkBlue80 }} />
                    <Box my={2}>
                        <Typography variant="h5">{translate(props.desktopModule.title)}</Typography>
                        <Typography variant="subtitle2">{translate(props.desktopModule.subtitle)}</Typography>
                    </Box>
                    <ShvkButton onClick={async (): Promise<void> => await navigate(`/${props.desktopModule.link}`)}>
                        {translate('DESKTOP_ACTION_GO')}
                    </ShvkButton>
                </Box>
            </CardContent>
        </Card>
    );
}

export default observer(DesktopCard);
