import React, { useState, useEffect } from 'react';
import { observer, useLocalObservable } from 'mobx-react-lite';
import {
    Alert,
    Box,
    Button,
    Collapse,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    Grid,
    MenuItem,
    Radio,
    RadioGroup,
    Tooltip,
    Typography,
} from '@mui/material';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { NewDocument, OrganizationDocument } from '../../types/document';
import { Language } from '../../types/localization';
import { TranslationKeys } from '../../types/translation';
import { TextFieldEvent } from '../../types/events';
import ShvkTextField from '../../styled/ShvkTextField';
import InfoIcon from '@mui/icons-material/Info';
import ShvkButton from '../../styled/ShvkButton';
import useStore from '../../store/storeContext';

const PREFIX = 'newDocumentDialog';

const classes = {
    subDir: `${PREFIX}-subDir`,
    label: `${PREFIX}-label`,
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
    [`& .${classes.subDir}`]: {
        paddingLeft: theme.spacing(4),
    },

    [`& .${classes.label}`]: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
}));

interface Props {
    open: boolean;
    onClose(): void;
    type: 'SHVK' | 'EVA' | 'ANOTHER_REPORT';
    fromPopover: boolean;
}

type NewDocumentField =
    | 'documentType'
    | 'year'
    | 'title'
    | 'evaTemplateId'
    | 'documentTemplateId'
    | 'userGroup'
    | 'languageId'
    | 'directoryId';

type ValidStep = 1 | 2 | 3 | 4 | 5 | 6;

type DirectoryMenuItem = { id: number; name: string; className?: string };

interface State {
    activeStep: ValidStep;
    errorAlertText: string;
    documentType: string;
    year: string;
    title: string;
    evaTemplateId: string;
    userGroup: string;
    languageId: string;
    directoryId: string;
    evaMiniTip: string;
    evaTip: string;
    largeReportTip: string;
    yearlyReportTip: string;
    regionalLargeReportTip: string;
    regionalYearlyReportTip: string;
    years: string[];
    periods: string[];
}

interface StoreState {
    docType: string;
    docTemplateId: string;
    stepsToSkip: Set<ValidStep>;
    directoryMenuItems: DirectoryMenuItem[];
    isRegional: boolean;
    setDocType: (type: string) => void;
    setDocTemplateId: (id: string) => void;
}

const NEW_GROUP: Readonly<string> = 'NEW_GROUP';
const COPY_GROUP: Readonly<string> = 'COPY_GROUP';
const steps: Readonly<Array<keyof TranslationKeys>> = [
    'SELECT_DOCUMENT_TYPE',
    'SELECT_DOCUMENT_TEMPLATE',
    'SELECT_EVA_TEMPLATE', // Only eva without document template
    'SELECT_USER_GROUP', // Only with document template
    'SELECT_LANGUAGE', // Only shvk
    'SELECT_DIRECTORY',
];

function NewDocumentDialog(props: Props) {
    const context = useStore();
    const { localization, theming } = context;

    const [state, setState] = useState<State>({
        activeStep: 1,
        errorAlertText: '',
        documentType: '',
        year: '',
        title: '',
        evaTemplateId: '',
        userGroup: '',
        languageId: '',
        directoryId: '',
        evaMiniTip: '',
        evaTip: '',
        largeReportTip: '',
        yearlyReportTip: '',
        regionalLargeReportTip: '',
        regionalYearlyReportTip: '',
        years: calculateYears(),
        periods: getPeriods(),
    });

    const localStore = useLocalObservable<StoreState>(() => ({
        // Some steps are not visible in every case. Add those steps to this set.
        stepsToSkip: new Set(),
        docType: '',
        docTemplateId: '-1',

        get directoryMenuItems(): DirectoryMenuItem[] {
            const { shvkDirectories, evaDirectories, anotherDirectories } = context.directory;
            const directories =
                this.docType === 'SHVK'
                    ? shvkDirectories
                    : this.docType === 'EVA'
                    ? evaDirectories
                    : anotherDirectories;

            const menuItems: DirectoryMenuItem[] = [];
            directories.forEach((mainDir) => {
                menuItems.push({
                    id: mainDir.id,
                    name: mainDir.name,
                });
                mainDir.subDirs.forEach((subDir) => {
                    menuItems.push({
                        id: subDir.id,
                        name: subDir.name,
                        className: classes.subDir,
                    });
                });
            });

            return menuItems;
        },

        get isRegional(): boolean {
            const currentOrganization = context.organization.currentOrganization;
            return currentOrganization !== null && currentOrganization.category === 'HYVINVOINTIALUE';
        },

        setDocTemplateId(id: string): void {
            this.docTemplateId = id;

            // User group selection is visible if a document template is selected.
            if (this.docTemplateId === '-1') {
                this.stepsToSkip.add(4);
            } else {
                this.stepsToSkip.delete(4);
            }

            // Eva template selection is hidden if a document template is selected.
            // For shvk documents, it is always hidden.
            if (this.docType === 'EVA') {
                if (this.docTemplateId === '-1') {
                    this.stepsToSkip.delete(3);
                } else this.stepsToSkip.add(3);
            }
        },

        setDocType(type: string): void {
            this.docType = type;
        },
    }));

    useEffect(() => {
        if (!context.chapterTemplate.documentTypes.length) {
            void context.chapterTemplate.fetchAvailableDocTypes();
        }
    }, []);

    useEffect(() => {
        if (props.open) {
            void getData();
            changeTips();
        }
        resetForm();
    }, [props.open, props.fromPopover]);

    async function getData(): Promise<void> {
        try {
            const organizationId = context.organization.currentOrganizationId;
            if (!organizationId) {
                return;
            }

            await context.document.fetchAvailableDocumentTypes();
            await context.evaTemplate.fetchOrganizationEvaTemplates([organizationId]);
            await context.evaTemplate.fetchDefaultEvaTemplates();
            await context.directory.fetchDirectories();
        } catch (e) {
            context.snackbar.showFetchFailedMessage(e.data?.code);
        }
    }

    async function createDocument(): Promise<void> {
        try {
            const evaTemplate =
                state.evaTemplateId !== '' ? context.evaTemplate.getEvaTemplateById(Number(state.evaTemplateId)) : null;

            const documentTemplate =
                localStore.docTemplateId !== ''
                    ? context.document.getOrganizationDocumentById(Number(localStore.docTemplateId))
                    : null;

            const language = context.localization.getLanguageById(Number(state.languageId));
            const docType = context.document.getDocumentTypeByCode(state.documentType);

            const organizationId = context.organization.currentOrganizationId;

            if (!docType || !organizationId) {
                context.snackbar.showError();
                return;
            }

            const params: NewDocument = {
                copyOldGroup: state.userGroup === COPY_GROUP,
                directoryId: Number(state.directoryId),
                documentTypeId: docType.id,
                evaTemplate,
                language: language || null,
                organizationId: organizationId,
                template: documentTemplate,
                title: state.title,
                year: state.year,
            };

            context.loadingIndicator.show();
            await context.document.createDocument(params);
            await context.document.fetchOrganizationDocuments(organizationId);
            props.onClose();
        } catch (error) {
            setState((state) => ({ ...state, errorAlertText: error.data }));
            context.loadingIndicator.hide();
        }
    }

    function calculateYears(): string[] {
        const currentYear = new Date().getFullYear();
        return [(currentYear - 2).toString(), (currentYear - 1).toString(), currentYear.toString()];
    }

    function getPeriods(): string[] {
        return ['2013 - 2016', '2017 - 2020', '2021 - 2025', '2025 - 2029'];
    }

    function resetForm(): void {
        setState((state) => ({
            ...state,
            activeStep: 1,
            documentType: '',
            year: '',
            title: '',
            evaTemplateId: '',
            directoryId: '-1',
            userGroup: NEW_GROUP,
            languageId: context.localization.getCurrentLanguage.id.toString(),
        }));

        // Hide user group selection. Will be changed back to visible if a document template is selected.
        localStore.stepsToSkip.clear();
        localStore.stepsToSkip.add(4);

        switch (props.type) {
            case 'SHVK':
                // For shvk, hide eva template selection.
                localStore.stepsToSkip.add(3);
                localStore.setDocType('SHVK');
                break;
            case 'EVA':
                // For eva, hide language selection.
                localStore.stepsToSkip.add(5);
                localStore.setDocType('EVA');
                break;
            case 'ANOTHER_REPORT':
                // For another, hide document type selection, eva template selection, and language selection.
                localStore.stepsToSkip.add(1);
                localStore.stepsToSkip.add(3);
                localStore.stepsToSkip.add(5);
                localStore.setDocType('ANOTHER_REPORT');
                setState((state) => ({
                    ...state,
                    activeStep: 2,
                    documentType: 'ANOTHER_REPORT',
                    year: new Date().getFullYear().toString(),
                }));
                break;
        }
    }

    function changeTips(): void {
        setState((state) => ({
            ...state,
            largeReportTip: context.chapterTemplate.documentTypes[0].description[localization.locale],
            yearlyReportTip: context.chapterTemplate.documentTypes[1].description[localization.locale],
            evaMiniTip: context.chapterTemplate.documentTypes[2].description[localization.locale],
            evaTip: context.chapterTemplate.documentTypes[3].description[localization.locale],
            regionalLargeReportTip: context.chapterTemplate.documentTypes[4].description[localization.locale],
            regionalYearlyReportTip: context.chapterTemplate.documentTypes[5].description[localization.locale],
        }));
    }

    function handleChange(event: TextFieldEvent, field: NewDocumentField): void {
        if (state.errorAlertText) {
            setState((state) => ({ ...state, errorAlertText: '' }));
        }

        if (field === 'documentTemplateId') {
            localStore.setDocTemplateId(event.target.value);
            return;
        }

        if (field === 'documentType') {
            setState((state) => ({ ...state, year: '' }));
        }

        setState((state) => ({
            ...state,
            [field]: event.target.value,
        }));
    }

    const stepContents = {
        1: {
            contents: (): JSX.Element => renderStep1(),
            disabled: (): boolean => (props.type === 'EVA' ? state.title === '' : state.year === ''),
        },
        2: {
            contents: (): JSX.Element => renderStep2(),
            disabled: (): boolean => localStore.docTemplateId === undefined,
        },
        3: {
            contents: (): JSX.Element => renderStep3(),
            disabled: (): boolean => state.evaTemplateId === '',
        },
        4: {
            contents: (): JSX.Element => renderStep4(),
            disabled: (): boolean => state.userGroup !== NEW_GROUP && state.userGroup !== COPY_GROUP,
        },
        5: {
            contents: (): JSX.Element => renderStep5(),
            disabled: (): boolean => state.languageId === undefined,
        },
        6: {
            contents: (): JSX.Element => renderStep6(),
            disabled: (): boolean => state.directoryId === undefined,
        },
    };

    const getStepContent = (): JSX.Element => {
        return stepContents[state.activeStep].contents();
    };

    const isNextButtonDisabled = (): boolean => {
        return stepContents[state.activeStep].disabled();
    };

    const handleNext = (): void => {
        // Find and activate the next step that is not skipped
        let validStep = (state.activeStep + 1) as ValidStep;
        for (validStep; validStep < steps.length + 1; validStep++) {
            if (localStore.stepsToSkip?.has(validStep)) continue;
            setState((state) => ({
                ...state,
                activeStep: validStep,
            }));
            break;
        }
    };

    const handleBack = (): void => {
        if (state.errorAlertText) {
            setState((state) => ({ ...state, errorAlertText: '' }));
        }

        // Find and activate a previous step that is not skipped
        let validStep = (state.activeStep - 1) as ValidStep;
        for (validStep; validStep > 0; validStep--) {
            if (localStore.stepsToSkip?.has(validStep)) continue;
            setState((state) => ({
                ...state,
                activeStep: validStep,
            }));
            break;
        }
    };

    const documentTemplates = (): OrganizationDocument[] => {
        const documentModule = context.document;

        if (state.documentType === 'ANOTHER_REPORT') {
            return documentModule.organizationAnotherDocuments;
        } else if (state.documentType === 'EVA' || state.documentType === 'EVA_MINI') {
            return documentModule.organizationEvaDocuments.filter((doc) => doc.type.code === state.documentType);
        } else return documentModule.organizationShvkDocuments;
    };

    const selectedEvaTemplateDescription = (evaTemplateId: string | number): string => {
        const allTemplates = [
            ...context.evaTemplate.organizationEvaTemplateList,
            ...context.evaTemplate.defaultEvaTemplateList,
        ];
        const template = allTemplates.find((template) => template.id === evaTemplateId);
        return template ? template.description : '';
    };

    function renderStep1(): JSX.Element {
        const { translate } = context.localization;

        return (
            <div>
                <FormControl component="fieldset" required className={classes.label}>
                    <RadioGroup
                        aria-label={translate('DOCUMENT_TYPE')}
                        name="document-type"
                        value={state.documentType}
                        onChange={(event): void => handleChange(event, 'documentType')}
                    >
                        {props.type === 'EVA' ? (
                            <>
                                <Grid container direction="row" alignItems="center">
                                    <FormControlLabel
                                        value="EVA_MINI"
                                        control={<Radio />}
                                        label={translate('EVA_MINI')}
                                    />
                                    <Tooltip title={state.evaMiniTip} placement={'right-start'}>
                                        <InfoIcon color="primary" />
                                    </Tooltip>
                                </Grid>
                                <Grid container direction="row" alignItems="center">
                                    <FormControlLabel value="EVA" control={<Radio />} label={translate('EVA')} />
                                    <Tooltip title={state.evaTip} placement={'right-start'}>
                                        <InfoIcon color="primary" />
                                    </Tooltip>
                                </Grid>
                            </>
                        ) : (
                            <>
                                <Grid container direction="row" alignItems="center">
                                    <FormControlLabel
                                        value={localStore.isRegional ? 'REGIONAL_LARGE_REPORT' : 'LARGE_REPORT'}
                                        control={<Radio />}
                                        label={translate('LARGE_REPORT')}
                                    />
                                    <Tooltip
                                        title={
                                            localStore.isRegional ? state.regionalLargeReportTip : state.largeReportTip
                                        }
                                        placement={'right-start'}
                                    >
                                        <InfoIcon color="primary" />
                                    </Tooltip>
                                </Grid>
                                <Grid container direction="row" alignItems="center">
                                    <FormControlLabel
                                        value={localStore.isRegional ? 'REGIONAL_YEARLY_REPORT' : 'YEARLY_REPORT'}
                                        control={<Radio />}
                                        label={translate('YEARLY_REPORT')}
                                    />
                                    <Tooltip
                                        title={
                                            localStore.isRegional
                                                ? state.regionalYearlyReportTip
                                                : state.yearlyReportTip
                                        }
                                        placement={'right-start'}
                                    >
                                        <InfoIcon color="primary" />
                                    </Tooltip>
                                </Grid>
                            </>
                        )}
                    </RadioGroup>
                </FormControl>
                <Collapse in={state.documentType !== ''}>
                    {props.type === 'EVA' ? (
                        <ShvkTextField
                            id="document-title"
                            name="document-title"
                            label={translate('TITLE')}
                            value={state.title}
                            onChange={(event): void => handleChange(event, 'title')}
                            variant="outlined"
                            size="small"
                            fullWidth
                            style={{ marginBottom: theming.theme.spacing(2) }}
                            autoComplete="off"
                        />
                    ) : (
                        <ShvkTextField
                            select
                            name="document-year"
                            id="select-period"
                            value={state.year}
                            onChange={(event): void => handleChange(event, 'year')}
                            label={translate('PERIOD')}
                            variant="outlined"
                            size="small"
                            fullWidth
                            style={{ marginBottom: theming.theme.spacing(2) }}
                        >
                            {state.documentType === 'YEARLY_REPORT' || state.documentType === 'REGIONAL_YEARLY_REPORT'
                                ? state.years.map((year) => (
                                      <MenuItem key={year} value={year}>
                                          {year}
                                      </MenuItem>
                                  ))
                                : state.periods.map((period) => (
                                      <MenuItem key={period} value={period}>
                                          {period}
                                      </MenuItem>
                                  ))}
                        </ShvkTextField>
                    )}
                </Collapse>
            </div>
        );
    }

    function renderStep2(): JSX.Element {
        const { translate } = context.localization;

        return (
            <ShvkTextField
                select
                name="document-template"
                id="select-document-template"
                value={localStore.docTemplateId}
                onChange={(event): void => handleChange(event, 'documentTemplateId')}
                label={translate('DOCUMENT_TEMPLATE')}
                fullWidth
                style={{ marginBottom: theming.theme.spacing(2) }}
                variant="outlined"
                size="small"
            >
                <MenuItem value={'-1'}>{translate('NEW_DOCUMENT')}</MenuItem>
                {documentTemplates().map((template) => (
                    <MenuItem key={template.id} value={template.id}>
                        {template.name}
                    </MenuItem>
                ))}
            </ShvkTextField>
        );
    }

    function renderStep3(): JSX.Element {
        const { translate } = context.localization;

        return (
            <>
                <ShvkTextField
                    select
                    name="eva-template"
                    id="select-eva-template"
                    value={state.evaTemplateId}
                    onChange={(event): void => handleChange(event, 'evaTemplateId')}
                    label={translate('ORGANIZATION_EVA_TEMPLATE')}
                    fullWidth
                    style={{ marginBottom: theming.theme.spacing(2) }}
                    variant="outlined"
                    size="small"
                >
                    {context.evaTemplate.defaultEvaTemplateList.map((template) => (
                        <MenuItem key={template.id} value={template.id}>
                            {template.text}
                        </MenuItem>
                    ))}
                    {context.evaTemplate.organizationEvaTemplateList.map((template) => (
                        <MenuItem key={template.id} value={template.id}>
                            {template.title}
                        </MenuItem>
                    ))}
                </ShvkTextField>
                {selectedEvaTemplateDescription(state.evaTemplateId) && (
                    <>
                        <Typography style={{ fontWeight: 'bold' }}>{translate('DESCRIPTION')}</Typography>
                        <Typography>{selectedEvaTemplateDescription(state.evaTemplateId)}</Typography>
                    </>
                )}
            </>
        );
    }

    function renderStep4(): JSX.Element {
        const { translate } = context.localization;
        return (
            <FormControl component="fieldset" required className={classes.label}>
                <RadioGroup
                    aria-label={translate('USER_GROUP')}
                    name="user-group"
                    value={state.userGroup}
                    onChange={(event): void => handleChange(event, 'userGroup')}
                >
                    <FormControlLabel value={COPY_GROUP} control={<Radio />} label={translate('COPY_USER_GROUP')} />
                    <FormControlLabel value={NEW_GROUP} control={<Radio />} label={translate('NEW_USER_GROUP')} />
                </RadioGroup>
            </FormControl>
        );
    }

    function renderStep5(): JSX.Element {
        const { translate } = context.localization;

        const bothLanguages: Language = {
            id: -1,
            name: translate('CREATE_DOCUMENT_BY_ALL_LANGUAGES_SELECTION'),
            locale: undefined,
        };

        return (
            <ShvkTextField
                select
                name="language"
                id="select-language"
                value={state.languageId}
                onChange={(event): void => handleChange(event, 'languageId')}
                label={translate('LANGUAGE')}
                fullWidth
                style={{ marginBottom: theming.theme.spacing(2) }}
                variant="outlined"
                size="small"
            >
                {context.localization.languages.map((language: Language) => (
                    <MenuItem key={language.id} value={language.id.toString()}>
                        {language.name}
                    </MenuItem>
                ))}
                <MenuItem value={bothLanguages.id}>{bothLanguages.name}</MenuItem>
            </ShvkTextField>
        );
    }

    function renderStep6(): JSX.Element {
        const { translate } = context.localization;

        return (
            <ShvkTextField
                select
                name="directory"
                id="select-directory"
                value={state.directoryId}
                onChange={(event): void => handleChange(event, 'directoryId')}
                label={translate('DIRECTORY')}
                fullWidth
                style={{ marginBottom: theming.theme.spacing(2) }}
                variant="outlined"
                size="small"
            >
                <MenuItem value={'-1'}>{translate('NO_DIRECTORY')}</MenuItem>
                {localStore.directoryMenuItems.map((directory) => (
                    <MenuItem key={directory.id} value={directory.id} className={directory.className}>
                        {directory.name}
                    </MenuItem>
                ))}
            </ShvkTextField>
        );
    }

    const { translate } = context.localization;

    return (
        <StyledDialog
            open={props.open}
            onClose={props.onClose}
            aria-labelledby="new-document-dialog-title"
            maxWidth={'sm'}
            fullWidth
        >
            <DialogTitle id="new-document-dialog-title">
                {state.activeStep === 3 && props.type === 'EVA' ? (
                    <Grid container direction="row" alignItems="center" spacing={1}>
                        <Grid item>{translate(steps[state.activeStep - 1])}</Grid>
                        <Tooltip title={translate('valitse_eva_arviointipohja_12')}>
                            <InfoIcon color="primary" />
                        </Tooltip>
                    </Grid>
                ) : (
                    translate(steps[state.activeStep - 1])
                )}
            </DialogTitle>
            <DialogContent dividers>
                <>
                    {getStepContent()}
                    <Collapse in={Boolean(state.errorAlertText)}>
                        <Alert severity="error" variant="filled">
                            {state.errorAlertText}
                        </Alert>
                    </Collapse>
                </>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose} variant="contained">
                    {translate('CANCEL')}
                </Button>
                <Box flexGrow={1} />
                <Button
                    variant="contained"
                    onClick={handleBack}
                    disabled={state.activeStep === 1}
                    startIcon={<KeyboardArrowLeft />}
                >
                    {translate('NAVIGATE_BACK')}
                </Button>
                {state.activeStep === steps.length ? (
                    <ShvkButton
                        variant="contained"
                        onClick={createDocument}
                        color="primary"
                        disabled={isNextButtonDisabled()}
                    >
                        {translate('CREATE_DOCUMENT')}
                    </ShvkButton>
                ) : (
                    <ShvkButton
                        variant="contained"
                        onClick={handleNext}
                        color="primary"
                        disabled={isNextButtonDisabled()}
                        endIcon={<KeyboardArrowRight />}
                    >
                        {translate('NEXT')}
                    </ShvkButton>
                )}
            </DialogActions>
        </StyledDialog>
    );
}

export default observer(NewDocumentDialog);
