import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import FroalaEditorComponent from 'react-froala-wysiwyg';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Tabs,
    Tooltip,
} from '@mui/material';
import { TabContext, TabPanel } from '@mui/lab';
import { Delete, Timeline } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { AddGoalActionParams, FocusArea, FocusAreaGoal, FocusAreaGoalAction } from '../../../../types/document';
import { AddIndicatorDialogIndicator } from '../../../../types/indicator';
import AddIndicatorDialog from '../AddIndicatorDialog';
import DangerIconButton from '../../../../styled/DangerIconButton';
import ShvkButton from '../../../../styled/ShvkButton';
import useStore, { rootModule } from '../../../../store/storeContext';

const PREFIX = 'AddFocusAreaActionDialog';

const classes = {
    tabPanel: `${PREFIX}-tabPanel`,
    tableCell: `${PREFIX}-tableCell`,
    table: `${PREFIX}-table`,
    tableWrapper: `${PREFIX}-tableWrapper`,
};

const { theming } = rootModule;

const StyledDialog = styled(Dialog)(({ theme }) => ({
    [`& .${classes.tabPanel}`]: {
        minHeight: '300px',
    },

    [`& .${classes.tableCell}`]: {
        border: '1px solid' + theming.shvkLightGrey,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(0.5),
    },

    [`& .${classes.table}`]: {
        '& p': {
            margin: 0,
            padding: 0,
        },
    },

    [`& .${classes.tableWrapper}`]: {
        width: '70%',
        marginLeft: 'auto',
        marginRight: 'auto',
        overFlowX: 'auto',
    },
}));

interface Props {
    isOpen: boolean;
    close(): void;
    focusArea?: FocusArea | null;
    goal?: FocusAreaGoal | null;
    handleEditAction?: (goal: FocusAreaGoal, action: FocusAreaGoalAction, tab: string) => void;
}

interface State {
    tab: string;
    open: boolean;
    allIndicators: AddIndicatorDialogIndicator[];
}

const initialParams = {
    goalId: 0,
    goalLength: 0,
    action: {
        title: '',
        additionalColumn: '',
        evaluationGauge: '',
        resources: '',
        sotkanetIndicators: [],
        organizationIndicators: [],
        otherIndicators: [],
    },
} as AddGoalActionParams;

function AddFocusAreaActionDialog(props: Props) {
    const { document, dialog, localization, loadingIndicator, snackbar } = useStore();

    const [state, setState] = useState<State>({
        tab: 'title',
        open: false,
        allIndicators: [],
    });
    const [params, setParams] = useState<AddGoalActionParams>(initialParams);
    useEffect(() => {
        props.isOpen && init();
        return () => {
            setParams(initialParams);
            setState(() => ({ ...state, tab: 'title', open: false, allIndicators: [] }));
        };
    }, [props.isOpen]);
    function init(): void {
        setParams((state) => ({
            ...state,
            goalId: props.goal!.id,
            goalLength: props.goal!.actions.length,
            action: {
                title: '',
                additionalColumn: '',
                evaluationGauge: '',
                resources: '',
                sotkanetIndicators: [],
                organizationIndicators: [],
                otherIndicators: [],
            },
        }));

        setState((state) => ({
            ...state,
            tab: 'title',
            open: false,
            allIndicators: [],
        }));
    }

    async function addGoalAction(): Promise<void> {
        try {
            loadingIndicator.show();
            await document.addGoalAction(props.focusArea!.id, params);
            snackbar.showSuccess();
        } catch (error) {
            snackbar.showError(error.data?.code);
        } finally {
            props.close();
            loadingIndicator.hide();
        }
    }

    async function saveIndicators(indicators: AddIndicatorDialogIndicator[]): Promise<void> {
        const updatedParams = {
            ...params,
            action: {
                ...params.action,
                sotkanetIndicators: [...params.action.sotkanetIndicators],
                organizationIndicators: [...params.action.organizationIndicators],
                otherIndicators: [...params.action.otherIndicators],
            },
        };

        indicators.forEach((indicator: AddIndicatorDialogIndicator) => {
            switch (indicator.source.code) {
                case 'CHP_SOTKANET':
                case 'SOTKANET': {
                    updatedParams.action.sotkanetIndicators.push(indicator);
                    break;
                }
                case 'CHP_CUSTOM':
                case 'CUSTOM': {
                    updatedParams.action.organizationIndicators.push(indicator);
                    break;
                }
                case 'CHP_OTHER':
                case 'OTHER': {
                    updatedParams.action.otherIndicators.push(indicator);
                    break;
                }
                default:
                    break;
            }

            setParams(updatedParams);
            setState((state) => ({
                ...state,
                allIndicators: [...state.allIndicators, indicator],
            }));
        });
    }

    async function removeIndicator(removedIndicator: AddIndicatorDialogIndicator): Promise<void> {
        const confirmation = await dialog.getConfirmation(undefined, undefined, true);
        if (!confirmation) return;

        setAllIndicators(state.allIndicators.filter((indicator) => indicator.id !== removedIndicator.id));

        const updatedParams = {
            ...params,
            action: {
                ...params.action,
                sotkanetIndicators: [...params.action.sotkanetIndicators],
                organizationIndicators: [...params.action.organizationIndicators],
                otherIndicators: [...params.action.otherIndicators],
            },
        };

        switch (removedIndicator.source.code) {
            case 'CHP_SOTKANET':
            case 'SOTKANET': {
                updatedParams.action.sotkanetIndicators.push(removedIndicator);
                break;
            }
            case 'CHP_CUSTOM':
            case 'CUSTOM': {
                updatedParams.action.organizationIndicators.push(removedIndicator);
                break;
            }
            case 'CHP_OTHER':
            case 'OTHER': {
                updatedParams.action.otherIndicators.push(removedIndicator);
                break;
            }
            default:
                break;
        }

        setParams(updatedParams);
    }

    function setAllIndicators(indicators: AddIndicatorDialogIndicator[]): void {
        setState((state) => ({
            ...state,
            allIndicators: indicators,
        }));
    }

    const handleDialog = (): void => {
        setState((state) => ({ ...state, open: !state.open }));
    };

    const handleTitleChange = (value: string): void => {
        setParams((state) => ({
            ...state,
            action: { ...state.action, title: value },
        }));
    };

    const handleResourceChange = (value: string): void => {
        setParams((state) => ({
            ...state,
            action: { ...state.action, resources: value },
        }));
    };

    const handleEvaluationGaugeChange = (value: string): void => {
        setParams((state) => ({
            ...state,
            action: { ...state.action, evaluationGauge: value },
        }));
    };

    const handleAdditionalColumnByActionTitleChange = (value: string): void => {
        setParams((state) => ({
            ...state,
            action: { ...state.action, additionalColumn: value },
        }));
    };

    const handleTabChange = (_event: React.ChangeEvent<unknown>, tab: string): void => {
        setState((state) => ({
            ...state,
            tab: tab,
        }));
    };

    const { translate } = localization;
    const { focusAreaTableTitles } = document.currentDocument;

    return (
        <StyledDialog id="edit-action-dialog" open={props.isOpen} onClose={props.close} maxWidth="lg" fullWidth>
            <DialogTitle>{translate('ADD_GOAL_ACTION')}</DialogTitle>
            <DialogContent>
                <TabContext value={state.tab}>
                    <Tabs
                        value={state.tab}
                        indicatorColor="primary"
                        textColor="primary"
                        onChange={handleTabChange}
                        variant="fullWidth"
                    >
                        <Tab label={focusAreaTableTitles?.actionTitle} value={'title'} />
                        <Tab label={focusAreaTableTitles?.resourceTitle} value={'resources'} />
                        <Tab label={focusAreaTableTitles?.evaluationGaugeTitle} value={'evaluationGauge'} />
                        {focusAreaTableTitles?.additionalColumnByActionTitle && (
                            <Tab
                                label={focusAreaTableTitles?.additionalColumnByActionTitle}
                                value={'additionalColumnByActionTitle'}
                            />
                        )}
                        <Tab label={translate('INDICATOR')} value={'indicators'} />
                    </Tabs>
                    <TabPanel value={'title'} className={classes.tabPanel}>
                        <FroalaEditorComponent
                            tag="textarea"
                            model={params.action!.title}
                            onModelChange={handleTitleChange}
                            config={{ ...document.froalaFocusAreaConfig }}
                        />
                    </TabPanel>
                    <TabPanel value={'resources'} className={classes.tabPanel}>
                        <FroalaEditorComponent
                            tag="textarea"
                            model={params.action!.resources}
                            onModelChange={handleResourceChange}
                            config={{ ...document.froalaFocusAreaConfig }}
                        />
                    </TabPanel>
                    <TabPanel value={'evaluationGauge'} className={classes.tabPanel}>
                        <FroalaEditorComponent
                            tag="textarea"
                            model={params.action!.evaluationGauge}
                            onModelChange={handleEvaluationGaugeChange}
                            config={{ ...document.froalaFocusAreaConfig }}
                        />
                    </TabPanel>
                    {focusAreaTableTitles?.additionalColumnByActionTitle && (
                        <TabPanel value={'additionalColumnByActionTitle'} className={classes.tabPanel}>
                            <FroalaEditorComponent
                                tag="textarea"
                                model={params.action!.additionalColumn}
                                onModelChange={handleAdditionalColumnByActionTitleChange}
                                config={{ ...document.froalaFocusAreaConfig }}
                            />
                        </TabPanel>
                    )}
                    <TabPanel value={'indicators'} className={classes.tabPanel}>
                        <div className={classes.tableWrapper}>
                            <Table className={classes.table}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.tableCell} style={{ width: '80%' }}>
                                            {translate('INDICATOR')}
                                        </TableCell>
                                        <TableCell className={classes.tableCell}>{translate('ACTIONS')}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {state.allIndicators.map((indicator: AddIndicatorDialogIndicator) => (
                                        <TableRow key={indicator.id}>
                                            <TableCell className={classes.tableCell}>{indicator.name}</TableCell>
                                            <TableCell className={classes.tableCell}>
                                                <Tooltip title={translate('SAVE_ACTION_FIRST')}>
                                                    <IconButton size="small" sx={{ cursor: 'default' }}>
                                                        <Timeline />
                                                    </IconButton>
                                                </Tooltip>
                                                <DangerIconButton
                                                    size="small"
                                                    onClick={(): Promise<void> => removeIndicator(indicator)}
                                                >
                                                    <Delete />
                                                </DangerIconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </div>
                    </TabPanel>
                </TabContext>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={props.close}>
                    {translate('CLOSE')}
                </Button>
                {state.tab === 'indicators' && (
                    <ShvkButton onClick={handleDialog}>{translate('ADD_INDICATOR')}</ShvkButton>
                )}
                <Box flexGrow={1} />
                <ShvkButton onClick={addGoalAction}>{translate('SAVE')}</ShvkButton>
            </DialogActions>
            <AddIndicatorDialog isOpen={state.open} close={handleDialog} saveIndicators={saveIndicators} sotkanetOnly />
        </StyledDialog>
    );
}

export default observer(AddFocusAreaActionDialog);
