import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Box, Button, Container, IconButton, Menu, MenuItem, Toolbar, Tooltip, Typography } from '@mui/material';
import { Business, Info, InsertDriveFile, PersonalVideo, Settings } from '@mui/icons-material';
import { ButtonEvent } from '../../types/events';
import UserInfoDialog from '../user/UserInfoDialog';
import ColorDialog from '../user/ColorDialog';
import SelectOrganizationPopover from './SelectOrganizationPopover';
import useStore from '../../store/storeContext';

export type UserBarMenu = 'settingsMenu' | 'organizationMenu';
export type UserBarDialog = 'isColorDialogOpen' | 'isUserInfoDialogOpen';

interface State {
    settingsMenu: HTMLElement | null;
    organizationMenu: HTMLElement | null;
    isColorDialogOpen: boolean;
    isUserInfoDialogOpen: boolean;
}

function UserBar() {
    const { document, user, session, localization, dialog, snackbar, theming } = useStore();

    const navigate = useNavigate();

    const [state, setState] = useState<State>({
        settingsMenu: null,
        organizationMenu: null,
        isColorDialogOpen: false,
        isUserInfoDialogOpen: false,
    });

    useEffect(() => {
        setTimeout(async () => {
            try {
                await user.removeUserSelectedOrganization();
                await document.closeCurrentDocument();
                await session.logout();
                navigate('/');
            } catch (error) {
                snackbar.showError(error.data?.code);
            }
        }, session.logoutTime * 60000);
    }, []);

    async function handleHomeClick(): Promise<void> {
        await document.closeCurrentDocument();
        navigate('/home');
    }

    async function handleDesktopClick(): Promise<void> {
        await document.closeCurrentDocument();
        navigate('/desktop');
    }

    const handleDialogState = (dialog: UserBarDialog, isOpen: boolean): void => {
        setState((state) => ({ ...state, [dialog]: isOpen }));
    };

    const handleClick = (event: ButtonEvent, menu: UserBarMenu): void => {
        setState((state) => ({ ...state, [menu]: event.currentTarget }));
    };

    const handleClose = (menu: UserBarMenu): void => {
        setState((state) => ({ ...state, [menu]: null }));
    };

    const { translate } = localization;
    const { userDetails } = user;

    return (
        <>
            <Box
                style={{
                    backgroundColor: theming.palette.primary.main,
                    color: theming.palette.common.white,
                }}
            >
                <Container>
                    <Toolbar disableGutters>
                        <Tooltip arrow title={translate('MY_INFO')}>
                            <Typography>
                                <Button color="inherit" onClick={() => handleDialogState('isUserInfoDialogOpen', true)}>
                                    {userDetails?.fullName + ' (' + userDetails?.userName + ')'}
                                </Button>
                            </Typography>
                        </Tooltip>
                        <div style={{ flexGrow: 1 }} />
                        <Typography>{userDetails?.selectedOrganization.name}</Typography>
                        <Tooltip arrow title={translate('SELECT_ORGANIZATION_TITLE')}>
                            <IconButton
                                aria-label={translate('SELECT_ORGANIZATION_TITLE')}
                                aria-controls="select-organization-popup"
                                aria-haspopup="true"
                                onClick={(event): void => handleClick(event, 'organizationMenu')}
                                color="inherit"
                                size="large"
                            >
                                <Business />
                            </IconButton>
                        </Tooltip>
                        <SelectOrganizationPopover anchorEl={state.organizationMenu} handleClose={handleClose} />
                        <Tooltip arrow title={translate('USER_OWN_DOCUMENTS_TIP')}>
                            <IconButton
                                aria-label={translate('USER_OWN_DOCUMENTS_TIP')}
                                onClick={handleHomeClick}
                                color="inherit"
                                size="large"
                            >
                                <InsertDriveFile />
                            </IconButton>
                        </Tooltip>
                        <Tooltip arrow title={translate('DESKTOP')}>
                            <IconButton
                                aria-label={translate('DESKTOP')}
                                onClick={handleDesktopClick}
                                color="inherit"
                                size="large"
                            >
                                <PersonalVideo />
                            </IconButton>
                        </Tooltip>
                        <Tooltip arrow title={translate('SETTINGS')}>
                            <IconButton
                                name="settings"
                                aria-label={translate('SETTINGS')}
                                aria-controls="settings-menu"
                                onClick={(event): void => handleClick(event, 'settingsMenu')}
                                aria-haspopup="true"
                                color="inherit"
                                size="large"
                            >
                                <Settings />
                            </IconButton>
                        </Tooltip>
                        <Menu
                            id="settings-menu"
                            anchorEl={state.settingsMenu}
                            keepMounted
                            open={Boolean(state.settingsMenu)}
                            onClose={(): void => handleClose('settingsMenu')}
                        >
                            <MenuItem
                                key={1}
                                onClick={(): void => {
                                    handleClose('settingsMenu');
                                    handleDialogState('isUserInfoDialogOpen', true);
                                }}
                            >
                                {translate('MY_INFO')}
                            </MenuItem>
                            <MenuItem
                                key={2}
                                onClick={(): void => {
                                    handleClose('settingsMenu');
                                    handleDialogState('isColorDialogOpen', true);
                                }}
                            >
                                {translate('USER_SETTINGS_COLOR_SCHEME')}
                            </MenuItem>
                        </Menu>
                        <Tooltip arrow title={translate('APP_VERSION_INFO')}>
                            <IconButton
                                aria-label={translate('APP_VERSION_INFO')}
                                aria-controls=""
                                onClick={dialog.openVersionInfoDialog}
                                color="inherit"
                                size="large"
                            >
                                <Info />
                            </IconButton>
                        </Tooltip>
                    </Toolbar>
                </Container>
            </Box>
            <UserInfoDialog
                open={state.isUserInfoDialogOpen}
                onClose={() => handleDialogState('isUserInfoDialogOpen', false)}
            />
            <ColorDialog open={state.isColorDialogOpen} onClose={() => handleDialogState('isColorDialogOpen', false)} />
        </>
    );
}

export default observer(UserBar);
