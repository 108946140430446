import Axios from '../../axiosConfig';
import { BACKEND_URL } from '../../config';
import { configure, runInAction, makeAutoObservable } from 'mobx';
import Directory, { SaveDirectoryParams } from '../../types/directory';
import RootModule from '../rootModule';

configure({ enforceActions: 'observed' });

/**Provides directory related variables and methods */
export default class DirectoryModule {
    private rootModule: RootModule;
    private directories: Directory[] = [];

    constructor(rootModule: RootModule) {
        makeAutoObservable(this, {}, { autoBind: true });
        this.rootModule = rootModule;
    }

    public async fetchDirectories(): Promise<void> {
        const response = await Axios({
            method: 'POST',
            url: BACKEND_URL + 'document/getDirectories',
            data: {
                organizationId: this.rootModule.organization.currentOrganizationId,
            },
        });
        runInAction(() => {
            this.directories = [
                ...response.data.shvkDirectories,
                ...response.data.evaDirectories,
                ...response.data.anotherDirectories,
            ];
        });
    }

    public async saveDirectories(directoryData: SaveDirectoryParams, organizationId: number): Promise<void> {
        await Axios({
            method: 'POST',
            url: BACKEND_URL + 'document/saveDirectories',
            data: {
                directoryData,
                organizationId,
            },
        });
    }
    public get evaDirectories(): Directory[] {
        return this.directories.filter((d) => d.type === 'EVA');
    }

    public get shvkDirectories(): Directory[] {
        return this.directories.filter((d) => d.type === 'SHVK');
    }

    public get anotherDirectories(): Directory[] {
        return this.directories.filter((d) => d.type === 'ANOTHER');
    }
}
