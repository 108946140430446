import React, { useState, useEffect } from 'react';
import { observer, useLocalObservable } from 'mobx-react-lite';
import {
    Box,
    CircularProgress,
    Container,
    Divider,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    Pagination,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import HelpTextDialog from '../components/admin/HelpTextDialog';
import HelpText from '../types/helpText';
import useStore from '../store/storeContext';

const PREFIX = 'HelpTexts';

const classes = {
    table: `${PREFIX}-table`,
    cell: `${PREFIX}-cell`,
    row: `${PREFIX}-row`,
};

const Root = styled('div')(() => ({
    [`& .${classes.table}`]: {
        tableLayout: 'fixed',
    },

    [`& .${classes.cell}`]: {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    },

    [`& .${classes.row}`]: {
        cursor: 'pointer',
    },
}));

interface State {
    fetching: boolean;
    selectedItem: HelpText | null;
    dialogOpen: boolean;
}

interface StoreState {
    page: number;
    pageCount: number;
    pageContent: HelpText[];
    handlePageChange: <T>(_event: React.ChangeEvent<T>, value: number) => void;
}

const itemsPerPage: Readonly<number> = 10;

function HelpTexts() {
    const { admin, snackbar, localization } = useStore();

    const [state, setState] = useState<State>({
        fetching: false,
        selectedItem: null,
        dialogOpen: false,
    });

    const localStore = useLocalObservable<StoreState>(() => ({
        page: 1,

        get pageCount(): number {
            return Math.ceil(admin.helpTexts.length / itemsPerPage);
        },

        get pageContent(): HelpText[] {
            const pageFirstIndex = (this.page - 1) * itemsPerPage;
            return admin.helpTexts.slice(pageFirstIndex, pageFirstIndex + itemsPerPage);
        },

        handlePageChange(_event: React.ChangeEvent<unknown>, value: number): void {
            this.page = value;
        },
    }));

    useEffect(() => {
        void getData();
    }, []);

    async function getData(): Promise<void> {
        setState((state) => ({ ...state, fetching: true }));
        try {
            await admin.fetchAllHelpTexts();
        } catch {
            snackbar.showFetchFailedMessage();
        } finally {
            setState((state) => ({ ...state, fetching: false }));
        }
    }

    const openDialog = (item: HelpText): void => {
        setState((state) => ({ ...state, dialogOpen: true, selectedItem: item }));
    };

    const closeDialog = (): void => {
        setState((state) => ({ ...state, dialogOpen: false, selectedItem: null }));
    };

    const { translate } = localization;

    return (
        <Root>
            <Container>
                <Box my={3}>
                    <Paper>
                        <Box px={2} py={1} textAlign="center">
                            <Typography variant="h6">{translate('HELP_TEXT_MANAGEMENT')}</Typography>
                        </Box>
                        <Divider />
                        <Box m={2}>
                            {state.fetching ? (
                                <Box textAlign="center">
                                    <CircularProgress />
                                </Box>
                            ) : (
                                <Table
                                    aria-label={translate('HELP_TEXT_MANAGEMENT')}
                                    className={classes.table}
                                    size="small"
                                >
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>{translate('HELP_TEXT_LOCATION')}</TableCell>
                                            <TableCell>{translate('FI_TRANSLATION')}</TableCell>
                                            <TableCell>{translate('SE_TRANSLATION')}</TableCell>
                                            <TableCell>{translate('EN_TRANSLATION')}</TableCell>
                                            <TableCell>{translate('HELP_TEXT_DESCRIPTION')}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {localStore.pageContent.map((row) => (
                                            <TableRow
                                                key={row.key}
                                                hover
                                                className={classes.row}
                                                onClick={(): void => openDialog(row)}
                                            >
                                                <TableCell className={classes.cell}>{row.location}</TableCell>
                                                <TableCell className={classes.cell}>{row.fi_fi}</TableCell>
                                                <TableCell className={classes.cell}>{row.sv_se}</TableCell>
                                                <TableCell className={classes.cell}>{row.en_gb}</TableCell>
                                                <TableCell className={classes.cell}>{row.description}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            )}
                        </Box>
                        <Box px={2} pb={2} display="flex">
                            <Pagination
                                count={localStore.pageCount}
                                page={localStore.page}
                                onChange={localStore.handlePageChange}
                                color="primary"
                            />
                        </Box>
                    </Paper>
                </Box>
            </Container>
            <HelpTextDialog open={state.dialogOpen} onClose={closeDialog} helpText={state.selectedItem} />
        </Root>
    );
}

export default observer(HelpTexts);
