import React from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Paper, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import InfoIcon from '@mui/icons-material/Info';
import useStore from '../../../store/storeContext';

const PREFIX = 'IndicatorPackageThemeItemTable';

const classes = {
    tableBody: `${PREFIX}-tableBody`,
    paper: `${PREFIX}-paper`,
};

const StyledBox = styled(Box)({
    [`& .${classes.tableBody}`]: {
        '& .MuiTableRow-root': {
            verticalAlign: 'top',
        },
    },

    [`& .${classes.paper}`]: {
        overflowX: 'auto',
    },
});

interface Props {
    children: JSX.Element | JSX.Element[];
}
function IndicatorPackageThemeItemTable(props: Props) {
    const { indicator, localization } = useStore();

    const { translate } = localization;

    return (
        <StyledBox mt={2} mb={4}>
            <Paper className={classes.paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>{translate('EVA_INDICATORS')}</TableCell>
                            {indicator.getIndicatorViewMode === 'WIDE_MODE' ? (
                                <>
                                    <TableCell>
                                        <Box display="flex" alignItems="center" justifyContent="center">
                                            <Box flexShrink={0}>{translate('CHANGE')} (%)</Box>
                                            <Tooltip
                                                placement={'top'}
                                                sx={{ ml: 0.5 }}
                                                title={translate('muutos_sarakeotsikko_ind_lista_5')}
                                            >
                                                <InfoIcon color="primary" fontSize={'small'} />
                                            </Tooltip>
                                        </Box>
                                    </TableCell>
                                    <TableCell>
                                        <Box display="flex" alignItems="center" justifyContent="center">
                                            {translate('DIRECTION')}
                                            <Tooltip
                                                placement={'top'}
                                                sx={{ ml: 0.5 }}
                                                title={translate('suunta_sarakeotsikko_ind_lista_6')}
                                            >
                                                <InfoIcon color="primary" fontSize={'small'} />
                                            </Tooltip>
                                        </Box>
                                    </TableCell>
                                    <TableCell>
                                        <Box display="flex" alignItems="center" justifyContent="center">
                                            {translate('COMPARISON')}
                                            <Tooltip
                                                placement={'top'}
                                                sx={{ ml: 0.5 }}
                                                title={
                                                    <span style={{ whiteSpace: 'pre-line' }}>
                                                        {translate('vertailu_sarakeotsikko_ind_lista_7')}{' '}
                                                    </span>
                                                }
                                            >
                                                <InfoIcon color="primary" fontSize={'small'} />
                                            </Tooltip>
                                        </Box>
                                    </TableCell>
                                </>
                            ) : null}
                        </TableRow>
                    </TableHead>
                    <TableBody className={classes.tableBody}>{props.children}</TableBody>
                </Table>
            </Paper>
        </StyledBox>
    );
}

export default observer(IndicatorPackageThemeItemTable);
