import { configure, makeAutoObservable } from 'mobx';

configure({ enforceActions: 'observed' });

export default class LoadingIndicatorModule {
    private loading = false;
    private login = false;

    constructor() {
        makeAutoObservable(this, {}, { autoBind: true });
    }

    public get isLoading(): boolean {
        return this.loading;
    }

    public get isLoggingIn(): boolean {
        return this.login;
    }

    public show(): void {
        this.loading = true;
    }

    public hide(): void {
        this.loading = false;
    }

    public showLogin(): void {
        this.login = true;
    }

    public hideLogin(): void {
        this.login = false;
    }
}
