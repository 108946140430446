import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import FroalaEditorComponent from 'react-froala-wysiwyg';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    IconButton,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Tabs,
} from '@mui/material';
import { Delete, Timeline } from '@mui/icons-material';
import { TabContext, TabPanel } from '@mui/lab';
import { styled } from '@mui/material/styles';
import { FocusArea, FocusAreaGoal, FocusAreaGoalAction, SaveGoalActionParams } from '../../../../types/document';
import { AddIndicatorDialogIndicator, Indicator } from '../../../../types/indicator';
import AddIndicatorDialog from '../AddIndicatorDialog';
import DangerIconButton from '../../../../styled/DangerIconButton';
import ShvkButton from '../../../../styled/ShvkButton';
import useStore, { rootModule } from '../../../../store/storeContext';

const PREFIX = 'EditActionDialog';

const classes = {
    tabPanel: `${PREFIX}-tabPanel`,
    tableCell: `${PREFIX}-tableCell`,
    table: `${PREFIX}-table`,
    tableWrapper: `${PREFIX}-tableWrapper`,
};

const { theming } = rootModule;

const StyledDialog = styled(Dialog)(({ theme }) => ({
    [`& .${classes.tabPanel}`]: {
        minHeight: '300px',
    },

    [`& .${classes.tableCell}`]: {
        border: '1px solid' + theming.shvkLightGrey,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(0.5),
    },

    [`& .${classes.table}`]: {
        '& p': {
            margin: 0,
            padding: 0,
        },
    },

    [`& .${classes.tableWrapper}`]: {
        width: '70%',
        marginLeft: 'auto',
        marginRight: 'auto',
        overFlowX: 'auto',
    },
}));

interface Props {
    isOpen: boolean;
    close(): void;
    focusArea: FocusArea;
    goal: FocusAreaGoal;
    action: FocusAreaGoalAction;
    tab: string;
}

interface State {
    tab: string;
    open: boolean;
    allIndicators: Indicator[];
}

const initialParams: SaveGoalActionParams = {
    title: '',
    id: -1,
    additionalColumn: '',
    evaluationGauge: '',
    resources: '',
    organizationIndicators: [],
    sotkanetIndicators: [],
    otherIndicators: [],
};

function EditActionDialog(props: Props) {
    const { document, dialog, localization, loadingIndicator, snackbar } = useStore();

    const [state, setState] = useState<State>({
        tab: 'title',
        open: false,
        allIndicators: [],
    });

    const [saveGoalActionParams, setSaveGoalActionParams] = useState<SaveGoalActionParams>(initialParams);

    useEffect(() => {
        props.isOpen && init();
        return () => {
            setSaveGoalActionParams(initialParams);
            setState((state) => ({ ...state, tab: 'title', open: false, allIndicators: [] }));
        };
    }, [props.isOpen]);

    function init(): void {
        const params = {
            id: props.action.id,
            title: props.action.title,
            resources: props.action.resources,
            evaluationGauge: props.action.evaluationGauge,
            additionalColumn: props.action.additionalColumn,
            organizationIndicators: props.action.organizationIndicators,
            sotkanetIndicators: props.action.sotkanetIndicators,
            otherIndicators: props.action.otherIndicators,
        };
        setState((state) => ({ ...state, tab: state.tab !== 'indicators' ? props.tab : 'indicators' }));
        setSaveGoalActionParams(params);
        setAllIndicators([
            ...props.action.sotkanetIndicators,
            ...props.action.organizationIndicators,
            ...props.action.otherIndicators,
        ]);
    }

    async function save(): Promise<void> {
        try {
            loadingIndicator.show();
            await document.saveGoalAction(props.focusArea.id, props.goal.id, saveGoalActionParams);
            snackbar.showSuccess();
        } catch (error) {
            snackbar.showError(error.data?.code);
        } finally {
            props.close();
            loadingIndicator.hide();
        }
    }

    async function saveIndicators(indicators: AddIndicatorDialogIndicator[]): Promise<void> {
        const saveIndicatorParams = {
            ...saveGoalActionParams,
            sotkanetIndicators: [...saveGoalActionParams.sotkanetIndicators] as AddIndicatorDialogIndicator[],
            organizationIndicators: [...saveGoalActionParams.organizationIndicators] as AddIndicatorDialogIndicator[],
            otherIndicators: [...saveGoalActionParams.otherIndicators] as AddIndicatorDialogIndicator[],
        };

        indicators.forEach((indicator: AddIndicatorDialogIndicator) => {
            switch (indicator.source.code) {
                case 'CHP_SOTKANET':
                case 'SOTKANET': {
                    saveIndicatorParams.sotkanetIndicators.push(indicator);
                    break;
                }
                case 'CHP_CUSTOM':
                case 'CUSTOM': {
                    saveIndicatorParams.organizationIndicators.push(indicator);
                    break;
                }
                case 'CHP_OTHER':
                case 'OTHER': {
                    saveIndicatorParams.otherIndicators.push(indicator);
                    break;
                }
                default:
                    break;
            }
        });

        try {
            loadingIndicator.show();
            const updatedAction = await document.saveGoalAction(props.focusArea.id, props.goal.id, saveIndicatorParams);
            setSaveGoalActionParams(saveIndicatorParams);
            setAllIndicators([
                ...updatedAction.sotkanetIndicators,
                ...updatedAction.organizationIndicators,
                ...updatedAction.otherIndicators,
            ]);
            snackbar.showSuccess();
        } catch (error) {
            snackbar.showError(error.data?.code);
        } finally {
            loadingIndicator.hide();
        }
    }

    async function removeIndicator(removedIndicator: Indicator): Promise<void> {
        const { sotkanetIndicators, otherIndicators, organizationIndicators } = saveGoalActionParams;
        const confirmation = await dialog.getConfirmation(undefined, undefined, true);

        if (!confirmation) return;

        setAllIndicators(state.allIndicators.filter((indicator) => indicator.id !== removedIndicator.id));

        switch (removedIndicator.source.code) {
            case 'CHP_SOTKANET':
            case 'SOTKANET': {
                const filtered = sotkanetIndicators.filter((indicator) => indicator.id !== removedIndicator.id);
                setSaveGoalActionParams((params) => ({ ...params, sotkanetIndicators: filtered }));
                break;
            }
            case 'CHP_CUSTOM':
            case 'CUSTOM': {
                const filtered = organizationIndicators.filter((indicator) => indicator.id !== removedIndicator.id);
                setSaveGoalActionParams((params) => ({ ...params, organizationIndicators: filtered }));
                break;
            }
            case 'CHP_OTHER':
            case 'OTHER': {
                const filtered = otherIndicators.filter((indicator) => indicator.id !== removedIndicator.id);
                setSaveGoalActionParams((params) => ({ ...params, otherIndicators: filtered }));
                break;
            }
            default:
                break;
        }
    }

    function setAllIndicators(indicators: Indicator[]): void {
        setState((state) => ({ ...state, allIndicators: indicators }));
    }

    const handleTitleChange = (value: string): void => {
        setSaveGoalActionParams((params) => ({ ...params, title: value }));
    };

    const handleResourceChange = (value: string): void => {
        setSaveGoalActionParams((params) => ({ ...params, resources: value }));
    };

    const handleEvaluationGaugeChange = (value: string): void => {
        setSaveGoalActionParams((params) => ({ ...params, evaluationGauge: value }));
    };

    const handleAdditionalColumnByActionTitleChange = (value: string): void => {
        setSaveGoalActionParams((params) => ({ ...params, additionalColumn: value }));
    };

    const handleTabChange = (_event: React.ChangeEvent<unknown>, tab: string): void => {
        setState((state) => ({ ...state, tab }));
    };

    const handleDialog = (): void => {
        setState((state) => ({ ...state, open: !state.open }));
    };

    const handleIndicatorGraphClick = (indicator: Indicator): void => {
        dialog.openDocumentDialog('indicatorGraphDialog', { indicator, isTopRowIndicator: true });
    };

    const { translate } = localization;
    const { focusAreaTableTitles } = document.currentDocument;

    return (
        <StyledDialog id="edit-action-dialog" open={props.isOpen} onClose={props.close} maxWidth="lg" fullWidth>
            <DialogContent>
                <TabContext value={state.tab}>
                    <Tabs
                        value={state.tab}
                        indicatorColor="primary"
                        textColor="primary"
                        onChange={handleTabChange}
                        variant="fullWidth"
                    >
                        <Tab label={focusAreaTableTitles.actionTitle} value={'title'} />
                        <Tab label={focusAreaTableTitles.resourceTitle} value={'resources'} />
                        <Tab label={focusAreaTableTitles.evaluationGaugeTitle} value={'evaluationGauge'} />
                        {focusAreaTableTitles.additionalColumnByActionTitle && (
                            <Tab
                                label={focusAreaTableTitles.additionalColumnByActionTitle}
                                value={'additionalColumnByActionTitle'}
                            />
                        )}
                        <Tab label={translate('INDICATOR')} value={'indicators'} />
                    </Tabs>
                    <TabPanel value={'title'} className={classes.tabPanel}>
                        <FroalaEditorComponent
                            tag="textarea"
                            model={saveGoalActionParams.title}
                            onModelChange={handleTitleChange}
                            config={{ ...document.froalaFocusAreaConfig }}
                        />
                    </TabPanel>
                    <TabPanel value={'resources'} className={classes.tabPanel}>
                        <FroalaEditorComponent
                            tag="textarea"
                            model={saveGoalActionParams.resources}
                            onModelChange={handleResourceChange}
                            config={{ ...document.froalaFocusAreaConfig }}
                        />
                    </TabPanel>
                    <TabPanel value={'evaluationGauge'} className={classes.tabPanel}>
                        <FroalaEditorComponent
                            tag="textarea"
                            model={saveGoalActionParams.evaluationGauge}
                            onModelChange={handleEvaluationGaugeChange}
                            config={{ ...document.froalaFocusAreaConfig }}
                        />
                    </TabPanel>
                    {focusAreaTableTitles.additionalColumnByActionTitle && (
                        <TabPanel value={'additionalColumnByActionTitle'} className={classes.tabPanel}>
                            <FroalaEditorComponent
                                tag="textarea"
                                model={saveGoalActionParams.additionalColumn}
                                onModelChange={handleAdditionalColumnByActionTitleChange}
                                config={{ ...document.froalaFocusAreaConfig }}
                            />
                        </TabPanel>
                    )}
                    <TabPanel value={'indicators'} className={classes.tabPanel}>
                        <div className={classes.tableWrapper}>
                            <Table className={classes.table}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.tableCell} style={{ width: '80%' }}>
                                            {translate('INDICATOR')}
                                        </TableCell>
                                        <TableCell className={classes.tableCell}>{translate('ACTIONS')}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {state.allIndicators.map((indicator: Indicator) => (
                                        <TableRow key={indicator.id}>
                                            <TableCell className={classes.tableCell}>{indicator.name}</TableCell>
                                            <TableCell className={classes.tableCell}>
                                                <IconButton
                                                    size="small"
                                                    color="primary"
                                                    onClick={(): void => handleIndicatorGraphClick(indicator)}
                                                >
                                                    <Timeline />
                                                </IconButton>
                                                <DangerIconButton
                                                    size="small"
                                                    onClick={() => removeIndicator(indicator)}
                                                >
                                                    <Delete />
                                                </DangerIconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </div>
                    </TabPanel>
                </TabContext>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={props.close}>
                    {translate('CLOSE')}
                </Button>
                {state.tab === 'indicators' && (
                    <ShvkButton onClick={handleDialog}>{translate('ADD_INDICATOR')}</ShvkButton>
                )}
                <Box flexGrow={1} />
                <ShvkButton onClick={save}>{translate('SAVE')}</ShvkButton>
            </DialogActions>
            <AddIndicatorDialog
                isOpen={state.open}
                close={handleDialog}
                goalAction={props.action}
                saveIndicators={saveIndicators}
                sotkanetOnly
            />
        </StyledDialog>
    );
}

export default observer(EditActionDialog);
