import Axios from '../../axiosConfig';
import { BACKEND_URL } from '../../config';
import { configure, runInAction, makeAutoObservable } from 'mobx';
import AuditLogItem, { AuditLogRequestParams } from '../../types/auditLog';
import ConfigDataItem from '../../types/configData';
import LicenseSchema from '../../types/license';
import HelpText from '../../types/helpText';
import moment from 'moment';

configure({ enforceActions: 'observed' });

export default class AdminModule {
    private auditLogItems: AuditLogItem[] = [];
    private config: ConfigDataItem[] = [];
    private licenses: LicenseSchema[] = [];
    private infoTexts: HelpText[] = [];

    constructor() {
        makeAutoObservable(this, {}, { autoBind: true });
    }

    public async fetchAuditLog(requestParams: AuditLogRequestParams): Promise<void> {
        const response = await Axios({
            method: 'POST',
            url: BACKEND_URL + 'app/auditlog',
            data: requestParams,
        });
        if (response?.status === 200) runInAction(() => (this.auditLogItems = response.data));
    }

    public async fetchConfigData(): Promise<void> {
        const response = await Axios({
            method: 'GET',
            url: BACKEND_URL + 'configuration/controlData',
        });
        if (response?.status === 200) runInAction(() => (this.config = response.data));
    }

    public async saveConfigData(configData: ConfigDataItem): Promise<void> {
        const response = await Axios({
            method: 'POST',
            url: BACKEND_URL + 'configuration/save',
            data: configData,
        });

        const newItem: ConfigDataItem = response.data;
        const index = this.configData.findIndex((item) => item.id === newItem.id);
        runInAction(() => {
            if (index > -1) this.config[index] = newItem;
            else this.config.push(newItem);
        });
    }

    public async fetchLicenseSchemas(): Promise<void> {
        const response = await Axios({
            method: 'GET',
            url: BACKEND_URL + 'license/licenseSchemas',
        });
        runInAction(() => (this.licenses = response.data));
    }

    public async saveLicenseSchema(licenseSchema: LicenseSchema): Promise<void> {
        const response = await Axios({
            method: 'POST',
            url: BACKEND_URL + 'license/save',
            data: { licenseSchema },
        });

        const newItem: LicenseSchema = response.data;
        const index = this.licenseSchemas.findIndex((item) => item.id === newItem.id);
        runInAction(() => {
            if (index > -1) this.licenses[index] = newItem;
            else this.licenses.push(newItem);
        });
    }

    public async removeLicenseSchema(schemaId: number): Promise<void> {
        await Axios({
            method: 'POST',
            url: BACKEND_URL + 'license/remove',
            data: { schemaId },
        });

        runInAction(() => {
            this.licenses = this.licenses.filter((license) => license.id !== schemaId);
        });
    }

    public async fetchAllHelpTexts(): Promise<void> {
        const response = await Axios({
            method: 'POST',
            url: BACKEND_URL + 'helptext/getAllHelpTexts',
        });

        runInAction(() => {
            this.infoTexts = response.data;
        });
    }

    public async saveHelpText(helpText: HelpText): Promise<void> {
        const response = await Axios({
            method: 'POST',
            url: BACKEND_URL + 'helptext/save',
            data: helpText,
        });

        const newItem: HelpText = response.data;
        const index = this.helpTexts.findIndex((item) => item.location === newItem.location);
        runInAction(() => {
            if (index > -1) this.infoTexts[index] = newItem;
            else this.infoTexts.push(newItem);
        });
    }

    public get auditLog(): AuditLogItem[] {
        return this.auditLogItems;
    }

    public get configData(): ConfigDataItem[] {
        return this.config;
    }

    public get licenseSchemas(): LicenseSchema[] {
        return this.licenses;
    }

    public get helpTexts(): HelpText[] {
        return this.infoTexts.slice().sort((a, b) => Number(a.location) - Number(b.location));
    }

    public filteredAuditLog(filter: string): AuditLogItem[] {
        const stringifyItem = (item: AuditLogItem): string =>
            (
                item.action.toString() +
                item.object.toString() +
                item.username.toString() +
                item.status.toString()
            ).toLowerCase();
        return this.auditLog.filter((logItem) => stringifyItem(logItem).indexOf(filter.toLowerCase()) > -1);
    }

    public getLicenseSchemaById(id: number): LicenseSchema | null {
        return this.licenseSchemas.find((license) => license.id === id) || null;
    }

    public isLicenseExpired(endDate: string | Date): boolean {
        const today = moment().startOf('day');
        const end = moment(endDate).startOf('day');
        return today.isAfter(end);
    }

    public countDaysLeft(endDate: string | Date): number {
        const today = moment();
        const end = moment(endDate);
        const difference = end.diff(today, 'days');
        return difference < 0 ? 0 : difference;
    }
}
