import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import FroalaEditorComponent from 'react-froala-wysiwyg';
import { Box, Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Delete } from '@mui/icons-material';
import { FocusArea, FocusAreaGoal } from '../../../../types/document';
import ShvkButton from '../../../../styled/ShvkButton';
import DangerButton from '../../../../styled/DangerButton';
import useStore from '../../../../store/storeContext';

const PREFIX = 'EditGoalDialog';

const classes = {
    paper: `${PREFIX}-paper`,
};

const StyledDialog = styled(Dialog)(() => ({
    [`& .${classes.paper}`]: {
        height: 'auto',
    },
}));

interface Props {
    isOpen: boolean;
    close(): void;
    focusArea: FocusArea;
    goal: FocusAreaGoal;
}

interface State {
    title: string;
}

function EditGoalDialog(props: Props) {
    const { document, dialog, localization, loadingIndicator, snackbar } = useStore();

    const [state, setState] = useState<State>({
        title: '',
    });

    useEffect(() => {
        props.isOpen && setState((state) => ({ ...state, title: props.goal.title }));
    }, [props.isOpen]);

    const handleTitleChange = (title: string): void => {
        setState((state) => ({ ...state, title }));
    };

    async function save(): Promise<void> {
        try {
            loadingIndicator.show();
            await document.saveGoal(props.focusArea.id, props.goal.id, state.title);
            snackbar.showSuccess();
        } catch (error) {
            snackbar.showError(error.data?.code);
        } finally {
            props.close();
            loadingIndicator.hide();
        }
    }

    async function remove(): Promise<void> {
        const confirmation = await dialog.getConfirmation(undefined, undefined, true);
        if (!confirmation) return;

        try {
            loadingIndicator.show();
            await document.removeGoal(props.focusArea.id, props.goal.id);
            snackbar.showSuccess();
        } catch (error) {
            snackbar.showError(error.data?.code);
        } finally {
            props.close();
            loadingIndicator.hide();
        }
    }

    const { translate } = localization;
    const { isOpen, close } = props;

    return (
        <StyledDialog
            id="edit-goal-dialog"
            open={isOpen}
            onClose={close}
            classes={{ paper: classes.paper }}
            maxWidth="lg"
            fullWidth
        >
            <DialogContent>
                <FroalaEditorComponent
                    tag="textarea"
                    model={state.title}
                    onModelChange={handleTitleChange}
                    config={{ ...document.froalaFocusAreaConfig }}
                />
            </DialogContent>
            <DialogActions>
                <DangerButton variant="contained" onClick={remove} startIcon={<Delete />}>
                    {translate('REMOVE')}
                </DangerButton>
                <Box flexGrow={1} />
                <Button variant="contained" onClick={close}>
                    {translate('CLOSE')}
                </Button>
                <ShvkButton onClick={save}>{translate('SAVE')}</ShvkButton>
            </DialogActions>
        </StyledDialog>
    );
}

export default observer(EditGoalDialog);
