import React from 'react';
import { observer } from 'mobx-react-lite';
import { Grid, TableCell, TableRow, Tooltip, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { EditorDecisionOption, EditorImpactOnTarget } from '../../../../types/evaTemplate';
import EvaCircle from './EvaCircle';
import InfoIcon from '@mui/icons-material/Info';
import useStore, { rootModule } from '../../../../store/storeContext';

const PREFIX = 'ComparisonTableImpactOnTargetRows';

const classes = {
    tableInnerCell: `${PREFIX}-tableInnerCell`,
    lastRowTableInnerCell: `${PREFIX}-lastRowTableInnerCell`,
};

const { theming } = rootModule;

const StyledTableRow = styled(TableRow)(() => ({
    [`& .${classes.tableInnerCell}`]: {
        borderLeft: '1px solid' + theming.shvkLightGrey,
        borderRight: '1px solid' + theming.shvkLightGrey,
        borderBottom: '0',
    },

    [`& .${classes.lastRowTableInnerCell}`]: {
        borderLeft: '1px solid' + theming.shvkLightGrey,
        borderRight: '1px solid' + theming.shvkLightGrey,
    },
}));

interface Props {
    impact: EditorImpactOnTarget;
    perspectiveIndex: number;
    impactIndex: number;
    editing: boolean;
}

function ComparisonTableImpactOnTargetRows(props: Props) {
    const { document, dialog, localization } = useStore();

    function getIsEditable(): boolean {
        return !document.isCurrentDocumentApproved && !document.isPreview && props.editing;
    }

    const openImpactOnTargetDialog = (decisionOption: EditorDecisionOption): void => {
        if (isEditable) {
            const perspective = decisionOption.evaPerspectives[props.perspectiveIndex];
            const impactOnTarget = perspective.impactTargets[props.impactIndex];
            dialog.openDocumentDialog('impactOnTargetDialog', {
                decisionOption,
                perspective,
                impactOnTarget,
            });
        }
    };

    const openEditImpactOnTargetNameDialog = (): void => {
        if (isEditable) {
            const impactOnTarget = props.impact;
            dialog.openDocumentDialog('editImpactOnTargetNameDialog', { impactOnTarget });
        }
    };

    const isEditable = getIsEditable();

    const { impact, perspectiveIndex, impactIndex } = props;
    const { evaDecisionOptionChapter } = document;
    const { translate } = localization;

    const cursorStyleAndWidthOfCells = {
        cursor: isEditable ? 'pointer' : 'auto',
        width: evaDecisionOptionChapter
            ? `${(1 / (evaDecisionOptionChapter.evaDecisionOptions.length + 1)) * 100}%`
            : '100%',
    };

    return (
        <React.Fragment key={impact.id}>
            {/* FIRST ROW --- IMPACT ON TARGET NAME*/}
            <StyledTableRow>
                {/*Name of the impact on target*/}
                <TableCell
                    className={classes.tableInnerCell}
                    onClick={openEditImpactOnTargetNameDialog}
                    style={cursorStyleAndWidthOfCells}
                >
                    <Grid container direction="row" alignItems="center">
                        <Typography variant="subtitle2" sx={{ wordBreak: 'break-word' }}>
                            {impact.target}
                        </Typography>
                        {impact.info && (
                            <Tooltip title={impact.info}>
                                <InfoIcon
                                    color="primary"
                                    style={{
                                        display: 'inline-block',
                                        verticalAlign: 'sub',
                                        marginLeft: theming.spacing(1),
                                    }}
                                />
                            </Tooltip>
                        )}
                    </Grid>
                </TableCell>
                {evaDecisionOptionChapter?.evaDecisionOptions.map((decisionOption) => (
                    // Empty cells for every decision option
                    <TableCell
                        key={decisionOption.id}
                        className={classes.tableInnerCell}
                        onClick={(): void => openImpactOnTargetDialog(decisionOption)}
                        style={cursorStyleAndWidthOfCells}
                    />
                ))}
            </StyledTableRow>

            {/* SECOND ROW --- SHORT TERM*/}
            <StyledTableRow>
                {/* Short term title */}
                <TableCell className={classes.tableInnerCell} style={{ width: cursorStyleAndWidthOfCells.width }}>
                    <i>{translate('EVA_TERM_EFFECT_PART_1_SHORT') + ' ' + translate('EVA_TERM_EFFECT_PART_3')}</i>
                </TableCell>
                {evaDecisionOptionChapter?.evaDecisionOptions.map((decisionOption) => (
                    // Short term effect values of every decision option
                    <TableCell
                        key={decisionOption.id}
                        className={classes.tableInnerCell}
                        onClick={(): void => openImpactOnTargetDialog(decisionOption)}
                        style={cursorStyleAndWidthOfCells}
                    >
                        <EvaCircle
                            value={
                                decisionOption.evaPerspectives[perspectiveIndex]?.impactTargets[impactIndex]
                                    ?.shortTermEffect
                            }
                        />
                    </TableCell>
                ))}
            </StyledTableRow>

            {/* THIRD ROW --- LONG TERM */}
            <StyledTableRow>
                {/* Long term title */}
                <TableCell className={classes.tableInnerCell} style={{ width: cursorStyleAndWidthOfCells.width }}>
                    <i>{translate('EVA_TERM_EFFECT_PART_2_LONG') + ' ' + translate('EVA_TERM_EFFECT_PART_3')}</i>
                </TableCell>
                {evaDecisionOptionChapter?.evaDecisionOptions.map((decisionOption) => (
                    // Long term effect values of every decision option
                    <TableCell
                        key={decisionOption.id}
                        className={classes.tableInnerCell}
                        onClick={(): void => openImpactOnTargetDialog(decisionOption)}
                        style={cursorStyleAndWidthOfCells}
                    >
                        <EvaCircle
                            value={
                                decisionOption.evaPerspectives[perspectiveIndex]?.impactTargets[impactIndex]
                                    ?.longTermEffect
                            }
                        />
                    </TableCell>
                ))}
            </StyledTableRow>

            {/* FOURTH ROW --- DESCRIPTION */}
            <StyledTableRow>
                {/* Description title */}
                <TableCell
                    className={classes.lastRowTableInnerCell}
                    style={{ width: cursorStyleAndWidthOfCells.width }}
                >
                    <i>{translate('EVA_DECISION_OPTION_DESCRIPTION')}</i>
                </TableCell>
                {evaDecisionOptionChapter?.evaDecisionOptions.map((decisionOption) => (
                    // Description of every decision option
                    <TableCell
                        key={decisionOption.id}
                        className={classes.lastRowTableInnerCell}
                        onClick={(): void => openImpactOnTargetDialog(decisionOption)}
                        style={cursorStyleAndWidthOfCells}
                    >
                        <Typography sx={{ wordBreak: 'break-word', whiteSpace: 'pre-wrap' }}>
                            {decisionOption.evaPerspectives[perspectiveIndex]?.impactTargets[impactIndex]?.description}
                        </Typography>
                    </TableCell>
                ))}
            </StyledTableRow>
        </React.Fragment>
    );
}

export default observer(ComparisonTableImpactOnTargetRows);
