import React from 'react';
import { observer } from 'mobx-react-lite';
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import { Add, Remove } from '@mui/icons-material';
import { IndicatorValue } from '../../../types/indicator';
import { rootModule } from '../../../store/storeContext';

const PREFIX = 'IndicatorSlider';

const classes = {
    buttonArea: `${PREFIX}-buttonArea`,
    slider: `${PREFIX}-slider`,
    rangeIndicator: `${PREFIX}-rangeIndicator`,
    thumbText: `${PREFIX}-thumbText`,
    sliderNegative: `${PREFIX}-sliderNegative`,
    sliderPositive: `${PREFIX}-sliderPositive`,
    sliderNeutral: `${PREFIX}-sliderNeutral`,
};

const { theming } = rootModule;

const Root = styled('div')(({ theme }) => ({
    [`&.${classes.buttonArea}`]: {
        textAlign: 'center',
        width: '97px',
        height: '34px',
        background: 'transparent',
        border: '2px hidden',
        borderTopLeftRadius: '15px',
        borderTopRightRadius: '15px',
        borderBottomRightRadius: '15px',
        borderBottomLeftRadius: '15px',
        position: 'relative',
    },

    [`& .${classes.slider}`]: {
        appearance: 'none',
        height: '30px',
        margin: 0,
        width: '100%',
        position: 'relative',
        boxSizing: 'content-box',
        paddingTop: 0,
        backgroundColor: 'transparent',
        '&:focus': {
            outline: 'none',
        },
        '&::-webkit-slider-thumb': {
            boxShadow: '0 0 0 #000031',
            height: '28px',
            width: '28px',
            borderRadius: '50px',
            background: '#FFFFFF',
            cursor: 'pointer',
            appearance: 'none',
        },
        '&::-moz-range-thumb': {
            boxShadow: '0 0 0 #000031',
            height: '23px',
            width: '23px',
            borderRadius: '50px',
            background: '#FFFFFF',
            cursor: 'pointer',
            appearance: 'none',
        },
    },

    [`& .${classes.rangeIndicator}`]: {
        '&::-webkit-slider-runnable-track': {
            background: '#f7f7f7',

            width: '100%',
            height: '30px',
            cursor: 'pointer',
            boxShadow: 'none',
            borderRadius: '50px',
            border: '1px solid #DDDDDD',
        },
        '&::-moz-range-track': {
            background: '#f7f7f7',

            width: '100%',
            height: '30px',
            cursor: 'pointer',
            boxShadow: 'none',
            borderRadius: '50px',
            border: '1px solid #DDDDDD',
        },
        '&:focus': {
            '&::-webkit-slider-runnable-track': {
                background: '#f7f7f7',
            },
            '&::-moz-range-track': {
                background: '#f7f7f7',
            },
        },
    },

    [`& .${classes.thumbText}`]: {
        pointerEvents: 'none',
        position: 'absolute',
        top: '3px',
    },

    [`& .${classes.sliderNegative}`]: {
        '&::-webkit-slider-thumb': {
            border: '3px solid ' + theme.palette.error.main,
        },
        '&::-moz-range-thumb': {
            border: '3px solid ' + theme.palette.error.main,
        },
    },

    [`& .${classes.sliderPositive}`]: {
        '&::-webkit-slider-thumb': {
            border: '3px solid ' + theme.palette.warning.main,
        },
        '&::-moz-range-thumb': {
            border: '3px solid ' + theme.palette.warning.main,
        },
    },

    [`& .${classes.sliderNeutral}`]: {
        '&::-webkit-slider-thumb': {
            border: '3px solid ' + theming.shvkDarkGrey,
        },
        '&::-moz-range-thumb': {
            border: '3px solid ' + theming.shvkDarkGrey,
        },
    },
}));

interface Props {
    value: IndicatorValue;
    onChange(newValue: IndicatorValue): void;
}

function IndicatorSlider(props: Props) {
    function thumbIcon(): JSX.Element {
        const iconStyle = {
            left: '',
        };
        let icon: JSX.Element | null = null;

        switch (props.value) {
            case 0:
                iconStyle.left = '3px';
                icon = <Remove />;
                break;
            case 1:
                iconStyle.left = '38px';
                break;
            case 2:
                iconStyle.left = '71px';
                icon = <Add />;
                break;
        }

        return (
            <Typography component="div" className={classes.thumbText} style={iconStyle}>
                {icon}
            </Typography>
        );
    }

    function inputClassName(): string {
        let valueClass;

        if (props.value === 0) valueClass = classes.sliderNegative;
        else if (props.value === 2) valueClass = classes.sliderPositive;
        else valueClass = classes.sliderNeutral;

        return `${classes.slider} ${classes.rangeIndicator} ${valueClass}`;
    }

    return (
        <Root className={classes.buttonArea}>
            <input
                value={props.value}
                onChange={(e) => props.onChange(Number(e.currentTarget.value) as IndicatorValue)}
                type="range"
                min="0"
                max="2"
                step="1"
                className={inputClassName()}
            />
            {thumbIcon()}
        </Root>
    );
}

export default observer(IndicatorSlider);
