import { configure, makeAutoObservable } from 'mobx';
import { TranslationKeys } from '../../types/translation';
import RootModule from '../rootModule';

configure({ enforceActions: 'observed' });

type SnackbarSeverityType = 'success' | 'info' | 'error' | 'warning';

export default class SnackbarModule {
    private rootModule: RootModule;
    private text = '';
    private show = false;
    private color: SnackbarSeverityType = 'success';

    constructor(rootModule: RootModule) {
        makeAutoObservable(this, {}, { autoBind: true });
        this.rootModule = rootModule;
    }

    public get isOpen(): boolean {
        return this.show;
    }

    public get message(): string {
        return this.text;
    }

    public get severity(): SnackbarSeverityType {
        return this.color;
    }

    public open(): void {
        this.show = true;
    }

    public close(): void {
        this.show = false;
    }

    public setMessage(message: string): void {
        this.text = message;
    }

    public setSeverity(severity: SnackbarSeverityType): void {
        this.color = severity;
    }

    public showSuccess(message?: keyof TranslationKeys): void {
        this.text = this.rootModule.localization.translate(message || 'SUCCESS');
        this.color = 'success';
        this.show = true;
    }

    public showError(message?: keyof TranslationKeys): void {
        this.text = this.rootModule.localization.translate(message || 'GENERAL_ERROR');
        this.color = 'error';
        this.show = true;
    }

    public showFetchFailedMessage(message?: keyof TranslationKeys): void {
        this.text = this.rootModule.localization.translate(message || 'DATA_FETCH_FAILED');
        this.color = 'error';
        this.show = true;
    }
}
