import React, { useEffect } from 'react';
import { IReactionDisposer, reaction, runInAction } from 'mobx';
import { useLocalObservable, observer } from 'mobx-react-lite';
import { Box, Container, Grid } from '@mui/material';
import Directories from '../components/home/Directories';
import DocumentArea from '../components/home/DocumentArea';
import Document from '../components/document/Document';
import useStore from '../store/storeContext';

function Home() {
    const { document, organization, directory, session, user, snackbar } = useStore();

    const state = useLocalObservable(() => ({
        fetching: false,
        disposer: null as IReactionDisposer | null,

        // Maybe change reaction to useEffect because they effectively do the same thing?
        async runDisposer() {
            this.disposer = reaction(() => user.userDetails?.selectedOrganization, await getData, {
                fireImmediately: true,
            });
        },
    }));

    useEffect(() => {
        const init = async (): Promise<void> => {
            if (!session.isControlDataInitialized) {
                await session.fetchInitialControlData();
            }
            await state.runDisposer();
        };

        void init();

        return () => {
            if (state.disposer) state.disposer();
        };
    }, []);

    async function getData(): Promise<void> {
        try {
            const organizationId = organization.currentOrganizationId;
            if (organizationId) {
                runInAction(() => (state.fetching = true));
                await document.fetchOrganizationDocuments(organizationId);
                await directory.fetchDirectories();
            }
        } catch (error) {
            if (error.data?.code) snackbar.showError(error.data.code);
            else snackbar.showFetchFailedMessage();
        } finally {
            runInAction(() => (state.fetching = false));
        }
    }

    return (
        <>
            {document.currentDocument.id ? (
                <Document />
            ) : (
                <Container>
                    <Box my={3}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={8}>
                                <DocumentArea />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Directories fetching={state.fetching} getData={getData} />
                            </Grid>
                        </Grid>
                    </Box>
                </Container>
            )}
        </>
    );
}

export default observer(Home);
