import React from 'react';
import { observer } from 'mobx-react-lite';
import {
    Box,
    Card,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Delete, Info } from '@mui/icons-material';
import { EditorDecisionOption } from '../../../../types/evaTemplate';
import { ButtonEvent } from '../../../../types/events';
import { Chapter } from '../../../../types/document';
import EvaDecisionOptionAccordions from './EvaDecisionOptionAccordions';
import DangerIconButton from '../../../../styled/DangerIconButton';
import ShvkButton from '../../../../styled/ShvkButton';
import useStore from '../../../../store/storeContext';

const PREFIX = 'EvaDecisionOption';

const classes = {
    table: `${PREFIX}-table`,
    cell: `${PREFIX}-cell`,
};

const StyledBox = styled(Box)({
    [`& .${classes.table}`]: {
        tableLayout: 'fixed',
    },
    [`& .${classes.cell}`]: {},
});

interface Props {
    chapter: Chapter;
    editing: boolean;
}

function EvaDecisionOption(props: Props) {
    const { document, dialog, localization, theming, snackbar, loadingIndicator } = useStore();

    async function removeDecisionOption(event: ButtonEvent, decisionOption: EditorDecisionOption): Promise<void> {
        event.stopPropagation();
        const { translate } = localization;
        const translation = translate('CONFIRM_EVA_DECISION_OPTION_REMOVAL_TEXT');
        const confirmDialogText = translation.replace('{{optionName}}', decisionOption.text);
        const confirmation = await dialog.getConfirmation(undefined, confirmDialogText, true);
        if (!confirmation) return;

        try {
            loadingIndicator.show();
            await document.removeEvaDecisionOption(decisionOption.id);
        } catch (error) {
            snackbar.showError(error.data?.code);
        } finally {
            loadingIndicator.hide();
        }
    }

    function getIsEditable(): boolean {
        return !document.isCurrentDocumentApproved && !document.isPreview && props.editing;
    }

    function getIsMaxDecisionOptions(): boolean {
        const { evaDecisionOptions } = props.chapter;
        return evaDecisionOptions.length >= 4;
    }

    const openDecisionOptionDialog = (decisionOption: EditorDecisionOption | null): void => {
        if (isEditable) {
            const { chapter } = props;
            dialog.openDocumentDialog('addDecisionOptionDialog', { decisionOption, chapter });
        }
    };

    const isEditable = getIsEditable();
    const isMaxDecisionOptions = getIsMaxDecisionOptions();
    const cursorStyle = {
        cursor: isEditable ? 'pointer' : 'auto',
    };

    const { translate } = localization;

    return (
        <StyledBox my={2}>
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <TableContainer component={Card} variant="outlined">
                        <Table className={classes.table} size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ width: '20%' }}>
                                        {document.isCurrentDocumentEvaMini
                                            ? translate('EVA_MINI_DECISION_OPTION')
                                            : translate('EVA_OPTION')}
                                    </TableCell>
                                    <TableCell>{translate('EVA_OPTION_DESCRIPTION')}</TableCell>
                                    <TableCell padding="checkbox" />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {props.chapter.evaDecisionOptions.map((decisionOption) => (
                                    <TableRow
                                        key={decisionOption.id}
                                        hover={isEditable}
                                        style={cursorStyle}
                                        onClick={(): void => openDecisionOptionDialog(decisionOption)}
                                    >
                                        <TableCell className={classes.cell}>{decisionOption.text}</TableCell>
                                        <TableCell className={classes.cell}>{decisionOption.description}</TableCell>
                                        <TableCell className={classes.cell} padding="checkbox">
                                            {isEditable && (
                                                <DangerIconButton
                                                    onClick={(event): Promise<void> =>
                                                        removeDecisionOption(event, decisionOption)
                                                    }
                                                >
                                                    <Delete />
                                                </DangerIconButton>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                {isEditable && (
                    <Grid item xs={12}>
                        <Tooltip
                            placement="top"
                            title={isMaxDecisionOptions ? translate('EVA_ADD_DECISION_OPTION_MAX_AMOUNT_TIP') : ''}
                        >
                            <span>
                                <ShvkButton
                                    disabled={isMaxDecisionOptions}
                                    variant="contained"
                                    color="primary"
                                    onClick={(): void => openDecisionOptionDialog(null)}
                                >
                                    {document.isCurrentDocumentEvaMini
                                        ? translate('EVA_MINI_ADD_DECISION_OPTION')
                                        : translate('EVA_ADD_DECISION_OPTION')}
                                </ShvkButton>
                            </span>
                        </Tooltip>
                        {!isMaxDecisionOptions && (
                            <Tooltip
                                placement="top"
                                leaveDelay={100}
                                title={translate('EVA_ADD_DECISION_OPTION_TIP')}
                                style={{ verticalAlign: 'middle', marginLeft: theming.spacing(1) }}
                            >
                                <Info color="primary" />
                            </Tooltip>
                        )}
                    </Grid>
                )}
                <Grid item xs={12}>
                    <EvaDecisionOptionAccordions chapter={props.chapter} editing={props.editing} />
                </Grid>
            </Grid>
        </StyledBox>
    );
}

export default observer(EvaDecisionOption);
