import Axios from '../../axiosConfig';
import { BACKEND_URL } from '../../config';
import { configure, runInAction, makeAutoObservable } from 'mobx';
import LocalParameters, { VersionParameters } from '../../types/sessionParameters';
import RootModule from '../rootModule';

configure({ enforceActions: 'observed' });

export default class SessionModule {
    private rootModule: RootModule;
    private localParameters: LocalParameters = {
        authToken: '',
    };

    private controlData = {
        controlDataInitialized: false,
        logoutTime: 480,
        isTrainingEnvironment: true,
        maxAddIndicators: 50,
        hyteIdsK: [] as string[],
        hyteIdoK: [] as string[],
        hyteIdsA: [] as string[],
        hyteIdoA: [] as string[],
        hyteIdsKLossi: [] as string[],
        hyteIdsALossi: [] as string[],
        chapterLockTimeout: 1500000,
    };

    constructor(rootModule: RootModule) {
        makeAutoObservable(this, {}, { autoBind: true });
        this.rootModule = rootModule;
    }

    public get logoutTime(): number {
        return this.controlData.logoutTime;
    }

    public get maxAddIndicators(): number {
        return this.controlData.maxAddIndicators;
    }

    public get hyteIdsK(): string[] {
        return this.controlData.hyteIdsK;
    }

    public get hyteIdoK(): string[] {
        return this.controlData.hyteIdoK;
    }

    public get hyteIdsA(): string[] {
        return this.controlData.hyteIdsA;
    }

    public get hyteIdoA(): string[] {
        return this.controlData.hyteIdoA;
    }

    public get hyteIdsKLossi(): string[] {
        return this.controlData.hyteIdsKLossi;
    }

    public get hyteIdsALossi(): string[] {
        return this.controlData.hyteIdsALossi;
    }

    public get chapterLockTimeout(): number {
        return this.controlData.chapterLockTimeout;
    }

    public get isTrainingEnvironment(): boolean {
        return this.controlData.isTrainingEnvironment;
    }

    public get isControlDataInitialized(): boolean {
        return this.controlData.controlDataInitialized;
    }

    public setParameters(parameters: LocalParameters): void {
        this.localParameters = parameters;
    }

    public async login(username: string, password: string): Promise<void> {
        const response = await Axios({
            method: 'POST',
            url: BACKEND_URL + 'login',
            data: {
                username,
                password,
            },
        });
        localStorage.setItem('shvkAuthToken', response.data.access_token);
        this.setParameters({
            authToken: response.data.access_token,
        });
    }

    public async logout(): Promise<void> {
        try {
            this.rootModule.loadingIndicator.showLogin();
            await Axios({
                method: 'POST',
                url: BACKEND_URL + 'logout',
            });
            this.setParameters({ authToken: '' });
            this.clearLocalStorage();
            this.rootModule.user.clearUserDetails();
        } finally {
            this.rootModule.loadingIndicator.hideLogin();
        }
    }

    public clearLocalStorage(): void {
        localStorage.removeItem('shvkAuthToken');
    }

    public getParametersFromStorage(): void {
        const authToken = localStorage.getItem('shvkAuthToken');
        this.setParameters({
            authToken,
        });
    }

    public get parameters(): LocalParameters {
        return this.localParameters;
    }

    public async fetchBackendVersion(): Promise<VersionParameters> {
        const response = await Axios({
            method: 'GET',
            url: BACKEND_URL + 'version/index',
        });
        return response.data;
    }

    public async fetchFrontendVersion(): Promise<VersionParameters> {
        const response = await Axios({
            method: 'GET',
            url: 'build.json',
        });
        return response.data;
    }

    public async fetchInitialControlData(): Promise<void> {
        const response = await Axios({
            method: 'GET',
            url: BACKEND_URL + 'app/initialControlData',
        });
        if (response.data) {
            runInAction(() => {
                this.controlData.logoutTime = parseInt(response.data.logoutTime);
                this.controlData.isTrainingEnvironment = response.data.isTrainingEnvironment !== 'false';
                this.controlData.controlDataInitialized = true;
            });
        }
    }

    public async fetchControlData(): Promise<void> {
        const response = await Axios({
            method: 'GET',
            url: BACKEND_URL + 'app/controlData',
        });
        if (response.data) {
            runInAction(() => {
                this.controlData.maxAddIndicators = response.data.maxAddIndicators;
                this.controlData.hyteIdsK = response.data.hyteIdsK.split(',');
                this.controlData.hyteIdoK = response.data.hyteIdoK;
                this.controlData.hyteIdsA = response.data.hyteIdsA.split(',');
                this.controlData.hyteIdoA = response.data.hyteIdoA;
                this.controlData.hyteIdsKLossi = response.data.hyteIdsKLossi.split(',');
                this.controlData.hyteIdsALossi = response.data.hyteIdsALossi.split(',');
                this.controlData.chapterLockTimeout = Number(response.data.chapterLockTimeout);
            });
        }
    }

    public async getInitialAppData(): Promise<void> {
        this.getParametersFromStorage();
        try {
            await this.rootModule.localization.fetchTranslations();
            await this.rootModule.localization.fetchLanguages();
            await this.rootModule.feedback.fetchFeedbackTypes();
            await this.rootModule.info.fetchCookiesInfo();
            await this.rootModule.info.fetchAccessibilityStatement();
        } catch {
            this.rootModule.snackbar.showFetchFailedMessage();
        }
    }

    public clearSessionData(): void {
        this.rootModule.session.setParameters({ authToken: '' });
        this.rootModule.session.clearLocalStorage();
        this.rootModule.user.clearUserDetails();
    }
}
