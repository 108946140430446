import React from 'react';
import { observer } from 'mobx-react-lite';
import { runInAction } from 'mobx';
import { Indicator, SaveIndicatorParams } from '../../../types/indicator';
import FroalaEditorComponent from 'react-froala-wysiwyg';
import useStore from '../../../store/storeContext';

interface Props {
    indicator: Indicator;
}

function FroalaIndicatorDescription(props: Props) {
    const context = useStore();
    const { snackbar } = context;

    async function saveDescription(): Promise<void> {
        try {
            const params: SaveIndicatorParams = {
                description: props.indicator.description || ' ',
                source: props.indicator.source,
                indicatorId: props.indicator.id,
            };
            await context.indicator.saveIndicator(params);
        } catch (e) {
            snackbar.showError(e.data?.code);
        }
    }

    function onModelChange(description: string): void {
        runInAction(() => (props.indicator.description = description !== '' ? description : null));
        void saveDescription();
    }

    return (
        <FroalaEditorComponent
            model={props.indicator.description}
            onModelChange={onModelChange}
            config={{ ...context.document.froalaFocusAreaConfig }}
        />
    );
}

export default observer(FroalaIndicatorDescription);
