import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import FroalaEditorComponent from 'react-froala-wysiwyg';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Tooltip } from '@mui/material';
import { Chapter } from '../../../../types/document';
import ShvkButton from '../../../../styled/ShvkButton';
import useStore from '../../../../store/storeContext';

interface Props {
    isOpen: boolean;
    close(): void;
    chapter?: Chapter | null;
}

interface State {
    title: string;
}

function AddFocusAreaDialog(props: Props) {
    const context = useStore();

    const [state, setState] = useState<State>({
        title: '',
    });

    useEffect(() => {
        props.isOpen && setState((state) => ({ ...state, title: '' }));
    }, [props.isOpen]);

    const handleTitleChange = (title: string): void => {
        setState((state) => ({ ...state, title }));
    };

    async function addFocusArea(): Promise<void> {
        try {
            context.loadingIndicator.show();
            await context.document.addFocusArea(props.chapter!.id, state.title);
            context.snackbar.showSuccess();
        } catch (error) {
            context.snackbar.showError(error.data?.code);
        } finally {
            props.close();
            context.loadingIndicator.hide();
        }
    }

    const { translate } = context.localization;

    return (
        <Dialog open={props.isOpen} onClose={props.close} maxWidth="lg" fullWidth>
            <DialogTitle>{translate('ADD_FOCUS_AREA')}</DialogTitle>
            <DialogContent>
                <FroalaEditorComponent
                    tag="textarea"
                    model={state.title}
                    onModelChange={handleTitleChange}
                    config={{ ...context.document.froalaFocusAreaConfig }}
                />
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={props.close}>
                    {translate('CLOSE')}
                </Button>
                <Box flexGrow={1} />
                <Tooltip title={!state.title.length && translate('FOCUS_AREA_TITLE_EMPTY')} placement="top">
                    <span>
                        <ShvkButton onClick={addFocusArea} disabled={!state.title.length}>
                            {translate('SAVE')}
                        </ShvkButton>
                    </span>
                </Tooltip>
            </DialogActions>
        </Dialog>
    );
}

export default observer(AddFocusAreaDialog);
