import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Box, IconButton, Paper, Typography } from '@mui/material';
import { AddBox, Delete, Edit } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { EvaNestableItem, EvaRenderItemArgs } from '../../types/nestable';
import useStore from '../../store/storeContext';

const PREFIX = 'EvaNestableComponent';

const classes = {
    paper: `${PREFIX}-paper`,
};

const StyledPaper = styled(Paper)(({ theme }) => ({
    [`&.${classes.paper}`]: {
        position: 'relative',
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(0.5),
        paddingRight: theme.spacing(1),
        paddingLeft: theme.spacing(1),
    },
}));

interface Props {
    args: EvaRenderItemArgs;
    removeItem(item: EvaNestableItem): void;
    openDialog(item: EvaNestableItem | null, parentId: number | null): void;
}

function EvaNestableComponent(props: Props) {
    const { theming } = useStore();

    const [color, setColor] = useState(theming.palette.common.white);

    useEffect(() => {
        setColor(getColor);
    }, []);

    function getColor(): string {
        switch (props.args.item.depth) {
            case 1:
                return theming.shvkBlueGray60;
            case 2:
                return theming.shvkBlueGray40;
            case 3:
                return theming.shvkBlueGray20;
            default:
                return theming.palette.common.white;
        }
    }
    const { args, removeItem, openDialog } = props;

    return (
        <StyledPaper variant="outlined" className={classes.paper} style={{ backgroundColor: color }}>
            {args.handler}
            <Box display="flex" alignItems="center">
                {args.collapseIcon}
                <Typography>{args.item.text}</Typography>
                <Box flexGrow={1} />
                {args.item.depth < 3 && (
                    <IconButton size="small" color="primary" onClick={(): void => openDialog(null, args.item.id)}>
                        <AddBox />
                    </IconButton>
                )}
                <IconButton size="small" color="primary" onClick={(): void => openDialog(args.item, null)}>
                    <Edit />
                </IconButton>
                <IconButton size="small" color="primary" onClick={(): void => removeItem(args.item)}>
                    <Delete />
                </IconButton>
            </Box>
        </StyledPaper>
    );
}

export default observer(EvaNestableComponent);
