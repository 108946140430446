import AdminModule from './modules/adminModule';
import ChapterTemplateModule from './modules/chapterTemplateModule';
import ContactModule from './modules/contactModule';
import DialogModule from './modules/dialogModule';
import DirectoryModule from './modules/directoryModule';
import LoadingIndicatorModule from './modules/loadingIndicatorModule';
import ImageModule from './modules/imageModule';
import OrganizationModule from './modules/organizationModule';
import InfoModule from './modules/infoModule';
import IndicatorModule from './modules/indicatorModule';
import SnackbarModule from './modules/snackbarModule';
import NewsModule from './modules/newsModule';
import UserModule from './modules/userModule';
import DocumentModule from './modules/documentModule';
import FeedbackModule from './modules/feedbackModule';
import ThemingModule from './modules/themingModule';
import EvaTemplateModule from './modules/evaTemplateModule';
import LocalizationModule from './modules/localizationModule';
import SessionModule from './modules/sessionModule';

// This module composes every other module and is used to create context with React.createContext (storeContext.tsx)
export default class RootModule {
    admin: AdminModule;
    chapterTemplate: ChapterTemplateModule;
    contact: ContactModule;
    dialog: DialogModule;
    directory: DirectoryModule;
    document: DocumentModule;
    evaTemplate: EvaTemplateModule;
    feedback: FeedbackModule;
    image: ImageModule;
    indicator: IndicatorModule;
    info: InfoModule;
    loadingIndicator: LoadingIndicatorModule;
    localization: LocalizationModule;
    news: NewsModule;
    organization: OrganizationModule;
    session: SessionModule;
    snackbar: SnackbarModule;
    theming: ThemingModule;
    user: UserModule;

    constructor() {
        this.admin = new AdminModule();
        this.chapterTemplate = new ChapterTemplateModule(this);
        this.contact = new ContactModule();
        this.dialog = new DialogModule(this);
        this.directory = new DirectoryModule(this);
        this.document = new DocumentModule(this);
        this.evaTemplate = new EvaTemplateModule();
        this.feedback = new FeedbackModule();
        this.image = new ImageModule();
        this.indicator = new IndicatorModule(this);
        this.info = new InfoModule();
        this.loadingIndicator = new LoadingIndicatorModule();
        this.localization = new LocalizationModule();
        this.news = new NewsModule();
        this.organization = new OrganizationModule(this);
        this.session = new SessionModule(this);
        this.snackbar = new SnackbarModule(this);
        this.theming = new ThemingModule(this);
        this.user = new UserModule(this);
    }
}
