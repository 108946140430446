import Axios from '../../axiosConfig';
import { BACKEND_URL } from '../../config';
import { configure, runInAction, makeAutoObservable } from 'mobx';
import { DefaultEvaTemplate, EvaTemplateRequest, OrganizationEvaTemplate } from '../../types/evaTemplate';

configure({ enforceActions: 'observed' });

export default class EvaTemplateModule {
    private defaultEvaTemplates: DefaultEvaTemplate[] = [];
    private organizationEvaTemplates: OrganizationEvaTemplate[] = [];

    constructor() {
        makeAutoObservable(this, {}, { autoBind: true });
    }

    public async fetchOrganizationEvaTemplates(idList: number[]): Promise<void> {
        const organizations = idList.map((id) => {
            return { id };
        });
        const response = await Axios({
            method: 'POST',
            url: BACKEND_URL + 'eva/organizationEvaTemplates',
            data: { organizations },
        });
        runInAction(() => (this.organizationEvaTemplates = response.data));
    }

    public async fetchDefaultEvaTemplates(): Promise<void> {
        const response = await Axios({
            method: 'POST',
            url: BACKEND_URL + 'eva/getDefaultEvaTemplates',
        });
        runInAction(() => (this.defaultEvaTemplates = response.data));
    }

    public async saveEvaTemplate(template: EvaTemplateRequest): Promise<void> {
        const response = await Axios({
            method: 'POST',
            url: BACKEND_URL + 'eva/saveEvaTemplate',
            data: { template },
        });
        const savedTemplate: OrganizationEvaTemplate = response.data;
        const index = this.organizationEvaTemplates.findIndex((template) => template.id === savedTemplate.id);
        if (index > -1) runInAction(() => (this.organizationEvaTemplates[index] = savedTemplate));
        else runInAction(() => this.organizationEvaTemplates.push(response.data));
    }

    public async removeEvaTemplate(evaTemplateId: number): Promise<void> {
        await Axios({
            method: 'POST',
            url: BACKEND_URL + 'eva/removeEvaTemplate',
            data: { evaTemplateId },
        });
        runInAction(() => {
            this.organizationEvaTemplates = this.organizationEvaTemplates.filter(
                (template) => template.id !== evaTemplateId,
            );
        });
    }

    public get organizationEvaTemplateList(): OrganizationEvaTemplate[] {
        return this.organizationEvaTemplates;
    }

    public get defaultEvaTemplateList(): DefaultEvaTemplate[] {
        return this.defaultEvaTemplates;
    }

    public getEvaTemplateById(id: number): OrganizationEvaTemplate | DefaultEvaTemplate | null {
        let template: OrganizationEvaTemplate | DefaultEvaTemplate | undefined;
        template = this.defaultEvaTemplates.find((eva) => eva.id === id);
        if (!template) template = this.organizationEvaTemplates.find((eva) => eva.id === id);

        return template || null;
    }
}
